import React, { useEffect, useState } from 'react'
import './InvoiceMarginReport.css'
import { FormControlLabel, IconButton, Skeleton, Switch, Tooltip } from '@mui/material';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { ViewInvoiceMarginReportAPIcall } from './GeneralReportAPI';
import { useSelector } from 'react-redux';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { convertDateFormat } from '../../../../../Js/Date';
import { useNavigate } from 'react-router-dom';
import { Bar } from "react-chartjs-2";
import { styled } from '@mui/material/styles';
import CustomPagination from '../../../../Single Components/CustomPagination';
import BarChart from './components/barChart/BarChart';
import ReactToPrint from 'react-to-print';
import TableListActionButtons from '../../../../Single Components/ActionButtons/TableListActionButtons';


function InvoiceMarginReport() {
  const ExcelJS = require("exceljs");

    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue)
    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );

    let navigate = useNavigate();

    const invoiceMarginList = useSelector((state) => state?.generalReport?.InvoiceMarginReport)

    const decimalPosition = localStorage.getItem("decimalPosition");

    const [FormDataInfo, setFormDataInfo] = useState([])
    const [symbol, setSymbol] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState({}); 

    const [selectedButton, setSelectedButton] = useState('customer');
    const [showPaymentsContainer, setShowPaymentsContainer] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = invoiceMarginList?.pages;
    const handlePageChange = (page) => {

        setCurrentPage(page);
    }
    const handleChartViewToggle = () => {
        setShowPaymentsContainer(!showPaymentsContainer);
    };
    const handleFilterChange = (updatedItems) => {
        setFilteredData(updatedItems);
      };
    const IOSSwitch = styled(({ value, handleChange, ...props }) => (
        <Switch
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            checked={value}
            onChange={handleChange}
            {...props}
        />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    const formatFilterInfo = (filters) => {
        const parts = [];
      
        if (filters?.fromDate && filters?.toDate) {
          parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
        }
        console.log(filters?.fromDate);
    
        if (filters?.branchList) {
          parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
        }
        if (filters?.createdBy) {
            parts.push(`createdBy: ${filters?.createdBy?.staff_name}`);
          }
          if (filters?.customer) {
            parts.push(`customer: ${filters?.customer?.name}`);
          }

        return parts; // Return the array directly
      };

    const handleDataFromChild = (data) => {
        setFormDataInfo(data);
    };
    const handleRowClick = (row) => {
        localStorage.setItem("invoiceSingleViewId", row?._id);
        navigate("/userdashboard/accounts/report/generalReport/InvoiceMarginReport/SingleView", { state: { invoiceNo: row?.invoiceNo } })
        // navigate("/userdashboard/accounts/report/generalReport/InvoiceMarginReport/SingleView")
    };
    const handleButtonClick = (buttonType) => {
        setSelectedButton(buttonType);
    };

    const downloadList = () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Invoice Margin Report");
        sheet.properties.defaultRowHeight = 20;
      
        // Add header section
        const titleRow = sheet.addRow(["Invoice Margin Report"]);
        titleRow.font = { size: 18, bold: true };
        sheet.mergeCells('A1:H1');
        titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      
        // Add additional header information
        const filterParts = formatFilterInfo(filteredData) || [];
      
        // Ensure filterParts is an array and iterate over it
        if (Array.isArray(filterParts)) {
          filterParts.forEach((part, index) => {
            const row = sheet.addRow([part]);
            row.font = { size: 15, bold: true };
            sheet.mergeCells(`A${index + 2}:H${index + 2}`);
            row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
          });
        }
      
        // Add an empty row between header and table
        sheet.addRow([]);
      
        // Header row styling
        const headerRow = sheet.addRow(["Date", "Invoice", "Employee", "Customer","Net Cost","Revenue","Profit","Profit Margin %"]);
        const headerFill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
        };
        const headerFont = {
          size: 15,
          bold: true,
          color: { argb: "FFFFFFFF" }, // White font color
        };
      
        headerRow.eachCell((cell) => {
          cell.fill = headerFill;
          cell.font = headerFont;
          cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
        });
      
        sheet.columns = [
          { header: "Date", key: "invoiceDate", width: 20 },
          { header: "Invoice", key: "invoiceNo", width: 20 },
          { header: "Employee", key: "employeeName", width: 20 },
          { header: "Customer", key: "vendorName", width: 20 },
          { header: "Net Cost", key: "cost", width: 20 },
          { header: "Revenue", key: "revenue", width: 20 },
          { header: "Profit", key: "profit", width: 20 },
          { header: "Invoice Margin Report", key: "profitMargin", width: 20 },
        ];
      
        invoiceMarginList?.report?.forEach((item) => {
          const row = sheet.addRow({
            invoiceDate: item.invoiceDate,
            invoiceNo: item.invoiceNo,
            employeeName: item.employeeName,
            vendorName: item.vendorName,
            cost: `${symbol} ${item?.cost?.toFixed(2)}`,
            revenue: `${symbol} ${item?.revenue?.toFixed(2)}`,
            profit: `${symbol} ${item?.profit?.toFixed(2)}`,
            profitMargin: ` ${item?.profitMargin?.toFixed(2)}`,
          });
      
          const rowFill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFededed" }, // Use full hex code with "FF" prefix for better compatibility
          };
          
          row.eachCell((cell, colNumber) => {
            cell.fill = rowFill;
            cell.border = {
              top: { style: "thin", color: { argb: "FFFFFFFF" } },
              left: { style: "thin", color: { argb: "FFFFFFFF" } },
              bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
              right: { style: "thin", color: { argb: "FFFFFFFF" } },
            };
            if (colNumber > 1) { // Right align all columns except the first
              cell.alignment = { horizontal: 'right' };
            }
            
          });
        });
      
        // Add row for totals
        const totalRow = sheet.addRow([
          "Total",
          "",
          "",
          "",
          `${symbol} ${invoiceMarginList?.total?.cost?.toFixed(2)}`,
          `${symbol} ${invoiceMarginList?.total?.revenue?.toFixed(2)}`,
          `${symbol} ${invoiceMarginList?.total?.profit?.toFixed(2)}`,
          `${symbol} ${invoiceMarginList?.total?.profitMargin?.toFixed(2)}`,
        ]);
      
        totalRow.eachCell((cell,colNumber) => {
          cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
          cell.fill = headerFill;
          cell.border = {
            top: { style: "thin", color: { argb: "FFFFFFFF" } },
            left: { style: "thin", color: { argb: "FFFFFFFF" } },
            bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
            right: { style: "thin", color: { argb: "FFFFFFFF" } },
          };
          if (colNumber > 1) { // Right align all columns except the first
            cell.alignment = { horizontal: 'right' };
          }
        });
      
        workbook.xlsx.writeBuffer().then(function (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "Invoice Margin Report.xlsx";
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
      };

    
    const labels = selectedButton === 'customer' ?
        invoiceMarginList?.report?.map(item => item?.vendorName) || [] :
        invoiceMarginList?.report?.map(item => `${item?.employeeName} - ${item?.invoiceNo}`) || [];

    const option = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: "Chart.js Bar Chart",
            },
        },
        scales: {
            y: {
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                    stepSize: 10,
                },
            },
        },
    };

    const datas = {
        labels,
        datasets: [
            {
                label: "Profit Margin",
                data: invoiceMarginList?.report?.map(item => item.profitMargin?.toFixed(decimalPosition)) || [],
                backgroundColor: "#05b30e",
                barThickness: 35,
            },
        ],
    };

    useEffect(() => {
        if (FormDataInfo.length !== 0) {
            ViewInvoiceMarginReportAPIcall({
                fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
                toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
                employee: FormDataInfo?.createdBy.length === 0 ? '' : FormDataInfo?.createdBy,
                vendor: FormDataInfo?.customer?.length === 0 ? '' : FormDataInfo?.customer,
                search: FormDataInfo?.search,
                branchId: FormDataInfo?.branchList,
                index: currentPage-1,
            })
        }
    }, [FormDataInfo, currentPage])

    useEffect(() => {
        let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
        setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])

    return (
      <div className="in-or-out-container updated-global-page-parent-container">
        <div
          className="in-or-out-report-wrapper"
          style={{ backgroundColor: "unset", padding: "5px" }}
        >
              <div className="justify-space-between list-create-head-bar">
                <h3>Invoice Margin Report</h3>
                <div className="create-head-buttons-container">
                  <TableListActionButtons  downloadClick={downloadList}/>
                  <CategorySearchandFilter
                    onData={handleDataFromChild}
                    onFilterChange={handleFilterChange}
                    isCustomer={true}
                    isCreatedBy={true}
                  />
                </div>
              </div>
          <div
            className="invoice-table-container"
            style={{
              width: "100%",
              marginTop: "5px",
              marginLeft: "0",
              height: "73vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "98%",
                maxHeight: "70vh",
              }}
            >
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    defaultChecked
                    value={showPaymentsContainer}
                    handleChange={handleChartViewToggle}
                  />
                }
                labelPlacement="start"
                label="Chart View"
                onChange={handleChartViewToggle}
              />

              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
            <div className="invoice-main-div">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Invoice</th>
                    <th>Employee</th>
                    <th>Customer</th>
                    <th>Net Cost</th>
                    <th>Revenue</th>
                    <th>Profit </th>
                    <th>Profit Margin %</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    [...Array(10)].map((r, i) => (
                      <tr key={i}>
                        <td colSpan={8}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                          />
                        </td>
                      </tr>
                    ))
                  ) : invoiceMarginList?.report !== undefined &&
                    invoiceMarginList?.report?.length !== 0 ? (
                    invoiceMarginList?.report?.slice(0)?.map((r, i) => (
                      <tr key={i} onClick={() => handleRowClick(r)}>
                        <td>{convertDateFormat(r?.invoiceDate)}</td>
                        <td>{r?.invoiceNo}</td>
                        <td>{r?.employeeName}</td>
                        <td>{r?.vendorName}</td>
                        <td>
                          {symbol}&nbsp;{r?.cost?.toFixed(decimalPosition)}
                        </td>
                        <td>
                          {symbol}&nbsp;{r?.revenue?.toFixed(decimalPosition)}
                        </td>
                        <td>
                          {symbol}&nbsp;{r?.profit?.toFixed(decimalPosition)}
                        </td>
                        <td>{r?.profitMargin?.toFixed(decimalPosition)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td style={{ textAlign: "center" }} colSpan={8}>
                        No Data
                      </td>
                    </tr>
                  )}
                </tbody>
                <tr
                  style={{ textAlign: "end", position: "sticky", bottom: "0" }}
                >
                  <td colSpan={4} style={{ fontWeight: "bold" }}>
                    Total
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {symbol}&nbsp;
                    {invoiceMarginList?.total !== undefined &&
                      invoiceMarginList?.total?.cost?.toFixed(decimalPosition)}
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {symbol}&nbsp;
                    {invoiceMarginList?.total !== undefined &&
                      invoiceMarginList?.total?.revenue?.toFixed(
                        decimalPosition
                      )}
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {symbol}&nbsp;
                    {invoiceMarginList?.total !== undefined &&
                      invoiceMarginList?.total?.profit?.toFixed(
                        decimalPosition
                      )}
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {invoiceMarginList?.total !== undefined &&
                      invoiceMarginList?.total?.profitMargin?.toFixed(
                        decimalPosition
                      )}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          {showPaymentsContainer && (
            <div
              className="payments-container"
              style={{ backgroundColor: "white" }}
            >
              <div
                className="top-bar"
                style={{
                  marginTop: "-3%",
                  position: "relative",
                  padding: "0 3%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <div className="invoice-button-ctn">
                    <button
                      className={
                        selectedButton === "customer"
                          ? "selected-button"
                          : "non-selected-btn"
                      }
                      onClick={() => handleButtonClick("customer")}
                    >
                      Customer
                    </button>
                    <button
                      className={
                        selectedButton === "employee"
                          ? "selected-button"
                          : "non-selected-btn"
                      }
                      onClick={() => handleButtonClick("employee")}
                    >
                      Employee
                    </button>
                  </div>
                </div>
              </div>

              {/* <div style={{ marginTop: "3%", width: "100%", position: "relative" }}>
                            <Bar options={option} data={datas} />
                        </div> */}
              <div
                style={{
                  marginTop: "3%",
                  width: "100%",
                  position: "relative",
                  overflowX: "auto",
                }}
              >
                {/* <Bar options={option} data={datas} /> */}
                <BarChart
                  selection={selectedButton}
                  datas={invoiceMarginList?.report || []}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
}

export default InvoiceMarginReport