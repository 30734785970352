import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { addSeatFormKeyName } from "../../utils/floorManagementEnum";

const SingleSelectionInput = ({
  focused,
  label,
  options,
  value,
  placeHolder,
  onChange,
}) => {
  return (
    <div>
      <Autocomplete
        id="tags-outlined"
        options={options}
        getOptionLabel={(option) => option?.branchName}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            focused={focused}
            {...params}
            label={label}
            placeholder={placeHolder}
          />
        )}
        value={value}
        onChange={(e, v) => onChange(addSeatFormKeyName.BRANCH, v)}
      />
    </div>
  );
};

export default SingleSelectionInput;
