import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import postRibbon from "../../../../../Assets/statusRibbon/post.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import Draft from "../../../../../Assets/statusRibbon/draft.png";
import cancel from "../../../../../Assets/statusRibbon/cancel.png"
import { convertDateFormat, today } from "../../../../../Js/Date";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { getTemplateAPI, salesQuotationSingleViewAPICall } from './salesQuatationAPI';
import Template1 from "./Template1";
import Template2 from "./Template2";
import { set_Back_button_action } from "../../../../Single Components/listSlice/filterCategorySlice";
import { InvoiceContent } from "../../../Accounts/PrintComponents/printTemplates/common/InvoiceContent";
import { printSection } from "../../../Accounts/PrintComponents/printTemplates/common/enum";

export const SalesQuatationSingleVieww = () => {
  let paymentsPrint = useRef();
  let paymentsPrintNew = useRef();
  const componentRef = useRef();

  const decimalPosition = localStorage.getItem("decimalPosition");

  const singleRowId = localStorage.getItem("singleViewById")
  const dispatch = useDispatch();
  //data of selected List
  const singleList = useSelector(
    (state) => state?.salesQuatationSlice?.rowData
  );

  const template =useSelector((state)=>state?.salesQuatationSlice?.setTemplate)

  //console.log(singleList);

  // company details and info
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue)

  // initial values

  const formValuesInitialState = {
    status: "",
    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
    companyTRN: "",

    customerName: "",
    customerAddress: "",
    customerContact: "",
    trn:"",

    deliveryName: "",
    deliveryAddress: "",
    deliveryContact: "",

    dateN1:"Quotation Date",
    dateN2:"Expiry Date",

    name: "",
    firstDate: "",
    secondDate: "",

    termsAndCondition:"",

    lpo:"",
    ref:"",
    subtotal: "",
    inclusiveTax: "",
    exclusiveTax: "",
    total: "",
    totalAmount:"",
    payTerm:"",
    sequence:"Quot No",
    label:"QUOTATION"
  };
  const [formValues, setFormValues] = useState(formValuesInitialState);

  // table values
  const [data, setData] = useState([]);
  const [tax, setTax] = useState([]);
  // api to fetch companyprofile data
  useEffect(() => {
    getTemplateAPI();
    if (singleRowId) {
      salesQuotationSingleViewAPICall({ _id: singleRowId })
    }

  }, [])
  //console.log(formValues?.status);
  useEffect(() => {
    setFormValues({
      ...formValues,
      status: singleList?.status,
      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
      companyTRN: companyProfileData?.companyTRN || "",

      customerName: singleList?.customerAddress?.customerName || "",
      customerAddress: singleList?.customerAddress?.streetName || "",
      customerContact: singleList?.customerAddress?.mobileNo || "",
      trn: singleList?.customerAddress?.TRN || "",

      // deliveryName: singleList?.supplierName || "",
      // deliveryAddress: singleList?.status || "",
      // deliveryContact: singleList?.status || "",
      termsAndCondition:singleList?.termsAndConditions || "",

      
      lpo: singleList?.LpoNo ||"",
      name: singleList?.NAME || "",
      firstDate: singleList?.quotationDate || "",
      secondDate: singleList?.expiryDate || "",
      ref: singleList?.reference || "",
      subtotal: singleList?.subTotal || "",
      inclusiveTax: singleList?.totalTaxInclusive || "",
      exclusiveTax: singleList?.totalTaxExclusive || "",
      totalAmount: singleList?.totalAmount || "",
      total: singleList?.total || "",
      payTerm: singleList?.PAYMENTTERM || "",
    });

    setData(singleList?.productInfo);
    setTax(singleList?.taxDetails)
  }, [singleList, companyProfileData]);


  let navigateTo = useNavigate();
  const [domain, setDomain] = useState("");

  useEffect(() => {
    if (formValues) {
      let element = formValues;
      // console.log(element);
      // const domainAddress = `${window.location.protocol}//${window.location.host}/posReaderPreview?path=admin/viewqrcodedata&db=${element?.db}&vendororcusid=${element?.supplierId}&type=${element?.qrcodetype}&orderId=${element?._id}`;
      const domainAddress = `${window.location.protocol}//${window.location.host}/posReaderPreview?path=admin/viewqrcodedata&db=${encodeURIComponent(element?.db)}&vendororcusid=${encodeURIComponent(element?.supplierId)}&type=${encodeURIComponent(element?.qrcodetype)}&orderId=${encodeURIComponent(element?._id)}`;


      setDomain(domainAddress);
    }
  }, [formValues]);

  return (
    <div className="main-container"  style={{ backgroundColor: "unset", padding: "0" }}>
      <div className="first-div-container" style={{ backgroundColor: "white", justifyContent: "space-between" }}>
        {/* <div className="post-ribbon">
          {formValues?.status === "DRAFT" && <img src={Draft} alt="" />}
          {formValues?.status === "POSTED" && <img src={postRibbon} alt="" />}
          {formValues?.status === "CANCEL" && <img src={cancel} alt="" />}
        </div> */}
        <div style={{display:"flex",gap:"8px"}}>

        <Tooltip title="Edit">
          <IconButton>
            <i
              class="bi bi-pencil-square edit-icon1"
              onClick={() =>
                navigateTo("/userdashboard/sales/orders/quotation/create")
              }
            ></i>
          </IconButton>
        </Tooltip>
        
          <h3>
            <span  
              style={{ fontSize: "medium", color: "black", cursor: "pointer" }}
              onClick={() =>{
                navigateTo("/userdashboard/sales/orders/quotation")
                dispatch(set_Back_button_action({ backButtonClickAction: true }));
              }    
              }
            >Sales Quotation &gt;</span>
         <span style={{fontSize:"medium",paddingLeft:"8px"}}>{formValues?.name}</span>

          </h3>
        
        </div>
        <div>
        {/* <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div">
              <i style={{ color: "black" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrint}
        /> */}
        <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor:"#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        {/* <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrintNew}
        /> */}
        <ReactToPrint
            // The print button
            trigger={() => (
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i
                  style={{
                    color: "white",
                    backgroundColor: " #d787e7",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                  class="bi bi-printer"
                ></i>
              </IconButton>
            )}
            // The content to be printed (InvoiceContent component)
            content={() => componentRef.current}
            // Set the page style for printing
            pageStyle="@page { size: A4; margin: 0; }"
          />

        <Tooltip title="Download">
          <IconButton>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>
        </div>
      </div>
      {/* <hr className="line" /> */}
      <div style={{ backgroundColor: "white", height: "84vh", marginTop: "4px", position: "relative" }}>
      <div className="second-main-container">
      <div className="post-ribbon" style={{ right: "0px", top: "0px" }}>
          {formValues?.status === "DRAFT" && <img src={Draft} alt="" />}
          {formValues?.status === "POSTED" && <img src={postRibbon} alt="" />}
          {formValues?.status === "CANCEL" && <img src={cancel} alt="" />}
        </div>
        <div className="left-side-div">
          <img src={formValues?.companyLogo} alt="" />
          <p>{formValues?.companyName}</p>
          <p>{formValues?.companyAddress}</p>
          <p>{formValues?.companyContact}</p>

          <h1 className="vendor-head">Customer Address</h1>
          <p>{formValues?.customerName}</p>
          <p>{formValues?.customerContact}</p>        

          <h1 className="vendor-head">Delivery To </h1>
          {/* <p>--</p>
          <p>--</p>
          <p>--</p> */}
        </div>
        <div className="right-side-div">
          <h1>Sales Order</h1>
          <p>{formValues?.name}</p>
          <div className="date-div">
            <p>
              <span class="date-container-single">Quotation Date:</span>
              {formValues?.firstDate}
            </p>
            <p>
              <span class="date-container-single">Expiry Date:</span>
              {formValues?.secondDate}
            </p>
          </div>
        </div>
      </div>

      <div className="Table-container">
        <div className="Table-data" style={{maxHeight:"44vh"}}>
          <table>
            <thead>
              <tr style={{ backgroundColor: "#eaeef9" }}>
              <th>SI NO</th>
                <th>PRODUCT</th>
                <th>DESCRIPTION</th>
                <th>QTY</th>
                <th>UOM</th>
                <th>RATE</th>
                <th>AMOUNT</th>
                <th>DISC%</th>
                <th>VAT </th>
                <th>VAT Amount</th>
                <th>NET AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{item?.product}</td>
                  <td>{item.label}</td>
                  <td>{item.qty}</td>
                  <td>{item.uomName}</td>
                  <td>{item.unitPrice?.toFixed(decimalPosition)}</td>
                  <td>{(item.qty * item.unitPrice)?.toFixed(decimalPosition)}</td>
                  <td>{item.discount}%</td>
                  <td

                  >
                    {item.taxIdList?.map((r, i) => (
                      <p key={i} className="tax" style={{ margin: "1px", border: "none" }}>
                        {r?.taxName}
                      </p>
                    ))}
                  </td>
                  <td>{item.taxAmt?.toFixed(decimalPosition)}</td>
                  <td>
                    {item.includedPrice === false ?
                      ((item.lineTotal + item.taxAmt)?.toFixed(decimalPosition)) :
                      (item.lineTotal?.toFixed(decimalPosition))
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
         
        </div>
        <div style={{ backgroundColor: "#e9e9e9", display: "flex", textAlign: "end", paddingInline: "5%" ,width:"85%"}}>
            <p style={{ margin: "0" }}>Total</p>
            {data && data.length > 0 && (
              <p style={{ width: "58%", margin: "0" }}>
                {" "}
                {data?.reduce((total, item) => {
                  const qty = typeof item.qty === 'number' ? item.qty : 0; // Check if item.qty is a number, otherwise default to 0
                  const unitPrice = typeof item.unitPrice === 'number' ? item.unitPrice : 0; // Check if item.unitPrice is a number, otherwise default to 0
                  return total + qty * unitPrice;
                }, 0)?.toFixed(decimalPosition)}
              </p>
            )}


            <p style={{ width: "27%", margin: "0" }}> {(data?.reduce((acc, row) => acc + row?.taxAmt, 0.00))?.toFixed(decimalPosition)}</p>
            <p style={{ width: "14%", padding: "3px", margin: "0", fontSize: "smaller" }}>
              {data?.reduce((acc, row) => {
                if (row.includedPrice === false) {
                  return acc + (row.lineTotal + row.taxAmt);
                } else {
                  return acc + row.lineTotal;
                }
              }, 0.00)?.toFixed(decimalPosition)}
            </p>

          </div>
          <div className="last-total-div"style={{width:"85%",padding:"18px 32px"}}>
            <div className="sub-total">
              <h1>Sub Total</h1>
              <p>Total tax Inclusive</p>
              <p>Total tax Exclusive</p>
              <hr className='line-2' />
              <h1>Total</h1>
            </div>
            <div className="sub-total-amount">
              <h1>{formValues?.subtotal || 0}</h1>
              <p>{formValues?.inclusiveTax || 0}</p>
              <p>{formValues?.exclusiveTax || 0}</p>
              <hr className='line-3' />
              <h1>{formValues?.total}</h1>
            </div>
          </div>
      </div>
</div>
      {/* payments print view */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <p></p>
              <p></p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {/* <h3>--</h3>

              <h3>--</h3> */}

              <p>Sales Order</p>
            </div>

            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>{convertDateFormat(today)}</p>
              <p>{""}</p>
            </div>
          </div>

          <div className="second-main-container">
            <div className="left-side-div">
              <img
                src={formValues?.companyLogo}
                alt=""
              />
              <p>{formValues?.companyName}</p>
              <p>{formValues?.companyAddress}</p>
              <p>{formValues?.companyContact}</p>

              <h1 className="vendor-head">Customer Address</h1>
              <p>{formValues?.customerName}</p>
              {/* <p>--</p>
              <p>--</p> */}

              <h1 className="vendor-head">Deliver To</h1>
              {/* <p>--</p>
              <p>--</p>
              <p>--</p> */}
            </div>
            <div className="right-side-div">
              <h1>Sales Order </h1>
              <p>{formValues?.name}</p>
              <div className="date-div">
                <p>
                  <span class="date-container-single">Quotation Date:</span>
                  {formValues?.firstDate}
                </p>
                <p>
                  <span class="date-container-single">Expiry Date:</span>
                  {formValues?.secondDate}
                </p>
              </div>
            </div>
          </div>

          <div className="Table-container" style={{ padding: "25px 35px" }}>
            <div className="Table-datas">
              <table style={{ overflow: "none" }}>
                <thead>
                  <tr style={{ backgroundColor: "#eaeef9" }}>
                    <th>No</th>
                    <th>Product</th>
                    <th>Description</th>
                    <th>UOM</th>
                    <th>QTY</th>
                    <th>Unit Price</th>
                    <th>Discount</th>
                    <th>Tax</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                        height: "32px",
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{item.product}</td>
                      <td>{item.label}</td>
                      <td>{item.uomName}</td>
                      <td>{item.qty}</td>
                      <td>{item.unitPrice}</td>
                      <td>{item.discount}</td>
                      <td
                        style={{
                          display: "flex",
                          placeContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        {item.taxes?.map((r, i) => (
                          <p key={i} className="tax" style={{ margin: "1px" }}>
                            {r?.amount}%
                          </p>
                        ))}
                      </td>
                      <td>{item.lineTotal}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="last-total-div">
                <div className="sub-total">
                  <h1>Sub Total</h1>
                  <p>Total tax Inclusive</p>
                  <p>Total tax Exclusive</p>
                  <hr className='line-2' />
                  <h1>Total</h1>
                </div>
                <div className="sub-total-amount">
                  <h1>{formValues?.subtotal || 0}</h1>
                  <p>{formValues?.inclusiveTax || 0}</p>
                  <p>{formValues?.exclusiveTax || 0}</p>
                  <hr className='line-3' />
                  <h1>{formValues?.total}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="terms">
            <h1>Terms & Conditions</h1>
            <hr className="lines" />
          </div>
        </div>
      </div>
      {/* <div style={{ display: "none" }}>
     <div ref={(el) => (paymentsPrintNew = el)}>
    {template?.templateModel === 'template1' ? (
      <Template1 data={data} formValues={formValues} tax={tax}     domain={domain}/>
    ) : (
      <Template2 data={data} formValues={formValues} tax={tax}    domain={domain}/>
    )}
  </div>
</div> */}
<div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
          <InvoiceContent
            invoiceDetails={{ ...formValues, printSection: printSection.salesQuotation }}
            ref={componentRef}
            printData={data}
            taxDetails={tax}
            qrData={domain}
          />
        </div>
      </div>
    </div>
  );
};
