// icon:ellipse-outline | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

function IconEllipseOutline(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1.5em"
      width="1.5em"
      {...props}
    >
      <path d="M12 6c4.41 0 8 2.69 8 6s-3.59 6-8 6-8-2.69-8-6 3.59-6 8-6m0-2C6.5 4 2 7.58 2 12s4.5 8 10 8 10-3.58 10-8-4.5-8-10-8z" />
    </svg>
  );
}

export default IconEllipseOutline;
