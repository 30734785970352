import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAggregator, setAggregatorRef, setBillType, setBtnSelected, setCustomer, setCustomerTextfieldInput, setCustomerWallet, setOrderWaiter, setSearchBarInput, setSingleCusInfo} from './topBarSliceBilling'
import { Autocomplete, Dialog, IconButton, TextField, createFilterOptions } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import { defaultCustomerForPOSAPI } from '../API/billingFloorSection'
import { setDialogBox, setErrorSnack, setOrderIdInPOS, setPageIndex, setSnackMsg } from '../Slice/billingScreenSlice'
import { findObjFromList } from '../../../../../Js/generalFunctions'
import { listHeldOrders, listOrdersForBillingAPI } from '../API/viewOrderSection'
import { getCustomerWallet } from '../API/billingPaymentSection'
import { listReservationList } from '../API/billingReservation'
import { setApplyBtnClicked, setFilteredOrderType, setFilteredStatus } from '../Utilities/filterButtonSlice'
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import EditIcon from '@mui/icons-material/Edit';
import AddCustomerFromReservation from '../Utilities/AddCustomerFromReservation'
import { getSingleCustomerInfoAPICall } from '../API/billingScreenSection'
import { setVisibleKeyboard } from '../../../../Single Components/customKeyBoardSlice';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import CustomeKeyBoard from '../../../../Single Components/CustomeKeyBoard';
import CustomerListPopup from '../Utilities/CustomerListPopup';
import { successSnackMsg } from '../../../../Custom Hooks/SnackkBarUtilities';

function TopBar() {

const menuRef=useRef()

const userInfo=useSelector((state)=>state.loginResponseSlice.value)

// api responses
const customerList = useSelector((state) => state.allCustomerListSlice.value);

// const createObjectsArray = (num) => {
//     return Array.from({ length: num }, (_, index) => ({
//         _id: `unique_id_${index}`,
//         name: `Name_${index}`,
//         mobileNo: `000000000${index % 10}`
//     }));
// };

// const customerList = createObjectsArray(8000);

const customerAutocompletRef=useRef(null)

// store states
const {customer,btnClicked,defaultCustomer,aggregator,searchBarInput,aggregatorRef,customerTextfieldInput}=useSelector((state)=>state.topBarSliceBilling)
const {heldOrders}=useSelector((state)=>state.viewOrdersBillingSlice)
const {reservationList}=useSelector((state)=>state.reservationSlice)
const {keyBoardVisible} = useSelector((state) => state.customKeyBoardSlice);


const {singleOrderInfo,dialogOpen} = useSelector((state) => state.billingScreenSlice);
const {aggregatorList}=useSelector((state)=>state.aggregatorSlice)
const allActiveEmpList = useSelector((state) => state.allActiveEmployeeSlice.value);
const dispatch=useDispatch()
// order types list
const switchTabs=["Dine In","Take Away","Delivery"]

// useStates
const [addCustomerDialog, setAddCustomerDialog] = useState(false);
const [billTypeOption,setBillTypeOption]=useState("Dine In")
const [showAggregator,setShowAggregator]=useState(false)
const [customerInputFocus, setCustomerInputFocused] = useState(false);
const [aggregatorRefValidation, setAggregatorRefValidation] = useState(false);
// functions
const onChangeBillType=(key)=>()=>{
    let option=key==="Dine In"?"DINEIN":key==="Take Away"?"TAKEAWAY":"DELIVERY"
    dispatch(setBillType(option))
    setBillTypeOption(key)
    option==="DELIVERY" ? setShowAggregator(!showAggregator):setShowAggregator(false)
}

const closeCustomerDialog=()=>{
    setAddCustomerDialog(false)
    dispatch(setDialogBox(null))
    dispatch(setVisibleKeyboard(null))
    
}

const buttonsOnClick=(option)=> {
    dispatch(setSearchBarInput(null))
    dispatch(setOrderIdInPOS(null))
    if(btnClicked===option){
        dispatch(setBtnSelected(null))
    }else{
        dispatch(setBtnSelected(option))
        if(option==="viewOrder"){
            dispatch(setFilteredOrderType(null))
            dispatch(setFilteredStatus(null))
            listOrdersForBillingAPI({branchId:userInfo?.branchPk,type:2})
        }
        if(option==="heldOrder"){
            dispatch(setFilteredStatus(null))
            listHeldOrders({branchId:userInfo?.branchPk,type:1})
        }
        if(option==="reservation"){
            listReservationList({branchId:userInfo?.branchPk})
        }
    }
}

const aggregatorSelected=(item)=>()=>{
    dispatch(setAggregator((aggregator?._id===item?._id)?null:item))
    dispatch(setAggregatorRef(null))
    if(!(aggregator?._id===item?._id)){
        item?.isSelf?setShowAggregator(false):dispatch(setDialogBox("aggregatorRef"))
        successSnackMsg(`${item?.name} : selected for delivery`)
    }
}

const customerOnChange=(e,newValue)=>{
    dispatch(setVisibleKeyboard(null))
    if(singleOrderInfo?.type==="refund"){
        dispatch(setSnackMsg("Customer can't be changed on refund!!"))
        dispatch(setErrorSnack(true))
    }else{
        dispatch(setCustomer(newValue))
        newValue?getCustomerWallet({cusId:newValue?._id}):dispatch(setCustomerWallet(null))
    }
        setCustomerInputFocused(newValue===null)
    
}

const searchInputOnChange=(e)=>{
    dispatch(setSearchBarInput(e.target.value))
    dispatch(setApplyBtnClicked("clicked"))
    dispatch(setPageIndex(0))
}

const customerTextfieldChange=(newInputValue)=>{
    dispatch(setCustomerTextfieldInput(newInputValue))
}
// const filterOptions = createFilterOptions({
//     matchFrom: "any",
//     stringify: (option) => option?.name + option.mobileNo,
//   });

// const filterOptions = createFilterOptions({
//     ignoreCase: true,
//     matchFrom: "end",
//     limit: 30,
//     stringify: (option) => option?.name + option.mobileNo,
//   });


const filterOptionsPaginated = createFilterOptions({
    ignoreCase: true,
    matchFrom: "any",
    limit: 30,
    stringify: (option) => `${option.name} ${option.mobileNo}`,
});


const filterOptions = (options, state) => {
    const inputValue = state?.inputValue?.replace(/^0+/, ''); // Remove leading zeros
    const filtered = filterOptionsPaginated(options, { ...state, inputValue }); // Use the initial filter options with preprocessed inputValue
    return filtered.filter(option => {
        const searchStr = `${option.name} ${option.mobileNo}`;
        return searchStr.toLowerCase().includes(inputValue.toLowerCase());
    });
};

const onFocusCustomer=()=>{
    setCustomerInputFocused(true)
}
const onBlurCustomer=()=>{
    setCustomerInputFocused(false)
}

const onCloseAggReference=()=>{
    dispatch(setDialogBox(null))
    dispatch(setAggregatorRef(null))
    dispatch(setAggregator(null))
    setShowAggregator(true)
}
useEffect(()=>{
    defaultCustomer===null &&   defaultCustomerForPOSAPI({})
},[])

useEffect(()=>{
    if(customer!==null){
        getSingleCustomerInfoAPICall({_id:customer?._id})
    }else{
        dispatch(setSingleCusInfo(null))
        dispatch(setCustomerWallet(null))
    }
},[customer])
useEffect(()=>{
    if(singleOrderInfo!==null){
        let cusObj=findObjFromList("_id",customerList,"cusId",singleOrderInfo)
        dispatch(setCustomer(cusObj))

        cusObj!==null &&getCustomerWallet({cusId:singleOrderInfo?.cusId})
        dispatch(setAggregator(findObjFromList("_id",aggregatorList,"aggregatorId",singleOrderInfo)))
        dispatch(setOrderWaiter(findObjFromList("_id",allActiveEmpList,"waiterId",singleOrderInfo)))
        dispatch(setAggregatorRef(singleOrderInfo?.aggregatorRef || null))
        let key=singleOrderInfo?.ordType
        let option=key==="TAKEAWAY"?"Take Away":key==="DELIVERY"?"Delivery":"Dine In"
        dispatch(setBillType(key))
        setBillTypeOption(option)
    }else{
        if(btnClicked!==null){
            dispatch(setCustomer(null))
        }
        dispatch(setSingleCusInfo(null))
        dispatch(setAggregator(null))
        dispatch(setAggregatorRef(null))
        dispatch(setOrderWaiter(null))
        setBillTypeOption("Dine In")
        dispatch(setBillType("DINEIN"))
    }
},[singleOrderInfo])

useEffect(() => {
    const handleClickOutside = (event) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target)
        ) {
            setShowAggregator(false);
        }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, []);

return (
        <div className="billing-left-first"
        style={{justifyContent:btnClicked &&"space-between",position:"inherit"}}
        >
          {
          !(btnClicked) &&
          <>
          <div
                className="new-billing-order-type-switch"
            >
                    <div
                        className={`switch-bill-type active`}
                        // onClick={onChangeBillType(option)}
                        style={{ paddingBottom:"0px"}}
                    >
                        <p readOnly style={{ margin: "0", fontSize: "0.9rem" }}>
                            {billTypeOption}
                        </p>
                    </div>
                
                
            </div>

            <div
                className="new-billing-order-type-switch switch-bill-type-hover"
            >
                {
                    switchTabs?.map((option) => (
                        <div
                            key={option}
                            className={`switch-bill-type ${billTypeOption === option ? 'active' : ''}`}
                            onClick={onChangeBillType(option)}
                            style={{paddingBottom:billTypeOption === option ?"3px":"7px"}}
                        >
                            <p readOnly style={{ margin: "0", fontSize: "0.8rem" }}>
                                {option}
                            </p>
                        </div>
                    ))
               }
            </div>

            {
                    showAggregator &&
                    <div className="aggregate-ctn" ref={menuRef}>
                        {
                            aggregatorList.length !== 0 ?
                                aggregatorList?.map((item, i) => (
                                    <div
                                        onClick={aggregatorSelected(item)}
                                        key={i}
                                        style={{ backgroundColor: (aggregator?._id === item?._id) ? "rgb(64 19 19)" : "#795547" }}
                                    >
                                        {item?.name}
                                    </div>
                                ))
                                :
                                <div>No Records !!</div>
                        }
                    </div>
                }
           </> }
            <div
                className="billing-left-secoundnav-container"
                style={{width:btnClicked&&"69%"}}
            >
                <button className="top-bar-buttons billing-btn-2">
                    <span
                        onClick={() =>{
                            dispatch(setDialogBox("addCustomer")) 
                            dispatch(setVisibleKeyboard(null))}}
                        className="circle-button justify-center">{customer===null?"+":<EditIcon/> }</span>
                    <div style={{ flex: "1" }}>
                        <CustomerListPopup/>
                        {/* <Autocomplete
                            freeSolo={keyBoardVisible==="customerKeyboard"}
                            open={keyBoardVisible==="customerKeyboard" || customerInputFocus}
                            ref={customerAutocompletRef}
                            options={customerList || []}
                            getOptionLabel={(option) => option?.name}
                            value={customer}
                            inputValue={customerTextfieldInput}
                            onInputChange={(e,newInputValue)=>customerTextfieldChange(newInputValue)}
                            filterOptions={filterOptions}
                            onChange={customerOnChange}
                            onFocus={onFocusCustomer}
                            onBlur={onBlurCustomer}
                            renderInput={(params) => (
                                <div className='justify-center'>
                                    <TextField 
                                    {...params} placeholder="Customer" />
                                    <IconButton
                                        onClick={()=>dispatch(setVisibleKeyboard(keyBoardVisible?null:"customerKeyboard"))}
                                    >
                                        <KeyboardIcon/>
                                    </IconButton>
                                </div>
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option?._id}>
                                  <span>{option?.name}</span>
                                </li>
                              )}
                        /> */}

                    </div>
                </button>
                <button
                    onClick={() =>buttonsOnClick("viewOrder")}
                    className="top-bar-buttons billing-btn-3"
                    style={{
                        backgroundColor:(btnClicked==="viewOrder")?"#fff":"rgb(21 128 145)",
                        color:(btnClicked==="viewOrder")?"rgb(4 221 255)":"#fff",
                    }}
                    >View Order</button>
                <button
                    style={{
                        backgroundColor:(btnClicked==="heldOrder")?"#fff":"#cc5200",
                        color:(btnClicked==="heldOrder")?"#cc5200":"#fff",
                        position: "relative"
                    }}
                    onClick={() =>buttonsOnClick("heldOrder")}
                    className="top-bar-buttons billing-btn-4">Ongoing Orders
                        {
                        heldOrders.length!==0&&
                        <span
                            style={{
                                padding: "2px 5px",
                                backgroundColor: "#fff",
                                marginLeft:"4px",
                                color:"#cc5200",
                                border:"1px solid #cc5200"
                            }}
                        >
                        {heldOrders.length}
                        </span>}
                </button>

                {
                    billTypeOption === "Dine In" &&
                    <button
                    style={{
                        backgroundColor:(btnClicked==="floorPlan")?"#fff":"rgb(0 6 193)",
                        color:(btnClicked==="floorPlan")?"rgb(6 0 255)":"#fff",
                        position: "relative"
                    }}
                    onClick={() =>buttonsOnClick(btnClicked==="tableChange"?"tableChange":"floorPlan")}
                        className="top-bar-buttons billing-btn-table">Tables
                    </button>}

                    <button
                    style={{
                        backgroundColor:(btnClicked==="reservation")?"#fff":"#0f3e01",
                        color:(btnClicked==="reservation")?"#0f3e01":"#fff",
                        position: "relative"
                    }}
                    onClick={() =>buttonsOnClick("reservation")}
                        className="top-bar-buttons billing-btn-table">
                        Reservation
                        {
                        reservationList && reservationList?.pendingCount!==0&&
                        <span
                            style={{
                                padding: "2px 5px",
                                backgroundColor: "#fff",
                                marginLeft:"4px",
                                color:"#0f3e01",
                                border:"1px solid #0f3e01"
                            }}
                        >
                        {reservationList?.pendingCount}
                        </span>}
                    </button>
            </div>

            <div className="billing-search-bar">
                <SearchIcon
                sx={{cursor:"pointer"}}
                className="billing-search-icon" />
                <input
                    type="text"
                    placeholder={`Search ${btnClicked===null?"products":"orders"} by name..`}
                    className="search-bar"
                    value={searchBarInput}
                    onChange={searchInputOnChange}
                    onFocus={(e)=>e.target.select()}
                />
            <IconButton
                onClick={() => dispatch(setVisibleKeyboard(keyBoardVisible ? null : "searchKeyboard"))}
            >
                <KeyboardIcon/>
            </IconButton>
            </div>


            {/* add customer */}
        <Dialog
            open={dialogOpen==="addCustomer"}
            onClose={closeCustomerDialog}
            maxWidth="fit-content"
        >
            <AddCustomerFromReservation setCustomerPopup={setAddCustomerDialog} />
        </Dialog>

        <Dialog 
        open={dialogOpen==="aggregatorRef"} 
        >
            <div className='wallet-change-dialog' >
                <div className="head justify-center">
                    <div className="icon justify-center" style={{backgroundColor:"#ffa50030"}}>
                        <DeliveryDiningIcon sx={{color:"#ff7201"}}/>
                    </div>
                    Add Refference ID
                </div>
                <div className="input-ctn">
                    <button disabled className="btn" style={{width:"80px",textAlign:"start"}}>Aggregator</button>
                    <input type="text"
                        readOnly
                        value={aggregator?.name}
                        style={{ textAlign: "start", paddingLeft: "10px" }}
                    />
                </div>
                <div className="input-ctn">
                    <button disabled className="btn" style={{width:"80px",textAlign:"start"}}>REF ID</button>
                    <input type="text"
                        value={aggregatorRef}
                        onChange={(e)=>{
                                         dispatch(setAggregatorRef(e.target.value))
                                         setAggregatorRefValidation(false)}}
                        style={{ textAlign: "start", paddingLeft: "10px" }}
                    />{
                        aggregatorRefValidation &&
                    <p className="doc-validation-alert" style={{margin:"0"}}>Refference is required !!</p>}
                </div>
                <div className="wallet-change-btn">
                    <button
                        onClick={onCloseAggReference}
                        className="btn-secondary-outlined btn">Cancel</button>
                    <button
                        style={{backgroundColor:"#ff7201"}}
                        className="btn-primary btn"
                        onClick={()=>(aggregatorRef===null || aggregatorRef==="")?setAggregatorRefValidation(true)  :dispatch(setDialogBox(null))}>Submit</button>
                </div>
            </div>
        </Dialog>
        {
            keyBoardVisible === "customerKeyboard" &&
            <div className="billing-keyboard-position" style={{zIndex:"1020"}}>
                <CustomeKeyBoard
                    input={customerTextfieldInput}
                    setInput={(value) => customerTextfieldChange(value)}
                    inputRef={customerAutocompletRef}
                />
            </div>
        }
        </div>
  )
}

export default TopBar