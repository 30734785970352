import { forwardRef, useEffect, useState } from "react";
import PrintTempOne from "../PrintTempOne";
import { useSelector } from "react-redux";
import PrintTempTwo from "../PrintTempTwo";
import PrintTempThree from "../PrintTempThree";

export const InvoiceContent = forwardRef((props, ref) => {
  const template = useSelector(
    (state) => state?.salesQuatationSlice?.setTemplate
  );
  // console.log("print-temp-model :", template);
  const { printData, taxDetails, qrData, invoiceDetails } = props;
  const invoiceData = {
    items: printData?.map((r, i) => ({ ...r, id: i + 1 })) || [],
  };

  const itemsPerPage = 15; //number of rows to fit on a page
  const pageCount = Math.ceil(invoiceData.items.length / itemsPerPage);

  // console.log("printData",printData)
  // console.log("invoiceDetails",invoiceDetails)


  return (
    <div ref={ref}>
      {Array.from({ length: pageCount + 1 }, (_, i) =>
        template?.templateModel === "template2" ? (
          <PrintTempOne
            key={i}
            pageNumber={i + 1}
            totalPages={pageCount + 1}
            invoiceData={invoiceData}
            isLastPage={i + 1 === pageCount + 1}
            items={invoiceData.items.slice(
              i * itemsPerPage,
              (i + 1) * itemsPerPage
            )}
            tableTotal={i + 1 == pageCount}
            allData={printData}
            taxDetails={taxDetails}
            qrData={qrData}
            invoiceDetails={invoiceDetails}
          />
        ) : template?.templateModel === "template1" ? (
          <PrintTempTwo
            key={i}
            pageNumber={i + 1}
            totalPages={pageCount + 1}
            invoiceData={invoiceData}
            isLastPage={i + 1 === pageCount + 1}
            items={invoiceData.items.slice(
              i * itemsPerPage,
              (i + 1) * itemsPerPage
            )}
            tableTotal={i + 1 == pageCount}
            allData={printData}
            taxDetails={taxDetails}
            qrData={qrData}
            invoiceDetails={invoiceDetails}
          />
        ) : template?.templateModel === "template3" ? (
          <PrintTempThree
            key={i}
            pageNumber={i + 1}
            totalPages={pageCount + 1}
            invoiceData={invoiceData}
            isLastPage={i + 1 === pageCount + 1}
            items={invoiceData.items.slice(
              i * itemsPerPage,
              (i + 1) * itemsPerPage
            )}
            tableTotal={i + 1 == pageCount}
            allData={printData}
            taxDetails={taxDetails}
            qrData={qrData}
            invoiceDetails={invoiceDetails}
          />
        ) : (
          ""
        )
      )}
    </div>
  );
});
