import React from "react";
import { useSelector } from "react-redux";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";


const SalesGraph = () => {
  const {mainDashboardData}=useSelector(state=>state.homeDashboardSlice)
  // console.log(mainDashboardData)
  return (
    <div className="home-dash-temp-cntr">
      <div className="home-dash-graph-head">
      <span>sales </span> <span>{mainDashboardData?.currency?.currencyCode||""}&nbsp;{mainDashboardData?.sales?.total||0}</span>
      </div>
   
      <ResponsiveContainer style={{padding:'0.5em'}}  minWidth={400} width="100%" height={200}>
        <AreaChart
          width={500}
          height={200}
          data={mainDashboardData?.sales?.graph||[]}
          syncId="anyId"
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" name="month" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="amount" stroke="#82ca9d" fill="#82ca9d" />
        </AreaChart>
      </ResponsiveContainer>

   
    </div>
  );
};

export default SalesGraph;
