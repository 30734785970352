import { createSlice } from "@reduxjs/toolkit";

export const ReportSlice=createSlice({
    name:'posReportshiftSlice',
    initialState:{
        shiftReport:undefined,
        shiftReportSale:undefined,
        shiftReportWalletPay:undefined,
        shiftReporPaymentSummary:undefined,
        salesReport:undefined,
        shiftReporPayment:undefined,
        cashCardReport:undefined,
        aggregatorReport:undefined,
        billTypeSummary:undefined,
        deliveryPartnerSummary:undefined,
        deliveryBoysSummary:undefined,
        discountSummary:undefined,
        categorySalesSummary:undefined,
        shiftReportDetails:undefined,
        shiftReportBranch:null,
        shiftReportShift:null,
        shiftBtnClicked:null,
        showSubHeaders:false,
        reportPrintSelected:null
    },
    reducers:{
        get_shift_report_list:(state,action)=>{
            state.shiftReport=action.payload
        },
        get_shift_report_sale:(state,action)=>{
            state.shiftReportSale=action.payload
        },
        get_shift_report_wallet_pay:(state,action)=>{
            state.shiftReportWalletPay=action.payload
        },
        get_shift_report_payment:(state,action)=>{
            state.shiftReporPayment=action.payload
        },
        get_shift_report_payment_summary:(state,action)=>{
            state.shiftReporPaymentSummary=action.payload
        },
        get_sales_details_list:(state,action)=>{
            state.salesReport=action.payload
        },
       //cash card summary
        get_cash_card_report:(state,action)=>{
             state.cashCardReport=action.payload
         },
         //aggregator
        get_aggregator_report:(state,action)=>{
             state.aggregatorReport=action.payload
         },
        get_bill_type_summary_report:(state,action)=>{
             state.billTypeSummary=action.payload
         },
        get_delivery_partner_summary_report:(state,action)=>{
             state.deliveryPartnerSummary=action.payload
         },
        get_delivery_boys_summary_report:(state,action)=>{
             state.deliveryBoysSummary=action.payload
         },
        get_discount_summary_report:(state,action)=>{
             state.discountSummary=action.payload
         },
        get_category_summary_report:(state,action)=>{
             state.categorySalesSummary=action.payload
         },
        get_shift_details_report:(state,action)=>{
             state.shiftReportDetails=action.payload
         },
         set_shift_report_branch:(state,action)=>{
            state.shiftReportBranch=action.payload
        },
        set_shift_report_shift:(state,action)=>{
            state.shiftReportShift=action.payload
        },
        set_shift_report_submit:(state,action)=>{
            state.shiftBtnClicked=action.payload
        },
        set_show_sub_headers:(state,action)=>{
            state.showSubHeaders=action.payload
        },
        set_shift_report_print:(state,action)=>{
            state.reportPrintSelected=action.payload
        },

    }

})
export const {get_shift_report_list,
    get_shift_report_sale,
    get_shift_report_wallet_pay,
     get_shift_report_payment_summary,
     get_sales_details_list,
     get_shift_report_payment,
     get_aggregator_report,
     get_cash_card_report,
     get_bill_type_summary_report,
     get_delivery_partner_summary_report,
     get_delivery_boys_summary_report,
     get_discount_summary_report,
     get_category_summary_report,
     get_shift_details_report,
     set_shift_report_branch,
     set_shift_report_shift,
     set_shift_report_submit,
     set_show_sub_headers,
     set_shift_report_print,
    }=ReportSlice.actions
export default ReportSlice.reducer