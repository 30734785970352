import {IconButton, Skeleton, Tooltip } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { GRNFilterAPICall, getGRNpoViewAPICall} from "./grnListingAPI";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import store from "../../../../../Redux/store";
import {get_grn_list_row_data_list } from "./grnListingSlice";
import { convertDateFormat} from "../../../../../Js/Date";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import axios from "axios";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs"
import TableListActionButtons from "../../../../Single Components/ActionButtons/TableListActionButtons";

export const GrnListing1 = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  let navigateTo = useNavigate();
  const [path, setPath] = useState(false);

  const [filteredData, setFilteredData] = useState({});
  const tableList = useSelector(
    (state) => state.goodsReceivedNoteUpdatedSlice.apiResList
  );
  const grnList = useSelector((state) => state.goodsReceivedNoteUpdatedSlice.grnViewList)


  const [filteredArray, setFilteredArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const GRNpoId = localStorage.getItem("singleViewByIdgrn")
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = filteredArray?.pages;

  const [selectedHeading, setSelectedHeading] = useState("GRN");
  const [selectedBranch, setSelectedBranch] = useState(null);

  const handleClickRow = (row) => {
    localStorage.removeItem("singleViewPoById");
    localStorage.setItem("singleViewById", row?._id);
    localStorage.setItem("sequence", row?.purchaseOrderName)
    store.dispatch( setFilterActive(window.location.pathname))
    store.dispatch(get_grn_list_row_data_list({ grnListSingleRowData: row }));
    navigateTo("/userdashboard/purchaseManager/GoodsReceivedNote/SingleView")
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  useEffect(() => {
    if (GRNpoId !== null) {
      grnList !== undefined && setFilteredArray(grnList)
      setPath(true)
    }
    else {
      setPath(false)
      localStorage.removeItem("singleViewById");
      GRNpoId === null && setFilteredArray(tableList);
    }

  }, [grnList, tableList])

  useEffect(() => {
    if (GRNpoId) {
      getGRNpoViewAPICall({ purchaseId: GRNpoId }, setIsLoading);

    } else {
     // viewGrnUpdatedListAPICall({ branchId: branchId === "null" ? null : branchId }, setIsLoading);

    }
  }, [GRNpoId]);

  const changeColor = (heading) => {
    setSelectedHeading(heading);
  };


  const handleBackpage = () => {
    navigateTo("/userdashboard/purchaseManager/NewPurchaseOrder")
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const formatFilterInfo = (filters) => {
    const parts = [];
  
    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    }
   
    if (filters?.status) {
      parts.push(`Status: ${filters?.status}`);
    }
    if (filters?.location) {
      parts.push(`location: ${filters?.location?.name}`);
    }
   
    
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Goods Received note");
    sheet.properties.defaultRowHeight = 20;
  
    // Add header section
    const titleRow = sheet.addRow(["Goods Received note"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells('A1:H1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];
  
    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:H${index + 2}`);
        row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      });
    }
  
    // Add an empty row between header and table
    sheet.addRow([]);
  
    // Header row styling
    const headerRow = sheet.addRow(["No", "Name","Date","Branch","Location","Vendor","Created By","Status"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };
  
    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    })
  
    sheet.columns = [
      { header: "No", key: "No", width: 8 },
      { header: "Name", key: "name", width: 15 },
      { header: "Date", key: "date", width: 25 },
      { header: "Branch", key: "branchId", width: 15 },
      { header: "Location", key: "locationName", width: 25 },
      { header: "Vendor", key: "supplierName", width: 15 },
      { header: "Created By", key: "CREATEDBY", width: 15 },
      { header: "Goods Received Note", key: "status", width: 15 },
  
    ];
  
    filteredArray?.list?.forEach((item,index) => {
      const row = sheet.addRow({
        No: index+1,
        name: item.name,
        date:convertDateFormat (item?.date),
        branchId: item?.branchId,
        locationName: item?.locationName,
        supplierName: item?.supplierName,
        CREATEDBY: item?.CREATEDBY,
        status: item?.status,
      
      });
  
      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };
      
      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
        //   top: { style: "thin", color: { argb: "FF000000" } },
        //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
        //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) { // Right align all columns except the first
            cell.alignment = { horizontal: 'center' };
          }
         
      });
    });
  
    // Add row for totals
    // const totalRow = sheet.addRow([
    //   "Total",
    //   "",
    //   `${symbol} ${SingleListData?.sumOfCurrent.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf30?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf60?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf90?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf120?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOfAbove120?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOfTotal?.toFixed(2)}`,
    // ]);
  
    // totalRow.eachCell((cell,colNumber) => {
    //   cell.font = { bold: true, color: { argb: "FF000000" } };
    //   cell.fill = headerFill;
    //   cell.border = {
    //     top: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     left: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     right: { style: "thin", color: { argb: "FFFFFFFF" } },
    //   };
    //   if (colNumber > 1) { // Right align all columns except the first
    //     cell.alignment = { horizontal: 'right' };
    //   }
    // });
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Goods Received Note.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    // const source = axios.CancelToken.source();
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&
      GRNFilterAPICall({
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        location: FormDataInfo.location,
        branchList: FormDataInfo.branchList,
        status: FormDataInfo.status,
        search: FormDataInfo.search,
        index: currentPage - 1,
      }, setIsLoading);
    };
    // return () => {
    //   source.cancel('Request canceled by cleanup');
    // };
  }, [FormDataInfo, currentPage]);

  return (
    <>
      <div className="global-page-parent-container updated-global-page-parent-container">
          {path && (
            <div className="first-main-container-div">
              <div className="first-main-heading-div" id="headingDiv">
                <p
                  style={{
                    marginLeft: "14px",
                    color: selectedHeading === "Purchase Order" ? "black" : "gray",
                  }}
                  onClick={() => { changeColor("Purchase Order"); handleBackpage() }}
                >
                  Purchase Order
                </p>
                <p
                  style={{
                    color: selectedHeading === "GRN" ? "black" : "gray",
                  }}
                >
                  GRN
                </p>

              </div>
            </div>
          )}
          <div className="justify-space-between list-create-head-bar">
            <h3>Goods Received Note</h3>
            <div className="create-head-buttons-container">
              <TableListActionButtons downloadClick={downloadList}/>
              <CategorySearchandFilter
              statusOptions={['Complete', 'Drafted', 'Returned']}
              onData={handleDataFromChild}
              isLocation={true}
              isStatus={true}
              setFormDataInfo={setFormDataInfo}
              onBranchSelect={handleBranchSelect}
              onFilterChange={handleFilterChange}
            />
            </div>
          </div>
     
        <div className="justify-space-between pagination-container">
            {FormDataInfo?.branchList?.length > 0 && (
                <h4>Branch name: {selectedBranch?.storeCode}-{selectedBranch?.branchName}</h4>
            )}
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        <div className="new-global-table-container updated-table-container">
       

          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Date</th>
                <th>Branch</th>
                <th>Location</th>
                <th>Vendor</th>
                <th>Created By</th>
                <th>Status</th>

              </tr>
            </thead>
            <tbody>
              {isLoading === true ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={9}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : filteredArray?.list?.length !== 0 ? (
                filteredArray?.list
                  ?.slice(0)
                  
                  ?.map((r, i) => (
                    <tr onClick={() => handleClickRow(r)} key={i}>
                      <td>{i + 1}</td>
                      <td>{r?.name}</td>
                      <td>{convertDateFormat(r?.date)}</td>
                      <td>{r?.branchId}</td>
                      <td>{r?.locationName}</td>
                      <td>{r?.supplierName}</td>
                      <td>{r?.CREATEDBY}</td>
                      <td className="table-data-box">
                        {r?.status === "POSTED" ? (
                          <span className="post"> POSTED</span>
                        ) : r?.status === "DRAFTED" 
                                         
                         ? (
                          <span className="draft"> DRAFTED</span>
                        ) : r?.status === "Returned" ? (
                          <span className="return">RETURN</span>
                        ) : (
                          "---"
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={7}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
