import axios from "axios";
import { HEADERS } from "../../../../API/UrlAndPaths";
import store from "../../../../Redux/store";
import { setPosReceiptSlice } from "./posReceiptSlice";
import { setErrorSnack, setSnackMsg, setSuccessSnack } from "../../Sales/Billing/Slice/billingScreenSlice";
import { setSubmitLoading } from "../../../../Redux/Dashboard/mainDashBoardSlice";

//get select template info
export const getTemplateInfoAPI = async () => {
  try {
    const result = await axios.post(
      "admin/getPosReceiptTemplateModel",
      {},
      HEADERS
    );
    if (result.status === 200) {
      store.dispatch(setPosReceiptSlice(result.data));
    }
  } catch (error) {
   store.dispatch(setPosReceiptSlice(null));

    console.log(error);
  }
};
//post  select pos receipt template data to server
export const setPosReceiptTemplateAPI = async (data) => {
  store.dispatch(setSubmitLoading(true))
  try {
    const result = await axios.post(
      "admin/setPosReceiptTemplateModel",
      data.body,
      HEADERS
    );
    if (result.status === 200) {
      store.dispatch(setSubmitLoading(false))
      store.dispatch(setSnackMsg(`Changed to Template ${result.data?.posReceiptTemplate}`))
      store.dispatch(setSuccessSnack(true))
      store.dispatch(setPosReceiptSlice(result.data));
    }
  } catch (error) {
    store.dispatch(setSubmitLoading(false))
    store.dispatch(setSnackMsg(error?.response.status===500?"Internal server error !!":error?.response?.data))
    store.dispatch(setErrorSnack(true))
  }
};
