import React from 'react'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";

const TopCreditCustomers = () => {
    const {mainDashboardData}=useSelector(state=>state.homeDashboardSlice)
  return (
    <div className="home-dash-temp-cntr">
    <div className="home-dash-graph-head">
      <span>Top Credit Customers</span>
    </div>
    {/* <TableContainer component={Paper}> */}
    <Table sx={{ minWidth: 400 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="right">Orders</TableCell>
          <TableCell align="right">Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {mainDashboardData?.TopCreditCustomers?.map((row,i) => (
          <TableRow
            key={i}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell align="left">{row?.name}</TableCell>
            <TableCell align="right">
              {row?.count}
            </TableCell>
            <TableCell align="right">{row?.amount}</TableCell>
    
          </TableRow>
        ))}
      </TableBody>
    </Table>
    {/* </TableContainer> */}
  </div>
  )
}

export default TopCreditCustomers