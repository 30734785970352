import React, { useEffect, useRef, useState } from 'react'
import ReplyIcon from '@mui/icons-material/Reply';
import ReactToPrint from 'react-to-print';
import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material';
import SalesTable from "./PosShiftReportTables/SalesTable"
import Payment from "./PosShiftReportTables/Payment"
import Wallet from "./PosShiftReportTables/Wallet"
import ProductSummary from "./PosShiftReportTables/ProductSummary"
import PaymentSummary from "./PosShiftReportTables/PaymentSummary"
import icon from "../../../../Assets/Images/Icon ionic-ios-share-alt.png"
import { viewAllAllowedBranchesAPICall, viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { useSelector } from 'react-redux';
import { ListPosPaymentSummaryAPI, ListPosShifReportAPI, ListPosShifReportSalesAPI, ListPosShifReportWalletAPI } from './ReportAPI';
import { viewShiftAPICall } from '../../../../API/Settings/Shift/viewShiftAPI';
import { viewPosSettingsAPICall } from '../Configuration/Settings/posSettingsAPI';
import store from '../../../../Redux/store';
import { useDispatch } from 'react-redux';
import { get_shift_report_list, get_shift_report_payment, get_shift_report_payment_summary, get_shift_report_sale, get_shift_report_wallet_pay } from './ReportSlice';
import Template from "./PosShiftReportPrint"
import ExcelJS from "exceljs"

const PosShiftReport = () => {
    let paymentsPrint = useRef();

    const dispatch = useDispatch();

    // console.log(branch);
    const allBranchesList = useSelector(
        (state) => state.allBranchesSlice.value);
    const shiftSalesReport = useSelector(
        (state) => state.posReportshiftSlice.shiftReportSale);

    const shiftReportpayment = useSelector(
        (state) => state.posReportshiftSlice.shiftReporPayment);

    const paymentSummmary = useSelector(
        (state) => state.posReportshiftSlice.shiftReporPaymentSummary);

    const productSummmary = useSelector(
        (state) => state.posReportshiftSlice.shiftReport);

    const shiftReportwallet = useSelector(
        (state) => state.posReportshiftSlice.shiftReportWalletPay);
    const posSettingsList = useSelector((state) => state.posSettingSlice.apiResList);

    const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);

    const userRole = useSelector((state) => state.userRoleSlice.value)
    const userInfo = useSelector((state) => state.loginResponseSlice.value);

    const allShiftList = useSelector((state) => state.viewShiftSlice.value);
    // console.log(allShiftList);

    // const userRole = useSelector((state) => state.userRoleSlice.value);
    const [selectedButton, setSelectedButton] = useState("sales");
    const [branch, setBranch] = useState(null);
    const [shift, setShift] = useState(null);
    const [shiftDetails, setShiftDetails] = useState()

    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
        if (buttonName === "payment") {
            setShiftDetails(shiftReportpayment)
        }
        else if (buttonName === "wallet") {
            setShiftDetails(shiftReportwallet)
        }
        else if (buttonName === "product Summary") {
            setShiftDetails(productSummmary)
        }
        else {
            setShiftDetails(paymentSummmary)
        }
    };

    const formatDate = (dateTimeString) => {
        return dateTimeString?.split(' ')[0]; // Split the string by space and return the first part (the date)
    };


    const handleSubmit = () => {
        let body = {
            branchId: branch?._id,
            shiftId: shift?._id,
            index: 0
        }
        let wallet = {
            branchId: branch?._id,
            shiftId: shift?._id,
            index: 0,
            isWallet: true
        }
        let payment = {
            branchId: branch?._id,
            shiftId: shift?._id,
            index: 0,
            isWallet: false
        }
        ListPosShifReportSalesAPI(body,setShiftDetails)
        ListPosShifReportAPI(body)
        ListPosShifReportWalletAPI(wallet)
        ListPosShifReportWalletAPI(payment)
        ListPosPaymentSummaryAPI(body)
    }

    const branchOnchange = (e, newValue) => {
        setBranch(newValue)
        setShift(null)
        viewShiftAPICall({ branchId: newValue?._id });
    }


    const generateExcelReport = async () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('Shift Report');
        sheet.properties.defaultRowHeight = 20;
    
        // Add header section
        const titleRow = sheet.addRow(['Shift Report']);
        titleRow.font = { size: 18, bold: true };
        sheet.mergeCells('A1:E1');
        titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    
        // Add shift details
        if (shiftDetails) {
            const shiftIdRow = sheet.addRow([`Shift ID: ${shiftDetails?.shiftData?.shiftId || '-NIL-'}`]);
            shiftIdRow.font = { size: 15, bold: true };
            sheet.mergeCells(`A${sheet.lastRow.number}:E${sheet.lastRow.number}`);
            shiftIdRow.eachCell((cell) => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF8F8F8' } };
            });
    
            const startByRow = sheet.addRow([
                `Start by: ${shiftDetails?.shiftData?.startedBy || ''}`,
                '',
                '',
                `End by: ${shiftDetails?.shiftData?.endedBy || ''}`
            ]);
            startByRow.font = { size: 12, bold: false };
            sheet.mergeCells(`A${sheet.lastRow.number}:C${sheet.lastRow.number}`);
            sheet.mergeCells(`D${sheet.lastRow.number}:E${sheet.lastRow.number}`);
            startByRow.eachCell((cell) => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF8F8F8' } };
            });
    
            const startDateRow = sheet.addRow([
                `Start Date: ${shiftDetails?.shiftData?.startDate || ''}`,
                '',
                '',
                `End Date: ${shiftDetails?.shiftData?.endDate || ''}`
            ]);
            startDateRow.font = { size: 12, bold: false };
            sheet.mergeCells(`A${sheet.lastRow.number}:C${sheet.lastRow.number}`);
            sheet.mergeCells(`D${sheet.lastRow.number}:E${sheet.lastRow.number}`);
            startDateRow.eachCell((cell) => {
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF8F8F8' } };
            });
    
            // Add table headers
            const headerRow = sheet.addRow(['Order Date', 'Order Ref', 'Customer', 'Mobile', 'Order Amount']);
            headerRow.font = { size: 12, bold: true };
            headerRow.eachCell((cell) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFEDF2F9' } };
            });
    
            // Add data rows for Sales
            if (Array.isArray(shiftSalesReport.sale)) {
                shiftSalesReport.sale.forEach(order => {
                    sheet.addRow([
                        order.orderDate,
                        order.name,
                        order.customerName,
                        order.custMobile,
                        order.amtTotal
                    ]);
                });
            }
    
            // Add data rows for Wallet
            if (Array.isArray(shiftReportwallet)) {
                shiftReportwallet.forEach(wallet => {
                    sheet.addRow([
                        wallet.orderDate,
                        wallet.name,
                        wallet.customerName,
                        wallet.custMobile,
                        wallet.amtTotal
                    ]);
                });
            }
    
            // Add data rows for Product Summary
            if (Array.isArray(productSummmary)) {
                productSummmary.forEach(product => {
                    sheet.addRow([
                        product.productName,
                        product.quantity,
                        product.amount
                    ]);
                });
            }
    
            // Add data rows for Payment Summary
            if (Array.isArray(paymentSummmary)) {
                paymentSummmary.forEach(payment => {
                    sheet.addRow([
                        payment.paymentType,
                        payment.amount
                    ]);
                });
            }
    
            // Add data rows for Payment Report
            if (Array.isArray(shiftReportpayment)) {
                shiftReportpayment.forEach(payment => {
                    sheet.addRow([
                        payment.date,
                        payment.amount
                    ]);
                });
            }
    
            // Add total row
            const totalRow = sheet.addRow(['', '', '', 'TOTAL', (Array.isArray(shiftSalesReport.sale) ? shiftSalesReport.sale.reduce((sum, order) => sum + order.amtTotal, 0) : 0)]);
            totalRow.font = { size: 12, bold: true };
            totalRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFDFD9D9' } };
            totalRow.eachCell((cell) => {
                cell.alignment = { vertical: 'middle', horizontal: 'right' };
            });
    
            // Add grand total row
            const grandTotalRow = sheet.addRow(['', '', '', 'GRAND TOTAL', (Array.isArray(shiftSalesReport.sale) ? shiftSalesReport.sale.reduce((sum, order) => sum + order.amtTotal, 0) : 0)]);
            grandTotalRow.font = { size: 12, bold: true };
            grandTotalRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFDFD9D9' } };
            grandTotalRow.eachCell((cell) => {
                cell.alignment = { vertical: 'middle', horizontal: 'right' };
            });
        }
    
        // Write to buffer and trigger download
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'PosShiftReport.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    };
    
    
    // console.log(branch);
    useEffect(() => {
       
        userRole==="user"&& viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });

        viewAllBranchesAPICall();
        viewPosSettingsAPICall()
        store.dispatch(get_shift_report_sale(undefined))
        store.dispatch(get_shift_report_list(undefined))
        store.dispatch(get_shift_report_payment(undefined));
        store.dispatch(get_shift_report_wallet_pay(undefined));
        store.dispatch( get_shift_report_payment_summary(undefined))

    }, [])
    return (
        <div className="global-page-parent-container">
            <div className="global-white-bg-container Header" style={{ display: "flex", justifyContent: "space-between", padding: "0px 30px 0 25px", alignItems: "center" }}>
                <p>Shift Report</p>
                <div>
                    {/* <div className="ribbon-ctn">
                 <img src={icon} alt='' />
                        </div> */}

                    <ReactToPrint
                        trigger={() => (
                            <IconButton className="printer-div" style={{ padding: "1px" }}>
                                <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
                            </IconButton>
                        )}
                        content={() => paymentsPrint}
                    />

                    <Tooltip title="Download">
                        <IconButton onClick={generateExcelReport}>
                            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div className="global-white-bg-container " style={{ display: "flex", alignItems: "center" }}>
                <div className="new-global-single-input auto-complete-new" style={{ width: "26%", paddingInline: "20px" }}>
                    <Autocomplete
                        disablePortal
                        options={userRole === "admin" ? allBranchesList : allowedBranchList || []}
                        getOptionLabel={(option) => option?.branchName}
                        placeholder='Branch'
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                            <TextField {...params} label="Branch" focused placeholder='' />
                        )}
                        value={branch}
                        onChange={branchOnchange}
                    //   disabled={formValues?.isEdit}
                    />
                </div>
                <div className="new-global-single-input auto-complete-new" style={{ width: "28%", padding: "0 55px 0 14px" }}>
                    <Autocomplete
                        disablePortal
                        options={(allShiftList?.filter(obj => obj.branchId === branch?._id) || []).reverse()}
                        getOptionLabel={(option) => option?.SHIFID}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                            <TextField {...params} label="Shift" focused placeholder='' />
                        )}
                        value={shift}
                        onChange={(e, newValue) => setShift(newValue)}
                    //   disabled={formValues?.isEdit}
                    />
                </div>
                <button className='create-button-blue' onClick={handleSubmit}>Submit</button>
                {(shiftSalesReport !== undefined || shiftReportwallet !== undefined || productSummmary !== undefined || paymentSummmary !== undefined || shiftReportpayment !== undefined) && (
                   
                    <div className="single-view-left-ctn" style={{ width: "60%", minWidth: "fit-content", maxWidth: "550px", padding: "16px", margin: "1%", marginBottom: "0"}}>
                    <h3 style={{ margin: "0", cursor: "pointer", fontSize: "larger" }}>{shiftDetails?.shiftData?.shiftId}</h3>
                    <div className="justify-space-between" style={{ display: "flex" }}>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <div className="single-view-info-ctn-left flex-column">
                            {(shiftDetails?.shiftData?.startedBy !== "" || shiftDetails?.shiftData?.startedBy !== null) && (
                                <p style={{ margin: "1px" }}>Shift by:</p>)}
                            {(shiftDetails?.shiftData?.startDate !== "" || shiftDetails?.shiftData?.startDate !== null) && (
                                <p style={{ margin: "1px" }}>Start Date</p>)}
                            </div>
                            <div className="single-view-info-ctn-right flex-column">
                                <p style={{ margin: "1px" }}>{formatDate(shiftDetails?.shiftData?.startedBy)} </p>
                                <p style={{ margin: "1px" }}>{formatDate(shiftDetails?.shiftData?.startDate)}</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <div className="single-view-info-ctn-left flex-column">
                            {(shiftDetails?.shiftData?.endedBy !== "" || shiftDetails?.shiftData?.endedBy !== null) && (
                                <p style={{ margin: "1px" }}>End by:</p>)}
                                {(shiftDetails?.shiftData?.endDate !== "" || shiftDetails?.shiftData?.endDate !== null) && (
                                <p style={{ margin: "1px" }}>End Date</p>)}
                            </div>
                            <div className="single-view-info-ctn-right flex-column">
                                <p style={{ margin: "1px" }}>{formatDate(shiftDetails?.shiftData?.endedBy)}</p>
                                <p style={{ margin: "1px" }}>{formatDate(shiftDetails?.shiftData?.endDate)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                )}
            </div>
            {(shiftSalesReport !== undefined || shiftReportwallet !== undefined || productSummmary !== undefined || paymentSummmary !== undefined || shiftReportpayment !== undefined) && (
                <div style={{ marginTop: "8px", backgroundColor: "white", height: "74vh" }} className='flex-column'>


                    <div className="button-div" style={{ padding: "20px 0 0 17px" }}>


                        <button
                            className={selectedButton === 'sales' ? 'selected-button' : ''}
                            onClick={() => handleButtonClick('sales')}
                        >
                            SALES
                        </button>

                        <button
                            className={selectedButton === 'payment' ? 'selected-button' : ''}
                            onClick={() => handleButtonClick('payment')}
                        >
                            PAYMENT
                        </button>

                        {posSettingsList?.activeWalletStatus === true && (
                            <button
                                className={selectedButton === 'wallet' ? 'selected-button' : ''}
                                onClick={() => handleButtonClick('wallet')}
                            >
                                WALLET
                            </button>
                        )}
                        <button
                            className={selectedButton === 'product Summary' ? 'selected-button' : ''}
                            onClick={() => handleButtonClick('product Summary')}
                        >
                            PRODUCT SUMMARY
                        </button>
                        <button
                            className={selectedButton === 'payment summary' ? 'selected-button' : ''}
                            onClick={() => handleButtonClick('payment summary')}
                        >
                            PAYMENT SUMMARY
                        </button>

                    </div>
                    {selectedButton === "sales" && (
                        <SalesTable />
                    )}
                    {selectedButton === "payment" && (
                        <Payment />
                    )}
                    {selectedButton === "wallet" && (
                        <Wallet />
                    )}
                    {selectedButton === "product Summary" && (
                        <ProductSummary />
                    )}
                    {selectedButton === "payment summary" && (
                        <PaymentSummary />
                    )}

                </div>
            )}

            <div style={{ display: "none" }}>
                <div ref={(el) => (paymentsPrint = el)}>
                    <Template branch={branch} shift={shift} />
                </div>
            </div>
        </div>
    )
}

export default PosShiftReport