import React, { useEffect, useRef, useState } from "react";
import BillTypeSummary from "./BillTypeSummary";
import DelePartnerSummary from "./DelePartnerSummary";
import DeliveryBoysSumm from "./DeliveryBoysSumm";
import DiscountSummary from "./DiscountSummary";
import CategorySummary from "./CategorySummary";
import { 
  ListPosShifReportBillTypeSummary,
  ListPosShifReportCategorySalesSummary,
  ListPosShifReportDeleBoysSummary,
  ListPosShifReportDelePartnerSummary,
  ListPosShifReportDetails,
  ListPosShifReportDiscountSummary,
} from "../ReportAPI";
import { useDispatch, useSelector } from "react-redux";
import { set_shift_report_submit, set_show_sub_headers } from "../ReportSlice";
import { convertToAMPM } from "../../../../../Js/Date";
import receiptIcon from "../../../../../Assets/Images/printReceiptIcon.svg"
import { IconButton } from "@mui/material";
import AdvanceShiftReportPrintComponent from "../AdvancedShiftReportPRint/AdvanceShiftReportPrintComponent";
import { useReactToPrint } from "react-to-print";
function ShiftReportTable() {

  const dispatch=useDispatch()
  const {shiftReportBranch,shiftReportShift,shiftBtnClicked,showSubHeaders}=useSelector((state)=>state.posReportshiftSlice)
  
  const buttonsArray=["BILL TYPE","DELIVERY PARTNERS","DELIVERY BOYS","DISCOUNT","CATEGORY/SALES"]
  const [selectedButton, setSelectedButton] = useState("BILL TYPE");

  const printRef=useRef()

  const shiftDetails = useSelector(
    (state) => state.posReportshiftSlice.shiftReportDetails
  );


  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const handleCheckboxChange = (event) => {
    dispatch(set_show_sub_headers(event.target.checked))
  };

  const handlePrintKot=useReactToPrint({
    content: () => printRef.current,
  })

  useEffect(()=>{
    if(shiftBtnClicked==="submit"){

      let body = {
        branchId: shiftReportBranch?._id,
        shiftId: shiftReportShift?._id,
      };

      ListPosShifReportBillTypeSummary(body);
      ListPosShifReportDelePartnerSummary(body);
      ListPosShifReportDiscountSummary(body);
      ListPosShifReportDeleBoysSummary(body);
      ListPosShifReportCategorySalesSummary(body);
      ListPosShifReportDetails(body);

      dispatch(set_shift_report_submit(null))

    }
  },[shiftBtnClicked])
  return (
    shiftDetails&&
    <div
      style={{ marginTop: "8px", backgroundColor: "white", height: "74vh" }}
      className="flex-column advance-shift-report-table-container"
    >
      <div
        className="single-view-left-ctn"
        style={{
          width: "60%",
          minWidth: "fit-content",
          maxWidth: "550px",
          padding: "16px",
          margin: "1%",
          marginBottom: "0",
          backgroundColor: "#fcfafa",
        }}
      >
        <h3 style={{ margin: "0", cursor: "pointer", fontSize: "larger" }}>
          {shiftDetails?.branchName}
        </h3>
        <div className="justify-space-between" style={{ display: "flex" }}>
          <div style={{ display: "flex", gap: "10px" }}>
            <div className="single-view-info-ctn-left flex-column">
              <p style={{ margin: "1px" }}>Shift</p>

              <p style={{ margin: "1px" }}>Employee</p>
            </div>
            <div className="single-view-info-ctn-right flex-column">
              <p style={{ margin: "1px" }}> {shiftDetails?.shiftId} </p>
              <p style={{ margin: "1px" }}>{shiftDetails?.employeeName}</p>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <div className="single-view-info-ctn-left flex-column">
              <p style={{ margin: "1px" }}>Start Date</p>

              <p style={{ margin: "1px" }}>End Date</p>
            </div>
            <div className="single-view-info-ctn-right flex-column">
              <p style={{ margin: "1px" }}>{shiftDetails?.startDate}: {convertToAMPM(shiftDetails?.startTime)}</p>
              {
                shiftDetails?.endDate ?
                <p style={{ margin: "1px" }}>{shiftDetails?.endDate}: {convertToAMPM(shiftDetails?.endTime)} </p>
                :<p style={{ margin: "1px" }}>Active Shift </p>}
            </div>
          </div>
        </div>
      </div>
      {/* <h4 style={{paddingLeft:"20px",margin:"0",marginTop:"5px",fontWeight:"400"}}>Shift Report Summary</h4> */}
      <div
        className="shiftButton-div button"
        style={{ padding: "0px 0 0 17px" }}
      >
        {
          buttonsArray.map((button,i)=>(
          <button
          className={
            `${selectedButton === button ? "selected-button" : ""} tab-btn`
          }
          onClick={() => handleButtonClick(button)}
          >
          {button}
          {
        selectedButton === button &&
          <IconButton onClick={handlePrintKot} title="Print">
            <img src={receiptIcon} alt="print receipt" width="20" height="20" />
          </IconButton>
          }
        </button>

          ))
        }
       

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
            gap: "10px",
            padding: "1%",
          }}
        >
          <label htmlFor="detailsCheckBox" style={{ fontSize: "14px",cursor:"pointer",userSelect: "none"}}>Details</label>
          <input
            id="detailsCheckBox"
            type="checkbox"
            className="toggle-checkbox"
            checked={showSubHeaders}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>
      {selectedButton === "BILL TYPE" && (
        <BillTypeSummary showSubHeaders={showSubHeaders} />
      )}
      {selectedButton === "DELIVERY PARTNERS" && (
        <DelePartnerSummary showSubHeaders={showSubHeaders} />
      )}
      {selectedButton === "DELIVERY BOYS" && (
        <DeliveryBoysSumm showSubHeaders={showSubHeaders} />
      )}
      {selectedButton === "DISCOUNT" && (
        <DiscountSummary showSubHeaders={showSubHeaders} />
      )}
      {selectedButton === "CATEGORY/SALES" && (
        <CategorySummary showSubHeaders={showSubHeaders} />
      )}

      {
        <div className="print-parent">
          <div ref={printRef}>
              <AdvanceShiftReportPrintComponent selectedButton={selectedButton}/>
            </div>
        </div>
      }
    </div>
  );
}

export default ShiftReportTable;
