import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../css/Single Components/addOutlet.css";
import { Select, Divider, Dialog, IconButton, MenuItem, Autocomplete, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import countryWithState from "../../Assets/JSON/countryStates.json"
import {
  viewAllLocationsAPICall,
  addNewLocationsAPICall,
  addNewOutletAPICall,
  viewCompanyProfileInfoAPICall,
  editOutletAPICall,
} from "../../API/Settings/Company Settings/companySettingsAPI";
import SuccessSnackbar from "./SnackBars/SuccessSnackbar";
import ErrorSnackbar from "./SnackBars/ErrorSnackbar";
import { FilePicker } from "./filePicker";
export const AddOutlet = (props) => {
  let currentCountry = localStorage.getItem("country");
  const { cancelDialog } = props;
  //Company Profile Data
  const companyProfileData = useSelector(
    (state) => state.companyProfileSlice.value
  );
  //Company Locations Data
  const companyLocationseData = useSelector(
    (state) => state.allLocationsSlice.value
  );

  //Branch List
  const companyBranchList = useSelector(
    (state) => state.allBranchesSlice.value
  );

  //Update Listener States
  const [isModify, setIsModify] = useState(false);
  //AdLocation Popup State
  const [addLocation, setAddLocation] = useState(false);
  //New Location Name State
  const [newLocationName, setNewLocationName] = useState("");
  //Snackbar States
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);

  const [filteredCompanyList,setFilteredCompanyList]=useState([])
  const [searchKeyword,setSearchKeyword]=useState("")

  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [snackMsg, setSnackMsg] = useState("something went wrong!!");
  //Extensible Branch Response
  const [branchResExtensible, setBranchResExtensible] = useState([]);
  //Add Outlet Form
  const addOutletFormInitialState = {
    country: null,
    state:null,
    location: "0",
    branchName: "",
    storeName: "",
    storeNameNative: "",
    contactNo: "",
    TRN: "",
    email: "",
    contactName: "",
    address: "",
    addressNative: "",
  };
  const [outletLogo, setOutletLogo] = useState(null);
  const [addOutletForm, setAddOutletForm] = useState(addOutletFormInitialState);
  //Determine Form in edit stage or not
  const [isEdit,setIsEdit]=useState(false)
  //Editing branch id
  const [editingBranchId,setEditingBranchId]=useState(null)
  const [stateList,setStateList]=useState(false)
  //Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //Make Branch list Extensible
  const makeBranchListExtensible = (branchRes) => {
    if (branchRes !== undefined) {
      let extensibleBranch = branchRes.map((branch) => {
        return { ...branch, isEdit: false };
      });
      console.log(extensibleBranch);
      setBranchResExtensible(extensibleBranch);
    } 
  };

  //Click Edit Branch button
  const clickEditBranchBtn = (branch) =>()=> {
    // branch.isEdit = !branch.isEdit;
    // setBranchResExtensible([...branchResExtensible])
    setIsEdit(true)
    //Assign Form States as edit data
    setEditingBranchId(branch._id)
    setAddOutletForm({
      ...addOutletForm,
      country:branch.country?.toLowerCase(),
      location:branch.locationId,
      branchName:branch.branchName,
      storeName:branch.nameOfStore,
      storeNameNative:branch.nativenameOfStore,
      contactNo:branch.contactNumber,
      TRN:branch.trnNumber,
      email:branch.email,
      contactName:branch.contactPerson,
      address:branch.address,
      addressNative:branch.nativeAddress
    })
    setOutletLogo(branch.logo)
  };

  //Edit Cancel btn
  const editCancelBtnClick=()=>{
    setAddOutletForm(addOutletFormInitialState)
    setOutletLogo(companyProfileData?.profile?.logo)
    setIsEdit(false)
    setEditingBranchId(null)
  }


  //Edit Branch button click
  const branchEditFormData=new FormData()
  branchEditFormData.append('branchId',editingBranchId)
  branchEditFormData.append('logo',outletLogo)
  branchEditFormData.append('nativeName',addOutletForm.storeNameNative)
  branchEditFormData.append('contactNumber',addOutletForm.contactNo)
  branchEditFormData.append('trnNumber',addOutletForm.TRN)
  branchEditFormData.append('email',addOutletForm.email)
  branchEditFormData.append('contactPerson',addOutletForm.contactName)
  branchEditFormData.append('storeName',addOutletForm.storeName)
  branchEditFormData.append('address',addOutletForm.address)
  branchEditFormData.append('nativeAddress',addOutletForm.addressNative)

  const editBranch=()=>{
    editOutletAPICall(branchEditFormData,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg,clearStates,updateListener)
  }



  //* Onchange Fns
  const getAddOutletFormData = (key) => (e,newValue) => {
    const { value } = e.target;
    if (key === "country") {
      setAddOutletForm({ ...addOutletForm, country: newValue });
    }
    if (key === "state") {
      setAddOutletForm({ ...addOutletForm, state: newValue });
    }
    if (key === "location") {
      setAddOutletForm({ ...addOutletForm, location: value });
    }
    if (key === "branchName") {
      setAddOutletForm({ ...addOutletForm, branchName: value });
    }
    if (key === "storeName") {
      setAddOutletForm({ ...addOutletForm, storeName: value });
    }
    if (key === "storeNameNative") {
      setAddOutletForm({ ...addOutletForm, storeNameNative: value });
    }
    if (key === "contactNo") {
      setAddOutletForm({ ...addOutletForm, contactNo: value });
    }
    if (key === "TRN") {
      setAddOutletForm({ ...addOutletForm, TRN: value });
    }
    if (key === "email") {
      setAddOutletForm({ ...addOutletForm, email: value });
    }
    if (key === "address") {
      setAddOutletForm({ ...addOutletForm, address: value });
    }
    if (key === "addressNative") {
      setAddOutletForm({ ...addOutletForm, addressNative: value });
    }
    if (key === "contactName") {
      setAddOutletForm({ ...addOutletForm, contactName: value });
    }
  };



 

  // filter state from selected country
  useEffect(()=>{
   let data =  countryWithState?.countries?.filter((obj)=>{
      if(obj?.country===addOutletForm.country?.country){
        return obj
      }
    })
    setStateList(data !== undefined && data[0])
  },[addOutletForm.country,countryWithState])


  const getNewLocationName = (e) => {
    setNewLocationName(e.target.value);
  };
  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnackbar(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };

  //*Click Fns
  //Adding new location
  const addNewLocationClick = () => {
    addNewLocationsAPICall(
      {
        country: addOutletForm.country?.country,
        locationName: newLocationName,
      },
      setOpenSuccessSnackbar,
      setOpenErrorSnackbar,
      setSnackMsg,
      setNewLocationName,
      updateListener
    );
  };
  //Add New Outlet
  //Update Outlet Logo Body
  const branchLogoFormData = new FormData();
  branchLogoFormData.append("logo", outletLogo);
  //Create Outlet body (JSON)
  const addNewOutletBody = {
    locationId: addOutletForm.location,
    contactPerson: addOutletForm.contactName,
    contactNumber: addOutletForm.contactNo,
    trnNumber: addOutletForm.TRN,
    email: addOutletForm.email,
    nameOfStore: addOutletForm.storeName,
    branchName: addOutletForm.branchName,
    address: addOutletForm.address,
    nativeAddress: addOutletForm.addressNative,
    nativenameOfStore: addOutletForm.storeNameNative,
    country:addOutletForm?.country?.country,
    state:addOutletForm?.state
  };

  const addNewOutletClick = () => {
    if (
      addOutletForm.location !== "0" &&
      addOutletForm.country !== "0" &&
      addOutletForm.branchName !== "" &&
      addOutletForm.storeName !== "" &&
      addOutletForm.address !== ""
    ) {
      addNewOutletAPICall(
        addNewOutletBody,
        setOpenSuccessSnackbar,
        setOpenErrorSnackbar,
        setSnackMsg,
        clearStates,
        updateListener,
        branchLogoFormData
      );
    } else {
      alert("fill all fields");
    }
  };

  useEffect(() => {
    viewCompanyProfileInfoAPICall();
    viewAllLocationsAPICall();
  }, [isModify]);

  //Assign Default values
  useEffect(() => {
    if (companyProfileData !== undefined) {
      setAddOutletForm({
        ...addOutletForm,
        storeName: companyProfileData?.profile?.companyName,
        storeNameNative: companyProfileData?.profile?.nativeCompanyName,
        address: companyProfileData?.profile?.companyAddress,
        addressNative: companyProfileData?.profile?.nativeCompanyAddress,
      });
      setOutletLogo(companyProfileData?.profile?.logo);
    }
  }, [companyProfileData]);
  //Call Make branch list extensible fn
  useEffect(() => {
    if (companyBranchList !== undefined) {
      makeBranchListExtensible(companyBranchList);
    }
  }, [companyBranchList]);

  //Clear States
  const clearStates = () => {
    setAddOutletForm({
      ...addOutletForm,
      location: "0",
      contactNo: "",
      TRN:"",
      email:"",
      storeName: "",
      branchName: "",
      address: "",
      addressNative: "",
      storeNameNative: "",
      contactName:""
    });
    setOutletLogo(companyProfileData?.profile?.logo);
    setIsEdit(false)
    setEditingBranchId(null)
  };

  useEffect(()=>{
    setFilteredCompanyList(companyLocationseData)
  },[companyLocationseData])

  useEffect(()=>{
    let filteredArray=[]
    if(companyLocationseData!==undefined){
          if(searchKeyword!==""){
            filteredArray=companyLocationseData.filter((obj)=>
            obj?.locationName?.toLowerCase()?.includes(searchKeyword?.toLowerCase())
            )
            setFilteredCompanyList(filteredArray)
          }
          else{
            setFilteredCompanyList(companyLocationseData)
          }
    }
  },[searchKeyword])
  
console.log(companyBranchList)
  return (
    <>
      <div className="add-outlet-container">
        <h3>Add Outlet</h3>
        <hr className="global-hr" />
        <div className="add-outlet-input-container">
          <div className="add-outlet-input auto-complete">
            <p>Country</p>
            <Autocomplete
                sx={{ width: "100%" }}
                options={countryWithState?.countries||[""]}
                getOptionLabel={(option) =>option?.country}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Country"/>
                )}
                onChange={getAddOutletFormData("country")}
                disabled={isEdit}
                
             />
            
          </div>
          <div className="add-outlet-input auto-complete">
             <p>State</p>
             <Autocomplete
                sx={{ width: "100%" }}
                options={stateList?.states||[""]}
                getOptionLabel={(option) =>option}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select State" />
                )}
                onChange={getAddOutletFormData("state")}
                disabled={isEdit}
                
             />
          </div>
          <div className="add-outlet-input location-container">
            <p>Location</p>
            <Select
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid rgb(206, 199, 199)",
                },
              }}
              value={addOutletForm.location}
              onChange={getAddOutletFormData("location")}
              disabled={isEdit}
            >
              <MenuItem value="0">-select location-</MenuItem>
              {companyLocationseData
                ?.slice(0)
                ?.reverse()
                ?.map((item, i) => (
                  <MenuItem key={i} value={item?._id}>
                    {item?.locationName}
                  </MenuItem>
                ))}
            </Select>
            <p
              onClick={() => setAddLocation(true)}
              className="add-location-text"
            >
              Add Location
            </p>
          </div>
          <div className="add-outlet-input">
            <p>Branch</p>
            {/* <Select
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "1px solid rgb(206, 199, 199)",
              },
            }}
            value={branchName}
            onChange={getBranchName}
          ></Select> */}
            <input
              value={addOutletForm.branchName}
              onChange={getAddOutletFormData("branchName")}
              placeholder="Enter new branch name..."
              disabled={isEdit}
            />
          </div>
          {/* <div className="add-outlet-input store-code">
          <p>Store Code</p>
          <input />
        </div> */}
          <div className="add-outlet-input">
            <p>Name of Store</p>
            <input
              value={addOutletForm.storeName}
              onChange={getAddOutletFormData("storeName")}
              placeholder="Enter store name..."
              // disabled={isEdit}
            />
          </div>
          {currentCountry !== "india" && (
            <div className="add-outlet-input arabic-name arabic">
              <p className="address-arabic">اسم المتجر</p>
              <input
                dir="rtl"
                value={addOutletForm.storeNameNative}
                onChange={getAddOutletFormData("storeNameNative")}
                placeholder="أدخل اسم المتجر"
              />
            </div>
           )}

          <div className="add-outlet-input">
            <p>Contact Name</p>
            <input
              value={addOutletForm.contactName}
              onChange={getAddOutletFormData("contactName")}
              placeholder="Contact person Name..."
            />
          </div>
          <div className="add-outlet-input">
            <p>Contact Number</p>
            <input
              value={addOutletForm.contactNo}
              onChange={getAddOutletFormData("contactNo")}
              placeholder="Contact person number..."
            />
          </div>
          <div className="add-outlet-input">
            <p>TRN NO</p>
            <input
              value={addOutletForm.TRN}
              onChange={getAddOutletFormData("TRN")}
              placeholder="TRN number..."
            />
          </div>
          <div className="add-outlet-input">
            <p>Email</p>
            <input
            type="email"
              value={addOutletForm.email}
              onChange={getAddOutletFormData("email")}
              placeholder="Email..."
            />
          </div>
          
          <div className="add-outlet-input">
            <p>Address</p>
            <textarea
              rows="4"
              value={addOutletForm.address}
              onChange={getAddOutletFormData("address")}
              placeholder="Enter outlet address..."
              // disabled={isEdit}
            />
          </div>


          {currentCountry !== "india" && (
            <div className="add-outlet-input arabic-name arabic">
              <p className="address-arabic">تبوك</p>
              <textarea
                dir="rtl"
                rows="4"
                value={addOutletForm.addressNative}
                onChange={getAddOutletFormData("addressNative")}
                placeholder="أدخل عنوان المنفذ ..."
              />
            </div>
          )}
          <div className="add-outlet-input">
            <p>Logo</p>
            <FilePicker
              id="outletImage"
              uploadImageUrl={outletLogo}
              setImage={setOutletLogo}
              onChange={(e) => setOutletLogo(e.target.files[0])}
            />
          </div>
          {/* <div > */}
        {!isEdit &&   <button
            id="addOutletButton"
            className={
              addOutletForm.location !== "0" &&
              addOutletForm.country !== "0" &&
              addOutletForm.branchName !== "" &&
              addOutletForm.storeName !== "" &&
              addOutletForm.address !== ""
                ? "add-outlet-add-button"
                : "add-outlet-add-button disabled"
            }
            onClick={
              addOutletForm.location !== "0" &&
              addOutletForm.country !== "0" &&
              addOutletForm.branchName !== "" &&
              addOutletForm.storeName !== "" &&
              addOutletForm.address !== ""
                ? addNewOutletClick
                : undefined
            }
          >
            +
          </button>}
          {isEdit && <>
          <button className="btn btn-secondary-outlined" onClick={editCancelBtnClick}>Cancel</button>
          <button className="btn btn-primary" onClick={editBranch}>Update</button>
          </>}


          {/* </div> */}
        </div>
        <Divider />
        <div className="global-table-container">
          <table className="global-table add-outlet-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Country</th>
                <th>Location</th>
                <th>branch</th>
                <th>Store Code</th>
                <th>Name Of Store</th>
                <th>Contact Number</th>
                <th>Address</th>
                {/* <th>Logo</th> */}
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {branchResExtensible
                ?.slice(0)
                ?.reverse()
                ?.map((r, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{r?.country}</td>
                    <td>{r?.locationName}</td>
                    <td>{r?.branchName}</td>
                    <td>{r?.storeCode}</td>
                    <td>{r?.nameOfStore}</td>
                    <td>
                      {r?.contactPerson !== "" ? r?.contactPerson : "####"}
                    </td>
                    <td>{r?.address?.substring(0, 9) + "..."}</td>
                    {/* <td className="branch-image-cell">
                      <img src={r?.logo || NoImage} />
                    </td> */}
                    <td>
                      <IconButton  onClick={clickEditBranchBtn(r)}>
                        {r?.isEdit ? (
                           <i class="bi bi-arrow-repeat"></i>
                        ) : (
                         
                          <i class="bi bi-pencil-square edit-icon"></i>
                        )}
                      </IconButton>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="add-outlet-button-container">
          <button className="btn btn-secondary-outlined" onClick={cancelDialog}>
            Close
          </button>
        </div>

        <Dialog open={addLocation} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setAddLocation(false )} onClose={()=>setAddLocation(false)}>
          <div className="add-outlet-add-location-container">
            <div className="add-location-top-container">
              <p>Add Location</p>
              <div
                onClick={() => setAddLocation(false)}
                className="add-location-close-icon"
              >
                <CloseIcon />
              </div>
            </div>
            <Divider />
            <div className="add-location-content-top-container">
              <input
                placeholder="Enter new location..."
                value={newLocationName}
                onChange={getNewLocationName}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    addNewLocationClick();
                  }
                }}
              />
              <button onClick={addNewLocationClick}>+</button>
            </div>
            <div className="add-location-content-main-second-container">
              <div className="add-location-content-second-container">
                <input placeholder="Search Location" 
                  value={searchKeyword}
                  onChange={(e)=>setSearchKeyword(e.target.value)}
                />
                <button>
                  <SearchIcon />
                </button>
              </div>


              {filteredCompanyList
                ?.slice(0)
                ?.reverse()
                ?.map((r, i) => (
                  <div className="add-location-list-items">
                    <p key={i}>{r?.locationName}</p>
                    {/* <IconButton>
                      <i class="bi bi-trash3 delete-icon"></i>
                    </IconButton> */}
                  </div>
                ))}
            </div>
          </div>
        </Dialog>
      </div>
      <SuccessSnackbar
        open={openSuccessSnackbar}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnackbar}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
