import { createSlice } from "@reduxjs/toolkit";

export const homeDashboardSlice = createSlice({
  name: "homeDashboardSlice",
  initialState: {
    mainDashboardData: null,
  },

  reducers: {
    setMainDashboardData: (state, action) => {
      state.mainDashboardData = action.payload;
    },
  },
});

export const { setMainDashboardData } = homeDashboardSlice.actions;

export default homeDashboardSlice.reducer;
