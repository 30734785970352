import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ListManufactureAPI } from './FoodManagementNewAPI';
import { useSelector } from 'react-redux';
import { list_single_view, list_single_view_order } from './FoodManagementSlice';
import store from '../../../Redux/store';

const ManufacturingOrderList = () => {
    let navigateTo = useNavigate();

  const allOrderList = useSelector(
    (state) => state.foodManagement.listOrder)
console.log(allOrderList);

    const createNew = () => {
        localStorage.removeItem("order_id")
        store.dispatch(list_single_view(undefined))
        store.dispatch(list_single_view_order(undefined))
        navigateTo("/userdashboard/foodManagement/manufacturingOrder/create")
    }

    const handleClickRow=(row)=>{
        localStorage.setItem("order_id", row?._id)
        navigateTo("/userdashboard/foodManagement/manufacturingOrder/create")
    }

    useEffect(() => {
        ListManufactureAPI();
      },[])
    
    return (
        <div className="global-page-parent-container aggregator-main-ctn" >
            <div className="global-white-bg-container Header" style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <p>Preparation</p>
                <button className='create-button-blue' onClick={() => createNew()}>Create</button>

            </div>
            <div className='global-white-bg-container'>
                <div className="justify-space-between"
                    style={{ gap: "0 12px", display: 'flex', justifyContent: 'end', alignItems: 'end' }}
                >
                    {/* <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '9px',

                    }}>

                        <button className='create-button-blue' onClick={() => createNew()}>Create</button>
                    </div> */}
                </div>
            
            <div className="global-product-table special-items-table" style={{paddingTop:"15px",maxHeight:"78vh"}}>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Product</th>
                            <th>qty</th>
                            <th>Bill of Material</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        { allOrderList?.list!==undefined && allOrderList?.list?.lenght!==0 ? allOrderList?.list?.map((item,index)=>(
                        <tr key={index}  onClick={()=>handleClickRow(item)} style={{borderBottom:"2px solid #dee3f8"}}>
                            <td>{item.sequenceName}</td>
                            <td>{item.productName}</td>
                            <td>{item.quantity}</td>
                            <td>{item.recipeSequenceName}</td>
                            <td className="table-data-box">
                        {item?.status === "DRAFTED" ? (
                          <span className="draft"> DRAFT</span>
                        ) : item?.status === "POSTED" ? (
                          <span className="post"> POST</span>
                        ) : item?.status === "CANCELLED" ? (
                          <span className="cancel">CANCEL</span>
                        ) : (
                          <span>{item?.status}</span>
                        )}
                      </td>
                        </tr>
                        )):
                        <td>
                            <tr>No Data</tr>
                        </td>
                        }
                    </tbody>
                </table>
            </div>
            </div>
        </div>
    )
}

export default ManufacturingOrderList