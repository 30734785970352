import React, { useState } from "react";
import PayrollCard from "../components/PayrollCard";
import "./payHead.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from "@mui/material";

import MuiBackBtn from "../components/MuiBackBtn";
import MuiSaveBtn from "../components/MuiSaveBtn";
import MuiEditButton from "../components/MuiEditButton";
import MuiToggleBtn from "../components/MuiToggleBtn";
import MuiAutocomplete from "../components/MuiAutocomplete";

const PayHead = () => {
  const [open, setOpen] = useState(false);
  const handleClickCreatePayHead = () => {
    console.log("clicked");
    setOpen(!open);
  };
  const handleBackBtnClicked = () => {
    setOpen(false);
  };

  return (
    <div className="pay-head-container">
      <div className="pay-head-wrapper">
        <div className="pay-head-nav">
          <h3 className="head-name">Pay Head</h3>
          <div className="pay-head-btn-cntr">
            <button
              onClick={() => handleClickCreatePayHead()}
              className="pay-head-btn"
            >
              Create Pay Head
            </button>
          </div>
        </div>
        <div className="card-container">
          {Array(6)
            .fill(null)
            .map((res) => (
              <PayrollCard />
            ))}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg" 
        sx={{padding:"5em",}}
      >
        <div style={{minHeight:"50vh"}}>
        <DialogContent dividers>
          <Grid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid>
              <MuiBackBtn onClick={handleBackBtnClicked} />
              <MuiEditButton />
              <MuiSaveBtn />
            </Grid>
            <Grid
              gap={3}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography fontWeight={500} id="alert-dialog-title">
                Active
              </Typography>
              <MuiToggleBtn />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogContent className="pay-head-dialog">
         
         <div className="new-global-single-input">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={  []}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="CATEGORY*" focused />
              )}
              // value={internalTransferForm?.destiLoc}
              // onChange={getInternalTransForm("destiLoc")}
              // disabled={internalTransferForm?.isEdit}
            />
            {/* <p className="doc-validation-alert">{validationAlert?.dLocation}</p> */}
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="NAME*"
              variant="outlined"
              type="text"
              focused

              // value={internalTransferForm?.date}
              // onChange={getInternalTransForm("date")}
              // disabled={internalTransferForm?.isEdit}
            />
          </div>
         <div className="new-global-single-input">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={  []}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="CALCULATION TYPE*" focused />
              )}
              // value={internalTransferForm?.destiLoc}
              // onChange={getInternalTransForm("destiLoc")}
              // disabled={internalTransferForm?.isEdit}
            />
            {/* <p className="doc-validation-alert">{validationAlert?.dLocation}</p> */}
          </div>
         <div className="new-global-single-input">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={  []}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="PERCENTAGE OF CTC*" focused />
              )}
              // value={internalTransferForm?.destiLoc}
              // onChange={getInternalTransForm("destiLoc")}
              // disabled={internalTransferForm?.isEdit}
            />
            {/* <p className="doc-validation-alert">{validationAlert?.dLocation}</p> */}
          </div>
         <div className="new-global-single-input">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={  []}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="EXPENSE ACCOUNT*" focused />
              )}
              // value={internalTransferForm?.destiLoc}
              // onChange={getInternalTransForm("destiLoc")}
              // disabled={internalTransferForm?.isEdit}
            />
            {/* <p className="doc-validation-alert">{validationAlert?.dLocation}</p> */}
          </div>

      

        </DialogContent>
        </div>
        {/* <DialogActions>
          <Button onClick={"handleClose"}>Disagree</Button>
          <Button onClick={"handleClose"} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default PayHead;
