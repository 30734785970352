import axios from "axios";
import { HEADERS } from "../../../../API/UrlAndPaths";
import store from "../../../../Redux/store";
import {
  setBankJournalList,
  setBankReSingleViewData,
  setBankReconciliationForm,
  setBankReconciliationList,
  setCompanyBookList,
  setSnackbarNotification,
} from "./BankReconciliationSlice";
import { snackbarNotification } from "./bankReconciliationUtil";

//view all bank journals
export const viewAllBankJournalsAPI = async (data) => {
  try {
    const response = await axios.post("account/viewbankjournals", {}, HEADERS);
    if (response.status === 200) {
      store.dispatch(setBankJournalList(response?.data));
    }
  } catch (error) {
    console.error(error);
    store.dispatch(setBankJournalList([]));
  }
};

//fetch bank reconciliation
export const fetchBankReconciliationAPI = async (data) => {
  try {
    const response = await axios.post(
      "account/fetchdataforbankreconcilation",
      data.body,
      HEADERS
    );
    if (response.status === 200) {
      const newState = (prev) => ({
        ...prev,
        closingBalance: response.data[0]?.balance,
        companyBook: response.data[0]?.list,
      });

      // Dispatch the new state directly
      store.dispatch(
        setBankReconciliationForm(
          newState(store.getState().bankReconciliation.bankReconciliationForm)
        )
      );
    }
  } catch (error) {
    console.error(error);
  }
};

// api call for create bank reconciliation
export const createBankReconciliationAPI = async (data) => {
  try {
    const response = await axios.post(
      "account/addbankreconcilation",
      data.body,
      HEADERS
    );
    if (response.status === 200) {
      // console.log(response);
      viewSingleBankReconciliationAPI({ _id: response.data._id });
      localStorage.setItem(
        "singleViewByIdBankReconciliation",
        response.data._id
      );
    }
  } catch (error) {
    console.error(error);
  }
};

// api call for get single bank reconciliation
export const viewSingleBankReconciliationAPI = async (body) => {
  try {
    const response = await axios.post(
      "account/viewsinglebankreconcilation",
      body,
      HEADERS
    );
    if (response.status === 200) {
      // console.log(response);
      store.dispatch(setBankReSingleViewData(response.data));
    }
  } catch (error) {
    console.error(error);
    store.dispatch(setBankReSingleViewData(null));
  }
};

// get list of bank reconciliation
export const listBankReconciliationAPI = async (body) => {
  try {
    const response = await axios.post(
      "account/viewbankreconcilation",
      body,
      HEADERS
    );
    if (response.status === 200) {
      // console.log(response);
      store.dispatch(setBankReconciliationList(response.data));
    }
  } catch (error) {
    console.error(error);
    store.dispatch(setBankReconciliationList([]));
  }
};

// import bank reconciliation
export const uploadImportedBankReconciliationAPI = async (data) => {
  try {
    const response = await axios.post(
      "account/importbankreconcilation",
      data.body,
      HEADERS
    );
    if (response.status === 200) {
      viewSingleBankReconciliationAPI({ _id: response.data._id });
      store.dispatch(
        setSnackbarNotification({
          message: "File uploaded successfully",
          open: true,
          severity: snackbarNotification.success,
        })
      );
      data.setOpen(false);
    }
  } catch (error) {
    console.error(error);
    store.dispatch(
      setSnackbarNotification({
        message: "Failed to upload file",
        open: true,
        severity: snackbarNotification.error,
      })
    );

    // store.dispatch(setBankReconciliationList([]))
  }
};

// update bank reconciliation
export const updateBankReconciliationAPI = async (data) => {
  try {
    const response = await axios.post(
      "account/updatedraftedreconcilations",
      data.body,
      HEADERS
    );
    if (response.status === 200) {
      // console.log(response);
      viewSingleBankReconciliationAPI({ _id: response.data._id });
      data.isEdit((prev) => ({ ...prev, isEdit: false }));
      store.dispatch(
        setSnackbarNotification({
          message: "Bank reconciliation updated successfully",
          open: true,
          severity: snackbarNotification.success,
        })
      );
    }
  } catch (error) {
    console.error(error);
    store.dispatch(
      setSnackbarNotification({
        message: "Failed to update bank reconciliation",
        open: true,
        severity: snackbarNotification.error,
      })
    );

    // store.dispatch(setBankReconciliationList([]))
  }
};

// reset to draft bank reconciliation
export const resetToDraftBankReconciliationAPI = async (data) => {
  try {
    const response = await axios.post(
      "account/bankreconcilationresettodraft",
      data.body,
      HEADERS
    );
    if (response.status === 200) {
      // console.log(response);
      viewSingleBankReconciliationAPI({ _id: response.data._id });
      fetchBankReconciliationAPI({
        body: {
          journalId: data.journalId,
          date: data.date,
        },
      });
      // data.isEdit(prev=>({...prev,isEdit:false}))
      store.dispatch(
        setSnackbarNotification({
          message: "Bank reconciliation reset to draft successfully",
          open: true,
          severity: snackbarNotification.success,
        })
      );
    }
  } catch (error) {
    console.error(error);
    store.dispatch(
      setSnackbarNotification({
        message: "Failed to reset bank reconciliation to draft",
        open: true,
        severity: snackbarNotification.error,
      })
    );

    // store.dispatch(setBankReconciliationList([]))
  }
};

// cancel bank reconciliation
export const cancelBankReconciliationAPI = async (data) => {
  try {
    const response = await axios.post(
      "account/cancelbankreconcilation",
      data.body,
      HEADERS
    );
    if (response.status === 200) {
      // console.log(response);
      viewSingleBankReconciliationAPI({ _id: response.data._id });
      // data.isEdit(prev=>({...prev,isEdit:false}))
      store.dispatch(
        setSnackbarNotification({
          message: "Bank reconciliation canceled successfully",
          open: true,
          severity: snackbarNotification.success,
        })
      );
    }
  } catch (error) {
    console.error(error);
    store.dispatch(
      setSnackbarNotification({
        message: "Failed to cancel bank reconciliation",
        open: true,
        severity: snackbarNotification.error,
      })
    );

    // store.dispatch(setBankReconciliationList([]))
  }
};

// post bank reconciliation
export const postBankReconciliationAPI = async (data) => {
  // console.log( data.body)

  try {
    const response = await axios.post(
      "account/postBankReconcilation",
      data.body,
      HEADERS
    );
    if (response.status === 200) {
      // console.log(response);
      viewSingleBankReconciliationAPI({ _id: response?.data?._id });
      // data.isEdit(prev=>({...prev,isEdit:false}))
      store.dispatch(
        setSnackbarNotification({
          message: "Bank reconciliation posted successfully",
          open: true,
          severity: snackbarNotification.success,
        })
      );
    }
  } catch (error) {
    console.error(error);
    store.dispatch(
      setSnackbarNotification({
        message: "Failed to post bank reconciliation",
        open: true,
        severity: snackbarNotification.error,
      })
    );

    // store.dispatch(setBankReconciliationList([]))
  }
};
// map bank reconciliation
export const mapReconciliationAPI = async (data) => {
  try {
    const response = await axios.put(
      "account/matchAndUpdateReconcilation",
      data.body,
      HEADERS
    );
    if (response.status === 200) {
      viewSingleBankReconciliationAPI({ _id: response.data._id });
      store.dispatch(
        setSnackbarNotification({
          message: "Reconciliation matched successfully",
          open: true,
          severity: snackbarNotification.success,
        })
      );
    }
  } catch (error) {
    console.error(error);
    store.dispatch(
      setSnackbarNotification({
        message: "Failed to match reconciliation",
        open: true,
        severity: snackbarNotification.error,
      })
    );

    // store.dispatch(setBankReconciliationList([]))
  }
};
// compute Bank reconciliation
export const computeBankReconciliationAPI = async (data) => {
  try {
    const response = await axios.post(
      "account/computeBankReconcilation",
      data.body,
      HEADERS
    );
    if (response.status === 200) {
      viewSingleBankReconciliationAPI({ _id: response.data._id });
      store.dispatch(
        setSnackbarNotification({
          message: "Company book updated successfully",
          open: true,
          severity: snackbarNotification.success,
        })
      );
    }
  } catch (error) {
    console.error(error);
    store.dispatch(
      setSnackbarNotification({
        message: "Failed to update company book",
        open: true,
        severity: snackbarNotification.error,
      })
    );
    // store.dispatch(setBankReconciliationList([]))
  }
};
