import React from 'react'
import { useSelector } from 'react-redux';

const ProductSummary = () => {
    const productSummmary = useSelector(
        (state) => state.posReportshiftSlice.shiftReport);
const decimalPosition = localStorage.getItem("decimalPosition");
  
  return (
    <div className="new-global-table-container" style={{ paddingTop: "0 42px" }}>
    <table>
        <thead>
            <tr>
                
                <th>Product</th>
                <th>Qty</th>
                <th> Amount</th>
                
            </tr>
        </thead>
        <tbody>
            {productSummmary?.sale!==undefined ? productSummmary?.sale?.map((r, i) => (
                <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                    
                    <td style={{ border: "none", height: "29px" }}>{r.productName}</td>
                    <td style={{ border: "none", height: "29px" }}>{r.totalQty}</td>
                    <td style={{ border: "none", height: "29px" }}>{r.amount}</td>
                    
                </tr>
            )):(
                <tr>
                    <td colSpan={3}>NO DATA</td>
                </tr>
            )}
        </tbody>
        <tfoot>
        <tr style={{backgroundColor:"rgb(150 148 148)"}}>
           <td colSpan={2} style={{textAlignLast:"end",paddingInline:"10px"}}>TOTAL</td> 
           <td style={{textAlignLast:"center"}}>{productSummmary?.sale?.reduce((acc, current) => acc + Number(current?.amount || 0), 0)?.toFixed(decimalPosition)}</td>
        </tr>
    </tfoot>
    </table>
    </div>
  )
}

export default ProductSummary