import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  convertArrayToExcel,
  filterObjFromList,
} from "../../../../../Js/generalFunctions";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { AccountPayableSingleAPIcall } from "./partnerReportApi";
import Template from "./AccountSingleViewPrint";
import { IconButton, Tooltip } from "@mui/material";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs";
import PrintComponent from "../../../../print/report/PrintComponent";

const AccountPayableSingleView = () => {
  console.log("single view..........");
  let paymentsPrintNew = useRef();
  let navigate = useNavigate();
  const [symbol, setSymbol] = useState(null);

  const decimalPosition = localStorage.getItem("decimalPosition");
  const vendor = localStorage.getItem("vendor");
  const singleViewId = localStorage.getItem("singleViewById");
  const StartDate = localStorage.getItem("fromDate");
  const EndDate = localStorage.getItem("toDate");

  const singleView = useSelector(
    (state) => state?.PartnerReport?.AccountPayableSingleView
  );

  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const initialvalue = {
    totalBalance: 0,
    totalCredit: 0,
    totalDebit: 0,
    label: "Account Payable Report",

    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
  };
  const [data, setData] = useState([]);
  const [formValues, setFormValues] = useState(initialvalue);

  useEffect(() => {
    setFormValues({
      ...formValues,
      totalBalance: singleView?.totalBalance || 0,
      totalCredit: singleView?.totalCredit || 0,
      totalDebit: singleView?.totalDebit || 0,

      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
    });
    setData(singleView?.entries);
  }, [singleView, companyProfileData]);

  // const formatFilterInfo = (filters) => {
  //   const parts = [];

  //   if (filters?.fromDate && filters?.toDate) {
  //     parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
  //   }
  //   if (filters?.partner) {
  //     parts.push(`Partner: ${filters?.partner?.name}`);
  //   }
  //   if (filters?.accountType) {
  //     parts.push(`Account Type: ${filters?.accountType?.label}`);
  //   }
  //   if (filters?.branchList) {
  //     parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
  //   }
  //   if (filters?.status) {
  //     parts.push(`Status: ${filters?.status}`);
  //   }
  //   if (filters?.createdBy) {
  //     parts.push(`Employee: ${filters?.createdBy?.staff_name}`);
  //   }

  //   return parts; // Return the array directly
  // };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("ACCOUNT PAYABLE");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["ACCOUNT PAYABLE"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells("A1:G1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    // const filterParts = formatFilterInfo(filteredData) || [];

    // Ensure filterParts is an array and iterate over it
    // if (Array.isArray(filterParts)) {
    //   filterParts.forEach((part, index) => {
    //     const row = sheet.addRow([part]);
    //     row.font = { size: 13, bold: true };
    //     sheet.mergeCells(`A${index + 2}:H${index + 2}`);
    //     row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    //   });
    // }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "Date",
      "Name",
      "Journal",
      "Reference",
      "Debit",
      "Credit",
      "Balance",
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };

    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Date", key: "date", width: 20 },
      { header: "Name", key: "name", width: 20 },
      { header: "Journal", key: "journalNo", width: 20 },
      { header: "Reference", key: "reference", width: 20 },
      { header: "Debit", key: "debit", width: 20 },
      { header: "Credit", key: "credit", width: 20 },
      { header: "Account Payable", key: "balance", width: 20 },
    ];

    singleView?.entries?.forEach((item) => {
      const row = sheet.addRow({
        date: item?.date,
        name: ` ${item?.name}`,
        journalNo: ` ${item?.journalNo}`,
        reference: ` ${item?.reference}`,
        debit: ` ${item?.debit?.toFixed(2)}`,
        credit: ` ${item?.credit?.toFixed(2)}`,
        balance: ` ${item?.balance?.toFixed(2)}`,
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFC2CBF9" } },
          left: { style: "thin", color: { argb: "FFC2CBF9" } },
          bottom: { style: "thin", color: { argb: "FFC2CBF9" } },
          right: { style: "thin", color: { argb: "FFC2CBF9" } },
        };
        if (colNumber > 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "right" };
        }
      });
    });

    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      "",
      "",
      "",
      `${symbol} ${singleView?.totalDebit?.toFixed(2)}`,
      `${symbol} ${singleView?.totalCredit?.toFixed(2)}`,
      `${symbol} ${singleView?.balanceTotal?.toFixed(2)}`,
    ]);

    // Merge cells from A to E
    sheet.mergeCells(`A${totalRow.number}:D${totalRow.number}`);

    totalRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber > 1) {
        // Right align all columns except the first
        cell.alignment = { horizontal: "right" };
      }
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Account Payable Single View.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const handleback = () => {
    navigate("/userdashboard/accounts/report/partnerReport/accountPayable");
  };
  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    AccountPayableSingleAPIcall({
      startDate: StartDate,
      endDate: EndDate,
      id: singleViewId,
    });
  }, [StartDate, EndDate, singleViewId]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);
  return (
    <div className="global-page-parent-container" style={{ margin: "0" }}>
      <div
        className="global-white-bg-container"
        style={{ backgroundColor: "unset", padding: "4px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "white",
          }}
        >
          <div style={{ display: "flex" }}>
            <div className="sharp-arrow" onClick={handleback}>
              {" "}
              <p style={{ fontSize: "medium" }}>Account Payable Report &gt;</p>
            </div>
            <div className="sharp-arrow" style={{ width: "auto" }}>
              {" "}
              <p style={{ fontSize: "medium" }}>{vendor}</p>
            </div>
          </div>
          <div style={{ paddingInline: "15px" }}>
            <Tooltip title="Share">
              <IconButton>
                <i
                  class="bi bi-share"
                  style={{
                    backgroundColor: "#9797e9",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>

            <ReactToPrint
              trigger={() => (
                <IconButton className="printer-div" style={{ padding: "1px" }}>
                  <i
                    style={{
                      color: "white",
                      backgroundColor: " #d787e7",
                      fontSize: "medium",
                      padding: "8px",
                      borderRadius: "2px",
                    }}
                    class="bi bi-printer"
                  ></i>
                </IconButton>
              )}
              content={() => paymentsPrintNew}
            />

            <Tooltip title="Download">
              <IconButton onClick={downloadList}>
                <i
                  class="bi bi-arrow-down-circle"
                  style={{
                    backgroundColor: "rgb(255 118 133 / 69%)",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div
          className="global-report-table-container"
          style={{
            height: "85vh",
            maxHeight: "85vh",
            backgroundColor: "white",
            marginTop: "5px",
          }}
        >
          <table style={{ paddingTop: "4px" }}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Journal</th>
                <th>Referance</th>
                <th>Debit</th>
                <th>Credit</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {singleView?.entries?.map((item, index) => (
                <tr key={index} style={{ height: "38px" }}>
                  <td
                    style={{
                      backgroundColor: "white",
                      border: "0.8px solid #c3d4f7",
                    }}
                  >
                    {item.date}
                  </td>
                  <td
                    style={{
                      backgroundColor: "white",
                      border: "0.8px solid #c3d4f7",
                    }}
                  >
                    {item.name}
                  </td>
                  <td
                    style={{
                      backgroundColor: "white",
                      border: "0.8px solid #c3d4f7",
                    }}
                  >
                    {item.journalNo}
                  </td>
                  <td
                    style={{
                      backgroundColor: "white",
                      border: "0.8px solid #c3d4f7",
                    }}
                  >
                    {item.reference}
                  </td>
                  <td
                    style={{
                      backgroundColor: "white",
                      border: "0.8px solid #c3d4f7",
                    }}
                  >
                    {item.debit?.toFixed(decimalPosition)}
                  </td>
                  <td
                    style={{
                      backgroundColor: "white",
                      border: "0.8px solid #c3d4f7",
                    }}
                  >
                    {item.credit?.toFixed(decimalPosition)}
                  </td>
                  <td
                    style={{
                      backgroundColor: "white",
                      border: "0.8px solid #c3d4f7",
                    }}
                  >
                    {item.balance?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan="4">Total</th>
                <th>
                  {symbol}&nbsp;
                  {singleView?.totalDebit?.toFixed(decimalPosition)}
                </th>
                <th>
                  {symbol}&nbsp;
                  {singleView?.totalCredit?.toFixed(decimalPosition)}
                </th>
                <th>
                  {symbol}&nbsp;
                  {singleView?.balanceTotal?.toFixed(decimalPosition)}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
          <PrintComponent pageHeight={75} header={`ACCOUNT PAYABLE REPORT`} subHead={vendor}>
            <table>
              <thead>
                <tr>
                  <th style={{backgroundColor:"#1d1da7",fontWeight:600,color:"white"}}>Date</th>
                  <th style={{backgroundColor:"#1d1da7",fontWeight:600,color:"white"}}>Name</th>
                  <th style={{backgroundColor:"#1d1da7",fontWeight:600,color:"white"}}>Journal</th>
                  <th style={{backgroundColor:"#1d1da7",fontWeight:600,color:"white"}}>Referance</th>
                  <th style={{backgroundColor:"#1d1da7",fontWeight:600,color:"white"}}>Debit</th>
                  <th style={{backgroundColor:"#1d1da7",fontWeight:600,color:"white"}}>Credit</th>
                  <th style={{backgroundColor:"#1d1da7",fontWeight:600,color:"white"}}>Balance</th>
                </tr>
              </thead>
              <tbody>
                {singleView?.entries?.map((item, index) => (
                  <>
                    <tr key={index} style={{ height: "38px" }}>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "0.8px solid #c3d4f7",
                        }}
                      >
                        {item.date}
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "0.8px solid #c3d4f7",
                        }}
                      >
                        {item.name}
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "0.8px solid #c3d4f7",
                        }}
                      >
                        {item.journalNo}
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "0.8px solid #c3d4f7",
                        }}
                      >
                        {item.reference}
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "0.8px solid #c3d4f7",
                        }}
                      >
                        {item.debit?.toFixed(decimalPosition)}
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "0.8px solid #c3d4f7",
                        }}
                      >
                        {item.credit?.toFixed(decimalPosition)}
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          border: "0.8px solid #c3d4f7",
                        }}
                      >
                        {item.balance?.toFixed(decimalPosition)}
                      </td>
                    </tr>
                    {singleView?.entries?.length -1 === index && (
                      <tr>
                        <td  style={{backgroundColor:"#1d1da7",fontWeight:600,color:"white"}}  colSpan="4">Total</td >
                        <td  style={{backgroundColor:"#1d1da7",fontWeight:600,color:"white"}} >
                          {symbol}&nbsp;
                          {singleView?.totalDebit?.toFixed(decimalPosition)}
                        </td   >
                        <td  style={{backgroundColor:"#1d1da7",fontWeight:600,color:"white"}} >
                          {symbol}&nbsp;
                          {singleView?.totalCredit?.toFixed(decimalPosition)}
                        </td   >
                        <td  style={{backgroundColor:"#1d1da7",fontWeight:600,color:"white"}} >
                          {symbol}&nbsp;
                          {singleView?.balanceTotal?.toFixed(decimalPosition)}
                        </td >
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </PrintComponent>
          {/* <Template  data={data} formValues={formValues}/>  */}
        </div>
      </div>
    </div>
  );
};

export default AccountPayableSingleView;
