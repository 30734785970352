import { Autocomplete, IconButton, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAggregatorDialog, setAggregatorSingleRow } from './aggregatorSlice'
import SpecialItemsInputFields from '../../../Sales/SpecialItems/components/SpecialItemsInputFields'
import { addAggregatorsAPI, editAggregatorsAPI } from './aggregatorAPI'
import { findObjFromList } from '../../../../../Js/generalFunctions'

function AddAggregator() {


  const dispatch=useDispatch()
  const decimalPosition=localStorage.getItem("decimalPosition")
  const {dialogBox,singleList}=useSelector((state)=>state.aggregatorSlice)
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);
  const userRole=useSelector((state)=>state.userRoleSlice.value)
  const employeeList=useSelector((state)=>state.allActiveEmployeeSlice.value)

  const formValuesInitial = {
    name: "",
    branch:null,
    employee:null,
    commissionType:"Fixed",
    commission:0,
    isEdit:false,
    self:false,
    code:""
}
  const [formValues, setFormValues] = useState(formValuesInitial)
  const [editClicked,setIsEditClicked]=useState(false)
  const [validationAlert,setValidationAlert]=useState({})

  const formOnchange=(key)=>(e,newValue)=>{

    const{value}=e.target;
    setFormValues({...formValues,[key]:newValue===undefined?value:newValue})
    setValidationAlert({})
  }

  const saveBtnClick=(key)=>()=>{
    if((!formValues.self && formValues.name==="") || (formValues.self && formValues?.employee===null)){
        setValidationAlert({...validationAlert,name:"Name is required !!"})
    }
    else if(formValues.commissionType===null){
        setValidationAlert({...validationAlert,commissionType:"Type is required !!"})
    }
    else if(formValues.self && formValues.code===""){
        setValidationAlert({...validationAlert,code:"Delivery code is required !!"})
    }
    else{
        let payload={
            name:formValues.self?formValues?.employee?.staff_name:formValues?.name,
            branchId:formValues.branch?._id || null,
            commType:formValues.commissionType,
            amount:(formValues.commission===""?0:Number(formValues.commission) ) || 0,
            empId:formValues.employee?._id || null,
            isSelf:formValues.self,
            code:formValues.self?formValues?.code:null
        }
        if(key==="add"){
            addAggregatorsAPI(payload)
        }else{
            payload._id=singleList?._id
            editAggregatorsAPI(payload)
        }
    }
  }


  useEffect(()=>{
    if(singleList!==null){
        setFormValues({
            ...formValues,
            name:singleList?.name || "",
            branch:findObjFromList("_id",allBranchesList,"branchId",singleList),
            employee:findObjFromList("_id",employeeList,"empId",singleList),
            commissionType:singleList?.commType || null,
            commission:singleList?.amount || 0,
            self:singleList?.isSelf || false,
            code:singleList?.code || "",
            isEdit:true
        })
    }else{
        setFormValues(formValuesInitial)
    }
  },[singleList])
  return (
    <div className="global-white-bg-container special-items-head" style={{padding:"0",width:"60vw",maxWidth:"800px"}}>
    <div className='justify-center' style={{justifyContent:"space-between",width:"100%"}}>
        
        <div className='justify-start'>
        
        <IconButton onClick={()=>{
            dispatch(setAggregatorDialog(null))
            dispatch(setAggregatorSingleRow(null))
            }}>
            <i class="bi bi-chevron-left"></i>
        </IconButton>

        {
            dialogBox==="edit"?
           <IconButton
            onClick={
            editClicked
              ? saveBtnClick("edit")
              : () => {
                  setIsEditClicked(true)
                  setFormValues({...formValues,isEdit:false})
                }
          }
          >
              <i
                  class={
                      editClicked
                          ? "bi bi-check2-square edit-icon"
                          : "bi bi-pencil-square edit-icon1"
                  }
              ></i>
          </IconButton>:
          <IconButton onClick={saveBtnClick("add")}
          >
              <i class="bi bi-floppy save-icon"></i>
          </IconButton>
            
          
          }

                  
        </div>
        <div className="justify-center" style={{marginLeft:"auto",marginRight:"10px",gap:"10px"}}>
            <h4 style={{textAlign:"end"}}>Self</h4>
            <input
                className="toggle-checkbox"
                type="checkbox"
                style={{width:"70px"}}
                checked={formValues?.self}
                onChange={(e) => setFormValues({
                    ...formValues, self: e.target.checked,employee:null,name:""
                })}
                disabled={formValues?.isEdit}
            />
        </div>
    </div>
    <hr className="global-hr" />
          <div className="new-global-input-container aggregator-input-ctn">
              <div className="new-global-single-input">
                  {
                    formValues?.self?
                    <Autocomplete
                    options={employeeList || []}
                    getOptionLabel={(option) => option?.staff_name}
                    renderInput={(params) => (
                        <TextField {...params} label="Employee*" focused variant='outlined'/>
                    )}
                    value={formValues?.employee}
                    onChange={formOnchange("employee")}
                    disabled={formValues?.isEdit}
                    />
                    :<TextField
                      id="outlined-basic"
                      label={`Agregator Name*`}
                      variant="outlined"
                      type="text"
                      focused
                      value={formValues?.name}
                      onChange={formOnchange("name")}
                      disabled={formValues?.isEdit}
                  />}
                  <p className="doc-validation-alert">{validationAlert?.name}</p>
              </div>
              <div className="new-global-single-input">
                  <Autocomplete
                      options={(userRole==="admin"?allBranchesList:allowedBranchList) || []}
                      getOptionLabel={(option) => option?.branchName}
                      renderInput={(params) => (
                          <TextField {...params} label="Branch" focused variant='outlined'/>
                      )}
                      value={formValues?.branch}
                      onChange={formOnchange("branch")}
                      disabled={formValues?.isEdit}
                  />
                  <p className="doc-validation-alert">{validationAlert?.branch}</p>
              </div>
              <div className="new-global-single-input">
                          <Autocomplete
                              options={["Fixed","Percentage"]}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                  <TextField {...params} focused label="Commission type"/>
                              )}
                              value={formValues?.commissionType}
                              onChange={formOnchange("commissionType")}
                              disabled={formValues?.isEdit}
                          />
                        <p className="doc-validation-alert">{validationAlert?.commissionType}</p>
              </div>
                      <div className="new-global-single-input" >
                        <TextField
                            id="outlined-basic"
                            label="Commission"
                            variant="outlined"
                            type="number"
                            focused
                            InputProps={{
                                style: { textAlign: 'end', paddingRight: '9px' },
                            }}
                            value={formValues?.commission}
                            onChange={formOnchange("commission")}
                            disabled={formValues?.isEdit}
                            onFocus={(e)=>e.target.select()}
                            onMouseLeave={()=>setFormValues({...formValues,commission:Number(formValues.commission)?.toFixed(decimalPosition)})}
                        />
                        <p className="doc-validation-alert">{validationAlert?.commission}</p>
                      </div>
                     {
                     formValues?.self &&
                     <div className="new-global-single-input">
                        <TextField
                            id="outlined-basic"
                            label="Delivery Code*"
                            variant="outlined"
                            type="text"
                            focused
                            value={formValues?.code}
                            onChange={formOnchange("code")}
                            disabled={formValues?.isEdit}
                            onFocus={(e)=>e.target.select()}
                        />
                        <p className="doc-validation-alert">{validationAlert?.code}</p>
                      </div>}
      </div>
    </div>

  )
}

export default AddAggregator