import { Key } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { editCompanyProfileInfoAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import "../../../../css/Settings/CompanySettings/gstAndTax.css";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";

export const GstAndTax = (props) => {
  const { profileData, updateListener } = props;

  //Snackbar states
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");
  const [isToggle,setIsToggle]=useState(false)

  let currentCountry = localStorage.getItem("country");
  useEffect(() => {
    currentCountry = localStorage.getItem("country");
  }, []);

  //profile GST and TaxForm
  const profileGstAndTaxInitialState = {
    sellerRegisteredName: profileData?.gst?.sellerRegisteredNumber,
    taxNo: profileData?.gst?.gstNumber,
    taxCode: profileData?.gst?.panNumber,
  };
  const [profileGstAndTaxForm, setProfileGstAndTaxForm] = useState(
    profileGstAndTaxInitialState
  );

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnack(false);
  };
  //Close errorSnackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };

  //*onChange
  const getProfileGstAndTax = (Key) => (e) => {
    const { value } = e.target;

    if (Key === "sellerRegisteredName") {
      setProfileGstAndTaxForm({
        ...profileGstAndTaxForm,
        sellerRegisteredName: value,
      });
    }
    if (Key === "taxNo") {
      setProfileGstAndTaxForm({ ...profileGstAndTaxForm, taxNo: value });
    }
    if (Key === "panNo") {
      setProfileGstAndTaxForm({ ...profileGstAndTaxForm, panNo: value });
    }
  };

  //Form data body
  const taxFormData = new FormData();
  
    taxFormData.append(
      "sellerRegisteredNumber",
      isToggle===true?profileGstAndTaxForm.sellerRegisteredName:null
    );
    taxFormData.append("gstNumber",isToggle===true?profileGstAndTaxForm.taxNo:null);
    taxFormData.append("panNumber",isToggle===true?profileGstAndTaxForm.panNo:null);
  
 
  taxFormData.append("isTax",isToggle)
  //Edit tax settings
  const editTaxSettings = () => {
    editCompanyProfileInfoAPICall(
      taxFormData,
      setOpenSuccessSnack,
      setOpenErrorSnack,
      setSnackMsg,
      updateListener
    );
  };

  
  const changeGstToggle=(e)=>{
    setIsToggle(e.target.checked)
  }

  const clearFn=()=>{
    setProfileGstAndTaxForm(profileGstAndTaxInitialState)
  }



  return (
    <>
      <div className="account-settings-container">        
        
        <div className="company-setting-gst-top">        
          <h4 style={{margin:"2% 0",width:"100%"}}>Enter Tax Details</h4>
          <input
            className="toggle-checkbox"
            type={"checkbox"}
            name="productList"
            checked={isToggle}
            onChange={changeGstToggle}
          />
        </div>
        <div className="gst-and-tax-container">
          <div className={isToggle===false?"profile-input-container-disable":"profile-input-container"}>
            <p>Seller Registered name</p>
            <input
              placeholder="Enter seller registered name..."
              value={profileGstAndTaxForm.sellerRegisteredName==="null"?"":profileGstAndTaxForm.sellerRegisteredName}
              onChange={getProfileGstAndTax("sellerRegisteredName")}
              disabled={isToggle===false}
              
            />
          </div>
          <div className={isToggle===false?"profile-input-container-disable":"profile-input-container"}>
            <p>{currentCountry === "india" ? "GST Number" : "VAT number"}</p>
            <input
              value={profileGstAndTaxForm.taxNo==="null"?"":profileGstAndTaxForm.taxNo}
              onChange={getProfileGstAndTax("taxNo")}
              disabled={isToggle===false}
            />
          </div>
          <div className={isToggle===false?"profile-input-container-disable":"profile-input-container"}>
            <p>
              {currentCountry === "india" ? "PAN Number" : "Income Tax Code"}
            </p>
            <input
              value={profileGstAndTaxForm.panNo==="null"?"":profileGstAndTaxForm.panNo}
              onChange={getProfileGstAndTax("panNo")}
              disabled={isToggle===false}
            />
          </div>
        </div>
      </div>
      <button className="btn btn-primary profile-info-save-btn" onClick={editTaxSettings}>Save</button>


      {/* Snackbars  */}
      <SuccessSnackbar
        open={openSuccessSnack}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
