import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Bar, BarChart } from 'recharts';

function CustomBarChart({ datas, selection }) {
    const [data, setData] = useState();

    // useEffect(() => {
    //     if (datas) {
    //         if (selection === "employee") {
    //             // Process data if needed
    //         }
    //     }
    // }, [datas]);

    return (
        <div>
            <ResponsiveContainer minWidth={400} width="100%" height={200}>
                <BarChart
                    width={500}
                    height={300}
                    data={datas}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 50, // Increase bottom margin to accommodate rotated labels
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                        dataKey={selection === "employee" ? "employeeName" : "vendorName"} 
                        interval={0} 
                        angle={50} 
                        textAnchor="start" 
                        height={60} // Increase height to accommodate the rotated labels
                    />
                    <YAxis dataKey="profitMargin" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="profitMargin" fill="#00866b" name='Profit Margin' barSize={30} legendType="none" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default CustomBarChart;
