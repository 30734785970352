import React from "react";
import {
  Autocomplete,
  Dialog,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import post from "../../../../Assets/statusRibbon/post.png";
import returned from "../../../../Assets/statusRibbon/return.png";
import draft from "../../../../Assets/statusRibbon/draft.png";
import { getCurrentTime, today } from "../../../../Js/Date";
import { useNavigate } from "react-router-dom";
import {
  editUpdatedGRNDraftAPICall,
  getGRNsingleViewAPICall,
  postReturnGRNAPICall,
  postUpdatedGRNAPICall,
} from "./GRNUpdated/grnListingAPI";
import { viewGlConfigAPICall } from "../../Accounts/Configuration/glConfigAPI";
import {
  createVendorBillDraftFromGRN,
} from "../../Accounts/Vendor/API/vendorBillAPI";
import { findObjFromList } from "../../../../Js/generalFunctions";
import logoImage from "../../../../Assets/Images/Icon ionic-logo-buffer.png";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import { LoadingForm } from "../../../Single Components/LoadingForm";
import { setFilterActive } from "../../../Single Components/listSlice/filterCategorySlice";

export const NewGoodsReceivedNotes1 = () => {
  let navigateTo = useNavigate();
  const dispatch = useDispatch();
  const editedData = useSelector(
    (state) => state.goodsReceivedNoteUpdatedSlice.rowData
  );

  const [journalLocationList, setJournaLocationList] = useState([])

  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );

  const generatedName = useSelector(
    (state) => state.goodsReceivedNoteUpdatedSlice.generateName
  );

  const singleViewID = localStorage.getItem("singleViewById");
  const [logView, setLogView] = useState(false);
  const [formAddedTable, setFormAddedTable] = useState([]);

  const [loadingForm, setLoadingForm] = useState(false)

  const [validationAlert, setValidationAlert] = useState({});
  const [rowIndex, setRowIndex] = useState(-1);
  const [returnValidationAlert, setReturnValidationAlert] = useState(-1);
  const [editClicked, setEditClicked] = useState(false);

  const [draftName, setDraftName] = useState("###");
  const [showPostBtn, setShowPostBtn] = useState(false);
  const [showEditbtn, setShowEditBtn] = useState(false);
  const [returnProduct, setReturnProduct] = useState(false);

  const formInitialState = {
    status: "",
    purchaseOrder: null,
    branch: null,
    location: null,
    vendor: null,
    date: today,
    reference: "",
    purchaseId: "",
    purchaseDate: "",
    vendorId: "",
    id: "",
    journalId: "",
    chartOfAccountId: "",
    purchaseOrderName: "",
    grnId: "",
    PurchaseOrderId: "",
    isBillGenerated: false,
    branchPk: ""
  };
  const [formValues, setFormValues] = useState(formInitialState);
  const [returnProductList, setReturnProductList] = useState();


  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false,
  });
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  const getFormInfo = (key) => (e, newValue) => {
    setValidationAlert({});
    const { value } = e.target;
    setFormValues({ ...formValues, [key]: newValue === undefined ? value : newValue });
  };
  const clickLogView = () => {
    setLogView(!logView);
  };

  const [log, setLog] = useState([]);

  const handleReceivedQtyChange = (qty, index) => (e) => {
    const { value } = e.target;
    setRowIndex(index);
    setValidationAlert({});
    const receivedQty = Number(value);

    if (receivedQty > qty) {
      setValidationAlert({ ...validationAlert, maxQty: true });
    }
    formAddedTable[index].recievedQuantity =
      receivedQty > qty ? qty : Number(value);
    setFormAddedTable([...formAddedTable]);
  };

  const handleReturnQuantityChange = (qty, index) => (e) => {
    const { value } = e.target;
    setRowIndex(index);
    setReturnValidationAlert({});
    const rtqty = Number(value);

    if (rtqty > qty) {
      setReturnValidationAlert({ ...validationAlert, maxQty: true });
    }

    setReturnProductList((prevReturnProductList) => {
      const updatedList = [...prevReturnProductList];
      updatedList[index] = { ...updatedList[index], returnQuantity: rtqty > qty ? qty : rtqty };
      return updatedList;
    });
  };

  const payload = {
    _id: formValues.id,
    branchId: formValues?.branch,
    location: formValues?.location?._id,
    date: formValues?.date,
    reference: formValues?.reference,
    supplierId: formValues?.vendorId,
    postDate: `${today} ${getCurrentTime()}`,
    totalOrderQuantity: formAddedTable?.reduce(
      (sum, item) => sum + item?.orderQuantity,
      0
    ),
    totalReceivedQuantity: formAddedTable?.reduce(
      (sum, item) => sum + item?.recievedQuantity,
      0
    ),
    productInfo: formAddedTable,
  };

  const postPayload = {
    _id: formValues.id,
    postDate: `${today} ${getCurrentTime()}`,
  };


  const navigateAfterGeneration = () => {
    navigateTo("/userdashboard/accounts/vendor/createvendorBills");
  };
  const postSuccessFn = (data) => {
    setFormValues({
      ...formValues,
      status: "Complete",
      grnId: data?._id,
      PurchaseOrderId: data?.purchase_id,
    });
  };

  const submitClick = (key) => () => {
    dispatch(setFilterActive(false))
    if (formValues?.location === null) {
      setValidationAlert({ ...validationAlert, location: "select location !!" })
    }
    else {


      if (key === "edit") {
        editUpdatedGRNDraftAPICall(
          payload,
          setSnackBarStates,
          snackBarStates,
          setShowPostBtn,
          setEditClicked, setLoadingForm
        );
      } else if (key === "post") {
        postUpdatedGRNAPICall(
          postPayload,
          setSnackBarStates,
          snackBarStates,
          postSuccessFn,
          setShowPostBtn,
          setShowEditBtn, setLoadingForm
        );
      } else if (key === "generateBill") {
        const body = {
          grnId: formValues?.grnId || editedData?._id,
          postDate: `${today} ${getCurrentTime()}`,
        };

        createVendorBillDraftFromGRN(
          body,
          navigateAfterGeneration,
          setSnackBarStates,
          snackBarStates,
          navigateAfterGeneration, setLoadingForm
        );
      } else if (key === "return") {
        setReturnProduct(true);

      } else if (key === "submitReturnProduct") {

        const sortProductArray = returnProductList.filter(
          (res) => res.returnQuantity !== 0 && res.recievedQuantity >= res.returnQuantity
        );
        let returnBody = {
          postDate: today,
          mainGrnId: formValues.id,
          productInfo: sortProductArray,
        };
        if (sortProductArray.length > 0) {
          postReturnGRNAPICall(
            returnBody,
            setSnackBarStates,
            setReturnProduct,
            formValues.id, setLoadingForm
          );
        }
      }
    }
  };

  // editBtnFun
  const editBtnFun = () => {
    setEditClicked(true);
    setShowPostBtn(false);
  };
  const handleDelete = (id) => {
    const filteredData = returnProductList.filter((_, i) => i !== id);
    setReturnProductList(filteredData);
  };


  useEffect(() => {
    if (singleViewID !== undefined) {
      getGRNsingleViewAPICall({ _id: singleViewID });
    }
    viewGlConfigAPICall();
  }, []);

  useEffect(() => {
    setDraftName(generatedName === undefined ? "###" : generatedName);
  }, [generatedName]);

  useEffect(() => {
    if (editedData !== undefined) {
      setFormValues({
        ...formValues,
        purchaseId: editedData?.purchase_id,
        branch: editedData?.branchId,
        vendor: editedData?.supplierName,
        vendorId: editedData?.supplierId,
        purchaseDate: editedData?.purchaseDate,
        status: editedData?.status,
        purchaseOrder: editedData?.name,
        purchaseOrderName: editedData?.purchaseOrderName,
        id: editedData?._id,
        reference: editedData?.reference,
        unTaxedAmount: editedData?.untaxedAmount,
        tax: editedData?.totalTax,
        total: editedData?.total,

        isBillGenerated: editedData?.isBillGenerated,
        branchPk: editedData?.branchPk || "",
        location: editedData?.location === null ? editedData?.defaultLocation[0] || null : findObjFromList("_id", editedData?.defaultLocation || [], "location", editedData),
        journalId: configurationList?.purchase?.purchaseJournal || "",
        chartOfAccountId: configurationList?.purchase?.purchaseControlAccount || "",
      });

      setJournaLocationList(editedData?.defaultLocation || [])
      const filterdatas = editedData?.productInfo?.filter(
        (item) => item?.line_type === "lines"
      );
      const updatedPurchaseInfo = filterdatas?.map((item) => {
        return {
          ...item,
          recievedQuantity:
            item?.recievedQuantity,
          dimension: item.unit,
        };
      });
      setLog(editedData?.log || [])
      setReturnProductList(updatedPurchaseInfo);
      setFormAddedTable(updatedPurchaseInfo);
      // vieWareHouseLocationAPICall({ branchId: editedData?.branchPk });
    } else {
      setFormValues({ ...formValues, journalId: "" });
    }
  }, [editedData, configurationList]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = editClicked;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [editClicked]);
  return (
    <div className="global-page-parent-container">
      <div
        className="new-global-white-bg-container"
        style={{padding: "0 0", backgroundColor: "unset" }}
      >
        <div className="new-global-white-bg-icon-container"style={{backgroundColor:"white"}}>
          <div className="new-global-white-bg-icon-container-left" style={{ width: "60%", display: "flex", alignItems: "center" }}>
            {/* <Tooltip title="Back">
              <IconButton
                onClick={() => {
                  if (editClicked === true) {
                    const userConfirmed = window.confirm(
                      "Changes you made may not be saved."
                    );

                    if (userConfirmed) {
                      navigateTo(
                        "/userdashboard/purchaseManager/GoodsReceivedNote"
                      );
                    }
                  } else {
                    navigateTo(
                      "/userdashboard/purchaseManager/GoodsReceivedNote"
                    );
                  }
                }}
              >
                <i class="bi bi-chevron-left"></i>
              </IconButton>
            </Tooltip> */}

            {showEditbtn === false && formValues?.status === "Drafted" && (
              <Tooltip title="Edit">
                <IconButton>
                  {editClicked ? (
                    <i
                      class="bi bi-floppy save-icon"
                      style={{ fontSize: "1.5rem" }}
                      onClick={submitClick("edit")}
                    ></i>
                  ) : (
                    <i
                      class="bi bi-pencil-square edit-icon1"
                      onClick={editBtnFun}
                    ></i>
                  )}
                </IconButton>
              </Tooltip>
            )}

            {formValues?.status === "Drafted" && !editClicked && (
              <button className="btn btn-post" onClick={submitClick("post")}>
                Post
              </button>
            )}

            {formValues?.status === "Complete" &&
              formValues?.isBillGenerated === false && (
                <button
                  className="btn btn-post"
                  onClick={submitClick("generateBill")}
                >
                  GenerateBill
                </button>
              )}
            {formValues?.status === "Complete" &&
              formValues?.isBillGenerated === false && (
                <button
                  className="btn btn-post"
                  onClick={submitClick("return")}
                >
                  Return
                </button>
              )}
            <h3>
              <span
                style={{ fontSize: "medium", color: "#676666", cursor: "pointer" }}
                onClick={() => {
                  if (editClicked === true) {
                    const userConfirmed = window.confirm(
                      "Changes you made may not be saved."
                    );

                    if (userConfirmed) {
                      navigateTo(
                        "/userdashboard/purchaseManager/GoodsReceivedNote"
                      );
                    }
                  } else {
                    navigateTo(
                      "/userdashboard/purchaseManager/GoodsReceivedNote"
                    );
                  }
                }}
              > Goods Receivable Note &gt;</span>
              <span style={{ fontSize: "medium", paddingLeft: "8px" }}>{formValues?.purchaseOrder}</span>

            </h3>
          </div>

         
        </div>
        {/* <hr className="global-hr" /> */}
        {/* <h4 className="global-name">{formValues?.purchaseOrder}</h4> */}
        <div style={{backgroundColor:"white",padding:"10px",marginTop:"6px",position:"relative"}}>
        <div className="status-container" style={{right:"0"}}>
            {formValues?.status === "Drafted" && <img src={draft} alt="" />}

            {formValues?.status === "Complete" && <img src={post} alt="" />}
            {formValues?.status === "Returned" && <img src={returned} alt="" />}
          </div>
        <div className="new-global-input-container">
          <div className="new-global-single-input disabled-input">
            <TextField
              id="outlined-basic"
              label="Purchase Order*"
              variant="outlined"
              type="text"
              focused
              value={formValues?.purchaseOrderName}
            />
          </div>
          <div className="new-global-single-input disabled-input">
            <TextField
              id="outlined-basic"
              label="Branch*"
              variant="outlined"
              type="text"
              focused
              value={formValues?.branch}
            />
          </div>
          <div className="new-global-single-input">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={journalLocationList || []}
              getOptionLabel={(option) => option?.locationName}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Location*" focused />
              )}
              value={formValues?.location}
              onChange={getFormInfo("location")}
              disabled={!editClicked}
            />
            {
              <p className="doc-validation-alert">{validationAlert?.location}</p>
            }
          </div>
          <div className="new-global-single-input disabled-input">
            <TextField
              id="outlined-basic"
              label="Vendor*"
              variant="outlined"
              type="text"
              focused
              value={formValues?.vendor}
            />
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Date*"
              variant="outlined"
              type="date"
              focused
              value={formValues.date}
              onChange={getFormInfo("date")}
              disabled={!editClicked}
            />
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Reference*"
              variant="outlined"
              type="text"
              focused
              value={formValues.reference}
              onChange={getFormInfo("reference")}
              disabled={!editClicked}
            />
          </div>
        </div>
     
      <div
        className="new-global-white-bg-container"
        style={{ display: "flex", padding: "8px 2%" }}
      >
        <div
          className="global-white-bg-container add-table-selection"
          style={{ width: "100%" }}
        >
          <div className="global-page-parent-container">
            <div className="purchase-order-main-container second-row-container">
              <div className="top-bar">
                <h3>Received Products</h3>
              </div>
              <div className="new-global-table-container" style={{ maxHeight: "45vh" }}>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>UOM</th>
                      <th>Order Quantity</th>
                      {formValues.status === "Returned" ? <th>Return Quantity </th> : <th>Received Quantity</th>}
                    </tr>
                  </thead>

                  <tbody>
                    {formAddedTable?.length !== 0 ? (
                      formAddedTable?.filter(obj => obj?.prodType === "Goods")?.map((r, i) => (
                        <tr
                          key={i}
                          style={{
                            cursor:
                              formValues?.status === "Complete" &&
                              "not-allowed",
                          }}
                        >
                          <td>{r?.product}</td>
                          <td>{r?.purchaseUomName}</td>
                          <td style={{ width: "20%" }}>{r?.orderQuantity}</td>
                          <td
                            className="table-data-box"
                            style={{
                              width: "20%",
                              textAlign: "center",
                              position: "relative",
                            }}
                          >
                            {formValues?.status === "Drafted" ? (
                              <input
                                type="number"
                                id={i}
                                value={r?.recievedQuantity}
                                onChange={handleReceivedQtyChange(
                                  r?.orderQuantity,
                                  i
                                )}
                                disabled={!editClicked}
                                onFocus={(e) => e.target.select()}
                              />
                            ) : (
                              formValues?.status === "Returned" ? r?.returnQuantity : r?.recievedQuantity
                            )}
                            {rowIndex === i && validationAlert?.maxQty && (
                              <p
                                style={{
                                  top: "50%",
                                  right: "25%",
                                  fontWeight: "600",
                                }}
                                className="doc-validation-alert"
                              >
                                max : {r?.orderQuantity}
                              </p>
                            )}
                          </td>

                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={10}>No Data</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {formAddedTable?.length !== 0 && (
                <div
                  className="net-amount-container"
                  style={{ backgroundColor: "rgb(216 217 220)" }}
                >
                  <div
                    className="global-single-input grn-net-amount-inputs"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <p style={{
                      color: "#000",
                      fontWeight: "600"
                    }}>Total</p>
                    <p
                      style={{
                        width: "20%",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      {formAddedTable?.reduce(
                        (sum, item) => sum + Number(item?.orderQuantity),
                        0
                      )}
                    </p>
                    <p
                      style={{
                        width: "20%",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      {formAddedTable?.reduce(
                        (sum, item) => sum + Number(formValues?.status === "Returned" ? item?.returnQuantity : item?.recievedQuantity),
                        0
                      )}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="new-global-white-bg-container new-log-container"
        style={{ padding: "4px" }}
      >
        <div
          style={{ padding: "8px", cursor: "pointer" }}
          onClick={clickLogView}
        ></div>
        <div
          className={logView ? "pointer-up" : "pointer"}
          onClick={clickLogView}
          style={{ cursor: "pointer" }}
        ></div>
        {logView && (
          <div className="single-log-container">
            {log?.map((r, i) => (
              <div className="single-log-item">
                <div className="single-log-item-line-image">
                  <hr className="single-log-item-line" />
                  <div className="single-log-item-image">
                    <img src={logoImage} alt="" />
                  </div>
                  <hr className="single-log-item-line" />
                </div>
                <p className="heading">{r?.status}</p>
                <p className="name">{r?.empId}</p>
                <p className="date-time">{r?.date}</p>
                <p className="date-time">{r?.time}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <Dialog
        open={returnProduct}
        maxWidth="lg"
        sx={{ zIndex: 1210, marginTop: "2%" }}
        onKeyDown={(e) => e.key === "Escape" && setReturnProduct(false)}
      >
        <div className="add-expense-dialog-container">
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Location*"
              variant="outlined"
              type="text"
              focused
              value={formValues?.location?.name}
            />
          </div>
          <div
            className="global-white-bg-container add-table-selection"
            style={{ width: "100%" }}
          >
            <div className="global-page-parent-container">
              <div className="purchase-order-main-container second-row-container">
                <div className="top-bar">
                  <h3>Return Products</h3>
                </div>
                <div className="new-global-table-container">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>UOM</th>
                        <th>Order Quantity</th>
                        <th>Received Quantity</th>
                        <th>Return Quantity</th>
                        <th style={{ backgroundColor: "#fff" }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {returnProductList?.length !== 0 ? (
                        returnProductList?.map((r, i) => (
                          <tr
                            key={i}
                            style={{
                              cursor:
                                formValues?.status === "Complete" &&
                                "not-allowed",
                            }}
                          >
                            {/* <td>{r?.code}</td> */}
                            <td>{r?.product}</td>
                            <td>{r?.purchaseUomName}</td>
                            <td style={{ width: "20%" }}>{r?.orderQuantity}</td>
                            <td>{r?.recievedQuantity}</td>
                            <td
                              className="table-data-box"
                              style={{
                                width: "20%",
                                textAlign: "center",
                                position: "relative",
                              }}
                            >
                              {formValues?.status === "Complete" ? (
                                <input
                                  type="number"
                                  id={i}
                                  value={r?.returnQuantity > r.returnQtyLimit ? r.returnQtyLimit : r?.returnQuantity}
                                  onChange={handleReturnQuantityChange(
                                    r?.returnQtyLimit,
                                    i
                                  )}
                                  onFocus={(e) => e.target.select()}
                                />
                              ) : (
                                r?.returnQuantity
                              )}
                              {rowIndex === i &&
                                returnValidationAlert?.maxQty && (
                                  <p
                                    style={{
                                      top: "50%",
                                      right: "25%",
                                      fontWeight: "600",
                                    }}
                                    className="doc-validation-alert"
                                  >
                                    max : {r?.returnQtyLimit}
                                  </p>
                                )}
                            </td>
                            <td style={{ border: "none", width: "4%" }}>
                              <IconButton
                                onClick={() => handleDelete(i)}
                                style={{ height: "30px" }}
                              // disabled={branchLocationForm?.isEdit}
                              >
                                <i
                                  className="bi bi-trash3"
                                  style={{
                                    color: "#db0000",
                                    fontSize: "1.3rem",
                                  }}
                                ></i>
                              </IconButton>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={10}>No Data</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="expense-button-container">
          <button
            onClick={() => setReturnProduct(false)}
            className="btn btn-secondary-outlined"
          >
            Cancel
          </button>
          <button
            onClick={submitClick("submitReturnProduct")}
            className="btn btn-primary"
          >
            Submit
          </button>
        </div>
      </Dialog>

      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <LoadingForm loading={loadingForm} />
   </div>
   </div>
    </div>
  );
};
