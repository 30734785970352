import React, { useEffect, useState } from "react";
import "./bankReconciliationStyle.css";
import { useNavigate } from "react-router-dom";
import { listBankReconciliationAPI } from "./BankReconciliationAPI";
import { useDispatch, useSelector } from "react-redux";
import {
  setBankReSingleViewData,
  setBankReconciliationList,
} from "./BankReconciliationSlice";

export const BankReconciliationNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { bankReconciliationList } = useSelector(
    (state) => state.bankReconciliation
  );

  useEffect(() => {
    localStorage.removeItem("singleViewByIdBankReconciliation");
    localStorage.removeItem("singleViewById");
    dispatch(setBankReSingleViewData(null));
    listBankReconciliationAPI();
  }, []);

  const handleLineClick = (data) => {
    // console.log(data);
    navigate(
      "/userdashboard/accounts/Reconciliation/BankReconciliation/create",
      { state: data }
    );
    localStorage.setItem("singleViewByIdBankReconciliation", data._id);
  };
  return (
    <>
      <div className="brc-main-container">
        {/* header */}
        <div className="brc-main-head">
          <div>
            <span>Bank Reconciliation</span>
          </div>
          <div className="brc-head-right">
            <div>
              {/* filter and search */}
              </div>
            <div>
              <button
                className="brc-head-create-btn"
                onClick={() =>
                  navigate(
                    "/userdashboard/accounts/Reconciliation/BankReconciliation/create"
                  )
                }
              >
                <span>create</span>
              </button>
            </div>
          </div>
        </div>
        {/* list body*/}
        <div className="brc-list">
          {/* filter */}
          <div className="brc-pagination"> 
            {/* pagination  */}

          </div>
          {/* list */}
          <div className="brc-main-list">
            <BankReconciliationTable
              data={bankReconciliationList?.list}
              handleLineClick={handleLineClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const BankReconciliationTable = ({ data, handleLineClick }) => {
  return (
    <div className="brc-table-container">
      <table className="brc-table">
        <thead className="brc-thead">
          <tr className="brc-tr">
            <th className="brc-th">Date</th>
            <th className="brc-th">Bank</th>
            <th className="brc-th">Reference</th>
            <th className="brc-th">Status</th>
          </tr>
        </thead>
        <tbody className="brc-tbody">
          {data?.length > 0 &&
            [...data].reverse()?.map((row, index) => (
              <tr
                key={index}
                className="brc-tr"
                onClick={() => handleLineClick(row)}
              >
                <td className="brc-td">{row.date}</td>
                <td className="brc-td">{row.journal}</td>
                <td className="brc-td">{row.reference}</td>
                <td className={`brc-td `}>
                  <button
                    className={`brc-status-btn ${
                      row.status === "Posted"
                        ? "brc-status-posted"
                        : row.status === "Drafted"
                        ? "brc-status-draft"
                        : row.status === "Cancel"
                        ? "brc-status-canceled"
                        : row.status === "In-progress"
                        ? "brc-status-in-progress"
                        : ""
                    }`}
                  >
                    {" "}
                    <span>{row.status}</span>{" "}
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
