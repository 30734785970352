import {createSlice} from '@reduxjs/toolkit'

export const cashInOrOutSlice=createSlice({
    name:'cashInOrOutSlice',
    initialState:{
        value:undefined,
        cashInOrOutDialog:false
    },
    reducers:{
        get_cash_inor_out_details:(state,action)=>{
            state.value=action.payload.cashInOrOutData
        },
        setCashInOrOutDialog:(state,action)=>{
            state.cashInOrOutDialog=action.payload
        }
    }
})
export const {get_cash_inor_out_details,setCashInOrOutDialog}=cashInOrOutSlice.actions;
export default cashInOrOutSlice.reducer;