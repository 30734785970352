import { createSlice } from "@reduxjs/toolkit";

export const Report = createSlice({
    name:"ReportSlice",
    initialState:{
        StockMove:undefined,
        StockMoveSingle:[]
    },
    reducers:{
        setStockMove:(state,action)=>{
            state.StockMove = action.payload.stockMoveList
        },
        setStockMoveSigleView:(state,action)=>{
            state.StockMoveSingle = action.payload.stockMoveSingleList
        }, 

    }
})
export const {setStockMove,setStockMoveSigleView}=Report.actions;
export default Report.reducer;