import { createSlice } from "@reduxjs/toolkit";

export const filterButtonSlice=createSlice({
    name:"filterButtonSlice",
    initialState:{
        orderTypeOptions:[],
        filteredOrderType:null,
        statusOptions:[],
        filteredStatus:null,
        applyBtnClicked:false,
        filterPopup:false,
        kotPopup:false,
        kotStatusFilter:"All",
    },
    reducers:{
        setOrderTypeOptions:(state,action)=>{
            state.orderTypeOptions=action.payload
        },
        setStatusOptions:(state,action)=>{
            state.statusOptions=action.payload
        },
        setFilteredOrderType:(state,action)=>{
            state.filteredOrderType=action.payload
        },
        setFilteredStatus:(state,action)=>{
            state.filteredStatus=action.payload
        },
        setApplyBtnClicked:(state,action)=>{
            state.applyBtnClicked=action.payload
        },
        setFilterPopup:(state,action)=>{
            state.filterPopup=action.payload
        },
        setKotPopup:(state,action)=>{
            state.kotPopup=action.payload
        },
        setKotStatus:(state,action)=>{
            state.kotStatusFilter=action.payload
        }
        
    }
})

export const { setOrderTypeOptions,
    setStatusOptions, setFilteredOrderType, setFilteredStatus,setApplyBtnClicked,setFilterPopup,setKotPopup,setKotStatus
        }=filterButtonSlice.actions

export default filterButtonSlice.reducer