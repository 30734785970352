import React from 'react'
import { useSelector } from 'react-redux';

function DiscountSummary({showSubHeaders}) {
    const getBackgroundColor = (index) => {
        return index % 2 === 0 ? "#ffffff" : "#dfdfdf";
      };

      const ShiftDiscountSummary=useSelector((state)=>state.posReportshiftSlice.discountSummary)
      const decimalPosition = localStorage.getItem("decimalPosition");
  return (
    <div
    className="new-global-table-container"
    style={{ paddingTop: "0 42px" }}
  >
    <div className="bill-main-head">
      <h5>Bill Type</h5>
      <h5>Amount</h5>
    </div>
    <div className="bill-sub-head"  style={{ backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(0) }}>
      <h5>Dine In</h5>
      <h5>{ShiftDiscountSummary?.dineIn?.discount?.toFixed(decimalPosition)|| 0.00}</h5>
    </div>
    {showSubHeaders&&(

   
    <table>
      <thead style={{ backgroundColor: "#C0CFF8" }}>
        <tr>
          <th>Order No</th>
          <th>Date</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
            {ShiftDiscountSummary?.dineIn?.data!==undefined? ShiftDiscountSummary?.dineIn?.data?.map((r, i) => (
                <tr key={i} >
                   
                    <td style={{ borderBottom: "1px solid black", height: "29px" }}>{r.name}</td>
                    <td style={{ borderBottom: "1px solid black", height: "29px" }}>{r.orderDate}</td>
                    <td style={{ borderBottom: "1px solid black", height: "29px" }}>{r.discount}</td>
                </tr>
            )):(
                <tr>
                    <td colSpan={8}>
                        NO DATA
                    </td>
                </tr>
            )}
        </tbody>
     
    </table>
     )}
    <div className="bill-sub-head" style={{ backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(1) }}>
      <h5>Take Away</h5>
      <h5>{ShiftDiscountSummary?.takeAway?.discount?.toFixed(decimalPosition)|| 0.00}</h5>
    </div>
    {showSubHeaders&&(

   
    <table>
      <thead style={{ backgroundColor: "#C0CFF8" }}>
        <tr>
          <th>Order No</th>
          <th>Date</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
            {ShiftDiscountSummary?.takeAway?.data!==undefined? ShiftDiscountSummary?.takeAway?.data?.map((r, i) => (
                <tr key={i} >
                   
                    <td style={{ borderBottom: "1px solid black", height: "29px" }}>{r.name}</td>
                    <td style={{ borderBottom: "1px solid black", height: "29px" }}>{r.orderDate}</td>
                    <td style={{ borderBottom: "1px solid black", height: "29px" }}>{r.discount}</td>
                </tr>
            )):(
                <tr>
                    <td colSpan={8}>
                        NO DATA
                    </td>
                </tr>
            )}
        </tbody>
    </table>
     )}
    <div className="bill-sub-head" style={{ backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(2) }}>
      <h5>Delivery</h5>
      <h5>{ShiftDiscountSummary?.delivery?.discount?.toFixed(decimalPosition)|| 0.00}</h5>
    </div>
     {showSubHeaders&&(
  
    <table>
      <thead style={{ backgroundColor: "#C0CFF8" }}>
        <tr>
          <th>Order No</th>
          <th>Date</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
            {ShiftDiscountSummary?.delivery?.data!==undefined? ShiftDiscountSummary?.delivery?.data?.map((r, i) => (
                <tr key={i} >
                   
                    <td style={{ borderBottom: "1px solid black", height: "29px" }}>{r.name}</td>
                    <td style={{ borderBottom: "1px solid black", height: "29px" }}>{r.orderDate}</td>
                    <td style={{ borderBottom: "1px solid black", height: "29px" }}>{r.discount}</td>
                </tr>
            )):(
                <tr>
                    <td colSpan={8}>
                        NO DATA
                    </td>
                </tr>
            )}
        </tbody>
    </table>
     )}
    
  </div>
  )
}

export default DiscountSummary