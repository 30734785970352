import * as React from "react";
import Header from "../components/header/Header";
import "./addSeatStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { Box, Tab, Tabs } from "@mui/material";
import TabPanel from "../components/tabPanel/TabPanel";
import {
  setSelectedElementDetails,
  setSelectedFloorDetails,
} from "./addSeatSlice";
import { rgba } from "polished";
import useTabWidth from "../hooks/useTabWidth";
import { getSingleFloorAPI, listAllFloorAPI } from "./floorManagementApi";
import { listAllBranches } from "../apis/commonApi";
import { LoadingForm } from "../../../Single Components/LoadingForm";

const AddSeat = () => {
  const dispatch = useDispatch();
  const { floors, branch } = useSelector((state) => state.addSeatSlice);
  const tabWidth = useTabWidth(floors?.length);
  const [value, setValue] = React.useState(null);
  const { selectedFloorDetails } = useSelector((store) => store.addSeatSlice);
  const [loading, setLoading] = React.useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Set floor tab details
  const handleTabDetails = (res) => {
    dispatch(setSelectedElementDetails({ ...res, isFloor: true }));
    setLoading(true);
    getSingleFloorAPI({ id: res._id }, setLoading);
  };

  // Use polished's rgba function to decrease opacity
  const getSelectedTabColor = (originalColor) => {
    try {
      // Try to parse the color, if it fails, return a default color
      return rgba(originalColor, 0.5);
    } catch (error) {
      console.error("Error parsing color:", error);
      return rgba("#f67878", 0.5); // Return a default color if parsing fails
    }
  };

  // Initial actions
  React.useEffect(() => {
    dispatch(setSelectedFloorDetails(null));
    listAllBranches();
  }, []);

  // when change branch
  React.useEffect(() => {
    if (branch) {
      dispatch(setSelectedFloorDetails(null));
      setValue(null);
      listAllFloorAPI({ branchId: branch._id });
    }
  }, [branch]);

  return (
    <div
      className="add-seat-container"
      onMouseLeave={() => dispatch(setSelectedElementDetails(null))}
    >
      <div className="add-seat-wrapper">
        <Header />
        <Box
          sx={{ maxWidth: "100%", width: "100%", bgcolor: "background.paper" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            className="floor-container"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: `${"red"} !important`,
              },
            }}
          >
            {branch &&
              floors?.map((res, i) => (
                <Tab
                  key={i}
                  sx={{
                    backgroundColor:
                      getSelectedTabColor(res?.color) || rgba("#f67878", 0.5),
                    minWidth: tabWidth,
                    color: "white",
                    "&.Mui-selected": {
                      backgroundColor: res?.color || "#f67878",
                      color: "white",
                    },
                  }}
                  className="floor-box"
                  label={res?.nameOfFloor}
                  onClick={() => handleTabDetails(res)}
                />
              ))}
          </Tabs>

          {branch ? (
            <div>
              {selectedFloorDetails ? (
                <TabPanel selectedTab={value} />
              ) : floors.length === 0 ? (
                <p style={{ textAlign: "center" }}>No floors were created.</p>
              ) : (
                <p style={{ textAlign: "center" }}>Select A Floor</p>
              )}
            </div>
          ) : (
            <p style={{ textAlign: "center" }}>Select A Branch First.</p>
          )}
        </Box>
      </div>
      <LoadingForm loading={loading} />
    </div>
  );
};

export default AddSeat;
