import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import "./popUpStyle.css";
import { IconButton } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  headersKeyName,
  addSeatFormKeyName,
  alertMessages,
} from "../../utils/floorManagementEnum";
import { useDispatch, useSelector } from "react-redux";
import {
  addFloorAPI,
  editFloorAPI,
  updateCanvasElementAPI,
} from "../../addSeat/floorManagementApi";
import AlertMessage from "../alertMessage/AlertMessage";
import { formatDynamicKey } from "../../utils/formater";
import { LoadingForm } from "../../../../Single Components/LoadingForm";

export default function PopUp({ open, onClose, handleChange }) {
  const dynamicKey = Object.keys(open)[0];
  const dispatch = useDispatch();
  const { seatSelection, branch, selectedElementDetails } = useSelector(
    (store) => store.addSeatSlice
  );

  const dialogInitialForm = {
    floorColor: "",
    floorName: "",
    rename: "",
    color: "",
  };
  const [dialogForm, setDialogForm] = useState(dialogInitialForm);
  const [errorMessageStatus, setErrorMessageStatus] = useState({});

  const onHandleChange = (key, value, e) => {
    setDialogForm((prev) => ({ ...prev, [key]: value }));
    if (e.target.validity.valid) {
      setErrorMessageStatus((prev) => ({ ...prev, [key]: false }));
    } else {
      setErrorMessageStatus((prev) => ({ ...prev, [key]: true }));
    }
  };

  const [ApiAlertMessage, setApiAlertMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = (key) => {
    if (!branch) {
      setApiAlertMessage({
        status: alertMessages.INFO,
        message: "please add a branch first",
      });
      return;
    }

    //update obj for shapes
    let updateShapeObj = {
      _id: selectedElementDetails?._id,
      branchId: branch?._id,
      floorId: selectedElementDetails?.floorId,
      seatSelection:seatSelection,
      type: selectedElementDetails?.type,
    };

    //update floor

    if (key === headersKeyName.ADDFLOOR) {
      if (!dialogForm.floorName) {
        setErrorMessageStatus((prev) => ({
          ...prev,
          [addSeatFormKeyName.FLOORNAME]: true,
        }));
        return;
      } else if (!dialogForm.floorColor) {
        setErrorMessageStatus((prev) => ({
          ...prev,
          [addSeatFormKeyName.FLOORCOLOR]: true,
        }));
        return;
      }
      setLoading(true);
      addFloorAPI(
        {
          branchId: branch._id,
          nameOfFloor: dialogForm.floorName,
          color: dialogForm.floorColor,
          seatSelection: seatSelection,
        },
        setLoading,
        setApiAlertMessage,
        setDialogForm,
        onClose,
        dynamicKey
      );
    } else if (key === headersKeyName.RENAME) {
      if (!dialogForm.rename) {
        console.log(errorMessageStatus);
        setErrorMessageStatus((prev) => ({
          ...prev,
          [headersKeyName.RENAME]: true,
        }));
        return;
      }
      if (selectedElementDetails?.isFloor) {
        editFloorAPI({
          branchId: branch?._id,
          nameOfFloor: dialogForm.rename,
          seatSelection:selectedElementDetails?.seatSelection,
          floorId: selectedElementDetails?._id,
          _id: selectedElementDetails?._id,
        },
        setLoading,
        setApiAlertMessage,
        setDialogForm,
        onClose,
        dynamicKey
      );
      } else {
        updateCanvasElementAPI(
          {
            ...updateShapeObj,
            name: dialogForm.rename,
          },
          setLoading,
          setApiAlertMessage,
          setDialogForm,
          onClose,
          dynamicKey
        );
      }
    } else if (key === headersKeyName.SETCOLOR) {
      if (!dialogForm.color) {
        setErrorMessageStatus((prev) => ({
          ...prev,
          [addSeatFormKeyName.COLOR]: true,
        }));
        return;
      }
      if (selectedElementDetails?.isFloor) {
        editFloorAPI({
          branchId: branch?._id,
          color: dialogForm.color,
          _id: selectedElementDetails?._id,
        });
      } else {
        updateCanvasElementAPI(
          {
            ...updateShapeObj,
            color: dialogForm.color,
          },
          setLoading,
          setApiAlertMessage,
          setDialogForm,
          onClose,
          dynamicKey
        );
      }
    }
  };

  useEffect(() => {
    if (selectedElementDetails) {
      setDialogForm({
        rename:
          selectedElementDetails?.name || selectedElementDetails?.nameOfFloor,
        floorColor: selectedElementDetails?.floorColor,
        floorName: selectedElementDetails?.floorName,
        color: selectedElementDetails?.color,
      });
    }
  }, [selectedElementDetails]);

  //--------------------------------------------------------

  return (
    <div>
      {dynamicKey !== headersKeyName.ADDTABLE &&
        dynamicKey !== headersKeyName.ADDSHAPE &&
        dynamicKey !== headersKeyName.ADDSEATS && (
          <Dialog
            maxWidth="md"
            open={open[dynamicKey]}
            onClose={() => onClose(dynamicKey)}
          >
            <div className="popup-container">
              <div className="dialog-title">
                <div>
                  <h3>{formatDynamicKey(dynamicKey)}</h3>
                </div>
                <div>
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={() => onClose(dynamicKey)}
                    color="error"
                  >
                    <CancelOutlinedIcon />
                  </IconButton>
                </div>
              </div>

              {dynamicKey === headersKeyName.ADDFLOOR && (
                <div>
                  <div className="popup-input-container">
                    <TextField
                      className="input-1"
                      focused
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      value={dialogForm.floorName || ""}
                      onChange={(e) =>
                        onHandleChange(
                          addSeatFormKeyName?.FLOORNAME,
                          e.target.value,
                          e
                        )
                      }
                      required
                      error={errorMessageStatus[addSeatFormKeyName.FLOORNAME]}
                      helperText={
                        errorMessageStatus[addSeatFormKeyName?.FLOORNAME]
                          ? "Floor Name Required"
                          : ""
                      }
                    />

                    <div
                      style={{
                        backgroundColor: dialogForm.floorColor || "#ff2b2b",
                      }}
                      className="display-color-div"
                    />
                  </div>
                  <div className="background-selection-container">
                    <label htmlFor="color-picker">Background Color</label>
                    <div className="input-color-wrapper">
                      <input
                        value={dialogForm.floorColor || ""}
                        onChange={(e) =>
                          onHandleChange(
                            addSeatFormKeyName.FLOORCOLOR,
                            e.target.value,
                            e
                          )
                        }
                        id="color-picker"
                        className="color-picker-input"
                        type="color"
                      />
                    </div>
                    <div>
                      {errorMessageStatus[addSeatFormKeyName.FLOORCOLOR] && (
                        <p className="validation-error">
                          Choose a background color
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {dynamicKey === headersKeyName.RENAME && (
                <div className="popup-input-container">
                  <TextField
                    className="input-1"
                    focused
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    value={dialogForm?.rename || ""}
                    onChange={(e) =>
                      onHandleChange(headersKeyName.RENAME, e.target.value, e)
                    }
                    required
                    error={errorMessageStatus[headersKeyName.RENAME]}
                    helperText={
                      errorMessageStatus[headersKeyName.RENAME]
                        ? "Please enter a valid name"
                        : ""
                    }
                  />
                </div>
              )}
              {dynamicKey === headersKeyName.SETCOLOR && (
                <div className="background-selection-container">
                  <label htmlFor="color-picker">Background Color</label>
                  <div className="input-color-wrapper">
                    <input
                      value={dialogForm.color || ""}
                      onChange={(e) =>
                        onHandleChange(
                          addSeatFormKeyName.COLOR,
                          e.target.value,
                          e
                        )
                      }
                      id="color-picker"
                      className="color-picker-input"
                      type="color"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="popup-submit-btn-container">
              <button
                onClick={() => handleSubmit(dynamicKey)}
                className="popup-submit-btn"
              >
                {dynamicKey === headersKeyName.ADDFLOOR
                  ? "Create"
                  : dynamicKey === headersKeyName.RENAME
                  ? "Rename"
                  : "Add"}
              </button>
            </div>
          </Dialog>
        )}
      <AlertMessage
        status={ApiAlertMessage?.status}
        alertMessage={ApiAlertMessage?.message}
        setApiAlertMessage={setApiAlertMessage}
      />
      <LoadingForm loading={loading} />
    </div>
  );
}
