import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import logoImage from "../../../../../Assets/Images/Icon ionic-logo-buffer.png";
import BannerAvailable from "../../../../../Assets/statusRibbon/available.png";
import BannerDrafted from "../../../../../Assets/statusRibbon/draft.png";
import BannerCompleted from "../../../../../Assets/statusRibbon/post.png";
import { useNavigate } from "react-router-dom";
import {
  viewAllAllowedBranchesAPICall,
  viewAllBranchesAPICall,
} from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { getAllNewProductList } from "../../../Accounts/Vendor/API/vendorBillAPI";
import { vieWareHouseLocationAPICall } from "../../Configuration/wareHouseLocationAPI";
import { useSelector } from "react-redux";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import {
  editBranchReceiptAPICall,
  getSingleViewData,
  postBranchReceiptAPICall,
} from "./BranchReceiptAPI";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import { today } from "../../../../../Js/Date";
import { viewUOMSubListAPICall } from "../../../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall";
import { LoadingForm } from "../../../../Single Components/LoadingForm";

export const CreateBranchReceipt = () => {
  let singleViewId = localStorage.getItem("singleViewById");

  let navigate = useNavigate();
  const containerRef = useRef(null);
  // all allowed branches list
  const allowedBranchList = useSelector(
    (state) => state.allAllowedBranchesSlice.value
  );
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // ware house location
  const wareHouseLocationList = useSelector(
    (state) => state.wareHouseLocationSlice.value
  );
  // new produuct list
  const allNewProductList = useSelector(
    (state) => state.vendorBillSlice.NewProductList
  );
  //single view
  const singleView = useSelector(
    (state) => state.BranchReceiptSlice.singleValue
  );
  // const [sing,setState]= useState("")
  //get uom list
  const uomSubList = useSelector(
    (state) => state.unitOfMeasurmentSlice.subUomList
  );

  useEffect(() => {
    if (singleViewId) {
      getSingleViewData({ id: singleViewId });
    }
  }, [singleViewId]);

  //---
  // source
  // destination

  const [log, setLog] = useState();
  const addRowData = [{ value: "Add Header" }, { value: "Add Line" }];
  const [logView, setLogView] = useState(false);
  const [tableData, setTableData] = useState([
    {
      type: "lines",
      id: Date.now(),
      product: null,
      des: "",
      uom: "",
      onHandQty: "",
      transferQty: "",
      receivedQty: "",
    },
  ]);
  const [addRowInput, setAddRowInput] = useState(addRowData[1]);
  const branchReceiptFormInitialState = {
    sourceBranch: null,
    sourceLoc: "",
    destBranch: null,
    destiLoc: "",
    scheduleDate: "",
    reference: "",
    contactPreson: "",
    Grn: "",
    isSourceChange: false,
    isdestChange: false,
    status: "",
  };
  const [branchReceiptForm, setBranchReceiptForm] = useState(
    branchReceiptFormInitialState
  );

  const [loadingForm,setLoadingForm]=useState(false)

  const [destinationBranch, setDestinationBranch] = useState([]);
  const [sourceBranch, setSourceBranch] = useState([]);
  const [sourceLoc, setSourceLoc] = useState([]);
  const [destinationLoc, setDestinationLoc] = useState([]);
  const [tableDataForBackend, setTableDataForBackend] = useState([]);
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("Error!!");
  const [editClicked, setEditClicked] = useState(false);

  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };

  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };
  const [addRowScroll, setAddRowScroll] = useState(false);
  const addRow = () => {
    if (addRowInput?.value === "Add Header") {
      setTableData([
        ...tableData,
        { type: "header", id: Date.now(), headerValue: "" },
      ]);
    } else if (addRowInput?.value === "Add Line") {
      setTableData([
        ...tableData,
        {
          type: "lines",
          id: Date.now(),
          product: null,
          des: "",
          uom: "",
          onHandQty: "",
          transferQty: "",
          receivedQty: "",
          uomName: "",
        },
      ]);
    }
    setAddRowScroll(!addRowScroll);
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [addRowScroll]);

  const handleInputChange = (id, field) => (e, newValue) => {
    const { value } = e.target;
    const updatedData = tableData.map((row, index) => {
      if (index === id) {
        if (field === "product") {
          return {
            ...row,
            [field]: newValue,
            des: newValue?.productName,
            uomName: newValue?.uomName,
            uom: newValue?.uom,
            onHandQty: newValue?.stock,
          };
        } else {
          return { ...row, [field]: value === 0 ? newValue : e.target.value };
        }
      }
      return row;
    });
    setTableData(updatedData);
  };

  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };
  const clickLogView = () => {
    setLogView(!logView);
  };
  const backButtonFn = () => {
    if (branchReceiptForm?.isEdit === false) {
      const userConfirmed = window.confirm(
        "Changes you made may not be saved."
      );

      if (userConfirmed) {
        navigate("/userdashboard/inventory/operation/branchreceipt");
      }
    } else {
      navigate("/userdashboard/inventory/operation/branchreceipt");
    }
  };

  useEffect(() => {
    viewAllAllowedBranchesAPICall({ employeeId: loginResponse?.data?._id });
    viewAllBranchesAPICall();
    getAllNewProductList();
    viewUOMSubListAPICall();
  }, []);

  const getBranchReceiptForm = (field) => (e, newValue) => {
    const { value } = e.target;
    if (field === "sourceBranch") {
      setBranchReceiptForm({
        ...branchReceiptForm,
        [field]: newValue,
        isSourceChange: true,
        isdestChange: false,
      });
    } else if (field === "destBranch") {
      setBranchReceiptForm({
        ...branchReceiptForm,
        [field]: newValue,
        isSourceChange: false,
        isdestChange: true,
      });
    } else {
      setBranchReceiptForm({
        ...branchReceiptForm,
        [field]: value !== 0 ? value : newValue,
      });
    }
  };
  useEffect(() => {
    if (branchReceiptForm?.sourceBranch !== null) {
      vieWareHouseLocationAPICall({
        branchId: branchReceiptForm?.sourceBranch?._id,
      });
    }
  }, [branchReceiptForm?.sourceBranch]);

  useEffect(() => {
    if (branchReceiptForm?.destBranch !== null) {
      vieWareHouseLocationAPICall({
        branchId: branchReceiptForm?.destBranch?._id,
      });
    }
  }, [branchReceiptForm?.destBranch]);

  useEffect(() => {
    if (allowedBranchList?.length !== 0) {
      let data = allowedBranchList?.filter((obj) => {
        if (obj?._id !== branchReceiptForm?.destBranch?._id) {
          return obj;
        }
      });
      setSourceBranch(data);
    }
  }, [allowedBranchList, branchReceiptForm?.destBranch]);

  /// filter destination branch with source branch
  useEffect(() => {
    if (allBranchesList?.length !== 0) {
      let data = allBranchesList?.filter((obj) => {
        if (obj?._id !== branchReceiptForm?.sourceBranch?._id) {
          return obj;
        }
      });
      setDestinationBranch(data);
    }
  }, [allBranchesList, branchReceiptForm?.sourceBranch]);

  useEffect(() => {
    if (wareHouseLocationList !== undefined) {
      if (branchReceiptForm?.isSourceChange === true) {
        setSourceLoc(wareHouseLocationList);
      }

      if (branchReceiptForm?.isdestChange === true) {
        setDestinationLoc(wareHouseLocationList);
      }
    }
  }, [wareHouseLocationList]);

  /// edit section

  useEffect(() => {
    if (singleView !== undefined) {
      setBranchReceiptForm({
        ...branchReceiptForm,
        sourceBranch: filterObjFromList(
          "_id",
          allBranchesList,
          "sourcebranchId",
          singleView
        ),
        destBranch: filterObjFromList(
          "_id",
          allBranchesList,
          "destinationBranchId",
          singleView
        ),
        sourceLoc: singleView?.sourceLocation,
        destiLoc: singleView?.destinationLocation,
        scheduleDate: singleView?.date,
        reference: singleView?.referenceIn,
        contactPreson: singleView?.contactPerson,
        Grn: singleView?.grnNumber,
        editBtn: true,
        isEdit: true,
        status: singleView?.status,
      });
      setTableData(
        singleView?.products?.map((r, i) => {
          if (r?.line_type === "header") {
            return {
              type: r?.line_type,
              HeaderValue: r?.label,
            };
          } else {
          
            return {
              type: r?.line_type,
              product: filterObjFromList("_id", allNewProductList, "_id", r),
              des: r?.description,
              uom: r?.uom,
              uomName: filterObjFromList("_id", uomSubList, "uom", r)?.uomName,
              transferQty: r?.transferQty,
              prodId: r?._id,
              receivedQty: r?.receivedQty ? r?.receivedQty : r?.transferQty,
              unitPrice: r?.unitPrice,
              spMargin: r?.spMargin,
              posCat: r?.posCat,
              mainCatgeoryId: r?.mainCatgeoryId,
            };
          }
        })
      );
      setLog(
        singleView?.log?.map((r, i) => {
          return r;
        })
      );
    } else {
      setEditClicked(false);
      setBranchReceiptForm(branchReceiptFormInitialState);
      // setProductTable([])
    }
  }, [singleView, allBranchesList, allNewProductList]);


  // useEffect(() => {
  //   if (singleView !== undefined && sourceLoc.length !== 0) {
  //     setBranchReceiptForm({
  //       ...branchReceiptForm,
  //       sourceLoc: filterObjFromList("_id", sourceLoc, "source", singleView),
  //       isdestChange: true,
  //       isSourceChange: false,
  //       destBranch: filterObjFromList(
  //         "_id",
  //         allBranchesList,
  //         "destinationBranchId",
  //         singleView
  //       ),
  //     });
  //   }
  // }, [sourceLoc, allBranchesList]);
  // useEffect(() => {
  //   if (singleView !== undefined) {
  //     setBranchReceiptForm({
  //       ...branchReceiptForm,
  //       destiLoc: filterObjFromList(
  //         "_id",
  //         destinationLoc,
  //         "destination",
  //         singleView
  //       ),
  //     });
  //   }
  // }, [destinationLoc]);


  // set table for backend
  useEffect(() => {
    setTableDataForBackend(
      tableData?.map((r) => {
        if (r?.type === "header") {
          return {
            line_type: r?.type,
            label: r?.HeaderValue,
          };
        } else {
          return {
            line_type: r?.type,
            _id: r?.prodId,
            product: r?.product?.productName,
            uom: r?.uom,
            uomName: r?.uomName,
            transferQty: Number(r?.transferQty),
            inhand: Number(r?.onHandQty),
            description: r?.des,
            receivedQty: Number(r?.receivedQty),
            unitPrice: r?.unitPrice,
            spMargin: r?.spMargin,
            posCat: r?.posCat,
            mainCatgeoryId: r?.mainCatgeoryId,
          };
        }
      })
    );
  }, [tableData]);

  const editBody = {
    _id: singleView?._id,
    products: tableDataForBackend,
    postDate: today,
  };
  const editFunction = () => {
    let editFn = () => {
      setEditClicked(false);
      setBranchReceiptForm({ ...branchReceiptForm, isEdit: true });
    };
    editBranchReceiptAPICall(
      editBody,
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      editFn,setLoadingForm
    );

  };
  const editBtnEnableFn = () => {
    setBranchReceiptForm({ ...branchReceiptForm, isEdit: false });
    setEditClicked(true);
  };

  const postBtnFn = () => {
    postBranchReceiptAPICall(
      { _id: singleView?._id, postDate: today },
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,setLoadingForm
    );
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !branchReceiptForm?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [branchReceiptForm?.isEdit]);
  return (
    <div className="global-page-parent-container">
      <div
        className="new-global-white-bg-container"
        style={{ padding: "0 2%" }}
      >
        <div className="new-global-white-bg-icon-container">
          <div className="new-global-white-bg-icon-container-left">
            <Tooltip title="Back" onClick={backButtonFn}>
              <IconButton>
                <i class="bi bi-chevron-left"></i>
              </IconButton>
            </Tooltip>
            {(branchReceiptForm.status === "Drafted" ||
              branchReceiptForm.status === "Available") && (
              <Tooltip title="Edit">
                <IconButton
                  onClick={
                    branchReceiptForm?.isEdit === true
                      ? editBtnEnableFn
                      : editFunction
                  }
                >
                  {branchReceiptForm?.isEdit === true ? (
                    <i class="bi bi-pencil-square edit-icon1"></i>
                  ) : (
                    <i class="bi bi-check2-square edit-icon"></i>
                  )}
                </IconButton>
              </Tooltip>
            )}
            {/* <Tooltip title="Save">
             <IconButton>
               <i class="bi bi-floppy save-icon"></i>
             </IconButton>
         </Tooltip> */}
            {!editClicked &&
              (branchReceiptForm.status === "Drafted" ||
                branchReceiptForm.status === "Available") && (
                <button onClick={postBtnFn} className="btn btn-post">
                  Post
                </button>
              )}
          </div>

          <div className="status-container">
            {branchReceiptForm.status === "Available" ? (
              <img src={BannerAvailable} alt="" />
            ) : branchReceiptForm.status === "Drafted" ? (
              <img src={BannerDrafted} alt="" />
            ) : branchReceiptForm.status === "Completed" ? (
              <img src={BannerCompleted} alt="" />
            ) : null}
          </div>
        </div>
        <hr className="global-hr" />
        <h4 className="global-name">{branchReceiptForm.reference}</h4>
        <div className="new-global-input-container">
          <div className="new-global-single-input auto-complete-new">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={sourceBranch || []}
              getOptionLabel={(option) =>
                `${option?.storeCode}-${option?.branchName}`
              }
              renderInput={(params) => (
                <TextField {...params} label="Source Branch*" focused />
              )}
              value={branchReceiptForm?.sourceBranch}
              onChange={getBranchReceiptForm("sourceBranch")}
              disabled
            />
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Source Location*"
              variant="outlined"
              type="text"
              focused
              value={branchReceiptForm?.sourceLoc}
              disabled
            />
            {/* <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={sourceLoc || []}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Source Location*" focused />
              )}
              value={branchReceiptForm?.sourceLoc}
              onChange={getBranchReceiptForm("sourceLoc")}
              disabled
            /> */}
          </div>
          <div className="new-global-single-input">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={destinationBranch || []}
              getOptionLabel={(option) =>
                `${option?.storeCode}-${option?.branchName}`
              }
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Destination Branch*" focused />
              )}
              value={branchReceiptForm?.destBranch}
              onChange={getBranchReceiptForm("destBranch")}
              disabled
            />
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Destination Location*"
              variant="outlined"
              type="text"
              focused
              value={branchReceiptForm?.destiLoc}
              disabled
            />
            {/* <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={destinationLoc || []}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Destination Location*" focused />
              )}
              value={branchReceiptForm?.destiLoc}
              onChange={getBranchReceiptForm("destiLoc")}
              disabled
            /> */}
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Schedule Date*"
              variant="outlined"
              type="date"
              focused
              value={branchReceiptForm?.scheduleDate}
              onChange={getBranchReceiptForm("scheduleDate")}
              disabled
            />
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Reference*"
              variant="outlined"
              type="text"
              focused
              value={branchReceiptForm?.reference}
              onChange={getBranchReceiptForm("reference")}
              disabled
            />
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Contact Person*"
              variant="outlined"
              type="text"
              focused
              value={branchReceiptForm?.contactPreson}
              onChange={getBranchReceiptForm("contactPreson")}
              disabled
            />
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="GRN*"
              variant="outlined"
              type="text"
              focused
              value={branchReceiptForm?.Grn}
              disabled
            />
          </div>
        </div>
      </div>
      <div className="new-global-white-bg-container">
        <div className="global-product-table">
          <table>
            <thead>
              <tr>
                <th>Product</th>
                <th>Description</th>
                <th>UOM</th>
                <th>Transfer Qty</th>
                <th>Received Qty</th>
                <th style={{ backgroundColor: "#fff" }}></th>
              </tr>
            </thead>

            <tbody>
              {tableData?.map((item, index) => (
                <tr
                  key={index}
                  ref={index === tableData.length - 1 ? containerRef : null}
                >
                  {item.type === "Header" ? (
                    <>
                      <td colSpan="5">
                        <input
                          type="text"
                          value={item.headerValue}
                          // onChange={(e) => handleInputChange(e, item.id, 'value')}
                        />
                      </td>
                      <td style={{ border: "none", width: "4%" }}>
                        <IconButton
                          onClick={() => handleDelete(index)}
                          style={{ height: "30px" }}
                        >
                          <i
                            className="bi bi-trash3"
                            style={{ color: "#db0000", fontSize: "1.3rem" }}
                          ></i>
                        </IconButton>
                      </td>
                    </>
                  ) : (
                    <>
                      <td style={{ minWidth: "200px" }}>
                        <div className="product-table-auto-complete">
                          <Autocomplete
                            options={allNewProductList || []}
                            style={{ maxHeight: "100px" }}
                            getOptionLabel={(option) => option?.productName}
                            renderInput={(params) => <TextField {...params} />}
                            value={item.product}
                            // onChange={handleInputChange(item.id, 'product')}
                            disabled
                          />
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          value={item.des}
                          // onChange={handleInputChange(item.id, 'des')}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={item.uomName}
                          // onChange={handleInputChange(item.id, 'uom')}
                          disabled
                        />
                      </td>

                      <td>
                        <input type="text" value={item.transferQty} disabled />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={item.receivedQty}
                          onChange={handleInputChange(index, "receivedQty")}
                          disabled={branchReceiptForm?.isEdit}
                        />
                      </td>
                      <td style={{ border: "none", width: "4%" }}>
                        <IconButton
                          onClick={() => handleDelete(index)}
                          style={{ height: "30px" }}
                          disabled
                        >
                          <i
                            className="bi bi-trash3"
                            style={{ color: "#db0000", fontSize: "1.3rem" }}
                          ></i>
                        </IconButton>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div className='add-row-btn-container'>
              <div className='global-single-input auto-complete add-line-autocomplete' style={{width:"76%"}}>
           <Autocomplete
           options={addRowData||[]}

           getOptionLabel={(option) => option?.value}
           renderInput={(params) => <TextField {...params} />}
           value={addRowInput}
           onChange={(e,newValue)=>setAddRowInput(newValue)}
           renderClear={() => null}
/>
           </div>
           <button onClick={()=>addRow()} className='add-row-btn'>+</button>
         </div> */}
      </div>

      <div
        className="new-global-white-bg-container new-log-container"
        style={{ padding: "4px" }}
      >
        <div
          style={{ padding: "8px", cursor: "pointer" }}
          onClick={clickLogView}
        ></div>
        <div
          className={logView ? "pointer-up" : "pointer"}
          onClick={clickLogView}
          style={{ cursor: "pointer" }}
        ></div>
        {logView && (
          <div className="single-log-container">
            {log?.map((r, i) => (
              <div className="single-log-item">
                <div className="single-log-item-line-image">
                  <hr className="single-log-item-line" />
                  <div className="single-log-item-image">
                    <img src={logoImage} alt="" />
                  </div>
                  <hr className="single-log-item-line" />
                </div>
                <p className="heading">{r?.status}</p>
                <p className="name">{r?.empId}</p>
                <p className="date-time">{r?.date}</p>
              </div>
            ))}
          </div>
        )}

        {/* <div className='single-log-container-scroll-line'></div> */}
      </div>

      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
          <LoadingForm loading={loadingForm}/>

    </div>
  );
};
