import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import "./tabPanelStyle.css";
import {
  deleteShapesAPI,
  updateCanvasElementAPI,
} from "../../addSeat/floorManagementApi";
import { setSelectedElementDetails } from "../../addSeat/addSeatSlice";
import { shapeTypes, structures } from "../../utils/floorManagementEnum";
import LineSegmentTool from "../icons/LineSegmentTool";
import SplitFloorTool from "../icons/SplitFloorTool";
import StarTool from "../icons/StarTool";
import Chair from "../icons/Chair";

const TabPanel = () => {
  const { selectedFloorDetails, branch, selectedElementDetails } = useSelector(
    (store) => store.addSeatSlice
  );
  const dispatch = useDispatch();
  const [shapes, setShapes] = useState(selectedFloorDetails?.floorCanvas || []);
  const [debouncedUpdate, setDebouncedUpdate] = useState(null);

  const containerRef = useRef();
  //handle selected
  const handleShapeSelected = (shape) => {
    dispatch(setSelectedElementDetails(shape));
  };

  useEffect(() => {
    setShapes(selectedFloorDetails?.floorCanvas || []);
  }, [selectedFloorDetails]);

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;

      setShapes((prevShapes) =>
        prevShapes.map((s) => ({
          ...s,
          position: {
            x: (s.position.x / s.containerWidth) * containerWidth,
            y: (s.position.y / s.containerWidth) * containerWidth,
          },
          containerWidth: containerWidth,
        }))
      );
    }
  }, [containerRef.current?.clientWidth]);

  const handleSizeChange = (id, size, position, shape) => {
    const containerWidth = containerRef.current?.clientWidth || 0;
    const containerHeight = (containerWidth * 3) / 4;

    let maxPositionX = containerWidth - size.width;
    let maxPositionY = containerHeight - size.height;

    if (shape.type === shapeTypes.CHAIR) {
      size.width = 25;
      size.height = 25;
    }

    const boundedPosition = {
      x: Math.min(Math.max(position.x, 0), maxPositionX),
      y: Math.min(Math.max(position.y, 0), maxPositionY),
    };

    setShapes((prevShapes) =>
      prevShapes.map((s) =>
        s._id === id ? { ...s, size, position: boundedPosition } : s
      )
    );

    clearTimeout(debouncedUpdate);
    const timeout = setTimeout(() => {
      updateCanvasElementAPI({
        type: shape.type,
        _id: shape._id,
        transNo: 1,
        floorId: shape.floorId,
        structure: shape.structure,
        size: size,
        position: boundedPosition,
        color: shape.color,
        branchId: branch._id,
      });
    }, 500);
    setDebouncedUpdate(timeout);
  };

  // ==== option  selectors ====
  const [menuPosition, setMenuPosition] = useState({ x: '0px', y: '0px' });
  const [isVisible, setIsVisible] = useState(false);

  const handleContextMenu = (e,_,position) => {
    if (!selectedElementDetails) return;
    const containerWidth = containerRef.current.clientWidth;
    e.preventDefault();
    setIsVisible(true);

    const clickX = position.x;
    const clickY = position.y;
    const screenW = containerWidth;
    const screenH = containerWidth;
    const menuW = 100; // Width of the context menu
    const menuH = 100; // Height of the context menu

    // Check if there's enough space on the right
    const maxX = screenW - menuW;
    const newY = clickY > screenH - menuH ? screenH - menuH : clickY;
    const newX = clickX > maxX ? maxX : clickX;

    setMenuPosition({ x: `${newX}px`, y: `${newY}px` });
  };

  const handleItemClick = (action) => {
    switch (action) {
      case 'copy':
        // Implement copy action
        break;
      case 'delete':
        // Implement delete action
        break;
      default:
        break;
    }
    setIsVisible(false);
  };

 

  return (
    <div className="tab-panel-container" ref={containerRef}>
      <div className="sub-tab-wrapper"   >
        {shapes?.length > 0 ? (
          shapes?.map((shape) => (
            <Rnd
              enableResizing={shape.type === shapeTypes.CHAIR ? false : true}
              key={shape._id}
              default={{
                x: shape.position.x,
                y: shape.position.y,
                width: shape.size.width,
                height: shape.size.height,
              }}
              minWidth={10}
              minHeight={10}
              bounds="parent"
              onResize={(e, direction, ref, delta, position) =>
                handleSizeChange(
                  shape._id,
                  { width: ref.offsetWidth, height: ref.offsetHeight },
                  position,
                  shape
                )
              }
              onDragStop={(e, d) =>
                handleSizeChange(
                  shape._id,
                  { width: shape.size.width, height: shape.size.height },
                  { x: d.x, y: d.y },
                  shape
                )
              }
            >
              {/* <div onContextMenu={(e) => handleContextMenu(e, shape,{x:shape.position.x,y:shape.position.y})}> */}
              {shape.type === shapeTypes.SHAPE ? (
                <div
                  onClick={() => handleShapeSelected(shape)}
                  className="shape-container "
                  style={{ border: "transparent" }}
                >
                  {shape?.structure === structures.LINESEGMENTTOOL ? (
                    <LineSegmentTool
                      color={
                        selectedElementDetails?._id === shape._id
                          ? "#e91d1d"
                          : shape?.color
                      }
                      width={shape?.size?.width}
                      height={shape?.size?.height}
                    />
                  ) : shape?.structure === structures.SPLITFLOOR ? (
                    <div
                      className="shape-container"
                      style={{
                        backgroundColor:
                          selectedElementDetails?._id === shape._id
                            ? "#e91d1d"
                            : shape?.color,
                      }}
                    />
                  ) : shape?.structure === structures.HORIZONTAL ? (
                    <div
                      className="shape-container"
                      style={{
                        backgroundColor:
                          selectedElementDetails?._id === shape._id
                            ? "#e91d1d"
                            : shape?.color,
                      }}
                    />
                  ) : shape?.structure === structures.STAR ? (
                    <StarTool
                      color={
                        selectedElementDetails?._id === shape._id
                          ? "#e91d1d"
                          : shape?.color
                      }
                      width={shape?.size?.width}
                      height={shape?.size?.height}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : shape.type === shapeTypes.TABLE ? (
                <div
                  onClick={() => handleShapeSelected(shape)}
                  className={`shape-container ${shape.structure} ${
                    selectedElementDetails?._id === shape._id ? "selected" : ""
                  }`}
                  style={{
                    background: shape.color,
                  }}
                >
                  {/* <input type="text" readOnly value={shape.name} /> */}
                  <span>{shape.name}</span>
                </div>
              ) : (
                <div
                  className="chair-container"
                  onClick={() => handleShapeSelected(shape)}
                >
                  <Chair
                    color={
                      selectedElementDetails?._id === shape._id
                        ? "#e91d1d"
                        : shape?.color
                    }
                    width={shape?.size?.width}
                    name={shape?.name}
                    height={shape?.size?.height}
                  />
                </div>
              )}
               {/* </div> */}
            </Rnd>
          ))
        ) : (
          <div className="not-found-container">
            {" "}
            <p>No Floor Plan added .!</p>
          </div>
        )}
          {isVisible && (
        <div
          className="context-menu"
          style={{ top: menuPosition.y, left: menuPosition.x }}
        >
          <div
            className="context-menu-item"
            onClick={() => handleItemClick("copy")}
          >
            Copy
          </div>
          <div
            className="context-menu-item"
            onClick={() => handleItemClick("delete")}
          >
            Delete
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default TabPanel;
