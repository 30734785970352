import axios from "axios"
import { HEADERS, HEADER_QUERY } from "../../../../API/UrlAndPaths"
import store from "../../../../Redux/store"
import { setIsEdit, setIsEditClicked, setTableLoading, storProductListForSpecialItems, storeSpecialItemList, storeSpecialItemListForBilling, storeSpecialItemSingleList } from "./specialItemsSlice"
import { setErrorSnack, setSnackMsg, setSuccessSnack } from "../Billing/Slice/billingScreenSlice"
import { setSubmitLoading } from "../../../../Redux/Dashboard/mainDashBoardSlice"

export const productlistSpecialItemsAPI=async(body)=>{    store.dispatch(setTableLoading(true))
    await axios.post("readymade/viewProductlistforSpecialOffer",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(storProductListForSpecialItems(res.data))
            }
        })
        .catch((err)=>{
            store.dispatch(storProductListForSpecialItems([]))
        })
}
export const listSpecialItemsAPI=async(body)=>{
    store.dispatch(setTableLoading(true))
    await axios.post("pos/viewSpecialOfferList",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(storeSpecialItemList(res.data))
                store.dispatch(setTableLoading(false))
            }
        })
        .catch((err)=>{
            store.dispatch(storeSpecialItemList([]))
            store.dispatch(setTableLoading(false))
        })
}
export const listSpecialItemsForBilling=async(body)=>{
    await axios.post("pos/viewSpecialOffer",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(storeSpecialItemListForBilling(res.data))
            }
        })
        .catch((err)=>{
            store.dispatch(storeSpecialItemListForBilling([]))
        })
}
export const specialItemsSingleView=async(body,actions)=>{
    store.dispatch(setSubmitLoading(true))

    await axios.post("pos/viewSpecialOfferOrComboById",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(storeSpecialItemSingleList(res.data))
                actions()
                store.dispatch(setSubmitLoading(false))
            }
        })
        .catch((err)=>{
                store.dispatch(setSubmitLoading(false))
                let message=err.response.status===500?"Internal Server Error !!":err.response.data
                store.dispatch(setSnackMsg(message))              
                store.dispatch(setErrorSnack(true))
        })
}

export const addListSpecialItemsAPI=async(body,functions)=>{
    store.dispatch(setSubmitLoading(true))
    await axios.post("pos/addSpecialOffer",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setSnackMsg("Offer Addedd"))
                store.dispatch(setSuccessSnack(true))
                functions()
                store.dispatch(setSubmitLoading(false))
            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.data
            store.dispatch(setSnackMsg(message))              
            store.dispatch(setErrorSnack(true))
            store.dispatch(setSubmitLoading(false))
        })
}
export const editListSpecialItemsAPI=async(body)=>{
    store.dispatch(setSubmitLoading(true))
    await axios.put("pos/editSpecialOffer",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setSnackMsg("List Edited"))              
                store.dispatch(setSuccessSnack(true))
                store.dispatch(setSubmitLoading(false))
                store.dispatch(setIsEditClicked(false))
                store.dispatch(setIsEdit(true))
                specialItemsSingleView({id:res.data?._id})

            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.data
            store.dispatch(setSnackMsg(message))              
            store.dispatch(setErrorSnack(true))
            store.dispatch(setSubmitLoading(false))
        })
}
export const deleteListSpecialItemsAPI=async(body,functions)=>{
    store.dispatch(setSubmitLoading(true))
    await axios.delete("pos/deleteSpecialOffer",
                {headers:HEADER_QUERY,data:body})
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setSnackMsg("List Deleted"))              
                store.dispatch(setSuccessSnack(true))
                store.dispatch(setSubmitLoading(false))
                functions()
            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.data
            store.dispatch(setSnackMsg(message))              
            store.dispatch(setErrorSnack(true))
            store.dispatch(setSubmitLoading(false))
        })
}

