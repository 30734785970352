import axios from "axios";
import { HEADERS } from "../../../../API/UrlAndPaths";
import store from "../../../../Redux/store";
import { setMainDashboardData } from "./homeDashboardSlice";

//get main dashboard data 
export const getMainDashboardDataAPI = async (data)=>{
    try {
        const response = await axios.post('account/mainDashBoard',{},HEADERS)
        if(response.status === 200){
            // dispatch action to redux store
            store.dispatch(setMainDashboardData(response.data))
        }
    } catch (error) {
        console.log(error)
        // dispatch action to redux store
        store.dispatch(setMainDashboardData(null))

        
    }
}