import {
  Autocomplete,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  Radio,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import React, { useEffect, useRef, useState } from "react";
import uploadFile from "../../../../../Assets/Images/Icon feather-upload.png";
import { CloseOutlined } from "@mui/icons-material";
import logoImage from "../../../../../Assets/Images/Icon ionic-logo-buffer.png";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentTime, today } from "../../../../../Js/Date";
import { useNavigate } from "react-router-dom";
import { viewVendorListAPICall } from "../API/vendorListAPICall";
import { chartOfAccountListApiCall } from "../../../../../API/AccountUpdated/chartOfAccountAPI";
import { journalListApiCall } from "../../../../../API/AccountUpdated/journalAPI";
import {
  companyProfileInfoForUser,
  viewAllAllowedBranchesAPICall,
  viewAllBranchesAPICall,
} from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { getAllActiveEmployeesAPICall } from "../../../../../API/Staff/HRM/employeeAPI";
import {
  draftVendorPaymentAPICall,
  editVendorPaymentAPICall,
  generateVendorPaymentNameAPICall,
  postVendorPaymentAPICall,
  returnVendorPaymentAPI,
  showVendorBillApi,
  updateVendorPaymentAPI,
  vendorPaymentSingleViewApi,
} from "../API/vendorPaymenAPICall";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import postRibbon from "../../../../../Assets/statusRibbon/post.png";
import returned from "../../../../../Assets/statusRibbon/return.png";
import draftRibbon from "../../../../../Assets/statusRibbon/draft.png";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { viewMultiCurrencyConfiguration } from "../../../../../API/Purchase Manager/configuration";
import ReactToPrint from "react-to-print";
import Template from "./VendorPaymentPrint";
import TemplateVendor from "./VendorPaymentVendorPrint";
import {
  set_Back_button_action,
  setFilterActive,
} from "../../../../Single Components/listSlice/filterCategorySlice";
import { TableVirtuoso } from "react-virtuoso";
import { get_vendor_list_row_data_list } from "../Slice/vendorsListSlice";
const styles = `
  .advs-pay-amt-cntr{
    display:flex;
    justify-content:flex-end;
  
  }
  .advs-pay-amt-cntr > div {
    display:inherit;
    flex-direction:column;
    padding: 1em;
    // width:20%;
    gap: 0.5em;
  }
  .advs-pay-amt-cntr  .adv-amt-cntr{
    display:inherit;
    font-weight: 600;
  }
  .advs-pay-amt-cntr  .adv-amt-cntr.adv-tt-amt{
    font-size: 20px;
  }
  .advs-pay-amt-cntr  .adv-amt-cntr.adv-tt-amt :nth-child(1){
    width:4em;
  
  }
  .advs-pay-amt-cntr  .adv-amt-cntr :nth-child(1){
    background-color:white;
    width:5em;
    display:block;
    color:#6e706f;
    text-align: end;
    padding-right: 1em;
   
  }
`;

export const CreateVendorPayments = ({backBtn=true,handleClosePaymentDialog,computeBankReconciliation}) => {
  let singleViewId = localStorage.getItem("singleViewById");
  let paymentsPrintNew = useRef();
  let navigateTo = useNavigate();
  const numToWords = require("num-words");
  const editedData = useSelector((state) => state.vendorPaymentSlice?.rowData);
  const allProductList = useSelector(
    (state) => state.vendorBillSlice.productsList
  );
  const journalList = useSelector((state) => state.journalSlice.value);
  const vendorList = useSelector((state) => state.vendorsListSlice.apiResList);
  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );

  const vendorPaymentAccounts = chartOfAccountList?.filter(
    (item) => item.type === "Current asset"
  );

  //Active Employee List
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const allowedBranchList = useSelector(
    (state) => state.allAllowedBranchesSlice.value
  );
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const generatedName = useSelector(
    (state) => state.vendorPaymentSlice.generateName
  );
  const MultiCurrencyView = useSelector(
    (state) => state.ConfigurationSlice.MultiCurrency
  );
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const companyProfileData = useSelector(
    (state) => state.companyProfileSlice.userValue
  );
  const vendorBillsFromStore = useSelector(
    (state) => state.vendorPaymentSlice.vendorBills
  );
  const [vendorBills, setVendorBills] = useState();
  const [paymentName, setPaymentName] = useState("###");
  const addRowData = ["Add Line"];
  const [isActiveTable, setIsActiveTable] = useState("invoiceLines");
  const [addRowInput, setAddRowInput] = useState(addRowData[0]);
  const [tableData, setTableData] = useState([
    {
      type: "lines",
      id: Date.now(),
      account: null,
      label: "",
      branch: null,
      employee: null,
      amount: "",
    },
  ]);
  const [tableDataupdate, setTableDataUpdate] = useState();
  // console.log(tableDataupdate);
  const [image, setImage] = useState([]);
  const [displayImage, setDisplayImage] = useState([]);
  const [receivedImgUrl, setReceivedImgUrl] = useState([]);
  const [branchList, setBranchList] = useState();

  const [logView, setLogView] = useState(false);
  const containerRef = useRef(null);
  const [validationAlert, setValidationAlert] = useState({});
  // filtered journal list
  const [paymentTypeList, setPaymentTypeList] = useState([]);

  const [log, setLog] = useState([]);

  const vendorPaymentFormInitialState = {
    vendor: null,
    paymentType: null,
    type: "Manual",
    chequeDate: today,
    chequeNo: "",
    amount: "",
    paymentDate: today,
    paymentReference: "",
    amountInWords: "",
    paymentMethod: "otherPayment",
    customerNotes: "",
    termsAndConditions: "",
    isEdit: false,
    status: "",
    branchId: null,
    currency: null,
    paymentMode: null,
    billId: null,
    account: null,
  };
  const [vendorPaymentForm, setVendorPaymentForm] = useState(
    vendorPaymentFormInitialState
  );
  const [vendorPaymentFomUpdated, setVendorPaymentFormUpdated] = useState();
  const [tableDataForBackend, setTableDataForBackend] = useState([]);
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false,
  });

  const [editDataId, setEditDataId] = useState("");
  const [editClicked, setIsEditClicked] = useState(false);
  const [importPopup, setImportPopup] = useState(false);
  const [alertPopup, setAlertPopup] = useState(false);
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  // console.log(vendorPaymentFomUpdated);
  useEffect(() => {
    viewVendorListAPICall();
    chartOfAccountListApiCall();
    journalListApiCall();
    viewAllBranchesAPICall();
    getAllActiveEmployeesAPICall();
    // generateVendorPaymentNameAPICall();
    vendorPaymentSingleViewApi({ _id: singleViewId });

    viewAllAllowedBranchesAPICall({ employeeId: loginResponse?.data?._id });
    viewAllCurrencyUpdated({ status: 0 });
    viewMultiCurrencyConfiguration();
    companyProfileInfoForUser();
  }, []);
  //update by default currency
  useEffect(() => {
    if (
      !MultiCurrencyView?.isMultiCurrency &&
      currencyListUpdated &&
      editedData === undefined &&
      companyProfileData
    ) {
      const defaultCurrency =
        currencyListUpdated?.find(
          (data) => data._id === companyProfileData?.currency
        ) || null;
      setVendorPaymentForm((prev) => ({ ...prev, currency: defaultCurrency }));
    }
  }, [
    currencyListUpdated,
    MultiCurrencyView?.isMultiCurrency,
    companyProfileData,
  ]);

  const [addRowScroll, setAddRowScroll] = useState(false);

  const addRow = () => {
    if (addRowInput === "Add Header") {
      setTableData([
        ...tableData,
        { type: "header", id: Date.now(), headerValue: "" },
      ]);
    } else if (addRowInput === "Add Line") {
      setTableData([
        ...tableData,
        {
          type: "lines",
          id: Date.now(),
          account: null,
          label: "",
          branch: null,
          employee: null,
          amount: "",
        },
      ]);
    }
    setAddRowScroll(!addRowScroll);
    // setAddRowInput(null);
  };

  useEffect(() => {
    if (containerRef.current) {
      setTimeout(() => {
        containerRef.current.scrollIntoView({
          index: tableData.length,
          behavior: "smooth",
          align: "end",
          behaviour: "smooth",
        });
      }, 0);
    }
  }, [addRowScroll]);

  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const changeImage = (e) => {
    // if (typeof e.target.files[0] === "string") {
    //   setImage([...image, e.target.files[0]]);
    // } else if (e.target.files[0] !== undefined && e.target.files[0] !== null) {
    //   setImage([...image, URL.createObjectURL(e.target.files[0])]);
    // }
    setImage([...image, e.target.files[0]]);
    setDisplayImage([
      ...displayImage,
      { url: URL.createObjectURL(e.target.files[0]) },
    ]);
  };

  const removeImageFn = (i) => {
    // image.splice(i, 1);
    // setImage([...image]);
    if (editedData === undefined) {
      image.splice(i, 1);
    } else {
      receivedImgUrl?.length !== 0 &&
        image.splice(i - receivedImgUrl?.length, 1);
      receivedImgUrl.splice(i, 1);
    }

    displayImage.splice(i, 1);
    setImage([...image]);
    setDisplayImage([...displayImage]);
    setReceivedImgUrl([...receivedImgUrl]);
  };

  const clickLogView = () => {
    setLogView(!logView);
  };

  // handleInputChange
  // console.log(tableDataupdate);
  const handleInputChange = (id, field) => (e, newValue) => {
    const { value } = e.target;
    const updatedData = tableData.map((row, index) => {
      if (index === id) {
        return {
          ...row,
          [field]: newValue !== undefined ? newValue : e.target.value,
        };
      }
      return row;
    });
    setTableData(updatedData);
    if (updatedData) {
      const totalAmount = updatedData.reduce((sum, res) => {
        if (res.type === "lines") {
          return sum + (Number(res?.amount) || 0);
        }
        return sum;
      }, 0);
      setVendorPaymentForm({ ...vendorPaymentForm, amount: totalAmount });
    }
  };

  const handleInputChangeUpdate = (id, field) => (e, newValue) => {
    const { value } = e.target;
    const updatedData = tableData.map((row, index) => {
      if (index === id) {
        return {
          ...row,
          [field]: newValue !== undefined ? newValue : e.target.value,
        };
      }
      return row;
    });
    setTableDataUpdate(updatedData);
    if (updatedData) {
      const totalAmount = updatedData.reduce((sum, res) => {
        if (res.type === "lines") {
          return sum + (Number(res?.amount) || 0);
        }
        return sum;
      }, 0);
      setVendorPaymentFormUpdated({
        ...vendorPaymentFomUpdated,
        amount: totalAmount,
      });
    }
  };

  //  integration
  // onChange function
  // const getFormInfo = (key) => (e, newValue) => {
  //   const { value } = e.target;
  //   setValidationAlert({});
  //   setVendorPaymentForm({
  //     ...vendorPaymentForm,
  //     [key]: value !== 0 ? value : newValue,
  //   });
  // };
  const getFormInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    const keyCode = e.keyCode || e.which;
    setValidationAlert({});
    setVendorPaymentForm({
      ...vendorPaymentForm,
      [key]:
        value !== 0 && value !== undefined
          ? value
          : newValue !== null
          ? newValue
          : null,
    });
    if (keyCode === 13) {
      setValidationAlert({});
      setVendorPaymentForm({
        ...vendorPaymentForm,
        [key]: newValue !== null ? newValue : null,
      });
    }
  };
  const dispatch = useDispatch();
  const getFormInfUpdate = (key) => (e, newValue) => {
    const { value } = e.target;
    setValidationAlert({});
    setVendorPaymentFormUpdated({
      ...vendorPaymentFomUpdated,
      [key]: newValue !== undefined ? newValue : value,
    });
  };

  // const changePaymentMethod = (e) => {
  //   setVendorPaymentForm({
  //     ...vendorPaymentForm,
  //     paymentMethod: e.target.value,
  //     vendor: null,
  //     amount: "",
  //   });
  //   setVendorBills(null);
  // };
  const changePaymentMethod = (e) => {
    let newAmount = "";
    if (e.target.value === "otherPayment") {
      newAmount = tableData.reduce(
        (sum, res) =>
          res.type === "lines" ? sum + (Number(res?.amount) || 0) : sum,
        0
      );
    }

    setVendorPaymentForm((prevFormValues) => ({
      ...prevFormValues,
      paymentMethod: e.target.value,
      amount: newAmount,
    }));
    setVendorBills(null);
  };
  // filter payment type
  useEffect(() => {
    if (journalList !== undefined) {
      let stockValuationAccList = journalList?.filter(
        (item) => item.type === "bank" || item.type === "cash"
      );
      setPaymentTypeList(stockValuationAccList);
    }
  }, [journalList]);
  // console.log(vendorPaymentForm);

  // set table data for backend
  useEffect(() => {
    setTableDataForBackend(
      tableData?.map((r) => {
        if (r?.type === "header") {
          return {
            line_type: r?.type,
            label: r?.headerValue,
          };
        } else {
          return {
            line_type: r?.type,
            chartOfAccountId: r?.account?._id,
            label: r?.label,
            branchId: r?.branch?._id || undefined,
            employeeId: r?.employee?._id,
            amount: Number(r?.amount),
          };
        }
      })
    );
  }, [tableData]);

  // console.log(loginResponse);
  // create vendor payment draft
  const formData = new FormData();
  formData.append(
    "vendorId",
    vendorPaymentForm?.paymentMethod === "vendorPayment"
      ? vendorPaymentForm?.vendor?._id
      : null
  );
  formData.append(
    "isVendorPayment",
    vendorPaymentForm?.paymentMethod === "otherPayment" ? false : true
  );
  formData.append("paymentTypeId", vendorPaymentForm?.paymentType?._id);
  formData.append(
    "chequeDate",
    vendorPaymentForm?.type === "Cheque" ? vendorPaymentForm?.chequeDate : null
  );
  formData.append("chequeNo", vendorPaymentForm?.chequeNo);
  formData.append("type", vendorPaymentForm?.type === "Manual" ? 1 : 2);
  formData.append("totalAmount", Number(vendorPaymentForm?.amount));
  formData.append("paymentDate", vendorPaymentForm?.paymentDate);
  formData.append("paymentReference", vendorPaymentForm?.paymentReference);
  formData.append(
    "amountInWords",
    numToWords(Math.floor(vendorPaymentForm?.amount))
  );
  formData.append(
    "itemInfo",
    vendorPaymentForm?.paymentMethod === "vendorPayment"
      ? null
      : JSON.stringify(tableDataForBackend)
  );
  formData.append("branchId", vendorPaymentForm?.branchId?._id);
  image?.forEach((file, index) => {
    formData.append("file", file);
  });
  formData.append("customerNote", vendorPaymentForm?.customerNotes);
  formData.append("termsAndCondition", vendorPaymentForm?.termsAndConditions);
  formData.append("postDate", `${today} ${getCurrentTime()}`);
  formData.append("currency", vendorPaymentForm?.currency?._id);
  vendorPaymentForm?.paymentMethod === "vendorPayment" &&
    formData.append(
      "paymentMode",
      vendorPaymentForm?.paymentMode?.toUpperCase()
    );
  vendorPaymentForm?.paymentMethod === "vendorPayment" &&
    vendorPaymentForm?.paymentMode === "ADVANCE" &&
    formData.append("advAccount", vendorPaymentForm?.account?._id);

  const vendorPaymentFn = (key) => () => {
    dispatch(setFilterActive(false));
    //  for (const pair of formData.entries()) {
    //     console.log(pair[0] + ': ' + pair[1]);
    //   }

    const selectedBills = (vendorBills || [])
      .filter((res) => res.isSelected === true)
      .map((res) => ({
        billId: res?.billId,
        paidAmount: res?.amount,
        case: res?.case,
      }));

    if (
      vendorPaymentForm?.paymentMethod === "vendorPayment" &&
      vendorPaymentForm?.paymentMode === "AGAINST REF"
    ) {
      formData.append("billId", JSON.stringify(selectedBills));
    } else {
      formData.append("billId", null);
    }

    if (key === "post") {
      let draftFn = () => {
        setVendorPaymentForm({
          ...vendorPaymentForm,
          isEdit: true,
          status: "POSTED",
        });
      };
      postVendorPaymentAPICall(
        { id: editDataId },
        draftFn,
        setSnackBarStates,
        snackBarStates
      );
    }
    if (
      vendorPaymentForm?.paymentMethod === "vendorPayment" &&
      vendorPaymentForm?.vendor === null
    ) {
      setValidationAlert({ ...validationAlert, vendor: "Select a vendor" });
    } else if (vendorPaymentForm?.paymentType === null) {
      setValidationAlert({
        ...validationAlert,
        paymentType: "Select a payment type",
      });
    } else if (vendorPaymentForm?.type === null) {
      setValidationAlert({ ...validationAlert, type: "Select a type" });
    } else if (
      vendorPaymentForm?.type === "Cheque" &&
      vendorPaymentForm?.chequeDate === ""
    ) {
      setValidationAlert({ ...validationAlert, chequeDate: "fill this field" });
    } else if (vendorPaymentForm?.chequeNo === "") {
      setValidationAlert({ ...validationAlert, chequeNo: "fill this field" });
    } else if (vendorPaymentForm?.amount === "") {
      setValidationAlert({ ...validationAlert, amount: "fill this field" });
    } else if (vendorPaymentForm?.paymentDate === "") {
      setValidationAlert({
        ...validationAlert,
        paymentDate: "fill this field",
      });
    } else if (vendorPaymentForm?.paymentReference === "") {
      setValidationAlert({
        ...validationAlert,
        paymentReference: "fill this field",
      });
    } else if (
      vendorPaymentForm?.branchId === null ||
      vendorPaymentForm?.branchId === ""
    ) {
      setValidationAlert({
        ...validationAlert,
        branch: "fill this field",
      });
    } else if (
      vendorPaymentForm?.currency === null ||
      vendorPaymentForm?.currency === "" ||
      vendorPaymentForm?.currency === undefined
    ) {
      setValidationAlert({
        ...validationAlert,
        currency: "fill this field",
      });
    } else if (
      vendorPaymentForm?.paymentMethod === "vendorPayment" &&
      (vendorPaymentForm?.paymentMode == null ||
        vendorPaymentForm?.paymentMode === "" ||
        vendorPaymentForm?.paymentMode === undefined)
    ) {
      setValidationAlert({
        ...validationAlert,
        paymentMode: "fill this field",
      });
    } else if (
      vendorPaymentForm?.paymentMethod === "vendorPayment" &&
      vendorPaymentForm?.paymentMode === "ADVANCE" &&
      (vendorPaymentForm?.account == null ||
        vendorPaymentForm?.account === "" ||
        vendorPaymentForm?.account === undefined)
    ) {
      setValidationAlert({
        ...validationAlert,
        account: "fill this field",
      });
    } else {
      if (key === "draft") {
        let draftFn = () => {
          setVendorPaymentForm({
            ...vendorPaymentForm,
            isEdit: true,
            status: "DRAFT",
          });
        };
        if (
          (vendorPaymentForm?.paymentMode === "AGAINST REF" &&
            selectedBills.length > 0) ||
          vendorPaymentForm?.paymentMode !== "AGAINST REF"
        ) {
          draftVendorPaymentAPICall(
            formData,
            draftFn,
            setSnackBarStates,
            snackBarStates,
            setEditDataId
          );
        } else {
          setSnackBarStates({
            error: true,
            message: "please choose a bill",
          });
        }
      } else if (key === "edit") {
        formData.append("id", editDataId);
        formData.append("uploadUrl", JSON.stringify(receivedImgUrl));
        let draftFn = () => {
          setVendorPaymentForm({
            ...vendorPaymentForm,
            isEdit: true,
            status: "DRAFT",
          });
          setIsEditClicked(false);
        };
        editVendorPaymentAPICall(
          formData,
          draftFn,
          setSnackBarStates,
          snackBarStates
        );
      } else if (key === "update") {
        let sendBody = {
          id: singleViewId,
          postDate: `${today} ${getCurrentTime()}`,
          vendorId: vendorPaymentForm?.vendor?._id,
          isVendorPayment:
            vendorPaymentForm?.paymentMethod === "otherPayment" ? false : true,
          paymentTypeId: vendorPaymentForm.paymentType._id,
          type: vendorPaymentForm?.type === "Manual" ? 1 : 2,
          chequeDate: vendorPaymentForm.chequeDate,
          chequeNo: vendorPaymentForm.chequeNo,
          totalAmount: vendorPaymentForm.amount,
          paymentDate: vendorPaymentForm.paymentDate,
          paymentReference: vendorPaymentForm.paymentReference,
          amountInWords: vendorPaymentForm.amountInWords,
          itemInfo:
            tableDataForBackend?.length > 0 ? tableDataForBackend : null,
          branchId: vendorPaymentForm.branchId._id,
          customerNote: vendorPaymentForm.customerNotes,
          termsAndCondition: vendorPaymentForm.termsAndConditions,
          currency: vendorPaymentForm.currency._id,
          billId: selectedBills?.length > 0 ? selectedBills : null,
          paymentMode: vendorPaymentForm?.paymentMode,
          account:
            vendorPaymentForm.paymentMode === "ADVANCE"
              ? vendorPaymentForm.account._id
              : null,
        };
        updateVendorPaymentAPI(sendBody, setImportPopup, setSnackBarStates);
      } else if (key === "return") {
        returnVendorPaymentAPI(
          {
            id: singleViewId,
            postDate: `${today} ${getCurrentTime()}`,
          },
          setAlertPopup,
          setSnackBarStates
        );
      }
    }
  };

  // edit section
  useEffect(() => {
    if (editedData !== undefined) {
      setLog(editedData?.log || []);
      setEditDataId(editedData?._id);
      setVendorPaymentForm({
        ...vendorPaymentForm,
        isEdit: true,
        status: editedData?.status,
        paymentMethod:
          editedData?.isVendorPayment === false
            ? "otherPayment"
            : "vendorPayment",
        paymentType: filterObjFromList(
          "_id",
          paymentTypeList,
          "paymentTypeId",
          editedData
        ),
        vendor: filterObjFromList("_id", vendorList, "vendorId", editedData),
        type: editedData?.TYPE,
        chequeDate: editedData?.chequeDate,
        chequeNo: editedData?.chequeNo,
        amount: editedData?.totalAmount,
        amountTt: editedData?.totalAmount,
        advBalanceAmt: editedData?.advBalanceAmt,
        paymentDate: editedData?.paymentDate,
        paymentReference: editedData?.paymentReference,
        customerNotes: editedData?.customerNote,
        termsAndConditions: editedData?.termsAndCondition,
        paymentMode: ["AGAINST REF", "ADVANCE", "NEW REF"].find(
          (res) => res === editedData.paymentMode
        ),
        billId: editedData?.billId,
        account: filterObjFromList(
          "_id",
          chartOfAccountList,
          "advAccount",
          editedData
        ),
        branchId: allBranchesList?.find(
          (data) => data._id === editedData.branchId
        ),
        currency:
          MultiCurrencyView !== undefined &&
          MultiCurrencyView?.isMultiCurrency === true
            ? filterObjFromList(
                "_id",
                currencyListUpdated,
                "currency",
                editedData
              )
            : filterObjFromList(
                "_id",
                currencyListUpdated,
                "currency",
                companyProfileData
              ),
      });
      setTableData(
        editedData?.itemInfo?.map((r, i) => {
          if (r?.line_type === "header") {
            return {
              type: r?.line_type,
              headerValue: r?.label,
            };
          } else {
            return {
              type: r?.line_type,
              account: filterObjFromList(
                "_id",
                chartOfAccountList,
                "chartOfAccountId",
                r
              ),
              label: r?.label,
              branch: filterObjFromList("_id", allBranchesList, "branchId", r),
              employee: filterObjFromList(
                "_id",
                allActiveEmpList,
                "employeeId",
                r
              ),
              amount: r?.amount,
            };
          }
        })
      );
      setDisplayImage(
        editedData?.imageUrl?.map((r) => {
          return {
            url: r.url,
          };
        })
      );
      setReceivedImgUrl(
        editedData?.imageUrl?.map((r) => {
          return {
            url: r?.url,
            _id: r?._id,
          };
        })
      );
      let newData = editedData?.billId?.map((element) => {
        return { ...element, amount: element?.paidAmount, isSelected: true };
      });
      setVendorBills(newData);
   
      if (editedData?.status === "COMPLETED") {
        if(!backBtn){
          localStorage.removeItem("singleViewById");
          dispatch(get_vendor_list_row_data_list({customerReceiptReportSingleList:undefined}))
          handleClosePaymentDialog();
          computeBankReconciliation()

        }
      }
    }
  }, [
    editedData,
    paymentTypeList,
    vendorList,
    allBranchesList,
    allActiveEmpList,
    // vendorBillsFromStore
  ]);

  // useEffect(() => {
  //   if (
  //     vendorPaymentForm?.paymentType !== null &&
  //     vendorPaymentForm?.paymentDate !== "" &&
  //     vendorPaymentForm?.branchId !== null
  //   ) {
  //     generateVendorPaymentNameAPICall({
  //       branchId: vendorPaymentForm?.branchId?._id,
  //       paymentDate: vendorPaymentForm.paymentDate,
  //     });
  //   }
  // }, [
  //   vendorPaymentForm.paymentType,
  //   vendorPaymentForm.paymentDate,
  //   vendorPaymentForm.branchId,
  // ]);

  useEffect(() => {
    if (vendorPaymentForm?.branchId && !singleViewId) {
      generateVendorPaymentNameAPICall({
        branchId: vendorPaymentForm?.branchId?._id,
        paymentDate: vendorPaymentForm.paymentDate,
      });
    }
  }, [vendorPaymentForm?.branchId]);

  useEffect(() => {
    editedData !== undefined
      ? setPaymentName(editedData?.name)
      : setPaymentName(generatedName !== undefined ? generatedName : "###");
  }, [generatedName, vendorPaymentForm]);

  const backButtonFunction = () => {
    if (vendorPaymentForm?.isEdit === false) {
      const userConfirmed = window.confirm(
        "Changes you made may not be saved."
      );

      if (userConfirmed) {
        localStorage.removeItem("singleViewById");
        navigateTo("/userdashboard/accounts/vendor/payments");
        dispatch(set_Back_button_action({ backButtonClickAction: true }));
      }
    } else {
      localStorage.removeItem("singleViewById");
      navigateTo("/userdashboard/accounts/vendor/payments");
      dispatch(set_Back_button_action({ backButtonClickAction: true }));
    }
  };

  useEffect(() => {
    if (loginResponse) {
      const userRole = localStorage.getItem("userRole");
      // console.log("userRole", userRole);
      if (userRole === "admin") {
        setBranchList(allBranchesList);
      } else if (userRole === "user") {
        setBranchList(allowedBranchList);

        if (editedData === undefined) {
          const defaultBranchId = loginResponse?.data?.branchId;
          const userBranch = allBranchesList?.find(
            (data) => data.storeCode === defaultBranchId
          );
          setVendorPaymentForm((prev) => ({
            ...prev,
            branchId: userBranch,
            currency: currencyListUpdated?.find(
              (res) => res._id === loginResponse?.currency
            ),
          }));
        }
      }
    }
  }, [loginResponse, allowedBranchList, allBranchesList]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !vendorPaymentForm?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [vendorPaymentForm?.isEdit]);

  // for get vendor bill
  useEffect(() => {
    if (
      editedData === undefined &&
      vendorPaymentForm.vendor?._id &&
      vendorPaymentForm.branchId?._id &&
      vendorPaymentForm.currency?._id
    ) {
      let sendObj = {
        vendorId: vendorPaymentForm.vendor._id,
        branchId: vendorPaymentForm.branchId._id,
        currency: vendorPaymentForm.currency._id,
      };

      showVendorBillApi(sendObj);
    }
  }, [
    vendorPaymentForm.branchId,
    vendorPaymentForm.vendor,
    vendorPaymentForm.currency,
  ]);

  useEffect(() => {
    let newData = vendorBillsFromStore.map((element) => {
      return { ...element, amount: null, isSelected: false };
    });
    setVendorBills(newData);
  }, [vendorBillsFromStore]);

  //handle bill
  const handleSelected = (e, index) => {
    const { checked } = e.target;
    let element = vendorBills[index];
    if (!element.amount || element.amount <= 0) {
      setSnackBarStates({
        error: true,
        message:
          element.amount === null
            ? "please enter a amount"
            : "please enter a valid amount",
      });
    } else {
      setVendorBills((prev) => {
        const currentArray = prev || [];
        if (currentArray[index]) {
          const updatedVendorBills = [...currentArray];
          updatedVendorBills[index] = {
            ...updatedVendorBills[index],
            isSelected: checked,
          };
          return updatedVendorBills;
        }
        return currentArray;
      });
    }
  };

  const handleAmountChange = (e, index, item) => {
    const { value } = e.target;
    setVendorBills((prev) => {
      const currentArray = prev || [];
      const total = item.amountDue + item.paidAmount;

      if (currentArray[index] && value !== undefined) {
        const updatedVendorBills = [...currentArray];

        const newAmount = importPopup
          ? Number(value) > total
            ? total
            : Number(value)
          : value > item.amountDue
          ? item.amountDue
          : Number(value);

        updatedVendorBills[index] = {
          ...updatedVendorBills[index],
          amount: newAmount,
          isSelected: value <= 0 ? false : true,
        };

        return updatedVendorBills;
      }
      return currentArray;
    });
  };

  useEffect(() => {
    if (vendorBills?.length > 0) {
      const sum = (vendorBills || [])
        .filter((res) => res.isSelected === true)
        .reduce((accumulator, res) => accumulator + (res?.amount || 0), 0);
      setVendorPaymentForm((prev) => ({
        ...prev,
        amount:
          vendorPaymentForm?.paymentMode !== "ADVANCE"
            ? sum
            : vendorPaymentForm?.amount,
        amountTt: sum,
      }));
    }
  }, [vendorBills]);

  return (
    <>
      <style>{styles}</style>
      <div className="global-page-parent-container">
        <div
          className="new-global-white-bg-container"
          style={{ padding: "0 2%" }}
        >
         {!backBtn && <div>
            <h3>Vendor Payment</h3>
          </div>}
          <div className="new-global-white-bg-icon-container">
            <div
              className="new-global-white-bg-icon-container-left"
              style={{ width: "56%" }}
            >
          
             {backBtn && <Tooltip title="Back">
                <IconButton onClick={backButtonFunction}>
                  <i class="bi bi-chevron-left"></i>
                </IconButton>
              </Tooltip>}

              {vendorPaymentForm?.status === "COMPLETED" && (
                <>
                  <button
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "rgb(166 17 217)",
                      height: "32px",
                      width: "29%",
                    }}
                    onClick={() => {
                      setImportPopup(true);
                      // setVendorPaymentFormUpdated(vendorPaymentForm);
                      // setTableDataUpdate(tableData);
                    }}
                  >
                    Update
                  </button>

                  <button
                    className="btn btn-primary"
                    style={{
                      backgroundColor: " rgb(217 17 112",
                      height: "32px",
                      width: "34%",
                    }}
                    onClick={() => setAlertPopup(true)}
                  >
                    Payment Return
                  </button>
                </>
              )}
              {vendorPaymentForm?.status === "DRAFT" && (
                <Tooltip title="Edit">
                  <IconButton
                    onClick={
                      editClicked
                        ? vendorPaymentFn("edit")
                        : () => {
                            setVendorPaymentForm({
                              ...vendorPaymentForm,
                              isEdit: false,
                            });
                            setIsEditClicked(true);
                          }
                    }
                  >
                    <i
                      class={
                        editClicked
                          ? "bi bi-check2-square edit-icon"
                          : "bi bi-pencil-square edit-icon1"
                      }
                    ></i>
                    {/* <i class="bi bi-check2-square edit-icon"></i> */}
                  </IconButton>
                </Tooltip>
              )}

              {vendorPaymentForm?.status === "" && (
                <Tooltip title="Save">
                  <IconButton onClick={vendorPaymentFn("draft")}>
                    <i class="bi bi-floppy save-icon"></i>
                  </IconButton>
                </Tooltip>
              )}
              {vendorPaymentForm?.status === "DRAFT" && !editClicked && (
                <button
                  onClick={vendorPaymentFn("post")}
                  className="btn btn-post"
                >
                  POST
                </button>
              )}
              {vendorPaymentForm?.status !== "" && (
                <>
                  <Tooltip title="Share">
                    <IconButton>
                      <i
                        class="bi bi-share"
                        style={{
                          backgroundColor: "#9797e9",
                          color: "white",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                      ></i>
                    </IconButton>
                  </Tooltip>

                  <ReactToPrint
                    trigger={() => (
                      <IconButton
                        className="printer-div"
                        style={{ padding: "1px" }}
                      >
                        <i
                          style={{
                            color: "white",
                            backgroundColor: " #d787e7",
                            fontSize: "medium",
                            padding: "8px",
                            borderRadius: "2px",
                          }}
                          class="bi bi-printer"
                        ></i>
                      </IconButton>
                    )}
                    content={() => paymentsPrintNew}
                  />

                  <Tooltip title="Download">
                    <IconButton>
                      <i
                        class="bi bi-arrow-down-circle"
                        style={{
                          backgroundColor: "rgb(255 118 133 / 69%)",
                          color: "white",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                      ></i>
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>

            {/* <div className="status-container">
            <p>Draft</p>
          </div> */}
            <div className="status-container">
              {vendorPaymentForm?.status === "DRAFT" && (
                <img src={draftRibbon} alt="" />
              )}
              {vendorPaymentForm?.status === "COMPLETED" && (
                <img src={postRibbon} alt="" />
              )}
              {vendorPaymentForm?.status === "RETURNED" && (
                <img src={returned} alt="" />
              )}
            </div>
          </div>
          <hr className="global-hr" />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4 style={{ width: "35%" }} className="global-name">
              {paymentName}
            </h4>
            <div className="radio-groups-container" style={{ width: "40%" }}>
              <div
                className="global-radio-button"
                style={{ width: "50%", display: "flex", alignItems: "center" }}
              >
                <input
                  type="radio"
                  value="otherPayment"
                  onChange={changePaymentMethod}
                  name="vendorPayment"
                  id="vendorOtherPayment"
                  // defaultChecked
                  checked={vendorPaymentForm?.paymentMethod === "otherPayment"}
                  disabled={vendorPaymentForm?.isEdit}
                />
                <label htmlFor="vendorOtherPayment">Other Payment</label>
              </div>
              <div
                className="global-radio-button"
                style={{ width: "50%", display: "flex", alignItems: "center" }}
              >
                <input
                  type="radio"
                  value="vendorPayment"
                  onChange={changePaymentMethod}
                  name="vendorPayment"
                  id="vendorPayment"
                  disabled={vendorPaymentForm?.isEdit}
                  checked={vendorPaymentForm?.paymentMethod === "vendorPayment"}
                />
                <label htmlFor="vendorPayment">Vendor Payment</label>
              </div>
            </div>
          </div>
          <div className="new-global-input-container">
            {vendorPaymentForm?.paymentMethod === "vendorPayment" && (
              <div className="new-global-single-input auto-complete-new">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={["AGAINST REF", "ADVANCE", "NEW REF"]}
                  getOptionLabel={(option) => option}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Payment Type*" focused />
                  )}
                  value={vendorPaymentForm?.paymentMode}
                  onChange={getFormInfo("paymentMode")}
                  disabled={vendorPaymentForm?.isEdit}
                />
                <p className="doc-validation-alert">
                  {validationAlert?.paymentMode}
                </p>
              </div>
            )}
            {vendorPaymentForm?.paymentMethod === "vendorPayment" && (
              <div className="new-global-single-input auto-complete-new">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={vendorList || []}
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Vendor*" focused />
                  )}
                  value={vendorPaymentForm?.vendor}
                  onChange={getFormInfo("vendor")}
                  disabled={vendorPaymentForm?.isEdit}
                />
                <p className="doc-validation-alert">
                  {validationAlert?.vendor}
                </p>
              </div>
            )}
            <div className="new-global-single-input auto-complete-new">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={paymentTypeList || []}
                getOptionLabel={(option) => option?.journalName}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Payment Method*" focused />
                )}
                value={vendorPaymentForm?.paymentType}
                onChange={getFormInfo("paymentType")}
                disabled={vendorPaymentForm?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.paymentType}
              </p>
            </div>
            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={["Manual", "Cheque"]}
                getOptionLabel={(option) => option}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Type*" focused />
                )}
                value={vendorPaymentForm?.type}
                onChange={getFormInfo("type")}
                disabled={vendorPaymentForm?.isEdit}
              />
              <p className="doc-validation-alert">{validationAlert?.type}</p>
            </div>
            {vendorPaymentForm?.type === "Cheque" && (
              <>
                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label="Cheque Date*"
                    variant="outlined"
                    type="date"
                    focused
                    value={vendorPaymentForm?.chequeDate}
                    onChange={getFormInfo("chequeDate")}
                    disabled={vendorPaymentForm?.isEdit}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.chequeDate}
                  </p>
                </div>
              </>
            )}
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label={
                  vendorPaymentForm?.type === "Cheque"
                    ? "Cheque No*"
                    : "Transaction No"
                }
                variant="outlined"
                type="text"
                focused
                value={vendorPaymentForm?.chequeNo}
                onChange={getFormInfo("chequeNo")}
                disabled={vendorPaymentForm?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.chequeNo}
              </p>
            </div>

            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Amount*"
                variant="outlined"
                type="text"
                focused
                defaultValue={"0"}
                value={vendorPaymentForm.amount}
                onChange={
                  vendorPaymentForm.paymentMethod === "otherPayment"
                    ? undefined
                    : getFormInfo("amount")
                }
                disabled={
                  vendorPaymentForm.paymentMethod === "otherPayment" ||
                  vendorPaymentForm?.isEdit ||
                  (vendorPaymentForm.paymentMethod === "vendorPayment" &&
                    vendorPaymentForm.paymentMode === "AGAINST REF")
                }
              />
              <p className="doc-validation-alert">{validationAlert?.amount}</p>
            </div>

            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Payment Date*"
                variant="outlined"
                type="date"
                focused
                value={vendorPaymentForm?.paymentDate || ""}
                onChange={getFormInfo("paymentDate")}
                disabled={vendorPaymentForm?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.paymentDate}
              </p>
            </div>

            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Payment Reference*"
                variant="outlined"
                type="text"
                focused
                value={vendorPaymentForm?.paymentReference}
                onChange={getFormInfo("paymentReference")}
                disabled={vendorPaymentForm?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.paymentReference}
              </p>
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Amount In Words*"
                variant="outlined"
                type="text"
                focused
                value={numToWords(Math.floor(vendorPaymentForm?.amount))}
                disabled={vendorPaymentForm?.isEdit}
              />
            </div>
            <div className="new-global-single-input auto-complete-new">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allowedBranchList || []}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Branch*" focused />
                )}
                value={vendorPaymentForm?.branchId}
                onChange={getFormInfo("branchId")}
                disabled={vendorPaymentForm?.isEdit}
              />
              <p className="doc-validation-alert">{validationAlert?.branch}</p>
            </div>
            <div className="new-global-single-input">
              {MultiCurrencyView !== undefined &&
              MultiCurrencyView?.isMultiCurrency ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={currencyListUpdated || []}
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Currency*" focused />
                  )}
                  value={vendorPaymentForm?.currency}
                  onChange={getFormInfo("currency")}
                  disabled={vendorPaymentForm?.isEdit}
                />
              ) : (
                <TextField
                  value={vendorPaymentForm?.currency?.name || ""}
                  sx={{ caretColor: "transparent" }}
                  className="disabled-input"
                  id="outlined-basic"
                  label="Currency*"
                  variant="outlined"
                  type="text"
                  focused
                  title="Multi Currency not enabled"
                  disabled={vendorPaymentForm?.isEdit}
                />
              )}
              <p className="doc-validation-alert">
                {validationAlert?.currency}
              </p>
            </div>
            {vendorPaymentForm?.paymentMethod === "vendorPayment" &&
              vendorPaymentForm?.paymentMode === "ADVANCE" && (
                <div className="new-global-single-input auto-complete-new">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={vendorPaymentAccounts}
                    getOptionLabel={(option) => option?.accountName || ""}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Account*" focused />
                    )}
                    value={vendorPaymentForm?.account}
                    onChange={getFormInfo("account")}
                    disabled={vendorPaymentForm?.isEdit}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.account}
                  </p>
                </div>
              )}
          </div>
        </div>
        {/* other payment  */}
        {vendorPaymentForm?.paymentMethod === "otherPayment" && (
          <>
            <div className="new-global-white-bg-container">
              <>
                <TableVirtuoso
                  ref={containerRef}
                  className="global-product-table"
                  style={{
                    height:
                      tableData?.length === 1
                        ? "10vh"
                        : tableData?.length < 5
                        ? `${tableData?.length * 7}vh`
                        : "25vh",
                    maxHeight: "24vh",
                  }}
                  data={tableData}
                  fixedHeaderContent={() => (
                    <tr>
                      <th>Account</th>
                      <th>Label</th>
                      <th>Branch</th>
                      <th>Employee</th>
                      <th>Amount</th>
                    </tr>
                  )}
                  itemContent={(index, item) => (
                    <>
                      {item.type === "header" ? (
                        <>
                          <td colSpan="5">
                            <input
                              type="text"
                              value={item.headerValue}
                              onChange={handleInputChange(index, "headerValue")}
                              disabled={vendorPaymentForm?.isEdit}
                            />
                          </td>
                          <td style={{ border: "none", width: "4%" }}>
                            <IconButton
                              disabled={vendorPaymentForm?.isEdit}
                              onClick={() => handleDelete(index)}
                              style={{ height: "30px" }}
                            >
                              <i
                                className="bi bi-trash3"
                                style={{
                                  color: "#db0000",
                                  fontSize: "1.3rem",
                                }}
                              ></i>
                            </IconButton>
                          </td>
                        </>
                      ) : (
                        <>
                          <td style={{ minWidth: "200px" }}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                options={chartOfAccountList || []}
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option) => option?.accountName}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                value={item?.account}
                                onChange={handleInputChange(index, "account")}
                                disabled={vendorPaymentForm?.isEdit}
                              />
                            </div>
                          </td>
                          <td style={{ maxWidth: "180px" }}>
                            <input
                              type="text"
                              value={item?.label}
                              onChange={handleInputChange(index, "label")}
                              disabled={vendorPaymentForm?.isEdit}
                            />
                          </td>
                          <td style={{ minWidth: "200px" }}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                options={allBranchesList || []}
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option) =>
                                  `${option?.storeCode}-${option?.branchName}`
                                }
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                value={item?.branch}
                                onChange={handleInputChange(index, "branch")}
                                disabled={vendorPaymentForm?.isEdit}
                              />
                            </div>
                          </td>
                          <td style={{ minWidth: "200px" }}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                options={allActiveEmpList || []}
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option) => option?.staff_name}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                value={item?.employee}
                                onChange={handleInputChange(index, "employee")}
                                disabled={vendorPaymentForm?.isEdit}
                              />
                            </div>
                          </td>
                          <td style={{ maxWidth: "80px" }}>
                            <input
                              type="text"
                              value={item?.amount}
                              onChange={handleInputChange(index, "amount")}
                              disabled={vendorPaymentForm?.isEdit}
                            />
                          </td>
                          {vendorPaymentForm.status !== "COMPLETED" && (
                            <td style={{ border: "none", width: "4%" }}>
                              <IconButton
                                onClick={() => handleDelete(index)}
                                style={{ height: "30px" }}
                                disabled={vendorPaymentForm?.isEdit}
                              >
                                <i
                                  className="bi bi-trash3"
                                  style={{
                                    color: "#db0000",
                                    fontSize: "1.3rem",
                                  }}
                                ></i>
                              </IconButton>
                            </td>
                          )}
                        </>
                      )}
                    </>
                  )}
                />
                <div className="add-row-btn-container">
                  <div
                    className="global-single-input auto-complete add-line-autocomplete"
                    style={{ width: "76%" }}
                  >
                    <Autocomplete
                      options={addRowData || []}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => <TextField {...params} />}
                      value={addRowInput}
                      onChange={(e, newValue) => setAddRowInput(newValue)}
                      renderClear={() => null}
                      disabled={vendorPaymentForm?.isEdit}
                    />
                  </div>
                  <button
                    disabled={vendorPaymentForm?.isEdit}
                    onClick={() => addRow()}
                    className="add-row-btn"
                  >
                    +
                  </button>
                </div>
              </>
            </div>
            <div
              className="new-global-white-bg-container"
              style={{
                display: "flex",
                flexWrap: "wrap",
                padding: "8px 2%",
              }}
            >
              <div
                style={{
                  display: "flex",

                  width: "97%",
                }}
              >
                <div
                  className="new-global-single-input"
                  style={{ width: "35%" }}
                >
                  <p>Customer Notes</p>
                  <textarea
                    name=""
                    id=""
                    cols="50"
                    rows="5"
                    value={vendorPaymentForm?.customerNotes}
                    onChange={getFormInfo("customerNotes")}
                    disabled={vendorPaymentForm?.isEdit}
                  ></textarea>
                </div>

                <div
                  className="new-global-single-input"
                  style={{ width: "35%" }}
                >
                  <p>Terms & Conditions</p>
                  <textarea
                    name=""
                    id=""
                    cols="50"
                    rows="5"
                    value={vendorPaymentForm?.termsAndConditions}
                    onChange={getFormInfo("termsAndConditions")}
                    disabled={vendorPaymentForm?.isEdit}
                  ></textarea>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                }}
              >
                <div className="file-picker-new">
                  <input
                    disabled={vendorPaymentForm?.isEdit}
                    type="file"
                    id="fileUpload"
                    onChange={changeImage}
                  />
                  <label
                    disabled={vendorPaymentForm?.isEdit}
                    htmlFor="fileUpload"
                  >
                    <img src={uploadFile} alt="" />
                    Upload File
                  </label>
                </div>
                <div style={{ display: "flex", margin: "0 0 0 22px" }}>
                  {displayImage?.map((r, i) => (
                    <div className="uploaded-image-list">
                      <img src={r?.url} alt="" />
                      <div className="image-remove-icon-new">
                        <IconButton
                          onClick={() => removeImageFn(i)}
                          disabled={vendorPaymentForm?.isEdit}
                        >
                          <CloseOutlined
                            sx={{ color: "#fd0301", fontSize: "0.9rem" }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
        {/* vendor payment  */}
        {vendorPaymentForm?.paymentMethod === "vendorPayment" &&
            (vendorPaymentForm?.paymentMode === "AGAINST REF" ||
              (vendorPaymentForm?.paymentMode === "ADVANCE" &&
                editedData?.length === 0) ||
              (vendorPaymentForm?.paymentMode === "ADVANCE" &&
                vendorPaymentForm?.status === "COMPLETED")) &&
            vendorBills?.length > 0 && (
            <>
              <div className="new-global-white-bg-container">
                <>
                  <div className="global-product-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Bill</th>
                          <th>Date</th>
                          <th>Bill Reference</th>
                          <th>Amount Total</th>
                          <th>Amount Due</th>
                          <th>Amount </th>
                        </tr>
                      </thead>

                      <tbody>
                        {Array.isArray(vendorBills) &&
                        vendorBills.length > 0 ? (
                          vendorBills.map((item, index) => (
                            <tr
                              key={index}
                              ref={
                                index === tableData?.length - 1
                                  ? containerRef
                                  : null
                              }
                            >
                              {item.type === "header" ? (
                                <>
                                  <td colSpan="5">
                                    <input
                                      type="text"
                                      value={item.headerValue}
                                      onChange={handleInputChange(
                                        index,
                                        "headerValue"
                                      )}
                                      disabled={vendorPaymentForm?.isEdit}
                                    />
                                  </td>
                                  <td style={{ border: "none", width: "4%" }}>
                                    <IconButton
                                      disabled={vendorPaymentForm?.isEdit}
                                      onClick={() => handleDelete(index)}
                                      style={{ height: "30px" }}
                                    >
                                      <i
                                        className="bi bi-trash3"
                                        style={{
                                          color: "#db0000",
                                          fontSize: "1.3rem",
                                        }}
                                      ></i>
                                    </IconButton>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td style={{ minWidth: "200px" }}>
                                    {item?.name}
                                  </td>
                                  <td style={{ maxWidth: "180px" }}>
                                    {item?.billDate}
                                  </td>
                                  <td style={{ minWidth: "200px" }}>
                                    {item?.billreference || ""}
                                  </td>
                                  <td style={{ minWidth: "200px" }}>
                                    {item?.total}
                                  </td>
                                  <td style={{ maxWidth: "80px" }}>
                                    {item?.amountDue}
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="Number"
                                      value={item?.amount}
                                      onChange={(e) =>
                                        handleAmountChange(e, index, item)
                                      }
                                      disabled={vendorPaymentForm?.isEdit}
                                    />
                                  </td>
                                  {vendorPaymentForm?.paymentMode !==
                                    "ADVANCE" && (
                                    <td style={{ border: "none", width: "4%" }}>
                                      <Checkbox
                                        disabled={vendorPaymentForm?.isEdit}
                                        checked={item?.isSelected}
                                        onChange={(e) =>
                                          handleSelected(e, index)
                                        }
                                        sx={{
                                          color: "#000000",
                                          "&.Mui-checked": {
                                            color: "#000000",
                                          },
                                        }}
                                      />
                                    </td>
                                  )}
                                </>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan={6}>
                              No Data
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {vendorPaymentForm?.paymentMode === "ADVANCE" && (
                      <div className="advs-pay-amt-cntr">
                        <div>
                          <div className="adv-amt-cntr adv-tt-amt">
                            <span>Total</span>
                            <span>
                              {vendorPaymentForm?.paymentMode !== "ADVANCE"
                                ? vendorPaymentForm?.amount
                                : vendorPaymentForm?.amountTt || 0}
                              &nbsp;
                              {vendorPaymentForm?.currency?.currencyCode}
                            </span>
                          </div>
                          <div className="adv-amt-cntr">
                            <span>Balance</span>
                            <span>
                              {vendorPaymentForm?.advBalanceAmt || 0}&nbsp;
                              {vendorPaymentForm?.currency?.currencyCode}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className="add-row-btn-container">
                  <div
                    className="global-single-input auto-complete add-line-autocomplete"
                    style={{ width: "76%" }}
                  >
                    <Autocomplete
                      options={addRowData || []}
                      getOptionLabel={(option) => option?.value}
                      renderInput={(params) => <TextField {...params} />}
                      value={addRowInput}
                      onChange={(e, newValue) => setAddRowInput(newValue)}
                      renderClear={() => null}
                      disabled={vendorPaymentForm?.isEdit}
                    />
                  </div>
                  <button
                    disabled={vendorPaymentForm?.isEdit}
                    onClick={() => addRow()}
                    className="add-row-btn"
                  >
                    +
                  </button>
                </div> */}
                </>
              </div>
              <div
                className="new-global-white-bg-container"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "8px 2%",
                }}
              >
                <div
                  style={{
                    display: "flex",

                    width: "97%",
                  }}
                >
                  <div
                    className="new-global-single-input"
                    style={{ width: "35%" }}
                  >
                    <p>Customer Notes</p>
                    <textarea
                      name=""
                      id=""
                      cols="50"
                      rows="5"
                      value={vendorPaymentForm?.customerNotes}
                      onChange={getFormInfo("customerNotes")}
                      disabled={vendorPaymentForm?.isEdit}
                    ></textarea>
                  </div>

                  <div
                    className="new-global-single-input"
                    style={{ width: "35%" }}
                  >
                    <p>Terms & Conditions</p>
                    <textarea
                      name=""
                      id=""
                      cols="50"
                      rows="5"
                      value={vendorPaymentForm?.termsAndConditions}
                      onChange={getFormInfo("termsAndConditions")}
                      disabled={vendorPaymentForm?.isEdit}
                    ></textarea>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "60%",
                  }}
                >
                  <div className="file-picker-new">
                    <input
                      disabled={vendorPaymentForm?.isEdit}
                      type="file"
                      id="fileUpload"
                      onChange={changeImage}
                    />
                    <label
                      disabled={vendorPaymentForm?.isEdit}
                      htmlFor="fileUpload"
                    >
                      <img src={uploadFile} alt="" />
                      Upload File
                    </label>
                  </div>
                  <div style={{ display: "flex", margin: "0 0 0 22px" }}>
                    {displayImage?.map((r, i) => (
                      <div className="uploaded-image-list">
                        <img src={r?.url} alt="" />
                        <div className="image-remove-icon-new">
                          <IconButton
                            onClick={() => removeImageFn(i)}
                            disabled={vendorPaymentForm?.isEdit}
                          >
                            <CloseOutlined
                              sx={{ color: "#fd0301", fontSize: "0.9rem" }}
                            />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}

        {log.length > 0 && (
          <div
            className="new-global-white-bg-container new-log-container"
            style={{ padding: "4px" }}
          >
            <div
              style={{ padding: "8px", cursor: "pointer" }}
              onClick={clickLogView}
            ></div>
            <div
              className={logView ? "pointer-up" : "pointer"}
              onClick={clickLogView}
              style={{ cursor: "pointer" }}
            ></div>
            {logView && (
              <div className="single-log-container">
                {log?.map((r, i) => (
                  <div className="single-log-item">
                    <div className="single-log-item-line-image">
                      <hr className="single-log-item-line" />
                      <div className="single-log-item-image">
                        <img src={logoImage} alt="" />
                      </div>
                      <hr className="single-log-item-line" />
                    </div>
                    <p className="heading">{r?.status}</p>
                    <p className="name">{r?.empId}</p>
                    <p className="date-time">{r?.date}</p>
                    <p className="date-time">{r?.time}</p>
                  </div>
                ))}
              </div>
            )}

            {/* <div className='single-log-container-scroll-line'></div> */}
          </div>
        )}

        {/* new update payment  */}
        <Dialog
          maxWidth="lg"
          open={importPopup}
          // onClose={() => setRegisterPayment(false)}
        >
          <div className="payment-popup">
            <div className="global-input-field-container">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4 style={{ width: "35%" }} className="global-name">
                  {paymentName}
                </h4>
              </div>
              <div className="new-global-input-container">
                {vendorPaymentForm?.paymentMethod === "vendorPayment" && (
                  <div className="new-global-single-input auto-complete-new">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={vendorList || []}
                      getOptionLabel={(option) => option?.name}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Vendor*" focused />
                      )}
                      value={vendorPaymentForm?.vendor}
                      onChange={getFormInfo("vendor")}
                      // disabled={vendorPaymentForm?.isEdit}
                    />
                    <p className="doc-validation-alert">
                      {validationAlert?.vendor}
                    </p>
                  </div>
                )}
                <div className="new-global-single-input auto-complete-new">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={paymentTypeList || []}
                    getOptionLabel={(option) => option?.journalName}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Payment Method*" focused />
                    )}
                    value={vendorPaymentForm?.paymentType}
                    onChange={getFormInfo("paymentType")}
                    // disabled={vendorPaymentForm?.isEdit}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.paymentType}
                  </p>
                </div>
                <div className="new-global-single-input">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={["Manual", "Cheque"]}
                    getOptionLabel={(option) => option}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Type*" focused />
                    )}
                    value={vendorPaymentForm?.type}
                    onChange={getFormInfo("type")}
                    // disabled={vendorPaymentForm?.isEdit}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.type}
                  </p>
                </div>
                {vendorPaymentForm?.type === "Cheque" && (
                  <>
                    <div className="new-global-single-input">
                      <TextField
                        id="outlined-basic"
                        label="Cheque Date*"
                        variant="outlined"
                        type="date"
                        focused
                        value={vendorPaymentForm?.chequeDate}
                        onChange={getFormInfo("chequeDate")}
                        // disabled={vendorPaymentForm?.isEdit}
                      />
                      <p className="doc-validation-alert">
                        {validationAlert?.chequeDate}
                      </p>
                    </div>
                  </>
                )}
                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label={
                      vendorPaymentForm?.type === "Cheque"
                        ? "Cheque No*"
                        : "Transaction No"
                    }
                    variant="outlined"
                    type="text"
                    focused
                    value={vendorPaymentForm?.chequeNo}
                    onChange={getFormInfo("chequeNo")}
                    // disabled={vendorPaymentForm?.isEdit}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.chequeNo}
                  </p>
                </div>

                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label="Amount*"
                    variant="outlined"
                    type="text"
                    focused
                    defaultValue={"0"}
                    value={vendorPaymentForm.amount}
                    onChange={
                      vendorPaymentForm.paymentMethod === "otherPayment"
                        ? undefined
                        : getFormInfo("amount")
                    }
                    // disabled={
                    //   vendorPaymentForm.paymentMethod === "otherPayment" &&
                    //   vendorPaymentForm?.isEdit
                    //     ? true
                    //     : vendorPaymentForm?.isEdit
                    //     ? true
                    //     : false
                    // }
                    // disabled={
                    //   vendorPaymentForm.paymentMethod === "otherPayment" ||
                    //   vendorPaymentForm?.isEdit ||
                    //   (vendorPaymentForm.paymentMethod === "vendorPayment" &&
                    //     vendorPaymentForm.paymentMode === "AGAINST REF")
                    // }
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.amount}
                  </p>
                </div>

                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label="Payment Date*"
                    variant="outlined"
                    type="date"
                    focused
                    value={vendorPaymentForm?.paymentDate || ""}
                    onChange={getFormInfo("paymentDate")}
                    // disabled={vendorPaymentForm?.isEdit}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.paymentDate}
                  </p>
                </div>
                {vendorPaymentForm?.paymentMethod === "vendorPayment" && (
                  <div className="new-global-single-input auto-complete-new">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={["AGAINST REF", "ADVANCE"]}
                      getOptionLabel={(option) => option}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Payment Type*" focused />
                      )}
                      value={vendorPaymentForm?.paymentMode}
                      onChange={getFormInfo("paymentMode")}
                      // disabled={vendorPaymentForm?.isEdit}
                    />
                    <p className="doc-validation-alert">
                      {validationAlert?.paymentMode}
                    </p>
                  </div>
                )}
                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label="Payment Reference*"
                    variant="outlined"
                    type="text"
                    focused
                    value={vendorPaymentForm?.paymentReference}
                    onChange={getFormInfo("paymentReference")}
                    // disabled={vendorPaymentForm?.isEdit}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.paymentReference}
                  </p>
                </div>
                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label="Amount In Words*"
                    variant="outlined"
                    type="text"
                    focused
                    value={numToWords(Math.floor(vendorPaymentForm?.amount))}
                    // disabled={vendorPaymentForm?.isEdit}
                  />
                </div>
                <div className="new-global-single-input auto-complete-new">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={branchList || []}
                    getOptionLabel={(option) =>
                      `${option?.storeCode}-${option?.branchName}`
                    }
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Branch*" focused />
                    )}
                    value={vendorPaymentForm?.branchId}
                    onChange={getFormInfo("branchId")}
                    // disabled={vendorPaymentForm?.isEdit}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.branch}
                  </p>
                </div>
                <div className="new-global-single-input">
                  {MultiCurrencyView !== undefined &&
                  MultiCurrencyView?.isMultiCurrency ? (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={currencyListUpdated || []}
                      getOptionLabel={(option) => option?.name}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Currency*" focused />
                      )}
                      value={vendorPaymentForm?.currency}
                      onChange={getFormInfo("currency")}
                      // disabled={vendorPaymentForm?.isEdit}
                    />
                  ) : (
                    <TextField
                      value={vendorPaymentForm?.currency?.name || ""}
                      sx={{ caretColor: "transparent" }}
                      className="disabled-input"
                      id="outlined-basic"
                      label="Currency*"
                      variant="outlined"
                      type="text"
                      focused
                      title="Multi Currency not enabled"
                      // disabled={vendorPaymentForm?.isEdit}
                    />
                  )}
                  <p className="doc-validation-alert">
                    {validationAlert?.currency}
                  </p>
                </div>
                {vendorPaymentForm?.paymentMethod === "vendorPayment" &&
                  vendorPaymentForm?.paymentMode === "ADVANCE" && (
                    <div className="new-global-single-input auto-complete-new">
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={vendorPaymentAccounts}
                        getOptionLabel={(option) => option?.accountName || ""}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Account*" focused />
                        )}
                        value={vendorPaymentForm?.account}
                        onChange={getFormInfo("account")}
                        // disabled={vendorPaymentForm?.isEdit}
                      />
                      <p className="doc-validation-alert">
                        {validationAlert?.account}
                      </p>
                    </div>
                  )}
              </div>
            </div>
            {/* other payment  */}
            {vendorPaymentForm?.paymentMethod === "otherPayment" && (
              <>
                <div className="new-global-white-bg-container">
                  <>
                    <div className="global-product-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Account</th>
                            <th>Label</th>
                            <th>Branch</th>
                            <th>Employee</th>
                            <th>Amount</th>
                          </tr>
                        </thead>

                        <tbody>
                          {tableData?.map((item, index) => (
                            <tr
                              key={index}
                              ref={
                                index === tableData.length - 1
                                  ? containerRef
                                  : null
                              }
                            >
                              {item.type === "header" ? (
                                <>
                                  <td colSpan="5">
                                    <input
                                      type="text"
                                      value={item.headerValue}
                                      onChange={handleInputChange(
                                        index,
                                        "headerValue"
                                      )}
                                      // disabled={vendorPaymentForm?.isEdit}
                                    />
                                  </td>
                                  <td style={{ border: "none", width: "4%" }}>
                                    <IconButton
                                      disabled={vendorPaymentForm?.isEdit}
                                      onClick={() => handleDelete(index)}
                                      style={{ height: "30px" }}
                                    >
                                      <i
                                        className="bi bi-trash3"
                                        style={{
                                          color: "#db0000",
                                          fontSize: "1.3rem",
                                        }}
                                      ></i>
                                    </IconButton>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td style={{ minWidth: "200px" }}>
                                    <div className="product-table-auto-complete">
                                      <Autocomplete
                                        options={chartOfAccountList || []}
                                        style={{ maxHeight: "100px" }}
                                        getOptionLabel={(option) =>
                                          option?.accountName
                                        }
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                        value={item?.account}
                                        onChange={handleInputChange(
                                          index,
                                          "account"
                                        )}
                                        // disabled={vendorPaymentForm?.isEdit}
                                      />
                                    </div>
                                  </td>
                                  <td style={{ maxWidth: "180px" }}>
                                    <input
                                      type="text"
                                      value={item?.label}
                                      onChange={handleInputChange(
                                        index,
                                        "label"
                                      )}
                                      // disabled={vendorPaymentForm?.isEdit}
                                    />
                                  </td>
                                  <td style={{ minWidth: "200px" }}>
                                    <div className="product-table-auto-complete">
                                      <Autocomplete
                                        options={allBranchesList || []}
                                        style={{ maxHeight: "100px" }}
                                        getOptionLabel={(option) =>
                                          `${option?.storeCode}-${option?.branchName}`
                                        }
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                        value={item?.branch}
                                        onChange={handleInputChange(
                                          index,
                                          "branch"
                                        )}
                                        // disabled={vendorPaymentForm?.isEdit}
                                      />
                                    </div>
                                  </td>
                                  <td style={{ minWidth: "200px" }}>
                                    <div className="product-table-auto-complete">
                                      <Autocomplete
                                        options={allActiveEmpList || []}
                                        style={{ maxHeight: "100px" }}
                                        getOptionLabel={(option) =>
                                          option?.staff_name
                                        }
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                        value={item?.employee}
                                        onChange={handleInputChange(
                                          index,
                                          "employee"
                                        )}
                                        // disabled={vendorPaymentForm?.isEdit}
                                      />
                                    </div>
                                  </td>
                                  <td style={{ maxWidth: "80px" }}>
                                    <input
                                      type="text"
                                      value={item?.amount}
                                      onChange={handleInputChange(
                                        index,
                                        "amount"
                                      )}
                                      // disabled={vendorPaymentForm?.isEdit}
                                    />
                                  </td>
                                  <td style={{ border: "none", width: "4%" }}>
                                    <IconButton
                                      onClick={() => handleDelete(index)}
                                      style={{ height: "30px" }}
                                      // disabled={vendorPaymentForm?.isEdit}
                                    >
                                      <i
                                        className="bi bi-trash3"
                                        style={{
                                          color: "#db0000",
                                          fontSize: "1.3rem",
                                        }}
                                      ></i>
                                    </IconButton>
                                  </td>
                                </>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {editedData?.status !== "COMPLETED" && (
                      <div className="add-row-btn-container">
                        <div
                          className="global-single-input auto-complete add-line-autocomplete"
                          style={{ width: "76%" }}
                        >
                          <Autocomplete
                            options={addRowData || []}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => <TextField {...params} />}
                            value={addRowInput}
                            onChange={(e, newValue) => setAddRowInput(newValue)}
                            renderClear={() => null}
                            // disabled={vendorPaymentForm?.isEdit}
                          />
                        </div>
                        <button
                          // disabled={vendorPaymentForm?.isEdit}
                          onClick={() => addRow()}
                          className="add-row-btn"
                        >
                          +
                        </button>
                      </div>
                    )}
                  </>
                </div>
              </>
            )}
            {/* vendor payment  */}
            {vendorPaymentForm?.paymentMethod === "vendorPayment" &&
              vendorPaymentForm?.paymentMode === "AGAINST REF" && (
                <>
                  <div className="new-global-white-bg-container">
                    <>
                      <div className="global-product-table">
                        <table>
                          <thead>
                            <tr>
                              <th>Bill</th>
                              <th>Date</th>
                              <th>Bill Reference</th>
                              <th>Amount Total</th>
                              <th>Amount Due</th>
                              <th>Amount </th>
                            </tr>
                          </thead>

                          <tbody>
                            {Array.isArray(vendorBills) &&
                            vendorBills.length > 0 ? (
                              vendorBills.map((item, index) => (
                                <tr
                                  key={index}
                                  ref={
                                    index === tableData?.length - 1
                                      ? containerRef
                                      : null
                                  }
                                >
                                  {item.type === "header" ? (
                                    <>
                                      <td colSpan="5">
                                        <input
                                          type="text"
                                          value={item.headerValue}
                                          onChange={handleInputChange(
                                            index,
                                            "headerValue"
                                          )}
                                          disabled={vendorPaymentForm?.isEdit}
                                        />
                                      </td>
                                      <td
                                        style={{ border: "none", width: "4%" }}
                                      >
                                        <IconButton
                                          disabled={vendorPaymentForm?.isEdit}
                                          onClick={() => handleDelete(index)}
                                          style={{ height: "30px" }}
                                        >
                                          <i
                                            className="bi bi-trash3"
                                            style={{
                                              color: "#db0000",
                                              fontSize: "1.3rem",
                                            }}
                                          ></i>
                                        </IconButton>
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td style={{ minWidth: "200px" }}>
                                        {item?.name}
                                      </td>
                                      <td style={{ maxWidth: "180px" }}>
                                        {item?.billDate}
                                      </td>
                                      <td style={{ minWidth: "200px" }}>
                                        {item?.billreference || ""}
                                      </td>
                                      <td style={{ minWidth: "200px" }}>
                                        {item?.total}
                                      </td>
                                      <td style={{ maxWidth: "80px" }}>
                                        {item?.amountDue}
                                      </td>
                                      <td>
                                        {" "}
                                        <input
                                          type="Number"
                                          defaultValue={item?.amountDue}
                                          value={item?.amount}
                                          onChange={(e) =>
                                            handleAmountChange(e, index, item)
                                          }
                                          // disabled={vendorPaymentForm?.isEdit}
                                        />
                                      </td>
                                      <td
                                        style={{ border: "none", width: "4%" }}
                                      >
                                        <Checkbox
                                          // disabled={vendorPaymentForm?.isEdit}
                                          checked={item?.isSelected}
                                          onChange={(e) =>
                                            handleSelected(e, index)
                                          }
                                          sx={{
                                            color: "#000000",
                                            "&.Mui-checked": {
                                              color: "#000000",
                                            },
                                          }}
                                        />
                                      </td>
                                    </>
                                  )}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td style={{ textAlign: "center" }} colSpan={6}>
                                  No Data
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  </div>
                </>
              )}
            <hr className="global-hr" />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() => {
                  vendorPaymentSingleViewApi({ _id: singleViewId }) &&
                    setImportPopup(false);
                }}
                style={{ height: "35px", width: "25%" }}
                className="btn btn-secondary-outlined"
              >
                Cancel
              </button>
              <button
                style={{ height: "35px", width: "25%" }}
                className="btn btn-primary"
                onClick={vendorPaymentFn("update")}
              >
                Post
              </button>
            </div>
          </div>
        </Dialog>

        {/* payment return */}
        <Dialog
          open={alertPopup}
          onClose={() => {
            alertPopup();
          }}
          maxWidth="lg"
        >
          <div style={{ textAlign: "center", paddingTop: "24px" }}>
            {" "}
            <ReportProblemOutlinedIcon
              sx={{ color: " #d50808", fontSize: "xx-large" }}
            />
          </div>
          <div
            className="shift-end-button-password-container"
            style={{ width: "25vw", padding: "2% 4%" }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                margin: "2% 0",
                alignSelf: "center",
                paddingBottom: "14px",
              }}
            >
              Are you sure you want to return
            </div>
            <hr
              style={{
                backgroundColor: "#bfbfc3",
                width: "100%",
                height: "2px",
                marginBottom: "27px",
              }}
            ></hr>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                style={{ height: "28px", width: "28%" }}
                className="btn btn-primary"
                onClick={vendorPaymentFn("return")}
              >
                Yes
              </button>
              <button
                onClick={() => setAlertPopup(false)}
                style={{ height: "28px", width: "28%" }}
                className="btn btn-secondary-outlined"
              >
                No
              </button>
            </div>
          </div>
        </Dialog>

        <SuccessSnackbar
          open={snackBarStates.success}
          handleClose={closeSnackbar}
          message={snackBarStates.message}
        />
        <ErrorSnackbar
          open={snackBarStates.error}
          handleClose={closeSnackbar}
          message={snackBarStates.message}
        />
        <div style={{ display: "none" }}>
          <div ref={(el) => (paymentsPrintNew = el)}>
            {vendorPaymentForm?.paymentMethod === "otherPayment" ? (
              <Template />
            ) : (
              <TemplateVendor />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
