import { createSlice } from "@reduxjs/toolkit";

export const addSeatSlice = createSlice({
  name: "addSeatSlice",
  initialState: {
    branch:null,
    selectedFloorDetails:null,
    floors:[],
    activeSelection:null,
    branchListFromApi:[],
    selectedElementDetails:null,
    seatSelection:false
  },

  reducers: {
    setBranch: (state, action) => {
      state.branch = action.payload;
    },
    setSelectedFloorDetails: (state, action) => {
      state.selectedFloorDetails = action.payload;
    },
    setFloors: (state, action) => {
      state.floors = action.payload;
    },
    setBranchList: (state, action) => {
      state.branchListFromApi = action.payload;
    },
    setSelectedElementDetails: (state, action) => {
      state.selectedElementDetails = action.payload;
    },
    setSeatSelection: (state, action) => {
      state.seatSelection = action.payload;
    },
    
  },
});

export const {setSeatSelection,setSelectedElementDetails,setBranchList, setBranch,setSelectedFloorDetails,setFloors} = addSeatSlice.actions;

export default addSeatSlice.reducer;
