export const orderStatus = {
  COMPLETE: "order-completed",
  REJECT: "order-reject",
  RESET: "order-reset",
  TOCOOK: "order-to-cook",
  DELIVERY: "order-delivery",
  PICKUP: "order-pickup-ready",
  CANCEL: "order-cancel",
};

export const listKotOrderStatus = {
  ALL: "ALL",
  NEW: "NEW",
  PREPARING: "PREPARING",
  COMPLETED: "COMPLETED",
  DELIVERY:"DELIVERY"
};

export const actionKeyName = {
  TOCOOK: "TOCOOK",
  REJECT: "REJECT",
  COMPLETED: "COMPLETED",
  DELIVERY: "DELIVERY",
  RESET: "RESET",
};
export const NETWORK = {
  OFFLINE:"OFFLINE",
  ONLINE:"ONLINE",
};
export const APISTATUS ={
  PENDING:"PENDING",
  COMPLETED:"COMPLETED",
 
}
