import { createSlice } from "@reduxjs/toolkit";

export const kitchenOrderTicketSlice = createSlice({
  name: "kitchenOrderTicketSlice",
  initialState: {
    sideMenu: false,
    popup: true,
    kotOrderList: [],
    kotOrderStatus: { index: 0, status: { key: "ALL" } },
    isLoading: true,
    alertMessage: null,
    selectedKOTOrder: null,
    darkMode: false,
    networkStatus: "ONLINE",
    sideMenuList: [],
    sortProduct: null,
  },

  reducers: {
    setSideMenu: (state, action) => {
      state.sideMenu = action.payload;
    },
    setPopup: (state, action) => {
      state.popup = action.payload;
    },
    setKotOrderList: (state, action) => {
      state.kotOrderList = action.payload;
    },
    setKotOrderStatus: (state, action) => {
      state.kotOrderStatus = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setAlertMessage: (state, action) => {
      state.alertMessage = action.payload;
    },
    setSelectedKOTOrder: (state, action) => {
      state.selectedKOTOrder = action.payload;
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
    setNetworkStatus: (state, action) => {
      state.networkStatus = action.payload;
    },
    setSideMenuList: (state, action) => {
      state.sideMenuList = action.payload;
    },
    setSortProduct: (state, action) => {
      state.sortProduct = action.payload;
    },
  },
});

export const {
  setSortProduct,
  setSideMenuList,
  setNetworkStatus,
  setDarkMode,
  setSelectedKOTOrder,
  setAlertMessage,
  setIsLoading,
  setKotOrderStatus,
  setKotOrderList,
  setSideMenu,
  setPopup,
} = kitchenOrderTicketSlice.actions;

export default kitchenOrderTicketSlice.reducer;
