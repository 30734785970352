import React from 'react'
import AdvncShiftReportHeader from './AdvncShiftReportHeader'
import BillTypeSummaryPrint from './BillTypeSummaryPrint'
import DeliveryPartnerSummaryPrint from './DeliveryPartnerSummaryPrint'
import DeliveryBoysSummaryPrint from './DeliveryBoysSummaryPrint'
import DiscountSummaryPrint from './DiscountSummaryPrint'
import CategorySalesSummaryPrint from './CategorySalesSummaryPrint'

const AdvanceShiftReportPrintComponent = ({selectedButton}) => {
  return (
    <div className='flex-column advance-shift-report-table-container'>
        <AdvncShiftReportHeader/>
        {
            selectedButton === "BILL TYPE"?
            <BillTypeSummaryPrint/>:
            selectedButton === "DELIVERY PARTNERS"?
            <DeliveryPartnerSummaryPrint/>:
            selectedButton === "DELIVERY BOYS"?
            <DeliveryBoysSummaryPrint/>:
            selectedButton === "DISCOUNT"?
            <DiscountSummaryPrint/>:
            <CategorySalesSummaryPrint/>
        }
    </div>

  )
}

export default AdvanceShiftReportPrintComponent