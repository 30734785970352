import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Days from "../../Billing/JSON/days.json"
import { FilePicker } from '../../../../Single Components/filePicker'
import { useDispatch, useSelector } from 'react-redux'
import { setImageUpload, setSpecialItemsFormInfo } from '../specialItemsSlice'
import { filterObjFromList } from '../../../../../Js/generalFunctions'

function SpecialItemsInputFields() {

const dispatch=useDispatch()

const {isCombo,specialItemsSingleList,isEdit}=useSelector((state)=>state.specialItemsSlice)
const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);
const userRole=useSelector((state)=>state.userRoleSlice.value)
const uomSubList = useSelector((state) => state.unitOfMeasurmentSlice.subUomList);
const allTaxList = useSelector((state) => state.taxSettingsSlice.value);
const allTaxListSalesType = allTaxList?.filter(
    (item) => item?.taxType === "Sales"
  );

const [filteredTaxList,setFilteredTaxList]=useState(allTaxListSalesType)
const formValuesInitial = {
    name: "",
    isEdit: false,
    uom:null,
    comboPrice:"",
    days:[],
    branches:[],
    tax:[]
}
const [image, setImage] = useState(null);

const [formValues, setFormValues] = useState(formValuesInitial)

const getImage = (e) => {
    let file = e.target.files[0];
    setImage(file)
};


const taxOnChange=(e,newValue)=>{
    let newFilteredTaxOptions=[]
    if(newValue?.length!==0){
        let taxObj=newValue[0].includedPrice
        newFilteredTaxOptions =allTaxListSalesType?.filter((option) => option.includedPrice === taxObj)
    }else{
        newFilteredTaxOptions =allTaxListSalesType
    }
    setFilteredTaxList(newFilteredTaxOptions)
    setFormValues({...formValues,tax:newValue})
}

useEffect(()=>{
 dispatch(setSpecialItemsFormInfo(formValues))
},[formValues])
useEffect(()=>{
    dispatch(setImageUpload(image))
},[image])

useEffect(()=>{
    if(specialItemsSingleList!==null && allTaxList!==undefined){

    let taxesId=specialItemsSingleList?.taxes || []
    let taxObjects = allTaxList?.filter(obj => taxesId.includes(obj._id));

    setFormValues({...formValues,
                    name:specialItemsSingleList?.name ||"",
                    comboPrice:specialItemsSingleList?.comboPrice ||"",
                    branches:allBranchesList?.filter(obj => specialItemsSingleList?.branchId?.includes(obj._id)) || [],
                    days:Days.filter(obj => specialItemsSingleList?.weekdays.includes(obj.value)) || [],
                    uom:filterObjFromList("_id",uomSubList,"uom",specialItemsSingleList),
                    tax:taxObjects
                })
                
    setImage(specialItemsSingleList?.imageUrl || null)

        if(taxObjects.length!==0){
            let taxObj=taxObjects[0].includedPrice
            let newFilteredTaxOptions=allTaxListSalesType?.filter((option) => option.includedPrice === taxObj)
            setFilteredTaxList(newFilteredTaxOptions)
        }else{
            setFilteredTaxList(allTaxListSalesType)
        }

    }else{
      setFormValues(formValuesInitial)
    }
},[specialItemsSingleList,allTaxList,uomSubList])

  return (
      <div className='justify-center' style={{justifyContent:"start",gap:"30px"}}>
          <div className="new-global-input-container" style={{flex:"1",flexWrap:"wrap",gap:"0 30px",justifyContent:"space-between"}}>
              <div className="new-global-single-input" style={{width:isCombo?"calc(50% - 15px)":"100%"}}>
                  <TextField
                      id="outlined-basic"
                      label={`${isCombo?"Combo":"Offer"} Name*`}
                      variant="outlined"
                      type="text"
                      focused
                      value={formValues?.name}
                      onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                      disabled={isEdit}
                  />
              </div>
              <div className="new-global-single-input" style={{width:"calc(50% - 15px)"}}>
                  <Autocomplete
                      multiple
                      options={Days || []}
                      getOptionLabel={(option) => option.value}
                      renderOption={(props, option) => (
                        <li {...props} key={option.label}>
                          <span>{option.label}</span>
                        </li>
                      )}
                      renderInput={(params) => (
                          <TextField {...params}  label="Week Days" focused variant='outlined'/>
                      )}
                      value={formValues?.days}
                      onChange={(e,newValue) => setFormValues({ ...formValues, days: newValue })}
                      disabled={isEdit}
                  />
              </div>
              <div className="new-global-single-input" style={{width:"calc(50% - 15px)"}}>
                  <Autocomplete
                      multiple
                      options={userRole==="admin"?allBranchesList:allowedBranchList || []}
                      getOptionLabel={(option) => option?.branchName}
                      renderInput={(params) => (
                          <TextField {...params} label="Branch" focused variant='outlined'/>
                      )}
                      value={formValues?.branches}
                      onChange={(e,newValue) => setFormValues({ ...formValues, branches: newValue })}
                      disabled={isEdit}
                  />
              </div>
              {
                isCombo&&
                <>
                      <div className="new-global-single-input" style={{width:"calc(25% - 22.5px)"}}>
                          <TextField
                              id="outlined-basic"
                              label="Special Combo Price*"
                              variant="outlined"
                              type="number"
                              focused
                              value={formValues?.comboPrice}
                              onChange={(e) => setFormValues({ ...formValues, comboPrice: e.target.value })}
                              disabled={isEdit}
                          />
                      </div>
                      <div className="new-global-single-input" style={{width:"calc(25% - 22.5px)"}}>
                          <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={uomSubList || []}
                              getOptionLabel={(option) => option?.uomName}
                              renderInput={(params) => (
                                  <TextField {...params} label="UOM*" focused />
                              )}
                              renderOption={(props, option) => (
                                  <li {...props} key={option._id}>
                                      <span>{option.uomName}</span>
                                  </li>
                              )}
                              value={formValues?.uom}
                              onChange={(e,newValue) => setFormValues({ ...formValues, uom: newValue })}
                              disabled={isEdit}
                          />
                      </div>
                      <div className="new-global-single-input" style={{width:"calc(50% - 15px)"}}>
                          <Autocomplete
                              multiple
                              options={filteredTaxList || []}
                              getOptionLabel={(option) => option.taxName}
                              renderInput={(params) => (
                                  <TextField {...params} label="Tax" focused variant='outlined' />
                              )}
                              value={formValues?.tax}
                              onChange={taxOnChange}
                              disabled={isEdit}
                          />
                      </div>
                </>
              }
          </div>
          <FilePicker
              id="comboImg"
              uploadImageUrl={image}
              setImage={setImage}
              onChange={getImage}
              disabled={isEdit}
          />
      </div>
  )
}

export default SpecialItemsInputFields