import { createSlice } from "@reduxjs/toolkit";


export const RfqSlice = createSlice({
    name:"Rfq",
    initialState:{
        value:undefined,
        rfqSingleView:"",
        purchaseOrderSingleView:undefined,
        purchaseOrderList:"",
        poHistory:undefined
    },
    reducers:{
        getAllRfqList:(state,action)=>{
            state.value = action.payload.RfqList;
        },
        rfqSingleView:(state,action)=>{
            state.rfqSingleView = action.payload.singleRowData;
        },
        getAllPurchaseOrderList:(state,action)=>{
            state.purchaseOrderList = action.payload.purchaseOrderList;
        },

        purchaseOrderSingleView:(state,action)=>{
            state.purchaseOrderSingleView = action.payload.singleRowData;
        },
        get_po_history:(state,action)=>{
            state.poHistory=action.payload.poHistoryData
        },
        resetRfqSingleView:(state,action)=>{
            console.log("#####")
            state.rfqSingleView="";
        }

    }
})
export const {getAllRfqList,rfqSingleView,getAllPurchaseOrderList,purchaseOrderSingleView,resetRfqSingleView,get_po_history} = RfqSlice.actions;
export default RfqSlice.reducer;