import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getCurrentTime, previousDate, today } from '../../../../Js/Date';
import SearchIcon from "@mui/icons-material/Search";
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { useSelector } from 'react-redux';
import { SalesReportSingleAPIcall } from './SalesReportApi';
import ReactToPrint from 'react-to-print';
import { IconButton, Tooltip } from '@mui/material';
import ExcelJS from "exceljs"

export const SalesReportSingleView = () => {
    let navigate = useNavigate();
    const [selectedHeading, setSelectedHeading] = useState("Ledger Balance");
    const [startDate, setStartDate] = useState(previousDate);
    const [endDate, setEndDate] = useState(today);
    const [FormDataInfo,setFormDataInfo]=useState([])
    const [filteredData, setFilteredData] = useState({}); 
    const singleViewID = localStorage.getItem("singleViewById");
    
   const account = localStorage.getItem("account")
   const singleViewId =localStorage.getItem("singleViewById")
   const StartDate = localStorage.getItem("fromDate");
   const EndDate = localStorage.getItem("toDate");

    //initialise values
  
    const decimalPosition = localStorage.getItem("decimalPosition");
  
    const singleView = useSelector((state)=>state?.salesReport?.salesReportSinglelist)
    console.log(singleView);

    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
      };
    
    const getFromDate = (e) => {
      setStartDate(e.target.value);
    };
    const getToDate = (e) => {
      setEndDate(e.target.value);
    };
    const handleBackpage = () => {
      navigate("/userdashboard/sales/Report/salesReport");
    };
    const handleFilterChange = (updatedItems) => {
      setFilteredData(updatedItems);
    };
    const formatFilterInfo = (filters) => {
      const parts = [];
    
      if (filters?.fromDate && filters?.toDate) {
        parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
      }
      console.log(filters?.fromDate);
  
      if (filters?.branchList) {
        parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
      }
     
      
      return parts; // Return the array directly
    };
    const downloadList = () => {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("Sales Report");
      sheet.properties.defaultRowHeight = 20;
    
      // Add header section
      const titleRow = sheet.addRow(["Sales Report"]);
      titleRow.font = { size: 18, bold: true };
      sheet.mergeCells('A1:G1');
      titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    
      // Add additional header information
      const filterParts = formatFilterInfo(filteredData) || [];
    
      // Ensure filterParts is an array and iterate over it
      if (Array.isArray(filterParts)) {
        filterParts.forEach((part, index) => {
          const row = sheet.addRow([part]);
          row.font = { size: 15, bold: true };
          sheet.mergeCells(`A${index + 2}:G${index + 2}`);
          row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
        });
      }
    
      // Add an empty row between header and table
      sheet.addRow([]);
    
      // Header row styling
      const headerRow = sheet.addRow(["Date", "Customer", "Invoice No.", "Qty","sales Price","Discount","Sales Amount"]);
      const headerFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF002995" }, // Use full hex code with "FF" prefix for better compatibility
      };
      const headerFont = {
        size: 15,
        bold: true,
        color: { argb: "FFFFFFFF" }, // White font color
      };
    
      headerRow.eachCell((cell) => {
        cell.fill = headerFill;
        cell.font = headerFont;
        cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
      });
    
      sheet.columns = [
        { header: "Date", key: "date", width: 50 },
        { header: "Customer", key: "customername", width: 20 },
        { header: "Invoice No.", key: "invoiceNo", width: 20 },
        { header: "Qty", key: "qty", width: 20 },
        { header: "sales Price", key: "salesPrice", width: 20 },
        { header: "Discount", key: "discount", width: 20 },
        { header: "Sales Amount", key: "salesAmount", width: 20 },
      ];
    
      singleView?.table?.forEach((item) => {
        const row = sheet.addRow({
          date: item.date,
          customername: item.customername,
          invoiceNo: item.invoiceNo,
          qty: item.qty,
          salesPrice: item.salesPrice,
          discount: item.discount,
          salesAmount: item.salesAmount,
        });
    
        const rowFill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFededed" }, // Use full hex code with "FF" prefix for better compatibility
        };
        
        row.eachCell((cell, colNumber) => {
          cell.fill = rowFill;
          cell.border = {
            top: { style: "thin", color: { argb: "FFFFFFFF" } },
            left: { style: "thin", color: { argb: "FFFFFFFF" } },
            bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
            right: { style: "thin", color: { argb: "FFFFFFFF" } },
          };
          if (colNumber>1) { // Assuming the balance column is the 4th column
           cell.alignment = {  horizontal: 'right' }; // Ensure header text
          }
        });
      });
    
      // Add row for totals
      const totalRow = sheet.addRow([
        "Total",
        "",
        "",      
        ` ${singleView?.total?.qty}`,
        ` ${singleView?.total?.salesPrice}`,
        ` ${singleView?.total?.discount}`,
        ` ${singleView?.total?.salesAmount}`,
        
      ]);
    
      totalRow.eachCell((cell,colNumber) => {
        cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
        cell.fill = headerFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber>1) { // Assuming the balance column is the 4th column
          cell.alignment = {  horizontal: 'right' }; // Ensure header text
         }

      });
    
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "Sales Report.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    };
    
    useEffect(()=>{
        SalesReportSingleAPIcall({
     fromDate:  StartDate===''?'':`${StartDate} 00:00:00`,
      endDate: EndDate===''?'':`${EndDate} 23:59:59`,
      productId: singleViewId,
    })
      },[StartDate,EndDate,singleViewId])
      
  return (
    <div className="global-page-parent-container"style={{margin:"0"}}>
      <div className="global-white-bg-container">
        <div className="header-div-path-search" style={{display:"flex", justifyContent:"space-between"}}>
           <div style={{display:"flex"}}>
             <div className="sharp-arrow" style={{width:"115px",height:"auto"}} > <p onClick={handleBackpage}> Sales Report &gt;</p></div>
             <div className="sharp-arrow" style={{marginLeft:"18px",width:"auto"}}> <p>{account}</p></div>
            </div>
            <div style={{ display: "flex" }}>
         
         <Tooltip title="Share">
           <IconButton>
             <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
           </IconButton>
         </Tooltip>

         <ReactToPrint
           trigger={() => (
             <IconButton className="printer-div" style={{ padding: "1px" }}>
               <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
             </IconButton>
           )}
         //   content={() => paymentsPrintNew}
         />

         <Tooltip title="Download">
           <IconButton 
           onClick={downloadList}
           >
             <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
           </IconButton>
         </Tooltip>

        
       </div>
            </div>
        {/* <div className="secound-main-container-div" style={{paddingTop:"0"}}>
        <div className="table-header" style={{height:"34px"}}>
          <p style={{fontSize:"14px",marginLeft:"-16px"}}>Sales Report By {account}</p>
        </div>*/}
      {/* </div> */}
      <div className="table-container" style={{ maxHeight: "73vh" }}>
        <table className="table">
          <thead>
            <tr>
              <th style={{paddingLeft: "12px", }}>Date</th>
              <th>Customer</th>
              <th>Invoice No.</th>
              <th>Qty</th>
              <th>sales Price</th>
              <th>Discount</th>
              <th>Sales Amount</th>
            </tr>
          </thead>
          <tbody>
          {singleView?.table?.map((item,index)=>(
              <tr key={index}>
                <td style={{ textAlign:"start", paddingLeft: "12px" }}>
                  {item.date}
                </td>
                <td>{item.customername}</td>
                <td>{item.invoiceNo}</td>
                <td>{item.qty}</td>
                <td>{item.salesPrice}</td>
                <td>{item.discount}</td>
                <td>{item.salesAmount}</td>
              </tr>
           ))}
          </tbody>
          <tfoot>
            <tr style={{ textAlign: "end" }}>
              <th  colSpan="3" style={{ width: "55%", paddingLeft: "12px" }}>Total</th>        
              <th>{singleView?.total?.qty}</th>        
              <th>{singleView?.total?.salesPrice}</th>        
              <th>{singleView?.total?.discount}</th>
              <th>{singleView?.total?.salesAmount}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    </div>
  );
};
