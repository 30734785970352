import React, { useEffect } from 'react'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteRecipeAPI, ListRecipeAPI } from './FoodManagementNewAPI';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { list_single_view } from './FoodManagementSlice';
import store from '../../../Redux/store';


const RecipeFoodList = () => {
  let navigateTo = useNavigate();
  const allRecipeList = useSelector((state) => state.foodManagement.listRecipe)
  // console.log(allRecipeList, "looo");

  const createNew = () => {
    localStorage.removeItem("recipe_id")
    store.dispatch(list_single_view(undefined))

    navigateTo("/userdashboard/foodManagement/recipe/create")

  }
  const handleClickRow = (row) => {
    localStorage.setItem("recipe_id", row?._id)
    navigateTo("/userdashboard/foodManagement/recipe/create")
  }
  const deleteRecipe = (row) => {
    DeleteRecipeAPI({ recipeId: row._id })
  }
  useEffect(() => {
    ListRecipeAPI();
  }, [])

  return (
    <div className="global-page-parent-container special-items-ctn" >
      <div className="global-white-bg-container Header">
        <p>Recipe</p>
      </div>
      <div className='global-white-bg-container'>
        <div className="justify-space-between"
          style={{ gap: "0 12px", display: 'flex', justifyContent: 'end', alignItems: 'end' }}
        >
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '9px',

          }}>

            <button className='create-button-blue' onClick={() => createNew()}>Create</button>
          </div>
        </div>
        <hr className="global-hr" style={{ paddingTop: "10px" }} />
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {allRecipeList?.list?.map((item, index) => (
            <div key={index}
              className="receipe-card" onClick={() => handleClickRow(item)}>
              <div className="justify-space-between" style={{ borderBottom: "none", alignItems: "flex-start" }}>
                <div >
                  <p className='head' style={{ fontSize: "larger", margin: "0" }}>{item?.sequenceName}</p>
                  <p style={{ marginBottom: "0", fontSize: "small" }}>Recipe</p>
                  <p style={{ marginTop: "0", color: "black", fontWeight: "600" }}>{item?.bomName}</p>
                  <p style={{ marginBottom: "0", fontSize: "small" }}>Product</p>
                  <p style={{ marginTop: "0", color: "black", fontWeight: "600", marginBottom: "0px" }}>{item.productName}</p>
                </div>
                <div>
                  <IconButton onClick={(event) =>{event.stopPropagation();deleteRecipe(item);}}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </div>

              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default RecipeFoodList