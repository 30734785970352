import axios from "axios";
import { HEADERS } from "../../../../../API/UrlAndPaths";
import store from "../../../../../Redux/store";
import { get_stock_move_list } from "./stockMovesSlice";

// export const viewStockMovesListAPICall=(body,setIsLoading)=>{

//     setIsLoading !== undefined && setIsLoading(true);
//     axios.post("inventory/viewStockMoves",body,HEADERS)
//     .then((res)=>{
//         if(res.status===200){
//             store.dispatch(get_stock_move_list({stockMoveData:res.data}))
//             setIsLoading !== undefined && setIsLoading(false);
//          }
//     })
//     .catch((err)=>{
//           store.dispatch(get_stock_move_list({stockMoveData:undefined}))
//            setIsLoading !== undefined && setIsLoading(false);
//     })
// }

// api call for sort stock move
export const sortViewStockMovesListAPICall = async (body, setIsLoading) => {
  setIsLoading(true)
  try {
    let response = await axios.post("inventory/viewStockMoves", body, HEADERS);
    if (response.status === 200) {
      store.dispatch(get_stock_move_list({ stockMoveData: response.data }));
      setIsLoading(false);
    }
  } catch (e) {
    
    console.error(e);
    store.dispatch(get_stock_move_list({ stockMoveData: undefined }));
    setIsLoading(false);
  }
};
