import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { JournalEntriesFilterAPICall, deleteSingleJournalEntryAPICall, viewJournalEntryAPICall } from './JournalEntry/journalEntryApi';
import { useSelector } from 'react-redux';
import { convertDateFormat, getCurrentTime } from '../../../../Js/Date';
import { update_journal_entry_list_by_row } from './JournalEntry/journalEntrySlice';
import store from '../../../../Redux/store';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import { useState } from 'react';
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../Single Components/CustomPagination';
import { setFilterActive } from '../../../Single Components/listSlice/filterCategorySlice';
import ReactToPrint from 'react-to-print';
import ExcelJS from "exceljs"
import TableListActionButtons from '../../../Single Components/ActionButtons/TableListActionButtons';

export const JournalEntries = () => {

  let navigateTo = useNavigate()
  const tableListData = useSelector((state) => state.journalEntrySlice.journalEntryList);
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const decimalePosition = localStorage.getItem("decimalPosition")
  const [isLoading, setIsLoading] = useState(false)
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = tableListData?.pages;
  const [filteredDatas, setFilteredData] = useState({});
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false
  });
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  const handleFilterChange=(updatedItems)=>{
    setFilteredData(updatedItems)
  }
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };

  const handleClickRow = (row) => {
    localStorage.setItem("singleViewById", row._id)
    store.dispatch( setFilterActive(window.location.pathname))
    navigateTo("/userdashboard/accounts/accounting/journalEntriesCreate")
  }

  const deleteJournalEntryClick = (id) => () => {
    deleteSingleJournalEntryAPICall({ id })
  }
  const deletePostedJournal = () => {
    setSnackBarStates({ ...snackBarStates, message: "posted journal entrie can't be deleted!! ", error: true })
  }
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }
    if (filters?.location) {
      parts.push(`location: ${filters?.location?.name}`);
    }
    if (filters?.toLocation) {
      parts.push(`To Location: ${filters?.toLocation?.name}`);
    }
    if (filters?.status) {
      parts.push(`status: ${filters?.status}`);
    }
    if (filters?.createdBy) {
      parts.push(`createdBy: ${filters?.createdBy}`);
    }
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Journal Entries");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Journal Entries"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells("A1:G1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredDatas) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:G${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "Date",
      "Name",
      "Reference",
      "Journal",
      "Total",
      "Created By",
      "Status",
      // "Delete",
     
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Date", key: "accountingDate", width: 15 },
      { header: "Name", key: "name", width: 20 },
      { header: "Reference", key: "reference", width: 25 },
      { header: "Journal", key: "journalName", width: 25 },
      { header: "Total", key: "total", width: 15 },
      { header: "Created By", key: "CREATEDBY", width: 25 },
      { header: "Journal Entries", key: "status", width: 25 },
      
      // { header: "Delete", key: "defaultAccountName", width: 35 },
     
    ];

    tableListData?.list?.forEach((item, index) => {
      const row = sheet.addRow({
        accountingDate: convertDateFormat(item?.accountingDate),
        name: item.name,
        reference: item.reference,
        journalName: item.journalName,
        total: item?.total,
        CREATEDBY: item.CREATEDBY,
        status:item.status
        
      });
     

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: "thin", color: { argb: "FF000000" } },
          //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
          //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Journal Entries.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
 
  useEffect(() => {
    localStorage.removeItem("singleViewById")
    store.dispatch(update_journal_entry_list_by_row({ journalEntryRowData: undefined }))

    // if(userRole==="user"){
    //   viewJournalEntryAPICall({branchId:userBranchId},setIsLoading)
    // }
    // else{
    //   viewJournalEntryAPICall({},setIsLoading)
    // }
  }, [])
 useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&
      JournalEntriesFilterAPICall({
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        branchList: FormDataInfo.branchList,
        status: FormDataInfo.status[0]==="POSTED" ?["Completed"]:FormDataInfo.status,
        search: FormDataInfo?.search,
        journal: FormDataInfo?.journal,
        account: [],
        partner: [],
        index: currentPage-1,
      }, setIsLoading);
    }
  
  }, [FormDataInfo, currentPage]);

  return (
    <div className='global-page-parent-container updated-global-page-parent-container'>
      <div className="justify-space-between list-create-head-bar">
         <h3>Journal Entries</h3>
         <div className="create-head-buttons-container">
          <TableListActionButtons downloadClick={downloadList} />
          <CategorySearchandFilter
            statusOptions={['POSTED', 'DRAFT']}
            onData={handleDataFromChild}
            isStatus={true}
            onBranchSelect={handleBranchSelect}
            isJournal={true}
            onFilterChange={handleFilterChange}
          />
          <button className='create-button-blue btn btn-primary'
            onClick={() => navigateTo("/userdashboard/accounts/accounting/journalEntriesCreate")}>
            Create</button>
         </div>
      </div>

      <div className="justify-space-between pagination-container">
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          setFormDataInfo={setFormDataInfo}
        />
      </div>

        <div className="new-global-table-container updated-table-container">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Reference</th>
                <th>Journal</th>
                <th>Total</th>
                <th>Created By</th>
                <th>Status</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {
                isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={8}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) :
                 
                  tableListData?.list !== undefined && tableListData?.list?.length !== 0 ?
                  tableListData?.list?.slice(0)?.map((r,i)=>(
                    <tr key={i} style={{ cursor: "pointer" }}>
                         <td onClick={() => handleClickRow(r)}>{convertDateFormat(r?.accountingDate)}</td>
                         <td onClick={() => handleClickRow(r)}>{r?.name}</td>
                        <td onClick={() => handleClickRow(r)}>{r?.reference}</td>
                        <td onClick={() => handleClickRow(r)}>{r?.journalName}</td>
                         <td onClick={() => handleClickRow(r)}>{r?.total?.toFixed(decimalePosition)}</td>
                         <td onClick={() => handleClickRow(r)}>{r?.CREATEDBY || "---"}</td>
                         <td onClick={() => handleClickRow(r)} className='table-data-box'>
                           {r?.status === "DRAFT" ? (
                            <span className='draft'> DRAFT</span>
                          ) : r?.status === "POSTED" ? (
                            <span className='post'> POST</span>
                          ) : r?.status === "CANCEL" ? (
                            <span className="cancel"> CANCEL</span>
                          ) : (
                            <span>{r?.status}</span>
                          )}
                        </td>
                        <td>
                          <IconButton
                            onClick={r?.status === "POSTED" ? deletePostedJournal : deleteJournalEntryClick(r?._id)}
                          >
                            <i className={`bi bi-trash3 ${r?.status === "POSTED"
                                ? "edit-icon-disabled"
                                : "delete-icon"
                              }`}></i>
                          </IconButton>
                        </td>
                      </tr>
                  ))


                    :
                    <tr>
                      <td colSpan={8}>No data</td>
                    </tr>
              }

            </tbody>
          </table>
        </div>
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
    </div>
  )
}
