import axios from "axios";
import store from "../../../../../Redux/store";
import { HEADERS } from "../../../../../API/UrlAndPaths";
import {
  generate_vendor_payment_name,
  get_vendor_payment_api_res_list,
} from "../Slice/vendorPaymentSlice";
import {
  getCustomerGroup_List,
  getPaymentTerms_List,
  get_vendor_list_api_res_list,
  get_vendor_list_row_data_list,
} from "../Slice/vendorsListSlice";
import { vendorBillSingleViewApi } from "./vendorBillAPI";
import { setSubmitLoading } from "../../../../../Redux/Dashboard/mainDashBoardSlice";
import { setErrorSnack, setSnackMsg, setSuccessSnack } from "../../../Sales/Billing/Slice/billingScreenSlice";
import { customerListApi } from "../../../../../API/Customer/customerAPI";

// let singleViewId = localStorage.getItem("singleViewById");
// list vendor bill apicall
export const viewVendorListAPICall = async (setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true);
  await axios
    .post(`account/viewVendor`, {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_vendor_list_api_res_list({ vendorResData: res.data })
        );
        setIsLoading !== undefined && setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(
        get_vendor_list_api_res_list({ vendorResData: undefined })
      );
      setIsLoading !== undefined && setIsLoading(false);
    });
};
export const VendorsFilterAPICall = (body, setIsLoading) => {
  setIsLoading!==undefined&&setIsLoading(true)
  axios
    .post("account/searchVendor", body, {
      headers: {
        'Content-Type': 'application/json',
      },
     
    })
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_vendor_list_api_res_list({ vendorResData: res.data })
        );
        setIsLoading !== undefined && setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(
        get_vendor_list_api_res_list({ vendorResData: undefined })
      );
      setIsLoading !== undefined && setIsLoading(false);
    });
};
//get vendor search list
export const vendorListApi = async (body) => {
  try {
    const response = await axios.post(
      "account/viewVendorForDropdown",
      body,
      HEADERS
    );
    if (response.status === 200) {
      store.dispatch(
        get_vendor_list_api_res_list({ vendorResData: response.data })
      );
    }
  } catch (error) {
    console.error(error.message);
    store.dispatch(get_vendor_list_api_res_list({ vendorResData: undefined }));
  }
};

// add vendor bill api call

export const createVendorAPICall = async (
  body,
  postFn,
  setState,
  state,
  setLoadingForm
) => {
  setLoadingForm !== undefined && setLoadingForm(true);
  await axios
    .post("account/createVendor", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm !== undefined && setLoadingForm(false);
        setState({
          ...state,
          message: "Vendor Created Successfully",
          success: true,
        });
        postFn();
        customerListApi()
        vendorListApi();
      }
    })
    .catch((err) => {
      setLoadingForm !== undefined && setLoadingForm(false);
      setState({ ...state, message: err?.response?.data, error: true });
    });
};
// edit uom api call

export const editVendorAPICall = async (
  body,
  postFn,
  setState,
  state,
  setLoadingForm
) => {
  setLoadingForm !== undefined && setLoadingForm(true);
  await axios
    .put("account/editVendor", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({ ...state, message: "vendor  edited", success: true });
        postFn();
        setLoadingForm !== undefined && setLoadingForm(false);
      }
    })
    .catch((err) => {
      setLoadingForm !== undefined && setLoadingForm(false);
      setState({ ...state, message: err?.response?.data, error: true });
    });
};

export const vendorBillRegisterPaymentAPICall = async (
  body,
  postFn,
  setState,
  state
) => {
  await axios
    .post("account/registerPaymentFromVendorBill", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({ ...state, message: "payment registered", success: true });
        postFn();
      }
    })
    .catch((err) => {
      setState({ ...state, message: err?.response?.data, error: true });
    });
};

//vendorBillRegisterPaymentNewAPICall

export const vendorBillRegisterPaymentNewAPICall = (
  body,
  setState,
  state,
  closeRegistePayment,
  singleViewId
) => {
  store.dispatch(setSubmitLoading(true))

  axios
    .post("account/registerPaymentFromVendorBill", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(setSubmitLoading(false))
        
        if (singleViewId) {
          vendorBillSingleViewApi({ _id: singleViewId });
        }
        setState({ ...state, message: "payment registered", success: true });
        closeRegistePayment();
      }
    })
    .catch((err) => {
      store.dispatch(setSubmitLoading(false))
      setState({ ...state, message: err?.response?.data || "Network error", error: true });
    });
};

//addPaymentTermsApiCall

export const addPaymentTermsApicall = (
  body,clearInput
) => {
  store.dispatch(setSubmitLoading(true))
  axios
    .post("customer/addPaymentTerms", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        clearInput()
        store.dispatch(setSnackMsg("Payment terms created"))
        store.dispatch(setSuccessSnack(true))
        store.dispatch(setSubmitLoading(false))
        ViewPaymentTermsListApiCall();
      }
    })
    .catch((err) => {
      store.dispatch(setSnackMsg(err.response.status===500?"Internal server error":err.response.data))
      store.dispatch(setErrorSnack(true))
      store.dispatch(setSubmitLoading(false))
    });
};

// addCustomerGroupApiCall

export const addCustomerGroupApiCall = (
  body,
  setState,
  state,
  setisModify,
  isModify,
  clearInput,
  setLoadingForm
) => {
  setLoadingForm !== undefined && setLoadingForm(true);
  axios
    .post("customer/addCustomerGroup", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm !== undefined && setLoadingForm(false);

        setState({
          ...state,
          message: "Create Customer Group Successfully !!",
          success: true,
        });
        setisModify(!isModify);
        clearInput();
        ViewCustomerGroupListApiCall();
      }
    })
    .catch((err) => {
      setLoadingForm !== undefined && setLoadingForm(false);
      setState({ ...state, message: err?.response?.data, error: true });
    });
};

//viewPaymentTermsApiCall

export const ViewPaymentTermsListApiCall = () => {
  axios
    .get("customer/viewPaymentTerms", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(getPaymentTerms_List({ allPaymentList: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(getPaymentTerms_List({ allPaymentList: undefined }));
    });
};

//ViewCustomerGroupListApiCall

export const ViewCustomerGroupListApiCall = () => {
  axios
    .get("customer/viewCustomerGroup", HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(getCustomerGroup_List({ allCustomerList: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(getCustomerGroup_List({ allCustomerList: undefined }));
    });
};

//delete payment terms
export const deletePaymentTermApiCall = async (id, setSnackBar) => {
  try {
    const response = await axios.delete("/customer/deletePaymentTerms", {
      data: { _id: id },
      headers: HEADERS,
    });
    if (response.status === 200) {
      ViewPaymentTermsListApiCall();
    }
  } catch (e) {
    console.log(e.message);
  }
};
//update payment terms
export const updatePaymentTermsApicall = async (
  body,clearInput
) => {
  store.dispatch(setSubmitLoading(true))

  try {
    const response = await axios.put(
      "/customer/updatePaymentTerms",
      body,
      HEADERS
    );
    if (response.status === 200) {
      clearInput()
      store.dispatch(setSnackMsg("Payment terms edited"))
      store.dispatch(setSuccessSnack(true))
      store.dispatch(setSubmitLoading(false))
      ViewPaymentTermsListApiCall();
    }
  } catch (e) {
    console.log(e)
    // store.dispatch(setSnackMsg(err.response.status === 500 ? "Internal server error" : err.response.data))
    store.dispatch(setErrorSnack(true))
    store.dispatch(setSubmitLoading(false))
  }
};
export const getVendorListSingleViewAPIcall=async(body)=>{
  await axios
  .post("customer/getSingleVendorDetails",body,HEADERS)
  .then((res)=>{
      if(res.status===200){
          store.dispatch(get_vendor_list_row_data_list(res.data))
      }
  })
  .catch((err)=>{
      store.dispatch(get_vendor_list_row_data_list(undefined))
      console.error(err.response);
  })
}