import React from "react";

const UploadImageSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 36.021 30.002"
    >
      <g
        id="Icon_feather-upload-cloud"
        data-name="Icon feather-upload-cloud"
        transform="translate(0.014 -2.998)"
      >
        <path
          id="Path_1521"
          data-name="Path 1521"
          d="M24,24l-6-6-6,6"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          id="Path_1522"
          data-name="Path 1522"
          d="M18,18V31.5"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          id="Path_1523"
          data-name="Path 1523"
          d="M30.585,27.585A7.5,7.5,0,0,0,27,13.5H25.11A12,12,0,1,0,4.5,24.45"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          id="Path_1524"
          data-name="Path 1524"
          d="M24,24l-6-6-6,6"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
      </g>
    </svg>
  );
};

export default UploadImageSvg;
