import React, { useEffect, useState } from 'react'
import "./posShift.css"
import ReactToPrint from 'react-to-print'
import { IconButton, Tooltip } from '@mui/material'
import { useSelector } from 'react-redux';
import CategorySearchAndFilter from "../../../../Single Components/CategorySearchandFilter"
import CustomPagination from '../../../../Single Components/CustomPagination';
import { useNavigate } from 'react-router-dom';
import { ListPosShiftAPI } from '../GeneralAPI';
import { getCurrentTime } from '../../../../../Js/Date';
import ExcelJS from "exceljs"
import TableListActionButtons from '../../../../Single Components/ActionButtons/TableListActionButtons';

function PosShift() {
  
  const navigate=useNavigate()
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [filteredData, setFilteredData] = useState({});
  const userRole = useSelector((state) => state.userRoleSlice.value);

    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
    };
    const [currentPage, setCurrentPage] = useState(1);
 
    const handlePageChange = (page) => {
    
      setCurrentPage(page);
    };
    const handleFilterChange = (updatedItems) => {
      setFilteredData(updatedItems);
    };

const shiftList=useSelector((state)=>state.generalSlice.shiftList)
const totalPages = shiftList?.pages; 

const formatFilterInfo = (filters) => {
  const parts = [];

  if (filters?.toDate&&filters.fromDate) {
    parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
  }
  if (filters?.branchList) {
    parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
  }
  if (filters?.status) {
    parts.push(`status: ${filters?.status}`);
  }
  if (filters?.Ordertype) {
    parts.push(`Order type: ${filters?.Ordertype?.label}`);
  }
 
  
  return parts; // Return the array directly
};

const downloadList = () => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("shift ");
  sheet.properties.defaultRowHeight = 20;

  // Add header section
  const titleRow = sheet.addRow(["shift"]);
  titleRow.font = { size: 15, bold: true };
  sheet.mergeCells('A1:F1');
  titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };

  // Add additional header information
  const filterParts = formatFilterInfo(filteredData) || [];

  // Ensure filterParts is an array and iterate over it
  if (Array.isArray(filterParts)) {
    filterParts.forEach((part, index) => {
      const row = sheet.addRow([part]);
      row.font = { size: 11, bold: true };
      sheet.mergeCells(`A${index + 2}:F${index + 2}`);
      row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    });
  }

  // Add an empty row between header and table
  sheet.addRow([]);

  // Header row styling
  const headerRow = sheet.addRow(["Shift No", "Branch", "Employee", "Start date","End Date","Status"]);
  const headerFill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
  };
  const headerFont = {
    size: 12,
    bold: true,
    color: { argb: "FF000000" }, // White font color
  };

  headerRow.eachCell((cell, colNumber) => {
    cell.fill = headerFill;
    cell.font = headerFont;
    cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
  })

  sheet.columns = [
    { header: "Shift No", key: "shiftNo", width: 18 },
    { header: "Branch", key: "branchName", width: 25 },
    { header: "Employee", key: "employee", width: 25 },
    { header: "Start date", key: "startDate", width: 15 },
    { header: "End Date", key: "endDate", width: 15 },
    { header: "Shift", key: "status", width: 15 },

  ];

  shiftList?.list?.forEach((item) => {
    const row = sheet.addRow({
      shiftNo: item.shiftNo,
      branchName: item.branchName,
      employee: item?.employee,
      startDate: item?.startDate,
      endDate: item?.endDate,
      status: item?.status,
    
    });

    const rowFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
    };
    
    row.eachCell((cell, colNumber) => {
      cell.fill = rowFill;
      cell.border = {
      //   top: { style: "thin", color: { argb: "FF000000" } },
      //   left: { style: "thin", color: { argb: "FF000000" } },
        bottom: { style: "thin", color: { argb: "FFedf2f9" } },
      //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber >= 1) { // Right align all columns except the first
          cell.alignment = { horizontal: 'center' };
        }
       
    });
  });

  // Add row for totals
  // const totalRow = sheet.addRow([
  //   "Total",
  //   "",
  //   `${symbol} ${SingleListData?.sumOfCurrent.toFixed(2)}`,
  //   `${symbol} ${SingleListData?.sumOf30?.toFixed(2)}`,
  //   `${symbol} ${SingleListData?.sumOf60?.toFixed(2)}`,
  //   `${symbol} ${SingleListData?.sumOf90?.toFixed(2)}`,
  //   `${symbol} ${SingleListData?.sumOf120?.toFixed(2)}`,
  //   `${symbol} ${SingleListData?.sumOfAbove120?.toFixed(2)}`,
  //   `${symbol} ${SingleListData?.sumOfTotal?.toFixed(2)}`,
  // ]);

  // totalRow.eachCell((cell,colNumber) => {
  //   cell.font = { bold: true, color: { argb: "FF000000" } };
  //   cell.fill = headerFill;
  //   cell.border = {
  //     top: { style: "thin", color: { argb: "FFFFFFFF" } },
  //     left: { style: "thin", color: { argb: "FFFFFFFF" } },
  //     bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
  //     right: { style: "thin", color: { argb: "FFFFFFFF" } },
  //   };
  //   if (colNumber > 1) { // Right align all columns except the first
  //     cell.alignment = { horizontal: 'right' };
  //   }
  // });

  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "pos shift.xlsx";
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};
    const singleRowClick=(row)=>{
      localStorage.setItem("singleViewId",row._id)
      navigate("/userdashboard/pointOfSales/general/shiftSingleView")
    }
    useEffect(()=>{
      if (FormDataInfo.length !== 0) {
      ListPosShiftAPI({
        fromDate: FormDataInfo.fromDate === '' ? undefined : `${FormDataInfo.fromDate} 00:00:00}`,
        endDate: FormDataInfo.toDate === '' ? undefined : `${FormDataInfo.toDate} 23:59:59}`,
        branchId: FormDataInfo?.branchList?.length === 0 ? undefined:FormDataInfo?.branchList,
        status: FormDataInfo?.status?.length === 0 ? undefined : FormDataInfo?.status[0],
        search: FormDataInfo?.search===''? undefined : FormDataInfo?.search,
        orderType: FormDataInfo?.Ordertype ==='' ? undefined : FormDataInfo?.Ordertype,
        index:currentPage-1})}
    },[FormDataInfo,currentPage])

    return (
    <div className='global-page-parent-container updated-global-page-parent-container'>
        <div className="justify-space-between list-create-head-bar">
            <h3>Shift</h3>
            <div className="create-head-buttons-container">
            <TableListActionButtons downloadClick={downloadList}/>
            <CategorySearchAndFilter
                statusOptions={['Active', 'Ended']}
                onData={handleDataFromChild}
                isStatus={true}
                isOrderType={true}
                isBranch={userRole==="admin"}
                onFilterChange={handleFilterChange}
                onBranchSelect={()=>{}}
            />
          </div>  
        </div>
        <div className="justify-space-between pagination-container">
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
        </div>

        <div className="new-global-table-container updated-table-container">
        <table>
        <thead>
          <tr>
            
            <th>Shift No</th>
            <th>Branch</th>
            <th>Employee</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
            {(shiftList?.list!==undefined && shiftList?.list.length!==0) ?
                shiftList?.list?.map((r,i)=>(
                    <tr key={i} onClick={()=>singleRowClick(r)}>
                        
                        <td>{r.shiftNo}</td>
                        <td>{r.branchName}</td>
                        <td>{r.employee}</td>
                        <td>{r.startDate}</td>
                        <td>{r.endDate}</td>
                        <td className="table-data-box">
                            {r?.status === "DRAFT" ? (
                                <span className="draft"> DRAFT</span>
                            ) :r?.status === "Active" ? (
                                <span className="post"> Active</span>
                            ) : r?.status === "Ended" ? (
                                <span className="end">ENDED</span>
                            ) : (
                                <span>{r?.status}</span>
                            )}
                        </td>
                    </tr>
                )):
                (<tr>
                  <td colSpan={6}>NO DATA</td>
                  </tr>
                  )
            }
        </tbody>
        </table>
        </div>
    </div>
  )
}

export default PosShift