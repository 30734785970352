import React from "react";
import "./InvoiceHeader.css";
import { useSelector } from "react-redux";

const PrintHeader = ({ header,subHead }) => {
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  
  return (
    <div className="new-hdr-invoice-header">
      <div className="new-hdr-left-section">
        <div className="new-hdr-company-name">
          <img
            src={companyProfileData?.logo ||"https://via.placeholder.com/50"}
            alt="Company Logo"
            className="new-hdr-company-logo"

          />
        </div>
        <div className="new-hdr-company-name">{companyProfileData?.companyName}</div>
      </div>
      <div className="new-hdr-right-section">
        <div className="new-hdr-invoice-title"style={{fontSize:subHead&&"13px", textTransform:"uppercase"}} >{header ||"INVOICE"} <div>
         <p style={{fontSize:"8px",margin:0,textTransform:"uppercase"}}>{subHead||""}</p> 
          </div></div>
        <div className="new-hdr-company-details">
          <p>{companyProfileData?.companyAddress}</p>
          <p>{companyProfileData?.companyEmail}</p>
          <p>{companyProfileData?.contactNumber}</p>
        </div>
      </div>
    </div>
  );
};

export default PrintHeader;
