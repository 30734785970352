import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbarNotification } from "../BankReconciliationSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarNotification = () => {
  const dispatch = useDispatch();
  const { showAlert } = useSelector((state) => state.bankReconciliation);

  const handleClose = () => {
    dispatch(
      setSnackbarNotification((prev)=>({...prev, message: "", open: false, severity: null }))
    );
  };

  return (
    <div>
      <Snackbar
        open={showAlert.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={showAlert.severity}
          sx={{ width: "100%" }}
        >
          {showAlert.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackbarNotification;
