import React from "react";
import "../Configuration/configuration.css";
import {
  Autocomplete,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { chartOfAccountListApiCall } from "../../../../API/AccountUpdated/chartOfAccountAPI";
import { journalListApiCall } from "../../../../API/AccountUpdated/journalAPI";
import { useEffect } from "react";
import { useState } from "react";
import { findObjFromList } from "../../../../Js/generalFunctions";
import { setGlConfigAPICall, viewGlConfigAPICall } from "./glConfigAPI";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { LoadingForm } from "../../../Single Components/LoadingForm";
import store from "../../../../Redux/store";
import { setErrorSnack, setSnackMsg } from "../../Sales/Billing/Slice/billingScreenSlice";
export const Configuration = () => {
  const decimalPointArray = [
    { number: 2 },
    { number: 3 },
    { number: 4 },
    { number: 5 },
  ];
  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );
  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );
  const journalList = useSelector((state) => state.journalSlice.value);
  // filtered accnt listes
  const journalPurchaseList = journalList?.filter(
    (item) => item.type === "purchase"
  );
  const journalSalesList = journalList?.filter(
    (item) => item.type === "sales"
  );
  const stockValuationAccList = chartOfAccountList?.filter(
    (item) => item.type === "Current asset"
  );
  const controlAndExpenseAccList = chartOfAccountList?.filter(
    (item) => item.type === "Cost of revenue" /*|| item.type === "Expenses"*/
  );
  const incomeAccList = chartOfAccountList?.filter(
    (item) => item.type === "Income"
  );
  const receivableList = chartOfAccountList?.filter(
    (item) => item.type === "Receivables"
  );
  const payableList = chartOfAccountList?.filter(
    (item) => item.type === "Payable"
  );
  const expenseAccList = chartOfAccountList?.filter(
    (item) => item.type === "Expenses"
  );

  const dispatch=useDispatch()
  const [validationAlert, setValidationAlert] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
  });
  const [formValues, setFormValues] = useState({
    stockJournal: null,
    stockValuationAcc: null,
    stockAdjustmentAcc: null,
    goodsInternalTransfer: null,
    purchaseJournal: null,
    purchaseControlAccount: null,
    salesJournal: null,
    salesExpenseAcc: null,
    incomeAcc: null,
    defaultReceivable: null,
    defaultPayable: null,
    decimalPoints: null,
    PurchaseDiscount: null,
    salesDiscount: null,
    financialYearEndDate: null,
    financialYearStartDate: "",
    accountsLockDate: null,
    stockDecimalPoint: null,
    rounding:false,
    roundingLimit:0,
    roundingAccount:null
  });

  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      message: "",
    });
  };
  const getFormInfo = (key) => (e, newValue) => {
    setValidationAlert({});
    setFormValues({ ...formValues, [key]: newValue });
  };

  const payload = {
    inventory: {
      stockJournal: formValues.stockJournal?._id,
      stockValuationAccount: formValues.stockValuationAcc?._id,
      stockAdjustmentAccount: formValues?.stockAdjustmentAcc?._id,
      goodsInternalTransfer: formValues.goodsInternalTransfer?._id,
    },
    purchase: {
      purchaseJournal: formValues.purchaseJournal?._id,
      purchaseControlAccount: formValues.purchaseControlAccount?._id,
      purchaseDiscount: formValues.PurchaseDiscount?._id,
    },
    sales: {
      salesJournal: formValues.salesJournal?._id,
      salesExpenseAccount: formValues.salesExpenseAcc?._id,
      incomeAccount: formValues.incomeAcc?._id,
      salesDiscount: formValues.salesDiscount?._id,
    },
    accounts: {
      defaultPayable: formValues.defaultPayable?._id,
      defaultRecievable: formValues.defaultReceivable?._id,
      yearEndDate: formValues?.financialYearEndDate===''?'':`${formValues?.financialYearEndDate}  23:59:59`,
      accountsLockDate: formValues?.accountsLockDate===''?'':`${formValues?.accountsLockDate}  23:59:59`,
      decimalPoint: formValues?.decimalPoints?.number,
      stockDecimalPoint: formValues?.stockDecimalPoint?.number,
      yearStartDate:formValues?.financialYearStartDate===''?'':`${formValues?.financialYearStartDate}  00:00:00`,
    },
    isRoundingEnabled: formValues?.rounding,
    roundingLimit: Number(formValues?.roundingLimit),
    roundingAccount: formValues?.roundingAccount
  };

  const saveBtnClick = () => {
    const validations = {
      stockJournal: "Select this field",
      stockValuationAcc: "Select this field",
      stockAdjustmentAcc: "Select this field",
      goodsInternalTransfer: "Select this field",
      purchaseJournal: "Select this field",
      purchaseControlAccount: "Select this field",
      PurchaseDiscount: "Select this field",
      salesJournal: "Select this field",
      salesExpenseAcc: "Select this field",
      incomeAcc: "Select this field",
      salesDiscount: "Select this field",
      defaultReceivable: "Select this field",
      defaultPayable: "Select this field",
      decimalPoints: "Select this field",
      financialYearStartDate: "Select this field",
      financialYearEndDate: "Select this field",
      accountsLockDate: "Select this field",
      roundingLimit: "Select this field",
      roundingAccount: "Select this field"
    };
  
    const newValidationAlert = {};
  
    for (const key in validations) {
      if (formValues[key] === null || (formValues[key] === "" && key !== 'financialYearStartDate' && key !== 'financialYearEndDate' && key !== 'accountsLockDate' )) {
        newValidationAlert[key] = validations[key];
        dispatch(setSnackMsg("some fields are required !!"))
        dispatch(setErrorSnack(true))
      }
    }
  
    setValidationAlert({ ...validationAlert, ...newValidationAlert });
  
    if (Object.keys(newValidationAlert).length === 0) {
      setGlConfigAPICall(payload, setSnackBarStates, snackBarStates, setLoadingForm);
    }
  };

  useEffect(() => {
    if (configurationList !== undefined) {
      
      setFormValues({
        ...formValues,
        stockJournal: findObjFromList(
          "_id",
          journalList,
          "stockJournal",
          configurationList?.inventory
        ),
        stockValuationAcc: findObjFromList(
          "_id",
          stockValuationAccList,
          "stockValuationAccount",
          configurationList?.inventory
        ),
        stockAdjustmentAcc: findObjFromList(
          "_id",
          stockValuationAccList,
          "stockAdjustmentAccount",
          configurationList?.inventory
        ),
        goodsInternalTransfer: findObjFromList(
          "_id",
          stockValuationAccList,
          "goodsInternalTransfer",
          configurationList?.inventory
        ),
        purchaseJournal: findObjFromList(
          "_id",
          journalList,
          "purchaseJournal",
          configurationList?.purchase
        ),
        purchaseControlAccount: findObjFromList(
          "_id",
          controlAndExpenseAccList,
          "purchaseControlAccount",
          configurationList?.purchase
        ),
        salesJournal: findObjFromList(
          "_id",
          journalList,
          "salesJournal",
          configurationList?.sales
        ),
        salesExpenseAcc: findObjFromList(
          "_id",
          controlAndExpenseAccList,
          "salesExpenseAccount",
          configurationList?.sales
        ),
        incomeAcc: findObjFromList(
          "_id",
          incomeAccList,
          "incomeAccount",
          configurationList?.sales
        ),
        defaultReceivable: findObjFromList(
          "_id",
          chartOfAccountList,
          "defaultRecievable",
          configurationList?.accounts
        ),
        defaultPayable: findObjFromList(
          "_id",
          chartOfAccountList,
          "defaultPayable",
          configurationList?.accounts
        ),
        PurchaseDiscount: findObjFromList(
          "_id",
          incomeAccList,
          "purchaseDiscount",
          configurationList?.purchase
        ),
        salesDiscount: findObjFromList(
          "_id",
          controlAndExpenseAccList,
          "salesDiscount",
          configurationList?.sales
        ),
        decimalPoints: findObjFromList(
          "number",
          decimalPointArray,
          "decimalPoint",
          configurationList?.accounts
        ),
        stockDecimalPoint: findObjFromList(
          "number",
          decimalPointArray,
          "stockDecimalPoint",
          configurationList?.accounts
        ),
        financialYearEndDate: configurationList?.yearEndDate,
        financialYearStartDate: configurationList?.yearStartDate,
        accountsLockDate: configurationList?.accountsLockDate,
        rounding: configurationList?.isRoundingEnabled,
        roundingLimit: configurationList?.roundingLimit || 0,
        roundingAccount: findObjFromList(
          "_id",
          chartOfAccountList,
          "roundingAccount",
          configurationList
        )

      });
      localStorage.setItem(
        "decimalPosition",
        configurationList?.accounts?.decimalPoint || 0
      );
    }
  }, [configurationList, journalList, chartOfAccountList]);



  useEffect(() => {
    chartOfAccountListApiCall();
    journalListApiCall();
    viewGlConfigAPICall(setLoadingForm);
  }, []);

  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">
        Accounts &gt; Configuration &gt; GL Configurations
      </p>

      <div className="global-white-bg-container">
        <>
          <Tooltip title="Save">
            <IconButton onClick={saveBtnClick}>
              <i class="bi bi-floppy"></i>
            </IconButton>
          </Tooltip>
        </>
      </div>

      <div
        className="global-white-bg-container"
        style={{ paddingLeft: "2%", paddingBottom: "3%" }}
      >
        <p className="primary-text">GL Configurations</p>
        <div style={{ width: "15%" }}>
          <hr
            style={{ border: "none", borderBottom: "2px solid rgb(10 51 254)" }}
          />
        </div>

        <div className="accounts-configuration-ctn">
          <p className="head">Inventory</p>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Stock Journal</p>
            
              <Autocomplete
                options={journalList || []}
                getOptionLabel={(option) => option?.journalName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select a journal" />
                )}
                value={formValues.stockJournal}
                onChange={getFormInfo("stockJournal")}
              />
          

            <p className="doc-validation-alert">
              {validationAlert?.stockJournal}
            </p>
          </div>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Stock Valuation Account</p>
           
              <Autocomplete
                options={stockValuationAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.stockValuationAcc}
                onChange={getFormInfo("stockValuationAcc")}
              />
          
            <p className="doc-validation-alert">
              {validationAlert?.stockValuationAcc}
            </p>
          </div>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Stock Adjustment Account</p>
            
              <Autocomplete
                options={stockValuationAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.stockAdjustmentAcc}
                onChange={getFormInfo("stockAdjustmentAcc")}
              />
          
            <p className="doc-validation-alert">
              {validationAlert?.stockAdjustmentAcc}
            </p>
          </div>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Goods Internal Transfer</p>
           
              <Autocomplete
                options={stockValuationAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.goodsInternalTransfer}
                onChange={getFormInfo("goodsInternalTransfer")}
              />
            
            <p className="doc-validation-alert">
              {validationAlert?.goodsInternalTransfer}
            </p>
          </div>
        </div>
        <div className="accounts-configuration-ctn">
          <p className="head">Purchase</p>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Purchase Journal</p>
          
              <Autocomplete
                options={journalPurchaseList || []}
                getOptionLabel={(option) => option?.journalName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select a journal" />
                )}
                value={formValues.purchaseJournal}
                onChange={getFormInfo("purchaseJournal")}
              />
          
            <p className="doc-validation-alert">
              {validationAlert?.purchaseJournal}
            </p>
          </div>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Purchase Control Acount</p>
           
              <Autocomplete
                options={controlAndExpenseAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.purchaseControlAccount}
                onChange={getFormInfo("purchaseControlAccount")}
              />
          
            <p className="doc-validation-alert">
              {validationAlert?.purchaseControlAccount}
            </p>
          </div>

          <div className="configuration-single-input global-single-input auto-complete">
            <p>Purchase Discount</p>
          
              <Autocomplete
                options={incomeAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.PurchaseDiscount}
                onChange={getFormInfo("PurchaseDiscount")}
              />
          
            <p className="doc-validation-alert">
              {validationAlert?.PurchaseDiscount}
            </p>
          </div>
        </div>

        <div className="accounts-configuration-ctn">
          <p className="head">Sales</p>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Sales Journal</p>
           
              <Autocomplete
                options={journalSalesList || []}
                getOptionLabel={(option) => option?.journalName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select a journal" />
                )}
                value={formValues.salesJournal}
                onChange={getFormInfo("salesJournal")}
              />
           
            <p className="doc-validation-alert">
              {validationAlert?.salesJournal}
            </p>
          </div>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Sales Expenses Acount</p>
           
              <Autocomplete
                options={controlAndExpenseAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.salesExpenseAcc}
                onChange={getFormInfo("salesExpenseAcc")}
              />
          
            <p className="doc-validation-alert">
              {validationAlert?.salesExpenseAcc}
            </p>
          </div>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Income Account</p>
           
              <Autocomplete
                options={incomeAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.incomeAcc}
                onChange={getFormInfo("incomeAcc")}
              />
           
            <p className="doc-validation-alert">{validationAlert?.incomeAcc}</p>
          </div>

          <div className="configuration-single-input global-single-input auto-complete">
            <p>Sales Discount</p>
           
              <Autocomplete
                options={controlAndExpenseAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues?.salesDiscount}
                onChange={getFormInfo("salesDiscount")}
              />
           
            <p className="doc-validation-alert">
              {validationAlert?.salesDiscount}
            </p>
          </div>
        </div>

        <div className="accounts-configuration-ctn">
          <p className="head">Accounts</p>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Default Payable</p>
           
              <Autocomplete
                options={payableList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.defaultPayable}
                onChange={getFormInfo("defaultPayable")}
              />
           
            <p className="doc-validation-alert">
              {validationAlert?.defaultPayable}
            </p>
          </div>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Default Receivable</p>
          
              <Autocomplete
                options={receivableList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.defaultReceivable}
                onChange={getFormInfo("defaultReceivable")}
              />
           
            <p className="doc-validation-alert">
              {validationAlert?.defaultReceivable}
            </p>
          </div>
          <div
            className="configuration-single-input global-single-input auto-complete"
            style={{ width: "40%" }}
          >
            <p>Currency Decimal Points</p>
            <Autocomplete
              style={{ width: "40%" }}
              options={decimalPointArray || []}
              getOptionLabel={(option) => option?.number}
              renderInput={(params) => (
                <TextField {...params} placeHolder="select" />
              )}
              value={formValues.decimalPoints}
              onChange={getFormInfo("decimalPoints")}
            />
          </div>
          <div
            className="configuration-single-input global-single-input auto-complete"
            style={{ width: "40%" }}
          >
            <p>Stock Decimal Points</p>
            <Autocomplete
              style={{ width: "40%" }}
              options={decimalPointArray || []}
              getOptionLabel={(option) => option?.number}
              renderInput={(params) => (
                <TextField {...params} placeHolder="select" />
              )}
              value={formValues.stockDecimalPoint}
              onChange={getFormInfo("stockDecimalPoint")}
            />
          </div>
          <div
            className="configuration-single-input global-single-input auto-complete"
            style={{ width: "40%" }}
          >
            <p style={{ width: "50%" }}>Financial Year Start Date</p>
            <input
              type="date"
              style={{ width: "40%" }}
              value={formValues?.financialYearStartDate}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  financialYearStartDate: e.target.value,
                })
              }
            />
            <p className="doc-validation-alert">{validationAlert.financialYearStartDate}</p>
          </div>
          <div
            className="configuration-single-input global-single-input auto-complete"
            style={{ width: "40%" }}
          >
            <p style={{ width: "50%" }}>Financial Year End Date</p>
            <input
              type="date"
              style={{ width: "40%" }}
              value={formValues?.financialYearEndDate}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  financialYearEndDate: e.target.value,
                })
              }
            />

            <p className="doc-validation-alert">{validationAlert.financialYearEndDate}</p>
          </div>
          
          <div
            className="configuration-single-input global-single-input auto-complete"
            style={{ width: "40%" }}
          >
            <p style={{ width: "50%" }}>Accounts Lock Date</p>
            <input
              type="date"
              style={{ width: "40%" }}
              value={formValues?.accountsLockDate}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  accountsLockDate: e.target.value,
                })
              }
            />
          </div>
          <div
            className="configuration-single-input global-single-input auto-complete"
            style={{ width: "40%",justifyContent:"start",gap:"10%" }}
          >
            <p style={{ width: "50%" }}>Rounding </p>
            <input
              type="checkbox"
              className="toggle-checkbox"
              checked={formValues?.rounding}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  rounding: e.target.checked,
                })
              }
            />
          </div>
          <div
            className="configuration-single-input global-single-input auto-complete"
            style={{ width: "40%" }}
          >
            <p style={{ width: "50%" }}>Rounding Limit</p>
            <input
              type="text"
              style={{ width: "40%" }}
              value={formValues?.roundingLimit}
              onFocus={(e)=>e.target.select()}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  roundingLimit: e.target.value,
                })
              }
            />
            <p className="doc-validation-alert">{validationAlert.roundingLimit}</p>
          </div>
          <div className="configuration-single-input global-single-input auto-complete">
            <p>Rounding Account</p>
           
              <Autocomplete
                options={expenseAccList || []}
                getOptionLabel={(option) => option?.accountName}
                renderInput={(params) => (
                  <TextField {...params} placeHolder="select an account" />
                )}
                value={formValues.roundingAccount}
                onChange={getFormInfo("roundingAccount")}
              />
           
            <p className="doc-validation-alert">
              {validationAlert?.roundingAccount}
            </p>
          </div>
        </div>
      </div>

      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <LoadingForm loading={loadingForm}/>
    </div>
  );
};
