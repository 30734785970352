import { IconButton, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import ReactToPrint from 'react-to-print'
import ActiveRibbon from "../../../../../Assets/statusRibbon/post.png"
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { useNavigate } from 'react-router-dom';
import { SingleViewPosShiftAPI } from '../GeneralAPI';
import { useSelector } from 'react-redux';
function PosShiftSingleView() {
    const navigate=useNavigate()
    const handleback=()=>{
        navigate("/userdashboard/pointOfSales/general/shift")
    }

    const singleView=useSelector((state)=>state.generalSlice.shiftListSingleView)
console.log(singleView);
    const singleId=localStorage.getItem("singleViewId")

    useEffect(()=>{
        singleId!==null && SingleViewPosShiftAPI({_id:singleId})
        },[])
    return (
        <div className='global-page-parent-container'>
            <div
                className="justify-space-between global-white-bg-container">
                     <Tooltip title="Back">
              <IconButton  onClick={handleback}>
                <i className="bi bi-chevron-left" style={{ fontSize: '30px', color: 'black', cursor: 'pointer' }}></i>
              </IconButton>
            </Tooltip>
                {/* <h5 style={{ margin: "0" ,cursor:"pointer"}}onClick={handleback}>SHIFT</h5> */}

                <div className="justify-center" style={{ gap: "10px", width: "70%", justifyContent: "flex-end" }}>
                    <ReactToPrint
                        trigger={() => (
                            <IconButton className="printer-div" style={{ color: "#fff", backgroundColor: "#002995", borderRadius: "0" }}>
                                <i class="bi bi-printer"></i>
                            </IconButton>
                        )}
                    //   content={() => paymentsPrint}
                    />
                    <Tooltip title="Download">
                        <IconButton style={{ backgroundColor: "#ffd2d2", color: "#002995", borderRadius: "0" }}>
                            <i class="bi bi-arrow-down-circle"></i>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            <div className="global-white-bg-container">
                <div className="ribbon-ctn">
                    <img src={ActiveRibbon} alt="" />
                </div>
                <div className="justify-space-between single-viewinfo-parent-ctn">
                    <div className="single-view-left-ctn">
                        <h3 style={{ margin: "0",cursor:"pointer",fontSize:"larger"  }}>{singleView?.shiftId}</h3>
                
                        <div className="justify-space-between">
                            <div className="single-view-info-ctn-left flex-column">
                                <p>Branch</p>
                                <p>Employee</p>
                                <p>Opening Date</p>
                                <p>Closing Date</p>

                            </div>
                            <div className="single-view-info-ctn-right flex-column">
                                <p>{singleView?.branchName}</p>
                                <p>{singleView?.employee}</p>
                                <p>{singleView?.openingTime}</p>
                                <p>{singleView?.closingTime}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='shift-bottom-container'>
                <div className="shift-info-card justify-space-between">
                    <div className="justify-space-between">
                        <ShoppingCartOutlinedIcon />
                        <p>Orders</p>
                    </div>
                    <p>{singleView?.ordCount}</p>
                </div>
                <div className="shift-info-card justify-space-between">
                    <div className="justify-space-between">
                        <LocalShippingOutlinedIcon />
                        <p>Pickings</p>
                    </div>
                    <p>{singleView?.kotCount}</p>
                </div>
                <div className="shift-info-card justify-space-between">
                    <div className="justify-space-between">
                        <PaymentOutlinedIcon />
                        <p>Payments</p>
                    </div>
                    <p>{singleView?.paymentCount}</p>
                </div>
                <div className="shift-info-card justify-space-between">
                    <div className="justify-space-between">
                        <AccountBalanceWalletOutlinedIcon />
                        <p>Wallet</p>
                    </div>
                    <p>{singleView?.walletCount}</p>
                </div>
            </div>
            <div style={{marginTop:"8px",backgroundColor:"white"}}>
               <h1 style={{margin:"0",padding:"19px",fontSize:"medium"}}>Payment Summery</h1> 
               <div className="new-global-table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Payment Methord</th>
                            <th>IN</th>
                            <th>OUT</th>
                            <th>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {singleView?.paymentSummary?.list!==undefined ? singleView?.paymentSummary?.list?.map((r,i)=>(
                        <tr key={i} style={{backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7"}}>
                            <td style={{border:"none",height:"29px"}}>{r.type}</td>
                            <td style={{border:"none",height:"29px"}}>{r.in}</td>
                            <td style={{border:"none",height:"29px"}}>{r.out}</td>
                            <td style={{border:"none",height:"29px"}}>{r.balance}</td>
                        </tr>
                        )):(
                            <tr>
                                <td colSpan={4}>NO DATA</td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={2} style={{textAlignLast:"end",paddingRight:"51px"}}>{singleView?.paymentSummary?.inTot}</td>
                            <td colSpan={1} style={{textAlignLast:"center"}}>{singleView?.paymentSummary?.outTot}</td>
                            <td colSpan={1} style={{textAlignLast:"center"}}>{singleView?.paymentSummary?.balTot}</td>
                        </tr>
                    </tfoot>
                </table>
               </div>
            </div>
        </div>
    )
}

export default PosShiftSingleView