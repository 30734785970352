import { Autocomplete, Dialog, IconButton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import listIcon from "../../../Assets/Images/Icon feather-list.png"
import { addCashInOrOutAPICall } from '../../../API/Dashboards/mainDashboardAPI';
import { getCurrentTime, today } from '../../../Js/Date';
import { setCashInOrOutDialog } from '../../../Redux/Dashboard/cashInOrOutSlice';
const CashInOrOut = () => {

    const dispatch=useDispatch()
    
    const userShiftStatus = localStorage.getItem("shiftStatus");
    const userLoginMeta = localStorage.getItem("login_meta");

    const userShiftId = JSON.parse(userShiftStatus)?.shiftId;
    const userShiftpk = JSON.parse(userShiftStatus)?._id;
    const userBranchpk = JSON.parse(userLoginMeta)?.branchPk;

    const cashInOrOutDialog=useSelector((state) => state.cashInOrOutSlice.cashInOrOutDialog);
    const cashInOrOutData = useSelector((state) => state.cashInOrOutSlice.value);
    const allActiveEmpList = useSelector((state) => state.allActiveEmployeeSlice.value);

    const [isDisplayList,setIsDisplayList] = useState(false)
    const [cashInOrOutState, setCashInOrOutState] = useState("cashIn");

    const cashInOrOutFormInitialState={
        refernce: "",
        amount: 0,
        employee: null,
      }
    const [cashInOrOutForm, setCashInOrOutForm] = useState(cashInOrOutFormInitialState);

    const [cashListForBackEnd, setCashListForBackEnd] = useState([]);

    const cashListInitialState=[
        { coin: 1000, count: 0, amount: 0 },
        { coin: 500, count: 0, amount: 0 },
        { coin: 200, count: 0, amount: 0 },
        { coin: 100, count: 0, amount: 0 },
        { coin: 50, count: 0, amount: 0 },
        { coin: 20, count: 0, amount: 0 },
        { coin: 10, count: 0, amount: 0 },
        { coin: 5, count: 0, amount: 0 },
        { coin: 2, count: 0, amount: 0 },
        { coin: 1, count: 0, amount: 0 },
        { coin: 0.5, count: 0, amount: 0 },
        { coin: 0.25, count: 0, amount: 0 },
    ]
    const [cashList, setCashList] = useState(cashListInitialState);
    const [total, setTotal] = useState(0);

    const displayListFn = () => {
        setIsDisplayList(!isDisplayList);
      };

    const handlePrintRow = (rowData) => {
    const printContent = `
        <html>
        <head>
            <title>Print</title>
            <style>
            body {
                font-family: Arial, sans-serif;
                margin: 20px;
            }

            h1 {
                color: #333;
            }

            p {
                margin-bottom: 10px;
            }
            table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 15px;
            }

            th, td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: left;
            }
            .grandTotal-container{
                display:flex;
                border: 1px solid #ddd;
                justify-content: flex-end;
                padding:4px 8px
            }
            .autorized-container{
                display:flex;
                justify-content:space-between;
            }
            h3{
                margin-right:12px;
                font-size:1rem
            }

            /* Add any additional custom styles here */
            </style>
        </head>
        <body>
            <div>
                <h1>logo</h1>
            </div>
            ${!rowData.inorout
        ? `
            <h4>Denomination Details:</h4>
            <table>
            <thead>
                <tr>
                <th>Coin</th>
                <th>Count</th>
                <th>Amount</th>
                </tr>
            </thead>
            <tbody>
                ${rowData.denomination
            .map(
            (d) => `
                <tr>
                    <td>${d.coin}</td>
                    <td>${d.count}</td>
                    <td>${d.amount}</td>
                </tr>
                `
            )
            .join("")}
            </tbody>
            </table>
            `
        : ""
        }
            <div class="grandTotal-container">
                <h3>Total:${rowData.total}</h3>
            
            </div>
            <div class="autorized-container">
            <h5>Autorized Person :</h5>
            <h5>Collected By :</h5>
        </div>
        </body>
        </html>
    `;

    // Create a hidden iframe for printing
    const printIframe = document.createElement("iframe");
    printIframe.style.visibility = "hidden";
    document.body.appendChild(printIframe);

    // Set the content of the iframe
    printIframe.contentDocument.write(printContent);

    // Trigger the print dialog
    printIframe.contentWindow.print();

    // Remove the iframe after printing
    document.body.removeChild(printIframe);
    };

    const getCashInOrOutForm = (key) => (e, newValue) => {
        if (key === "refernce") {
          setCashInOrOutForm({ ...cashInOrOutForm, refernce: e.target.value });
        } else if (key === "amount") {
          setCashInOrOutForm({ ...cashInOrOutForm, amount: e.target.value });
        } else if (key === "employee") {
          setCashInOrOutForm({ ...cashInOrOutForm, employee: newValue });
        }
      };


      

    const editTableRow = (index, key) => (e) => {
        const { value } = e.target;
        if (key === "count") {
            cashList[index].count = Number(e.target.value);
            cashList[index].amount = cashList[index].coin * cashList[index].count;
            setCashList([...cashList]);
        }
    };

    const cancelFn=()=>{
        dispatch(setCashInOrOutDialog(false))
        setCashInOrOutForm(cashInOrOutFormInitialState)
        setCashListForBackEnd([])
        setCashList(cashListInitialState)
        setTotal(0)
        setIsDisplayList(false)
        setCashInOrOutState("cashIn")
    }
    const bodyToCashInOrOut = {
        shiftId: userShiftId,
        shiftPk: userShiftpk,
        reference: cashInOrOutForm?.refernce,
        inorout: cashInOrOutState === "cashIn" ? true : false,
        total:
          cashInOrOutState === "cashIn"
            ? Number(cashInOrOutForm?.amount)
            : Number(total),
        date: `${today} ${getCurrentTime()}`,
        empId: cashInOrOutForm?.employee?._id,
        branchId: userBranchpk,
        denomination: cashInOrOutState === "cashOut" ? cashListForBackEnd : [],
      };
    
    const cashInOrOutSubmitFn = () => {
    addCashInOrOutAPICall(
        bodyToCashInOrOut,cancelFn
    );
    };
    // CALCULATE TOTAL
    useEffect(() => {
        let total = cashList.reduce((sum, item) => sum + Number(item.amount), 0);
        setTotal(total);

        setCashListForBackEnd(
            cashList?.filter((obj) => {
              if (obj.count > 0) {
                return obj;
              }
            })
          );
    }, [cashList]);
    return (
        <Dialog open={cashInOrOutDialog} maxWidth="lg" onClose={cancelFn}>
            <div
                onClick={displayListFn}
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "2% 5%",
                    cursor: "pointer",
                }}
            >
                <img
                    src={listIcon}
                    alt=""
                    style={{ width: "20px", height: "20px" }}
                />
            </div>
            {isDisplayList ? (
                <div
                    className="global-white-bg-container"
                    style={{ width: "60vw", padding: "2% 4%" }}
                >
                    <div className="create-button-blue-container">
                        <h4 style={{ margin: 0 }}>Cash IN Cash OUT</h4>
                    </div>
                    <div className="new-global-table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Branch</th>
                                    <th>Shift</th>
                                    <th>Employee</th>
                                    <th>Reference</th>
                                    <th>Amount</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                cashInOrOutData!==undefined?
                                cashInOrOutData
                                    ?.slice(0)
                                    ?.reverse()
                                    ?.map((r) => (
                                        <tr>
                                            <td>{r?.date}</td>
                                            <td>
                                                {r?.inorout === true ? "CashIn" : "CashOut"}
                                            </td>
                                            <td>{r?.branchId}</td>
                                            <td>{r?.shiftId}</td>
                                            <td>---</td>
                                            <td>{r?.reference}</td>
                                            <td>{r?.total}</td>
                                            <td>
                                                <IconButton
                                                    className="print-icon-container"
                                                    onClick={() => handlePrintRow(r)}
                                                >
                                                    {/* <i class="bi bi-printer printer-icon"></i> */}
                                                    <i
                                                        class="bi bi-printer"
                                                        style={{ fontSize: "1rem" }}
                                                    ></i>
                                                </IconButton>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={8}>No records !!</td>
                                    </tr>
                                
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div
                    className="shift-end-button-password-container"
                    style={{ width: "40vw", padding: "2% 4%" }}
                >
                    <div style={{ display: "flex" }}>
                        <div
                            style={{
                                marginRight: "2%",
                                width: "25%",
                                accentColor: "black",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <input
                                type="radio"
                                id="cashIn"
                                name="cashInOut"
                                // defaultChecked
                                value="cashIn"
                                onChange={(e) =>
                                    setCashInOrOutState(e.target.value)
                                }
                                checked={cashInOrOutState === "cashIn"}
                            />
                            <label
                                htmlFor="cashIn"
                                style={{
                                    fontSize: "0.8rem",
                                    fontWeight: 600,
                                    margin: "5px 0 0 2px",
                                }}
                            >
                                Cash In
                            </label>
                        </div>
                        <div
                            style={{
                                marginRight: "2%",
                                accentColor: "black",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <input
                                type="radio"
                                id="cashOut"
                                name="cashInOut"
                                value="cashOut"
                                onChange={(e) =>
                                    setCashInOrOutState(e.target.value)
                                }
                                checked={cashInOrOutState === "cashOut"}
                            />

                            <label
                                htmlFor="cashOut"
                                style={{
                                    fontSize: "0.8rem",
                                    fontWeight: 600,
                                    margin: "5px 0 0 2px",
                                }}
                            >
                                Cash Out
                            </label>
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            margin: "2% 0",
                        }}
                    >
                        <div
                            className="new-global-single-input"
                            style={{ width: "45%" }}
                        >
                            <TextField
                                id="outlined-basic"
                                label="Reference"
                                variant="outlined"
                                type="text"
                                focused
                                value={cashInOrOutForm?.refernce}
                                onChange={getCashInOrOutForm("refernce")}
                            />
                        </div>
                        {cashInOrOutState === "cashIn" && (
                            <div
                                className="new-global-single-input"
                                style={{ width: "45%" }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    label="Amount*"
                                    variant="outlined"
                                    type="text"
                                    focused
                                    value={cashInOrOutForm?.amount}
                                    onChange={getCashInOrOutForm("amount")}
                                    onFocus={(e)=>e.target.select()}

                                />
                            </div>
                        )}

                        <div
                            className="new-global-single-input auto-complete-new"
                            style={{ width: "45%" }}
                        >
                            <Autocomplete
                                options={allActiveEmpList || []}
                                getOptionLabel={(option) =>
                                    `${option?.emp_id}-${option?.staff_name}`
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Employee*"
                                        focused
                                    />
                                )}
                                value={cashInOrOutForm?.employee}
                                onChange={getCashInOrOutForm("employee")}
                            />
                        </div>
                    </div>

                    {cashInOrOutState === "cashOut" && (
                        <div className="cash-in-out-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Cash/Coin</th>
                                        <th>Count</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cashList?.map((r, i) => (
                                        <tr>
                                            <td>{r?.coin}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    value={r?.count}
                                                    onChange={editTableRow(i, "count")}
                                                    onFocus={(e)=>e.target.select()}
                                                />
                                            </td>
                                            <td>{r?.amount}</td>
                                        </tr>
                                    ))}

                                </tbody>
                                <tbody>
                                    <tr style={{ backgroundColor: "#f7e4ff" }}>
                                        <td></td>
                                        <td style={{ textAlign: "right" }}>Total</td>
                                        <td>{total}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <button
                            onClick={cancelFn}
                            style={{ height: "35px", width: "25%" }}
                            className="btn btn-secondary-outlined"
                        >
                            Cancel
                        </button>
                        <button
                            style={{ height: "35px", width: "25%" }}
                            className="btn btn-primary"
                            onClick={cashInOrOutSubmitFn}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            )}
        </Dialog>
    )
}

export default CashInOrOut