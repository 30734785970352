import {
  Autocomplete,
  Dialog,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import cancelRibbon from "../../../../../Assets/statusRibbon/cancel.png";
import draftRibbon from "../../../../../Assets/statusRibbon/draft.png";
import uploadFile from "../../../../../Assets/Images/Icon feather-upload.png";
import { CloseOutlined } from "@mui/icons-material";
import logoImage from "../../../../../Assets/Images/Icon ionic-logo-buffer.png";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentTime, today } from "../../../../../Js/Date";
import { useNavigate } from "react-router-dom";
import {
  findObjFromList,
  roundNumericFields,
  taxCalculationForLines,
} from "../../../../../Js/generalFunctions";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import {
  viewUOMSubListAPICall,
} from "../../../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall";
import { getAllTaxSettingsListAPICall } from "../../../../../API/Settings/TaxSettings/taxSettingsAPI";
import {
  cancelSalesQuatationAPICall,
  confirmSalesQuatationAPICall,
  draftSalesQuatationAPICall,
  editSalesQuatationAPICall,
  salesQuotationSingleViewAPICall,
} from "./salesQuatationAPI";
import { generateSalesOrderNameAPICall, salesOrderHistoryAPICall } from "../SalesOrder/salesOrderAPI";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import PaymentTerms from "../../../components/Dialog/paymentTerms/PaymentTerms";
import { set_Back_button_action, setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import { PurchaseHistory } from "../../../Accounts/Vendor/purchaseWPO/PurchaseHistory";
import purchasehistoryicon from "../../../../../Assets/SVG/Icon material-history.svg"
import { generate_sales_order_name } from "../SalesOrder/salesOrderSlice";
import store from "../../../../../Redux/store";
import { viewAllAllowedBranchesAPICall } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { TableVirtuoso } from "react-virtuoso";
import { setErrorSnack, setSnackMsg } from "../../../Sales/Billing/Slice/billingScreenSlice";
import { customerFilterOptions, getTableHeightForVirtuso } from "../../../../Single Components/GneralUtilities";


export const CreateSalesQuotation = () => {
  const soHistoryData = useSelector((state) => state.salesOrderSlice.history)
  const singleRowId = localStorage.getItem("singleViewById");
  const paymentTermsList = useSelector(
    (state) => state.vendorsListSlice?.paymentTermsList
  );
  const dispatch = useDispatch();
  //
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const userRole = useSelector((state) => state.userRoleSlice.value)
  const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);

  const userLoginMeta = localStorage.getItem("login_meta");
  const storeCode = JSON.parse(userLoginMeta)?.branchPk;
  const { userDetails, branchDetails } = useSelector((state) => state.mainDashBoardSlice)

  const userBranchId = localStorage.getItem("branchId");

  const decimalPosition = Number(localStorage.getItem("decimalPosition"));

  // stored data from redux
  const editedData = useSelector((state) => state.salesQuatationSlice.rowData);
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  const allNewProductList = useSelector(
    (state) => state.vendorBillSlice.NewProductList
  );

  const uomSubList = useSelector(
    (state) => state.unitOfMeasurmentSlice.subUomList
  );
  const creditNoteNameList = useSelector(
    (state) => state.salesOrderSlice.generateName
  );
  const allTaxList = useSelector((state) => state.taxSettingsSlice.value);
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);

  const allActiveTaxList = allTaxList?.filter(
    (item) => item?.taxType === "Sales"
  );

  // defining to navigate to another page
  let navigateTo = useNavigate();

  // useStates
  const addRowData = ["Add Header", "Add Line"];
  const [isActiveTable, setIsActiveTable] = useState("invoiceLines");
  const [addRowInput, setAddRowInput] = useState("Add Line");
  const [creditNoteName, setCreditNoteName] = useState("###");
  const [editDataId, setEditDataId] = useState("");
  const [handlePaymentTermsbtn, setHandlePaymentTermsbtn] = useState("");
  const [openPurchaseHistory, setOpenPurchaseHistory] = useState(false)
  const [rowId, setRowId] = useState()

  // total container data
  const [totalsInfo, setTotalsInfo] = useState({
    subTotal: 0.0,
    inclusiveTax: 0.0,
    exclusiveTax: 0.0,
    total: 0.0,
  });

  // table initial values
  const tableDataInitial = {
    type: "lines",
    product: null,
    label: "",
    account: null,
    uom: null,
    qty: "",
    unitPrice: "",
    discount: "",
    tax: [],
    amount: "",
    inclusive: "",
    header: "",
  };
  const [tableData, setTableData] = useState([tableDataInitial]);

  // setting taxes list for each row
  const [filteredTaxOptions, setFilteredTaxOptions] = useState(
    tableData?.map(() => allActiveTaxList || [])
  );


  const [rowIndex, setRowIndex] = useState(null);

  const [loadingForm, setLoadingForm] = useState(false);

  const [image, setImage] = useState([]);
  const [imageDisplay, setImageDisplay] = useState([]);
  const [logView, setLogView] = useState(false);
  const [isTableModified, setIsTableModified] = useState(false);
  const [isLoading, setIsLoading] = useState("");
  const [editClicked, setIsEditClicked] = useState(false);
  const containerRef = useRef(null);
  const [validationAlert, setValidationAlert] = useState({});
  const [log, setLog] = useState([]);
  // snackbar states
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false,
  });
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };

  // initial form input values
  const formInitialState = {
    customer: null,
    branch: branchDetails,
    expiryDate: "",
    quatationDate: today,
    reference: "",

    customerNotes: "",
    termsAndConditions: "",

    // otherInfo
    contactPerson: "",
    deliveryAddress: "",
    paymentTerms: null,
    lpoNo: "",

    isEdit: false,
    status: "",
  };

  const [formValues, setFormValues] = useState(formInitialState);

  const clearForm = () => {
    setFormValues(formInitialState);

    setTableData([tableDataInitial]);
    setIsTableModified(!isTableModified);
    setEditDataId("");
  };
  // switch between invoice lines and other info
  const productMenuClick = () => {
    document.getElementById("productMenuBtn").classList.add("active");
    document.getElementById("departmentMenuBtn").classList.remove("active");
    setIsActiveTable("invoiceLines");
  };

  const departmentMenuClick = () => {
    document.getElementById("productMenuBtn").classList.remove("active");
    document.getElementById("departmentMenuBtn").classList.add("active");
    setIsActiveTable("others");
  };

  const [addRowScroll, setAddRowScroll] = useState(false);
  // to add new row in table
  const addRow = () => {
    if (addRowInput === "Add Header") {
      setTableData([...tableData, { type: "header", header: "" }]);
    } else if (addRowInput === "Add Line") {
      let lastRow = tableData[tableData.length - 1]
      if (lastRow?.product === null) {
        dispatch(setSnackMsg("Fill all the required fields in the last row !!"))
        dispatch(setErrorSnack(true))
      } else {
        setTableData([
          ...tableData,
          {
            type: "lines",
            id: Date.now(),
            product: null,
            label: "",
            account: null,
            uom: null,
            qty: "",
            unitPrice: "",
            discount: "",
            tax: [],
            amount: "",
            inclusive: "",
          },
        ]);
      }

    }
    setAddRowScroll(!addRowScroll);
  };
  // delete a row from the table
  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  // upload file functions
  const changeImage = (e) => {
    setImage([...image, e.target.files[0]]);
    setImageDisplay([...imageDisplay, URL.createObjectURL(e.target.files[0])]);
  };
  const removeImageFn = (i) => {
    image.splice(i, 1);
    imageDisplay.splice(i, 1);
    setImage([...image]);
    setImageDisplay([...imageDisplay]);
  };

  // view logs enabled
  const clickLogView = () => {
    setLogView(!logView);
  };
  // form info onchange fn
  const getFormInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      setValidationAlert({});
      setFormValues({
        ...formValues,
        [key]: newValue !== null ? newValue : null,
      });
    } else {
      setValidationAlert({});
      setFormValues({
        ...formValues,
        [key]:
          value !== 0 && value !== undefined
            ? value
            : newValue !== null
              ? newValue
              : null,
      });
    }
  };

  // table data onchnage for each rows
  const handleInputChange = (id, field) => (e, newValue) => {
    const { value } = e.target;
    const updatedData = tableData.map((row, index) => {
      if (index === id) {
        if (field === "product") {
          setRowIndex(index);
          let customerTax = newValue?.customerTax || []
          let taxObjects = allTaxList?.filter(obj => customerTax.includes(obj._id))

          let selectedTaxType = null
          if (taxObjects.length !== 0) {
            selectedTaxType = taxObjects[0]?.includedPrice
          }
          const newFilteredTaxOptions = selectedTaxType !== null ? allActiveTaxList?.filter((option) => option.includedPrice === selectedTaxType)
            : allActiveTaxList;
          filteredTaxOptions[index] = newFilteredTaxOptions;

          return {
            ...row,
            [field]: newValue,
            label: newValue?.productName || "",
            uom: findObjFromList("_id", uomSubList, "uom", newValue),
            qty: newValue !== null ? 1 : "",
            unitPrice: newValue?.salesPrice || "",
            discount: newValue !== null ? 0 : "",
            tax: taxObjects,
            amount: newValue?.salesPrice || "",
            inclusive: selectedTaxType !== undefined ? selectedTaxType : "",
          };
        } else if (field === "tax") {
          const selectedTaxId = newValue?.length !== 0 ? newValue[0]?.includedPrice : undefined;
          const newFilteredTaxOptions =
            selectedTaxId !== undefined
              ? allActiveTaxList.filter(
                (option) => option.includedPrice === selectedTaxId
              )
              : allActiveTaxList;
          filteredTaxOptions[index] = newFilteredTaxOptions;
          return {
            ...row,
            tax: newValue,
            inclusive: selectedTaxId !== undefined ? selectedTaxId : "",
          };
        } else if (field === "qty") {
          let totalAmt = 0;
          let totalItemAmt =
            Number(value)?.toFixed(decimalPosition) * Number(row?.unitPrice);
          let discount = (Number(row?.discount) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;

          return { ...row, amount: totalAmt, [field]: value };
        } else if (field === "unitPrice") {
          let totalAmt = 0;
          let totalItemAmt =
            Number(value)?.toFixed(decimalPosition) * Number(row?.qty);
          let discount = (Number(row?.discount) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else if (field === "discount") {
          let totalAmt = 0;
          let totalItemAmt = Number(row?.qty) * Number(row?.unitPrice);
          let discount =
            (Number(value)?.toFixed(decimalPosition) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else {
          return { ...row, [field]: newValue !== undefined ? newValue : e.target.value };
        }
      }
      return row;
    });

    calculateTax(updatedData)
  };
  // tax calculated from general function
  const calculateTax = (array) => {
    let taxUpdatedTable = taxCalculationForLines(array)
    setTableData(taxUpdatedTable)
  }
  // functon used to set the decimalpositioning
  const handleMouseEnter = () => {
    const updatedArray = roundNumericFields(tableData, [
      "qty",
      "unitPrice",
      "discount",
      "amount",
    ]);
    setTableData(updatedArray);
  };

  // setting product info array to send in request
  const tableDataToBackend = tableData?.map((item) =>
    item?.type === "lines"
      ? {
        line_type: item?.type,
        _id: item?.product?._id,
        mainCatgeoryId: item?.product?.mainCatgeoryId || item?.mainCatgeoryId,
        product: item?.product?.productName,
        label: item?.label,
        uomName: item?.uom?.uomName,
        purchaseUomName: item?.product?.purchaseUomName || item?.purchaseUomName,
        purchaseuom: item?.product?.purchaseuom || item?.purchaseuom,
        cost: item?.product?.cost,
        uom: item?.uom?._id,
        qty: Number(item?.qty),
        unitPrice: Number(item?.unitPrice),
        discount: Number(item?.discount),
        includedPrice: item?.inclusive === "" ? null : item?.inclusive,
        taxes: item?.taxSplitted,
        taxIdList: item?.taxIdList,
        prodType: item?.prodType || item?.product?.prodType
      }
      : {
        line_type: item?.type,
        label: item?.header,
      }
  );

  // creating formdata
  const formData = new FormData();
  // function to send api requests
  const submitBtnClicked = (key) => () => {
    dispatch(setFilterActive(false))
    // appending key and values
  /*required*/ formData.append("customerId", formValues?.customer?._id);
  /*required*/ formData.append(
      "expiryDate",
      formValues?.expiryDate === "" ? null : formValues?.expiryDate
    );
    formValues?.reference !== "" &&
      formData.append("reference", formValues?.reference);
    formValues?.customerNotes !== "" &&
      formData.append("customerNotes", formValues?.customerNotes);
    formValues?.termsAndConditions !== "" &&
      formData.append("termsAndCondition", formValues?.termsAndConditions);
  /*required*/ formData.append(
        "total",
        totalsInfo?.total?.toFixed(decimalPosition)
      );
    formValues?.lpoNo !== "" && formData.append("LpoNo", formValues?.lpoNo);

  /*required*/ formData.append("taxableAmount", 0);
  /*required*/ formData.append("tax", 0);
  /*required*/ formData.append("quotationDate", formValues?.quatationDate);
  /*required*/ formData.append("branchId", formValues?.branch?._id);
  /*required*/ formData.append(
      "productInfo",
      JSON.stringify(tableDataToBackend)
    );
    image.length !== 0 &&
      image.forEach((file, index) => {
        formData.append("addUrl", file);
      });
  /*required*/ formData.append("postDate", `${today} ${getCurrentTime()}`);
    formValues?.contactPerson !== "" &&
      formData.append("contactPerson", formValues?.contactPerson);
    formValues?.deliveryAddress !== "" &&
      formData.append("deliveryAddress", formValues?.deliveryAddress);
    formValues?.paymentTerms !== null && formData.append("paymentTerms", formValues?.paymentTerms?._id || null);

    if (key === "confirm") {
      let draftFn = () => {
        navigateTo("/userdashboard/sales/orders/salesOrder/create");
      };
      let postFormData = new FormData();
      postFormData.append("_id", editDataId);
      postFormData.append("postDate", `${today} ${getCurrentTime()}`);

      confirmSalesQuatationAPICall(
        postFormData,
        draftFn,
        setSnackBarStates,
        snackBarStates,
        setLoadingForm
      );
    }
    if (key === "cancel") {
      let draftFn = () => {
        setFormValues({ ...formValues, isEdit: true, status: "CANCEL" });
        setIsEditClicked(false);
      };
      let postFormData = {
        _id: editDataId,
        statusType: 1,
        postDate: `${today} ${getCurrentTime()}`,
      };

      cancelSalesQuatationAPICall(
        postFormData,
        draftFn,
        setSnackBarStates,
        snackBarStates,
        setLoadingForm
      );
    }
    if (formValues?.customer === null || formValues?.customer === undefined) {
      setValidationAlert({
        ...validationAlert,
        customer: "fill this field !!",
      });
    } else if (
      formValues?.branch === null ||
      formValues?.branch === undefined
    ) {
      setValidationAlert({ ...validationAlert, branch: "fill this field !!" });
    } else if (
      formValues?.quatationDate === "" ||
      formValues?.quatationDate === undefined
    ) {
      setValidationAlert({
        ...validationAlert,
        quatationDate: "fill this field !!",
      });
    } else {
      if (key === "draft") {
        let draftFn = () => {
          setFormValues({ ...formValues, isEdit: true, status: "DRAFT" });
        };
        draftSalesQuatationAPICall(
          formData,
          draftFn,
          setSnackBarStates,
          snackBarStates,
          setEditDataId,
          setLoadingForm
        );
      } else if (key === "edit") {
        let draftFn = () => {
          setFormValues({ ...formValues, isEdit: true, status: "DRAFT" });
          setIsEditClicked(false);
        };
        formData.append("id", editDataId);
        editSalesQuatationAPICall(
          formData,
          draftFn,
          setSnackBarStates,
          snackBarStates,
          setLoadingForm
        );
      }
    }
  };


  const openPurchaseHistoryFn = (item) => {
    setRowId(item);
    setOpenPurchaseHistory(true)
  }
  useEffect(() => {
    if (containerRef.current) {
      setTimeout(() => {
        containerRef.current.scrollIntoView({ index: tableData.length, behavior: "smooth", align: "end", behaviour: "smooth" });
      }, 0);
    }
  }, [addRowScroll]);
  // useEffects
  // for adding new row in table
  // useEffect(() => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [addRowScroll]);

  // initial api calls for listing dats
  useEffect(() => {
    getAllTaxSettingsListAPICall();
    viewUOMSubListAPICall();
    if (singleRowId) {
      salesQuotationSingleViewAPICall({ _id: singleRowId });
    }
  }, []);

  // calculation of totals
  useEffect(() => {
    setTotalsInfo({
      ...totalsInfo,
      subTotal: tableData?.reduce(
        (sum, item) => sum + Number(item?.amount || 0),
        0
      ),
      inclusiveTax: tableData?.reduce(
        (sum, item) => sum + Number(item?.inclusiveTax || 0),
        0
      ),
      exclusiveTax: tableData?.reduce(
        (sum, item) => sum + Number(item?.exclusiveTax || 0),
        0
      ),
      total:
        tableData?.reduce((sum, item) => sum + Number(item?.amount || 0), 0) +
        tableData?.reduce(
          (sum, item) => sum + Number(item?.exclusiveTax || 0),
          0
        ),
    });
  }, [tableData]);

  // generate name apicall
  useEffect(() => {

    if (editedData === undefined && formValues?.branch) {
      generateSalesOrderNameAPICall({
        branchId: formValues?.branch?._id,
        quotationDate: formValues?.quatationDate,
      }, setCreditNoteName);
    }
    else {
      store.dispatch(generate_sales_order_name({ salesOrderQuotname: undefined }))
      setCreditNoteName("###")
    }
  }, [formValues?.branch]);



  // edit
  useEffect(() => {
    if (editedData !== undefined && allBranchesList !== undefined && customerList !== undefined) {
      setEditDataId(editedData?._id);
      setCreditNoteName(editedData?.TRANSNO);
      setFormValues({
        ...formValues,
        isEdit: true,
        status: editedData?.status,

        customer: editedData?.customer,
        branch: findObjFromList(
          "_id",
          allBranchesList,
          "branchId",
          editedData
        ),
        expiryDate: editedData?.expiryDate || "",
        quatationDate: editedData?.quotationDate || "",
        reference: editedData?.reference || "",
        customerNotes: editedData?.customerNotes || "",
        termsAndConditions: editedData?.termsAndConditions || "",
        contactPerson: editedData?.contactPerson || "",
        deliveryAddress: editedData?.deliveryAddress || "",
        lpoNo: editedData?.LpoNo || "",

        paymentTerms: findObjFromList(
          "_id",
          paymentTermsList,
          "paymentTerms",
          editedData
        ),
      });
      setLog(editedData?.log);
    } else {
      clearForm();
    }
  }, [
    editedData,
    customerList, allBranchesList
  ]);

  useEffect(() => {
    if (editedData !== undefined && allTaxList !== undefined) {
      const updatedArray = editedData?.productInfo?.map((r, i) => {

        if (r?.line_type === "Line" || r?.line_type === "lines") {

          let taxesId = r?.taxIdList || []
          let taxObjects = allTaxList?.filter(obj => taxesId.some(taxObj => taxObj._id === obj?._id));
          const newFilteredTaxOptions = r?.includedPrice !== null ? allActiveTaxList?.filter((option) => option.includedPrice === r?.includedPrice)
            : allActiveTaxList;
          filteredTaxOptions[i] = newFilteredTaxOptions;

          return {
            type: r?.line_type,
            product: r?.prodObj,
            label: r?.label || "",
            uom: findObjFromList("_id", uomSubList, "uom", r),
            qty: r?.qty || "",
            unitPrice: r?.unitPrice || "",
            discount: r?.discount || 0,
            tax: taxObjects,
            amount:
              r?.qty * r?.unitPrice -
              (r?.qty * r?.unitPrice * r?.discount) / 100 || "",
            inclusive: r?.includedPrice,
            prodType: r?.prodType
          };
        } else {
          return {
            type: r?.line_type,
            header: r?.label,
          };
        }
      });

      calculateTax(updatedArray)
    }
  }, [editedData?.productInfo, uomSubList, allTaxList]);



  useEffect(() => {

    userRole === "user" && viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });

    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !formValues?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formValues?.isEdit]);



  return (
    <div className="global-page-parent-container">
      <div
        className="new-global-white-bg-container"
        style={{ padding: "0 0", backgroundColor: "unset"}}
      >
        <div className="new-global-white-bg-icon-container" style={{backgroundColor:"white"}}>
          {isLoading ? (
            <Skeleton
              sx={{ marginTop: "1%" }}
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={40}
            />
          ) : (
            <div className="new-global-white-bg-icon-container-left" style={{ width: "60%", display: "flex", alignItems: "center" }}>
              {/* <Tooltip
                title="Back"
                onClick={() => {
                  if (formValues?.isEdit === false) {
                    const userConfirmed = window.confirm(
                      "Changes you made may not be saved."
                    );

                    if (userConfirmed) {
                      navigateTo("/userdashboard/sales/orders/quotation");
                     editedData!==undefined&& dispatch(set_Back_button_action({ backButtonClickAction: true }));
                    }
                  } else {
                    navigateTo("/userdashboard/sales/orders/quotation");
                    editedData!==undefined&& dispatch(set_Back_button_action({ backButtonClickAction: true }));
                  }
                }}
              >
                <IconButton>
                  <i class="bi bi-chevron-left"></i>
                </IconButton>
              </Tooltip> */}
              {formValues?.status === "DRAFT" && (
                <Tooltip title="Edit">
                  <IconButton
                    onClick={
                      editClicked
                        ? submitBtnClicked("edit")
                        : () => {
                          setFormValues({ ...formValues, isEdit: false });
                          setIsEditClicked(true);
                        }
                    }
                    onMouseEnter={handleMouseEnter}
                  >
                    <i
                      class={
                        editClicked
                          ? "bi bi-floppy save-icon"
                          : "bi bi-pencil-square edit-icon1"
                      }
                    ></i>
                  </IconButton>
                </Tooltip>
              )}

              {formValues?.status === "" && (
                <Tooltip title="Save">
                  <IconButton
                    onMouseEnter={handleMouseEnter}
                    onClick={submitBtnClicked("draft")}
                  >
                    <i class="bi bi-floppy save-icon"></i>
                  </IconButton>
                </Tooltip>
              )}
              {formValues?.status === "CANCEL" && (
                <Tooltip title="Reset To Draft">
                  <IconButton>
                    <i class="bi bi-floppy save-icon"></i>
                  </IconButton>
                </Tooltip>
              )}

              {formValues?.status === "DRAFT" && !editClicked && (
                <>
                  <button
                    className="btn btn-post"
                    style={{
                      color: "#131010",
                      backgroundColor: "white",
                      border: "2px solid black",
                    }}
                    onClick={submitBtnClicked("cancel")}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn btn-post"
                    onClick={submitBtnClicked("confirm")}
                  >
                    Confirm
                  </button>
                </>
              )}
              <h3 style={{ display: "flex" }}>
                <span
                  style={{ fontSize: "medium",color: "#676666", cursor: "pointer" }}
                  onClick={() => {
                    if (formValues?.isEdit === false) {
                      const userConfirmed = window.confirm(
                        "Changes you made may not be saved."
                      );

                      if (userConfirmed) {
                        navigateTo("/userdashboard/sales/orders/quotation");
                        editedData !== undefined && dispatch(set_Back_button_action({ backButtonClickAction: true }));
                      }
                    } else {
                      navigateTo("/userdashboard/sales/orders/quotation");
                      editedData !== undefined && dispatch(set_Back_button_action({ backButtonClickAction: true }));
                    }
                  }}
                >Sales Quotation &gt;</span>
                <span style={{ fontSize: "medium", paddingLeft: "8px" }}>{editedData?.NAME || creditNoteName}</span>

              </h3>
            </div>
          )}
         
        </div>
        {/* <hr className="global-hr" /> */}
        <div style={{backgroundColor:"white",padding:"10px",marginTop:"6px",position:"relative"}}>
        <div className="status-container" style={{right:'0'}}>
            {!isLoading && formValues?.status === "DRAFT" && (
              <img src={draftRibbon} alt="" />
            )}
            {!isLoading && formValues?.status === "CANCEL" && (
              <img src={cancelRibbon} alt="" />
            )}
          </div>
        <h4 className="global-name">{editedData?.NAME || creditNoteName}</h4>
        {isLoading ? (
          <Skeleton
            sx={{ margin: "1% 0" }}
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={150}
          />
        ) : (
          <div className="new-global-input-container">
            <div className="new-global-single-input auto-complete-new">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={customerList || []}
                filterOptions={customerFilterOptions}
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer*"
                    focused
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option.name}</span>
                  </li>
                )}
                onChange={getFormInfo("customer")}
                value={formValues?.customer}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.customer}
              </p>
            </div>

            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={userRole === "admin" ? allBranchesList : allowedBranchList || []}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Branch*" focused />
                )}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{`${option?.storeCode}-${option?.branchName}`}</span>
                  </li>
                )}
                onChange={getFormInfo("branch")}
                value={formValues?.branch}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">{validationAlert?.branch}</p>
            </div>

            <div className="new-global-single-input">
              <TextField
                value={formValues?.expiryDate}
                onChange={getFormInfo("expiryDate")}
                id="outlined-basic"
                label="Expiry Date*"
                variant="outlined"
                type="date"
                focused
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.expiryDate}
              </p>
            </div>

            <div className="new-global-single-input">
              <TextField
                value={formValues?.quatationDate}
                onChange={getFormInfo("quatationDate")}
                id="outlined-basic"
                label="Quatation Date*"
                variant="outlined"
                type="date"
                focused
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.quatationDate}
              </p>
            </div>

            <div className="new-global-single-input">
              <TextField
                value={formValues?.reference}
                onChange={getFormInfo("reference")}
                id="outlined-basic"
                label="Reference"
                variant="outlined"
                type="text"
                focused
                disabled={formValues?.isEdit}
              />
            </div>
            {/* <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Payment Terms"
                variant="outlined"
                type="text"
                focused
                disabled={formValues?.isEdit}
                value={formValues?.paymentTerms}
                onChange={getFormInfo("paymentTerms")}
              />
            </div>   */}
            <div
              className="new-global-single-input auto-complete-new"
              style={{ width: "22%" }}
            >
              <div className="" style={{ position: "relative" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={paymentTermsList || []}
                  getOptionLabel={(option) => option?.paymentTerm}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Payment Terms*" focused />
                  )}
                  value={formValues?.paymentTerms || null}
                  onChange={getFormInfo("paymentTerms")}
                  disabled={formValues?.isEdit}
                />
                {!formValues.isEdit && (
                  <button
                    onClick={() => {
                      setFormValues((prev) => ({
                        ...prev,
                        paymentTerms: null,
                      }));
                      setHandlePaymentTermsbtn(!handlePaymentTermsbtn);
                    }}
                    className="add-row-btn autocomplete-inside-plusbtn"
                  >
                    +
                  </button>
                )}
              </div>
            </div>
            <div className="new-global-single-input">
              <TextField
                value={formValues?.lpoNo}
                onChange={getFormInfo("lpoNo")}
                id="outlined-basic"
                label="LPO No."
                variant="outlined"
                type="text"
                focused
                disabled={formValues?.isEdit}
              />
            </div>
          </div>
        )}
      </div>

      <div className="new-global-white-bg-container">
        {isLoading ? (
          <Skeleton
            sx={{ marginTop: "1%" }}
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={110}
          />
        ) : (
          <>
            <div
              className="rewards-add-rewards-ctn-top-menu accounts-table-toggle"
              style={{ marginBottom: "1%" }}
            >
              <div
                className="tab-menu-button active"
                id="productMenuBtn"
                onClick={productMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Invoice Lines
                </Typography>
              </div>
              <div
                className="tab-menu-button"
                id="departmentMenuBtn"
                onClick={departmentMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Other Info
                </Typography>
              </div>
            </div>
            {isActiveTable === "invoiceLines" ? (
              <>
                <TableVirtuoso
                  ref={containerRef}
                  className="global-product-table"
                  style={{ height: getTableHeightForVirtuso(tableData), maxHeight: "265px" }}
                  data={tableData}
                  fixedHeaderContent={() => (
                    <tr>
                      <th>Product</th>
                      <th>Description</th>
                      <th>UOM</th>
                      <th>Qty</th>
                      <th>Unit Price</th>
                      <th>Discount(%)</th>
                      <th>Tax</th>
                      <th>Amount</th>
                      <th style={{ backgroundColor: "#fff" }}></th>
                    </tr>
                  )}

                  itemContent={(index, item) => (
                    <>
                      {item.type === "header" ? (
                        <>
                          <td colSpan="8">
                            <input
                              type="text"
                              style={{
                                fontSize: "0.9rem",
                                fontWeight: 600,
                              }}
                              value={item.header}
                              onChange={handleInputChange(index, "header")}
                              disabled={formValues?.isEdit}
                            />
                          </td>
                          <td style={{ border: "none", width: "4%" }}>
                            <IconButton
                              onClick={() => handleDelete(index)}
                              style={{ height: "30px" }}
                              disabled={formValues?.isEdit}
                            >
                              <i
                                className="bi bi-trash3"
                                style={{
                                  color: "#db0000",
                                  fontSize: "1.3rem",
                                }}
                              ></i>
                            </IconButton>
                          </td>
                        </>
                      ) : (
                        <>
                          <td style={{ minWidth: "140px" }}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                options={allNewProductList || []}
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option) => option?.productName}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderInput={(params) => (
                                  <TextField
                                    // inputRef={inputRef}
                                    // onChange={(e) =>
                                    //   productSearchApi({
                                    //     search: e.target.value,
                                    //   })
                                    // }
                                    {...params}
                                    onBlur={handleMouseEnter}
                                  />
                                )}
                                renderOption={(props, option) => (
                                  <li {...props} key={option._id}>
                                    <span>{option.productName}</span>
                                  </li>
                                )}
                                disabled={formValues?.isEdit}
                                value={item?.product}
                                onChange={handleInputChange(
                                  index,
                                  "product"
                                )}
                              />
                            </div>
                          </td>
                          <td style={{  width:`${
                                    String(item?.label).length * 10 +20
                                  }px`,minWidth:"90px" }}>
                            <div className="global-product-table-input-icon">
                              {
                                item?.product !== null &&
                                <img onClick={() => openPurchaseHistoryFn(item)} src={purchasehistoryicon} alt="" title="Sales History" />

                              }
                              <input
                                type="text"
                                onChange={handleInputChange(index, "label")}
                                value={item?.label}
                                disabled={formValues?.isEdit}
                              />
                            </div>

                          </td>
                          <td style={{ minWidth: "95px" }}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                options={uomSubList?.filter((obj) => obj?.parentCatId === (item?.product?.parentUomId)) || []}
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option) => option?.uomName}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                disabled={formValues?.isEdit}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                  />
                                )}
                                value={item?.uom}
                                onChange={handleInputChange(index, "uom")}
                              />
                            </div>
                          </td>
                          <td style={{ maxWidth: "85px" }}>
                            <input
                              type="text"
                              onChange={handleInputChange(index, "qty")}
                              value={item?.qty}
                              style={{ textAlign: "right" }}
                              disabled={formValues?.isEdit}
                              onBlur={handleMouseEnter}
                              onFocus={(e) => e.target.select()}
                            />
                          </td>
                          <td style={{ maxWidth: "85px" }}>
                            <input
                              type="text"
                              onChange={handleInputChange(
                                index,
                                "unitPrice"
                              )}
                              value={item?.unitPrice}
                              style={{ textAlign: "right" }}
                              disabled={formValues?.isEdit}
                              onBlur={handleMouseEnter}
                              onFocus={(e) => e.target.select()}
                            />
                          </td>
                          <td style={{ maxWidth: "85px" }}>
                            <input
                              type="text"
                              onChange={handleInputChange(
                                index,
                                "discount"
                              )}
                              value={item?.discount}
                              style={{ textAlign: "right" }}
                              disabled={formValues?.isEdit}
                              onBlur={handleMouseEnter}
                              onFocus={(e) => e.target.select()}
                            />
                          </td>
                          <td style={{ width: "216px" }}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                multiple
                                options={filteredTaxOptions[index] || []}
                                getOptionLabel={(option) => option?.taxName}
                                renderInput={(params) => (
                                  <TextField {...params} 
                                    onBlur={handleMouseEnter}
                                  />
                                )}
                                value={item.tax}
                                onChange={handleInputChange(index, "tax")}
                                disabled={formValues?.isEdit}
                              />
                            </div>
                          </td>
                          <td style={{ maxWidth: "90px" }}>
                            <input
                              type="text"
                              value={item?.amount}
                              disabled={true}
                              style={{ textAlign: "right" }}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                            />
                          </td>
                          <td style={{ border: "none", width: "4%" }}>
                            <IconButton
                              onClick={() => handleDelete(index)}
                              style={{ height: "30px" }}
                              disabled={formValues?.isEdit}
                            >
                              <i
                                className="bi bi-trash3"
                                style={{
                                  color: "#db0000",
                                  fontSize: "1.3rem",
                                }}
                              ></i>
                            </IconButton>
                          </td>
                        </>
                      )}
                    </>
                  )}

                />
                <div className="add-row-btn-container">
                  <div
                    className="global-single-input auto-complete add-line-autocomplete"
                    style={{ width: "76%" }}
                  >
                    <Autocomplete
                      options={addRowData || []}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => <TextField {...params} />}
                      value={addRowInput}
                      onChange={(e, newValue) => setAddRowInput(newValue)}
                      disabled={formValues?.isEdit}
                    />
                  </div>
                  <button
                    disabled={formValues?.isEdit}
                    onClick={() => addRow()}
                    className="add-row-btn"
                  >
                    +
                  </button>
                </div>
              </>
            ) : (
              <div className="new-global-input-container">
                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label="Contact Person"
                    variant="outlined"
                    type="text"
                    focused
                    disabled={formValues?.isEdit}
                    value={formValues?.contactPerson}
                    onChange={getFormInfo("contactPerson")}
                  />
                </div>
                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label="Delivery Address"
                    variant="outlined"
                    type="text"
                    focused
                    disabled={formValues?.isEdit}
                    value={formValues?.deliveryAddress}
                    onChange={getFormInfo("deliveryAddress")}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {isActiveTable === "invoiceLines" && (
        <div
          className="new-global-white-bg-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            padding: "8px 2%",
          }}
        >
          {isLoading ? (
            <Skeleton
              sx={{ marginTop: "1%" }}
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={110}
            />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "97%",
                }}
              >
                <div
                  className="new-global-single-input"
                  style={{ width: "35%" }}
                >
                  <p>Customer Notes</p>
                  <textarea
                    value={formValues?.customerNotes}
                    onChange={getFormInfo("customerNotes")}
                    disabled={formValues?.isEdit}
                    name=""
                    id=""
                    cols="50"
                    rows="5"
                  ></textarea>
                </div>

                <div
                  className="new-global-single-input"
                  style={{ width: "35%" }}
                >
                  <p>Terms & Conditions</p>
                  <textarea
                    value={formValues?.termsAndConditions}
                    onChange={getFormInfo("termsAndConditions")}
                    disabled={formValues?.isEdit}
                    name=""
                    id=""
                    cols="50"
                    rows="5"
                  ></textarea>
                </div>

                <div
                  className="new-global-single-input"
                  style={{ maxWidth: "250px" }}
                >
                  <div className="subtotal-ctn">
                    <div className="subtotal-ctn-left">
                      <p>Sub Total</p>
                      {totalsInfo?.inclusiveTax !== 0 && <p>Inclusive Tax</p>}

                      {totalsInfo?.exclusiveTax !== 0 && <p>Exclusive Tax</p>}
                      {totalsInfo?.exclusiveTax === 0 &&
                        totalsInfo?.inclusiveTax === 0 && <p>Tax</p>}
                    </div>
                    <div className="subtotal-ctn-right">
                      <p>{totalsInfo?.subTotal?.toFixed(decimalPosition)}</p>
                      {totalsInfo?.inclusiveTax !== 0 && (
                        <p>
                          {totalsInfo?.inclusiveTax?.toFixed(decimalPosition)}
                        </p>
                      )}
                      {totalsInfo?.exclusiveTax !== 0 && (
                        <p>
                          {totalsInfo?.exclusiveTax?.toFixed(decimalPosition)}
                        </p>
                      )}
                      {totalsInfo?.exclusiveTax === 0 &&
                        totalsInfo?.inclusiveTax === 0 && <p>0.00</p>}
                    </div>
                  </div>
                  <hr
                    className="global-hr"
                    style={{ width: "80%", marginLeft: "25%" }}
                  />
                  <div className="subtotal-ctn">
                    <div className="subtotal-ctn-left">
                      <p style={{ color: "#000", fontWeight: "600" }}>Total</p>
                    </div>
                    <div className="subtotal-ctn-right">
                      <p>{totalsInfo?.total?.toFixed(decimalPosition)} </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                }}
              >
                <div className="file-picker-new">
                  <input
                    disabled={formValues?.isEdit}
                    type="file"
                    id="fileUpload"
                    onChange={changeImage}
                  />
                  <label htmlFor="fileUpload">
                    <img src={uploadFile} alt="" />
                    Upload File
                  </label>
                </div>
                <div style={{ display: "flex", margin: "0 0 0 22px" }}>
                  {imageDisplay?.map((r, i) => (
                    <div className="uploaded-image-list">
                      <img src={r} alt="" />
                      <div className="image-remove-icon-new">
                        <IconButton
                          disabled={formValues?.isEdit}
                          onClick={() => removeImageFn(i)}
                        >
                          <CloseOutlined
                            sx={{ color: "#fd0301", fontSize: "0.9rem" }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {editedData !== undefined && (
        <div
          className="new-global-white-bg-container new-log-container"
          style={{ padding: "4px" }}
        >
          <div
            style={{ padding: "8px", cursor: "pointer" }}
            onClick={clickLogView}
          ></div>
          <div
            className={logView ? "pointer-up" : "pointer"}
            onClick={clickLogView}
            style={{ cursor: "pointer" }}
          ></div>
          {logView && (
            <div className="single-log-container">
              {log?.map((r, i) => (
                <div className="single-log-item">
                  <div className="single-log-item-line-image">
                    <hr className="single-log-item-line" />
                    <div className="single-log-item-image">
                      <img src={logoImage} alt="" />
                    </div>
                    <hr className="single-log-item-line" />
                  </div>
                  <p className="heading">{r?.status}</p>
                  <p className="name">{r?.empId}</p>
                  <p className="date-time">{r?.date}</p>
                  <p className="date-time">{r?.time}</p>
                </div>
              ))}
            </div>
          )}

          {/* <div className='single-log-container-scroll-line'></div> */}
        </div>
      )}
      <PaymentTerms
        handleDialog={handlePaymentTermsbtn}
        setHandleDialog={setHandlePaymentTermsbtn}
      />
      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <LoadingForm loading={loadingForm} />

      <Dialog open={openPurchaseHistory} maxWidth="auto" onClose={() => setOpenPurchaseHistory(false)}>
        <div>
          <PurchaseHistory
            closeDialog={() => setOpenPurchaseHistory(false)}
            item={rowId}
            historyApiCall={salesOrderHistoryAPICall}
            name="Sales Quotation"
            response={soHistoryData}
            orderno={creditNoteName}
            type="sales"
            formValues={formValues?.customer}
          />
        </div>
      </Dialog>
      </div>
    </div>
  );
};
