// icon:bx-chair | Boxicons https://boxicons.com/ | Atisa
import * as React from "react";

function Chair(props) {
  return (
    <div>
      <p style={{ textAlign: "center",margin:0 }}>{props.name}</p>
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        height="2em"
        width="2em"
        {...props}
      >
        <path d="M19 13V4c0-1.103-.897-2-2-2H7c-1.103 0-2 .897-2 2v9a1 1 0 00-1 1v8h2v-5h12v5h2v-8a1 1 0 00-1-1zm-2-9v9h-2V4h2zm-4 0v9h-2V4h2zM7 4h2v9H7V4z" />
      </svg>
    </div>
  );
}

export default Chair;
