import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
}));

const ContentBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  maxWidth: "400px",
  width: "100%",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontWeight: "normal",
  borderRadius: 0,
}));

const ConfirmationDialog = ({ isOpen, handleClose, handleConfirm }) => {
  return isOpen ? (
    <StyledBox>
      <ContentBox>
        <Typography variant="h6" gutterBottom>
          Confirm Close
        </Typography>
        <Typography variant="body1" gutterBottom>
          Any changes you have made may not be saved.
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <StyledButton onClick={handleClose} variant="outlined" sx={{ mr: 1 }}>
            Cancel
          </StyledButton>
          <StyledButton onClick={handleConfirm} variant="contained" autoFocus>
            Confirm
          </StyledButton>
        </Box>
      </ContentBox>
    </StyledBox>
  ) : null;
};

export default ConfirmationDialog;