import React, { useEffect } from 'react'
import "./specialItems.css"
import { IconButton, Skeleton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { deleteListSpecialItemsAPI, editListSpecialItemsAPI, listSpecialItemsAPI, specialItemsSingleView } from './specialItemsApi';
import { setIsEditClicked, storeSpecialItemList, storeSpecialItemSingleList } from './specialItemsSlice';
import { useNavigate } from 'react-router-dom';
import ComboPriceTag from './components/ComboPriceTag';
function SpecialItems() {
    
    const dispatch=useDispatch()
    const navigate=useNavigate()

    const decimalPosition=localStorage.getItem("decimalPosition")
    
    const userInfo=useSelector((state)=>state.loginResponseSlice.value)
    const {specialItemsList,tableLoading}=useSelector((state)=>state.specialItemsSlice)
  
    const createBtnClicked=()=>{
        dispatch(storeSpecialItemSingleList(null))
        navigate("create")
        localStorage.removeItem("singleViewById")
    }
    const InitialAPICalls=()=>{
        listSpecialItemsAPI({branchId:userInfo?.branchPk})
        dispatch(setIsEditClicked(false))
        localStorage.removeItem("singleViewById")
    }

    const editList=(row,index)=>(e)=>{

        const list=[...specialItemsList]

        const updatedItem = { ...list[index], status:e.target.checked };
        list[index] = updatedItem;
        dispatch(storeSpecialItemList(list))
   
        let editPayload={
            status:e.target.checked,
            _id:row?._id
        }


        editListSpecialItemsAPI(editPayload,InitialAPICalls)
    }

    const singleListClick=(row)=>()=>{
        let actions=()=>{
            navigate("create")
            localStorage.setItem("singleViewById",row?._id)
        }
        specialItemsSingleView({id:row?._id},actions)
    }
    
    const clickDeleteList=(id)=>()=>{
        deleteListSpecialItemsAPI({_id:id},InitialAPICalls)
    }
    useEffect(()=>{
        InitialAPICalls()
    },[])
    return (
    <div className="global-page-parent-container special-items-ctn">
        <div
            className="global-white-bg-container special-items-head justify-space-between">
            <h3>Special Items</h3>
            <button
            onClick={createBtnClicked}
            className="create-button-blue">Create</button>
        </div>
        <div className='lists'>
            {
            tableLoading?
            (
            [...Array(3)].map((r)=>(
                <div key={r} className="list-cards">
                    <Skeleton width="100%" height={"40px"} />
                    <div className="list-card-items">
                        {
                            [...Array(5)].map((r) => (
                                <p><Skeleton width={"100%"} /></p>
                            ))
                        }
                    </div>
                </div>
            ))
           )
            
            :
            specialItemsList.length!==0?
            specialItemsList.map((r,i)=>(
                <div 
                key={i} className={`list-cards ${!r.status && "disabled-lists"}` }>
                <div className="justify-space-between">
                    <p 
                    onClick={singleListClick(r)}
                    className='head'>{r?.name}</p>
                    <div className="btn-ctn">
                        <input
                        className="toggle-checkbox"
                        type={"checkbox"}
                        checked={r?.status}
                        onChange={editList(r,i)}
                        name="purchaseReport"
                    />
                    {
                        r?.isCombo && <ComboPriceTag amount={Number(r?.comboPrice)?.toFixed(decimalPosition)}/>
                    }
                    </div>
                </div>

                <div 
                onClick={singleListClick(r)}
                className="list-card-items">
                    {
                        r?.productList.map((item)=>(
                            <div className='justify-space-between' style={{border:"none"}}>
                                <p key={item?._id}>{item?.productName}</p>
                                
                                <p>{(r?.isCombo===true)? item?.qty:item?.offerPrice}</p>
                            </div>
                        ))
                    }
                </div>
                </div>
            ))
            :
            <div className="center-div" style={{ height: "30vh" ,backgroundColor:"#fff"}}>
                No Data
            </div>
            
            }  
        </div>
    </div>
  )
}

export default SpecialItems