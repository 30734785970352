import React from 'react'
import img1 from "../../../../Assets/Images/9616736.png"
import { useNavigate } from 'react-router-dom';
import "../../../../css/Account/createInvoice/accounting.css"
const OpeningBalance = () => {
    const navigate = useNavigate();

    const CreateOpeningBalance = () => {
        navigate("/userdashboard/accounts/accounting/openingBalance/create");
      };
    return (
        <div className="global-page-parent-container">
            <div className="global-white-bg-container">Opening Balance</div>
            <div className='opening-container'>
                <div className='image-div'>
                    <img src={img1} alt=""  style={{height:"298px"}}/>
                    <button onClick={CreateOpeningBalance} className="create-button-blue">
              Create
            </button>
                </div>
            </div>
        </div>
    )
}

export default OpeningBalance