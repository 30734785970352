import React from 'react'
import { useSelector } from 'react-redux';

const Payment = () => {
    const shiftReportpayment = useSelector(
        (state) => state.posReportshiftSlice.shiftReporPayment);
const decimalPosition = localStorage.getItem("decimalPosition");

        const formatDate = (dateTimeString) => {
            return dateTimeString?.split(' ')[0]; // Split the string by space and return the first part (the date)
        };
  return (
    <div className="new-global-table-container" style={{ paddingTop: "0 42px" }}>
<table>
    <thead>
        <tr>
            <th>Date</th>
            <th>Payment Reff</th>
            <th>Order Reff</th>
            <th>Customer</th>
            <th>Payment Method</th>
            <th> Amount</th>
            
        </tr>
    </thead>
    <tbody>
        {shiftReportpayment?.sale!==undefined ? shiftReportpayment?.sale?.map((r, i) => (
            <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                <td style={{ border: "none", height: "29px" }}>{formatDate(r.paymentDate)}</td>
                <td style={{ border: "none", height: "29px" }}>{r.name}</td>
                <td style={{ border: "none", height: "29px" }}>{r.orderName}</td>
                <td style={{ border: "none", height: "29px" }}>{r.customerName}</td>
                <td style={{ border: "none", height: "29px" }}>{r.type}</td>
                <td style={{ border: "none", height: "29px" }}>{r.amount}</td>
                
            </tr>
        )):(
            <tr>
                <td colSpan={6} >NO DATA</td>
            </tr>
        )}
    </tbody>
    <tfoot>
        <tr style={{backgroundColor:"rgb(150 148 148)"}}>
           <td colSpan={5} style={{textAlignLast:"end",paddingInline:"75px"}}>TOTAL</td> 
           <td style={{textAlignLast:"center"}}>{shiftReportpayment?.sale?.reduce((acc, current) => acc + Number(current?.amount || 0), 0)?.toFixed(decimalPosition)}</td>
        </tr>
    </tfoot>
</table>
</div>
  )
}

export default Payment