import React from "react";
import Badge from "@mui/material/Badge";
import styled from "@emotion/styled";

const StyledBadge = styled(Badge)(({ theme, isActive, activeColor,animate }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: isActive
      ? activeColor
        ? activeColor
        : "#0022ff"
      : "transparent",
    color: isActive ? (activeColor ? activeColor : "#0022ff") : "transparent",
    boxShadow: isActive
      ? `0 0 0 2px ${theme.palette.background.paper}`
      : "none", // Remove boxShadow if isActive is true
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: animate ? "none" : "ripple 1.2s infinite ease-in-out", // Remove animation if isActive is true
      border: animate ? "none" : "1px solid currentColor", // Remove border if isActive is true
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const StyledBadgeDot = ({ isActive, ActiveColor, animate }) => {
  return (
    <div style={{ padding: "5px", minWidth: "20px", minHeight: "30px" }}>
      <StyledBadge
       animate={animate}
        isActive={isActive||true}
        activeColor={ActiveColor}
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        variant="dot"
      />
    </div>
  );
};

export default StyledBadgeDot;
