import { createSlice } from "@reduxjs/toolkit";

export const advertisementSlice = createSlice({
  name: "advertisementSlice",
  initialState: {
  displayAdvertisement:null,
  alertMessage:null,
  loading:false,
  isEditAdv:false
  
  },

  reducers: {
    setDisplayAdvertisementData: (state, action) => {
      state.displayAdvertisement   = action.payload
    },
    setAlertMessage : (state,action) => {
      state.alertMessage = action.payload
    },
    setLoading : (state,action) => {
      state.loading = action.payload
    },
    setIsEditAdv : (state,action) => {
      state.isEditAdv = action.payload
    }

  },
});

export const { setDisplayAdvertisementData ,setAlertMessage,setLoading,setIsEditAdv} = advertisementSlice.actions;

export default advertisementSlice.reducer;
