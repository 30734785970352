import { Autocomplete, MenuItem, Select, Switch, TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { viewAllAllowedBranchesAPICall, viewAllBranchesAPICall } from "../../../../../../API/Settings/Company Settings/companySettingsAPI";
import {
  getAllDepartmentAPICall,
  getAllDesignationAPICall,
} from "../../../../../../API/Staff/HRM/departmentAndDesignationAPI";
import { addEmployeeCompanyAndBankSettingsAPICall } from "../../../../../../API/Staff/HRM/employeeAPI";
import ErrorSnackbar from "../../../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../../../Single Components/SnackBars/SuccessSnackbar";

// css
//import '../../../../../../css/Staff/HRM/Tab/empPersonalInfo.css'

export const EmpCompanyInfo = (props) => {
  const { singleEmpData, updateListener } = props;
  const currentEmpId = localStorage.getItem("EMP_ID");
  //*Redux State
  //Department List
  const allDepartmentList = useSelector(
    (state) => state.allDepartmentSlice.value
  );
  //Designation List
  const allDesignationList = useSelector(
    (state) => state.allDesignationListSlice.value
  );
  //all branches List
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // all allowed branches list
  const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const userRole=useSelector((state)=>state.userRoleSlice.value)

  //Employee company info form
  const empCompanyInfoInitialState = {
    empId: singleEmpData?.emp_id,
    dpt: singleEmpData?.department,
    desig: singleEmpData?.designation,
    doj: singleEmpData?.date_of_join,
    dol:
      singleEmpData?.date_of_leaving === null
        ? ""
        : singleEmpData?.date_of_leaving,
    workHours: singleEmpData?.workHour,
    outletLoc: singleEmpData?.outletLocation,
    salaryType: singleEmpData?.salaryType,
    monthlySalary: singleEmpData?.monthlySalary,
    contractFrom: singleEmpData?.contractPeriodFrm,
    contractTo:
      singleEmpData?.contractPeriodTo === null
        ? ""
        : singleEmpData?.contractPeriodTo,
    status: singleEmpData?.status,
    // allowedLoc:singleEmpData?.allowBranches,
    // allowedLoc:[],
    allowedLocFilter:[]
  
  };
  const [empCompanyInfoForm, setEmpCompanyInfoForm] = useState(
    empCompanyInfoInitialState
  );
  const [allowedLoc,setAllowedLoc]=useState([])
  const [allowedBranchArray,setAllowedBranchArray]=useState([])
  const [allBranchArray,setAllBranchArray]=useState([])




  useEffect(()=>{
    
    if(singleEmpData && allBranchesList){
      let data = allBranchesList?.filter((obj)=>{
        return singleEmpData?.allowBranches?.some(item1 => item1.outletLocation === obj._id);
      })
      setAllowedLoc(data?data:[])}
  },[singleEmpData,allBranchesList])


  // console.log(singleEmpData);
  // console.log(allowedLoc);

  //Filtered Designation State
  const [filteredDesignation, setFilteredDesignation] = useState([]);
  //Snackbar states
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState();
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState();
  const [snackMsg, setSnackMsg] = useState();

  //Initial API Calls
  useEffect(() => {
    getAllDepartmentAPICall();
    getAllDesignationAPICall();
    viewAllAllowedBranchesAPICall({ employeeId: loginResponse?.data?._id });
  }, []);

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnackbar(false);
  };
  //Close ErrorSnackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };
  //*useEffects
  //Call designation Filter function
  useEffect(() => {
    filterDesignationByDepartment();
  }, [empCompanyInfoForm.dpt,allDesignationList]);

  console.log(filteredDesignation);
  // filter Designation as per department selected
  const filterDesignationByDepartment = () => {
    let filteredDesignation = allDesignationList?.filter(
      (obj, i) => obj?.departmentId === empCompanyInfoForm.dpt
    );
    setFilteredDesignation(filteredDesignation);
  };

  //*onChange Fn
  const getUpdatedEmpCompanyInfo = (key) => (e,newValue) => {
    const { value, checked } = e.target;

    if (key === "dpt") {
      setEmpCompanyInfoForm({ ...empCompanyInfoForm, dpt: value });
    }
    if (key === "desig") {
      setEmpCompanyInfoForm({ ...empCompanyInfoForm, desig: value });
    }
    if (key === "doj") {
      setEmpCompanyInfoForm({ ...empCompanyInfoForm, doj: value });
    }
    if (key === "dol") {
      setEmpCompanyInfoForm({ ...empCompanyInfoForm, dol: value });
    }
    if (key === "outletLoc") {
      setEmpCompanyInfoForm({ ...empCompanyInfoForm, outletLoc: value });
    }
   
    if (key === "salaryType") {
      setEmpCompanyInfoForm({ ...empCompanyInfoForm, salaryType: value });
    }
    if (key === "monthlySalary") {
      setEmpCompanyInfoForm({ ...empCompanyInfoForm, monthlySalary: value });
    }
    if (key === "contractFrom") {
      setEmpCompanyInfoForm({ ...empCompanyInfoForm, contractFrom: value });
    }
    if (key === "contractTo") {
      setEmpCompanyInfoForm({ ...empCompanyInfoForm, contractTo: value });
    }
    if (key === "status") {
      setEmpCompanyInfoForm({ ...empCompanyInfoForm, status: checked });
    }
  };

console.log(allowedLoc);
useEffect(()=>{
  let filterData=allowedLoc?.map((obj)=>{
      return {
        outletLocation:obj?._id
      }
  })
  setEmpCompanyInfoForm({...empCompanyInfoForm,allowedLocFilter:filterData})
},[allowedLoc])

  //*onClick Fn
  const bodyToUpdateCompanySettings = {
    id: currentEmpId,
    department: empCompanyInfoForm.dpt,
    designation: empCompanyInfoForm.desig,
    date_of_join: empCompanyInfoForm.doj,
    workHour: parseFloat(empCompanyInfoForm.workHours),
    salaryType: empCompanyInfoForm.salaryType,
    monthlySalary: parseFloat(empCompanyInfoForm.monthlySalary),
    contractPeriodFrm: empCompanyInfoForm.contractFrom,
    contractPeriodTo: empCompanyInfoForm.contractTo,
    status: empCompanyInfoForm.status,
    ac_holder: singleEmpData?.ac_holder,
    ac_no: singleEmpData?.ac_no,
    bank: singleEmpData?.bank,
    bank_code: singleEmpData?.bank_code,
    bankLocation: singleEmpData?.bankLocation,
    pan: singleEmpData?.pan,
    qrcode: singleEmpData?.qrcode,
    outletLocation: empCompanyInfoForm.outletLoc,
    allowBranches:empCompanyInfoForm?.allowedLocFilter
  };
  const clickEmpCompanyInfoUpdateBtn = () => {
    addEmployeeCompanyAndBankSettingsAPICall(
      bodyToUpdateCompanySettings,
      setOpenSuccessSnackbar,
      setOpenErrorSnackbar,
      setSnackMsg
    );
  };

    // allowed branch
    useEffect(()=>{
      let data=allowedBranchList?.filter((obj)=>{
        if(obj?._id!==empCompanyInfoForm.outletLoc){
          return obj
        }
      })
      setAllowedBranchArray(data?data:allowedBranchList)
    },[empCompanyInfoForm.outletLoc,allowedBranchList])
  
    // all branch
    useEffect(()=>{
      let data=allBranchesList?.filter((obj)=>{
        if(obj?._id!==empCompanyInfoForm.outletLoc){
          return obj
        }
      })
      setAllBranchArray(data?data:allBranchesList)
    },[empCompanyInfoForm.outletLoc,allBranchesList])
  return (
    <>
      <div className="employee-personal-info-main-container">
        <div className="employee-personal-info-container">
          <div className="employee-personal-info-single-input-container">
            <div className="global-single-input add-employee-input">
              <p>Employee ID</p>
              <input type="text" value={empCompanyInfoForm.empId} />
            </div>
            <div className="global-single-input add-employee-input">
              <p>Department</p>
              <Select
                value={empCompanyInfoForm.dpt}
                onChange={getUpdatedEmpCompanyInfo("dpt")}
              >
                {allDepartmentList?.map((item, i) => (
                  <MenuItem value={item?._id} key={i}>
                    {item?.departmentName}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="global-single-input add-employee-input">
              <p>Designation</p>
              <Select
                value={empCompanyInfoForm.desig}
                onChange={getUpdatedEmpCompanyInfo("desig")}
              >
                <MenuItem value="0">-select designation-</MenuItem>
                {filteredDesignation?.length > 0 ? (
                  filteredDesignation?.map((item, i) => (
                    <MenuItem value={item?._id} key={i}>
                      {item?.position}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>No Designation</MenuItem>
                )}
              </Select>
            </div>
            <div className="global-single-input add-employee-input">
              <p>Date of Joining</p>
              <input
                type="date"
                value={empCompanyInfoForm.doj}
                onChange={getUpdatedEmpCompanyInfo("doj")}
              />
            </div>
            <div className="global-single-input add-employee-input">
              <p>Date of Leaving</p>
              <input
                type="date"
                value={empCompanyInfoForm.dol}
                onChange={getUpdatedEmpCompanyInfo("dol")}
              />
            </div>
            <div className="global-single-input add-employee-input">
              <p>Work Hours</p>
              <input
                type="text"
                value={empCompanyInfoForm.workHours}
                onChange={getUpdatedEmpCompanyInfo("workHours")}
              />
            </div>
            <div className="global-single-input add-employee-input">
              <p>Branch</p>
              <Select
                value={empCompanyInfoForm.outletLoc}
                onChange={getUpdatedEmpCompanyInfo("outletLoc")}
              >
                {allBranchesList?.map((item, i) => (
                  <MenuItem value={item?._id} key={i}>
                    {`${item?.storeCode}-${item?.branchName}`}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="global-single-input add-employee-input auto-complete" style={{margin:"2% 5% 1% 1%",borderRadius:"4px"}}>
            <p>Allowed Branches</p>
             <Autocomplete
                    multiple
                    options={userRole==="user"?(allowedBranchList || []):(allBranchesList || [])}
                    getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}
                    renderInput={(params)=>(
                      <TextField {...params} placeholder="select"/>
                    )}
                    value={allowedLoc}
                    onChange={(e,newValue)=>setAllowedLoc(newValue)}
                
                  />
        </div>

          </div>
          <button className="btn btn-primary employee-upadte-btn btn-font-size-less" onClick={clickEmpCompanyInfoUpdateBtn}>
            Update
          </button>
        </div>
        <hr className="global-hr" />
        <div className="employee-personal-info-account-login-container">
          <h4 style={{ margin: "0 0 1% 0" }}>Salary</h4>
          <div className="employee-personal-info-single-input-container">
            <div className="global-single-input add-employee-input">
              <p>Type</p>
              <Select
                value={empCompanyInfoForm.salaryType}
                onChange={getUpdatedEmpCompanyInfo("salaryType")}
              >
                <MenuItem value="Full time">Full time</MenuItem>
                <MenuItem value="Part time">Part time</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </div>
            <div className="global-single-input add-employee-input">
              <p>Monthly Salary</p>
              <input
                type="text"
                value={empCompanyInfoForm.monthlySalary}
                onChange={getUpdatedEmpCompanyInfo("monthlySalary")}
              />
            </div>
          </div>
        </div>
        <div className="employee-personal-info-account-login-container">
          <h4 style={{ margin: "0 0 1% 0" }}>Contract Period</h4>
          <div className="employee-personal-info-single-input-container">
            <div className="global-single-input add-employee-input">
              <p>From</p>
              <input
                type="date"
                value={empCompanyInfoForm.contractFrom}
                onChange={getUpdatedEmpCompanyInfo("contractFrom")}
              />
            </div>
            <div className="global-single-input add-employee-input">
              <p>To</p>
              <input
                type="date"
                value={empCompanyInfoForm.contractTo}
                onChange={getUpdatedEmpCompanyInfo("contractTo")}
              />
            </div>
          </div>
        </div>
        <hr className="global-hr" />
        <div className="company-info-status-container employee-dashbord-company-info-container">
          <h5>Status:</h5>
          <div className="company-info-status-switch-container">
            <Switch
              label="active"
              defaultChecked
              checked={empCompanyInfoForm.status}
              onChange={getUpdatedEmpCompanyInfo("status")}
            />
            <p>Inactive&nbsp;/&nbsp;Active</p>
          </div>
        </div>
      </div>

      <SuccessSnackbar
        open={openSuccessSnackbar}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnackbar}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
