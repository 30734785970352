import axios from "axios";
import { HEADERS} from "../../../../../API/UrlAndPaths";
import store from "../../../../../Redux/store";
import { get_general_list, get_general_single_list, get_profit_loss_list, get_trial_Balance_List,get_balance_sheet_list} from "./TrailBalanceSlice";

// general ledger
export const GeneralLedgerListAPI =async (body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)
await axios
.post("account/generalLedger", body, HEADERS)
.then((res) => {
  if (res.status === 200) {
    store.dispatch(get_general_list({trailBalanceList:res.data}))
    setIsLoading!==undefined&&setIsLoading(false)
  }
})
.catch((err) => {
    store.dispatch(get_general_list({trailBalanceList:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)
    console.error(err.response);
});
};
export const TrailBalananceSingleListAPI =async (body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)
  await axios
  .post("account/ledegerBalanceOfAc", body, HEADERS)
  .then((res) => {
    if (res.status === 200) {
      store.dispatch(get_general_single_list({trailBalanceSingleList:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)
    }
  })
  .catch((err) => {
      store.dispatch(get_general_single_list({trailBalanceSingleList:undefined}))
      setIsLoading!==undefined&&setIsLoading(false)
      console.error(err.response);
  });
  };

  //Trial Balance

  export const trailBalanceListAPI =async (body,setIsLoading)=>{
    await axios
    .post("account/trailBalance", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_trial_Balance_List({trailBalance:res.data}))
        setIsLoading!==undefined&&setIsLoading(false)
      }
    })
    .catch((err) => {
        store.dispatch(get_trial_Balance_List({trailBalance:undefined}))
        setIsLoading!==undefined&&setIsLoading(false)
        console.error(err.response);
    });
    };

    //profit and loss
    
    export const profitAndLossAPIcall =async (body,isLoading)=>{
      await axios
      .post("account/profitOrLossReport", body, HEADERS)
      .then((res) => {
        if (res.status === 200) {
          store.dispatch( get_profit_loss_list({profitAndLossList:res.data}))
          isLoading(false)
        }
      })
      .catch((err) => {
          store.dispatch(get_trial_Balance_List({profitAndLossList:undefined}))
          console.error(err.response);
          isLoading(false)
      });
      };
    export const ViewBalanceSheetAPIcall =async (body,isLoading)=>{
      await axios
      .post("account/viewBalanceSheet", body, HEADERS)
      .then((res) => {
        if (res.status === 200) {
          store.dispatch( get_balance_sheet_list({balanceSheetList:res.data}))
          isLoading(false)
        }
      })
      .catch((err) => {
          store.dispatch(get_balance_sheet_list({balanceSheetList:undefined}))
          console.error(err.response);
          isLoading(false)
      });
      };