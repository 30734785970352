import {
  Autocomplete,
  Dialog,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
  TextField,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import draftRibbon from "../../../../Assets/statusRibbon/draft.png";
import reconciledRibbon from "../../../../Assets/statusRibbon/reconciled.svg";
import doneRibbon from "../../../../Assets/statusRibbon/done.png";
import cancelRibbon from "../../../../Assets/statusRibbon/cancel.png";
import pendingRibbon from "../../../../Assets/statusRibbon/pending.svg";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {
  actionType,
  bankReTable,
  brStatus,
  dateToMilliseconds,
  getFieldName,
  snackbarNotification,
} from "./bankReconciliationUtil";
import ImportFileDialog from "./components/ImportFilePopup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import {
  cancelBankReconciliationAPI,
  createBankReconciliationAPI,
  fetchBankReconciliationAPI,
  mapReconciliationAPI,
  postBankReconciliationAPI,
  resetToDraftBankReconciliationAPI,
  updateBankReconciliationAPI,
  uploadImportedBankReconciliationAPI,
  viewAllBankJournalsAPI,
  viewSingleBankReconciliationAPI,
  computeBankReconciliationAPI,
} from "./BankReconciliationAPI";
import { getCurrentTime, today } from "../../../../Js/Date";
import SnackbarNotification from "./components/SnackbarNotification";
import { setSnackbarNotification } from "./BankReconciliationSlice";
import { CreateVendorPayments } from "../Vendor/pages/CreateVendorPayments";
import { CreateCustomerPayment } from "../Customers/Payments/CreateCustomerPayment";
import ConfirmationDialog from "./components/popup/ConfirmationPopup";
import CancelConfirmationDialog from "./components/CancelConfirmationDialog/CancelConfirmationDialog";

export const CreateBankReconciliation = () => {
  //local storage
  const singleViewId = localStorage.getItem("singleViewByIdBankReconciliation");
  const decimalPosition = localStorage.getItem("decimalPosition");
  //router-dom
  const navigate = useNavigate();
  //redux
  const dispatch = useDispatch();
  const location = useLocation();

  // console.log("location data",location)

  const {
    singleViewData,
    bankReconciliationForm,
    bankJournalList,
    bankStatement,
    importFile,
  } = useSelector((state) => state.bankReconciliation);
  const { currencyDetails } = useSelector((state) => state.mainDashBoardSlice);

  //state
  const [open, setOpen] = useState(false);
  const [confirmationDialogPopup, setConfirmationDialogPopup] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState({
    open: false,
    row: null,
  });

  const errorInitialState = {
    reference: { error: false, errorMessage: "" },
    bank: { error: false, errorMessage: "" },
    date: { error: false, errorMessage: "" },
    bankBalance: { error: false, errorMessage: "" },
  };

  const [errorField, setErrorField] = useState(errorInitialState);

  //initial data
  const initialBankReconciliationState = {
    reference: "",
    bank: null,
    date: today,
    closingBalance: "",
    bankBalance: "",
    brStatus: brStatus.new,
    isEdit: false,
    tableData: {
      companyBook: [],
      bankStatement: null,
      reconciliated: [],
    },
    totalData: {
      bankBalance: 0,
      notReflectedInBank: 0,
      totalBankCharge: 0,
      companyBookReconciledBalance: 0,
    },
  };
  const [bankReconciliationData, setBankReconciliationData] = useState(
    initialBankReconciliationState
  );
  const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);

  const handleChange = (e, value, name) => {
    const updateValue = value === null ? null : e.target.value || value;

    const updatedData = {
      ...bankReconciliationData,
      [e.target.name || name]:
        e.target.name === "bankBalance"
          ? isNaN(parseFloat(updateValue))
            ? ""
            : parseFloat(updateValue)
          : updateValue,
    };

    // Update the errorField state based on the input value
    const updatedErrorField = {
      ...errorField,
      [e.target.name || name]: {
        error:
          updateValue === undefined ||
          updateValue === null ||
          updateValue === "",
        errorMessage:
          updateValue === undefined ||
          updateValue === null ||
          updateValue === ""
            ? `${getFieldName(e.target.name || name)} is required`
            : "",
      },
    };

    if (name === "bank" && updateValue === null) {
      updatedData.closingBalance = "";
      updatedData.date = "";
      updatedData.tableData.companyBook = [];
    }

    setBankReconciliationData(updatedData);
    setErrorField(updatedErrorField);
  };

  // console.log("bank re con", bankReconciliationData);
  //handle dialog
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseConfirmationDialogPopup = () => {
    setConfirmationDialogPopup(false);
  };

  const handlePopupConfirm = () => {
    setConfirmationDialogPopup(false);
    setOpenPaymentDialog({ open: false, row: null });
  };
  //handle save
  const handleSave = () => {
    // Validate all fields

    const isValueEmpty = (value) => {
      return value === null || value === undefined || value === "";
    };

    const updatedErrorField = {
      reference: {
        error: isValueEmpty(bankReconciliationData.reference),
        errorMessage: isValueEmpty(bankReconciliationData.reference)
          ? "Reference is required"
          : "",
      },
      bank: {
        error: isValueEmpty(bankReconciliationData.bank),
        errorMessage: isValueEmpty(bankReconciliationData.bank)
          ? "Bank is required"
          : "",
      },
      date: {
        error: isValueEmpty(bankReconciliationData.date),
        errorMessage: isValueEmpty(bankReconciliationData.date)
          ? "Date is required"
          : "",
      },
      bankBalance: {
        error: isValueEmpty(bankReconciliationData.bankBalance),
        errorMessage: isValueEmpty(bankReconciliationData.bankBalance)
          ? "Bank Balance is required"
          : "",
      },
    };

    // console.log(updatedErrorField);

    setErrorField(updatedErrorField);

    // If no errors, proceed with form submission
    const hasErrors = Object.values(updatedErrorField).some(
      (field) => field.error
    );

    if (!hasErrors) {
      const body = {
        reference: bankReconciliationData?.reference,
        journalId: bankReconciliationData?.bank._id,
        date: `${bankReconciliationData?.date} ${getCurrentTime()}`,
        closingBalance: bankReconciliationData?.closingBalance,
        bankBalance: bankReconciliationData?.bankBalance,
        companybook: bankReconciliationData?.tableData.companyBook,
        reconcileTable: [],
        bankstatement: [],
        bankbalance: 0,
        notReflectedInBank:
          bankReconciliationData?.totalData.notReflectedInBank,
        totalBankCharge: bankReconciliationData?.totalData.totalBankCharge,
        companybookReconciledBalance:
          bankReconciliationData.totalData.companyBookReconciledBalance,
      };
      if (!singleViewData) {
        //draft
        createBankReconciliationAPI({
          body: body,
        });
      } else {
        //edit
        setBankReconciliationData({
          ...bankReconciliationData,
          isEdit: !bankReconciliationData.isEdit,
        });
        if (bankReconciliationData.isEdit) {
          updateBankReconciliationAPI({
            body: { ...body, _id: singleViewData._id },
            isEdit: setBankReconciliationData,
          });
        }
      }
    }
  };

  //upload imported file
  const handleUploadFile = () => {
    if (singleViewData) {
      if (bankStatement.length > 0) {
        uploadImportedBankReconciliationAPI({
          body: {
            _id: singleViewData._id,
            table: bankStatement?.map((res, i) => {
              return {
                ...res,
                status: "Pending",
                date: dateToMilliseconds(res.date),
              };
            }),
          },
          setOpen: setOpen,
        });
      } else {
        dispatch(
          setSnackbarNotification({
            message: "Please upload bank statement file first",
            open: true,
            severity: snackbarNotification.warning,
          })
        );
      }
    }
  };

  //handle map
  const handleMapBtnActon = () => {
    if (singleViewData) {
      mapReconciliationAPI({ body: { _id: singleViewData._id } });
    }
  };

  // handle post
  const handlePost = () => {
    // console.log("called post");
    postBankReconciliationAPI({
      body: {
        _id: singleViewData._id,
        postDate: `${today} ${getCurrentTime()}`,
      },
      // isEdit: setBankReconciliationData,
    });
  };

  // handle cancel
  const handleCancel = () => {
    console.log("handle cancel");
    cancelBankReconciliationAPI({
      body: { _id: singleViewData._id, isEdit: setBankReconciliationData },
    });
  };

  // reset to draft
  const handleResetToDraft = () => {
    resetToDraftBankReconciliationAPI({
      body: { _id: singleViewData._id, isEdit: setBankReconciliationData },
      journalId: bankReconciliationData.bank._id,
      date: `${bankReconciliationData.date} ${getCurrentTime()}`,
    });
  };

  //hadle cancel

  const handleCancelConfirm = () => {
    // Perform cancel operation here
    console.log("Cancel confirmed");
    setOpenCancelConfirmation(false);
    handleCancel();
  };

  const handleCancelCancel = () => {
    console.log("Cancel dismissed");
    setOpenCancelConfirmation(false);
  };

  const handleShowCancelConfirmation = () => {
    setOpenCancelConfirmation(true);
  };

  //handle button actions
  const handleBtnAction = (type) => {
    switch (type) {
      case actionType.edit:
        handleSave();
        break;
      case actionType.save:
        handleSave();
        break;
      case actionType.back:
        navigate("/userdashboard/accounts/Reconciliation/BankReconciliation");
        localStorage.removeItem("singleViewById");
        localStorage.removeItem("singleViewByIdBankReconciliation");
        break;
      case actionType.map:
        handleMapBtnActon();
        break;
      case actionType.post:
        handlePost();
        break;
      case actionType.restToDraft:
        handleResetToDraft();

        break;
      case actionType.cancel:
        handleShowCancelConfirmation();

        break;

      default:
        break;
    }
  };

  // handle table input change
  const handleTableChange = (e, row, index, tableType) => {
    const { name, value } = e.target;
    const parsedValue =
      name === "charge"
        ? isNaN(parseFloat(value))
          ? ""
          : parseFloat(value)
        : value;

    if (tableType === bankReTable.cb) {
      const newTableData = bankReconciliationData?.tableData?.companyBook?.map(
        (res, i) => (i === index ? { ...res, [name]: parsedValue } : res)
      );

      setBankReconciliationData((prevState) => ({
        ...prevState,
        tableData: {
          ...prevState.tableData,
          companyBook: newTableData,
        },
      }));
    }
  };

  // handle  payment action
  const handlePaymentAction = (row) => {
    setOpenPaymentDialog({ open: true, row: row });
  };

  const handleClosePaymentDialog = () => {
    setOpenPaymentDialog({ open: false, row: null });
  };
  const handleClosePaymentDialogAction = () => {
    setConfirmationDialogPopup(true);
    // window.location.reload();
  };

  //-----render actions-----

  //update state
  useEffect(() => {
    if (
      bankReconciliationForm &&
      bankReconciliationData.brStatus === brStatus.new
    ) {
      setBankReconciliationData((prev) => ({
        ...prev,
        closingBalance: bankReconciliationForm?.closingBalance || 0,
        tableData: {
          ...prev.tableData,
          companyBook: bankReconciliationForm?.companyBook || [],
        },
        totalData: {
          ...prev.totalData,
          notReflectedInBankValue:
            bankReconciliationForm?.companyBook.reduce(
              (acc, curr) => acc + (curr.debit - curr.credit),
              0
            ) || 0,
        },
      }));
    }
  }, [bankReconciliationForm]);

  useEffect(() => {
    if (bankReconciliationData.isEdit) {
      setBankReconciliationData((prev) => ({
        ...prev,
        closingBalance: bankReconciliationForm.closingBalance,
        tableData: {
          ...prev.tableData,
          companyBook: bankReconciliationForm.companyBook,
        },
      }));
    }
  }, [bankReconciliationForm]);

  //initial render api
  useEffect(() => {
    viewAllBankJournalsAPI();
  }, []);

  // single view
  useEffect(() => {
    if (singleViewData) {
      setBankReconciliationData((prev) => ({
        ...prev,
        reference: singleViewData?.reference || "",
        bank: bankJournalList.filter(
          (res) => res._id === singleViewData?.journalId
        )?.[0],
        date: singleViewData?.date,
        closingBalance: singleViewData?.closingBalance,
        bankBalance: singleViewData?.bankBalance,
        brStatus:
          singleViewData?.status === "Drafted"
            ? brStatus.draft
            : singleViewData?.status === "In-progress"
            ? brStatus.pending
            : singleViewData?.status === "Cancel"
            ? brStatus.cancel
            : singleViewData?.status === "Posted"
            ? brStatus.done
            : "",

        isEdit: false,
        tableData: {
          companyBook:
            singleViewData?.status !== "Cancel"
              ? singleViewData?.companybook?.length > 0
                ? singleViewData?.companybook
                : bankReconciliationForm?.companyBook
              : singleViewData?.companybook,
          bankStatement: singleViewData?.bankstatement,
          reconciliated: singleViewData?.reconcileTable,
        },
        totalData: {
          notReflectedInBank:
            singleViewData?.companybook.reduce(
              (acc, curr) => acc + (curr.debit - curr.credit),
              0
            ) || 0,
          companyBookReconciledBalance:
            singleViewData?.reconcileTable.reduce(
              (acc, curr) => acc + (curr.debit - curr.credit - curr.charge),
              0
            ) || 0,
          totalBankCharge:
            singleViewData?.reconcileTable.reduce(
              (acc, curr) => acc + curr.charge,
              0
            ) || 0,
        },
      }));
      setErrorField(errorInitialState);
    } else {
      setBankReconciliationData(initialBankReconciliationState);
      if (singleViewId) {
        viewSingleBankReconciliationAPI({
          _id: singleViewId,
        });
      }
    }
  }, [singleViewData]);

  const prevBank = useRef(bankReconciliationData.bank);
  const prevDate = useRef(bankReconciliationData.date);

  useEffect(() => {
    const bankChanged = bankReconciliationData.bank !== prevBank.current;
    const dateChanged = bankReconciliationData.date !== prevDate.current;

    const shouldFetchApi =
      bankReconciliationData.bank &&
      bankReconciliationData.date &&
      ((bankReconciliationData.brStatus === brStatus.new &&
        (bankChanged || dateChanged)) ||
        (bankReconciliationData.isEdit && (bankChanged || dateChanged)));

    if (shouldFetchApi) {
      fetchBankReconciliationAPI({
        body: {
          journalId: bankReconciliationData.bank._id,
          date: `${bankReconciliationData.date} ${getCurrentTime()}`,
        },
      });
    }

    // Update refs for the next render
    prevBank.current = bankReconciliationData.bank;
    prevDate.current = bankReconciliationData.date;
  }, [
    bankReconciliationData.bank,
    bankReconciliationData.date,
    bankReconciliationData.brStatus,
    bankReconciliationData.isEdit,
    fetchBankReconciliationAPI,
    getCurrentTime,
    brStatus.new,
  ]);

  //api for compute bank reconciliation
  const computeBankReconciliation = () => {
    if (singleViewData) {
      computeBankReconciliationAPI({ body: { _id: singleViewData._id } });
    }
  };

  return (
    <>
      <div className="crbr-main-container">
        <div className="crbr-main-head crbr-cmn">
          <span>Bank Reconciliation</span>
        </div>
        <div className="crbr-action-head crbr-cmn">
          <div className="crbr-container">
            <div className="crbr-button-group">
              <div className="crbr-wrapper">
                {/* back-btn */}
                <IconButton
                  className="crbr-button crb-back-btn crbr-edit-btn"
                  color="primary"
                  onClick={() => handleBtnAction(actionType.back)}
                >
                  <i class="bi bi-chevron-left"></i>
                </IconButton>
                {/* edit-btn */}
                {bankReconciliationData.brStatus !== brStatus.new &&
                  bankReconciliationData.brStatus !== brStatus.cancel &&
                  bankReconciliationData.brStatus !== brStatus.done && (
                    <IconButton
                      className="crbr-button crbr-edit-btn"
                      color="primary"
                      onClick={() => handleBtnAction(actionType.edit)}
                    >
                      <i
                        class={
                          bankReconciliationData.isEdit
                            ? "bi bi-check2-square edit-icon"
                            : "bi bi-pencil-square edit-icon1"
                        }
                      ></i>{" "}
                    </IconButton>
                  )}
                {/* save-btn */}
                {bankReconciliationData?.brStatus === brStatus.new &&
                  bankReconciliationData?.isEdit === false && (
                    <IconButton
                      onClick={() => handleBtnAction(actionType.save)}
                      className="crbr-button"
                      color="primary"
                    >
                      <i class="bi bi-floppy save-icon"></i>{" "}
                    </IconButton>
                  )}
                {/* import-btn */}
                {bankReconciliationData.brStatus === brStatus.draft &&
                  bankReconciliationData.brStatus !== brStatus.cancel &&
                  bankReconciliationData.brStatus !== brStatus.pending &&
                  bankReconciliationData.tableData.reconciliated.length === 0 &&
                  bankReconciliationData.brStatus !== brStatus.done &&
                  bankReconciliationData?.isEdit === false && (
                    <button
                      onClick={handleOpen}
                      className="crbr-button crcb-cmn-btn"
                      style={{ "--background-cl": "#ff8104" }}
                    >
                      <span>IMPORT </span> <ExitToAppIcon />
                    </button>
                  )}

                {/* reset to draft-btn */}
                {bankReconciliationData.brStatus !== brStatus.new &&
                  bankReconciliationData.brStatus === brStatus.cancel &&
                  bankReconciliationData.brStatus !== brStatus.done &&
                  bankReconciliationData?.isEdit === false && (
                    <button
                      onClick={() => handleBtnAction(actionType.restToDraft)}
                      className="crbr-button crcb-cmn-btn"
                    >
                      <span>RESET TO DRAFT</span>
                    </button>
                  )}
                {/* post -btn */}
                {bankReconciliationData.brStatus !== brStatus.new &&
                  bankReconciliationData.brStatus !== brStatus.cancel &&
                  bankReconciliationData.brStatus !== brStatus.done &&
                  bankReconciliationData?.isEdit === false && (
                    <button
                      onClick={() => handleBtnAction(actionType.post)}
                      className="crbr-button crcb-cmn-btn crbr-primary"
                    >
                      <span>POST</span>
                    </button>
                  )}
                {/* map-btn */}
                {bankReconciliationData.brStatus !== brStatus.new &&
                  bankReconciliationData.brStatus !== brStatus.done &&
                  bankReconciliationData.brStatus !== brStatus.cancel &&
                  bankReconciliationData?.isEdit === false && (
                    <button
                      onClick={() => handleBtnAction(actionType.map)}
                      className="crbr-button crcb-cmn-btn crbr-primary"
                    >
                      <span>MAP</span>
                    </button>
                  )}
                {/* cancel-btn */}
                {bankReconciliationData.brStatus !== brStatus.new &&
                  bankReconciliationData.brStatus !== brStatus.done &&
                  bankReconciliationData.brStatus !== brStatus.cancel &&
                  bankReconciliationData?.isEdit === false && (
                    <button
                      onClick={() => handleBtnAction(actionType.cancel)}
                      className="crbr-button crcb-cmn-btn"
                      style={{ "--background-cl": "#ff1804" }}
                    >
                      <span>CANCEL</span>
                    </button>
                  )}
              </div>
            </div>
            {/* --------------------inputs----------- */}
            <div className="crbr-input-group">
              {/* reference */}
              <FormControl
                variant="outlined"
                size="small"
                error={errorField.reference.error}
                disabled={
                  bankReconciliationData?.isEdit === false && singleViewData
                }
              >
                <InputLabel shrink>Reference*</InputLabel>
                <OutlinedInput
                  value={bankReconciliationData.reference}
                  label="Reference*"
                  name="reference"
                  notched
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
                <FormHelperText variant="standard">
                  {errorField.reference.errorMessage}
                </FormHelperText>
              </FormControl>
              {/* bank */}
              <FormControl
                variant="outlined"
                size="small"
                color="info"
                sx={{ width: "10%", minWidth: 150 }}
                error={errorField.bank.error}
              >
                <InputLabel shrink>Bank</InputLabel>
                {(bankReconciliationData?.isEdit === false &&
                  bankReconciliationData.brStatus !== brStatus.new) ||
                (bankReconciliationData.brStatus !== brStatus.draft &&
                  singleViewData) ? (
                  <TextField
                    value={bankReconciliationData.bank?.journalName || ""}
                    label="Bank"
                    size="small"
                    notched
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                ) : (
                  <Autocomplete
                    size="small"
                    value={bankReconciliationData.bank || null}
                    name="bank"
                    onChange={(event, newValue) =>
                      handleChange(event, newValue, "bank")
                    }
                    options={bankJournalList.filter(
                      (option) =>
                        option?._id !== bankReconciliationData?.bank?._id
                    )}
                    getOptionLabel={(option) => option?.journalName || ""}
                    freeSolo
                    clearIcon={<ClearIcon />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Bank"
                        notched
                        InputLabelProps={{ shrink: true }}
                        error={errorField.bank.error}
                      />
                    )}
                  />
                )}

                <FormHelperText variant="standard">
                  {errorField.bank.errorMessage}
                </FormHelperText>
              </FormControl>
              {/* date */}
              <FormControl
                variant="outlined"
                size="small"
                error={errorField.date.error}
                disabled={
                  (bankReconciliationData?.isEdit === false &&
                    bankReconciliationData.brStatus !== brStatus.new) ||
                  (bankReconciliationData.brStatus !== brStatus.draft &&
                    singleViewData)
                }
              >
                <InputLabel shrink>Date*</InputLabel>
                <OutlinedInput
                  value={bankReconciliationData.date}
                  label="Date*"
                  name="date"
                  type="date"
                  notched
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
                <FormHelperText variant="standard">
                  {errorField.date.errorMessage}
                </FormHelperText>
              </FormControl>
              {/* Closing Balance */}
              <FormControl variant="outlined" size="small" disabled={true}>
                <InputLabel shrink>Closing Balance*</InputLabel>
                <OutlinedInput
                  value={bankReconciliationData.closingBalance}
                  label="Closing Balance"
                  name="closingBalance"
                  notched
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  disabled={true}
                />
              </FormControl>
              {/* Bank Balance */}
              <FormControl
                variant="outlined"
                size="small"
                error={errorField.bankBalance.error}
                disabled={
                  bankReconciliationData?.isEdit === false && singleViewData
                }
              >
                <InputLabel shrink>Bank Balance*</InputLabel>
                <OutlinedInput
                  value={bankReconciliationData.bankBalance}
                  label="Bank Balance*"
                  name="bankBalance"
                  notched
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
                <FormHelperText variant="standard">
                  {errorField.bankBalance.errorMessage}
                </FormHelperText>
              </FormControl>
            </div>
            {/* --------status-ribbon---------- */}
            {bankReconciliationData.brStatus !== brStatus.new && (
              <div className="crbr-badge">
                <img
                  src={
                    bankReconciliationData.brStatus === brStatus.draft
                      ? draftRibbon
                      : bankReconciliationData.brStatus === brStatus.reconciled
                      ? reconciledRibbon
                      : bankReconciliationData.brStatus === brStatus.cancel
                      ? cancelRibbon
                      : bankReconciliationData.brStatus === brStatus.done
                      ? doneRibbon
                      : bankReconciliationData.brStatus === brStatus.pending
                      ? pendingRibbon
                      : ""
                  }
                  alt="ribbon"
                />
              </div>
            )}
          </div>
        </div>
        {bankReconciliationData?.tableData?.reconciliated?.length > 0 && (
          <div className="crbr-cmn">
            <div className="crbr-main-header">
              <span>RECONCILIATED TRANSACTION</span>
            </div>
            <div className="crbr-show-table">
              <CrbrTable
                tableModel={bankReTable.rt}
                data={bankReconciliationData?.tableData?.reconciliated || []}
              />
            </div>
          </div>
        )}
        {bankReconciliationData?.tableData?.companyBook?.length > 0 && (
          <div className="crbr-cmn">
            <div className="crbr-main-header">
              <span>COMPANY BOOK</span>
            </div>
            <div className="crbr-show-table">
              <CrbrTable
                handleTableChange={handleTableChange}
                tableModel={bankReTable.cb}
                data={bankReconciliationData?.tableData?.companyBook || []}
                disabled={
                  bankReconciliationData?.isEdit === false && singleViewData
                }
              />
            </div>
          </div>
        )}
        {bankReconciliationData?.tableData?.bankStatement?.length > 0 && (
          <div className="crbr-cmn">
            <div className="crbr-main-header">
              <span> BANK STATEMENT</span>
            </div>
            <div className="crbr-show-table">
              <CrbrTable
                data={bankReconciliationData?.tableData?.bankStatement || []}
                paymentBtn={
                  bankReconciliationData?.brStatus !== brStatus.cancel &&
                  bankReconciliationData?.brStatus !== brStatus.done
                    ? true
                    : false
                }
                tableModel={bankReTable.bs}
                handlePaymentAction={handlePaymentAction}
                status={bankReconciliationData?.brStatus}
              />
            </div>
          </div>
        )}
        {bankReconciliationData.brStatus !== brStatus.new && (
          <div className="crbr-cmn crbr-bottom-list">
            <ul>
              <li>
                <span>BANK BALANCE</span>
                <span>
                  {currencyDetails?.currencyCode}{" "}
                  {Number(bankReconciliationData.bankBalance)?.toFixed(
                    decimalPosition
                  )}
                </span>
              </li>
              <li>
                <span>NOT REFLECTED IN BANK</span>
                <span>
                  {currencyDetails?.currencyCode}{" "}
                  {Number(
                    bankReconciliationData.totalData.notReflectedInBank
                  )?.toFixed(decimalPosition)}
                </span>
              </li>
              <li>
                <span>Total Bank charges</span>
                <span>
                  {currencyDetails?.currencyCode}{" "}
                  {Number(
                    bankReconciliationData.totalData.totalBankCharge
                  )?.toFixed(decimalPosition)}
                </span>
              </li>
              <li>
                <span>COMPANY BOOK RECONCILED BALANCE</span>
                <span>
                  {currencyDetails?.currencyCode}{" "}
                  {Number(
                    bankReconciliationData.totalData
                      .companyBookReconciledBalance
                  )?.toFixed(decimalPosition)}
                </span>
              </li>
            </ul>
          </div>
        )}
        <SnackbarNotification />
        <ImportFileDialog
          state={bankReconciliationData}
          setState={setBankReconciliationData}
          open={open}
          handleClose={handleClose}
          handleUploadFile={handleUploadFile}
        />
        <Dialog
          maxWidth="lg"
          open={openPaymentDialog.open}
          onClose={handleClosePaymentDialogAction}
          // onClose={handleClose}
        >
          {openPaymentDialog?.row?.credit > 0 ? (
            <CreateVendorPayments
              backBtn={false}
              handleClosePaymentDialog={handleClosePaymentDialog}
              computeBankReconciliation={computeBankReconciliation}
            />
          ) : (
            <CreateCustomerPayment
              backBtn={false}
              handleClosePaymentDialog={handleClosePaymentDialog}
              computeBankReconciliation={computeBankReconciliation}
            />
          )}
          <ConfirmationDialog
            isOpen={confirmationDialogPopup}
            handleClose={handleCloseConfirmationDialogPopup}
            handleConfirm={handlePopupConfirm}
          />
        </Dialog>
        <CancelConfirmationDialog
          open={openCancelConfirmation}
          onConfirm={handleCancelConfirm}
          onCancel={handleCancelCancel}
        />
      </div>
    </>
  );
};

export const CrbrTable = ({
  paymentBtn = false,
  tableModel,
  data,
  handleTableChange,
  disabled,
  handlePaymentAction,
  status,
}) => {
  return (
    <div className={`crbr-table-container`}>
      <table className={`crbr-table  ${paymentBtn && "crbr-none"}`}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Name</th>
            <th>Reference</th>
            <th>Debit</th>
            <th>Credit</th>
            <th>Charge</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {
            <>
              {data?.length > 0 ? (
                data.map((row, index) => (
                  <tr key={index}>
                    <td>{row.date}</td>
                    <td>{row.name}</td>

                    <td>
                      {tableModel === bankReTable.cb ? (
                        <input
                          style={{ backgroundColor: "transparent" }}
                          disabled={disabled}
                          name="referenceNumber"
                          value={row?.referenceNumber}
                          onChange={(e) =>
                            handleTableChange(e, row, index, bankReTable.cb)
                          }
                          className="crbr-table-input"
                          type="text"
                        />
                      ) : (
                        row.referenceNumber
                      )}
                    </td>
                    <td>{row.debit}</td>
                    <td>{row.credit}</td>

                    <td>
                      {tableModel === bankReTable.cb ? (
                        <input
                          style={{ backgroundColor: "transparent" }}
                          name="charge"
                          disabled={disabled}
                          value={row?.charge}
                          onChange={(e) =>
                            handleTableChange(e, row, index, bankReTable.cb)
                          }
                          className="crbr-table-input"
                          type="text"
                        />
                      ) : (
                        row.charge
                      )}
                    </td>
                    <td>
                      <div className={`crbr-status `}>
                        <div className={`${row.status.toLowerCase()}`}>
                          {row.status}
                        </div>
                      </div>
                      {paymentBtn && (
                        <div className="crbr-pay-button-container">
                          {row.status === "Pending" && (
                            <button
                              onClick={() => handlePaymentAction(row)}
                              className="crbr-pay-button"
                            >
                              <span>Pay</span>
                            </button>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} style={{ textAlign: "center" }}>
                    <span>No Data </span>
                  </td>
                </tr>
              )}
            </>
          }
        </tbody>
      </table>
    </div>
  );
};
