import axios from "axios"
import { HEADERS } from "../../../../../API/UrlAndPaths"
import { setSubmitLoading } from "../../../../../Redux/Dashboard/mainDashBoardSlice"
import store from "../../../../../Redux/store"
import { errorSnackMsg, successSnackMsg } from "../../../../Custom Hooks/SnackkBarUtilities"

export const aggregatorReportPaymentAPI=async(body,submitFn)=>{
    store.dispatch(setSubmitLoading(true))
    try{
        const response=await axios.post("pos/postPosPaymentForAggregator",body,HEADERS)
        if(response.status===200){
            submitFn()
            successSnackMsg("Payment successfull 👍")
        }
    }
    catch(error){
        errorSnackMsg(error.response.data || "Newtwork connection failed !!")
    }finally{
        store.dispatch(setSubmitLoading(false))
    }
}