import React from 'react'

const AddButtonWithLabel = (props) => {

  const{onClick,text,style}=props

  return (
    <button class="add-btn-global-new" onClick={onClick} style={style}>
        <div class="sign">+</div>
        <div class="text">{text}</div>
    </button>
  )
}

export default AddButtonWithLabel