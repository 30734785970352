import React, { useEffect, useState } from 'react'
import '../Settings/posSettings.css'
import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material'
import { useSelector } from 'react-redux'
import { chartOfAccountListApiCall } from '../../../../../API/AccountUpdated/chartOfAccountAPI'
import { journalListApiCall } from '../../../../../API/AccountUpdated/journalAPI'
import { postPosSettingsAPICall, viewPosSettingsAPICall } from './posSettingsAPI'
import SuccessSnackbar from '../../../../Single Components/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../../../Single Components/SnackBars/ErrorSnackbar'
import { findObjFromList } from '../../../../../Js/generalFunctions'
export const PosSettings = () => {
    const chartOfAccountList = useSelector((state) => state.chartOfAccountsSlice.value)
    const journalList = useSelector((state) => state.journalSlice.value)
    const posSettingsList=useSelector((state)=>state.posSettingSlice.apiResList);

    const decimalPosition=localStorage.getItem('decimalPosition')

    const receivableList = chartOfAccountList?.filter(item => item.type === "Receivables");
    const incomeAccList = chartOfAccountList?.filter(item => item.type === "Income");
    const expenseAccList = chartOfAccountList?.filter(item =>item.type === "Cost of revenue");
    const bankAndCashAccList=chartOfAccountList?.filter(item =>item.type === "Bank and cash");
    const currentAssetAccList=chartOfAccountList?.filter(item =>item.type === "Current asset");
    const typeCashAndBankJournalList=journalList?.filter(item=>item.type === "cash" )
    const typesalesInJournalList = journalList?.filter(item=>item.type === 'sales')
   

    const [snackBarStates,setSnackBarStates]=useState({
      success:false,
      error:false,
      message:"",
    })
   const [validationAlert,setValidationAlert]=useState({})
    const [formValues,setFormValues] = useState({
      enableKot:false,
      posAccounts:false,
      posReceivable:null,
      posIncome:null,
      posDiscount:null,
      posBankCharges:null,
      cashGainOrLoss:null,
      deliveryChargeAccount:null,
      posJournal:null,
      packagingChargeAccount:null,

      pettyCash:false,
      pettyCashJournal:null,

      cashInOrOut:false,
      cashInOrOutAccount:null,

      activeWallet:false,
      walletjournal:null,

      activeLoyalty:false,
      loyaltyJournal:null,
      negativeStock:false,

      deliveryCharge:false,
      deliveryAmt:"",
      packageCharge:false,
      packageAmt:"",
    })
    const closeSnackbar=()=>{
      setSnackBarStates({...snackBarStates,success:false,
                              error:false,
                              message:"",})
  }
  const getFormInfo = (key) => (e, newValue) => {
   
    setFormValues({...formValues,[key]:newValue!==undefined?newValue:e.target.value})
}
const checkboxChange=(key)=>(e)=>{
  setFormValues({ ...formValues, [key]: e.target.checked })
}

const roundValues=()=>{
  setFormValues({...formValues,deliveryAmt:Number(formValues.deliveryAmt)?.toFixed(decimalPosition),packageAmt:Number(formValues.packageAmt)?.toFixed(decimalPosition)})
}
const payload={
  enableKot:formValues?.enableKot,
  posStatus:formValues?.posAccounts,
  posAccounts:formValues?.posAccounts===false?null:{
    posReceivableAccount:formValues?.posReceivable?._id,
    posIncomeAccount:formValues?.posIncome?._id,
    posDiscountAccount:formValues?.posDiscount?._id,
    posBankChargesAccount:formValues?.posBankCharges?._id,
    // cashGainOrLossAccount:formValues?.cashGainOrLoss?._id,
    deliveryChargeAccount:formValues?.deliveryChargeAccount?._id,
    packagingChargeAccount:formValues?.packagingChargeAccount?._id,
    posJournalAccount:formValues?.posJournal?._id


  },
  cashInOutStatus:formValues?.cashInOrOut,
  cashInOut:{
    cashInOutAccount:formValues?.cashInOrOut === true?formValues?.cashInOrOutAccount?._id:null
  },
  pettyCashStatus:formValues?.pettyCash,
  pettyCash:{
    PettycashJournel:formValues?.pettyCash === true?formValues?.pettyCashJournal?._id:null
  },
  activeWalletStatus:formValues?.activeWallet,
  activeWallet:{
    activeWalletJournel:formValues?.activeWallet===true?formValues?.walletjournal?._id:null
  },
  activeLoyaltyStatus:formValues?.activeLoyalty,
  activeLoyalty:{
    activeLoyaltyJournel:formValues?.activeLoyalty===true?formValues?.loyaltyJournal?._id:null
  },
  isNegativeStock:formValues?.negativeStock,
  deliveryCharge:formValues?.deliveryCharge,
  deliveryAmt:Number(formValues?.deliveryAmt),
  packagingCharge:formValues?.packageCharge,
  pkgCharge:Number(formValues?.packageAmt),
}
const saveBtnClick=()=>{
  let message="Select this field"
  if(formValues?.posAccounts===true && formValues?.posReceivable===null){
    setValidationAlert({...validationAlert,receivableAcc:message})
  }
  else if(formValues?.posAccounts===true && formValues?.posIncome===null){
    setValidationAlert({...validationAlert,posIncome:message})
  }
  else if(formValues?.posAccounts===true && formValues?.posDiscount===null){
    setValidationAlert({...validationAlert,posDiscount:message})
  }
  else if(formValues?.posAccounts===true && formValues?.posBankCharges === null){
    setValidationAlert({...validationAlert,posBankCharges:message})
  }
  // else if(formValues?.posAccounts===true && formValues?.cashGainOrLoss === null){
  //   setValidationAlert({...validationAlert,cashGainOrLoss:message})
  // }
  else if(formValues?.posAccounts===true && formValues?.deliveryChargeAccount === null){
    setValidationAlert({...validationAlert,deliveryCharge:message})
  }



  else if(formValues?.posAccounts===true && formValues?.posJournal === null){
    setValidationAlert({...validationAlert,posJournal:message})
  }
  else if(formValues?.posAccounts===true && formValues?.packagingChargeAccount === null){
    setValidationAlert({...validationAlert,packagingChargeAccount:message})
  }


  

  else if(formValues?.pettyCash===true && formValues?.pettyCashJournal === null){
    setValidationAlert({...validationAlert,pettyCashAccount:message})
  }
  else if(formValues?.cashInOrOut===true && formValues?.cashInOrOutAccount === null){
    setValidationAlert({...validationAlert,cashInOrOutAccount:message})
  }
  else if(formValues?.activeWallet===true && formValues?.walletjournal === null){
    setValidationAlert({...validationAlert,walletjournal:message})
  }
  else if(formValues?.activeLoyalty===true && formValues?.loyaltyJournal === null){
    setValidationAlert({...validationAlert,loyaltyJournal:message})
  }
  else if(formValues?.deliveryCharge && formValues?.deliveryAmt === ""){
    setValidationAlert({...validationAlert,deliveryAmt:message})
  }
  else if(formValues?.packageCharge && formValues?.packageAmt === ""){
    setValidationAlert({...validationAlert,packageAmt:message})
  }
  else{
    postPosSettingsAPICall(payload,setSnackBarStates,snackBarStates)
  }
 

}
useEffect(()=>{
  posSettingsList !== undefined &&
  setFormValues({
    ...formValues,
    posAccounts:posSettingsList?.posStatus,
    enableKot:posSettingsList?.enableKot,
    posReceivable:findObjFromList("_id",chartOfAccountList,"posReceivableAccount",posSettingsList?.posAccounts),
    posIncome:findObjFromList("_id",chartOfAccountList,"posIncomeAccount",posSettingsList?.posAccounts),
    posDiscount:findObjFromList("_id",chartOfAccountList,"posDiscountAccount",posSettingsList?.posAccounts),
    posBankCharges:findObjFromList("_id",chartOfAccountList,"posBankChargesAccount",posSettingsList?.posAccounts),
    cashGainOrLoss:findObjFromList("_id",chartOfAccountList,"cashGainOrLossAccount",posSettingsList?.posAccounts),
    deliveryChargeAccount:findObjFromList("_id",chartOfAccountList,"deliveryChargeAccount",posSettingsList?.posAccounts),
    packagingChargeAccount:findObjFromList("_id",chartOfAccountList,"packagingChargeAccount",posSettingsList?.posAccounts),

    posJournal:findObjFromList("_id",journalList,"posJournalAccount",posSettingsList?.posAccounts),

    pettyCash:posSettingsList?.pettyCashStatus,
    pettyCashJournal:findObjFromList("_id",journalList,"PettycashJournel",posSettingsList?.pettyCash),
    cashInOrOut:posSettingsList?.cashInOutStatus,
    cashInOrOutAccount:findObjFromList("_id",chartOfAccountList,"cashInOutAccount",posSettingsList?.cashInOut),
    activeWallet:posSettingsList?.activeWalletStatus,
    walletjournal:findObjFromList("_id",journalList,"activeWalletJournel",posSettingsList?.activeWallet),
    activeLoyalty:posSettingsList?.activeLoyaltyStatus,
    loyaltyJournal:findObjFromList("_id",journalList,"activeLoyaltyJournel",posSettingsList?.activeLoyalty),
    negativeStock:posSettingsList?.isNegativeStock,
    deliveryCharge:posSettingsList?.deliveryCharge,
    deliveryAmt:Number(posSettingsList?.deliveryAmt)?.toFixed(decimalPosition),
    packageCharge:posSettingsList?.packagingCharge,
    packageAmt:Number(posSettingsList?.pkgCharge)?.toFixed(decimalPosition),

  })
},[posSettingsList,chartOfAccountList,journalList])

    useEffect(() => {
      chartOfAccountListApiCall()
      journalListApiCall()
      viewPosSettingsAPICall()
  }, [])


  return (
    <div className="global-page-parent-container">
         <div className="global-white-bg-container">
                        <>
                            <Tooltip title="Save">
                                <IconButton 
                                onClick={saveBtnClick}
                                >
                                    <i class="bi bi-floppy"></i>
                                </IconButton>
                            </Tooltip>
                        </>
            </div>
       <div   className="new-global-white-bg-container">
       <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                type="checkbox" 
                id="enableKot"
                value={formValues?.enableKot}
                onChange={checkboxChange("enableKot")}
                checked={formValues?.enableKot}
                />
              <label htmlFor="enableKot">KOT</label>
            </div>
        </div>
        {/* 1 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                type="checkbox" 
                id="checkboxDropDown"
                value={formValues?.posAccounts}
                onChange={checkboxChange("posAccounts")}
                checked={formValues?.posAccounts===true}
                />
              <label htmlFor="checkboxDropDown">POS Accounts</label>
            </div>
           {
            formValues?.posAccounts===true &&
           
            <>
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
                 <div className="pos-settings-dropdown-container auto-complete-new">
                 <p>POS Receivable</p>
                        <Autocomplete
                            options={receivableList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.posReceivable}
                            onChange={getFormInfo("posReceivable")}
                           
                        />
                        
                    </div>
                    <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.receivableAcc}</p>
               </div>
        
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
                <div className='pos-settings-dropdown-container auto-complete-new'>
                <p>POS Income</p>
                <Autocomplete
                            options={incomeAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.posIncome}
                            onChange={getFormInfo("posIncome")}
                          
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.posIncome}</p>
               </div>
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>POS Discount</p>
               <Autocomplete
                            options={expenseAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.posDiscount}
                            onChange={getFormInfo("posDiscount")}
                         
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.posDiscount}</p>
               </div>
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>POS Bank Charges</p>
               <Autocomplete
                            options={expenseAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.posBankCharges}
                            onChange={getFormInfo("posBankCharges")}
                           
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.posBankCharges}</p>
               </div>
               
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Delivery Charge</p>
               <Autocomplete
                            options={expenseAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.deliveryChargeAccount}
                            onChange={getFormInfo("deliveryChargeAccount")}
                            
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.deliveryCharge}</p>
               </div>

               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Pos journal</p>
               <Autocomplete
                            options={typesalesInJournalList || []}
                            getOptionLabel={(option) => option?.journalName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.posJournal}
                            onChange={getFormInfo("posJournal")}
                            
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.posJournal}</p>
               </div>
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Packaging Charge</p>
               <Autocomplete
                            options={expenseAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.packagingChargeAccount}
                            onChange={getFormInfo("packagingChargeAccount")}
                            
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.packagingChargeAccount}</p>
               </div>
            </>
            }
          </div>
          {/* 2 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                 type="checkbox" 
                 id="PettyCash" 
                 value={formValues?.pettyCash}
                 onChange={checkboxChange("pettyCash")}
                 checked={formValues?.pettyCash === true}
                 />
              <label htmlFor="PettyCash">Petty Cash</label>
            </div>
            {
              formValues?.pettyCash === true &&
              <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
              <div className='pos-settings-dropdown-container auto-complete-new'>
                <p>Petty Cash Journal</p>
                 <Autocomplete
                              options={typeCashAndBankJournalList || []}
                              getOptionLabel={(option) => option?.journalName}
                              renderInput={(params) => (<TextField {...params} />)}
                              value={formValues?.pettyCashJournal}
                              onChange={getFormInfo("pettyCashJournal")}
                             
                          />
               
              </div>
              <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.pettyCashAccount}</p>
              </div>
            }
           
          
          </div>
          {/* 3 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                type="checkbox" 
                id="CashInOrOut"
                value={formValues?.cashInOrOut}
                onChange={checkboxChange("cashInOrOut")}
                checked={formValues?.cashInOrOut === true}
                />
              <label htmlFor="CashInOrOut">Cash In Or Out</label>
            </div>
            {
              formValues?.cashInOrOut === true &&
             <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Cash In Or Out Account</p>
              <Autocomplete
               disablePortal
               id="combo-box-demo"
               options={bankAndCashAccList||[]}
               getOptionLabel={(option) => option?.accountName}
               renderInput={(params) => <TextField {...params} 
              
               />}
              
               value={formValues?.cashInOrOutAccount}
               onChange={getFormInfo("cashInOrOutAccount")}
              
              />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.cashInOrOutAccount}</p>
            </div>
            }
            
          
          </div>
          {/* 4 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                 type="checkbox" 
                 id="ActiveWallet"
                 value={formValues?.activeWallet}
                 onChange={checkboxChange("activeWallet")}
                 checked={formValues?.activeWallet===true}
                 />
              <label htmlFor="ActiveWallet">Active Wallet</label>
            </div>
            {
              formValues?.activeWallet===true &&
             <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Wallet Journal</p>
              <Autocomplete
               disablePortal
               id="combo-box-demo"
               options={typesalesInJournalList||[]}
               getOptionLabel={(option) => option?.journalName}
               renderInput={(params) => <TextField {...params} 
               />}
               value={formValues?.walletjournal}
               onChange={getFormInfo("walletjournal")}
              
              
              />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.walletjournal}</p>
             </div>
            }
            
         
          
          </div>
          {/* 5 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                type="checkbox" 
                id="ActiveLoyalty"
                value={formValues?.activeLoyalty}
                onChange={checkboxChange("activeLoyalty")}
                checked={formValues?.activeLoyalty===true}
                />
              <label htmlFor="ActiveLoyalty">Active Loyalty</label>
            </div>
            {
              formValues?.activeLoyalty===true &&
              <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Loyalty Journal</p>
              <Autocomplete
               disablePortal
               id="combo-box-demo"
               options={typesalesInJournalList||[]}
               getOptionLabel={(option) => option?.journalName}
               renderInput={(params) => <TextField {...params} 
              
               />}
              
               value={formValues?.loyaltyJournal}
               onChange={getFormInfo("loyaltyJournal")}
              
              />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.loyaltyJournal}</p>
              </div>
            }
            
         
          
          </div>
          {/* 6 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                type="checkbox" 
                id="negativeStock"
                value={formValues?.negativeStock}
                onChange={checkboxChange("negativeStock")}
                checked={formValues?.negativeStock===true}
                />
              <label htmlFor="negativeStock">Negative Stock</label>
            </div>
          </div>
          {/* 7 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                type="checkbox" 
                id="deliveryCharge"
                value={formValues?.deliveryCharge}
                onChange={checkboxChange("deliveryCharge")}
                checked={formValues?.deliveryCharge===true}
                />
              <label htmlFor="deliveryCharge">Delivery Charge</label>
            </div>
            {
              formValues?.deliveryCharge &&
              <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Delivery Amount </p>
                  <TextField
                    value={formValues?.deliveryAmt}
                    onChange={getFormInfo("deliveryAmt")}
                    fullWidth
                    sx={{input:{padding:"4px 14px"}}}
                    onBlur={roundValues}
                    onFocus={(e)=>e.target.select()}
                  />
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.deliveryAmt}</p>
              </div>
            }
            
         
          
          </div>
          {/* 8 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                type="checkbox" 
                id="packageCharge"
                value={formValues?.packageCharge}
                onChange={checkboxChange("packageCharge")}
                checked={formValues?.packageCharge}
                />
              <label htmlFor="packageCharge">Packaging Charge</label>
            </div>
            {
              formValues?.packageCharge &&
              <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Package Amount </p>
                  <TextField
                    value={formValues?.packageAmt}
                    onChange={getFormInfo("packageAmt")}
                    fullWidth
                    sx={{input:{padding:"4px 14px"}}}
                    onBlur={roundValues}
                    onFocus={(e)=>e.target.select()}
                  />
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.packageAmt}</p>
              </div>
            }
            
         
          
          </div>
       </div>

       <SuccessSnackbar open={snackBarStates.success} handleClose={closeSnackbar} message={snackBarStates.message}/>
       <ErrorSnackbar open={snackBarStates.error} handleClose={closeSnackbar} message={snackBarStates.message}/>
    </div>
  )
}

