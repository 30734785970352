import axios from "axios"
import { HEADERS, HEADER_FORM_DATA } from "../../../../API/UrlAndPaths"
import store from "../../../../Redux/store";
import { store_printers_list } from "./deviceSettingsSlice";
import { setBillLoading, setErrorSnack, setSnackMsg } from "../../Sales/Billing/Slice/billingScreenSlice";
import { setSubmitLoading } from "../../../../Redux/Dashboard/mainDashBoardSlice";


export const getAllPrinterDevicesAPICall=async()=>{
    store.dispatch(setSubmitLoading(true))
    try {
        const response = await axios.get('http://localhost:5000/printers',HEADERS);
        store.dispatch(store_printers_list(response?.data))
        store.dispatch(setSubmitLoading(false))
        } catch (error) {
          store.dispatch(setSubmitLoading(false))
          store.dispatch(store_printers_list([]))
          store.dispatch(setSnackMsg("Cannot Find Printers"))
          store.dispatch(setErrorSnack(true))
      }
}
export const printRequestAPICall=async(body,manualPrint)=>{
    try {
        const response = await axios.post('http://localhost:5000/thermalPrint',body,HEADER_FORM_DATA);
        store.dispatch(setBillLoading(false))
        } catch (error) {
            store.dispatch(setSnackMsg("Print request failed"))
            store.dispatch(setErrorSnack(true))
            store.dispatch(setBillLoading(false))
            manualPrint()
      }
}
export const kotPrintRequestAPICall=async(body,manualPrint)=>{
    try {
        const response = await axios.post('http://localhost:5000/kotThermalPrint',body,HEADERS);
        store.dispatch(setBillLoading(false))
        } catch (error) {
            store.dispatch(setSnackMsg("Print request failed"))
            store.dispatch(setErrorSnack(true))
            store.dispatch(setBillLoading(false))
            manualPrint()
      }
}

