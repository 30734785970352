import { createSlice } from "@reduxjs/toolkit";

export const inventoryProductSlice = createSlice({
  name: "inventoryProductSlice",
  initialState: {
    apiResList: undefined,
    rowData:undefined,
    generateName:undefined,
    generateCode:undefined,

    apiResListProduct: undefined,
    rowDataProduct:undefined,
    generateNameProduct:undefined,
    generateCodeProduct:undefined,
    productListForStockReport:undefined,
    addProductDialog:false
  },
  reducers: {
    get_inventory_product_master_res_list: (state, action) => {
      state.apiResList = action.payload.inventoryProductMasterData;
    },
    get_inventory_product_master_row_list: (state, action) => {
      state.rowData = action.payload.inventoryProductMasterSingleData;
    },
    generate_inventory_product_master__name: (state, action) => {
      state.generateName = action.payload.inventoryProductMastername;
    },
    generate_inventory_product_master__code: (state, action) => {
      state.generateCode = action.payload.inventoryProductMasterCode;
    },

    // inventory products
    get_inventory_product_res_list: (state, action) => {
      state.apiResListProduct = action.payload.inventoryProductData;
    },
    get_inventory_product_row_list: (state, action) => {
      state.rowDataProduct = action.payload.inventoryProductSingleData;
    },
    generate_inventory_product_name: (state, action) => {
      state.generateNameProduct = action.payload.inventoryProductname;
    },
    generate_inventory_product_code: (state, action) => {
      state.generateCodeProduct = action.payload.inventoryProductCode;
    },
    product_list_for_stock_report:(state,action) => {
      state.productListForStockReport = action.payload.stockReportProducts;
    },
    set_product_dialog_open:(state,action) => {
      state.addProductDialog = action.payload;
    },
  }
});

export const {
    get_inventory_product_master_res_list,
    get_inventory_product_master_row_list,
    generate_inventory_product_master__name,
    generate_inventory_product_master__code,

    get_inventory_product_res_list,
    get_inventory_product_row_list,
    generate_inventory_product_name,
    generate_inventory_product_code,product_list_for_stock_report,
    set_product_dialog_open
} = inventoryProductSlice.actions;

export default inventoryProductSlice.reducer;
