import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

function MediaDialog({ dialogData, handleClose }) {
  return (
    <div>
      <Dialog minWidth="md" open={dialogData?.status} onClose={handleClose}>
        {/* <DialogTitle>Media Preview</DialogTitle> */}
        <DialogContent sx={{ p: 0 }}>
          {dialogData?.format === "image" && (
            <img
              src={dialogData?.url}
              alt="Media"
              style={{ maxWidth: "100%" }}
            />
          )}{" "}
          {dialogData?.format === "video" && (
            <video controls style={{ maxWidth: "100%" }}>
              <source src={dialogData?.url} type="video/mp4" />
              {/* Your browser does not support the video tag. */}
            </video>
          )}
          {/* {/\.(jpg|jpeg|png)$/i.test(dialogData?.url) ? (
            <img
              src={dialogData?.url}
              alt="Media"
              style={{ maxWidth: "100%" }}
            />
          ) : /\.(mp4|webm|mov|avi|wmv)$/i.test(dialogData?.url) ? (
            <video controls style={{ maxWidth: "100%" }}>
              <source src={dialogData?.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) :''} */}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default MediaDialog;
