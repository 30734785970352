
export const cardTypes ={
    SMALL: "SMALL",
    MEDIUM: "MEDIUM",
    LARGE: "LARGE",
}


//util functions 
export const amountConvertor = (amount) => {
    const suffixes = ["", "K", "M", "B", "T", "P", "E"];
    let count = 0;

    // Check if the amount is negative
    if (amount < 0) {
        amount = Math.abs(amount); // Convert negative amount to positive for calculation
        while (amount >= 1000 && count < suffixes.length - 1) {
            amount /= 1000;
            count++;
        }
        return { amount: "-" + amount?.toFixed(2), count: suffixes[count] };
    } else {
        while (amount >= 1000 && count < suffixes.length - 1) {
            amount /= 1000;
            count++;
        }
        return { amount: amount?.toFixed(2), count: suffixes[count] };
    }
};
