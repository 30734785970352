import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';

const PosAggregatePrint = (props) => {
    const {aggregatorReportList,branch}=props;
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
      );

      useEffect(()=>{
        companyProfileInfoForUser();
      },[])
  return (
    <div className="print-main-div" style={{ backgroundColor: "white" }}>
        <div className='header-div' style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <img src={companyProfileData?.companyLogo} alt="" style={{ height: "62px" }} />
                </div>
                <div style={{ textAlignLast: "center" }}>
                    <h1 style={{ fontSize: "medium", margin: "0" }}>Aggregate Report</h1>

                    <p style={{ margin: "0", fontSize: "small" }}>{branch?.branchName}</p>


                </div>
                <div>
                    <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyName}</p>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyAddress}</p>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyContact}</p>
                </div>
            </div>
            <div className="new-global-table-container" style={{ paddingTop: "0 42px" }}>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Order Reff</th>
                                <th>Customer</th>
                                <th>Aggregator</th>
                                <th>Mobile</th>
                                <th>Delivery Charge</th>
                                <th>Discount </th>
                                <th>Commition </th>
                                <th>Order Amount</th>
                                <th>Paid Amount</th>
                                <th>Balance</th>
                            </tr>
                        </thead>

                        <tbody>
                            {aggregatorReportList?.report?.map((item, index) => (

                                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? "white" : "rgb(214 212 212)" }}>
                                    <td style={{ border: "none" }}>{item.date}</td>
                                    <td style={{ border: "none" }}>{item.orderRef}</td>
                                    <td style={{ border: "none" }}>{item.customer}</td>
                                    <td style={{ border: "none" }}>{item.aggregator}</td>
                                    <td style={{ border: "none" }}>{item.mobile}</td>
                                    <td style={{ border: "none" }}>{item.deliveryCharge}</td>
                                    <td style={{ border: "none" }}>{item.discount}</td>
                                    <td style={{ border: "none" }}>{item.commType}</td>
                                    <td style={{ border: "none" }}>{item.orderAmount}</td>
                                    <td style={{ border: "none" }}>{item.paidAmount}</td>
                                    <td style={{ border: "none" }}>{item.balance}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr style={{ backgroundColor: "#aba8a8" }}>
                                <td colSpan={5}> </td>
                                <td style={{ textAlign: "center", border: "none" }}>{aggregatorReportList?.total?.deliveryCharge}</td>
                                <td style={{ textAlign: "center", border: "none" }}>{aggregatorReportList?.total?.discount}</td>
                                <td style={{ textAlign: "center", border: "none" }}></td>
                                <td style={{ textAlign: "center", border: "none" }}>{aggregatorReportList?.total?.orderAmount}</td>
                                <td style={{ textAlign: "center", border: "none" }}>{aggregatorReportList?.total?.paidAmount}</td>
                                <td style={{ textAlign: "center", border: "none" }}>{aggregatorReportList?.total?.balance}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
    </div>
  )
}

export default PosAggregatePrint