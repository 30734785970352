import React from "react";
import "./completeOrderCardStyle.css";

const CompleteOrderCard = ({ data }) => {
  return (
    <div className="token-display-completed-order-container">
      <div className="order-status-type-container">
        <h3 className="token-display-status-header">READY</h3>
      </div>
      <div className="token-status-card-body">
        <ul>
          {data?.map((res, i) => (
            <li key={i}>
              <div className="token-number-box">{res}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CompleteOrderCard;
