import React, { useEffect, useState } from 'react'
import { categoryListAPICall } from '../../Inventory/Configuration/Category/CategoryAPI';
import { useSelector } from 'react-redux';
import axios from 'axios';
import store from '../../../../Redux/store';
import { get_pos_reports_list } from './posSalesReportAPI';
import { HEADERS } from '../../../../API/UrlAndPaths';
import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter"
import { convertDateFormat, getCurrentTime } from '../../../../Js/Date';
import { Skeleton } from '@mui/material';

export const posReportsAPICall=async(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)
  await axios.post("sales/posSaleReport",body,HEADERS)
      .then((res)=>{
          if(res.status===200){
              store.dispatch(get_pos_reports_list({posReportsData:res.data}))
              setIsLoading!==undefined&&setIsLoading(false)
            }}
      )
      .catch((err)=>{
        store.dispatch(get_pos_reports_list({posReportsData:undefined}))
        setIsLoading!==undefined&&setIsLoading(false)

      }
         
          
          )
}


export const PosSalesReport = () => {
  const decimalPosition = localStorage.getItem("decimalPosition");


  const CategoryList = useSelector((state) => state.categorySlice.value);
  const posSalesReportsList=useSelector((state)=>state.posReports.reportsList)
  
  const [category,setCategory]=useState(true)
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [isLoading,setIsLoading]=useState(false)
  // const [paymentMethod,setPaymentMethod]=useState(false)

  
  function convertToCamelCase(inputStr) {
    return inputStr.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  
  useEffect(()=>{
    categoryListAPICall();
    posReportsAPICall({},setIsLoading)
  },[])

  useEffect(()=>{
    if(FormDataInfo.length!==0){
      posReportsAPICall({
        branchId:FormDataInfo?.branchList,
        search:FormDataInfo?.search,
        shiftId:FormDataInfo?.shiftCode?FormDataInfo?.shiftCode:'',
        invoice:FormDataInfo?.invoiceNo ?FormDataInfo?.invoiceNo :"",
        customer:FormDataInfo?.customer?.name,
        status: FormDataInfo?.status?.length === 0 ? '' :convertToCamelCase(FormDataInfo?.status[0]) ,
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
      },setIsLoading)
    }
  },[FormDataInfo])
 
  return (
    <div className="global-page-parent-container" style={{margin:"0"}}>

    <div className="global-white-bg-container">
     <div className='justify-space-between' style={{paddingBottom:"22px"}}>
     <div className="sharp-arrow">
       <p>POS Sales Report</p>
        
     </div>
     <CategorySearchandFilter
          onData={handleDataFromChild}
          statusOptions={[ 'Complete','Pending','Printing','Delivered','Order Returned','Delivey Returned','Return']}
          isInvoiceNo={true}
          isCustomer={true}
          isStatus={true}
          isShiftCode={true}
          isPosSalesReportPage={true}  
          shouldFetchData = {true}

        />
    </div>
    <div style={{display:"flex"}}>
    <div style={{display:"flex",alignItems:"center",margin:"6px 0"}}>
        <input 
          type="checkbox" 
          style={{accentColor:"#1d1da7",cursor:"pointer"}}
           id="posSalesReportCat"
           onChange={(e)=>setCategory(e.target.checked)}
           checked={category === true}
          //  disabled={posSalesReportsList === undefined}
           />
        <label htmlFor="posSalesReportCat" style={{color:"#1d1da7",fontSize:"0.8rem",fontWeight:600,cursor:"pointer"}}>Category</label>
    </div>
    {/* <div style={{display:"flex",alignItems:"center",margin:"6px 0 6px 6px"}}>
      <input 
        type="checkbox"  
        style={{accentColor:"#1d1da7",cursor:"pointer"}}
        onChange={(e)=>setPaymentMethod(e.target.checked)}
        />
      <label htmlFor="" style={{color:"#1d1da7",fontSize:"0.8rem",fontWeight:600,cursor:"pointer"}}>Payment Method</label>
    </div> */}
    </div>
    <div className='global-report-table-container' style={{maxHeight:"80vh"}}>
    <table>
        <thead>
            <tr>
               <th  style={{width:"8%"}}>Date</th>
               <th>Shift Code</th>
               <th>Invoice No</th>
               <th>Customer</th>
               {/* {
                paymentMethod &&
                  <th>Payment Method</th>
               } */}
               
               {
                category === true && CategoryList?.map((r)=>(
                   <th>{r?.categoryName}</th>
                ))
               }
               
               <th>Stitching</th>
               <th>Discount</th>
             
              
               <th>Order Total</th>
               <th>Additional Discount</th>
               <th>Paid</th>
               <th>Current Status</th>
            </tr>
        </thead>
        {isLoading?(
          <tbody>
          <tr>
            <td colSpan={20}>
              {/* <Loader /> */}
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={40}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={20}>
              {/* <Loader /> */}
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={40}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={20}>
              {/* <Loader /> */}
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={40}
              />
            </td>
          </tr>
        
        
        </tbody>
        )
          :
          (
<tbody>
      {
        posSalesReportsList?.report!==undefined ? 
          posSalesReportsList?.report?.map((r, i) => (
            <>

              <tr key={i}>
                <td style={{color:"blue",backgroundColor: "transparent", border: "none",fontWeight:"600",fontSize:"1rem"}}>{r?.branchName}</td>
              </tr>
              {
                r?.data.map((k, i) => (
                  <tr style={{ height: "38px" }} key={i}>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{convertDateFormat(k?.dateMillisec)}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.shiftId}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.orderNo}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.customer}</td>
                    {/* {
                        paymentMethod &&
                        <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>
                        {k?.payment[0]?.type?.map((m,i)=>(
                          <span>
                          {i === k?.payment[0]?.type?.length - 1
                            ?m
                            :m + ","}
                        </span>
                        ))}
                      </td>
                    } */}
                   
                    {
                      category &&  CategoryList?.map((category) => (
                        <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>
                          {k.lines.find((line) => line.categoryId === category._id)
                            ? k.lines.find((line) => line.categoryId === category._id).amount?.toFixed(decimalPosition)

                            : 0?.toFixed(decimalPosition)}

                        </td>
                      ))
                    }
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.totalStitchingRate?.toFixed(decimalPosition)}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.discount?.toFixed(decimalPosition)}</td>
                  
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.orderTotal?.toFixed(decimalPosition)}</td>
                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.deliveryDiscount?.toFixed(decimalPosition)}</td>

                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{k?.paymentTotal?.toFixed(decimalPosition)}</td>

                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }} className='table-data-box'>
                      {
                        k?.status?.toLowerCase()==="complete"?
                        <span className='post'>Complete</span>
                        :k?.status?.toLowerCase()==="pending"?
                        <span className='draft'>Pending</span>
                        :k?.status?.toLowerCase()==="printing"?
                        <span className='rfq'>Printing</span>
                        :k?.status?.toLowerCase()==="delivered"?
                        <span className='po'>Delivered</span>
                        : k?.status?.toLowerCase()==='orderreturned'?
                        <span className='cancel'>Order Returned</span>
                        :k?.status==='delivey Returned'?
                        <span className='cancel'>Delivery Returned</span>
                        :k?.status?.toLowerCase()==='return'?
                        <span className='sales-order'>Return</span>
                        :k?.status
                      }
                     
                      </td>
                  </tr>
               
                ))
              }  
              <tr>
                 <td colSpan="4" style={{textAlign:"right"}}>
                    <p style={{margin:"0 8px",fontSize:"0.9rem",fontWeight:600}}>Total</p>
                 </td>

                 {category &&
            CategoryList?.map((category) => (
              <td key={category._id}>
                {/* Calculate and display the grand total for each category */}
                {r.data.reduce((orderAcc, k) => {
                  const line = k.lines.find((line) => line.categoryId === category._id);
                  return orderAcc + (line ? line.amount : 0);
                }, 0)?.toFixed(decimalPosition)}
              </td>
            ))}
               
             
                 <td>{r?.subTotal?.stitchingRate?.toFixed(decimalPosition)}</td>
                 <td>{r?.subTotal?.discount?.toFixed(decimalPosition)}</td>
               
                 <td>{r?.subTotal?.orderTotal?.toFixed(decimalPosition)}</td>
                 <td>{r?.subTotal?.deliveryDiscount?.toFixed(decimalPosition)}</td>
                 <td>{r?.subTotal?.paidTotal?.toFixed(decimalPosition)}</td>

                 <td></td>
              </tr>
             
              </>
            ))

          
          
        
            :
            <tr>
              <td colspan={20}>No Data</td>
            </tr>
       }
        </tbody>
          )
      }
        
        <tfoot>
      <tr>
        <th colSpan="4">Total</th>
        {category &&
      CategoryList?.map((category) => (
        <th key={category._id}>
          {/* Calculate and display the grand total for each category */}
          {posSalesReportsList?.report?.reduce((acc, r) => {
            return (
              acc +
              r.data.reduce((orderAcc, k) => {
                const line = k.lines.find((line) => line.categoryId === category._id);
                return orderAcc + (line ? line.amount : 0);
              }, 0)
            );
          }, 0)?.toFixed(decimalPosition)}
        </th>
      ))}
        <th>{posSalesReportsList?.grandtotal?.netStitchingRate?.toFixed(decimalPosition)}</th>
        <th>{posSalesReportsList?.grandtotal?.netDiscount?.toFixed(decimalPosition)}</th>
       
        <th>{posSalesReportsList?.grandtotal?.netOrderTotal?.toFixed(decimalPosition)}</th>
        <th>{posSalesReportsList?.grandtotal?.netDeliveryDiscount?.toFixed(decimalPosition)}</th>
        <th>{posSalesReportsList?.grandtotal?.netPaidTotal?.toFixed(decimalPosition)}</th>

        <th></th>
      </tr>
    </tfoot>
     </table>

    </div>
 </div>  
  
</div>
  )
}
