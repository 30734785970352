import React from "react";
import Dialog from "@mui/material/Dialog";
import { orderStatus } from "../../utils/kotEnum";
import PrintIcon from "@mui/icons-material/Print";
import "./popUpStyle.css";
import { Autocomplete, DialogContent, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setPopup } from "../../kitchenOrderTicketSlice";

const KotPopup = () => {
  const dispatch = useDispatch();
  const { popup } = useSelector((state) => state.kitchenOrderTicketSlice);
  const onClose = () => {
    dispatch(setPopup(!popup));
  };
  return (
    <Dialog open={false} onClose={onClose}>
      <div className="kot-popup-container">
        <h3 className="kot-cancel-head">Order Rejected</h3>
        <hr />
        <div className="kot-card-container">
          <div className="kot-card-header">
            <p className="card-order-no">Order No : ABCD123456789</p>
            <p className="card-table-no">Table No : 03</p>
            <p className="card-date">12 : 36 PM, 13 - 09 - 2022</p>
            <p className="card-token-no">Tocken No : 165</p>
            <p className="card-type">
              TYPE : <span>TAKE AWAY</span>
            </p>
          </div>
        </div>
        <div className="card-print-container">
          <div>
            <span>waiter</span> <span>John Doe</span>
          </div>
          {/* <div>
            <button className="card-print-btn">
              <PrintIcon fontSize="small" />
            </button>
          </div> */}
        </div>
        <div className="kot-cancel-reason-container">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={[]}
            renderInput={(params) => (
              <TextField focused={true} {...params} label="Reason" />
            )}
          />
        </div>
        <div className="card-btn-container">
          <button onClick={() => onClose()} className={orderStatus.CANCEL}>
            <span>Cancel</span>
          </button>
          <button className={orderStatus.REJECT}>
            <span>Reject</span>
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default KotPopup;
