import axios from "axios"
import store from "../../../../../Redux/store"
import { setBillLoading, setDialogBox, setErrorSnack, setKOTSingleInfo, setOrderIdInPOS, setSingleOrderInfo, setSnackMsg, setSuccessSnack, setUpdateOrder } from "../Slice/billingScreenSlice"
import { HEADERS } from "../../../../../API/UrlAndPaths"
import { setAggregator, setAggregatorRef, setBtnSelected, setSingleCusInfo } from "../Components/topBarSliceBilling"
import { setCartLoading } from "../Slice/viewOrdersBillingSlice"
import { setAuthPassword } from "../newBillingSlice"




// add order from pos
export const addPOSOrdertoKOT=async(body,clearFn,orderType,printReceipt)=>{
    store.dispatch(setBillLoading(true))
    const printSettingsString=localStorage.getItem('PrinterSettings')
    const printSettings=JSON.parse(printSettingsString)
    await axios.post("pos/postOrderFromFloorPlan",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setBillLoading(false))                  

                store.dispatch(setSnackMsg("Order Placed Successfully"))
                store.dispatch(setSuccessSnack(true))
                store.dispatch(setAggregator(null))
                store.dispatch(setAggregatorRef(null))
        
                if(orderType==="paymentOrder"){  
                    store.dispatch(setBtnSelected("payment"))
                    singlePOSOrderInfoAPICall({id:res?.data?.data?._id})   
                }
                else{
                    store.dispatch(setOrderIdInPOS(null))

                    if(res?.data?.kotData===null){
                        store.dispatch(setDialogBox(false))        
                    }else{
                        (printSettings?.kotAuto)?printReceipt(res?.data?.kotData) : store.dispatch(setDialogBox("kotPrint"))           
                    }    
                    store.dispatch(setKOTSingleInfo(res?.data?.kotData || null))
                }
                clearFn()
            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.status===400?err.response.data[0]?.msg:err.response.data
                store.dispatch(setSnackMsg(message))
                store.dispatch(setBillLoading(false))                
                store.dispatch(setErrorSnack(true))
        })
}

// add pos order without chair selected
export const addPOSOrderWoFloorSelectionAPI=async(body,clearFn,orderType,printReceipt)=>{
    const printSettingsString=localStorage.getItem('PrinterSettings')
    const printSettings=JSON.parse(printSettingsString)
    store.dispatch(setBillLoading(true))
    await axios.post("pos/addPosOrder",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setBillLoading(false))                
                store.dispatch(setSnackMsg("Order Placed Successfully"))
                store.dispatch(setSuccessSnack(true))
                if(orderType==="paymentOrder"){
                    store.dispatch(setOrderIdInPOS(res.data?.data?._id))   
                    store.dispatch(setBtnSelected("payment"))   
                    }
                    else{

                    if(res?.data?.kotData===null){
                        store.dispatch(setDialogBox(null))        
                    }else{
                        // (printSettings?.kotAuto)?printReceipt(res?.data?.kotData) : store.dispatch(setDialogBox("kotPrint"))           
                        store.dispatch(setDialogBox(printSettings?.kotAuto?"autoPrintKot":"kotPrint"))           
                    }
                    store.dispatch(setKOTSingleInfo(res?.data?.kotData || null))  
                    store.dispatch(setOrderIdInPOS(null))
                }
                clearFn()
                store.dispatch(setAggregator(null))
                store.dispatch(setAggregatorRef(null))

            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.status===400?"Delivery partner not selected!!":err.response.data
                store.dispatch(setSnackMsg(message))
                store.dispatch(setBillLoading(false))                
                store.dispatch(setErrorSnack(true))
        })
}
// update an existing order

export const updatePosOrderAPICall=async(body,clearFn,orderType,printReceipt)=>{
    store.dispatch(setBillLoading(true))
    const printSettingsString=localStorage.getItem('PrinterSettings')
    const printSettings=JSON.parse(printSettingsString)
    await axios.post("pos/addItemsToExistingOrders",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setOrderIdInPOS(null))   
                store.dispatch(setSnackMsg("Order Updated 👍"))
                store.dispatch(setBillLoading(false))                
                store.dispatch(setSuccessSnack(true))   
                store.dispatch(setUpdateOrder(false))   
                if(orderType==="paymentOrder"){
                    store.dispatch(setBtnSelected("payment"))   
                    setTimeout(() => {
                        store.dispatch(setOrderIdInPOS(res.data?.data?._id))   
                    }, 0);
                }else{
                    if(res?.data?.kotData===null){
                        store.dispatch(setDialogBox(null))        
                    }else{
                        (printSettings?.kotAuto)?printReceipt(res?.data?.kotData) : store.dispatch(setDialogBox("kotPrint"))           
                    }
                    store.dispatch(setKOTSingleInfo(res?.data?.kotData || null))  
                }   
                store.dispatch(setAggregator(null))
                store.dispatch(setAggregatorRef(null))
                store.dispatch(setAuthPassword(""))
                store.dispatch(setDialogBox(null))

                clearFn()
            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.data
                store.dispatch(setSnackMsg(message))
                store.dispatch(setBillLoading(false))                
                store.dispatch(setErrorSnack(true))
        })
}

// get single order info

export const singlePOSOrderInfoAPICall=async(body)=>{
    store.dispatch(setCartLoading(true))
    await axios.post("pos/viewPosOrderById",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setSingleOrderInfo(res.data))
                store.dispatch(setCartLoading(false))
            }
        })
        .catch((err)=>{
            store.dispatch(setSingleOrderInfo(null))
                store.dispatch(setCartLoading(false))
        })
}


// hold order
export const holdPOSOrderAPI=async(body,clearFn,orderType)=>{
    store.dispatch(setBillLoading(true))
    await axios.post("pos/addPosOrderHold",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setBillLoading(false))                
                store.dispatch(setDialogBox(false))                
                store.dispatch(setSnackMsg("Order Holded"))
                store.dispatch(setSuccessSnack(true))
                clearFn()
                store.dispatch(setAggregator(null))
                store.dispatch(setAggregatorRef(null))
            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.status===400?"Wrong data passed!!":err.response.data
                store.dispatch(setSnackMsg(message))
                store.dispatch(setBillLoading(false))                
                store.dispatch(setErrorSnack(true))
        })
}

// get customer single info

export const getSingleCustomerInfoAPICall=async(body)=>{
    await axios.post("customer/getSingleVendorDetails",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setSingleCusInfo(res.data))
            }
            store.dispatch(setSingleCusInfo(res.data))
        })
        .catch((err)=>{
            store.dispatch(setSingleCusInfo(null))
        })
}