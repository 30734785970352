import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { CustomerPaymentSingleViewAPI } from './customerPaymentAPI';
import QR from "../../../../../Assets/Images/Asset 1.png";
import QRCode from 'react-qr-code';
import { filterObjFromList } from '../../../../../Js/generalFunctions';

const CustomerPaymentCustomerPrint = () => {
    const [symbol, setSymbol] = useState(null);
    const [unit, setUnit] = useState(null);

    const decimalPosition = localStorage.getItem("decimalPosition");
    const singleViewId = localStorage.getItem("singleViewById");

    const currencyListUpdated = useSelector(
        (state) => state?.viewUpdatedCurrency?.value
      );

    const singleView = useSelector((state) => state?.customerPaymentSlice?.customerPayment)
    // console.log(singleView);
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue)

        useEffect(() => {
            let currencyObj = filterObjFromList(
              "_id",
              currencyListUpdated,
              "currency",
              companyProfileData
            );
            // console.log(currencyObj);
            setSymbol(currencyObj?.symbol);
            setUnit(currencyObj?.currencyUnit);
          }, [companyProfileData, currencyListUpdated]);
        
    useEffect(() => {
        CustomerPaymentSingleViewAPI({ _id: singleViewId })
    }, [])
    return (
        <div className="print-main-div">
            <div className="first-container">
                <div className="left-side-div">
                    <img
                        src={companyProfileData?.logo}
                        alt=""
                    />
                    <h1 style={{ margin: "0", fontSize: "medium" }}>
                        {companyProfileData?.companyName}
                    </h1>
                </div>
                <div className="right-side-div" >
                    <h1 style={{ fontWeight: "600", fontSize: "medium", color: "#3378ab" }}>PAYMENT VOUCHER</h1>
                    <p style={{ fontWeight: "100", color: "#3378ab" }}>{singleView?.name}</p>
                    <p style={{ fontWeight: "100", color: "#3378ab" }}>{singleView?.TYPE}</p>
                </div>
            </div>
            <div className="secound-container">
                <div style={{ display: "flex", justifyContent: "space-between", width: "40%" }}>
                    <div>
                        <p>VENDOR</p>
                        <p>PAYMENT METHOD</p>
                        <p>TYPE</p>
                        <p>TRANSACTION NO</p>
                        <p>AMOUNT</p>
                    </div>
                    <div className='res-label'>
                        <p>{singleView?.customer}</p>
                        <p>{singleView?.PAYMENTMETHOD}</p>
                        <p>{singleView?.TYPE}</p>
                        <p>{singleView?.chequeNo}</p>
                        <p>{symbol} {singleView?.totalAmount?.toFixed(decimalPosition)}</p>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", width: "40%" }}>
                    <div>
                        <p>PAYMENT DATE</p>
                        {/* <p>PAYMENT TYPE</p> */}
                        <p>PAYMENT REF</p>
                        <p>BRANCH</p>
                    </div>
                    <div className='res-label'>
                        <p>{singleView?.paymentDate}</p>
                        {/* <p>{singleView?.PAYMENTTYPE || "--"}</p> */}
                        <p>{singleView?.paymentReference || "--"}</p>
                        <p>{singleView?.BRANCH}</p>
                    </div>
                </div>
            </div>
            <div className='table-div'>
                <table>
                    <thead>
                        <tr>
                            <th>Bill</th>
                            <th>Bill Ref</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {singleView?.billId?.map((item, index) => (
                            <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.invoiceRef}</td>
                                <td style={{textAlignLast:"end"}}>{symbol} {item?.paidAmount?.toFixed(decimalPosition)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="global-print-total-field">
                <div>
                    <p style={{ fontSize: "small" }}>Amount in Words: {singleView?.amountInWords}</p>
                </div>
                <div style={{ display: "flex", paddingTop: "35px" }}>
                    <QRCode
                        size={256}
                        style={{
                            height: "auto",
                            width: "70px",
                        }}
                        value={"https://public/qrcode"}
                        // value={"https://textile.eyeterp.com/userdashboard/sales/payments"}
                        viewBox={`0 0 256 256`}
                    />
                </div>
            </div>
            <div className='first-print-footer'>
                <p>prepared</p>
                <p>signature</p>
            </div>
            {/* <div className="print-footer" style={{backgroundColor:"#1b5d9f"}}>
<p>page 1/1</p>
</div> */}
        </div>
    )
}

export default CustomerPaymentCustomerPrint