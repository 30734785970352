import { Button } from '@mui/material';
import React from 'react';

const KotButton = ({active, color,name,onClick,index }) => {
  return (
    <Button
    onClick={()=>onClick(index)}
      sx={{
        width: "8em",
        backgroundColor:active?color:`${color}99` ,
        '&:hover': {
          backgroundColor:color , 
        },
        textWrap:"nowrap",
      }}
      variant="contained"
    >
      {name}
    </Button>
  );
};

export default KotButton;
