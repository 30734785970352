import React, { forwardRef, useEffect, useRef, useState } from 'react'
import logo from '../../../../../Assets/Images/Logo.png'
import QR from "../../../../../Assets/Images/Asset 1.png"
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI'
import { salesQuotationSingleViewAPICall } from './salesQuatationAPI'
import { useSelector } from 'react-redux'
import QRCode from 'react-qr-code'
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI'
import { filterObjFromList } from '../../../../../Js/generalFunctions'

export const Template2 = forwardRef((props, ref) => {
  const { data, formValues, tax, domain, } = props;
  const [symbol, setSymbol] = useState(null);
  const [unit, setUnit] = useState(null);

  const decimalPosition = localStorage.getItem("decimalPosition");
  const numToWords = require("num-words");
  // const singleRowId = localStorage.getItem("singleViewById")
  const currencyListUpdated = useSelector(
    (state) => state?.viewUpdatedCurrency?.value
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  function toCamelCase(str) {
    return str.replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    }).replace(/\s+/g, '');
  }

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    // console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
    setUnit(currencyObj?.currencyUnit);

  }, [companyProfileData, currencyListUpdated]);

  // const singleRowId = localStorage.getItem("singleViewById")

  return (
    <>
      <div className='template2-print-header' ref={ref} >
        <div className='template-head'>
          <img src={formValues?.companyLogo} alt='' style={{ width: "190px", height: "80px", textAlign: "center", border: "none" }} />
          <p>{formValues?.companyName}</p>
        </div>
        <div className='template-head-left'>
          <p style={{ fontWeight: "700", fontSize: "x-large", paddingLeft: "90px" }}>{formValues?.label}</p>
          <p style={{ paddingLeft: "30px", paddingTop: "10px" }}>
            {formValues?.companyAddress === null || formValues?.companyAddress === "null" ? "" : formValues?.companyAddress}</p>
          {/* <p style={{ paddingLeft: "30px", paddingTop: "10px" }}>{formValues?.companyTRN}</p> */}
          <p>{formValues?.companyContact === null ? "" : formValues?.companyContact}</p>
          {formValues?.companyTRN !== "" && (
            <p style={{ fontWeight: "100" }}>TRN: {formValues?.companyTRN}</p>
          )}

        </div>
      </div>
      <div className='template2-container'>
        <div className='template2-first-box'>
          <div>
            <p style={{ margin: "0" }}>{ "Invoice To"}&nbsp;: </p>
            <p style={{ paddingLeft: "10px", margin: "0", fontSize: "small" }}>{formValues?.customerName}</p>
            <p style={{ paddingLeft: "10px", margin: "0", fontSize: "small" }}>{formValues?.customerEmail}</p>
            <p style={{ paddingLeft: "10px", margin: "0", fontSize: "small" }}>{formValues?.customerAddress}</p>
            <p style={{ paddingLeft: "10px", margin: "0", fontSize: "small" }}>{formValues?.customerContact}</p>
            {formValues?.trn !== "" && (
              <p style={{ paddingLeft: "10px", margin: "0", fontSize: "small" }}>TRN: {formValues?.trn}</p>)}
          </div>
          <div style={{ display: "flex", width: "40%", justifyContent: "space-between" }}>
            <div>
              <p style={{ margin: "0", fontSize: "small" }}>{formValues?.sequence}</p>
              {formValues?.firstDate !== "" && (
                <p style={{ margin: "0", fontSize: "small" }}>{formValues?.dateN1}</p>)}
              {formValues?.secondDate !== "" && (
                <p style={{ margin: "0", fontSize: "small" }}>{formValues?.dateN2}</p>)}
              {formValues?.lpo !== "" && (
                <p style={{ margin: "0", fontSize: "small" }}>LPO No</p>
              )}
              {formValues?.ref !== "" && (
                <p style={{ margin: "0", fontSize: "small" }}>{formValues?.refN || "Ref No"}</p>)}
              {formValues?.payTerm !== "" && (
                <p style={{ margin: "0", fontSize: "small" }}>Payment Terms</p>)}
              {formValues?.salesOrder !== "" && formValues?.label === "DELIVERY NOTE" && (
                <p style={{ margin: "0", fontSize: "small" }}>SO No</p>)}
            </div>
            <div>
              <p style={{ margin: "0", fontSize: "small" }}>: {formValues?.name} </p>
              {formValues?.firstDate !== "" && (
                <p style={{ margin: "0", fontSize: "small" }}>: {formValues?.firstDate}</p>)}
              {formValues?.secondDate !== "" && (
                <p style={{ margin: "0", fontSize: "small" }}>: {formValues?.secondDate}</p>)}
              {formValues?.lpo !== "" && (
                <p style={{ margin: "0", fontSize: "small" }}>: {formValues?.lpo}</p>
              )}
              {formValues?.ref !== "" && (
                <p style={{ margin: "0", fontSize: "small" }}>: {formValues?.ref}</p>)}
              {formValues?.payTerm !== "" && (
                <p style={{ margin: "0", fontSize: "small" }}>: {formValues?.payTerm}</p>)}
              {formValues?.salesOrder !== "" && formValues?.label === "DELIVERY NOTE" && (
                <p style={{ margin: "0", fontSize: "small" }}>: {formValues?.salesOrder}</p>)}

            </div>
          </div>
        </div>

        {formValues?.label !== "DELIVERY NOTE" && formValues?.label !== "GRN" && (
          <>
            <table>
              <thead>
                <tr>
                  <th style={{ width: "6%", fontSize: "smaller" }}>SI NO</th>
                  <th style={{ fontSize: "smaller" }}>PRODUCT</th>
                  <th style={{ fontSize: "smaller" }}>DESCRIPTION</th>
                  <th style={{ width: "6%", fontSize: "smaller" }}>QTY</th>
                  <th style={{ width: "6%", fontSize: "smaller" }}>UOM</th>
                  <th style={{ width: "6%", fontSize: "smaller" }}>RATE</th>
                  <th style={{ width: "6%", fontSize: "smaller" }}>AMOUNT</th>
                  <th style={{ width: "6%", fontSize: "smaller" }}>DISC%</th>
                  <th style={{ width: "6%", fontSize: "smaller" }}>VAT </th>
                  <th style={{ width: "6%", fontSize: "smaller" }}>VAT AMOUNT</th>
                  <th style={{ width: "12%", fontSize: "smaller" }}>NET AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white", textAlignLast: "center"
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>{item?.label}</td>
                    <td>{item?.product}</td>
                    <td>{item?.qty}</td>
                    <td>{item?.uomName}</td>
                    <td>{item?.unitPrice?.toFixed(decimalPosition)}</td>
                    <td>{(item?.qty * item?.unitPrice)?.toFixed(decimalPosition)}</td>
                    <td>{item?.discount}%</td>
                    <td
                      style={{ minWidth: "100px" }}
                    >
                      {item.taxIdList?.map((r, i) => (
                        <p key={i}   style={{ margin: "1px", border: "none",textAlign:"center" }}>
                          {r?.taxName}{(i + 1) < item.taxIdList?.length && ","}
                        </p>
                      ))}
                    </td>
                    <td>{item.taxAmt?.toFixed(decimalPosition)}</td>
                    <td>
                      {item.includedPrice === false ?
                        ((item.lineTotal + item.taxAmt)?.toFixed(decimalPosition)) :
                        (item.lineTotal?.toFixed(decimalPosition))
                      }
                    </td>
                  </tr>
                ))}

              </tbody>

            </table>
            <div style={{ backgroundColor: "rgb(234, 238, 249)", display: "flex", textAlign: "end", marginTop: "5px", paddingInline: "3px" }}>

              {data && data.length > 0 && (
                <p style={{ width: "70%", paddingTop: "3px", margin: "0", fontSize: "smaller", fontWeight: "600" }}>
                  {symbol}{" "}
                  {data?.reduce((total, item) => {
                    const qty = typeof item.qty === 'number' ? item.qty : 0; // Check if item.qty is a number, otherwise default to 0
                    const unitPrice = typeof item.unitPrice === 'number' ? item.unitPrice : 0; // Check if item.unitPrice is a number, otherwise default to 0
                    return total + qty * unitPrice;
                  }, 0)?.toFixed(decimalPosition)}
                </p>
              )}

              <p style={{ width: "18%", paddingTop: "3px", margin: "0", fontSize: "smaller", fontWeight: "600" }}>{symbol} {data?.reduce((acc, row) => acc + row?.taxAmt, 0.00)?.toFixed(decimalPosition)}</p>
              <p style={{ width: "10%", paddingTop: "3px", margin: "0", fontSize: "smaller", fontWeight: "600" }}>
                {symbol} {data?.reduce((acc, row) => {
                  if (row.includedPrice === false) {
                    return acc + (row.lineTotal + row.taxAmt);
                  } else {
                    return acc + row.lineTotal;
                  }
                }, 0.00)?.toFixed(decimalPosition)}
              </p>

            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "end", marginTop: "8px" }}>
              <div style={{ width: "50%", backgroundColor: "#e9e9e9", paddingInline: "3px", justifyContent: "space-between" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <p style={{ margin: "0", fontWeight: "600" }}>Total Before Vat
                  </p>
                  <p style={{ margin: "0" }}>
                    {symbol} {formValues && formValues.subtotal && typeof formValues.subtotal === 'number'
                      ? formValues.subtotal.toFixed(decimalPosition)
                      : ''}
                  </p>

                </div>
                {tax?.map((item, index) => (
                  <div style={{ display: "flex", justifyContent: "space-between" }} key={index}>
                    <p style={{ margin: "0", fontSize: "small" }}>{item?.taxName}</p>
                    <p style={{ margin: "0", fontSize: "small" }}>{symbol} {item?.amount?.toFixed(decimalPosition)}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="global-print-total-field">
              <div>
                <p style={{ margin: "0", paddingInline: "10px", fontSize: "small" }}>Amount in Words: {formValues?.totalAmount >= 0 ? toCamelCase(numToWords(Math.floor(formValues?.totalAmount))) : "Negative " + toCamelCase(numToWords(Math.floor(-formValues?.totalAmount)))} {unit}</p>

              </div>
              <div className='print-total-vat'>
                <p style={{ fontWeight: "600", margin: "15px 0 0 0" }}>Total Inc Vat({symbol})</p>
                <p>
                  {symbol} {typeof formValues?.totalAmount === 'number'
                    ? formValues.totalAmount.toFixed(decimalPosition)
                    : ''}
                </p>

              </div>
            </div>
          </>)}

        {(formValues?.label === "DELIVERY NOTE" || formValues?.label === "GRN") && (
          <table>
            <thead>
              <tr>
                <th>SI NO</th>
                {/* <th>PRODUCT CODE</th> */}
                <th>PRODUCT</th>
                <th>UOM</th>
                <th>Orderd Qty</th>
                <th>Deliverd Qty</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white", textAlignLast: "center"
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{item?.product}</td>
                  <td>{item?.uomName}</td>
                  <td>{item?.orderQuantity}</td>
                  <td>{item?.deliveredQuantity || item.recievedQuantity}</td>
                </tr>
              ))}

            </tbody>

          </table>
        )}
        <div className="print-first-footer">
          <div style={{ width: "50%" }}>
            <QRCode
              size={256}
              style={{
                height: "auto",
                width: "70px",
              }}
              value={domain ? domain : ''}
              // value={"https://textile.eyeterp.com/userdashboard/sales/payments"}
              viewBox={`0 0 256 256`}
            />
            <p style={{ margin: "0", fontSize: "small" }}>Terms and Conditions</p>
            <p style={{ fontSize: "small", margin: "8px 0 0px 8px" }}>{formValues?.termsAndCondition}</p>

          </div>
          <div style={{ paddingInline: "6px" }}>
            <p style={{ paddingBottom: "30px", padding: " 39px 13px 38px 0px", fontSize: "small" }}>AUTHERISED SIGNATORY FOR</p>
            <hr className="line-sign"></hr>
          </div>
        </div>
        {/* <div className="print-footer-1" >
          <p>page 1/1</p>
        </div> */}
      </div>
    </>

  )
})
export default Template2