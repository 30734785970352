import React, { useState } from 'react'
import { useSelector } from 'react-redux';

const DeliveryPartnerSummaryPrint = () => {

  const decimalPosition = localStorage.getItem("decimalPosition");
  const deliveryPartnerSummary = useSelector((state) => state.posReportshiftSlice.deliveryPartnerSummary);

  const {showSubHeaders} = useSelector((state) => state.posReportshiftSlice);


  return (
     <div
      className="new-global-table-container shift-report-print-container"
    >
      <div className="bill-main-head">
        <h5>Delivery Partners</h5>
        <h5>Amount</h5>
      </div>

      {
      deliveryPartnerSummary?
      deliveryPartnerSummary?.map((partner, index) => (
        <>
          <div
            key={partner._id}
            className="bill-sub-head">
            <h5>{partner.partner}</h5>
            <h5>{partner.total?.toFixed(decimalPosition)}</h5>
          </div>

          {showSubHeaders && (
            <table>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {partner.data.map((order, orderIndex) => (
                  <tr key={orderIndex}>
                    <td>
                      {order.orderNo}
                    </td>
                    <td>
                      {order.date}
                    </td>
                    <td>
                      {order.amount?.toFixed(decimalPosition)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )):
      <div
            className="bill-sub-head" style={{justifyContent:"center"}}>
            <h5>No Records</h5>
          </div>
      }
    </div>
  )
}

export default DeliveryPartnerSummaryPrint