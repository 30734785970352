import React, { useEffect, useState } from 'react'
import './InvoiceMarginReport.css'
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import ReactToPrint from "react-to-print";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from 'react-router-dom';
import { InvoiceMarginReportSingleViewAPIcall } from './GeneralReportAPI';
import { useSelector } from 'react-redux';
import { filterObjFromList } from '../../../../../Js/generalFunctions';

function InvoiceMarginReportSingleView() {
    let navigate = useNavigate();
    let location=useLocation()
  const ExcelJS = require("exceljs");


    const invoiceSingleListData = useSelector((state) => state?.generalReport?.InvoiceMarginReportSingleList)
    console.log(invoiceSingleListData,'ffffff')
    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue)
        const [filteredData, setFilteredData] = useState({}); 

    const [FormDataInfo, setFormDataInfo] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [symbol, setSymbol] = useState(null);

    const decimalPosition = localStorage.getItem("decimalPosition");
    const singleViewId = localStorage.getItem("invoiceSingleViewId")
    const formatFilterInfo = (filters) => {
      const parts = [];
    
      if (filters?.fromDate && filters?.toDate) {
        parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
      }
      console.log(filters?.fromDate);
  
      if (filters?.branchList) {
        parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
      }
     
      
      return parts; // Return the array directly
    };
    const handleDataFromChild = (data) => {
        setFormDataInfo(data);
    };
    const downloadList = () => {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("Invoice Margin Report");
      sheet.properties.defaultRowHeight = 20;
    
      // Add header section
      const titleRow = sheet.addRow(["Invoice Margin Report"]);
      titleRow.font = { size: 18, bold: true };
      sheet.mergeCells('A1:G1');
      titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    
      // Add additional header information
      const filterParts = formatFilterInfo(filteredData) || [];
    
      // Ensure filterParts is an array and iterate over it
      if (Array.isArray(filterParts)) {
        filterParts.forEach((part, index) => {
          const row = sheet.addRow([part]);
          row.font = { size: 15, bold: true };
          sheet.mergeCells(`A${index + 2}:G${index + 2}`);
          row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
        });
      }
    
      // Add an empty row between header and table
      sheet.addRow([]);
    
      // Header row styling
      const headerRow = sheet.addRow(["PRODUCT", "QTY", "UNIT PRICE", "NET SALES","NET COST","PROFIT","PROFIT MARGIN %"]);
      const headerFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
      };
      const headerFont = {
        size: 15,
        bold: true,
        color: { argb: "FFFFFFFF" }, // White font color
      };
    
      headerRow.eachCell((cell) => {
        cell.fill = headerFill;
        cell.font = headerFont;
        cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
      });
    
      sheet.columns = [
        { header: "PRODUCT", key: "productName", width: 50 },
        { header: "QTY", key: "qty", width: 20 },
        { header: "UNIT PRICE", key: "unitPrice", width: 20 },
        { header: "NET SALES", key: "netSale", width: 20 },
        { header: "NET COST", key: "cost", width: 20 },
        { header: "PROFIT", key: "profit", width: 20 },
        { header: " Invoice Margin Report", key: "profitMargin", width: 20 },
      ];
    
      invoiceSingleListData?.report?.forEach((item) => {
        const row = sheet.addRow({
          productName: item.productName,
          qty: item.qty,
          unitPrice: item.unitPrice,
          netSale: item.netSale,
          cost: `${symbol} ${item?.cost?.toFixed(2)}`,
          profit: `${symbol} ${item?.profit?.toFixed(2)}`,
          profitMargin: ` ${item?.profitMargin?.toFixed(2)}`,
        });
    
        const rowFill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFededed" }, // Use full hex code with "FF" prefix for better compatibility
        };
        
        row.eachCell((cell, colNumber) => {
          cell.fill = rowFill;
          cell.border = {
            top: { style: "thin", color: { argb: "FFFFFFFF" } },
            left: { style: "thin", color: { argb: "FFFFFFFF" } },
            bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
            right: { style: "thin", color: { argb: "FFFFFFFF" } },
          };
          if (colNumber === 4) { // Assuming the balance column is the 4th column
            const value = parseFloat(item?.balance);
            if (value < 0) {
              cell.font = { color: { argb: "FFededed" } }; // Red color for negative values
            }
          }
        });
      });
    
      // Add row for totals
      const totalRow = sheet.addRow([
        "Total",
        "",
        "",
        `${symbol} ${invoiceSingleListData?.total !== undefined && invoiceSingleListData?.total?.netSale?.toFixed(2)}`,
        `${symbol} ${invoiceSingleListData?.total !== undefined && invoiceSingleListData?.total?.cost?.toFixed(2)}`,
        `${symbol} ${invoiceSingleListData?.total !== undefined && invoiceSingleListData?.total?.profit?.toFixed(2)}`,
        `${symbol} ${invoiceSingleListData?.total !== undefined && invoiceSingleListData?.total?.profitMargin?.toFixed(2)}`,
      ]);
    
      totalRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: "FFededed" } };
        cell.fill = headerFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
      });
    
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "Invoice Margin Report.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    };


    useEffect(() => {
        let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
        setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])


    useEffect(() => {
        if (FormDataInfo.length !== 0) {
            InvoiceMarginReportSingleViewAPIcall({
                _id: singleViewId,
                search: FormDataInfo?.search,

            })
        }
    }, [FormDataInfo, singleViewId])


    return (
        <div className="in-or-out-container" style={{margin:'0'}}>
            <div className="in-or-out-report-wrapper" style={{backgroundColor:"unset",padding:"5px"}}>
                <div className="sub-nav-head" style={{backgroundColor:"white",paddingInline:"10px"}}>
                <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.8em",
            }}
          >
           
            <div
              style={{
                background: "unset",
                borderRadius: "0",
                maxWidth: "20em",
                cursor:'pointer',
                padding:"0"
              }}
              className="path-name-wrapper"
              onClick={() => {
                navigate(
                  "/userdashboard/accounts/report/generalReport/InvoiceMarginReport"
                );
                localStorage.removeItem("invoiceSingleViewId");
              }}
            >
              <h3 className="path-name" style={{ color: "black",paddingRight:"0" }}>
              Invoice Margin Report &gt;
              </h3>
            </div>
            <div
              className="path-name-wrapper"
              style={{
                background: "unset",
                maxWidth: "17em",
                transition: "max-width 1s ease-in-out",
              }}
            >
              <h3 className="path-name" style={{ color: "black" }}>
                {location?.state?.invoiceNo}
              </h3>
            </div>
          </div>
                    {/* <div>
                        <div className="path-name-wrapper" style={{ backgroundColor: '#c3d4f7', maxWidth: "17em" }}>
                            <h3 style={{ color: '#06066f' }} className="path-name">Invoice Margin Report</h3>
                        </div>
                    </div> */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width:'62%'
                    }}>
     <Tooltip title="Share">
              <IconButton>
                <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
              </IconButton>
            </Tooltip>

            <ReactToPrint
              trigger={() => (
                <IconButton className="printer-div" style={{ padding: "1px" }}>
                  <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
                </IconButton>
              )}
            //   content={() => paymentsPrintNew}
            />

            <Tooltip title="Download">
              <IconButton onClick={downloadList}>
                <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
              </IconButton>
            </Tooltip>

                        <CategorySearchandFilter
                            onData={handleDataFromChild}
                            isDate={false}
                            isBranch={false}
                            isFilter={false}

                        />
                    
                    </div>
                </div>
                <div className="invoice-table-container" style={{width:"100%",marginTop:"5px",marginLeft:"0",height:"85vh"}}>
                    <div className="invoice-main-div">
                      
                        <table style={{paddingTop:"5px"}}>
                            <thead>
                                <tr>
                                    <th>PRODUCT</th>
                                    <th>QTY</th>
                                    <th>UNIT PRICE</th>
                                    <th>NET SALES</th>
                                    <th>NET COST</th>
                                    <th>PROFIT</th>
                                    <th>PROFIT MARGIN %</th>
                                </tr>
                            </thead>
                            <tbody>

                                {isLoading ? (
                                    [...Array(10)].map((r, i) => (
                                        <tr key={i}>
                                            <td colSpan={8}>
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation="wave"
                                                    width={"100%"}
                                                    height={40}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                ) : invoiceSingleListData?.report !== undefined && invoiceSingleListData?.report?.length !== 0 ? (
                                    invoiceSingleListData?.report
                                        ?.slice(0)
                                        ?.map((r, i) => (
                                            <tr key={i}>
                                                <td>{r?.productName}</td>
                                                <td>{r?.qty?.toFixed(decimalPosition)}</td>
                                                <td>{symbol}&nbsp;{r?.unitPrice?.toFixed(decimalPosition)}</td>
                                                <td>{symbol}&nbsp;{r?.netSale?.toFixed(decimalPosition)}</td>
                                                <td>{symbol}&nbsp;{r?.cost?.toFixed(decimalPosition)}</td>
                                                <td>{symbol}&nbsp;{r?.profit?.toFixed(decimalPosition)}</td>
                                                <td>{r?.profitMargin?.toFixed(decimalPosition)}</td>
                                            </tr>
                                        ))
                                ) : (
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={7}>No Data</td>
                                    </tr>
                                )}
                            </tbody>
                            <tr>
                                <td style={{ fontWeight: 'bold' }} colSpan={3} >Total</td>
                                <td style={{ fontWeight: 'bold' }}>{symbol}&nbsp;{invoiceSingleListData?.total !== undefined && invoiceSingleListData?.total?.netSale?.toFixed(decimalPosition)}</td>
                                <td style={{ fontWeight: 'bold' }}>{symbol}&nbsp;{invoiceSingleListData?.total !== undefined && invoiceSingleListData?.total?.cost?.toFixed(decimalPosition)}</td>
                                <td style={{ fontWeight: 'bold' }}>{symbol}&nbsp;{invoiceSingleListData?.total !== undefined && invoiceSingleListData?.total?.profit?.toFixed(decimalPosition)}</td>
                                <td style={{ fontWeight: 'bold' }}>{invoiceSingleListData?.total !== undefined && invoiceSingleListData?.total?.profitMargin?.toFixed(decimalPosition)}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            {/* <LoadingForm loading={isLoading} /> */}
        </div>
    )
}

export default InvoiceMarginReportSingleView