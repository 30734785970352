import axios from "axios";
import { HEADERS, HEADER_QUERY } from "../../../../../API/UrlAndPaths";
import store from "../../../../../Redux/store";
import { get_all_posCategory, get_mainCategoryList, posCategorySingleView, pos_category_table_list } from "./posCategorySlice";

// list api
export const posCategoryListAPICall = () => {
  axios
    .get("inventory/viewPosCategory", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_all_posCategory({ posCategoryData: res.data }));
      }
    })
    .catch((err) => {
      console.log(err.response);
    });
};
export const PosCategoryFilterAPICall = (body,setIsLoading) => {
  setIsLoading!==undefined&&setIsLoading(true)
  axios
    .post("inventory/searchPosCategory ", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log("respo",res.data)
        store.dispatch(pos_category_table_list({ tablePosList: res.data }));
        setIsLoading!==undefined&&setIsLoading(false)
      }
    })
    .catch((err) => {
      store.dispatch(pos_category_table_list({ tablePosList: undefined }));
      setIsLoading!==undefined&&setIsLoading(false)
    });
};
// add api 
export const addPOSCategoryAPICall = (body, setState, state,clear,setLoadingForm) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  axios
    .post("inventory/posCategoryAdd", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        clear()
        setLoadingForm!==undefined && setLoadingForm(false)
        posCategoryListAPICall()
        setState({ ...state, message: "AddSuccess fully !!", success: true });
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};

// edit api
export const editPOSCategoryAPICall = (body,setState,state,setDisabledInput,setLoadingForm) => {
  setLoadingForm!==undefined && setLoadingForm(true)

  axios
    .put("inventory/editPosCategory",body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({ ...state, message: "Update successfully !!", success: true });
        setDisabledInput(true)
        setLoadingForm!==undefined && setLoadingForm(false)

      }
    })
    .catch((err) => {
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
      setLoadingForm!==undefined && setLoadingForm(false)

    });
};

export const MainCategoryAPiCall = () => {
  axios
    .post("category/view", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_mainCategoryList({ mainCategoryList: res.data }));
      }
    })
    .catch((err) => {
      console.log("ERROR");
    });
};

//DeletePosCategoryApiCall
export const DeletePosCategoryApiCall = (id, updateListner) => {
  axios
    .delete("inventory/deletePosCategory", {
      headers: HEADER_QUERY,
      data: {
        id: id,
      },
    })
    .then((res) => {
      updateListner();
    })
    .catch((err) => {
      window.alert("SOMETHING WRONG >>>");
    });
};
//pos single view api 
export const posCategorySingleViewAPI = (body) => {
  axios
    .post("inventory/posCategorySingleView", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        // store.dispatch(get_mainCategoryList({ mainCategoryList: res.data }));
        store.dispatch(posCategorySingleView({singleViewOfPos:res.data}))
      }
    })
    .catch((err) => {
      console.log("ERROR");
    });
};
