import axios from "axios";
// import io from 'socket.io-client';

//*BASE URL LOCAL

// frontend
// axios.defaults.baseURL= process.env.REACT_APP_LOCAL_BASE_URL_FRONTEND;



// SARANG 34
// axios.defaults.baseURL = process.env.REACT_APP_LOCAL_BASE_URL_SARANG;


// JOWHAR 30
// test server build ip jowhar 30
//  axios.defaults.baseURL = process.env.REACT_APP_LOCAL_BASE_URL_JOWHAR;



//  axios.defaults.baseURL = process.env.REACT_APP_LOCAL_BASE_URL_JOWHAR;
// 

// SAVAD 28
// axios.defaults.baseURL = process.env.REACT_APP_LOCAL_BASE_URL_SAVAD;


// *BASE URL PRODUCTION
 axios.defaults.baseURL = process.env.REACT_APP_PRODUCTION_BASE_URL;


// *** BASE URL PRODUCTION UAE
// axios.defaults.baseURL=process.env.REACT_APP_PRODUCTION_BASE_URL_UAE

//*HEADERS:
//json Body
export const HEADERS = {
  headers: {
    "Content-Type": "application/json",
  },
};
//Query
export const HEADER_QUERY = {
  "Content-Type": "application/json", 
};
//FormData
export const HEADER_FORM_DATA = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

// <-------- web socket connection --------------->

// export const socket = io(axios.defaults.baseURL);

// <----------------------------------------------->
