import "./App.css";
import "./css/Global Css/globalCss.css";
import Dashboard from "./component/Dashboard/Dashboard";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./component/Login/Login";
import { ProtectedRoutes } from "./ProtectedRoutes";
import PosReaderPreview from "./component/Modules/PointOfSale/posReaderPreview/PosReaderPreview";
import PrintTempOne from "./component/Modules/Accounts/PrintComponents/printTemplates/PrintTempOne";
import PrintTempTwo from "./component/Modules/Accounts/PrintComponents/printTemplates/PrintTempTwo";
import PrintTempThree from "./component/Modules/Accounts/PrintComponents/printTemplates/PrintTempThree";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="posReaderPreview/*" element={<PosReaderPreview />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="userdashboard/*" element={<Dashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
