import { Autocomplete, Dialog, IconButton, MenuItem, Select, Skeleton, TextField } from "@mui/material";
import React from "react";
import "../../../../css/Settings/Utility/utilityStockCorrections.css"
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { editUtilityStockOrderAPICall, viewStockOrderListAPICall } from "../../../../API/Settings/Utility/stockCorrectionUtility/getStockOrderListAPICall";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";


    export const UtilityStockCorrections=()=>{

        // all branch list
        const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
        const stockOrderList = useSelector((state) => state.stockOrderListSlice.value);
        const [openSingleView, setOpenSingleView] = useState(false);    
        const [categorySelected, setCategorySelected] =useState("0");    
        const [branchSelected, setBranchSelected] =useState(null);    
        const [dimensionStock,setDimensionStock]=useState([])
        const [selectedDimension,setSelectedDimension]=useState("")
        // const [dimensionStockQty,setDimensionStockQty]=useState("")
        const [isLoading,setIsLoading]=useState(false)
        const [isModify,setIsModify]=useState(false)

        // snackbar states
        const [successSnackbar,setSuccessSnackbar]=useState(false)
        const [errorSnackbar,setErrorSnackbar]=useState(false)
        const [snackMsg,setSnackMsg]=useState('Error!!')
         // snackbar functions
        const closeSuccessSnackbar=()=>{
            setSuccessSnackbar(false)
        }
        const closeErrorSnackbar=()=>{
            setErrorSnackbar(false)
        }
        const stockCorrectionBodyInitial={
            prodId:"",
            category:"",
            prodName:"",
            dimension:"",
            stock:"",
            unit:"",
            productId:"",
            dimensionStock:""
        }
        const [stockCorrectionBody,setStockCorrectionBody]=useState(stockCorrectionBodyInitial)
    
        const openProductSingleView =(singleList)=>()=>{
            setOpenSingleView(true);
            setStockCorrectionBody({...stockCorrectionBody,prodId:singleList?.prodId,category:singleList?.category,
                                                    prodName:singleList?.prodName,stock:singleList?.stock,unit:singleList?.unit,productId:singleList?._id
            })
            setDimensionStock(singleList?.dimensionStock)
            // dimensionStock.length!==0 && dimensionStock[0]
            setSelectedDimension(singleList?.dimensionStock.length!==0 &&singleList?.dimensionStock[0])
        }

        const clearStates=()=>{
            setStockCorrectionBody(stockCorrectionBodyInitial)
            setOpenSingleView(false)
        }
        const editStockCorrectionPayload={
            branchId:branchSelected,
            stock:Number(stockCorrectionBody.dimensionStock),
            productId:stockCorrectionBody.productId,
            dimension:stockCorrectionBody.dimension
        }
        const updateListener=()=>{
            clearStates()
            setIsModify(!isModify)

        }
        const editStockCorrectionSubmit=()=>{
            editUtilityStockOrderAPICall(editStockCorrectionPayload,updateListener,setSuccessSnackbar
                                                    ,setErrorSnackbar,setSnackMsg)
        }

        useEffect(()=>{
            viewAllBranchesAPICall();
        },[])
        useEffect(()=>{
            viewStockOrderListAPICall({branchId:branchSelected,type:categorySelected},setIsLoading);
        },[branchSelected,categorySelected,isModify])

        useEffect(()=>{
            if(selectedDimension.length!==0){
                setStockCorrectionBody({...stockCorrectionBody,dimensionStock:selectedDimension?.dimensionStock,
                                            dimension:selectedDimension?.dimension 
                })
            }
        },[selectedDimension])
        return(
            <>
            <div className="utility-stock-corrections-container" id="utility-stock-correction-container">
                <div className="utility-stock-corrections-container-top">
                    <p>Stock Listed</p>
                    <div className="global-single-input auto-complete">
                        <Autocomplete
                         sx={{width:"100%"}}
                         options={allBranchesList || [""]}   
                         getOptionLabel={(option)=>`${option?.storeCode}-${option?.branchName}`}
                         renderInput={(params)=>(
                            <TextField {...params} 
                            placeholder="Select a branch"/>
                         )}
                         onChange={(e,newValue)=>setBranchSelected(newValue?.storeCode)}
                        />
                    </div>
                    <div className="global-single-input">
                        <Select value={categorySelected} onChange={(e)=>setCategorySelected(e.target.value)}>
                            <MenuItem value="0">ReadyMade</MenuItem>
                            <MenuItem value="2">Material</MenuItem>
                            <MenuItem value="1">Accessory</MenuItem>
                        </Select>
                    </div>
                </div>

                <div className="global-table-container" style={{width:'98%'}}>
                    <table className="global-table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Product ID</th>
                                <th>Category</th>
                                <th>Product Name</th>
                                <th>Stock</th>
                                <th>Unit</th>
                                <th>Image</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        {
                            isLoading?
                            <tbody>
                                <tr>
                                <td colSpan={10}>
                                    {/* <Loader /> */}
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width={"100%"}
                                        height={40}
                                    />
                                </td>
                                </tr>
                                <tr>
                                <td colSpan={10}>
                                    {/* <Loader /> */}
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width={"100%"}
                                        height={40}
                                    />
                                </td>
                                </tr>
                                <tr>
                                <td colSpan={10}>
                                    {/* <Loader /> */}
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width={"100%"}
                                        height={40}
                                    />
                                </td>
                                </tr>
                                <tr>
                                <td colSpan={10}>
                                    {/* <Loader /> */}
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width={"100%"}
                                        height={40}
                                    />
                                </td>
                                </tr>
                                <tr>
                                <td colSpan={10}>
                                    {/* <Loader /> */}
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width={"100%"}
                                        height={40}
                                    />
                                </td>
                                </tr> 
                                <tr>
                                <td colSpan={10}>
                                    {/* <Loader /> */}
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width={"100%"}
                                        height={40}
                                    />
                                </td>
                                </tr> 
                                <tr>
                                <td colSpan={10}>
                                    {/* <Loader /> */}
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width={"100%"}
                                        height={40}
                                    />
                                </td>
                                </tr> 
                                <tr>
                                <td colSpan={10}>
                                    {/* <Loader /> */}
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width={"100%"}
                                        height={40}
                                    />
                                </td>
                                </tr>
                            </tbody>:
                        <tbody>
                            {
                              stockOrderList!==undefined?
                              stockOrderList?.map((r,i)=>                                
                                <tr key={i}>
                                    <td>{i+1}</td>
                                    <td>{r?.prodId}</td>
                                    <td>{r?.category}</td>
                                    <td>{r?.prodName}</td>
                                    <td>{r?.stock}</td>
                                    <td>{r?.unit}</td>
                                    <td><img src={r?.img} style={{width:'50px',height:'50px'}} alt="" /></td>
                                    <td><IconButton onClick={openProductSingleView(r)}>
                                            <i class="bi bi-pencil-square edit-icon"></i>
                                        </IconButton>
                                    </td>
                                </tr>
                                ):
                                <tr>
                                    <td colSpan={8}>No Data</td>
                                </tr>
                            }
                            
                            
                        </tbody>
                        }
                    </table>
                </div>
            </div>

            
            <Dialog open={openSingleView} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setOpenSingleView(false)}>
                <div className="stock-correction-action-parent">
                    <div className="stock-correction-top">
                        <p>Stock Correction</p>
                    </div>
                    <div className="stock-correction-body">
                        <div className="stock-correction-body-section">

                        <div className="global-single-input" style={{width:'100%',margin:'4% 1%'}}>
                            <p>Product ID</p>
                            <input type="text" value={stockCorrectionBody.prodId} disabled="disabled" />
                        </div>
                        <div className="global-single-input" style={{width:'100%',margin:'4% 1%'}}>
                            <p>Product Name</p>
                            <input type="text" value={stockCorrectionBody.prodName} disabled="disabled" />
                        </div>
                        <div className="global-single-input" style={{width:'100%',margin:'4% 1%'}}>
                            <p>Unit</p>
                            <input type="text" value={stockCorrectionBody.unit} disabled="disabled" />
                        </div>

                        </div>

                        <div className="stock-correction-body-section">

                        <div className="global-single-input" style={{width:'100%',margin:'4% 1%'}}>
                            <p>Category</p>
                            <input type="text" value={stockCorrectionBody.category} disabled="disabled" />
                        </div>
                        {
                        categorySelected!=="2" &&
                        <div className="global-single-input auto-complete" style={{width:'100%',margin:'4% 1%'}}>
                            <p>Dimension</p>
                            <Autocomplete
                             sx={{width:"100%"}}
                             options={dimensionStock}
                             getOptionLabel={(option)=>option?.dimension}
                             value={selectedDimension}
                             renderInput={(params)=>(
                                <TextField {...params}/>
                             )}
                             onChange={(e,newValue)=>setSelectedDimension(newValue)}
                            />
                        </div>
                        }
                        <div className="global-single-input" style={{width:'100%',margin:'4% 1%'}}>
                            <p>Stock</p>
                            
                            <input type="number" value={stockCorrectionBody.dimensionStock} min="0"
                                onChange={(e)=>setStockCorrectionBody({...stockCorrectionBody,dimensionStock:e.target.value})}
                            />
                            
                        </div>

                        </div>
                    </div>
                    <div className="stock-correction-bottom">
                        <button className="btn btn-red-outlined" onClick={clearStates}>Cancel</button>
                        <button className="btn btn-primary" onClick={editStockCorrectionSubmit}>Submit</button>
                    </div>
                </div>
            </Dialog>
            
            
            <SuccessSnackbar
            open={successSnackbar}
            handleClose={closeSuccessSnackbar}
            message={snackMsg}
            />
            
            <ErrorSnackbar
            open={errorSnackbar}
            handleClose={closeErrorSnackbar}
            message={snackMsg}
            />
            </>


        )
    }