
export const flowChartElement = {
    bill:'BILL',
    invoice:'INVOICE',
    delivery:'DELIVERY',
    grn:'GOODS RECEIVED NOTE',
    payment:'PAYMENT',
    purchaseOrder:'PURCHASE ORDER',
    purchaseWpo:'PURCHASE WPO',
    quotation:'QUOTATION',
    receipt:'RECEIPT',
    rfq:'REQUEST FOR QUOTATION',
    salesOrder:'SALES ORDER',
    swso:'SWSO',
}

export const chartTypes = {
    purchase:'PURCHASE',
    sales:'SALES',
}
export const navigationPath = {
    purchaseWpo :'accounts/vendor/purchasewopo',
    saleWso:'accounts/customers/saleswso',
    rfq:'purchaseManager/RequestForQuatation',
    quotation:'sales/orders/quotation',
    salesOrder:'sales/orders/salesOrder',
    delivery:'sales/orders/deliveryNote',
    invoice:'accounts/customers/customerInvoices',
    purchaseOrder:'purchaseManager/NewPurchaseOrder',
    grn:'purchaseManager/GoodsReceivedNote',
    bill:'accounts/vendor/vendorBills',
    payment:'accounts/vendor/payments',
    receipt:'accounts/customer/payments'
}