import { createSlice } from "@reduxjs/toolkit";

export const accountDashboardSlice = createSlice({
  name: "accountDashboardSlice",
  initialState: {
    accountDashBoardData : null
  },
  reducers: {
    setAccountDashboardData: (state, action) => {
      state.accountDashBoardData  = action.payload;
    },
  },
});

export const {setAccountDashboardData} = accountDashboardSlice.actions;

export default accountDashboardSlice.reducer;
