import React, { useEffect, useRef, useState } from "react";
import { viewAllCurrencyUpdated } from "../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import {
  convertArrayToExcel,
  filterObjFromList,
} from "../../../../Js/generalFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import CustomPagination from "../../../Single Components/CustomPagination";
import { convertDateFormat, today } from "../../../../Js/Date";
import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter";
import { SalesReportBySalesSingleViewAPIcall } from "./SalesReportApi";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs";

function SalesReportBySalesSingleView() {
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  let componentRef = useRef();
  let navigate = useNavigate();
  let location = useLocation();

  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const SingleListData = useSelector(
    (state) => state?.salesReport?.salesReportBySalesSingleList
  );

  console.log(SingleListData, "....SingleListData");

  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [symbol, setSymbol] = useState(null);
  const [filteredData, setFilteredData] = useState({});
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const singleViewId = localStorage.getItem("SingleViewId");
  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate) {
      parts.push(`As of ${filters?.toDate}`);
    }

    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }

    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Sales Report By Sales Person");
    sheet.properties.defaultRowHeight = 30;

    // Add header section
    const titleRow = sheet.addRow(["Sales Report By Sales Person"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells("A1:J1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:J${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "SL NO",
      "CUSTOMER",
      "INVOICE REF",
      "INVOICE AMOUNT",
      "SALES RETURN REF",
      "SALES RETURN AMOUNT",
      "TAX AMOUNT",
      "DISCOUNT AMOUNT",
      "RECEIVED AMOUNT",
      "CREDIT AMOUNT",
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "SL NO", key: "No", width: 20 },
      { header: "CUSTOMER", key: "customer", width: 25 },
      { header: "INVOICE REF", key: "invoiceRef", width: 25 },
      { header: "INVOICE AMOUNT", key: "invoiceAmount", width: 25 },
      { header: "SALES RETURN REF", key: "returnRef", width: 25 },
      { header: "SALES RETURN AMOUNT", key: "returnAmount", width: 25 },
      { header: "TAX AMOUNT", key: "tax", width: 25 },
      { header: "DISCOUNT AMOUNT", key: "discount", width: 25 },
      { header: "RECEIVED AMOUNT", key: "received", width: 25 },
      { header: "Sales By Sales Person", key: "credit", width: 25 },
    ];

    SingleListData?.report?.forEach((item,index) => {
      const row = sheet.addRow({
        No: index+1,
        staff_name: item.staff_name,
        invoiceCount: ` ${item?.invoiceCount}`,
        invoiceAmount: ` ${item?.invoiceAmount?.toFixed(2)}`,
        returnCount: ` ${item?.returnCount}`,
        returnAmount: ` ${item?.returnAmount?.toFixed(2)}`,
        tax: ` ${item?.tax?.toFixed(2)}`,
        discount: ` ${item?.discount?.toFixed(2)}`,
        receivedAmount: ` ${item?.receivedAmount?.toFixed(2)}`,
        creditAmount: ` ${item?.creditAmount?.toFixed(2)}`,
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: "thin", color: { argb: "FF000000" } },
          //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FF000000" } },
          //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber > 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "right" };
        }
      });
    });

    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      "",
      "",
      `${SingleListData?.total?.invoiceAmount.toFixed(2)}`,
      `${symbol} ${SingleListData?.total?.returnAmount?.toFixed(2)}`,
      `${SingleListData?.total?.tax?.toFixed(2)}`,
      `${symbol} ${SingleListData?.total?.discount?.toFixed(2)}`,
      `${symbol} ${SingleListData?.total?.received?.toFixed(2)}`,
      `${symbol} ${SingleListData?.total?.credit?.toFixed(2)}`,
     
    ]);

    totalRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: "FF000000" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber > 1) {
        cell.alignment = { horizontal: "right" };
      }
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Sales By Sales Person.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  const totalPages = 10;
  const decimalPosition = localStorage.getItem("decimalPosition");

  // const totalJournalKeys = SingleListData !== undefined ? Object.keys(SingleListData?.total?.journal) : [];
  // console.log(totalJournalKeys, 'lll')

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleClick = () => {
    localStorage.removeItem("SingleViewId");
    navigate("/userdashboard/sales/Report/salesReportBySalesPerson");
  };

  useEffect(() => {
    SalesReportBySalesSingleViewAPIcall({
      _id: singleViewId,
      search: FormDataInfo?.search
    });
  }, [FormDataInfo, singleViewId]);

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);
  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);
  return (
    <div className="global-page-parent-container" style={{ margin: "0" }}>
      {/* table */}
      <div className="global-white-bg-container" style={{ height: "92vh", backgroundColor: "unset", padding: "5px" }}>
        <div
          className="create-button-blue-container"
          style={{ margin: "0",alignItems:"center", display: 'flex', justifyContent: 'space-between', backgroundColor: "white", paddingInline: "10px" }}>
          <h3 style={{ display: "inline-block" }}>
            <span
              style={{ color: "gray", cursor: "pointer",fontSize: "medium" }}
              onClick={handleClick}
            >
              Sales Report By Sales Person
            </span>
            <ArrowForwardIosIcon style={{ fontSize: "medium" }} />
            EMP/001
            {/* {location.state.customerName} */}
          </h3>
         

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              // width: "50%",
            }}
          > <div style={{ marginLeft: "19px", display: "flex" }}>
          <Tooltip title="Share">
            <IconButton>
              <i
                class="bi bi-share"
                style={{
                  backgroundColor: "#9797e9",
                  color: "white",
                  fontSize: "medium",
                  padding: "8px",
                  borderRadius: "2px",
                }}
              ></i>
            </IconButton>
          </Tooltip>
          <ReactToPrint
            trigger={() => (
              <Tooltip title="Print">
                <IconButton
                  className="printer-div"
                  style={{ padding: "1px" }}
                >
                  <i
                    style={{
                      color: "white",
                      backgroundColor: " #d787e7",
                      fontSize: "medium",
                      padding: "8px",
                      borderRadius: "2px",
                    }}
                    class="bi bi-printer"
                  ></i>
                </IconButton>
              </Tooltip>
            )}
            content={() => componentRef}
          />
          <Tooltip title="Download">
            <IconButton onClick={downloadList}>
              <i
                class="bi bi-arrow-down-circle"
                style={{
                  backgroundColor: "rgb(255 118 133 / 69%)",
                  color: "white",
                  fontSize: "medium",
                  padding: "8px",
                  borderRadius: "2px",
                }}
              ></i>
            </IconButton>
          </Tooltip>

        </div>
            <CategorySearchandFilter
              // statusOptions={['POSTED', 'DRAFT']}
              onData={handleDataFromChild}
              style={{ width: "100%" }}
              isLocation={true}
              isCategory={true}
              isposCategory={true}
              isDate={false}
              isFilter={false}

            // isStatus={true}
            />
            {/* <IconButton
            style={{ width: "100px" }}

            className="print-icon-container">
            <i class="bi bi-printer printer-icon"></i>
          </IconButton>
          <IconButton
            style={{ width: "100px" }}
            className="download-icon-container">
            <i class="bi bi-arrow-down-circle download-icon"></i>
          </IconButton> */}
          </div>
        </div>
       

        <div className="customer-receipt-table-container" style={{marginTop:"4px",maxHeight:"85vh",height:'85vh'}}>
          <table>
            <thead>
              <tr>
                <th>SL NO</th>
                <th>CUSTOMER</th>
                <th>INVOICE REF</th>
                <th>INVOICE AMOUNT</th>
                <th>SALES RETURN REF</th>
                <th>SALES RETURN AMOUNT</th>
                <th>TAX AMOUNT</th>
                <th>DISCOUNT AMOUNT</th>
                <th>RECEIVED AMOUNT</th>
                <th>CREDIT AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : SingleListData?.report !== undefined &&
                SingleListData?.report?.length !== 0 ? (
                SingleListData?.report?.slice(0)?.reverse()?.map((r, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{r?.customer}</td>
                    {/* <td>{symbol}&nbsp;{r?.discount?.toFixed(decimalPosition)}</td> */}
                    <td>{r?.invoiceRef}</td>
                    <td >
                      {r?.invoiceAmount?.toFixed(decimalPosition)}
                    </td>
                    <td>{r?.returnRef}</td>
                    <td>
                      {r?.returnAmount?.toFixed(decimalPosition) || "0.00"}
                    </td>
                    <td>
                      {r?.tax?.toFixed(decimalPosition) || "0.00"}
                    </td>
                    <td>
                      {r?.discount?.toFixed(decimalPosition)}
                    </td>
                    <td>
                      {r?.received?.toFixed(decimalPosition)}
                    </td>
                    <td>
                      {r?.credit?.toFixed(decimalPosition)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td style={{ textAlign: "center" }} colSpan={10}>
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <th
                  style={{
                    paddingLeft: "2%",
                    textAlign: "start",
                    fontSize: "0.8rem",
                  }}
                  colSpan={3}
                >
                  TOTAL
                </th>
                <th
                  style={{ paddingLeft: "3%", textAlign: "start" }}
                  colSpan={2}
                >
                  {symbol}&nbsp;
                  {SingleListData?.total !== undefined &&
                    SingleListData?.total?.invoiceAmount?.toFixed(
                      decimalPosition
                    )}
                </th>
                <th>
                  {symbol}&nbsp;
                  {SingleListData?.total !== undefined &&
                    SingleListData?.total?.returnAmount?.toFixed(
                      decimalPosition
                    )}
                </th>
                <th>
                  {symbol}&nbsp;
                  {SingleListData?.total !== undefined &&
                    SingleListData?.total?.tax?.toFixed(decimalPosition)}
                </th>
                <th>
                  {symbol}&nbsp;
                  {SingleListData?.total !== undefined &&
                    SingleListData?.total?.discount?.toFixed(decimalPosition)}
                </th>
                <th>
                  {symbol}&nbsp;
                  {SingleListData?.total !== undefined &&
                    SingleListData?.total?.received?.toFixed(decimalPosition)}
                </th>
                <th>
                  {symbol}&nbsp;
                  {SingleListData?.total !== undefined &&
                    SingleListData?.total?.credit?.toFixed(decimalPosition)}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>



        {/* print container */}


      </div>
      <div style={{ display: "none" }}>
        <div
          ref={(el) => (componentRef = el)}
          className="print-container-trail-balance"
          style={{ "@media print": { "@page": { size: "landscape" } } }}
        >
          <div className="trailbalnce-print-header">
            <div className="company-logo-container">
              <img
                height={100}
                width={100}
                src={userInfo?.logo || "http://placehold.it/100x100"}
                alt=""
              />
            </div>
            <div className="trail-balance-print-title">
              <span>Sales By Sales Person</span>
              <span style={{ fontSize: "13px" }}> As Off {today}</span>
            </div>
            <div className="company-details-container">
              <span>{userInfo?.data?.profile?.companyName} </span>
              <span>{userInfo?.data?.profile?.companyAddress} </span>
              <span>{userInfo?.data?.profile?.companyEmail}</span>
            </div>
          </div>
          <div>
            <div className="customer-receipt-table-container">
              <table>
                <thead>
                  <tr>
                    <th>SL NO</th>
                    <th>CUSTOMER</th>
                    <th>INVOICE REF</th>
                    <th>INVOICE AMOUNT</th>
                    <th>SALES RETURN REF</th>
                    <th>SALES RETURN AMOUNT</th>
                    <th>TAX AMOUNT</th>
                    <th>DISCOUNT AMOUNT</th>
                    <th>RECEIVED AMOUNT</th>
                    <th>CREDIT AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    [...Array(10)].map((r, i) => (
                      <tr key={i}>
                        <td colSpan={10}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                          />
                        </td>
                      </tr>
                    ))
                  ) : SingleListData?.report !== undefined &&
                    SingleListData?.report?.length !== 0 ? (
                    SingleListData?.report?.slice(0)?.map((r, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{r?.customer}</td>
                        {/* <td>{symbol}&nbsp;{r?.discount?.toFixed(decimalPosition)}</td> */}
                        <td>{r?.invoiceRef}</td>
                        <td>
                          {symbol}&nbsp;{r?.invoiceAmount?.toFixed(decimalPosition)}
                        </td>
                        <td>{r?.returnRef}</td>
                        <td>
                          {symbol}&nbsp;{r?.returnAmount?.toFixed(decimalPosition)}
                        </td>
                        <td>
                          {symbol}&nbsp;{r?.tax?.toFixed(decimalPosition)}
                        </td>
                        <td>
                          {symbol}&nbsp;{r?.discount?.toFixed(decimalPosition)}
                        </td>
                        <td>
                          {symbol}&nbsp;{r?.received?.toFixed(decimalPosition)}
                        </td>
                        <td>
                          {symbol}&nbsp;{r?.credit?.toFixed(decimalPosition)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td style={{ textAlign: "center" }} colSpan={10}>
                        No Data
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th
                      style={{
                        paddingLeft: "2%",
                        textAlign: "start",
                        fontSize: "0.8rem",
                      }}
                      colSpan={3}
                    >
                      TOTAL
                    </th>
                    <th
                      style={{ paddingLeft: "4%", textAlign: "start" }}
                      colSpan={2}
                    >
                      {symbol}&nbsp;
                      {SingleListData?.total !== undefined &&
                        SingleListData?.total?.invoiceAmount?.toFixed(
                          decimalPosition
                        )}
                    </th>
                    <th>
                      {symbol}&nbsp;
                      {SingleListData?.total !== undefined &&
                        SingleListData?.total?.returnAmount?.toFixed(
                          decimalPosition
                        )}
                    </th>
                    <th>
                      {symbol}&nbsp;
                      {SingleListData?.total !== undefined &&
                        SingleListData?.total?.tax?.toFixed(decimalPosition)}
                    </th>
                    <th>
                      {symbol}&nbsp;
                      {SingleListData?.total !== undefined &&
                        SingleListData?.total?.discount?.toFixed(decimalPosition)}
                    </th>
                    <th>
                      {symbol}&nbsp;
                      {SingleListData?.total !== undefined &&
                        SingleListData?.total?.received?.toFixed(decimalPosition)}
                    </th>
                    <th>
                      {symbol}&nbsp;
                      {SingleListData?.total !== undefined &&
                        SingleListData?.total?.credit?.toFixed(decimalPosition)}
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesReportBySalesSingleView;
