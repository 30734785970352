import axios from "axios"
import { HEADERS, HEADER_FORM_DATA } from "../../../../../API/UrlAndPaths"
import store from "../../../../../Redux/store"
import { get_internal_transfer_list, get_internal_transfer_name_view, get_internal_transfer_single_view } from "./InternalTransferSlice"

export const viewInternalTransferAPICall=(setIsLoading)=>{
  setIsLoading!==undefined && setIsLoading(true)
    axios.post("inventory/viewinternaltransfer",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_internal_transfer_list({internalTransferData:res.data}))
            setIsLoading!==undefined && setIsLoading(false)
        }
    })
    .catch((err)=>{
        console.log(err.response);
        setIsLoading!==undefined && setIsLoading(false)
    })
}
export const InternalTransferFilterAPICall =(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)
  axios.post("inventory/searchInternalTransfers",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_internal_transfer_list({internalTransferData:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)

    }
  })
  .catch((err)=>{
    store.dispatch(get_internal_transfer_list({internalTransferData:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)
  })

}

export const addInternalTransferAPICall=(
  body,postFn,setState,state,setEditDataId,setLoadingForm
    )=>{
    setLoadingForm!==undefined && setLoadingForm(true)

    axios.post("inventory/addinternaltransfer",body,HEADER_FORM_DATA)
    .then((res)=>{
        if(res.status===200){
          setLoadingForm!==undefined && setLoadingForm(false)

          setState({...state,message:"Internal Transfer drafted",success:true})
          postFn()
          setEditDataId(res.data?._id)
            store.dispatch(get_internal_transfer_single_view({singleInternalTransferData:res.data}))
        }
    })
    .catch((err)=>{
      setLoadingForm!==undefined && setLoadingForm(false)

      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
    } else {
        setState({ ...state, message: err?.response?.data, error: true })
    }
    })
}

export const EditInternalTransferAPICall=(
  body,postFn,setState,state,setLoadingForm
    )=>{
      setLoadingForm!==undefined && setLoadingForm(true)

    axios.post("inventory/editinternaltransfer",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
          setLoadingForm!==undefined && setLoadingForm(false)

          setState({...state,message:"Internal Transfer edited",success:true})
          postFn()
        }
    })
    .catch((err)=>{
      setLoadingForm!==undefined && setLoadingForm(false)

      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
    } else {
        setState({ ...state, message: err?.response?.data, error: true })
    }
    })
}


export const generateInternalTransferNameAPICall=()=>{
    axios.post("inventory/generateInternaltransNo",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
          store.dispatch(get_internal_transfer_name_view({InternalTransferNameData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
        // setSnackMsg(err.response?.data?.codeprefix)
        // setErrorSnackOpen(true)
    })
}


export const postInternalTransferAPICall=(
  body,postFn,setState,state,setLoadingForm
    )=>{
      setLoadingForm!==undefined && setLoadingForm(true)

    axios.post("inventory/postInternaltransfer",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
      setLoadingForm!==undefined && setLoadingForm(false)

          setState({...state,message:"Internal Transfer posted !!",success:true})
          postFn!==undefined && postFn()
        }
    })
    .catch((err)=>{
      setLoadingForm!==undefined && setLoadingForm(false)


      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    })
}

// internal transfer single view
export const internalTransferSingleViewAPICall=(body)=>{
  axios.post("inventory/singleViewInternalTransfers",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_internal_transfer_single_view({singleInternalTransferData:res.data}))

    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}

export const deleteInternalTransferAPICall=async(body)=>{
  await axios.post("inventory/deleteInternalTransfer",body,HEADERS)
    .then((res)=>{
      if(res.status===200){
        viewInternalTransferAPICall()
      }
    })
    .catch((err)=>{
      console.error(err.response);
    })
}