import React, { useState } from "react";
import "../../../../css/Product List/Pages/productList.css";
import { Dialog, IconButton, Skeleton, Tooltip } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import DemoBarcode from "../../../../Assets/Images/barcode.png";
import NoImage from "../../../../Assets/Images/no-image.png";
import { useEffect } from "react";
import {
  ProductListFilterAPICall,
} from "../../../../API/Product List/productListAPI";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";

import { sortGeneral } from "../../../../Js/generalFunctions";
import { useSelector } from "react-redux";
import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../Single Components/CustomPagination";
import { useNavigate } from "react-router-dom";
import store from "../../../../Redux/store";
import { setFilterActive } from "../../../Single Components/listSlice/filterCategorySlice";
import { update_product_list_single_view } from "../../../../Redux/Product List/allProductsListSlice";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs"
import TableListActionButtons from "../../../Single Components/ActionButtons/TableListActionButtons";

export const ProductList = () => {
  const navigateTo = useNavigate()

  // store
  const allProductsList = useSelector(
    (state) => state.allProductsListSlice.value
  );
  const filtereDataOptions = useSelector((state) => state.listCategory);

  const [filteredData, setFilteredData] = useState({});
  //Loading State
  const [isLoading, setIsLoading] = useState(false);
  //Stock Ascending or descending
  const [isAscending, setIsAscending] = useState(false);
  //Snackbar States
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [snackMsg, setSnackMsg] = useState(
    "Failed to fetch data, refresh page to try again!!"
  );
  const [importPopup, setImportPopup] = useState(false)

  const [productsFiltered, setProductsFiltered] = useState([]);
  //Single View Dialog States
  const [openSingleView, setOpenSingleView] = useState(false);
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const totalPages = productsFiltered?.pages;

  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);

  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  //Search Filtered Array


  //Single View States
  const singleViewInitialStates = {
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    barcode: "",
    productName: "",
    nativeproductName: "",
    productId: "",
    releaseDate: "",
    mrp: "",
    rate: "",
    calculatedPrice: "",
    size: [],
    color: "",
    fitting: "",
    productCare: "",
    materialComposition: "",
    stock: "",
    styleName: "",
    pattern: "",
    unit: "",
    material: "",
    brand: "",
    countryOfOrigin: "",
    returnPolicy: "",
    description: "",
    additionalInfos: [],
    youtubeUrl: "",
  };
  const [singleViewStates, setSingleViewStates] = useState(
    singleViewInitialStates
  );

  //Close Success Snackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnackbar(false);
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  //Close Snackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };
  //Close Single View
  const closeProductSingleView = () => {
    setOpenSingleView(false);
  };
  //Sort Ascending Order by Stock
  const sortStockAscending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(productsFiltered, "Number", "closingStock", true);
    setProductsFiltered(sorted);
  };
  //Sort descending Order by Stock
  const sortStockDescending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(productsFiltered, "Number", "closingStock", false);
    setProductsFiltered(sorted);
  };

  //Sort Ascending Order by Name
  //TODO Sot by string is not accurate(19-10-22)
  const sortProdNameAscending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(productsFiltered, "String", "name", true);
    setProductsFiltered(sorted);
  };
  //Sort Descending order by Name
  const sortProdNameDescending = () => {
    setIsAscending(!isAscending);
    let sorted = sortGeneral(productsFiltered, "String", "name", false);
    setProductsFiltered(sorted);
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };

  const clickRow = (row) => {
    store.dispatch(update_product_list_single_view({ productListSingleView: undefined }))
    localStorage.setItem("singleViewById", row?._id)
    store.dispatch(setFilterActive(window.location.pathname))

    navigateTo("/userdashboard/productList/singleview")
  }

  const formatFilterInfo = (filters) => {
    const parts = [];
  
    if (filters?.toDate&&filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    }
    if (filters?.location) {
      parts.push(`location: ${filters?.location?.name}`);
    }
    if (filters?.category) {
      parts.push(`category: ${filters?.category?.categoryName}`);
    }
    if (filters?.posCategory) {
      parts.push(`pos Category: ${filters?.posCategory?.name}`);
    }
   
    
    return parts; // Return the array directly
  };

  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Product List");
    sheet.properties.defaultRowHeight = 20;
  
    // Add header section
    const titleRow = sheet.addRow(["Product List"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells('A1:J1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];
  
    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:J${index + 2}`);
        row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      });
    }
  
    // Add an empty row between header and table
    sheet.addRow([]);
  
    // Header row styling
    const headerRow = sheet.addRow(["Product ID", "Product Name", "Attributes", "UOM","Purchase UOM","POS Category","Barcode","Closing Stock","Unit Cost","Stock Value"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };
  
    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
  
      // Change font color for specific headers
      if (colNumber === 8) { // Closing Stock
        cell.font = { size: 12, bold: true, color: { argb: "FFFF0000" } }; // Red font color
      }
      if (colNumber === 9) { // Unit Cost
        cell.font = { size: 12, bold: true, color: { argb: "FF0000FF" } }; // Blue font color
      }
    })
  
    sheet.columns = [
      { header: "Product ID", key: "prodId", width: 18 },
      { header: "Product Name", key: "name", width: 25 },
      { header: "Attributes", key: "value", width: 25 },
      { header: "UOM", key: "UOMNAME", width: 15 },
      { header: "Purchase UOM", key: "PUCHASEUOMNAME", width: 15 },
      { header: "POS Category", key: "POSCATNAME", width: 15 },
      { header: "Barcode", key: "barcode", width: 25 },
      { header: "Closing Stock", key: "closingStock", width: 25 },
      { header: "Unit Cost", key: "cost", width: 20 },
      { header: "Products List", key: "stockvalue", width: 25 },
    ];
  
    productsFiltered?.list?.forEach((item) => {
      const row = sheet.addRow({
        prodId: item.prodId,
        name: item.name,
        value: item?.value||"",
        UOMNAME: item?.UOMNAME,
        PUCHASEUOMNAME: item?.PUCHASEUOMNAME,
        POSCATNAME: item?.POSCATNAME,
        barcode: item?.barcode,
        closingStock: ` ${Number(item?.closingStock)?.toFixed(2)}`,
        cost: ` ${item?.cost?.toFixed(2)}`,
        stockvalue: ` ${Number(item?.stockvalue)?.toFixed(2)}`,
      
      });
  
      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };
      
      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
        //   top: { style: "thin", color: { argb: "FF000000" } },
        //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
        //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) { // Right align all columns except the first
            cell.alignment = { horizontal: 'center' };
          }
          if(colNumber===8){
            cell.font={color:{argb:"FFFF0000"}}
          }
      });
    });
  
    // Add row for totals
    // const totalRow = sheet.addRow([
    //   "Total",
    //   "",
    //   `${symbol} ${SingleListData?.sumOfCurrent.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf30?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf60?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf90?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf120?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOfAbove120?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOfTotal?.toFixed(2)}`,
    // ]);
  
    // totalRow.eachCell((cell,colNumber) => {
    //   cell.font = { bold: true, color: { argb: "FF000000" } };
    //   cell.fill = headerFill;
    //   cell.border = {
    //     top: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     left: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     right: { style: "thin", color: { argb: "FFFFFFFF" } },
    //   };
    //   if (colNumber > 1) { // Right align all columns except the first
    //     cell.alignment = { horizontal: 'right' };
    //   }
    // });
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Product List.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    setProductsFiltered(allProductsList || []);
  }, [allProductsList]);



  useEffect(() => {
    if (FormDataInfo?.length !== 0) {

      let loadData = window.location.pathname === filtereDataOptions?.filterActive

      if (!loadData) {
        ProductListFilterAPICall({
          branchList: FormDataInfo?.branchList,
          location: FormDataInfo?.location,
          mainCategory: FormDataInfo?.category,
          posCategory: FormDataInfo?.posCategory,
          search: FormDataInfo?.search,
          index: currentPage - 1,
        }, setIsLoading);
      }
    }
  }, [FormDataInfo,
    currentPage
  ]);
  return (
    <>
      <div className="global-page-parent-container updated-global-page-parent-container">
      <div className="justify-space-between list-create-head-bar">
          <h3>Products List</h3>
          <div className="create-head-buttons-container">
            <TableListActionButtons downloadClick={downloadList}/>
            <CategorySearchandFilter
                  onData={handleDataFromChild}
                  style={{ width: "100%" }}
                  isLocation={true}
                  isCategory={true}
                  isposCategory={true}
                  isDate={false}
                  onBranchSelect={handleBranchSelect}
                  onFilterChange={handleFilterChange}
                />
          </div>  
      </div>
      <div className="justify-space-between pagination-container">
            {FormDataInfo?.branchList?.length > 0 && (
                <h4>Branch name:
                  {selectedBranch?.storeCode || filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName || filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h4>
            )}
            <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                setFormDataInfo={setFormDataInfo}
              />
      </div>


          <div className='new-global-table-container updated-table-container'>

            <table>
              <thead>
                <tr>
                  <th>Product ID</th>
                  <th>
                    Product Name
                    {isAscending ? (
                      <IconButton onClick={sortProdNameAscending}>
                        <ArrowUpward sx={{ fontSize: "1rem" }} />
                      </IconButton>
                    ) : (
                      <IconButton onClick={sortProdNameDescending}>
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                      </IconButton>
                    )}
                  </th>
                  <th>Attributes</th>
                  <th>UOM</th>
                  <th>Purchase UOM</th>
                  <th>POS Category</th>
                  <th>Barcode</th>
                  <th className="closing-stock-column" style={{ color: "red" }}>
                    Closing Stock{" "}
                    {isAscending ? (
                      <IconButton onClick={sortStockAscending}>
                        <ArrowUpward sx={{ fontSize: "1rem" }} />
                      </IconButton>
                    ) : (
                      <IconButton onClick={sortStockDescending}>
                        <ArrowDownward sx={{ fontSize: "1rem" }} />
                      </IconButton>
                    )}
                  </th>
                  <th style={{ color: "blue" }}>Unit Cost</th>
                  <th className="closing-stock-column">
                    Stock Value
                  </th>
                </tr>
              </thead>
              <tbody >
                {

                  isLoading ? (
                    [...Array(10)].map((r, i) => (
                      <tr key={i}>
                        <td colSpan={11}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                          />
                        </td>
                      </tr>
                    ))
                  ) :
                    (productsFiltered?.length !== 0 && productsFiltered?.list?.length !== 0) ? productsFiltered?.list?.slice(0)?.reverse()?.map((r, i) => (
                      <tr key={i} onClick={() => clickRow(r)}>
                        <td>{r?.prodId}</td>
                        <td>{r?.name}</td>
                        <td className='table-data-box' style={{ textAlign: "center" }}>
                          <div className='table-data-round-parent'>

                            {
                              r?.attribute?.map((r, i) => (
                                <div className='round-border' key={i}>{r?.value}</div>
                              ))
                            }
                          </div>
                        </td>
                        <td>{r?.UOMNAME}</td>
                        <td>{r?.PUCHASEUOMNAME}</td>
                        <td>{r?.POSCATNAME}</td>
                        <td>{r?.barcode}</td>
                        <td className="closing-stock-column" style={{ color: "red" }}>
                          {Number(r?.closingStock)?.toFixed(2)}
                        </td>
                        <td>{r?.cost?.toFixed(2)}</td>
                        <td className="closing-stock-column">
                          {Number(r?.stockvalue)?.toFixed(2)}
                        </td>
                      </tr>
                    ))
                      :
                      <tr>
                        <td colSpan={11}>No Data</td>
                      </tr>
                }

              </tbody>
            </table>
          </div>
       

      </div>

      {/* Product Single View Dialog */}
      <Dialog open={openSingleView} maxWidth="lg" onKeyDown={(e) => e.key === "Escape" && setOpenSingleView(false)}>
        <div className="product-single-view-parent">
          <div className="product-single-view-top-bar">
            <h2>Product View</h2>
          </div>
          <div className="product-single-view-content">
            <div className="product-single-left">
              <div className="carousel-container">
                <Carousel>
                  <img src={singleViewStates.image1 || NoImage} alt="" />
                  <img src={singleViewStates.image2 || NoImage} alt="" />
                  <img src={singleViewStates.image3 || NoImage} alt="" />
                  <img src={singleViewStates.image4 || NoImage} alt="" />
                </Carousel>
              </div>
              <div className="barcode-container">
                <p>Barcode</p>
                <div className="barcode-img-container">
                  <img src={DemoBarcode} alt="barcode" />
                </div>
              </div>
            </div>
            <div className="product-single-right">
              <div className="single-data-row">
                <p className="label">Product Name</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.productName || "-NIL-"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Product ID</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.productId || "-NIL-"}
                </p>
              </div>
              {/* show MRP only in India  */}
              <div className="single-data-row">
                <p className="label">MRP </p>
                <p className="colon">:</p>
                <p className="prod-name">{singleViewStates.mrp || "-NIL-"}</p>
              </div>
              <div className="single-data-row">
                <p className="label"> Rate </p>
                <p className="colon">:</p>
                <p className="prod-name">{singleViewStates.rate || "-NIL-"}</p>
              </div>
              <div className="single-data-row">
                <p className="label">Calculated Selling Price</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.calculatedPrice || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Size</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates?.size?.map((s) => (
                    <span>{s?.brandUnit},</span>
                  )) || "-NIL-"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Color</p>
                <p className="colon">:</p>
                <p className="prod-name"> {singleViewStates.color || "-NIL"}</p>
              </div>
              <div className="single-data-row">
                <p className="label">Fitting</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {" "}
                  {singleViewStates.fitting || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Product Care</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.productCare || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Material Composition</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.materialComposition || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Stock</p>
                <p className="colon">:</p>
                <p className="prod-name">{singleViewStates.stock || "-NIL"}</p>
              </div>

              <div className="single-data-row">
                <p className="label">Style Name</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.styleName || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Pattern</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.pattern || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Unit</p>
                <p className="colon">:</p>
                <p className="prod-name">{singleViewStates.unit || "-NIL"}</p>
              </div>
              <div className="single-data-row">
                <p className="label">Material</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.material || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Brand</p>
                <p className="colon">:</p>
                <p className="prod-name">{singleViewStates.brand || "-NIL"}</p>
              </div>
              <div className="single-data-row">
                <p className="label">Country of Origin</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.countryOfOrigin || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Return Policy</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {(singleViewStates.returnPolicy &&
                    `within ${singleViewStates.returnPolicy} days`) ||
                    "-NIl-"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Description</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.description || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Additional Info</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.additionalInfos?.map((info) => (
                    <span>{info},</span>
                  )) || "-NIL"}
                </p>
              </div>
              <div className="single-data-row">
                <p className="label">Youtube URL</p>
                <p className="colon">:</p>
                <p className="prod-name">
                  {singleViewStates.youtubeUrl || "-NIL"}
                </p>
              </div>
            </div>
          </div>
          <div className="close-btn-container">
            <button
              className="btn btn-primary"
              onClick={closeProductSingleView}
            >
              CLOSE
            </button>
          </div>
        </div>
      </Dialog>


      <SuccessSnackbar
        open={openSuccessSnackbar}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnackbar}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
