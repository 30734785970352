import React from 'react'
import RedTag from "../../Billing/image/comboPrice.svg"
import {  useSelector } from "react-redux";

function ComboPriceTag(props) {

  const {amount}=props  
  const {currencyDetails}=useSelector((state)=>state.mainDashBoardSlice)

  return (
    <div className='combo-tag-ctn'>
        <img src={RedTag} alt="" />
        <p>{currencyDetails?.symbol} {amount===""?"0.00":amount}</p>
    </div>
  )
}

export default ComboPriceTag