import axios from "axios";
import { HEADERS } from "../../../API/UrlAndPaths";
import store from "../../../Redux/store";
import {
  setAlertMessage,
  setIsLoading,
  setKotOrderList,
  setSideMenuList,
} from "./kitchenOrderTicketSlice";
import { alertMessages } from "../FloorManagement/utils/floorManagementEnum";
import { actionKeyName } from "./utils/kotEnum";
import { setTableLoadingGlobal } from "../../../Redux/Dashboard/mainDashBoardSlice";

//list KOT orders API
export const listKotOrderAPI = async (obj) => {
  store.dispatch(setTableLoadingGlobal(true))
  try {
    const result = await axios.post(
      "pos/viewKotOrdersWithFilter",
      obj.body,
      HEADERS
    );
    if (result.status === 200) {
      store.dispatch(setTableLoadingGlobal(false))
      store.dispatch(setIsLoading(false));
      store.dispatch(setKotOrderList(result.data));
    }
  } catch (error) {
    store.dispatch(setKotOrderList([]));
    store.dispatch(setTableLoadingGlobal(false))
    store.dispatch(setIsLoading(false));
    store.dispatch(
      setAlertMessage({
        status: alertMessages.ERROR,
        message: error.response.data || "Error occurred while fetching data!",
      })
    );
  }
};

// KOT order action API
export const kotOrderActionAPI = async (obj) => {
  let endPoint;
  switch (obj.actionKeyName) {
    case actionKeyName.TOCOOK:
      endPoint = `kotStatusToCook`;
      break;
    case actionKeyName.REJECT:
      endPoint = ``;
      break;
    case actionKeyName.COMPLETED:
      endPoint = `kotStatusToCompleted`;
      break;
    case actionKeyName.DELIVERY:
      endPoint = `kotStatustoDelivered`;
      break;
    case actionKeyName.RESET:
      endPoint = `kotStatusResetToPreparing`;
      break;
    default:
      endPoint = ``;
      break;
  }

  try {
    store.dispatch(setIsLoading(true));
    const response = await axios.post(`pos/${endPoint}`, obj.body, HEADERS);
    if (response.status === 200) {
      store.dispatch(setIsLoading(false));
    }
  } catch (error) {
    store.dispatch(setIsLoading(false));
    store.dispatch(
      setAlertMessage({
        status: alertMessages.ERROR,
        message: error.response.data || "Error occurred while updating!",
      })
    );
  }
};

//KOT side menu list api
export const kotSideMenuListApi = async (obj) => {
  try {
    store.dispatch(setIsLoading(true));
    const response = await axios.post(
      `pos/listProductCountInKot`,
      obj.body,
      HEADERS
    );
    if (response.status === 200) {
      store.dispatch(setIsLoading(false));
      store.dispatch(setSideMenuList(response.data));
    }
  } catch (error) {
    console.error(error)
    store.dispatch(setSideMenuList([]));
    store.dispatch(setIsLoading(false));
    store.dispatch(
      setAlertMessage({
        status: alertMessages.ERROR,
        message: error.response.data || "Error occurred ",
      })
    );
  }
};

//KOT side menu sort product list
export const viewKOTSortProductListAPI = async (obj) => {
  try {
    store.dispatch(setIsLoading(true));
    const response = await axios.post(
      `pos/viewKotOrdersWithProdFilter`,
      obj.body,
      HEADERS
    );
    if (response.status === 200) {
      store.dispatch(setIsLoading(false));
      store.dispatch(setKotOrderList(response.data));
    }
  } catch (error) {
    store.dispatch(setIsLoading(false));
    store.dispatch(
      setAlertMessage({
        status: alertMessages.ERROR,
        message: error.response.data || "Error occurred ",
      })
    );
  }
};

//KOT update single product status
export const updateSingleProductStatus = async (obj) => {
  try {
    const response = await axios.put(
      `pos/setEachProductToCompleted`,
      obj.body,
      HEADERS
    );
    if (response.status === 200) {
    }
  } catch (error) {
    console.error(error);
  }
};

//KOT update all order status 
export const updateAllOrderStatus = async (obj) => {
  const endpoints = {
    1: 'kotStatusToPreparingAll',
    2: 'kotStatusToCompleteAll',
    3: 'kotStatusToDeliveredAll'
  };

  const endpoint = endpoints[obj.index];
  try {
    const response = await axios.put(
      `pos/${endpoint}`,
      obj.body,
      HEADERS
    );
    if (response.status === 200) {
      obj.setPopup(false)
    }
  } catch (error) {
    console.error(error);
    store.dispatch(
      setAlertMessage({
        status: alertMessages.ERROR,
        message: error.response.data || "Error occurred ",
      })
    );
  }
};
