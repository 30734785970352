import axios from 'axios'
import { getCurrentTime, today } from '../../../Js/Date';
import {HEADERS} from '../../UrlAndPaths'
import { getShiftStatusAPICAll } from './viewShiftAPI';
import { setSubmitLoading } from '../../../Redux/Dashboard/mainDashBoardSlice';
import store from '../../../Redux/store';

let branchId=localStorage.getItem('branchId')
//shift start api call
export const startManualShiftAPICall=async(
    body,
    setShiftBtnState,
    shiftBtnState,
    handleReset,
    handleStart,
    setErrorSnack,
    setErrorMsg
    )=>{
    store.dispatch(setSubmitLoading(true))
    await axios.post("settings/startmanualshift",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            handleReset()
            handleStart()
            setShiftBtnState(!shiftBtnState);
            getShiftStatusAPICAll({branchId,curTime:`${today} ${getCurrentTime()}`})
            store.dispatch(setSubmitLoading(false))
        }
    })
    .catch((err)=>{
        store.dispatch(setSubmitLoading(false))
        if(err.response.status === 500){
            setErrorMsg('Internal Server error !')
          }
          else{
            setErrorMsg(err?.response?.data || "Network connection failed !!")
          }
        setErrorSnack(true)

    })
}

// shift end api call
export const endManualShiftAPICall= async (
    body,
    handlePause,
    setShiftEndPasswordDialog,
    setShiftBtnState,
    shiftBtnState,
    setErrorSnack,
    setErrorMsg
    )=>{
    store.dispatch(setSubmitLoading(true))
    await axios.post("settings/endmanualshift",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            handlePause()
            setShiftEndPasswordDialog(false);
            setShiftBtnState(!shiftBtnState);
            localStorage.removeItem('shiftStatus')
            store.dispatch(setSubmitLoading(false))
            window.location.reload()
        }
    })
    .catch((err)=>{
        if(err.response.status === 500){
            setErrorMsg('Internal Server error !')
          }
          else{
            setErrorMsg(err?.response?.data)
          }
        setErrorSnack(true)
        store.dispatch(setSubmitLoading(false))
    })
}

export const addShiftDenominationAPICall=async(body,denominationUpdated)=>{
    await axios.post("settings/addDenomination",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                denominationUpdated()
            }
        })
        .catch((err)=>{
            console.error(err.response.status);
        })
}

// 