export const printSection = {
    rfq:'RFQ',
    purchaseOrder:'Purchase Order',
    goodsReceiveNote: "GRN",
    salesOrder:"salesOrder",
    deliveryNote:"deliveryNote",
    customerInvoice:"customerInvoice",
    salesWSO:"salesWSO",
    creditNote:"creditNote",
    vendorBill:"vendorBill",
    purchaseWPO:"purchaseWPO",
    debitNote:"debitNote",
    salesQuotation:"salesQuotation",
}
