import {
  Autocomplete,
  Checkbox,
  Dialog,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import uploadFile from "../../../../../Assets/Images/Icon feather-upload.png";
import { CloseOutlined } from "@mui/icons-material";
import logoImage from "../../../../../Assets/Images/Icon ionic-logo-buffer.png";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentTime, today } from "../../../../../Js/Date";
import { useLocation, useNavigate } from "react-router-dom";
import { chartOfAccountListApiCall } from "../../../../../API/AccountUpdated/chartOfAccountAPI";
import { journalListApiCall } from "../../../../../API/AccountUpdated/journalAPI";
import {
  companyProfileInfoForUser,
  viewAllAllowedBranchesAPICall,
  viewAllBranchesAPICall,
} from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { getAllActiveEmployeesAPICall } from "../../../../../API/Staff/HRM/employeeAPI";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import {
  customerPaymentSingleViewApi,
  draftCustomerPaymentAPICall,
  editCustomerPaymentAPICall,
  generateCustomerPaymentNameAPICall,
  postCustomerPaymentAPICall,
  returnCustomerPaymentAPI,
  showCustomerInvoiceApi,
  updateCustomerPaymentAPI,
} from "./customerPaymentAPI";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import postRibbon from "../../../../../Assets/statusRibbon/post.png";
import draftRibbon from "../../../../../Assets/statusRibbon/draft.png";
import returned from "../../../../../Assets/statusRibbon/return.png";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { viewMultiCurrencyConfiguration } from "../../../../../API/Purchase Manager/configuration";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import Template from "./CustomerPaymentPrint";
import TemplateCustomer from "./CustomerPaymentCustomerPrint";
import ReactToPrint from "react-to-print";
import {
  set_Back_button_action,
  setFilterActive,
} from "../../../../Single Components/listSlice/filterCategorySlice";
import { customerFilterOptions } from "../../../../Single Components/GneralUtilities";
import { get_customer_payment_row_data_list } from "./customerPaymentSlice";

const styles = `
  .advs-pay-amt-cntr{
    display:flex;
    justify-content:flex-end;
  
  }
  .advs-pay-amt-cntr > div {
    display:inherit;
    flex-direction:column;
    padding: 1em;
    // width:20%;
    gap: 0.5em;
  }
  .advs-pay-amt-cntr  .adv-amt-cntr{
    display:inherit;
    font-weight: 600;
  }
  .advs-pay-amt-cntr  .adv-amt-cntr.adv-tt-amt{
    font-size: 20px;
  }
  .advs-pay-amt-cntr  .adv-amt-cntr.adv-tt-amt :nth-child(1){
    width:4em;
  
  }
  .advs-pay-amt-cntr  .adv-amt-cntr :nth-child(1){
    background-color:white;
    width:5em;
    display:block;
    color:#6e706f;
    text-align: end;
    padding-right: 1em;
   
  }
`;

export const CreateCustomerPayment = ({
  backBtn = true,
  handleClosePaymentDialog,
  computeBankReconciliation
}) => {
  let singleViewId = localStorage.getItem("singleViewById");
  let paymentsPrintNew = useRef();
  let location = useLocation();
  const { branchDetails, currencyDetails } = useSelector(
    (state) => state.mainDashBoardSlice
  );

  let navigate = useNavigate();
  const numToWords = require("num-words");
  const userLoginMeta = localStorage.getItem("login_meta");

  const userBranchId = JSON.parse(userLoginMeta)?.branchPk;

  const userRole = useSelector((state) => state.userRoleSlice.value);
  const editedData = useSelector((state) => state.customerPaymentSlice.rowData);
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const allowedBranchList = useSelector(
    (state) => state.allAllowedBranchesSlice.value
  );
  const journalList = useSelector((state) => state.journalSlice.value);
  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );
  //Active Employee List
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const generatedName = useSelector(
    (state) => state.customerPaymentSlice.generateName
  );
  const MultiCurrencyView = useSelector(
    (state) => state.ConfigurationSlice.MultiCurrency
  );
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const companyProfileData = useSelector(
    (state) => state.companyProfileSlice.userValue
  );
  const customerPaymentAccounts = chartOfAccountList?.filter(
    (item) => item.type === "Current liability"
  );
  const customerInvoiceFromStore = useSelector(
    (state) => state.customerPaymentSlice.customerInvoice
  );
  const addRowData = ["Add Line"];
  const [isActiveTable, setIsActiveTable] = useState("invoiceLines");
  const [addRowInput, setAddRowInput] = useState(addRowData);
  const [tableData, setTableData] = useState([
    {
      type: "lines",
      id: Date.now(),
      account: null,
      label: "",
      branch: null,
      employee: null,
      amount: "",
    },
  ]);
  const dispatch = useDispatch();
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  const [customerInvoice, setCustomerInvoice] = useState();
  const [image, setImage] = useState([]);
  const [displayImage, setDisplayImage] = useState([]);
  const [receivedImgUrl, setReceivedImgUrl] = useState([]);
  const [importPopup, setImportPopup] = useState(false);
  const [customerPaymentFomUpdated, setCustomerPaymentFormUpdated] = useState();
  const [tableDataupdate, setTableDataUpdate] = useState();
  const [alertPopup, setAlertPopup] = useState(false);

  const [logView, setLogView] = useState(false);
  const containerRef = useRef(null);
  const [validationAlert, setValidationAlert] = useState({});
  const [log, setLog] = useState([]);
  const [branchList, setBranchList] = useState();

  const formValuesInitialState = {
    customer: null,
    paymentType: null,
    type: "Manual",
    chequeDate: today,
    chequeNo: "",
    amount: "",
    paymentDate: today,
    paymentReference: "",
    amountInWords: "",
    paymentMethod: "otherPayment",
    customerNotes: "",
    termsAndConditions: "",
    isEdit: false,
    status: "",
    branchId: branchDetails,
    currency: currencyDetails,
    paymentMode: null,
    billId: null,
    account: null,
    amountTt: null,
  };
  const [formValues, setFormValues] = useState(formValuesInitialState);
  const [customerPaymentForm, setCustomerPaymentForm] = useState(
    formValuesInitialState
  );
  // filtered journal listdisplayImage
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [tableDataForBackend, setTableDataForBackend] = useState([]);
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false,
  });
  const [editDataId, setEditDataId] = useState("");
  const [editClicked, setIsEditClicked] = useState(false);
  const [paymentName, setPaymentName] = useState("###");
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  const [addRowScroll, setAddRowScroll] = useState(false);
  const addRow = () => {
    if (addRowInput === "Add Header") {
      setTableData([
        ...tableData,
        { type: "header", id: Date.now(), headerValue: "" },
      ]);
    } else if (addRowInput === "Add Line") {
      setTableData([
        ...tableData,
        {
          type: "lines",
          id: Date.now(),
          account: null,
          label: "",
          branch: null,
          employee: null,
          amount: "",
        },
      ]);
    }

    // setAddRowInput(null);
    setAddRowScroll(!addRowScroll);
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [addRowScroll]);

  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const changeImage = (e) => {
    setImage([...image, e.target.files[0]]);
    setDisplayImage([
      ...displayImage,
      { url: URL.createObjectURL(e.target.files[0]) },
    ]);
  };

  const removeImageFn = (i) => {
    if (editedData === undefined) {
      image.splice(i, 1);
    } else {
      receivedImgUrl?.length !== 0 &&
        image.splice(i - receivedImgUrl?.length, 1);
      receivedImgUrl.splice(i, 1);
    }

    displayImage.splice(i, 1);
    setImage([...image]);
    setDisplayImage([...displayImage]);
    setReceivedImgUrl([...receivedImgUrl]);
  };

  const clickLogView = () => {
    setLogView(!logView);
  };

  // handleInputChange

  const handleInputChange = (id, field) => (e, newValue) => {
    const { value } = e.target;

    const updatedData = tableData.map((row, index) => {
      if (index === id) {
        return { ...row, [field]: value === 0 ? newValue : e.target.value };
      }
      return row;
    });
    setTableData(updatedData);
    if (updatedData) {
      const totalAmount = updatedData.reduce((sum, res) => {
        if (res.type === "lines") {
          return sum + (Number(res?.amount) || 0);
        }
        return sum;
      }, 0);

      setFormValues({ ...formValues, amount: totalAmount });
    }
  };

  const backButtonFunction = () => {
    if (formValues?.isEdit === false) {
      const userConfirmed = window.confirm(
        "Changes you made may not be saved."
      );

      if (userConfirmed) {
        localStorage.removeItem("singleViewById");
        navigate("/userdashboard/accounts/customer/payments");
        dispatch(set_Back_button_action({ backButtonClickAction: true }));
      }
    } else if (location?.state?.isCheque === true) {
      navigate(
        "/userdashboard/accounts/report/generalReport/ChequeRegisterReport"
      );
    } else {
      localStorage.removeItem("singleViewById");
      navigate("/userdashboard/accounts/customer/payments");
      dispatch(set_Back_button_action({ backButtonClickAction: true }));
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !formValues?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formValues?.isEdit]);

  useEffect(() => {
    chartOfAccountListApiCall();
    journalListApiCall();
    if (singleViewId) {
      customerPaymentSingleViewApi({ _id: singleViewId });
    }
    viewAllAllowedBranchesAPICall({ employeeId: loginResponse?.data?._id });
    viewMultiCurrencyConfiguration();
  }, []);

  // onChange function
  const getFormInfo = (key) => (e, newValue) => {
    // console.log("new value : ", e.target.value);
    const { value } = e.target;
    setValidationAlert({
      ...validationAlert,
      [key]: value === "" || value == null ? "fill this field" : "",
    });
    // setFormValues({ ...formValues, [key]: value !== 0 ? value : newValue });
    const keyCode = e.keyCode || e.which;
    setFormValues({
      ...formValues,
      [key]:
        value !== 0 && value !== undefined
          ? value
          : newValue !== null
          ? newValue
          : null,
    });
    if (keyCode === 13) {
      setFormValues({
        ...formValues,
        [key]: newValue !== null ? newValue : null,
      });
    }
  };

  const changePaymentMethod = (e) => {
    let newAmount = "";
    if (e.target.value === "otherPayment") {
      newAmount = tableData.reduce(
        (sum, res) =>
          res.type === "lines" ? sum + (Number(res?.amount) || 0) : sum,
        0
      );
    }

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      paymentMethod: e.target.value,
      amount: newAmount,
    }));
    setCustomerInvoice(null);
  };
  // filter payment type
  useEffect(() => {
    if (journalList !== undefined) {
      let stockValuationAccList = journalList?.filter(
        (item) => item.type === "bank" || item.type === "cash"
      );
      setPaymentTypeList(stockValuationAccList);
    }
  }, [journalList]);

  // set table data for backend
  useEffect(() => {
    setTableDataForBackend(
      tableData?.map((r) => {
        if (r?.type === "header") {
          return {
            line_type: r?.type,
            label: r?.headerValue,
          };
        } else {
          return {
            line_type: r?.type,
            chartOfAccountId: r?.account?._id,
            label: r?.label,
            branchId: r?.branch?._id,
            employeeId: r?.employee?._id,
            amount: Number(r?.amount),
          };
        }
      })
    );
  }, [tableData]);

  //  create Customer payment
  const formData = new FormData();
  formData.append(
    "customerId",
    formValues?.customer?._id ? formValues?.customer?._id : null
  );
  formData.append(
    "isCustomerPayment",
    formValues?.paymentMethod === "otherPayment" ? false : true
  );
  formData.append("paymentTypeId", formValues?.paymentType?._id);
  formData.append(
    "chequeDate",
    formValues?.type === "Cheque" ? formValues?.chequeDate : null
  );
  formData.append("chequeNo", formValues?.chequeNo);
  formData.append("type", formValues?.type === "Manual" ? 1 : 2);
  formData.append("totalAmount", Number(formValues?.amount));
  formData.append("paymentDate", formValues?.paymentDate);
  formData.append("paymentReference", formValues?.paymentReference);
  formData.append("amountInWords", numToWords(Math.floor(formValues?.amount)));
  formData.append(
    "itemInfo",
    formValues?.paymentMethod === "otherPayment"
      ? JSON.stringify(tableDataForBackend)
      : null
  );
  formData.append("currency", formValues?.currency?._id);
  formData.append("branchId", formValues?.branchId?._id);
  formData.append("customerNote", formValues?.customerNotes);
  formData.append("termsAndCondition", formValues?.termsAndConditions);
  formValues?.paymentMethod === "customerPayment" &&
    formData.append("paymentMode", formValues?.paymentMode?.toUpperCase());
  formValues?.paymentMethod === "customerPayment" &&
    formValues?.paymentMode === "ADVANCE" &&
    formData.append("advAccount", formValues?.account?._id);
  image?.forEach((file, index) => {
    formData.append("fileUrl", file);
  });
  formData.append("postDate", `${today} ${getCurrentTime()}`);
  const formSubmitFn = (key) => () => {
    dispatch(setFilterActive(false));
    const selectedBills = (customerInvoice || [])
      .filter((res) => res.isSelected === true)
      .map((res) => ({
        billId: res?.billId,
        paidAmount: res?.amount,
        case: res?.case,
      }));

    if (
      formValues?.paymentMethod === "customerPayment" &&
      formValues?.paymentMode === "AGAINST REF"
    ) {
      formData.append("billId", JSON.stringify(selectedBills));
    } else {
      formData.append("billId", null);
    }
    if (key === "post") {
      let draftFn = () => {
        setFormValues({ ...formValues, isEdit: true, status: "POSTED" });
      };
      // post api call
      postCustomerPaymentAPICall(
        { id: editDataId },
        draftFn,
        setSnackBarStates,
        snackBarStates
      );
    }
    if (
      formValues?.paymentMethod === "customerPayment" &&
      formValues?.customer === null
    ) {
      setValidationAlert({ ...validationAlert, customer: "Select a customer" });
    } else if (formValues?.paymentType === null) {
      setValidationAlert({
        ...validationAlert,
        paymentType: "Select a payment type",
      });
    } else if (formValues?.type === null) {
      setValidationAlert({ ...validationAlert, type: "Select a type" });
    } else if (
      formValues?.type === "Cheque" &&
      formValues?.chequeDate === "" &&
      formValues?.chequeDate === null
    ) {
      setValidationAlert({ ...validationAlert, chequeDate: "fill this field" });
    } else if (formValues?.chequeNo === "") {
      setValidationAlert({ ...validationAlert, chequeNo: "fill this field" });
    } else if (
      formValues?.amount === "" &&
      formValues.paymentMethod !== "otherPayment"
    ) {
      setValidationAlert({ ...validationAlert, amount: "fill this field" });
    } else if (formValues?.paymentDate === "") {
      setValidationAlert({
        ...validationAlert,
        paymentDate: "fill this field",
      });
    } else if (formValues?.paymentReference === "") {
      setValidationAlert({
        ...validationAlert,
        paymentReference: "fill this field",
      });
    } else if (formValues?.branchId === null || formValues?.branchId === "") {
      setValidationAlert({
        ...validationAlert,
        branch: "fill this field",
      });
    } else if (
      formValues?.currency === null ||
      formValues?.currency === "" ||
      formValues?.currency === undefined
    ) {
      setValidationAlert({
        ...validationAlert,
        currency: "fill this field",
      });
    } else if (
      formValues?.paymentMethod === "customerPayment" &&
      (formValues?.paymentMode == null ||
        formValues?.paymentMode === "" ||
        formValues?.paymentMode === undefined)
    ) {
      setValidationAlert({
        ...validationAlert,
        paymentMode: "fill this field",
      });
    } else if (
      formValues?.paymentMethod === "customerPayment" &&
      formValues?.paymentMode === "ADVANCE" &&
      (formValues?.account == null ||
        formValues?.account === "" ||
        formValues?.account === undefined)
    ) {
      setValidationAlert({
        ...validationAlert,
        account: "fill this field",
      });
    } else {
      if (key === "draft") {
        let draftFn = () => {
          setFormValues({ ...formValues, isEdit: true, status: "DRAFT" });
        };
        // draft api call

        if (
          (formValues?.paymentMode === "AGAINST REF" &&
            selectedBills.length > 0) ||
          formValues?.paymentMode !== "AGAINST REF"
        ) {
          draftCustomerPaymentAPICall(
            formData,
            draftFn,
            setSnackBarStates,
            snackBarStates,
            setEditDataId
          );
        } else {
          setSnackBarStates({
            error: true,
            message: "please choose a invoice",
          });
        }
      } else if (key === "edit") {
        formData.append("id", editDataId);
        formData.append("uploadUrl", JSON.stringify(receivedImgUrl));
        let draftFn = () => {
          setFormValues({ ...formValues, isEdit: true, status: "DRAFT" });
          setIsEditClicked(false);
        };
        // edit api call
        editCustomerPaymentAPICall(
          formData,
          draftFn,
          setSnackBarStates,
          snackBarStates
        );
      } else if (key === "update") {
        let sendBody = {
          id: singleViewId,
          postDate: `${today} ${getCurrentTime()}`,
          vendorId: formValues?.customer?._id,
          isVendorPayment:
            formValues?.paymentMethod === "otherPayment" ? false : true,
          paymentTypeId: formValues.paymentType._id,
          type: formValues?.type === "Manual" ? 1 : 2,
          chequeDate: formValues.chequeDate,
          chequeNo: formValues.chequeNo,
          totalAmount: formValues.amount,
          paymentDate: formValues.paymentDate,
          paymentReference: formValues.paymentReference,
          amountInWords: formValues.amountInWords,
          itemInfo:
            tableDataForBackend?.length > 0 ? tableDataForBackend : null,
          branchId: formValues.branchId._id,
          customerNote: formValues.customerNotes,
          termsAndCondition: formValues.termsAndConditions,
          currency: formValues.currency._id,
          billId: selectedBills?.length > 0 ? selectedBills : null,
          paymentMode: formValues?.paymentMode,
          account:
            formValues.paymentMode === "ADVANCE"
              ? formValues.account._id
              : null,
        };
        updateCustomerPaymentAPI(sendBody, setImportPopup, setSnackBarStates);
      } else if (key === "return") {
        returnCustomerPaymentAPI(
          {
            id: singleViewId,
            postDate: `${today} ${getCurrentTime()}`,
          },
          setAlertPopup,
          setSnackBarStates
        );
      }
    }
  };

  // edit section
  // console.log(editedData,'....editedData');
  useEffect(() => {
    if (editedData !== undefined && customerList !== undefined) {
      setImage([]);
      setEditDataId(editedData?._id);
      setLog(editedData?.log || []);
      setFormValues({
        ...formValues,
        isEdit: true,
        status: editedData?.status,
        paymentMethod:
          editedData?.isVendorPayment === false
            ? "otherPayment"
            : "customerPayment",
        paymentType: filterObjFromList(
          "_id",
          paymentTypeList,
          "paymentTypeId",
          editedData
        ),
        customer: filterObjFromList(
          "_id",
          customerList,
          "vendorId",
          editedData
        ),
        branchId: allBranchesList?.find(
          (data) => data?._id === editedData?.branchId
        ),
        type: editedData?.TYPE,
        chequeDate: editedData?.chequeDate,
        chequeNo: editedData?.chequeNo,
        amount: editedData?.totalAmount,
        amountTt: editedData?.totalAmount,
        advBalanceAmt: editedData?.advBalanceAmt,
        paymentDate: editedData?.paymentDate,
        paymentReference: editedData?.paymentReference,
        customerNotes: editedData?.customerNote,
        termsAndConditions: editedData?.termsAndCondition,
        paymentMode: ["AGAINST REF", "ADVANCE", "NEW REF"].find(
          (res) => res === editedData.paymentMode
        ),
        billId: editedData?.billId,
        account: filterObjFromList(
          "_id",
          chartOfAccountList,
          "advAccount",
          editedData
        ),
        currency:
          MultiCurrencyView !== undefined &&
          MultiCurrencyView?.isMultiCurrency === true
            ? filterObjFromList(
                "_id",
                currencyListUpdated,
                "currency",
                editedData
              )
            : filterObjFromList(
                "_id",
                currencyListUpdated,
                "currency",
                companyProfileData
              ),
      });

      setTableData(
        editedData?.itemInfo?.map((r, i) => {
          if (r?.line_type === "header") {
            return {
              type: r?.line_type,
              headerValue: r?.label,
            };
          } else {
            return {
              type: r?.line_type,
              account: filterObjFromList(
                "_id",
                chartOfAccountList,
                "chartOfAccountId",
                r
              ),
              label: r?.label,
              branch: filterObjFromList("_id", allBranchesList, "branchId", r),
              employee: filterObjFromList(
                "_id",
                allActiveEmpList,
                "employeeId",
                r
              ),
              amount: r?.amount,
            };
          }
        })
      );
      setDisplayImage(
        editedData?.imageUrl?.map((r) => {
          return {
            url: r.url,
          };
        })
      );
      setReceivedImgUrl(
        editedData?.imageUrl?.map((r) => {
          return {
            url: r?.url,
            _id: r?._id,
          };
        })
      );

      let newData = editedData?.billId?.map((element) => {
        return {
          ...element,
          amount: element?.paidAmount,
          totalAmount: formValues?.amount,
          isSelected: true,
        };
      });
      setCustomerInvoice(newData);
     
      if (editedData?.status === "COMPLETED") {
        if(!backBtn){
          localStorage.removeItem("singleViewById");
          dispatch(
            get_customer_payment_row_data_list({
              customerPaymentSingleRowData: undefined,
            }))
          handleClosePaymentDialog()
          computeBankReconciliation()
        }
      }
    } else {
      setFormValues(formValuesInitialState);
    }
  }, [
    editedData,
    paymentTypeList,
    customerList,
    allActiveEmpList,
    allBranchesList,
  ]);

  useEffect(() => {
    if (formValues?.branchId && !singleViewId) {
      generateCustomerPaymentNameAPICall({
        branchId: formValues.branchId._id,
        paymentDate: formValues.paymentDate,
      });
    }
  }, [formValues?.branchId]);
  // console.log("generatedName",generatedName )

  useEffect(() => {
    editedData !== undefined
      ? setPaymentName(editedData?.name)
      : setPaymentName(generatedName !== undefined ? generatedName : "###");
  }, [generatedName, editedData]);
  // for get customer bill
  useEffect(() => {
    if (
      editedData === undefined &&
      formValues.customer?._id &&
      formValues.branchId?._id &&
      formValues.currency?._id
    ) {
      let sendObj = {
        customerId: formValues.customer._id,
        branchId: formValues.branchId._id,
        currency: formValues.currency._id,
      };

      showCustomerInvoiceApi(sendObj);
    }
  }, [formValues.branchId, formValues.customer, formValues.currency]);

  useEffect(() => {
    let newData = customerInvoiceFromStore.map((element) => {
      return { ...element, amount: null, isSelected: false };
    });
    setCustomerInvoice(newData);
  }, [customerInvoiceFromStore]);
  //handle bill
  const handleSelected = (e, index) => {
    const { checked } = e.target;
    let element = customerInvoice[index];
    if (!element.amount || element.amount <= 0) {
      setSnackBarStates({
        error: true,
        message:
          element.amount === null
            ? "please enter a amount"
            : "please enter a valid amount",
      });
    } else {
      setCustomerInvoice((prev) => {
        const currentArray = prev || [];
        if (currentArray[index]) {
          const updatedcustomerInvoice = [...currentArray];
          updatedcustomerInvoice[index] = {
            ...updatedcustomerInvoice[index],
            isSelected: checked,
          };
          return updatedcustomerInvoice;
        }
        return currentArray;
      });
    }
  };

  const handleAmountChange = (e, index, item) => {
    const { value } = e.target;
    setCustomerInvoice((prev) => {
      const currentArray = prev || [];
      const total = item.amountDue + item.paidAmount;

      if (currentArray[index] && value !== undefined) {
        const updatedCustomerBills = [...currentArray];
        const newAmount = importPopup
          ? Number(value) > total
            ? total
            : Number(value)
          : value > item.amountDue
          ? item.amountDue
          : Number(value);
        updatedCustomerBills[index] = {
          ...updatedCustomerBills[index],
          amount: newAmount,
          isSelected: value <= 0 ? false : true,
        };
        return updatedCustomerBills;
      }
      return currentArray;
    });
  };

  useEffect(() => {
    if (customerInvoice?.length > 0) {
      // console.log("customer invoice",customerInvoice);
      const sum = (customerInvoice || [])
        .filter((res) => res.isSelected === true)
        .reduce((accumulator, res) => accumulator + (res?.amount || 0), 0);
      setFormValues((prev) => ({
        ...prev,
        amount:
          formValues?.paymentMode !== "ADVANCE" ? sum : formValues?.amount,
        amountTt: sum,
      }));
    }
  }, [customerInvoice]);

  // useEffect(() => {
  //   if (
  //     formValues?.paymentDate &&
  //     loginResponse?.branchPk &&
  //     editedData?.length === 0
  //   ) {
  //     generateCustomerPaymentNameAPICall({
  //       branchId: loginResponse?.branchPk,
  //       paymentDate: formValues?.paymentDate,
  //     });
  //   }
  // }, [formValues?.paymentDate, loginResponse?.branchPk]);

  return (
    <>
      {" "}
      <style>{styles}</style>
      <div className="global-page-parent-container">
        <div
          className="new-global-white-bg-container"
          style={{ padding: "0 2%" }}
        >
          {!backBtn && <div>
            <h3>Customer Payment</h3>
          </div>}
          <div className="new-global-white-bg-icon-container">
            <div
              className="new-global-white-bg-icon-container-left"
              style={{ width: "58%" }}
            >
              {backBtn && (
                <Tooltip title="Back">
                  <IconButton onClick={backButtonFunction}>
                    <i class="bi bi-chevron-left"></i>
                  </IconButton>
                </Tooltip>
              )}
              {formValues?.status === "COMPLETED" && (
                <>
                  <button
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "rgb(166 17 217)",
                      height: "32px",
                      width: "29%",
                    }}
                    onClick={() => {
                      setImportPopup(true);
                      setCustomerPaymentFormUpdated(customerPaymentForm);
                      setTableDataUpdate(tableData);
                    }}
                  >
                    Update
                  </button>

                  <button
                    className="btn btn-primary"
                    style={{
                      backgroundColor: " rgb(217 17 112",
                      height: "32px",
                      width: "34%",
                    }}
                    onClick={() => setAlertPopup(true)}
                  >
                    Payment Return
                  </button>
                </>
              )}
              {formValues?.status === "DRAFT" && (
                <Tooltip title="Edit">
                  <IconButton
                    onClick={
                      editClicked
                        ? formSubmitFn("edit")
                        : () => {
                            setFormValues({ ...formValues, isEdit: false });
                            setIsEditClicked(true);
                          }
                    }
                  >
                    <i
                      class={
                        editClicked
                          ? "bi bi-check2-square edit-icon"
                          : "bi bi-pencil-square edit-icon1"
                      }
                    ></i>
                    {/* <i class="bi bi-check2-square edit-icon"></i> */}
                  </IconButton>
                </Tooltip>
              )}
              {formValues?.status === "" && (
                <Tooltip title="Save">
                  <IconButton onClick={formSubmitFn("draft")}>
                    <i class="bi bi-floppy save-icon"></i>
                  </IconButton>
                </Tooltip>
              )}
              {formValues?.status === "DRAFT" && !editClicked && (
                <button onClick={formSubmitFn("post")} className="btn btn-post">
                  POST
                </button>
              )}
              {/* {formValues?.status !== "" && (
                <>
                  <Tooltip title="Share">
                    <IconButton>
                      <i
                        class="bi bi-share"
                        style={{
                          backgroundColor: "#9797e9",
                          color: "white",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                      ></i>
                    </IconButton>
                  </Tooltip>

                  <ReactToPrint
                    trigger={() => (
                      <IconButton
                        className="printer-div"
                        style={{ padding: "1px" }}
                      >
                        <i
                          style={{
                            color: "white",
                            backgroundColor: " #d787e7",
                            fontSize: "medium",
                            padding: "8px",
                            borderRadius: "2px",
                          }}
                          class="bi bi-printer"
                        ></i>
                      </IconButton>
                    )}
                    content={() => paymentsPrintNew}
                  />

                  <Tooltip title="Download">
                    <IconButton>
                      <i
                        class="bi bi-arrow-down-circle"
                        style={{
                          backgroundColor: "rgb(255 118 133 / 69%)",
                          color: "white",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                      ></i>
                    </IconButton>
                  </Tooltip>
                </>
              )} */}
            </div>

            {/* <div className="status-container">
            <p>Draft</p>
          </div> */}
            <div className="status-container">
              {/* <img src={Banner} alt="" /> */}
              {formValues?.status === "DRAFT" && (
                <img src={draftRibbon} alt="" />
              )}
              {formValues?.status === "COMPLETED" && (
                <img src={postRibbon} alt="" />
              )}
              {formValues?.status === "RETURNED" && (
                <img src={returned} alt="" />
              )}
            </div>
          </div>
          <hr className="global-hr" />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4 style={{ width: "35%" }} className="global-name">
              {paymentName}
            </h4>
            <div className="radio-groups-container" style={{ width: "40%" }}>
              <div
                className="global-radio-button"
                style={{ width: "50%", display: "flex", alignItems: "center" }}
              >
                <input
                  type="radio"
                  value="otherPayment"
                  onChange={changePaymentMethod}
                  name="customerPayment"
                  id="customerOtherPayment"
                  // defaultChecked
                  checked={formValues?.paymentMethod === "otherPayment"}
                  disabled={formValues?.isEdit}
                />
                <label htmlFor="customerOtherPayment">Other Payment</label>
              </div>
              <div
                className="global-radio-button"
                style={{ width: "50%", display: "flex", alignItems: "center" }}
              >
                <input
                  type="radio"
                  value="customerPayment"
                  onChange={changePaymentMethod}
                  name="customerPayment"
                  id="customerPayment"
                  disabled={formValues?.isEdit}
                  checked={formValues?.paymentMethod === "customerPayment"}
                />
                <label htmlFor="customerPayment">Customer Payment</label>
              </div>
            </div>
          </div>
          <div className="new-global-input-container">
            {formValues?.paymentMethod === "customerPayment" && (
              <div className="new-global-single-input auto-complete-new">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={["AGAINST REF", "ADVANCE", "NEW REF"]}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Payment Type*" focused />
                  )}
                  value={formValues?.paymentMode}
                  onChange={getFormInfo("paymentMode")}
                  disabled={formValues?.isEdit}
                />
                <p className="doc-validation-alert">
                  {validationAlert?.paymentMode}
                </p>
              </div>
            )}
            {formValues?.paymentMethod === "customerPayment" && (
              <div className="new-global-single-input auto-complete-new">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={customerList || []}
                  getOptionLabel={(option) => option?.name}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Customer*" focused />
                  )}
                  value={formValues?.customer}
                  onChange={getFormInfo("customer")}
                  disabled={formValues?.isEdit}
                />
                <p className="doc-validation-alert">
                  {validationAlert?.customer}
                </p>
              </div>
            )}

            <div className="new-global-single-input auto-complete-new">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={paymentTypeList || []}
                getOptionLabel={(option) => option?.journalName}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Payment Method*" focused />
                )}
                value={formValues?.paymentType}
                onChange={getFormInfo("paymentType")}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.paymentType}
              </p>
            </div>
            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={["Manual", "Cheque"]}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Type*" focused />
                )}
                value={formValues?.type}
                onChange={getFormInfo("type")}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">{validationAlert?.type}</p>
            </div>
            {formValues?.type === "Cheque" && (
              <>
                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label="Cheque Date*"
                    variant="outlined"
                    type="date"
                    focused
                    value={formValues?.chequeDate}
                    onChange={getFormInfo("chequeDate")}
                    disabled={formValues?.isEdit}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.chequeDate}
                  </p>
                </div>
              </>
            )}
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label={
                  formValues?.type === "Cheque"
                    ? "Cheque No*"
                    : "Transaction No"
                }
                variant="outlined"
                type="text"
                focused
                value={formValues?.chequeNo}
                onChange={getFormInfo("chequeNo")}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.chequeNo}
              </p>
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Amount*"
                variant="outlined"
                type="text"
                focused
                value={formValues?.amount}
                // value={(formValues.paymentMode === "ADVANCE" && editedData.length>0 )? formValues?.amountTt : formValues?.amount}
                // value={
                //   (formValues?.paymentMode === "ADVANCE" && editedData?.length > 0)
                //     ? formValues?.amount
                //     : formValues?.amountTt
                // }
                onChange={
                  formValues.paymentMethod === "otherPayment"
                    ? undefined
                    : getFormInfo("amount")
                }
                // disabled={
                //   formValues.paymentMethod === "otherPayment" &&
                //   formValues?.isEdit
                //     ? true
                //     : formValues?.isEdit
                //     ? true
                //     : false
                // }
                disabled={
                  formValues.paymentMethod === "otherPayment" ||
                  formValues?.isEdit ||
                  (formValues.paymentMethod === "customerPayment" &&
                    formValues.paymentMode === "AGAINST REF")
                }
                sx={{
                  cursor:
                    formValues.paymentMethod === "otherPayment"
                      ? "not-allowed"
                      : "auto",
                }}
              />
              <p className="doc-validation-alert">{validationAlert?.amount}</p>
            </div>

            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Payment Date*"
                variant="outlined"
                type="date"
                focused
                value={formValues?.paymentDate}
                onChange={getFormInfo("paymentDate")}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.paymentDate}
              </p>
            </div>

            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Payment Reference*"
                variant="outlined"
                type="text"
                focused
                value={formValues?.paymentReference}
                onChange={getFormInfo("paymentReference")}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.paymentReference}
              </p>
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Amount In Words*"
                variant="outlined"
                type="text"
                focused
                value={numToWords(Math.floor(formValues?.amount))}
                disabled={formValues?.isEdit}
              />
            </div>
            <div className="new-global-single-input auto-complete-new">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allowedBranchList || []}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Branch*" focused />
                )}
                value={formValues?.branchId}
                onChange={getFormInfo("branchId")}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">{validationAlert?.branch}</p>
            </div>
            <div className="new-global-single-input">
              {MultiCurrencyView !== undefined &&
              MultiCurrencyView?.isMultiCurrency ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={currencyListUpdated || []}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Currency*" focused />
                  )}
                  value={formValues?.currency}
                  onChange={getFormInfo("currency")}
                  disabled={formValues?.isEdit}
                />
              ) : (
                <TextField
                  value={formValues?.currency?.name || ""}
                  sx={{ caretColor: "transparent" }}
                  className="disabled-input"
                  id="outlined-basic"
                  label="Currency*"
                  variant="outlined"
                  type="text"
                  focused
                  title="Multi Currency not enabled"
                  disabled={formValues?.isEdit}
                />
              )}
              <p className="doc-validation-alert">
                {validationAlert?.currency}
              </p>
            </div>
            {formValues?.paymentMethod === "customerPayment" &&
              formValues?.paymentMode === "ADVANCE" && (
                <div className="new-global-single-input auto-complete-new">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={customerPaymentAccounts}
                    getOptionLabel={(option) => option?.accountName || ""}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Account*" focused />
                    )}
                    value={formValues?.account}
                    onChange={getFormInfo("account")}
                    disabled={formValues?.isEdit}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.account}
                  </p>
                </div>
              )}
          </div>
        </div>
        {formValues?.paymentMethod === "otherPayment" && (
          <>
            <div className="new-global-white-bg-container">
              <>
                <div className="global-product-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Account</th>
                        <th>Label</th>
                        <th>Branch</th>
                        <th>Employee</th>
                        <th>Amount</th>
                      </tr>
                    </thead>

                    <tbody>
                      {tableData?.map((item, index) => (
                        <tr
                          key={index}
                          ref={
                            index === tableData.length - 1 ? containerRef : null
                          }
                        >
                          {item.type === "header" ? (
                            <>
                              <td colSpan="5">
                                <input
                                  type="text"
                                  value={item.headerValue}
                                  onChange={handleInputChange(
                                    index,
                                    "headerValue"
                                  )}
                                  disabled={formValues?.isEdit}
                                />
                              </td>
                              <td style={{ border: "none", width: "4%" }}>
                                <IconButton
                                  disabled={formValues?.isEdit}
                                  onClick={() => handleDelete(index)}
                                  style={{ height: "30px" }}
                                >
                                  <i
                                    className="bi bi-trash3"
                                    style={{
                                      color: "#db0000",
                                      fontSize: "1.3rem",
                                    }}
                                  ></i>
                                </IconButton>
                              </td>
                            </>
                          ) : (
                            <>
                              <td>
                                <div className="product-table-auto-complete">
                                  <Autocomplete
                                    options={chartOfAccountList || []}
                                    style={{ maxHeight: "100px" }}
                                    isOptionEqualToValue={(option, value) =>
                                      option._id === value._id
                                    }
                                    getOptionLabel={(option) =>
                                      option?.accountName
                                    }
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    value={item?.account}
                                    onChange={handleInputChange(
                                      index,
                                      "account"
                                    )}
                                    disabled={formValues?.isEdit}
                                  />
                                </div>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={item?.label}
                                  onChange={handleInputChange(index, "label")}
                                  disabled={formValues?.isEdit}
                                />
                              </td>
                              <td>
                                <div className="product-table-auto-complete">
                                  <Autocomplete
                                    options={allBranchesList || []}
                                    isOptionEqualToValue={(option, value) =>
                                      option._id === value._id
                                    }
                                    style={{ maxHeight: "100px" }}
                                    getOptionLabel={(option) =>
                                      `${option?.storeCode}-${option?.branchName}`
                                    }
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    value={item?.branch}
                                    onChange={handleInputChange(
                                      index,
                                      "branch"
                                    )}
                                    disabled={formValues?.isEdit}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="product-table-auto-complete">
                                  <Autocomplete
                                    options={allActiveEmpList || []}
                                    isOptionEqualToValue={(option, value) =>
                                      option._id === value._id
                                    }
                                    style={{ maxHeight: "100px" }}
                                    getOptionLabel={(option) =>
                                      option?.staff_name
                                    }
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    value={item?.employee}
                                    onChange={handleInputChange(
                                      index,
                                      "employee"
                                    )}
                                    disabled={formValues?.isEdit}
                                  />
                                </div>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={item?.amount}
                                  onChange={handleInputChange(index, "amount")}
                                  disabled={formValues?.isEdit}
                                />
                              </td>
                              {formValues.status !== "COMPLETED" && (
                                <td style={{ border: "none", width: "4%" }}>
                                  <IconButton
                                    disabled={formValues?.isEdit}
                                    onClick={() => handleDelete(index)}
                                    style={{ height: "30px" }}
                                  >
                                    <i
                                      className="bi bi-trash3"
                                      style={{
                                        color: "#db0000",
                                        fontSize: "1.3rem",
                                      }}
                                    ></i>
                                  </IconButton>
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="add-row-btn-container">
                  <div
                    className="global-single-input auto-complete"
                    style={{ width: "76%" }}
                  >
                    <Autocomplete
                      options={addRowData || []}
                      getOptionLabel={(option) => option}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      renderInput={(params) => <TextField {...params} />}
                      value={addRowInput}
                      onChange={(e, newValue) => setAddRowInput(newValue)}
                      disabled={formValues?.isEdit}
                    />
                  </div>
                  <button
                    onClick={() => addRow()}
                    disabled={formValues?.isEdit}
                    className="add-row-btn"
                  >
                    +
                  </button>
                </div>
              </>
            </div>
            <div
              className="new-global-white-bg-container"
              style={{
                display: "flex",
                flexWrap: "wrap",
                padding: "8px 2%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "97%",
                }}
              >
                <div
                  className="new-global-single-input"
                  style={{ width: "35%" }}
                >
                  <p>Customer Notes</p>
                  <textarea
                    name=""
                    id=""
                    cols="50"
                    rows="5"
                    value={formValues?.customerNotes}
                    onChange={getFormInfo("customerNotes")}
                    disabled={formValues?.isEdit}
                  ></textarea>
                </div>

                <div
                  className="new-global-single-input"
                  style={{ width: "35%" }}
                >
                  <p>Terms & Conditions</p>
                  <textarea
                    name=""
                    id=""
                    cols="50"
                    rows="5"
                    value={formValues?.termsAndConditions}
                    onChange={getFormInfo("termsAndConditions")}
                    disabled={formValues?.isEdit}
                  ></textarea>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                }}
              >
                <div className="file-picker-new">
                  <input type="file" id="fileUpload" onChange={changeImage} />
                  <label htmlFor="fileUpload">
                    <img src={uploadFile} alt="" />
                    Upload File
                  </label>
                </div>
                <div style={{ display: "flex", margin: "0 0 0 22px" }}>
                  {displayImage?.map((r, i) => (
                    <div className="uploaded-image-list">
                      <img src={r?.url} alt="" />
                      <div className="image-remove-icon-new">
                        <IconButton onClick={() => removeImageFn(i)}>
                          <CloseOutlined
                            sx={{ color: "#fd0301", fontSize: "0.9rem" }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
        {/* customer payment  */}
        {formValues?.paymentMethod === "customerPayment" &&
          (formValues?.paymentMode === "AGAINST REF" ||
            (formValues?.paymentMode === "ADVANCE" &&
              editedData?.length === 0) ||
            (formValues?.paymentMode === "ADVANCE" &&
              editedData?.status === "COMPLETED")) &&
          customerInvoice?.length > 0 && (
            <>
              <div className="new-global-white-bg-container">
                <>
                  <div className="global-product-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Invoice</th>
                          <th>Date</th>
                          <th>Invoice Reference</th>
                          <th>Amount Total</th>
                          <th>Amount Due</th>
                          <th>Amount </th>
                        </tr>
                      </thead>

                      <tbody>
                        {Array.isArray(customerInvoice) &&
                        customerInvoice.length > 0 ? (
                          customerInvoice.map((item, index) => (
                            <tr
                              key={index}
                              ref={
                                index === tableData?.length - 1
                                  ? containerRef
                                  : null
                              }
                            >
                              {item.type === "header" ? (
                                <>
                                  <td colSpan="5">
                                    <input
                                      type="text"
                                      value={item.headerValue}
                                      onChange={handleInputChange(
                                        index,
                                        "headerValue"
                                      )}
                                      disabled={formValues?.isEdit}
                                    />
                                  </td>
                                  <td style={{ border: "none", width: "4%" }}>
                                    <IconButton
                                      disabled={formValues?.isEdit}
                                      onClick={() => handleDelete(index)}
                                      style={{ height: "30px" }}
                                    >
                                      <i
                                        className="bi bi-trash3"
                                        style={{
                                          color: "#db0000",
                                          fontSize: "1.3rem",
                                        }}
                                      ></i>
                                    </IconButton>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td style={{ minWidth: "200px" }}>
                                    {item?.name}
                                  </td>
                                  <td style={{ maxWidth: "180px" }}>
                                    {item?.invoiceDate}
                                  </td>
                                  <td style={{ minWidth: "200px" }}>
                                    {item?.invoiceRef || ""}
                                  </td>
                                  <td style={{ minWidth: "200px" }}>
                                    {item?.total}
                                  </td>
                                  <td style={{ maxWidth: "80px" }}>
                                    {item?.amountDue}
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="Number"
                                      value={item?.amount}
                                      onChange={(e) =>
                                        handleAmountChange(e, index, item)
                                      }
                                      disabled={formValues?.isEdit}
                                    />
                                  </td>
                                  {formValues?.paymentMode !== "ADVANCE" && (
                                    <td style={{ border: "none", width: "4%" }}>
                                      <Checkbox
                                        disabled={formValues?.isEdit}
                                        checked={item?.isSelected}
                                        onChange={(e) =>
                                          handleSelected(e, index)
                                        }
                                        sx={{
                                          color: "#000000",
                                          "&.Mui-checked": {
                                            color: "#000000",
                                          },
                                        }}
                                      />
                                    </td>
                                  )}
                                </>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan={6}>
                              No Data
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {formValues?.paymentMode === "ADVANCE" && (
                      <div className="advs-pay-amt-cntr">
                        <div>
                          <div className="adv-amt-cntr adv-tt-amt">
                            <span>Total</span>
                            <span>
                              {formValues?.paymentMode !== "ADVANCE"
                                ? formValues?.amount
                                : formValues?.amountTt || 0}
                              &nbsp;
                              {formValues?.currency?.currencyCode}
                            </span>
                          </div>
                          <div className="adv-amt-cntr">
                            <span>Balance</span>
                            <span>
                              {formValues?.advBalanceAmt || 0}&nbsp;
                              {formValues?.currency?.currencyCode}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {editedData?.status !== "COMPLETED" && (
                    <div className="add-row-btn-container">
                      <div
                        className="global-single-input auto-complete add-line-autocomplete"
                        style={{ width: "76%" }}
                      >
                        <Autocomplete
                          options={addRowData || []}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => <TextField {...params} />}
                          value={addRowInput}
                          onChange={(e, newValue) => setAddRowInput(newValue)}
                          renderClear={() => null}
                          disabled={formValues?.isEdit}
                        />
                      </div>
                      <button
                        disabled={formValues?.isEdit}
                        onClick={() => addRow()}
                        className="add-row-btn"
                      >
                        +
                      </button>
                    </div>
                  )}
                </>
              </div>
              {!formValues?.paymentMode === "ADVANCE" && (
                <div
                  className="new-global-white-bg-container"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    padding: "8px 2%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",

                      width: "97%",
                    }}
                  >
                    <div
                      className="new-global-single-input"
                      style={{ width: "35%" }}
                    >
                      <p>Customer Notes</p>
                      <textarea
                        name=""
                        id=""
                        cols="50"
                        rows="5"
                        value={formValues?.customerNotes}
                        onChange={getFormInfo("customerNotes")}
                        disabled={formValues?.isEdit}
                      ></textarea>
                    </div>

                    <div
                      className="new-global-single-input"
                      style={{ width: "35%" }}
                    >
                      <p>Terms & Conditions</p>
                      <textarea
                        name=""
                        id=""
                        cols="50"
                        rows="5"
                        value={formValues?.termsAndConditions}
                        onChange={getFormInfo("termsAndConditions")}
                        disabled={formValues?.isEdit}
                      ></textarea>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "60%",
                    }}
                  >
                    <div className="file-picker-new">
                      <input
                        disabled={formValues?.isEdit}
                        type="file"
                        id="fileUpload"
                        onChange={changeImage}
                      />
                      <label disabled={formValues?.isEdit} htmlFor="fileUpload">
                        <img src={uploadFile} alt="" />
                        Upload File
                      </label>
                    </div>
                    <div style={{ display: "flex", margin: "0 0 0 22px" }}>
                      {displayImage?.map((r, i) => (
                        <div className="uploaded-image-list">
                          <img src={r?.url} alt="" />
                          <div className="image-remove-icon-new">
                            <IconButton
                              onClick={() => removeImageFn(i)}
                              disabled={formValues?.isEdit}
                            >
                              <CloseOutlined
                                sx={{ color: "#fd0301", fontSize: "0.9rem" }}
                              />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        <div
          className="new-global-white-bg-container new-log-container"
          style={{ padding: "4px" }}
        >
          <div
            style={{ padding: "8px", cursor: "pointer" }}
            onClick={clickLogView}
          ></div>
          <div
            className={logView ? "pointer-up" : "pointer"}
            onClick={clickLogView}
            style={{ cursor: "pointer" }}
          ></div>
          {logView && (
            <div className="single-log-container">
              {log?.map((r, i) => (
                <div className="single-log-item">
                  <div className="single-log-item-line-image">
                    <hr className="single-log-item-line" />
                    <div className="single-log-item-image">
                      <img src={logoImage} alt="" />
                    </div>
                    <hr className="single-log-item-line" />
                  </div>
                  <p className="heading">{r?.status}</p>
                  <p className="name">{r?.empId}</p>
                  <p className="date-time">{r?.date}</p>
                  <p className="date-time">{r?.time}</p>
                </div>
              ))}
            </div>
          )}

          {/* <div className='single-log-container-scroll-line'></div> */}
        </div>
        {/* new update payment  */}
        <Dialog
          maxWidth="lg"
          open={importPopup}
          // onClose={() => setRegisterPayment(false)}
        >
          <div className="payment-popup">
            <div className="global-input-field-container">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4 style={{ width: "35%" }} className="global-name">
                  {paymentName}
                </h4>
              </div>
              <div className="new-global-input-container">
                {formValues?.paymentMethod === "vendorPayment" && (
                  <div className="new-global-single-input auto-complete-new">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={customerList || []}
                      filterOptions={customerFilterOptions}
                      getOptionLabel={(option) => option?.name}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Vendor*" focused />
                      )}
                      value={formValues?.vendor}
                      onChange={getFormInfo("vendor")}
                      // disabled={formValues?.isEdit}
                    />
                    <p className="doc-validation-alert">
                      {validationAlert?.vendor}
                    </p>
                  </div>
                )}
                <div className="new-global-single-input auto-complete-new">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={paymentTypeList || []}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    getOptionLabel={(option) => option?.journalName}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Payment Method*" focused />
                    )}
                    value={formValues?.paymentType}
                    onChange={getFormInfo("paymentType")}
                    // disabled={formValues?.isEdit}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.paymentType}
                  </p>
                </div>
                <div className="new-global-single-input">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={["Manual", "Cheque"]}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Type*" focused />
                    )}
                    value={formValues?.type}
                    onChange={getFormInfo("type")}
                    // disabled={formValues?.isEdit}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.type}
                  </p>
                </div>
                {formValues?.type === "Cheque" && (
                  <>
                    <div className="new-global-single-input">
                      <TextField
                        id="outlined-basic"
                        label="Cheque Date*"
                        variant="outlined"
                        type="date"
                        focused
                        value={formValues?.chequeDate}
                        onChange={getFormInfo("chequeDate")}
                        // disabled={formValues?.isEdit}
                      />
                      <p className="doc-validation-alert">
                        {validationAlert?.chequeDate}
                      </p>
                    </div>
                  </>
                )}
                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label={
                      formValues?.type === "Cheque"
                        ? "Cheque No*"
                        : "Transaction No"
                    }
                    variant="outlined"
                    type="text"
                    focused
                    value={formValues?.chequeNo}
                    onChange={getFormInfo("chequeNo")}
                    // disabled={formValues?.isEdit}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.chequeNo}
                  </p>
                </div>

                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label="Amount*"
                    variant="outlined"
                    type="text"
                    focused
                    defaultValue={"0"}
                    value={formValues.amount}
                    onChange={
                      formValues.paymentMethod === "otherPayment"
                        ? undefined
                        : getFormInfo("amount")
                    }
                    // disabled={
                    //   formValues.paymentMethod === "otherPayment" &&
                    //   formValues?.isEdit
                    //     ? true
                    //     : formValues?.isEdit
                    //     ? true
                    //     : false
                    // }
                    // disabled={
                    //   formValues.paymentMethod === "otherPayment" ||
                    //   formValues?.isEdit ||
                    //   (formValues.paymentMethod === "vendorPayment" &&
                    //     formValues.paymentMode === "AGAINST REF")
                    // }
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.amount}
                  </p>
                </div>

                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label="Payment Date*"
                    variant="outlined"
                    type="date"
                    focused
                    value={formValues?.paymentDate || ""}
                    onChange={getFormInfo("paymentDate")}
                    // disabled={formValues?.isEdit}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.paymentDate}
                  </p>
                </div>
                {formValues?.paymentMethod === "vendorPayment" && (
                  <div className="new-global-single-input auto-complete-new">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={["AGAINST REF", "ADVANCE"]}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      getOptionLabel={(option) => option}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Payment Type*" focused />
                      )}
                      value={formValues?.paymentMode}
                      onChange={getFormInfo("paymentMode")}
                      // disabled={formValues?.isEdit}
                    />
                    <p className="doc-validation-alert">
                      {validationAlert?.paymentMode}
                    </p>
                  </div>
                )}
                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label="Payment Reference*"
                    variant="outlined"
                    type="text"
                    focused
                    value={formValues?.paymentReference}
                    onChange={getFormInfo("paymentReference")}
                    // disabled={formValues?.isEdit}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.paymentReference}
                  </p>
                </div>
                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label="Amount In Words*"
                    variant="outlined"
                    type="text"
                    focused
                    value={numToWords(Math.floor(formValues?.amount))}
                    // disabled={formValues?.isEdit}
                  />
                </div>
                <div className="new-global-single-input auto-complete-new">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={branchList || []}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    getOptionLabel={(option) =>
                      `${option?.storeCode}-${option?.branchName}`
                    }
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Branch*" focused />
                    )}
                    value={formValues?.branchId}
                    onChange={getFormInfo("branchId")}
                    // disabled={formValues?.isEdit}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.branch}
                  </p>
                </div>
                <div className="new-global-single-input">
                  {MultiCurrencyView !== undefined &&
                  MultiCurrencyView?.isMultiCurrency ? (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={currencyListUpdated || []}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      getOptionLabel={(option) => option?.name}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Currency*" focused />
                      )}
                      value={formValues?.currency}
                      onChange={getFormInfo("currency")}
                      // disabled={formValues?.isEdit}
                    />
                  ) : (
                    <TextField
                      value={formValues?.currency?.name || ""}
                      sx={{ caretColor: "transparent" }}
                      className="disabled-input"
                      id="outlined-basic"
                      label="Currency*"
                      variant="outlined"
                      type="text"
                      focused
                      title="Multi Currency not enabled"
                      // disabled={formValues?.isEdit}
                    />
                  )}
                  <p className="doc-validation-alert">
                    {validationAlert?.currency}
                  </p>
                </div>
                {formValues?.paymentMethod === "vendorPayment" &&
                  formValues?.paymentMode === "ADVANCE" && (
                    <div className="new-global-single-input auto-complete-new">
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={customerPaymentAccounts}
                        isOptionEqualToValue={(option, value) =>
                          option._id === value._id
                        }
                        getOptionLabel={(option) => option?.accountName || ""}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Account*" focused />
                        )}
                        value={formValues?.account}
                        onChange={getFormInfo("account")}
                        // disabled={formValues?.isEdit}
                      />
                      <p className="doc-validation-alert">
                        {validationAlert?.account}
                      </p>
                    </div>
                  )}
              </div>
            </div>
            {/* other payment  */}
            {formValues?.paymentMethod === "otherPayment" && (
              <>
                <div className="new-global-white-bg-container">
                  <>
                    <div className="global-product-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Account</th>
                            <th>Label</th>
                            <th>Branch</th>
                            <th>Employee</th>
                            <th>Amount</th>
                          </tr>
                        </thead>

                        <tbody>
                          {tableData?.map((item, index) => (
                            <tr
                              key={index}
                              ref={
                                index === tableData.length - 1
                                  ? containerRef
                                  : null
                              }
                            >
                              {item.type === "header" ? (
                                <>
                                  <td colSpan="5">
                                    <input
                                      type="text"
                                      value={item.headerValue}
                                      onChange={handleInputChange(
                                        index,
                                        "headerValue"
                                      )}
                                      // disabled={formValues?.isEdit}
                                    />
                                  </td>
                                  <td style={{ border: "none", width: "4%" }}>
                                    <IconButton
                                      // disabled={formValues?.isEdit}
                                      onClick={() => handleDelete(index)}
                                      style={{ height: "30px" }}
                                    >
                                      <i
                                        className="bi bi-trash3"
                                        style={{
                                          color: "#db0000",
                                          fontSize: "1.3rem",
                                        }}
                                      ></i>
                                    </IconButton>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td style={{ minWidth: "200px" }}>
                                    <div className="product-table-auto-complete">
                                      <Autocomplete
                                        options={chartOfAccountList || []}
                                        isOptionEqualToValue={(option, value) =>
                                          option._id === value._id
                                        }
                                        style={{ maxHeight: "100px" }}
                                        getOptionLabel={(option) =>
                                          option?.accountName
                                        }
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                        value={item?.account}
                                        onChange={handleInputChange(
                                          index,
                                          "account"
                                        )}
                                        // disabled={formValues?.isEdit}
                                      />
                                    </div>
                                  </td>
                                  <td style={{ maxWidth: "180px" }}>
                                    <input
                                      type="text"
                                      value={item?.label}
                                      onChange={handleInputChange(
                                        index,
                                        "label"
                                      )}
                                      // disabled={formValues?.isEdit}
                                    />
                                  </td>
                                  <td style={{ minWidth: "200px" }}>
                                    <div className="product-table-auto-complete">
                                      <Autocomplete
                                        options={allBranchesList || []}
                                        isOptionEqualToValue={(option, value) =>
                                          option._id === value._id
                                        }
                                        style={{ maxHeight: "100px" }}
                                        getOptionLabel={(option) =>
                                          `${option?.storeCode}-${option?.branchName}`
                                        }
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                        value={item?.branch}
                                        onChange={handleInputChange(
                                          index,
                                          "branch"
                                        )}
                                        // disabled={formValues?.isEdit}
                                      />
                                    </div>
                                  </td>
                                  <td style={{ minWidth: "200px" }}>
                                    <div className="product-table-auto-complete">
                                      <Autocomplete
                                        options={allActiveEmpList || []}
                                        isOptionEqualToValue={(option, value) =>
                                          option._id === value._id
                                        }
                                        style={{ maxHeight: "100px" }}
                                        getOptionLabel={(option) =>
                                          option?.staff_name
                                        }
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                        value={item?.employee}
                                        onChange={handleInputChange(
                                          index,
                                          "employee"
                                        )}
                                        // disabled={formValues?.isEdit}
                                      />
                                    </div>
                                  </td>
                                  <td style={{ maxWidth: "80px" }}>
                                    <input
                                      type="text"
                                      value={item?.amount}
                                      onChange={handleInputChange(
                                        index,
                                        "amount"
                                      )}
                                      // disabled={formValues?.isEdit}
                                    />
                                  </td>
                                  <td style={{ border: "none", width: "4%" }}>
                                    <IconButton
                                      onClick={() => handleDelete(index)}
                                      style={{ height: "30px" }}
                                      // disabled={formValues?.isEdit}
                                    >
                                      <i
                                        className="bi bi-trash3"
                                        style={{
                                          color: "#db0000",
                                          fontSize: "1.3rem",
                                        }}
                                      ></i>
                                    </IconButton>
                                  </td>
                                </>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="add-row-btn-container">
                      <div
                        className="global-single-input auto-complete add-line-autocomplete"
                        style={{ width: "76%" }}
                      >
                        <Autocomplete
                          options={addRowData || []}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          getOptionLabel={(option) => option}
                          renderInput={(params) => <TextField {...params} />}
                          value={addRowInput}
                          onChange={(e, newValue) => setAddRowInput(newValue)}
                          renderClear={() => null}
                          // disabled={formValues?.isEdit}
                        />
                      </div>
                      <button
                        // disabled={formValues?.isEdit}
                        onClick={() => addRow()}
                        className="add-row-btn"
                      >
                        +
                      </button>
                    </div>
                  </>
                </div>
              </>
            )}
            {/* vendor payment  */}
            {formValues?.paymentMethod === "customerPayment" &&
              formValues?.paymentMode === "AGAINST REF" && (
                <>
                  <div className="new-global-white-bg-container">
                    <>
                      <div className="global-product-table">
                        <table>
                          <thead>
                            <tr>
                              <th>Bill</th>
                              <th>Date</th>
                              <th>Invoice Reference</th>
                              <th>Amount Total</th>
                              <th>Amount Due</th>
                              <th>Amount </th>
                            </tr>
                          </thead>

                          <tbody>
                            {Array.isArray(customerInvoice) &&
                            customerInvoice.length > 0 ? (
                              customerInvoice.map((item, index) => (
                                <tr
                                  key={index}
                                  ref={
                                    index === tableData?.length - 1
                                      ? containerRef
                                      : null
                                  }
                                >
                                  {item.type === "header" ? (
                                    <>
                                      <td colSpan="5">
                                        <input
                                          type="text"
                                          value={item.headerValue}
                                          onChange={handleInputChange(
                                            index,
                                            "headerValue"
                                          )}
                                          // disabled={formValues?.isEdit}
                                        />
                                      </td>
                                      <td
                                        style={{ border: "none", width: "4%" }}
                                      >
                                        <IconButton
                                          // disabled={formValues?.isEdit}
                                          onClick={() => handleDelete(index)}
                                          style={{ height: "30px" }}
                                        >
                                          <i
                                            className="bi bi-trash3"
                                            style={{
                                              color: "#db0000",
                                              fontSize: "1.3rem",
                                            }}
                                          ></i>
                                        </IconButton>
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td style={{ minWidth: "200px" }}>
                                        {item?.name}
                                      </td>
                                      <td style={{ maxWidth: "180px" }}>
                                        {item?.invoiceDate}
                                      </td>
                                      <td style={{ minWidth: "200px" }}>
                                        {item?.invoiceRef || ""}
                                      </td>
                                      <td style={{ minWidth: "200px" }}>
                                        {item?.total}
                                      </td>
                                      <td style={{ maxWidth: "80px" }}>
                                        {item?.amountDue}
                                      </td>
                                      <td>
                                        {" "}
                                        <input
                                          type="Number"
                                          value={item?.amount}
                                          onChange={(e) =>
                                            handleAmountChange(e, index, item)
                                          }
                                          // disabled={formValues?.isEdit}
                                        />
                                      </td>
                                      <td
                                        style={{ border: "none", width: "4%" }}
                                      >
                                        <Checkbox
                                          // disabled={formValues?.isEdit}
                                          checked={item?.isSelected}
                                          onChange={(e) =>
                                            handleSelected(e, index)
                                          }
                                          sx={{
                                            color: "#000000",
                                            "&.Mui-checked": {
                                              color: "#000000",
                                            },
                                          }}
                                        />
                                      </td>
                                    </>
                                  )}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td style={{ textAlign: "center" }} colSpan={6}>
                                  No Data
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  </div>
                </>
              )}
            <hr className="global-hr" />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() => {
                  customerPaymentSingleViewApi({ _id: singleViewId }) &&
                    setImportPopup(false);
                }}
                style={{ height: "35px", width: "25%" }}
                className="btn btn-secondary-outlined"
              >
                Cancel
              </button>
              <button
                style={{ height: "35px", width: "25%" }}
                className="btn btn-primary"
                onClick={formSubmitFn("update")}
              >
                Post
              </button>
            </div>
          </div>
        </Dialog>

        {/* payment return */}
        <Dialog
          open={alertPopup}
          onClose={() => {
            alertPopup();
          }}
          maxWidth="lg"
        >
          <div style={{ textAlign: "center", paddingTop: "24px" }}>
            {" "}
            <ReportProblemOutlinedIcon
              sx={{ color: " #d50808", fontSize: "xx-large" }}
            />
          </div>
          <div
            className="shift-end-button-password-container"
            style={{ width: "25vw", padding: "2% 4%" }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                margin: "2% 0",
                alignSelf: "center",
                paddingBottom: "14px",
              }}
            >
              Are you sure you want to return
            </div>
            <hr
              style={{
                backgroundColor: "#bfbfc3",
                width: "100%",
                height: "2px",
                marginBottom: "27px",
              }}
            ></hr>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                style={{ height: "28px", width: "28%" }}
                className="btn btn-primary"
                onClick={formSubmitFn("return")}
              >
                Yes
              </button>
              <button
                onClick={() => setAlertPopup(false)}
                style={{ height: "28px", width: "28%" }}
                className="btn btn-secondary-outlined"
              >
                No
              </button>
            </div>
          </div>
        </Dialog>
        <SuccessSnackbar
          open={snackBarStates.success}
          handleClose={closeSnackbar}
          message={snackBarStates.message}
        />
        <ErrorSnackbar
          open={snackBarStates.error}
          handleClose={closeSnackbar}
          message={snackBarStates.message}
        />

        <div style={{ display: "none" }}>
          <div ref={(el) => (paymentsPrintNew = el)}>
            {formValues?.paymentMethod === "otherPayment" ? (
              <Template />
            ) : (
              <TemplateCustomer />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
