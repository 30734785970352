import axios from "axios";
import { setSubmitLoading, setTableLoadingGlobal } from "../../../../Redux/Dashboard/mainDashBoardSlice";
import store from "../../../../Redux/store";
import { setAlertMessage, setIsLoading } from "../../kitchenOrderTicket/kitchenOrderTicketSlice";
import { setDeliveryOrderList } from "./deliveryScreenSlice";
import { HEADERS } from "../../../../API/UrlAndPaths";
import { alertMessages } from "../../FloorManagement/utils/floorManagementEnum";

//list Delivery orders API
export const listDeliveryOrderAPI = async (obj) => {
    store.dispatch(setTableLoadingGlobal(true))
    try {
      const result = await axios.post(
        "pos/viewKotOrdersWithFilter",
        obj?.body,
        HEADERS
      );
      if (result.status === 200) {
        store.dispatch(setTableLoadingGlobal(false))
        store.dispatch(setIsLoading(false));
        store.dispatch(setDeliveryOrderList(result.data));
      }
    } catch (error) {
      store.dispatch(setDeliveryOrderList([]));
      store.dispatch(setTableLoadingGlobal(false))
      store.dispatch(setIsLoading(false));
      store.dispatch(
        setAlertMessage({
          status: alertMessages.ERROR,
          message: error.response.data || "Error occurred while fetching data!",
        })
      );
    }
  };
//edit aggregator API
export const aggregatorUpdateAPI = async (body) => {
    store.dispatch(setSubmitLoading(true))
    try {
      const result = await axios.post(
        "pos/editAggregatorFromKot",
        body,
        HEADERS
      );
      if (result.status === 200) {
        store.dispatch(setSubmitLoading(false))
      }
    } catch (error) {
      store.dispatch(setSubmitLoading(false))
      store.dispatch(
        setAlertMessage({
          status: alertMessages.ERROR,
          message: error.response.data || "Error occurred while fetching data!",
        })
      );
    }
  };
export const pickupDeliveryOrder = async (body) => {
    store.dispatch(setSubmitLoading(true))
    try {
      const result = await axios.post(
        "pos/kotStatusToPickUp",
        body,
        HEADERS
      );
      if (result.status === 200) {
        store.dispatch(setSubmitLoading(false))
      }
    } catch (error) {
      store.dispatch(setSubmitLoading(false))
      store.dispatch(
        setAlertMessage({
          status: alertMessages.ERROR,
          message: error.response.data || "Error occurred while fetching data!",
        })
      );
    }
  };

export const orderDeliveredAPI = async (body) => {
    store.dispatch(setSubmitLoading(true))
    try {
      const result = await axios.post(
        "pos/kotStatusPickUpToDelivered",
        body,
        HEADERS
      );
      if (result.status === 200) {
        store.dispatch(setSubmitLoading(false))
      }
    } catch (error) {
      store.dispatch(setSubmitLoading(false))
      store.dispatch(
        setAlertMessage({
          status: alertMessages.ERROR,
          message: error.response.data || "Error occurred while fetching data!",
        })
      );
    }
  };