import axios from "axios";
import { HEADERS } from "../../../API/UrlAndPaths";
import store from "../../../Redux/store";
import { setKotTokenData } from "./kotTokenDisplaySlice";
import { tokenStatus } from "./util/kotTokenUtil";

export const getTokenDisplayAPI = async (obj) => {
  let status;
  try {
    const response = await axios.post(
      "pos/tokenDisplayByBranch",
      obj.body,
      HEADERS
    );
    if (response.status === 200) {
      if (response.status === 200) {
        const { completed, preparing } = response?.data;

        const completedTables = completed?.tables || [];
        const preparingTables = preparing?.tables || [];

        store.dispatch(
          setKotTokenData({
            completed: completedTables,
            preparing: preparingTables,
          })
        );
      }
    }
  } catch (error) {
    store.dispatch(
      setKotTokenData(
        store.dispatch(setKotTokenData({ completed: [], preparing: [] }))
      )
    );
    console.log(error);
  }
};
