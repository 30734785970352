import { createSlice } from "@reduxjs/toolkit";

export const GeneralSlice=createSlice({
    name:'generalSlice',
    initialState:{
        orderList:undefined,
        orderListSingleView:undefined,
        PaymentAndWalletList:undefined,
        shiftList:undefined,
        shiftListSingleView:undefined,
    },
    reducers:{
        get_order_list:(state,action)=>{
            state.orderList=action.payload
        },
        single_view_pos_order:(state,action)=>{
            state.orderListSingleView=action.payload
        },
        get_payment_wallet_list:(state,action)=>{
            state.PaymentAndWalletList=action.payload
        },
        get_shift_list:(state,action)=>{
            state.shiftList=action.payload
        },
        single_view_pos_shift:(state,action)=>{
            state.shiftListSingleView=action.payload
        },
       
    }
})
export const {get_order_list,single_view_pos_order,get_payment_wallet_list,get_shift_list,single_view_pos_shift}=GeneralSlice.actions
export default GeneralSlice.reducer