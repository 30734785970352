import React from "react";
import KotCard from "../kotCard/KotCard";
import "./style.css";
import { useSelector } from "react-redux";
import { NETWORK } from "../../utils/kotEnum";
import SkeletonComponent from "../skeleton/Skeleton";

const KotBody = () => {
  const { kotOrderList, networkStatus } = useSelector(
    (state) => state.kitchenOrderTicketSlice
  );


  return (
    <div className="kot-element-body">
      {networkStatus === NETWORK.OFFLINE && <SkeletonComponent />}
      {networkStatus === NETWORK.ONLINE && kotOrderList?.length > 0
        ? kotOrderList?.map((res, i) => (
            <KotCard
              key={i}
              elementData={res}
              orderNo={res?.ordName}
              tableNo={res?.tables}
              tokenNo={res?.transNo}
              orderInfo={res?.orderInfo}
              status={res?.status}
              day={res?.orderDate}
            />
          ))
        : ""}
    </div>
  );
};

export default KotBody;
