import { createSlice } from "@reduxjs/toolkit";


export const deviceSettingsSlice=createSlice({
    name:"deviceSettingsSlice",
    initialState: {
        printersList:[]
    },
    reducers:{
        store_printers_list:(state,action)=>{
            state.printersList=action.payload
        }
    }
})


export const {store_printers_list} = deviceSettingsSlice.actions;

export default deviceSettingsSlice.reducer;