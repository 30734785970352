import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDialogBox } from '../Slice/billingScreenSlice'
import HttpsIcon from '@mui/icons-material/Https';
import { setAuthPassword } from '../newBillingSlice';
const UpdateOrderAuth = ({submitClick}) => {
    const dispatch=useDispatch()
    const {authPassWord}=useSelector((state)=>state.newBillingSlice)
  return (

      <div className='wallet-change-dialog' style={{ maxWidth: "350px", minWidth: "350px" }}>
          <div className="head justify-center">
              <div className="icon justify-center" style={{ backgroundColor: "#dfaab4" }}>
                  <HttpsIcon color='error' />
              </div>
              Update Order
          </div>
          <div className="input-ctn">
              <button disabled className="btn" style={{ minWidth: "45px" }}><HttpsIcon fontSize='small' color="error" /></button>
              <input
                  value={authPassWord}
                  placeholder='Password'
                  type="password"
                  id="password"
                  name="password"
                  autoComplete="new-password"
                  style={{ textAlign: "start" }}
                  onChange={(e) => dispatch(setAuthPassword(e.target.value))}
                  onKeyDown={(e)=>e.key==="Enter" &&submitClick()}
              />
          </div>
          <div className="wallet-change-btn" style={{ justifyContent: "center" }}>
              <button
                  onClick={() => dispatch(setDialogBox(null))}
                  className="btn-secondary-outlined btn">Cancel</button>
              <button
                  onClick={submitClick}
                  className="btn-primary btn" style={{ backgroundColor: "#c01c1b" }}>Submit</button>
          </div>
      </div>
  )
}

export default UpdateOrderAuth