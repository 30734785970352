import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import draftRibbon from "../../../Assets/statusRibbon/draft.png";
import postRibbon from "../../../Assets/statusRibbon/post.png";
import cancelRibbon from "../../../Assets/statusRibbon/cancel.png"
import { ListRecipeAPI, ManufacturingOerderAddAPI, RowMaterialAPI, SingleViewOrderAPI, SingleViewRecipeAPI, cancelManufacturingOrderAPI, editedManufactureOrderAPI, getConfigAPI, postManufacturingOrderAPI } from './FoodManagementNewAPI';
import { viewAllBranchesAPICall } from '../../../API/Settings/Company Settings/companySettingsAPI';
import { chartOfAccountListApiCall } from '../../../API/AccountUpdated/chartOfAccountAPI';
import { viewUOMSubListAPICall } from '../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall';
import { vieWareHouseLocationAPICall } from '../Inventory/Configuration/wareHouseLocationAPI';
import { findObjFromList } from '../../../Js/generalFunctions';
import { list_single_view } from './FoodManagementSlice';
import store from '../../../Redux/store';
import { today } from '../../../Js/Date';
import { get_wareHouse_location_list } from '../Inventory/Configuration/wareHouseLocationSlice';

const ManufacturingOrder = () => {

  
  let navigateTo = useNavigate();
  const singleViewId = localStorage.getItem("order_id")
  const singleViewRecipe = useSelector(
    (state) => state?.foodManagement.listSinglRecipe);

  const [selectedButton, setSelectedButton] = useState("Components");

  const allNewProductList = useSelector(
    (state) => state.vendorBillSlice.NewProductList
  );
  // console.log(allNewProductList);
  const allRowMaterial = useSelector(
    (state) => state.foodManagement.rowMaterial
  );
  const uomSubList = useSelector(
    (state) => state.unitOfMeasurmentSlice.subUomList
  );

  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );

  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );
  // console.log(allActiveEmpList);
  const allBranchesList = useSelector(
    (state) => state.allBranchesSlice.value);


  const config = useSelector(
    (state) => state?.foodManagement.valueConfig);

  const allRecipeList = useSelector(
    (state) => state.foodManagement.listRecipe);

  const singleView = useSelector(
    (state) => state?.foodManagement.singlelistOrder);

  const wareHouseLocationList = useSelector(
    (state) => state.wareHouseLocationSlice.value)

  const formInitialState = {
    bom: null,
    product: null,
    productionDate: today,
    location: null,
    orderDate: today,
    qty: "",
    uom: "",
    status: "",
    branch: null,
    isEdit: false,
  }
  const [formValues, setFormValues] = useState(formInitialState);
  


  const handleInputChange = (key) => (e, newValue) => {
    if (key === "qty") {
       const updated=e.target.value

      recalculateTableRowQuantities(parseFloat(updated===""?0:updated),formValues?.recipeeQty||0);
    }

    if (key === "product") {
      setFormValues({...formValues,product:newValue,bom:null});
   }
   if (key === "branch") {
    setFormValues({...formValues,branch:newValue,location:null});
 }
   else{
    setFormValues({...formValues,[key]:newValue !== undefined ? newValue : e.target.value});
   }
   
    if(key === "bom") {
      SingleViewRecipeAPI({recipeId:newValue._id})
    }

  }
//qty
  const recalculateTableRowQuantities = (newExternalQty, previousExternalQty) => {
  //  console.log(newExternalQty);
  //  console.log(previousExternalQty);
    const updatedRows = tableDataComponents.map((row) => {
     
      const newQty = (row.recipeeTableQty  / previousExternalQty) * newExternalQty;
      return { ...row, qty: newQty };
    });
    const updatedProductRows = tableDataByProducts.map((row) => {
     
      const newQty = (row.recipeeTableQty  / previousExternalQty) * newExternalQty;
      return { ...row, qty: newQty };
    });

    setTableDataComponents(updatedRows);
    setTableDataByProducts(updatedProductRows);
  };


  const componentInitial = {
    product: null,
    qty: "",
    uom: null,
  }
  const [tableDataComponents, setTableDataComponents] = useState([componentInitial]);

  const stageInitial = {
    name: "",
    account: null,
    employee: null,
    // qty: "",
    // unitprice: "",
    total: "",
  }
  const [tableDataStages, setTableDataStages] = useState([stageInitial]);

  const productInitial = {
    product: null,
    qty: "",
    uom: null,
  }
  const [tableDataByProducts, setTableDataByProducts] = useState([productInitial]);

  //table onchange//
  const tableInputChange = (id, key, type) => (e, newValue) => {
    const { value } = e.target

    if (type === 1) {
      const updatedData = tableDataComponents.map((row, index) => {
        if (index === id) {
          if(key==="product"){
          return { ...row, 
             [key]: newValue === undefined ? value : newValue,
             uom:newValue.uomName 
            };
           
          }
          return { ...row, [key]: newValue === undefined ? value : newValue };
        }
        return row;
      });
      setTableDataComponents(updatedData)
    }
   

    else if (type === 2) {
      const updatedData = tableDataStages.map((row, index) => {
        if (index === id) {

          return { ...row, [key]: newValue === undefined ? value : newValue };

        }
        return row;
      });
      setTableDataStages(updatedData)
    }
    else {
      const updatedData = tableDataByProducts.map((row, index) => {
        if (index === id) {
          if(key==="product"){
          return { ...row, [key]: newValue === undefined ? value : newValue ,uom:newValue?.uomName};

          }
          return { ...row, [key]: newValue === undefined ? value : newValue };

        }
        return row;
      });
      setTableDataByProducts(updatedData)
    }


  }

  console.log(tableDataComponents)

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };
  const handleBack = () => {
    localStorage.removeItem("order_id")
    
    navigateTo("/userdashboard/foodManagement/preperation")
  }
  //post
  const postData = () => {
    postManufacturingOrderAPI({ manufacturingOrderId: singleViewId })
  }

  const cancelData = () => {
    cancelManufacturingOrderAPI({ manufacturingOrderId: singleViewId, type: 1 })
  }

  const resetData =()=>{
    cancelManufacturingOrderAPI({ manufacturingOrderId: singleViewId, type: 2 })

  }

  //save//
  const handleSave = () => {

    let componentArray = tableDataComponents?.filter((obj) => (obj?.product !== null && obj?.quantity !==null && obj?.uom!==null))?.map((obj) => (
      {
        productId: obj?.product?._id,
        quantity: obj?.qty,
        uom: obj?.product?.uomId
      }
    ))
    
    let stageArray = tableDataStages?.filter((obj) => (obj?.name !== null && obj?.account !== null && obj?.employee!== null  && obj?.quantity!== null  && obj?.unitprice!== null  && obj?.total!== null ))?.map((obj) => (
      {
        name: obj?.name,
        account: obj?.account?._id,
        employee: obj?.employee?._id,
        // quantity: obj?.qty,
        // unitprice: obj?.unitprice,
        total: obj?.total,
      }
    ))
    let productArray = tableDataByProducts?.filter((obj) => (obj?.product !== null && obj?.quantity!== null  && obj?.uom!== null ))?.map((obj) => (
      {
        productId: obj?.product?._id,
        quantity: obj?.qty,
        uom: obj?.product?.uom
      }
    ))

    let body = {
      bomRecipeId: formValues?.bom?._id,
      productId: formValues?.product?._id,
      quantity: formValues?.qty,
      uom: formValues?.product?.uom,
      locationId: formValues?.location?._id,
      branchId: formValues?.branch?._id,
      orderDate: formValues?.orderDate,
      productionDate: formValues?.productionDate,
      components: componentArray,
      stages: stageArray,

      byProducts: productArray,

    }
    ManufacturingOerderAddAPI(body)

  }

  // console.log(uomSubList);
///recipeee
useEffect(() => {
  // Check if BOM is selected
  if (formValues.bom) {
    if (singleViewRecipe !== undefined) {
      console.log("recipee");
      setFormValues((prevValues) => ({
        ...prevValues,
        qty: singleViewRecipe?.quantity,
        recipeeQty:singleViewRecipe?.quantity
      }));

      const tablecomponent = singleViewRecipe?.components?.map((r, i) => ({
        product: findObjFromList("_id", allRowMaterial, "productId", r),
        qty: r.quantity,
        uom: r.uomName,
        recipeeTableQty: r.quantity,
      }));
      setTableDataComponents(tablecomponent);

      const tableStagesData = singleViewRecipe?.stages?.map((r, i) => ({
        name: r?.name,
        account: findObjFromList("_id", chartOfAccountList, "account", r),
        employee: findObjFromList("_id", allActiveEmpList, "employee", r),
        // unitprice: r.unitprice,
        total: r.total,
      }));
      setTableDataStages(tableStagesData);

      const tableProduct = singleViewRecipe?.byProducts?.map((r, i) => ({
        product: findObjFromList("_id", allNewProductList, "productId", r),
        qty: r.quantity,
        uom:r?.uomName,
        recipeeTableQty: r.quantity,
      }));
      setTableDataByProducts(tableProduct);
    }
  } else {
    // Clear table data if no BOM is selected
    setFormValues((prevValues) => ({
      ...prevValues,
      qty: "", // Reset or clear quantity
    }));
    setTableDataComponents([]);
    setTableDataStages([]);
    setTableDataByProducts([]);
  }
}, [singleViewRecipe, allNewProductList, formValues.bom]);

// console.log(allRowMaterial);
  //singleView//
  useEffect(() => {
    if (singleView !== undefined) {
      console.log(singleView);
      setFormValues({
        ...formValues,
        isEdit: true,
        bom: findObjFromList("_id", allRecipeList?.list, "bomRecipeId", singleView),
        product: findObjFromList("_id", allNewProductList, "productId", singleView),
        qty: singleView?.quantity,
        productionDate: singleView?.productionDate?.split(" ")[0],
        location: findObjFromList("_id", wareHouseLocationList, "locationId", singleView),
        orderDate: singleView?.orderDate?.split(" ")[0],
        status: singleView?.status,
        recipeeQty:singleView?.quantity,
        branch: findObjFromList("_id", allBranchesList, "branchId", singleView)
      });

      const tablecomponent = singleView?.components?.map((r, i) => (
        {
          product: findObjFromList("_id", allRowMaterial, "productId", r),
          qty: r.quantity,
          uom: r.uomName,
          recipeeTableQty: r.quantity,


        }
      ))
      // console.log(tablecomponent);
      setTableDataComponents(tablecomponent)

      const tableStagesData = singleView?.stages?.map((r, i) => (
        {
          name: r?.name,
          account: findObjFromList("_id", chartOfAccountList, "account", r),
          employee: findObjFromList("_id", allActiveEmpList, "employee", r),
          total: r.total,
        }
      ))
      setTableDataStages(tableStagesData)

      const tableProduct = singleView?.byProducts?.map((r, i) => (
        {
          product: findObjFromList("_id", allNewProductList, "productId", r),
          qty: r.quantity,
          uom: r.uomName
        }
      ))
      setTableDataByProducts(tableProduct)

    }
  }, [singleView, allNewProductList,allRowMaterial])


//cost
const handleCost = () => {
  navigateTo("/userdashboard/foodManagement/manufacturingOrder/costAnalysis")
}

  //edit//
  const editOnClick = () => {
    if (formValues.isEdit === true) {
      setFormValues({ ...formValues, isEdit: false })
    }
    else {
      let componentArray = tableDataComponents?.filter((obj) => (obj?.product !== null && obj?.quantity!== null  && obj?.uom!== null ))?.map((obj) => (
        {
          productId: obj?.product?._id,
          quantity: obj?.qty,
          uom: obj?.product?.uomId,
         
        }
      ))
      let stageArray = tableDataStages?.filter((obj) => (obj?.name !== null && obj?.account!== null  && obj?.employee!== null  && obj?.quantity!== null  && obj?.unitprice!== null  && obj?.total!== null ))?.map((obj) => (
        {
          name: obj?.name,
          account: obj?.account?._id,
          employee: obj?.employee?._id,
          total: obj?.total,
        }
      ))
      let productArray = tableDataByProducts?.filter((obj) => (obj?.product !== null && obj?.quantity!== null  && obj?.uom!== null ))?.map((obj) => (
        {
          productId: obj?.product?._id,
          quantity: obj?.qty,
          uom: obj?.product?.uom
        }
      ))

      let body = {
        manufacturingOrderId: singleView?._id,
        bomRecipeId: formValues?.bom?._id,
        productId: formValues?.product?._id,
        quantity: formValues?.qty,
        uom: formValues?.product?.uom,
        locationId: formValues?.location?._id,
        branchId: formValues?.branch?._id,
        orderDate: formValues?.orderDate,
        productionDate: formValues?.productionDate,
        components: componentArray,
        stages: stageArray,

        byProducts: productArray,

      }

      const draftFn = () => {
        setFormValues({ ...formValues, isEdit: true });
      };
      editedManufactureOrderAPI(body, draftFn)
    }
  }


  //delete Row//
  const deleteRow = (tableType, index) => {
    let updatedTableData;
    if (tableType === 'Components') {
      updatedTableData = [...tableDataComponents];
      updatedTableData.splice(index, 1);
      setTableDataComponents(updatedTableData);
    } else if (tableType === 'Stages') {
      updatedTableData = [...tableDataStages];
      updatedTableData.splice(index, 1);
      setTableDataStages(updatedTableData);
    } else if (tableType === 'By Products') {
      updatedTableData = [...tableDataByProducts];
      updatedTableData.splice(index, 1);
      setTableDataByProducts(updatedTableData);
    }
  };
  //add Row//
  const addRow = (tableType) => {

    let updatedTableData;
    if (tableType === 'Components') {
      updatedTableData = [...tableDataComponents, { ...componentInitial }];
      setTableDataComponents(updatedTableData);
    } else if (tableType === 'Stages') {
      updatedTableData = [...tableDataStages, { ...stageInitial }];
      setTableDataStages(updatedTableData);
    } else if (tableType === 'By Products') {
      updatedTableData = [...tableDataByProducts, { ...productInitial }];
      setTableDataByProducts(updatedTableData);
    }
  };
  useEffect(() => {
    RowMaterialAPI({ type: 2 })
    viewUOMSubListAPICall()
    chartOfAccountListApiCall();
    viewAllBranchesAPICall();
    vieWareHouseLocationAPICall()
    getConfigAPI();
    ListRecipeAPI();
    if (singleViewId !== null) {
      SingleViewOrderAPI({ manufacturingOrderId: singleViewId });
    }
  }, [])
  useEffect(() => {
    if (formValues?.branch !== null) {
      vieWareHouseLocationAPICall({ branchId: formValues?.branch?._id })
    }
    else{
      store.dispatch(get_wareHouse_location_list({wareHouseLocationData:undefined}))
    }

    if(formValues?.isEdit){
      setFormValues({
        ...formValues,
        location: findObjFromList("_id", wareHouseLocationList, "locationId", singleView)
      });
    }
  }, [formValues?.branch])


  return (
    <div className="global-page-parent-container">
      <div className="global-white-bg-container Header" style={{display:"flex",alignItems:"center"}}>
      <Tooltip title="Back">
              <IconButton onClick={() => handleBack()}>
                <i className="bi bi-chevron-left" style={{ fontSize: '30px', color: 'black', cursor: 'pointer' }}></i>
              </IconButton>
            </Tooltip>
        <p>Preparation</p>
      </div>
      <div className="global-white-bg-container ">
        <div className="food_config_secound_header" style={{ paddingInline: "0" }}>
          <div className="post-ribbon" style={{ right: "0px", top: "0px" }}>
            {formValues?.status === "DRAFTED" && <img src={draftRibbon} alt="" />}
            {formValues?.status === "POSTED" && <img src={postRibbon} alt="" />}
            {formValues?.status === "CANCELLED" && <img src={cancelRibbon} alt="" />}

          </div>
          <div>
           
            {singleView !== undefined && formValues?.status !== "POSTED" &&(
              <Tooltip title="Edit">
                <IconButton onClick={editOnClick}>
                  <i
                    class={formValues?.isEdit ?
                      "bi bi-pencil-square edit-icon1"
                      :
                      "bi bi-check2-square edit-icon"
                    }
                  ></i>
                </IconButton>
              </Tooltip>
            )}
            {singleView === undefined && formValues?.status !== "POSTED" &&(
              <Tooltip title="Save">
                <IconButton onClick={handleSave}>
                  <i className="bi bi-floppy" style={{ color: 'black' }}></i>
                </IconButton>
              </Tooltip>
            )}
            {formValues?.status === "DRAFTED" && formValues.isEdit===true &&(
              <button onClick={postData} className='create-button-blue' style={{ backgroundColor: "#1038ed", marginLeft: "3px" }}>Process</button>
            )}
            {formValues?.status === "CANCELLED" && (
              <button  onClick={resetData} className='create-button-blue' style={{ backgroundColor: "#1038ed", marginLeft: "3px" }}>Reset To Draft</button>
            )}
            {formValues?.status === "DRAFTED" && formValues.isEdit===true &&(
              <button onClick={cancelData} className='create-button-blue' style={{ backgroundColor: "rgb(245 112 52)", marginLeft: "10px" }}>Cancel</button>
            )}
          </div>
        </div>
        <hr className="global-hr" />
        <div className="div_data_container">
          <div style={{ width: '50%' }}>
            <p style={{ fontWeight: 700, fontSize: 'larger', margin: '2px' }}>{singleView?.sequenceName}</p>
            <div style={{ display: 'flex', justifyContent: "space-between" }}>

              <p style={{ paddingLeft: '12px' }}>Product</p>
              <div className="new-global-single-input auto-complete-new" style={{ width: '79%' }}>
                <Autocomplete
                  disablePortal
                  options={allNewProductList?.filter(item=>item.isRawMaterial===false) || []} // Populate options with proper data
                  getOptionLabel={(option) => option?.productName}
                  sx={{ width: 300 }}
                  onChange={handleInputChange("product")}
                  value={formValues?.product}
                  renderInput={(params) => <TextField {...params} label="" focused />}
                  disabled={formValues?.isEdit}
                />

              </div>
            </div>


            <div style={{ display: 'flex', justifyContent: "space-between" }}>
              <p style={{ paddingLeft: '12px' }}>Recipe</p>
              <div className="new-global-single-input auto-complete-new" style={{ width: '79%' }}>
                <Autocomplete
                  disablePortal
                  options={allRecipeList?.list?.filter(obj=>obj.productId ===formValues?.product?._id) || []} // Populate options with proper data
                  getOptionLabel={(option) => option?.bomName}
                  sx={{ width: 300 }}
                  onChange={handleInputChange("bom")}
                  value={formValues?.bom}
                  renderInput={(params) => <TextField {...params} label="" focused />}
                  disabled={formValues?.isEdit}
                />

              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: "space-between" }}>
              <p style={{ paddingLeft: '12px' }}>Production Date</p>
              <div className="new-global-single-input" style={{ width: '79%' }}>
                <TextField
                  id="outlined-basic"
                  // placeholder="Enter BOM Name"
                  onChange={handleInputChange("productionDate")}
                  value={formValues?.productionDate}
                  variant="outlined"
                  type="date"
                  focused
                  disabled={formValues?.isEdit}
                />
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: "space-between" }}>
              <p style={{ paddingLeft: '12px' }}>Location</p>
              <div className="new-global-single-input auto-complete-new" style={{ width: '79%' }}>
                <Autocomplete
                  disablePortal
                  options={wareHouseLocationList || []} // Populate options with proper data
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: 300 }}
                  onChange={handleInputChange("location")}
                  value={formValues?.location}
                  renderInput={(params) => <TextField {...params} label="" focused />}
                  disabled={formValues?.isEdit}
                />

              </div>
            </div>
          </div>
          <div style={{ width: '50%', paddingLeft: "52px" }}>
            <p style={{ color: '#e5611f', margin: '2px 38px 2px auto', textAlign: 'end', cursor: "pointer",marginLeft:"auto",width:"fit-content" }}onClick={() => handleCost()}>Cost Analysis</p>

            <div style={{ display: 'flex',justifyContent:"space-between" }}>
              <p style={{ paddingLeft: '12px' }}>Qty</p>
              <div style={{display:"flex",width:"82%"}}>
              <div className="new-global-single-input" style={{ width: '96%' }}>
               <div className='justify-center'>
                <TextField
                  id="outlined-basic"
                  placeholder="qty"
                  variant="outlined"
                  type="text"
                  onChange={handleInputChange("qty")}
                  value={formValues?.qty}
                  focused
                  disabled={formValues?.isEdit}
                />
                <div style={{height:"41px",padding:"10px",backgroundColor:"#c3c0c0",paddingInline:"15px"}}>
                  {formValues?.product?.uomName || "UOM"}
                </div>
                </div>
              </div>
              {/* <div className="new-global-single-input">
                <TextField
                  id="outlined-basic"
                  placeholder="UOM"
                  variant="outlined"
                  type="text"
                  value={formValues?.product?.uomName}
                  focused
                  readOnly
                  disabled={formValues?.isEdit}
                />
              </div> */}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
              <p style={{ paddingLeft: '12px', margin: "0", alignSelf: "center" }}>Order Date</p>
              <div className="new-global-single-input" style={{ width: '79%' }}>
                <TextField
                  id="outlined-basic"
                  placeholder="Enter BOM Name"
                  onChange={handleInputChange("orderDate")}
                  value={formValues?.orderDate}
                  variant="outlined"
                  type="date"
                  focused
                  disabled={formValues?.isEdit}
                />
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: "space-between" }}>
              <p style={{ paddingLeft: '12px' }}>Branch</p>
              <div className="new-global-single-input auto-complete-new" style={{ width: '79%' }}>
                <Autocomplete
                  disablePortal
                  options={allBranchesList || []} // Populate options with proper data
                  getOptionLabel={(option) => option?.branchName}
                  sx={{ width: 300 }}
                  onChange={handleInputChange("branch")}
                  value={formValues?.branch}
                  renderInput={(params) => <TextField {...params} label="" focused />}
                  disabled={formValues?.isEdit}
                />

              </div>
            </div>
          </div>
        </div>


        <div className="button-div">

          <button
            className={selectedButton === 'Components' ? 'selected-button' : ''}
            onClick={() => handleButtonClick('Components')}
          >
            Components
          </button>
          {config?.isStageEnabled === true && (
            <button
              className={selectedButton === 'Stages' ? 'selected-button' : ''}
              onClick={() => handleButtonClick('Stages')}
            >
              Stages
            </button>
          )}
          {config?.isByProductEnabled === true && (
            <button
              className={selectedButton === 'By Products' ? 'selected-button' : ''}
              onClick={() => handleButtonClick('By Products')}
            >
              By Products
            </button>
          )}
        </div>
        {selectedButton === "Components" && (
          <div className="global-product-table special-items-table">
            <table style={{ borderBottom: '2px solid #dee3f8' }}>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Quatity</th>
                  <th>Unit</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableDataComponents?.map((r, index) => (
                  <tr >
                    <td style={{ paddingTop: "0" }}>
                      <div className="product-table-auto-complete">
                        <Autocomplete
                          style={{ maxHeight: "100px" }}
                          options={allRowMaterial || []}
                          getOptionLabel={(option) => option?.name}
                          disabled={formValues?.isEdit}
                          renderInput={(params) => (
                            <TextField
                              {...params}

                            />

                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              <span>{option.name}</span>
                            </li>
                          )}
                          value={r?.product}
                          onChange={tableInputChange(index, "product", 1)}
                        />
                      </div>
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <input
                        type="number"

                        onChange={tableInputChange(index, "qty", 1)}
                        value={r?.qty}
                        disabled={formValues?.isEdit}

                      />
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <div className="product-table-auto-complete">
                      <input
                        type="text"

                        // onChange={tableInputChange(index, "qty", 1)}
                        value={r.uom}
                        disabled={formValues?.isEdit}

                      />
                        {/* <Autocomplete
                          options={uomSubList?.filter(obj => obj._id === r?.product?.uomId) || []}

                          style={{ maxHeight: "100px" }}
                          getOptionLabel={(option) => option?.uomName}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              <span>{option.uomName}</span>
                            </li>
                          )}
                          value={r?.uom}
                          onChange={tableInputChange(index, "uom", 1)}
                          disabled={formValues?.isEdit}
                        /> */}
                      </div>
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <IconButton onClick={() => deleteRow('Components', index)}
                        disabled={formValues?.isEdit}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="add-row-btn-container">
              <div
                className="global-single-input auto-complete"
                style={{
                  width: '76%',
                  fontSize: '0.9rem',
                  paddingLeft: '7.5px',
                  fontWeight: '600',
                  color: 'rgb(134, 152, 229)',
                  cursor: 'pointer',
                }}
                onClick={() => addRow('Components')}
              >
                Add Line
              </div>
            </div>
          </div>
        )}

        {selectedButton === "Stages" && (
          <div className="global-product-table special-items-table">
            <table style={{ borderBottom: '2px solid #dee3f8' }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Account</th>
                  <th>Employee</th>
                  {/* <th>Qty</th>
                  <th>Unit Price</th> */}
                  <th>Amount</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableDataStages.map((r, index) => (
                  <tr >
                    <td >
                      <input
                        type="text"
                        onChange={tableInputChange(index, "name", 2)}

                        value={r.name}
                        disabled={formValues?.isEdit}
                      />
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <div className="product-table-auto-complete" style={{ minWidth: "136px" }}>
                        <Autocomplete
                          options={chartOfAccountList || []}
                          style={{ maxHeight: "100px" }}
                          getOptionLabel={(option) => option?.accountName}
                          disabled={formValues?.isEdit}
                          renderInput={(params) => (
                            <TextField
                              {...params}

                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              <span>{option.accountName}</span>
                            </li>
                          )}
                          value={r?.account}
                          onChange={tableInputChange(index, "account", 2)}
                        />
                      </div>
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <div className="product-table-auto-complete" style={{ minWidth: "136px" }}>
                        <Autocomplete
                          options={allActiveEmpList || []}
                          style={{ maxHeight: "100px" }}
                          getOptionLabel={(option) => option?.staff_name}
                          disabled={formValues?.isEdit}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              <span>{option.staff_name}</span>
                            </li>
                          )}
                          value={r?.employee}
                          onChange={tableInputChange(index, "employee", 2)}
                        />
                      </div>
                    </td>
                    {/* <td style={{ paddingTop: "0" }}>
                      <input
                        type="number"
                        onChange={tableInputChange(index, "qty", 2)}
                        value={r.qty}
                        disabled={formValues?.isEdit}
                      />
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <input
                        type="number"
                        onChange={tableInputChange(index, "unitprice", 2)}
                        value={r.unitprice}
                        disabled={formValues?.isEdit}
                      />
                    </td> */}
                    <td style={{ paddingTop: "0" }}>
                      <input
                        type="number"
                        onChange={tableInputChange(index, "total", 2)}
                        value={r.total}
                        disabled={formValues?.isEdit}
                      />
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <IconButton onClick={() => deleteRow('Stages', index)} disabled={formValues?.isEdit}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="add-row-btn-container">
              <div
                className="global-single-input auto-complete"
                style={{
                  width: '76%',
                  fontSize: '0.9rem',
                  paddingLeft: '7.5px',
                  fontWeight: '600',
                  color: 'rgb(134, 152, 229)',
                  cursor: 'pointer',
                }}
                onClick={() => addRow('Stages')}
              >
                Add Line
              </div>
            </div>
          </div>
        )}

        {selectedButton === "By Products" && (
          <div className="global-product-table special-items-table">
            <table style={{ borderBottom: '2px solid #dee3f8' }}>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Quatity</th>
                  <th>Unit</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableDataByProducts.map((r, index) => (
                  <tr>
                    <td style={{ paddingTop: "0" }}>
                      <div className="product-table-auto-complete">
                        <Autocomplete
                          options={allNewProductList || []}
                          style={{ maxHeight: "100px" }}
                          getOptionLabel={(option) => option?.productName}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              <span>{option.productName}</span>
                            </li>
                          )}
                          disabled={formValues?.isEdit}
                          value={r?.product}
                          onChange={tableInputChange(index, "product", 3)}
                        />
                      </div>
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <input
                        type="number"

                        onChange={tableInputChange(index, "qty", 3)}
                        value={r.qty}

                        disabled={formValues?.isEdit}
                      />
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <div className="product-table-auto-complete">
                      <input
                        type="text"

                        // onChange={tableInputChange(index, "qty", 3)}
                        value={r.uom}

                        disabled={formValues?.isEdit}
                      />
                        {/* <Autocomplete
                          options={uomSubList?.filter(obj => obj.parentCatId === formValues?.product?.parentUomId) || []}
                          style={{ maxHeight: "100px" }}
                          getOptionLabel={(option) => option?.uomName}
                          disabled={formValues?.isEdit}
                          renderInput={(params) => (
                            <TextField
                              {...params}

                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              <span>{option.uomName}</span>
                            </li>
                          )}
                          value={r?.uom}
                          onChange={tableInputChange(index, "uom", 3)}
                        /> */}
                      </div>
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <IconButton onClick={() => deleteRow('By Products', index)}
                        disabled={formValues?.isEdit}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="add-row-btn-container">
              <div
                className="global-single-input auto-complete"
                style={{
                  width: '76%',
                  fontSize: '0.9rem',
                  paddingLeft: '7.5px',
                  fontWeight: '600',
                  color: 'rgb(134, 152, 229)',
                  cursor: 'pointer',
                }}
                onClick={() => addRow('By Products')}
              >
                Add Line
              </div>
            </div>
          </div>
        )}


      </div>
    </div>
  )
}

export default ManufacturingOrder