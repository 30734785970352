
import { useState, useEffect } from "react";

const useTabWidth = (totalTabs) => {
  const [tabWidth, setTabWidth] = useState("100%");

  useEffect(() => {
    if (totalTabs === 1) {
      setTabWidth("100%");
    } else if (totalTabs === 2) {
      setTabWidth("50%");
    } else if (totalTabs === 3) {
      setTabWidth("33%");
    } else {
      setTabWidth("25%");
    }
  }, [totalTabs]);

  return tabWidth;
};

export default useTabWidth;
