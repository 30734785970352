import { Autocomplete, IconButton, Skeleton, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { listShiftSummaryReportAPI } from './ReportsAPI/posShiftSummaryReportAPI'
import { useSelector } from 'react-redux'
import { convertDateFormat, today } from '../../../../Js/Date'
import { viewAllAllowedBranchesAPICall, viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI'
import "./posBillingReports.css"
import { findSumOfObjkeys } from '../../../../Js/generalFunctions'
import Template from "./PosSummaryReportPrint"
import ExcelJS from "exceljs"

const PosShiftSummaryReport = () => {
    let paymentsPrint = useRef();
const {tableLoading}=useSelector((state)=>state.mainDashBoardSlice)
const {reportsList}=useSelector((state)=>state.posShiftSummaryReportSlice)
const userInfo = useSelector((state) => state.loginResponseSlice.value);
const userRole=useSelector((state)=>state.userRoleSlice.value)

const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);

const decimalPosition=localStorage.getItem("decimalPosition")
const userDefaultBranch={
    _id:userInfo?.branchPk,
    branchName:userInfo?.branchName
}
const formValuesInitial={
    branch:userRole==="user"?userDefaultBranch :null,
    fromDate:today,
    toDate:today
}
const [formValues,setFormValues]=useState(formValuesInitial)

const [grandTotals,setGrandTotals]=useState({})
const handleInputChange=(key)=>(e,newValue)=>{
    setFormValues({...formValues,[key]:newValue===undefined?e.target.value:newValue})
}

const submitButtonClick=()=>{
    let body={
        branchId:formValues.branch?._id || null,
        startDate:formValues?.fromDate===""?undefined:`${formValues?.fromDate} 00:00:00`,
        endDate:formValues?.toDate===""?undefined:`${formValues?.toDate} 23:59:59`
    }
    listShiftSummaryReportAPI(body)
}
const handleDownload = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Shift Summary Report');
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(['Shift Summary Report']);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells('A1:G1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };

    sheet.addRow(['']);

    reportsList?.forEach((list) => {
        // Add shift details
          // Add shift details
          const shiftIdRow = sheet.addRow([`${list?.shift?.shiftId || "-NIL-"}`]);
          shiftIdRow.font = { size: 15, bold: true };
          sheet.mergeCells(`A${sheet.lastRow._number}:G${sheet.lastRow._number}`);
          const shiftIdRowFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF8F8F8' } };
          shiftIdRow.eachCell((cell) => {
              cell.fill = shiftIdRowFill;
          });
  
          const startByRow = sheet.addRow([`Start by: ${list?.shift?.startedBy || ''}`, '', '', `End by: ${list?.shift?.endedBy || ''}`]);
          startByRow.font = { size: 12, bold: false };
          startByRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
          startByRow.getCell(4).alignment = { vertical: 'middle', horizontal: 'left' };
          sheet.mergeCells(`A${sheet.lastRow._number}:C${sheet.lastRow._number}`);
          sheet.mergeCells(`D${sheet.lastRow._number}:G${sheet.lastRow._number}`);
          const startByRowFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF8F8F8' } };
          startByRow.eachCell((cell) => {
              cell.fill = startByRowFill;
          });
  
          const startDateRow = sheet.addRow([`Start Date: ${list?.shift?.startDate || ''}`, '', '', `End Date: ${list?.shift?.endDate || ''}`]);
          startDateRow.font = { size: 12, bold: false };
          startDateRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' };
          startDateRow.getCell(4).alignment = { vertical: 'middle', horizontal: 'left' };
          sheet.mergeCells(`A${sheet.lastRow._number}:C${sheet.lastRow._number}`);
          sheet.mergeCells(`D${sheet.lastRow._number}:G${sheet.lastRow._number}`);
          const startDateRowFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF8F8F8' } };
          startDateRow.eachCell((cell) => {
              cell.fill = startDateRowFill;
          });

        // Add a blank row for separation
        sheet.addRow([]);

        // Add table headers
        const headerRow = sheet.addRow(['Order Date', 'Order Ref', 'Customer', 'Mobile', 'Order Amount', "Paid Amount", 'Credit']);
        headerRow.font = { size: 12, bold: true };
        const headerFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFedf2f9' } };
        headerRow.eachCell((cell) => {
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
            cell.fill = headerFill;
        });

        sheet.columns = [
            { header: "Order Date", key: "orderDate", width: 25 },
            { header: "Order Ref", key: "name", width: 25 },
            { header: "Customer", key: "customerName", width: 25 },
            { header: "Mobile", key: "custMobile", width: 25 },
            { header: "Order Amount", key: "amtTotal", width: 25 },
            { header: "Paid Amount", key: "paidAmt", width: 25 },
            { header: "Shift Summary Report", key: "amountDue", width: 25 },
        ];

        // Add table data
        list?.orders?.forEach((order, index) => {
            const row = sheet.addRow({
                orderDate: convertDateFormat(order?.orderDate),
                name: order?.name,
                customerName: order?.customerName,
                custMobile: order?.custMobile,
                amtTotal: order?.amtTotal?.toFixed(decimalPosition),
                paidAmt: order?.paidAmt?.toFixed(decimalPosition),
                amountDue: order?.amountDue?.toFixed(decimalPosition)
            });

            if (index % 2 === 1) {
                const alternateRowFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF8F8F8' } };
                row.eachCell((cell) => {
                    cell.fill = alternateRowFill;
                });
            }

            row.eachCell((cell, colNumber) => {
                if (colNumber >= 4) {
                    cell.alignment = { horizontal: "right" };
                } else {
                    cell.alignment = { horizontal: "center" };
                }
            });
        });

        const totalRow = sheet.addRow([
            '',
            '',
            '',
            'TOTAL',
            list?.totalAmtTotal?.toFixed(decimalPosition),
            list?.totalPaidAmt?.toFixed(decimalPosition),
            list?.totalDueAmount?.toFixed(decimalPosition)
        ]);
        totalRow.font = { size: 12, bold: true };

        const totalRowFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFDFD9D9' } };
        totalRow.eachCell((cell) => {
            cell.fill = totalRowFill;
            cell.alignment = { vertical: 'middle', horizontal: 'right' };
        });

        // Add a blank row for separation
        sheet.addRow([]);
    });

    // Add grand total
    const grandTotalRow = sheet.addRow([
        '',
        '',
        '',
        'GRAND TOTAL',
        Number(grandTotals?.totalAmtTotal)?.toFixed(decimalPosition),
        Number(grandTotals?.totalPaidAmt)?.toFixed(decimalPosition),
        Number(grandTotals?.totalDueAmount)?.toFixed(decimalPosition)
    ]);
    grandTotalRow.font = { size: 12, bold: true };
    const grandTotalFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFb7b6b6' } };
    grandTotalRow.eachCell((cell) => {
        cell.alignment = { vertical: 'middle', horizontal: 'right' };
        cell.fill = grandTotalFill;
    });

    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'CashCardSummary.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
};

// initial api calls
useEffect(()=>{
    viewAllAllowedBranchesAPICall({employeeId: userInfo?.data?._id }) 
    submitButtonClick()
},[])

useEffect(()=>{
if(reportsList.length!==0){
    let sum=findSumOfObjkeys(reportsList,"totalAmtTotal","totalPaidAmt","totalDueAmount")
    setGrandTotals(sum)
}else{
    setGrandTotals({})
}
},[reportsList])
    return (
        <div className="global-page-parent-container" style={{position:"relative"}}>
            <div className="global-white-bg-container Header" style={{ display: "flex", justifyContent: "space-between", padding: "0px 30px 0 25px", alignItems: "center" }}>
                <p>Shift Summary Report</p>
                <div>

                    <ReactToPrint
                        trigger={() => (
                            <IconButton className="printer-div" style={{ padding: "1px" }}>
                                <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
                           
                            </IconButton>
                        )}
                        content={() => paymentsPrint}
                    />

                    <Tooltip title="Download">
                        <IconButton onClick={handleDownload}>
                            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                        </IconButton>
                    </Tooltip>
                </div>

            </div>

            <div className="global-white-bg-container " style={{ display: "flex", alignItems: "center" }}>
                <div className="new-global-single-input auto-complete-new">
                    <Autocomplete
                        options={userRole === "admin" ? allBranchesList : allowedBranchList || []}
                        getOptionLabel={(option) => option?.branchName}
                        renderInput={(params) => (
                            <TextField {...params} label="Branch" focused variant='outlined' />
                        )}
                        value={formValues?.branch}
                        onChange={handleInputChange("branch")}
                    />
                </div>


                <div className="new-global-single-input" style={{ width: '21%' }}>
                    <TextField
                        id="outlined-basic"
                        placeholder="From"
                        onChange={handleInputChange("fromDate")}
                        value={formValues?.fromDate}
                        variant="outlined"
                        type="date"
                        focused
                        label="From Date"
                    />
                </div>



                <div className="new-global-single-input" style={{ width: '21%' }}>
                    <TextField
                        id="outlined-basic"
                        placeholder="From"
                        onChange={handleInputChange("toDate")}
                        value={formValues?.toDate}
                        variant="outlined"
                        type="date"
                        focused
                        label="To Date"
                    />
                </div>

                <button
                onClick={submitButtonClick}
                className='btn btn-primary'>Submit</button>

            </div>

            <div className='global-white-bg-container shift-report-table-container'>

                {
                
                 tableLoading?
                 <div className="new-global-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Order Date</th>
                                <th>Order Ref</th>
                                <th>Customer</th>
                                <th>Mobile</th>
                                <th>Order Amount</th>
                                <th>Paid Amount</th>
                                <th>Bank</th>
                                <th>Cash</th>
                                <th>Credit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            [...Array(10)].map((_,i)=>(
                            <tr key={i}>
                                <td colspan={15}>
                                    <Skeleton variant="rectangular"
                                    animation="wave"
                                    width={"100%"}
                                    height={40} />
                                </td>
                            </tr>
                            ))    
                        }
                        </tbody>
                    </table>
                 </div>
                 :
                 reportsList?.length!==0?
                 reportsList?.map((list,i)=>(
                     <>
                         <div className="single-view-left-ctn" style={{ width: "60%", minWidth: "fit-content", maxWidth: "550px", padding: "16px", margin: "1%", marginBottom: "0", backgroundColor: "rgb(248 248 248)" }}>
                             <h3 style={{ margin: "0", cursor: "pointer", fontSize: "larger" }}>{list?.shift?.shiftId || "-NIL-"}</h3>
                             <div className="justify-space-between" style={{ display: "flex" }}>
                                 <div style={{ display: "flex", gap: "10px" }}>
                                     <div className="single-view-info-ctn-left flex-column">
                                         <p style={{ margin: "1px" }}>Start by</p>
                                         <p style={{ margin: "1px" }}>Start Date</p>
                                     </div>
                                     <div className="single-view-info-ctn-right flex-column">
                                         <p style={{ margin: "1px" }}> {list?.shift?.startedBy}</p>
                                         <p style={{ margin: "1px" }}>{list?.shift?.startDate}</p>
                                     </div>
                                 </div>
                                 <div style={{ display: "flex", gap: "10px" }}>
                                     <div className="single-view-info-ctn-left flex-column">
                                         <p style={{ margin: "1px" }}>End by</p>
                                         <p style={{ margin: "1px" }}>End Date</p>
                                     </div>
                                     <div className="single-view-info-ctn-right flex-column">
                                         <p style={{ margin: "1px" }}>{list?.shift?.endedBy}</p>
                                         <p style={{ margin: "1px" }}>{list?.shift?.endDate}</p>
                                     </div>
                                 </div>
                             </div>
                         </div>

                         <div className="new-global-table-container" style={{padding:"8px 8px 19px 8px"}} >
                             <table>
                                 <thead >
                                     <tr>
                                         <th>Order Date</th>
                                         <th>Order Reff</th>
                                         <th>Customer</th>
                                         <th>Mobile</th>
                                         <th>Order Amount</th>
                                         <th>Paid Amount</th>
                                         <th>Credit</th>

                                     </tr>
                                 </thead>
                                 <tbody>
                                     {list?.orders?.length!==0?
                                     list?.orders?.map((item, i) => (
                                         <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                                             <td style={{ border: "none", height: "29px" }}>{convertDateFormat(item?.orderDate)}</td>
                                             <td style={{ border: "none", height: "29px" }}>{item?.name}</td>
                                             <td style={{ border: "none", height: "29px" }}>{item?.customerName}</td>
                                             <td style={{ border: "none", height: "29px" }}>{item?.custMobile}</td>
                                             <td style={{ border: "none", height: "29px" }}>{item?.amtTotal?.toFixed(decimalPosition)}</td>
                                             <td style={{ border: "none", height: "29px" }}>{item?.paidAmt?.toFixed(decimalPosition)}</td>
                                             <td style={{ border: "none", height: "29px" }}>{item?.amountDue?.toFixed(decimalPosition)}</td>
                                         </tr>
                                     ))
                                    :
                                    <tr>
                                        <td colspan={7}>No Records found</td>
                                    </tr>
                                    }
                                 </tbody>
                                 <tfoot>
                                     <tr style={{ backgroundColor: "#dfd9d9" }}>
                                         <td colSpan={4} style={{ textAlignLast: "end" }}>TOTAL</td>
                                         <td style={{ textAlignLast: "center" }}>{list?.totalAmtTotal?.toFixed(decimalPosition)}</td>
                                         <td style={{ textAlignLast: "center" }}>{list?.totalPaidAmt?.toFixed(decimalPosition)}</td>
                                         <td style={{ textAlignLast: "center" }}>{list?.totalDueAmount?.toFixed(decimalPosition)}</td>
                                     </tr>
                                 </tfoot>
                             </table>
                         </div>
                     </>
                 ))   
                : 
                <div className="center-div">
                    No Records
                </div>
                }
            </div>

            {
            Object.keys(grandTotals).length!==0 &&    
            <div className="new-global-table-container report-totalbar" >
                <table>
                    <thead style={{display:"none"}}>
                        <tr>
                            <th>Order Date</th>
                            <th>Order Reff</th>
                            <th>Customer</th>
                            <th>Mobile</th>
                            <th>Order Amount</th>
                            <th>Paid Amount</th>
                            <th>Credit</th>

                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>GRAND TOTAL</td>
                            <td>{Number(grandTotals?.totalAmtTotal)?.toFixed(decimalPosition)}</td>
                            <td>{Number(grandTotals?.totalPaidAmt)?.toFixed(decimalPosition)}</td>
                            <td>{Number(grandTotals?.totalDueAmount)?.toFixed(decimalPosition)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>}
            <div style={{display:"none"}}>
  <div ref={(el) => (paymentsPrint = el)}>
    <Template formValues={formValues} grandTotals={grandTotals}/>
  </div>
</div>
        </div>
    )
}

export default PosShiftSummaryReport