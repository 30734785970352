import React from 'react';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import { Tooltip } from '@mui/material';

const DownloadButton = (props) => {

  const {style}=props;
  const handleDownload = () => {
    const url = `${process.env.PUBLIC_URL}/PrintSetup/printService.zip`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'PrintService');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <Tooltip title="Download Print Services">
          <Button
              variant="text"
              color="primary"
              onClick={handleDownload}
              style={style}
          >
              <DownloadIcon style={{ margin: "0" }} />
          </Button>
    </Tooltip>
  );
};

export default DownloadButton;
