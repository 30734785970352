import React, { useEffect, useState } from "react";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";

const KotPrint = ({
  billNo,
  orderType,
  cashier,
  waiter,
  orderDate,
  orderData,
  tokenNumber,
  qrCodeData,
  barCodeData,
}) => {
  const decimalPosition = localStorage.getItem("decimalPosition");
  const [productInfo, setProductInfo] = useState({
    totalItem: null,
    totalQty: null,
  });
  useEffect(() => {
    let totalItem = 0;
    let totalQty = 0;
    for (let i = 0; i < orderData?.length; i++) {
      totalItem += 1;
      totalQty += parseInt(orderData[i].qty);
    }
    setProductInfo({
      totalItem: totalItem,
      totalQty: totalQty,
    });
  }, [orderData]);

  return (
    <div className="pos-receipt-template-container">
      <div className="pos-receipt-template-body">
        {/* <!--Bill-Details-> */}
        <div className="template-invoice-container">
          <div className="template-invoice-details">
            <span className="temp-bol-txt">BILL NO</span>:
            <span className="temp-bol-txt">{billNo || "---"}</span>
          </div>
          <div className="template-invoice-details temp-text-end">
            {/* <span className="temp-bol-txt ">{"orderType"}</span> */}
            <span className="temp-bol-txt ">{orderType || "---"}</span>
            {/* <span className="temp-bol-txt ">تناول الطعام في</span> */}
          </div>

          {/* {
            <div className="template-invoice-details">
              <span className="temp-bol-txt ">CASHIER :</span>
              <span className="temp-bol-txt ">{cashier || "---"}</span>
            </div>
          } */}
          <div></div>
          <div className="template-invoice-details temp-text-end">
            {/* <span>DATE & TIME :</span> */}
            <span style={{textWrap:"nowrap"}}>
              {orderDate?.split(" ")[0] || "---"}
              &nbsp;
              {orderDate?.split(" ")[1] || "---"}
            </span>
          </div>

          <div></div>

          <div></div>
          <div className="template-invoice-details">
            <span>WAITER :</span>
            <span>{waiter || "---"}</span>
          </div>
        </div>
        {/* <hr className="template-dashed-line" /> */}

        {/* <!--table--> */}
        <div className="template-table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <div className="temp-table-head">
                    <span style={{ textAlign: "start" }}>Item</span>
                    {/* <span>غرض</span> */}
                  </div>
                </th>
                <th>
                  <div className="temp-table-head">
                    <span style={{ textAlign: "end" }}>Quantity</span>
                    {/* <span>الكمية</span> */}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {orderData?.length > 0 ? (
                <>
                  {orderData?.map((res, i) => (
                    <>
                      <tr key={i}>
                        <td>
                          <div
                            className="table-item-center"
                            style={{ textAlign: "start" }}
                          >
                            <span className="temp-bol-txt">
                              {res?.productName}
                            </span>

                            <span className="temp-bol-txt">
                              {res?.nativeName}
                            </span>
                            {res?.customerNote && (
                              <span className="temp-bol-txt">
                                Note : {res?.customerNote}
                              </span>
                            )}
                          </div>
                        </td>
                        <td>
                          <div
                            className="table-item-center"
                            style={{ textAlign: "end" }}
                          >
                            <span>{res?.qty?.toFixed(decimalPosition)}</span>
                          </div>
                        </td>
                      </tr>
                      {res?.productList?.length > 0 && (
                        <tr>
                          {/* combo offer  */}
                          <ul className="kot-compo-offer-container">
                            {res?.productList?.map((res, i) => (
                              <li key={i}>
                                {res?.qty} X {res?.productName}
                              </li>
                            ))}
                          </ul>
                        </tr>
                      )}
                    </>
                  ))}
                  <tr>
                    <td>
                      <div className="sub-data-info-font">
                        <span>Item :</span>
                        <span className=" ">
                          &nbsp;{productInfo?.totalItem || "---"}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className=" sub-data-info">
                        <span>Qty :</span>
                        <span className="  ">
                          {productInfo?.totalQty || "---"}
                        </span>
                      </div>
                    </td>
                  </tr>
                </>
              ) : (
                <tr style={{ textAlign: "center" }}>
                  <td colSpan={2}>NO DATA</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <hr className="template-dotted-line" />
        {/* <!--Token--> */}

        <>
          <div className="template-token-container">
            <div>
              <span className="temp-token-name" style={{fontSize:"24px"}}>YOUR TOKEN NO</span>
              <span className="temp-token-no">{tokenNumber}</span>
              {/* <span className="temp-token-no">{receiptData?.kotInfo}</span> */}
            </div>
          </div>

          <hr className="template-dashed-line" />
        </>
      </div>
      <div className="pos-receipt-template-footer">
        <div className="template-footer-content">
          <div
            className="template-ft-qr-bar-code-container justify-center"
            style={{ gap: "1em", marginTop: "1em" }}
          >
            <div className="template-ft-qr">
              {/* <QRCode size={"8em"} 
            //   value={null} 
              viewBox={`0 0 256 256`} /> */}
            </div>
            <div className="template-ft-bar">
              {/* <Barcode
                format="CODE128"
                // value={null}
                width={2}
                fontSize={"5em"}
                height={60}
                margin={0}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KotPrint;
