import axios from "axios"
import { HEADERS } from "../../../../../API/UrlAndPaths"
import store from "../../../../../Redux/store"
import { setShiftSummaryReportList } from "../ReportsSlice/posShiftSummaryReportSlice"
import { setErrorSnack, setSnackMsg, setSuccessSnack } from "../../../Sales/Billing/Slice/billingScreenSlice"
import { setTableLoadingGlobal } from "../../../../../Redux/Dashboard/mainDashBoardSlice"

export const listShiftSummaryReportAPI=async(body)=>{
    store.dispatch(setTableLoadingGlobal(true))
    await axios.post("pos/shiftSummeryReport",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setTableLoadingGlobal(false))
                store.dispatch(setShiftSummaryReportList(res.data))
            }
        })
        .catch((err)=>{
            store.dispatch(setTableLoadingGlobal(false))
            store.dispatch(setShiftSummaryReportList([]))
            store.dispatch(setSnackMsg(err.response.status===500?"Internal server!!" :err.response.data))
            store.dispatch(setErrorSnack(true))
        })

}
