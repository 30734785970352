import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { ManufacturingcostAPI } from './FoodManagementNewAPI';
import { useNavigate } from 'react-router-dom';

const ManufacturingCostAnalysis = () => {
    let navigateTo = useNavigate();
    const singleViewId=localStorage.getItem("order_id")
    const decimalPosition = localStorage.getItem("decimalPosition");
  
  const listAnalysis= useSelector((state)=>state.foodManagement.OrdercostAnalysis)
  console.log(listAnalysis);
  
      const handleBack=()=>{
        localStorage.removeItem("recipe_id")
          navigateTo("/userdashboard/foodManagement/preperation")
  
      }
  
      useEffect(()=>{
        ManufacturingcostAPI({ manufacturingOrderId:singleViewId})
      },[])
  return (
    <div className="global-page-parent-container aggregator-main-ctn" >
    <div className="global-white-bg-container Header" style={{display:"flex"}}>
      <p style={{paddingInline:"10px",cursor:"pointer"}} onClick={()=>handleBack()}>Preparation</p>
      <p >{'>'}</p>
      <p style={{paddingInline:"10px"}}> Cost Analysis</p>
    </div>
    <div className="global-white-bg-container ">
    <p style={{ fontWeight: 700, fontSize: 'larger', margin: '2px',paddingInline:"27px" }}>Cost analysis</p>
       <p style={{paddingInline:"27px"}}>Raw materials cost</p>
        
       <div className="global-product-table special-items-table">
        <table>
        <thead>
                <tr >
                  <th>Product</th>
                  <th>Quatity</th>
                  <th>Unit Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
               
                  {listAnalysis?.components?.length !==0 ? listAnalysis?.components?.map((item,index)=>(
                <tr key={index} style={{borderBottom:"2px solid #dee3f8"}}>
                    <td>{item.productName}</td>
                    <td>{item.quantity}</td>
                    <td>{item.cost?.toFixed(decimalPosition)}</td>
                    <td>{item.total?.toFixed(decimalPosition)}</td>
                </tr>
                  ))
                 :
                  <tr>
                    <td colSpan={4}>No Data</td>
                  </tr>
                }
              </tbody>
        </table>
         <div style={{display:"flex",justifyContent:"end"}}>
            <div style={{width:"30%",backgroundColor:"#dee3f8",display:"flex",justifyContent:"space-between",paddingInline:"10px"}}>
                <p style={{margin:"0"}}>Total </p>
                <p style={{margin:"0",paddingRight:"40px"}}>{listAnalysis?.componentsTotalSum?.toFixed(decimalPosition)}</p>
                </div> 
            
            </div>
        </div>

        <p style={{paddingInline:"27px"}}>Expenses</p>
        
        <div className="global-product-table special-items-table">
         <table>
         <thead>
                 <tr>
                   <th>Name</th>
                   <th>Account</th>
                   <th>Cost</th>  
                 </tr>
               </thead>
               <tbody>
                {listAnalysis?.stages?.length!==0 ? listAnalysis?.stages?.map((item,index)=>(
                 <tr key={index} style={{borderBottom:"2px solid #dee3f8"}}>
                     <td>{item.name}</td>
                     <td>{item.accountName}</td>
                     <td>{item.total}</td>
                    
                 </tr>
                 )) :
                 <tr>
                    <td colSpan={3}> No Data</td></tr>}
               </tbody>
         </table>
          <div style={{display:"flex",justifyContent:"end"}}>
             <div style={{width:"30%",backgroundColor:"#dee3f8",display:"flex",justifyContent:"space-between",paddingInline:"10px"}}>
                 <p style={{margin:"0"}}>Total </p>
                 <p style={{margin:"0",paddingRight:"40px"}}>{listAnalysis?.stagesTotalSum}</p>
                 </div> 
             
             </div>
         </div>
        </div>
    </div>
  )
}

export default ManufacturingCostAnalysis