import { Autocomplete, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { convertDateFormat, getCurrentTime, today } from '../../../../../Js/Date'
import { useEffect } from 'react'
import { errorSnackMsg } from '../../../../Custom Hooks/SnackkBarUtilities'
import { aggregatorReportPaymentAPI } from './aggregatorReportAPI'
import { roundNumericFields } from '../../../../../Js/generalFunctions'

const AggregatorReportPayment = ({selectedBranch,submitFn}) => {

  const decimalPosition=localStorage.getItem('decimalPosition')
  const {paymentPopup}=useSelector((state) => state.posAggregatorReportSlice)
  const filteredList=paymentPopup?.list?.filter((obj)=>obj.status === 'Pending')

  const posSettings=useSelector((state)=>state.posSettingSlice.apiResList)
  const paymentTypesForBranch=useSelector((state)=>state.newBillingSlice.paymentTypeList)

  const [tableData,setTableData] = useState([])
  const [paymentMethodTotals,setPaymentMethodTotals] = useState([])
  

  const paymentMethodOnchange = (index, key) => (e, newValue) => {
    const updatedList = tableData.map((item, i) => {
        if (i === index) {
            const valueToUpdate = newValue !== undefined ? newValue : e.target.value;
            if (key === "balance") {
                const maxAllowedBalance = item.orderAmount - item.paidAmount;
                if (Number(valueToUpdate) > maxAllowedBalance) {
                    errorSnackMsg(`Balance cannot exceed ${maxAllowedBalance}`)
                    return item;
                }
            }

            return { ...item, [key]: valueToUpdate };
        }
        return item;
    });

    setTableData(updatedList);
};

  const roundBalance=()=>{
    const updatedArray = roundNumericFields(tableData,["balance"]);
    setTableData(updatedArray)
  }
  const proceedOnClick=()=>{

    let paymentArray=tableData.map((item)=>{
        if(item.paymentMethod){
            return {
                orderId: item._id,
                type: item.paymentMethod?.type,
                journalId: item.paymentMethod?.journalId,
                paidAmt: Number(item.balance),
                cardId: null
            }
        }
    })
    let payload={
        branchId:selectedBranch?._id,
        paymentDate: `${today} ${getCurrentTime()}`,
        paymentArray:paymentArray?.filter((obj)=>obj)
    }
    if(selectedBranch==null){
        errorSnackMsg("Please select a branch to receive payments")
    }
    else if(payload?.paymentArray.length===0){
        errorSnackMsg("Payment method not selected for any orders!!")
    }else{
        aggregatorReportPaymentAPI(payload,submitFn)
    }
  }

  useEffect(()=>{
    if(paymentTypesForBranch&&paymentTypesForBranch.length>0){
        let modifiedAraay=filteredList?.map((item)=>({...item,paymentMethod:paymentTypesForBranch[0]}))
        setTableData(modifiedAraay)
    }
  },[])

  useEffect(()=>{
    if(tableData.length!==0){
        const balanceSumsObj = tableData.reduce((acc, order) => {
            const journalId = order?.paymentMethod?.journalId;
            const balance = Number(order?.balance);
            const journalName = order?.paymentMethod?.journalName;
        
            // If the journalId exists, add the balance to the amount
            if (acc[journalId]) {
                acc[journalId].amount += balance;
            } else {
                // If it's the first time encountering this journalId, create an entry
                acc[journalId] = { journalId, journalName, amount: balance };
            }
        
            return acc;
        }, {});
        
        // Convert the accumulated object into an array of objects
        const balanceSumsArray = Object.values(balanceSumsObj);
        setPaymentMethodTotals(balanceSumsArray)
    }else{

    }
  },[tableData])

  return (
    <div className="flex-column">
        <div className="new-global-table-container aggregator-report-payment-table" style={{ paddingTop: "0 42px" }}>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Bill No</th>
                        <th>Order Amount</th>
                        <th>Paid Amount</th>
                        <th>Balance</th>
                        <th>Payment Method</th>
                        <th>Payment Referrence</th>
                    </tr>
                </thead>

                <tbody>
                    
                {
                tableData?.map((item,i)=>(
                    <tr key={i}>
                        <td>{convertDateFormat(item.date)}</td>
                        <td>{item.orderRef}</td>
                        <td>{Number(item.orderAmount)?.toFixed(decimalPosition)}</td>
                        <td>{Number(item.paidAmount)?.toFixed(decimalPosition)}</td>
                        <td>
                        {
                                <input type="number" 
                                    className='border-bottom-input'
                                    value={item?.balance || ""}
                                    onChange={paymentMethodOnchange(i,"balance")}
                                    placeholder='Balance'
                                    onBlur={roundBalance}
                                    onFocus={(e)=>e.target.select()}
                                    style={{textAlign: 'center',color:"red",borderColor:"red"}}
                                />
                            }
                        </td>
                        <td>
                            <div className="product-table-auto-complete">
                                <Autocomplete
                                    options={paymentTypesForBranch || []}
                                    getOptionLabel={(option) => option?.journalName}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                    value={item?.paymentMethod}
                                    onChange={paymentMethodOnchange(i,"paymentMethod")}
                                    />
                            </div>
                        </td>
                        <td>
                            {
                                <input type="text" 
                                    className='border-bottom-input'
                                    value={item?.paymentRefference || ""}
                                    onChange={paymentMethodOnchange(i,"paymentRefference")}
                                    placeholder='Refference'
                                />
                            }
                        </td>
                    </tr>
                    ))
                    }
                        
                </tbody>
            </table>
        </div>

        <div className="flex-end aggregator-reeport-payment-bottom">
            {
            paymentMethodTotals?.map((item,i)=>(
                item?.journalId &&
                <div key={i} className="global-single-input">
                    <p style={{textWrap:"nowrap",margin:"0"}}>{item?.journalName || "Balance"}</p>
                    <input type="text" readOnly value={item.amount?.toFixed(decimalPosition)}/>
                </div>
            ))
            }
        </div>
        <button
        onClick={proceedOnClick}
        className="btn proceed-btn">Proceed</button>
    </div>
  )
}

export default AggregatorReportPayment