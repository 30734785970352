import React from "react";
import "./flowChartStyle.css";
import IconSvg from "../../svg/IconSvg";
import {
  chartTypes,
  flowChartElement,
  navigationPath,
} from "../../../utils/dashboardEnum";
import { useNavigate } from "react-router-dom";

const FlowChart = ({ chartType }) => {
  const navigate = useNavigate();
  const onHandleClick = (path) => {
    console.log(path);
    navigate(`/userdashboard/${path}`);
  };

  return (
    <div className="home-dash-temp-cntr">
      <div className="dash-flow-chart-container">
        <div className="main-flow-chart-container">
          <div className="flowchart">
            <div className="purchase-main-container">
              <div
                className="node line-bottom flw-style-1"
                data-content="Purchase"
              >
                <span className="flw-main-head">
                  {chartType === chartTypes.purchase ? "Purchase" : "sales"}
                </span>
              </div>
            </div>
            <div className="divider-line"></div>
            <div className="arrow-split">
              <div className="split-1">
                <div className="arrow bottom-arrow pr-arrow-left"></div>
              </div>
              <div className="split-1">
                <div className="arrow bottom-arrow pr-arrow-right"></div>
              </div>
            </div>

            <div className="flow-row-2">
              <div>
                <div
                  className="node purchase-wpo line-bottom flw-style-2"
                  style={{
                    "--line-color":
                      chartType === chartTypes.purchase ? "#87bd47" : "#0b4546",
                    "--arrow-color":
                      chartType === chartTypes.purchase ? "#87bd47" : "#0b4546",
                    "--border-color":
                      chartType === chartTypes.purchase ? "#87bd47" : "#0b4546",
                  }}
                  data-content="Purchase w/PO"
                  onClick={() =>
                    onHandleClick(
                      chartType === chartTypes.purchase
                        ? navigationPath.purchaseWpo
                        : navigationPath.saleWso
                    )
                  }
                >
                  <div>
                    <IconSvg
                      svgName={
                        chartType === chartTypes.purchase
                          ? flowChartElement.purchaseWpo
                          : flowChartElement.swso
                      }
                    />
                  </div>
                  <span>
                    {" "}
                    {chartType === chartTypes.purchase
                      ? "Purchase w/PO"
                      : "sales w/so"}
                  </span>

                  <div className="arrow bottom-arrow pr-wpo-arrow"></div>
                </div>
              </div>
              <div>
                <div
                  className="node line-bottom flw-style-3"
                  data-content="RFQ"
                  onClick={() =>
                    onHandleClick(
                      chartType === chartTypes.purchase
                        ? navigationPath.rfq
                        : navigationPath.quotation
                    )
                  }
                  style={{
                    "--line-color":
                      chartType === chartTypes.purchase ? "#322eb0" : "#955b35",
                    "--arrow-color":
                      chartType === chartTypes.purchase ? "#322eb0" : "#955b35",
                    "--border-color":
                      chartType === chartTypes.purchase ? "#322eb0" : "#955b35",
                  }}
                >
                  <div>
                    <IconSvg
                      svgName={
                        chartType === chartTypes.purchase
                          ? flowChartElement.rfq
                          : flowChartElement.quotation
                      }
                    />
                  </div>
                  <span>
                    {chartType === chartTypes.purchase ? "RFQ" : "QUOTATION"}
                  </span>
                  <div className="arrow bottom-arrow pr-wpo-arrow"></div>
                </div>
              </div>
            </div>
            <div className="flow-row">
              <div>
                <div>
                  <div
                    className="node payment flw-style-4 "
                    data-content="Payment"
                    style={{ "--border-color": "#c8312f" }}
                    onClick={() =>
                      onHandleClick(
                        chartType === chartTypes.purchase
                          ? navigationPath.payment
                          : navigationPath.receipt
                      )
                    }
                  >
                    <div>
                      <IconSvg
                        svgName={
                          chartType === chartTypes.purchase
                            ? flowChartElement.payment
                            : flowChartElement.receipt
                        }
                      />
                    </div>
                    <span>
                      {chartType === chartTypes.purchase
                        ? "Payment "
                        : "RECEIPT"}
                    </span>
                    {/* <div className="arrow left-arrow arr-payment"></div> */}
                  </div>
                </div>{" "}
              </div>
              <div className="flow-row-right">
                {" "}
                <div>
                  <div
                    className="node bill line-left flw-style-3_3"
                    data-content="BILL"
                    onClick={() =>
                      onHandleClick(
                        chartType === chartTypes.purchase
                          ? navigationPath.bill
                          : navigationPath.invoice
                      )
                    }
                    style={{
                      "--line-color": "#ff8800",
                      "--arrow-color": "#ff8800",
                      "--border-color": "#ff8800",
                    }}
                  >
                    <div>
                      <IconSvg svgName={flowChartElement.bill} />
                    </div>
                    <span>
                      {chartType === chartTypes.purchase ? "BILL " : "INVOICE"}{" "}
                    </span>
                    <div className="arrow left-arrow arr-end-left"></div>
                  </div>
                </div>
                <div>
                  <div
                    className="node grn line-left flw-style-3_2"
                    data-content="GRN"
                    onClick={() =>
                      onHandleClick(
                        chartType === chartTypes.purchase
                          ? navigationPath.grn
                          : navigationPath.delivery
                      )
                    }
                    style={{
                      "--line-color":
                        chartType === chartTypes.purchase
                          ? "#1c893d"
                          : "#0e055d",
                      "--arrow-color":
                        chartType === chartTypes.purchase
                          ? "#1c893d"
                          : "#0e055d",
                      "--border-color":
                        chartType === chartTypes.purchase
                          ? "#1c893d"
                          : "#0e055d",
                    }}
                  >
                    <div>
                      <IconSvg
                        svgName={
                          chartType === chartTypes.purchase
                            ? flowChartElement.grn
                            : flowChartElement.delivery
                        }
                      />
                    </div>
                    <span>
                      {chartType === chartTypes.purchase ? "GRN " : "DELIVERY"}
                    </span>
                    <div className="arrow left-arrow arr-end-left"></div>
                  </div>
                </div>
                <div>
                  <div
                    className="node purchase-order line-left flw-style-3_1"
                    data-content="Purchase Order"
                    onClick={() =>
                      onHandleClick(
                        chartType === chartTypes.purchase
                          ? navigationPath.purchaseOrder
                          : navigationPath.salesOrder
                      )
                    }
                    style={{
                      "--line-color":
                        chartType === chartTypes.purchase
                          ? "#9b3e79"
                          : "#87c014",
                      "--arrow-color":
                        chartType === chartTypes.purchase
                          ? "#9b3e79"
                          : "#87c014",
                      "--border-color":
                        chartType === chartTypes.purchase
                          ? "#9b3e79"
                          : "#87c014",
                    }}
                  >
                    <div>
                      <IconSvg
                        svgName={
                          chartType === chartTypes.purchase
                            ? flowChartElement.purchaseOrder
                            : flowChartElement.salesOrder
                        }
                      />
                    </div>
                    <span>
                      {chartType === chartTypes.purchase
                        ? "PURCHASE ORDER "
                        : "SALES ORDER"}
                    </span>
                    <div className="arrow left-arrow arr-end-left"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlowChart;
