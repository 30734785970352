import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Snackbar } from "@mui/material";

const AlertMessage = ({ status, alertMessage,setApiAlertMessage,duration }) => {
  const [showAlert, setShowAlert] = useState(false);

  const handleCloseAlert = () => {
    setShowAlert(false);
    setApiAlertMessage({})
  };
  useEffect(() => {
   if (status) {
    setShowAlert(true)
   }
  }, [status])
  

  return (
    <div>
      <Snackbar
        open={showAlert}
        autoHideDuration={duration||5000}
        onClose={handleCloseAlert}
      >
        <Alert severity={status} onClose={handleCloseAlert}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AlertMessage;
