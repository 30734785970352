import axios from "axios";
import store from "../../../../../Redux/store";
import { HEADERS, HEADER_FORM_DATA } from "../../../../../API/UrlAndPaths";
import {
  generate_vendor_payment_name,
  get_vendor_payment_api_res_list,
  get_vendor_payment_row_data_list,
  set_vendor_bills,
  vendor_payment_single_view,
} from "../Slice/vendorPaymentSlice";
import { getCurrentTime, today } from "../../../../../Js/Date";

// list vendor bill apicall
export const viewVendorPaymentListAPICall = async (body, setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true);
  await axios
    .post(`account/viewVendorPaymentDraft`, body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_vendor_payment_api_res_list({ vendorPaymentResData: res.data })
        );
        setIsLoading !== undefined && setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(
        get_vendor_payment_api_res_list({ vendorPaymentResData: undefined })
      );
      setIsLoading !== undefined && setIsLoading(false);
    });
};
export const VendorPaymentFilterAPICall = (body, setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true);
  axios
    .post("account/searchVendorPayment", body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_vendor_payment_api_res_list({ vendorPaymentResData: res.data })
        );
        setIsLoading !== undefined && setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(
        get_vendor_payment_api_res_list({ vendorPaymentResData: undefined })
      );
      setIsLoading !== undefined && setIsLoading(false);
    });
};
// // generate vendor bill name
// export const generateVendorPaymentNameAPICall = async (body) => {
//   await axios
//     .post(`account/generateVendorPaymentDraftNo`, body, HEADERS)
//     .then((res) => {
//       if (res.status === 200) {
//         store.dispatch(
//           generate_vendor_payment_name({ vendorPaymentName: res.data })
//         );
//       }
//     })
//     .catch((err) => {
//       store.dispatch(
//         generate_vendor_payment_name({ vendorPaymentName: undefined })
//       );
//     });
// };
// add vendor bill api call

export const draftVendorPaymentAPICall = async (
  body,
  postFn,
  setState,
  state,
  setEditDataId
) => {
  await axios
    .post("account/createVendorPaymentDraft", body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setState({
          ...state,
          message: "vendor payment drafted",
          success: true,
        });
        postFn();
        setEditDataId(res.data?._id);

        vendorPaymentSingleViewApi({ _id: res.data._id });
        localStorage.setItem("singleViewById", res.data._id);
      }
    })
    .catch((err) => {
      setState({ ...state, message: "Something went wrong !!", error: true });
    });
};
// edit vendor call

export const editVendorPaymentAPICall = async (
  body,
  postFn,
  setState,
  state
) => {
  await axios
    .put("account/editVendorPaymentDraft", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({ ...state, message: "vendor payment edited", success: true });
        postFn();
        vendorPaymentSingleViewApi({ _id: res.data._id });
      }
    })
    .catch((err) => {
      setState({ ...state, message: "Something went wrong !!", error: true });
    });
};
// post vendorPayment

export const postVendorPaymentAPICall = async (
  body,
  postFn,
  setState,
  state
) => {
  await axios
    .post("account/postVendorPayment", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({
          ...state,
          message: "vendor payment posted !!",
          success: true,
        });
        postFn !== undefined && postFn();
        vendorPaymentSingleViewApi({
          _id: res.data._id,
          postDate: `${today} ${getCurrentTime()}`,
        });
      }
    })
    .catch((err) => {
      setState({ ...state, message: "Something went wrong !!", error: true });
    });
};

// vendor payment single view api
export const vendorPaymentSingleViewApi = async (body) => {
  await axios
    .post("account/vendorPaymentById", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        // console.log(res.data);
        store.dispatch(
          get_vendor_payment_row_data_list({
            vendorPaymentSingleRowData: res.data,
          })
        );
      }
    })
    .catch((err) => {
      // setState({...state,message:"Something went wrong !!",error:true})
    });
};

export const showVendorBillApi = async (body) => {
  try {
    let response = await axios.post(
      "account/listVendorBillByVendorId",
      body,
      HEADERS
    );
    if (response.status === 200) {
      store.dispatch(set_vendor_bills(response.data));
    }
  } catch (error) {
    console.error(error.message);
  }
};

//update payment

export const updateVendorPaymentAPI = async (
  body,
  setImportPopup,
  setSnackBarStates
) => {
  try {
    let response = await axios.post(
      "account/updateVendorPayment",
      body,
      HEADERS
    );
    if (response.status === 200) {
      vendorPaymentSingleViewApi({ _id: response.data._id });
      setImportPopup(false);
      setSnackBarStates({
        success: true,
        message: " vendor payment has been updated successfully.",
        alert: false,
      });
    }
  } catch (error) {
    setSnackBarStates({ error: true, message: error.message, alert: false });
  }
};

//return payment
export const returnVendorPaymentAPI = async (
  body,
  setAlertPopup,
  setSnackBarStates
) => {
  try {
    let response = await axios.post(
      "account/returnVendorPayment",
      body,
      HEADERS
    );
    if (response.status === 200) {
      vendorPaymentSingleViewApi({ _id: response.data._id });
      setSnackBarStates({
        success: true,
        message: " vendor payment has been return successfully.",
        alert: false,
      });
      setAlertPopup(false);
    }
  } catch (error) {
    setSnackBarStates({ error: true, message: error.message, alert: false });
  }
};

export const VendorPaymentSingleViewAPI = async (body) => {
  await axios
    .post(`account/vendorPaymentById`, body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          vendor_payment_single_view({ paymentSingleView: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        vendor_payment_single_view({ paymentSingleView: undefined })
      );
    });
};

// generate vendor bill name
export const generateVendorPaymentNameAPICall = async (body) => {
  try {
    const response = await axios.post(
      `account/generateVendorPaymentDraftNo`,
      body,
      HEADERS
    );
    if (response.status === 200) {
      store.dispatch(
        generate_vendor_payment_name({ vendorPaymentName: response.data })
      );
    }
  } catch (error) {
    store.dispatch(
      generate_vendor_payment_name({ vendorPaymentName: undefined })
    );
  }
};
