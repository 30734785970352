import {
  IconButton,
  Skeleton,
  Tooltip
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ProductMasterFilterAPICall,
  deleteProductMasterAPICall,
} from "../inventoryProductsAPI";
import { get_inventory_product_master_row_list } from "../inventoryProductsSlice";
import store from "../../../../../Redux/store";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import BrokenImageIcon from "../../../../../Assets/Images/brokenImg.png";
import { categoryListAPICall } from "../../Configuration/Category/CategoryAPI";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs"
import { convertDateFormat } from "../../../../../Js/Date";
import TableListActionButtons from "../../../../Single Components/ActionButtons/TableListActionButtons";

export const ProductMasterUpdated = () => {
  let navigateTo = useNavigate();
  const tableData = useSelector(
    (state) => state.inventoryProductSlice.apiResList
  );
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = tableData?.pages;
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [filteredData, setFilteredData] = useState({});
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false,
  });

  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDelete = (id) => {
    deleteProductMasterAPICall({ id }, setSnackBarStates);
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  
  const singleViewFn = (data) => {
    store.dispatch( setFilterActive(window.location.pathname))
    navigateTo("/userdashboard/inventory/products/productmaster/create");
    localStorage.setItem("singleViewById", data?._id);
  };
  const formatFilterInfo = (filters) => {
    const parts = [];
  
    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    }
   
    if (filters?.category) {
      parts.push(`Category: ${filters?.category?.categoryName}`);
    }
    if (filters?.posCategory) {
      parts.push(`POS Category: ${filters?.posCategory?.name}`);
    }
    return parts; 
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Product Master");
    sheet.properties.defaultRowHeight = 20;
  
    // Add header section
    const titleRow = sheet.addRow(["Product Master"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells('A1:E1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];
  
    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:E${index + 2}`);
        row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      });
    }
  
    // Add an empty row between header and table
    sheet.addRow([]);
  
    // Header row styling
    const headerRow = sheet.addRow(["Product Id", "Product Name","Product Image","UOM"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };
  
    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    })
  
    sheet.columns = [
      { header: "Product Id", key: "prodId", width: 15},
      { header: "Product Name", key: "name", width: 20 },
      { header: "Product Image", key: "imageUrl", width: 25 },
      { header: "Product Master", key: "UOMNAME", width: 15 },
      // { header: "Delete", key: "locationName", width: 25 },
  
    ];
  
    tableData?.list?.forEach((item) => {
      const row = sheet.addRow({
        prodId: item?.prodId,
        name: item?.name,
        imageUrl: item?.imageUrl,
        UOMNAME: item?.UOMNAME,   
      });
  
      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };
      
      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
        //   top: { style: "thin", color: { argb: "FF000000" } },
        //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
        //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) { // Right align all columns except the first
            cell.alignment = { horizontal: 'center' };
          }
         
      });
    });
  
    // Add row for totals
    // const totalRow = sheet.addRow([
    //   "Total",
    //   "",
    //   `${symbol} ${SingleListData?.sumOfCurrent.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf30?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf60?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf90?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf120?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOfAbove120?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOfTotal?.toFixed(2)}`,
    // ]);
  
    // totalRow.eachCell((cell,colNumber) => {
    //   cell.font = { bold: true, color: { argb: "FF000000" } };
    //   cell.fill = headerFill;
    //   cell.border = {
    //     top: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     left: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     right: { style: "thin", color: { argb: "FFFFFFFF" } },
    //   };
    //   if (colNumber > 1) { // Right align all columns except the first
    //     cell.alignment = { horizontal: 'right' };
    //   }
    // });
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Product Master.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    localStorage.removeItem("singleViewById");
    // viewInventoryProductMasterListAPICall({}, setIsLoading, setSnackBarStates, snackBarStates)
    categoryListAPICall();
    store.dispatch(
      get_inventory_product_master_row_list({
        inventoryProductMasterSingleData: undefined,
      })
    );
  }, []);

  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData && ProductMasterFilterAPICall(
        {
          branchList: FormDataInfo.branchList,
          mainCategory: FormDataInfo.category,
          posCategory: FormDataInfo.posCategory,
          search: FormDataInfo.search,
          index: currentPage - 1,
        },
        setIsLoading
      );
    }
   
  }, [FormDataInfo, currentPage]);
  return (
    <>
      <div className="global-page-parent-container updated-global-page-parent-container">
        <div className="justify-space-between list-create-head-bar">
          <h3>Product Master</h3>
          <div className="create-head-buttons-container">
            <TableListActionButtons downloadClick={downloadList} />
            <CategorySearchandFilter
              onData={handleDataFromChild}
              isDate={false}
              isBranch={false}
              isCategory={true}
              isposCategory={true}
              onBranchSelect={() => { }}
              onFilterChange={handleFilterChange}
            />
            <button
              className="btn btn-primary"
              onClick={() =>
                navigateTo(
                  "/userdashboard/inventory/products/productmaster/create"
                )
              }
            >
              Create
            </button>
          </div>
        </div>
          <div className="justify-space-between pagination-container">
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              setFormDataInfo={setFormDataInfo}
            />
          </div>
          <div className="global-table-container updated-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>Product Id</th>
                  <th>Product Name</th>
                  <th>Product Image</th>
                  <th>UOM</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={8}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) : tableData?.list !== undefined &&
                  tableData?.list?.length !== 0 ? (
                  tableData?.list
                    ?.map((r, i) => (
                      <tr key={i}>
                        <td onClick={() => singleViewFn(r)}>{r?.prodId}</td>
                        <td onClick={() => singleViewFn(r)}>{r?.name}</td>
                        <td onClick={() => singleViewFn(r)}>
                          <img
                            style={{ width: "40px", height: "40px" }}
                            src={
                              (r?.imageUrl !== null && r?.imageUrl[0]) ||
                              BrokenImageIcon
                            }
                            alt="no img"
                          />
                        </td>
                        <td onClick={() => singleViewFn(r)}>
                          {r?.UOMNAME || "---"}
                        </td>

                        <td>
                          <IconButton onClick={() => handleDelete(r?._id)}>
                            <i
                              class="bi bi-trash3 delete-icon"
                              style={{ fontSize: "15px" }}
                            >
                              {" "}
                            </i>
                          </IconButton>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={5}>No data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
       
        <ErrorSnackbar
          open={snackBarStates.error}
          handleClose={closeSnackbar}
          message={snackBarStates.message}
        />
        <SuccessSnackbar
          open={snackBarStates.success}
          handleClose={closeSnackbar}
          message={snackBarStates.message}
        />
      </div>
    </>
  );
};
