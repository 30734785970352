import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import postRibbon from "../../../../../Assets/statusRibbon/post.png";
import draftRibbon from "../../../../../Assets/statusRibbon/draft.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import { convertDateFormat, today } from "../../../../../Js/Date";
import { customerInvoiceSingleViewAPICall } from "./customerInvoiceAPI";
import paidRibbon from "../../../../../Assets/statusRibbon/paid.png";
import Template1 from "../../../SalesNew/SalesOrders/SalesQuotation/Template1";
import Template2 from "../../../SalesNew/SalesOrders/SalesQuotation/Template2";
import { getTemplateAPI } from "../../../SalesNew/SalesOrders/SalesQuotation/salesQuatationAPI";
import { set_Back_button_action } from "../../../../Single Components/listSlice/filterCategorySlice";
import { InvoiceContent } from "../../PrintComponents/printTemplates/common/InvoiceContent";
import { printSection } from "../../PrintComponents/printTemplates/common/enum";

export const CustomerInvoiceSingleView = () => {
  const singleRowId = localStorage.getItem("singleViewById");
  let location = useLocation()
  const template = useSelector(
    (state) => state?.salesQuatationSlice?.setTemplate
  );

  let navigateTo = useNavigate();
  let paymentsPrint = useRef();
  let paymentsPrintNew = useRef();
  const componentRef = useRef();


  //data of selected List
  const singleList = useSelector(
    (state) => state?.customerInvoiceSlice?.rowData
  );

  // company details and info
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  //console.log(companyProfileData);
  //initial value
  const formValuesInitialState = {
    status: "",
    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
    companyTRN: "",

    customerName: "",
    customerAddress: "",
    customerContact: "",
    trn: "",

    deliveryName: "",

    totalTaxInclusive: "",
    totalTaxExclusive: "",
    deliveryAddress: "",
    deliveryContact: "",

    dateN1: "Invoice Date",

    termsAndCondition: "",

    lpo: "",
    ref: "",
    sequence: "Invoice No",
    subtotal: "",
    name: "",
    firstDate: "",
    secondDate: "",
    accountDate: "",
    total: "",
    totalAmount: "",
    payTerm: "",
    label: "INVOICE",
  };
  const [formValues, setFormValues] = useState(formValuesInitialState);
  const [tax, setTax] = useState([]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    setFormValues({
      ...formValues,
      status: singleList?.status,
      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
      companyTRN: companyProfileData?.companyTRN || "",

      customerName: singleList?.customerAddress?.name || "",
      customerAddress: singleList?.customerAddress?.streetName || "",
      customerContact: singleList?.customerAddress?.mobileNo || "",
      trn: singleList?.customerAddress?.TRN || "",

      termsAndCondition: singleList?.termsAndConditions || "",

      totalTaxInclusive: singleList?.totalTaxInclusive || "",
      totalTaxExclusive: singleList?.totalTaxExclusive || "",
      deliveryName: singleList?.deliveryName || "",
      deliveryAddress: singleList?.deliveryAddress || "",
      deliveryContact: singleList?.deliveryContact || "",

      lpo: singleList?.LpoNo || "",
      ref: singleList?.paymentRef || "",
      name: singleList?.name || "",
      subtotal: singleList?.subTotal || "",
      firstDate: singleList?.invoiceDate || "",
      accountDate: singleList?.accountingDate || "",
      totalAmount: singleList?.total || "",
      total: singleList?.total || "",
      payTerm: singleList?.PAYMENTTERMS || "",
      qrcodetype: singleList?.qrcodetype || "",
      customerId: singleList?.customerId || "",
      db: singleList?.db || "",
      _id: singleList?._id || "",
    });
    setTax(singleList?.taxDetails);
    setData(singleList?.productInfo);
  }, [singleList, companyProfileData]);

  // api to fetch companyprofile data
  useEffect(() => {
    getTemplateAPI();
    if (singleRowId) {
      customerInvoiceSingleViewAPICall({ _id: singleRowId });
    }
  }, []);

  const [domain, setDomain] = useState("");
  useEffect(() => {
    if (formValues) {
      let element = formValues;
      const domainAddress = `${window.location.protocol}//${window.location.host
        }/posReaderPreview?path=admin/viewqrcodedata&db=${encodeURIComponent(
          element?.db
        )}&vendororcusid=${encodeURIComponent(
          element?.customerId
        )}&type=${encodeURIComponent(
          element?.qrcodetype
        )}&orderId=${encodeURIComponent(element?._id)}`;
      setDomain(domainAddress);
    }
  }, [formValues]);

  return (
    <div className="main-container" style={{ backgroundColor: "unset", padding: "0" }}>
      {location?.state?.isReceipt === true || location?.state?.isProduct === true ? (
        <div className="first-div-container" style={{ backgroundColor: "white", justifyContent: "space-between" }}>
          {/* <div className="post-ribbon">
          {formValues?.status === "DRAFT" && <img src={draftRibbon} alt="" />}
          {formValues?.status === "POSTED" && <img src={postRibbon} alt="" />}
          {formValues?.status === "PAID" && <img src={paidRibbon} alt="" />}
           </div> */}

          <div style={{ width: "94%", display: "flex", justifyContent: "space-between" }}>

            <div style={{ display: "flex" }}>
              {/* <Tooltip title="Back">
          <IconButton>
          <i
            className="bi bi-chevron-left"
            style={{ fontSize: "30px", color: "black", cursor: "pointer" }}
            onClick={() => {
              if(location?.state?.isReceipt===true){
                navigateTo(
                  "/userdashboard/sales/Report/CustomerReceiptReportSingleView"
                );

              }
              else if(location?.state?.isProduct===true){
                navigateTo(
                  "/userdashboard/accounts/report/generalReport/productMarginReport/SingleView"
                );
              }
              else{
              localStorage.removeItem("singleViewById");
              navigateTo("/userdashboard/accounts/customers/saleswso");
              dispatch(
                set_Back_button_action({ backButtonClickAction: true })
              );
            }}}
          ></i>
        </IconButton>
       </Tooltip> */}
              <h3 className="justify-center">
                <span style={{ paddingLeft: "20px", fontWeight: "700", fontSize: "medium", cursor: "pointer" }}
                  onClick={() => {
                    if (location?.state?.isReceipt === true) {
                      navigateTo(
                        "/userdashboard/sales/Report/CustomerReceiptReportSingleView"
                      );

                    }
                    else if (location?.state?.isProduct === true) {
                      navigateTo(
                        "/userdashboard/accounts/report/generalReport/productMarginReport/SingleView"
                      );
                    }
                    else {
                      localStorage.removeItem("singleViewById");
                      navigateTo("/userdashboard/accounts/customers/saleswso");
                      dispatch(
                        set_Back_button_action({ backButtonClickAction: true })
                      );
                    }
                  }}
                >{location?.state?.name}</span>
                &gt;
                <span style={{ fontSize: "medium" }}>{location?.state?.seq}</span>

              </h3>
            </div>
            <div>

              <Tooltip title="Share">
                <IconButton>
                  <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                </IconButton>
              </Tooltip>

              {/* <ReactToPrint
                trigger={() => (
                  <IconButton className="printer-div" style={{ padding: "1px" }}>
                    <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
                  </IconButton>
                )}
              // content={() => paymentsPrintNew}
              /> */}
               <ReactToPrint
            // The print button
            trigger={() => (
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i
                  style={{
                    color: "white",
                    backgroundColor: " #d787e7",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                  class="bi bi-printer"
                ></i>
              </IconButton>
            )}
            // The content to be printed (InvoiceContent component)
            content={() => componentRef.current}
            // Set the page style for printing
            pageStyle="@page { size: A4; margin: 0; }"
          />

              <Tooltip title="Download">
                <IconButton>
                  <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      ) : (
        <div className="first-div-container" style={{ backgroundColor: "white", justifyContent: "space-between" }}>
          {/* <div className="post-ribbon">
          {formValues?.status === "DRAFT" && <img src={draftRibbon} alt="" />}
          {formValues?.status === "POSTED" && <img src={postRibbon} alt="" />}
          {formValues?.status === "PAID" && <img src={paidRibbon} alt="" />}
        </div> */}
          <div style={{ display: "flex" }}>
            <Tooltip title="Edit">
              <IconButton>
                <i
                  class="bi bi-pencil-square edit-icon1"
                  onClick={() => {
                    if (location?.state?.isReceipt === true) {
                      navigateTo("/userdashboard/accounts/customers/customerInvoices/create", { state: { isReceipt: true } })
                    }
                    else if (location?.state?.isProduct === true) {
                      navigateTo("/userdashboard/accounts/customers/customerInvoices/create", { state: { isProduct: true } })
                    }
                    else {
                      navigateTo(
                        "/userdashboard/accounts/customers/customerInvoices/create"
                      )
                    }
                  }}
                ></i>
              </IconButton>
            </Tooltip>
            <h3 className="justify-center">
              <span

                style={{ paddingLeft: "20px", fontWeight: "700", fontSize: "medium", cursor: "pointer" }}
                onClick={() => {
                  if (location?.state?.isReceipt === true) {
                    navigateTo(
                      "/userdashboard/sales/Report/CustomerReceiptReportSingleView"
                    );
                  }
                  else if (location?.state?.isProduct === true) {
                    navigateTo(
                      "/userdashboard/accounts/report/generalReport/productMarginReport/SingleView"
                    );
                  }
                  else {
                    navigateTo(
                      "/userdashboard/accounts/customers/customerInvoices"
                    );
                    dispatch(
                      set_Back_button_action({ backButtonClickAction: true })
                    );
                  }
                }}
              > customer Invoice &gt;</span>
              <span style={{ fontSize: "medium" }}>{formValues?.name}</span>
            </h3>


          </div>
          <div>
            {/* <ReactToPrint
            trigger={() => (
              <IconButton className="printer-div">
                <i style={{ color: "black" }} class="bi bi-printer"></i>
              </IconButton>
            )}
            content={() => paymentsPrint}
          /> */}
            <Tooltip title="Share">
              <IconButton>
                <i
                  class="bi bi-share"
                  style={{
                    backgroundColor: "#9797e9",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>

            <ReactToPrint
            // The print button
            trigger={() => (
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i
                  style={{
                    color: "white",
                    backgroundColor: " #d787e7",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                  class="bi bi-printer"
                ></i>
              </IconButton>
            )}
            // The content to be printed (InvoiceContent component)
            content={() => componentRef.current}
            // Set the page style for printing
            pageStyle="@page { size: A4; margin: 0; }"
          />

            <Tooltip title="Download">
              <IconButton>
                <i
                  class="bi bi-arrow-down-circle"
                  style={{
                    backgroundColor: "rgb(255 118 133 / 69%)",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}


      {/* <hr className="line" /> */}
      <div style={{ backgroundColor: "white", height: "80vh", marginTop: "4px", position: "relative" }}>
        <div className="second-main-container">
          <div className="post-ribbon" style={{ right: "0px", top: "0px" }}>
            {formValues?.status === "DRAFT" && <img src={draftRibbon} alt="" />}
            {formValues?.status === "POSTED" && <img src={postRibbon} alt="" />}
            {formValues?.status === "PAID" && <img src={paidRibbon} alt="" />}
          </div>
          <div className="left-side-div">
            <img src={formValues?.companyLogo} alt="" />
            <p>{formValues?.companyName}</p>
            <p>{formValues?.companyAddress || ""}</p>
            <p>{formValues?.companyContact}</p>

            <h1 className="vendor-head">Customer Address</h1>
            <p>{formValues?.customerName}</p>
            <p>{formValues?.customerEmail}</p>
            <p>{formValues?.customerAddress}</p>
            <p>{formValues?.customerContact}</p>

            <h1 className="vendor-head">Deliver To</h1>
          </div>
          <div className="right-side-div">
            <h1>Customer Invoice </h1>
            <p>{formValues?.name}</p>
            <div className="date-div">
              <p>
                <span class="date-container-single">Invoice Date:</span>
                {formValues?.firstDate}
              </p>
              <p>
                <span class="date-container-single">Accounting Date:</span>
                {formValues?.accountDate}
              </p>
            </div>
          </div>
        </div>

        <div className="Table-container">
          <div className="Table-data">
            <table>
              <thead>
                <tr style={{ backgroundColor: "#eaeef9" }}>
                  <th>No</th>
                  <th>Product</th>
                  <th>Account</th>
                  <th>UOM</th>
                  <th>QTY</th>
                  <th>Unit Price</th>
                  <th>Discount</th>
                  <th>Tax</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>{item?.product}</td>
                    <td>{item?.accountName}</td>
                    <td>{item?.uomName}</td>
                    <td>{item?.qty}</td>
                    <td>{item?.unitPrice}</td>
                    <td>{item?.discount}</td>
                    <td
                      style={{
                        display: "flex",
                        placeContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      {item.taxIdList?.map((r, i) => (
                        <p key={i} style={{ margin: "1px" }}>
                          {r?.taxName}
                        </p>
                      ))}
                    </td>
                    <td>{item.lineTotal}</td>
                  </tr>
                ))}
              </tbody>
            </table>


          </div>
          <div className="last-total-div" style={{ width: "85%", padding: "18px 32px" }}>
            <div className="sub-total">
              <h1>Sub Total</h1>
              <p>Total tax Inclusive</p>
              <p>Total tax Exclusive</p>
              <hr className="line-2" />
              <h1>Total</h1>
            </div>
            <div className="sub-total-amount">
              <h1>{formValues?.subtotal || 0}</h1>
              <p>{formValues?.totalTaxInclusive || 0}</p>
              <p>{formValues?.totalTaxExclusive || 0}</p>
              <hr className="line-3" />
              <h1>{formValues?.total}</h1>
            </div>
          </div>
        </div>
      </div>

      {/* payments print view */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <p></p>
              <p></p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {/* <h3>--</h3>

              <h3>--</h3> */}

              <p>Customer Invoice</p>
            </div>

            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>{convertDateFormat(today)}</p>
              <p>{""}</p>
            </div>
          </div>

          <div className="second-main-container">
            <div className="left-side-div">
              <img
                src={formValues?.companyLogo}
                alt={formValues?.companyName}
              />
              <p>{formValues?.companyAddress}</p>
              <p>{formValues?.companyContact}</p>

              <h1 className="vendor-head">Customer Address</h1>
              <p>{formValues?.vendorName}</p>
              {/* <p>--</p>
              <p>--</p> */}

              <h1 className="vendor-head">Deliver To</h1>
              {/* <p>--</p>
                <p>--</p>
               <p>--</p> */}
            </div>
            <div className="right-side-div">
              <h1>Customer Invoice </h1>
              <p>{formValues?.name}</p>
              <div className="date-div">
                <p>
                  <span class="date-container-single">Invoice Date:</span>
                  {formValues?.invoiceDate}
                </p>
                <p>
                  <span class="date-container-single">Accounting Date:</span>
                  {formValues?.accountingDate}
                </p>
              </div>
            </div>
          </div>

          <div className="Table-container" style={{ padding: "0px 35px" }}>
            <div className="Table-datas">
              <table style={{ overflow: "none" }}>
                <thead>
                  <tr style={{ backgroundColor: "#eaeef9" }}>
                    <th>No</th>
                    <th>Product</th>
                    <th>UOM</th>
                    <th>QTY</th>
                    <th>Unit Price</th>
                    <th>Discount</th>
                    <th>Tax</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                        height: "32px",
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{item.product}</td>
                      <td>{item.uomName}</td>
                      <td>{item.qty}</td>
                      <td>{item.unitPrice}</td>
                      <td>{item.discount}</td>
                      <td
                        style={{
                          display: "flex",
                          placeContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        {item.taxes?.map((r, i) => (
                          <p key={i} className="tax" style={{ margin: "1px" }}>
                            {r?.amount}%
                          </p>
                        ))}
                      </td>
                      <td>{item.cost}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="last-total-div">
                <div className="sub-total">
                  <h1>Sub Total</h1>
                  <p>Total tax Inclusive</p>
                  <p>Total tax Exclusive</p>
                  <hr className="line-2" />
                  <h1>Total</h1>
                </div>
                <div className="sub-total-amount">
                  <h1>{formValues?.subtotal || 0}</h1>
                  <p>{formValues?.totalTaxInclusive || 0}</p>
                  <p>{formValues?.totalTaxExclusive || 0}</p>
                  <hr className="line-3" />
                  <h1>{formValues?.total}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="terms">
            <h1>Terms & Conditions</h1>
            <hr className="lines" />
          </div>
        </div>
      </div>
      {/* <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
          {template?.templateModel === "template1" ? (
            <Template1
              data={data}
              formValues={formValues}
              tax={tax}
              domain={domain}
            />
          ) : (
            <Template2
              data={data}
              formValues={formValues}
              tax={tax}
              domain={domain}
            />
          )}
        </div>
      </div> */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
          <InvoiceContent
            invoiceDetails={{ ...formValues, printSection: printSection.customerInvoice }}
            ref={componentRef}
            printData={data}
            taxDetails={tax}
            qrData={domain}
          />
        </div>
      </div>
    </div>
  );
};
