// icon:bx-rectangle | Boxicons https://boxicons.com/ | Atisa
import * as React from "react";

function IconBxRectangle(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1.5em"
      width="1.5em"
      {...props}
    >
      <path d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 19V5h16l.001 14H4z" />
    </svg>
  );
}

export default IconBxRectangle;
