import React, { useEffect } from 'react'
import { useNavigate } from "react-router";
import { JournalFilterAPICall } from '../../../../API/AccountUpdated/journalAPI';
import { useSelector } from 'react-redux';
import store from '../../../../Redux/store';
import { save_single_journal } from '../../../../Redux/AccountUpdated/journalSlice';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { useState } from 'react';
import CustomPagination from '../../../Single Components/CustomPagination';
import { setFilterActive } from '../../../Single Components/listSlice/filterCategorySlice';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';
import ExcelJS from "exceljs"
import TableListActionButtons from '../../../Single Components/ActionButtons/TableListActionButtons';

export const Journal = () => {
  const journalTypes=[
    { label: "sales", type: "subheading" 
      
    },
    {
        label:"purchase"
    },
    {
        label:"cash"
    },
    {
        label:"bank"
    },
    {
        label:"miscellaneous"
    },
]
  let navigate = useNavigate();
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const journalList = useSelector((state) => state.journalSlice.tableList)
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingForm,setLoadingForm]=useState(false)
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [filteredDatas, setFilteredData] = useState({});
  const totalPages = journalList?.pages;

  const createJournalFn = () => {
    navigate("/userdashboard/accounts/accounting/journal/createjournal")
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handleFilterChange=(updatedItems)=>{
    setFilteredData(updatedItems)
  }
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }
    if (filters?.location) {
      parts.push(`location: ${filters?.location?.name}`);
    }
    if (filters?.toLocation) {
      parts.push(`To Location: ${filters?.toLocation?.name}`);
    }
    if (filters?.status) {
      parts.push(`status: ${filters?.status}`);
    }
    if (filters?.createdBy) {
      parts.push(`createdBy: ${filters?.createdBy}`);
    }
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Journal");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Journal"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells("A1:E1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredDatas) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:E${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "No",
      "Journal Name",
      "Type",
      "Short Code",
      "Default Account",
     
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "No", key: "No", width: 15 },
      { header: "Journal Name", key: "journalName", width: 20 },
      { header: "Type", key: "type", width: 25 },
      { header: "Short Code", key: "shortCode", width: 25 },
      { header: "Journal", key: "defaultAccountName", width: 35 },
     
    ];

    journalList?.list?.forEach((item, index) => {
      const row = sheet.addRow({
        No: index+1,
        journalName: item.journalName,
        type: item.type,
        shortCode: item.shortCode,
        defaultAccountName: item?.defaultAccountName,
        
      });
      

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: "thin", color: { argb: "FF000000" } },
          //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
          //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Journal.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  useEffect(() => {
    // journalListApiCall()
    store.dispatch(save_single_journal({ singleJournalData: undefined }))
  }, [])

  const viewSingleRow = (data) => {

    store.dispatch(save_single_journal({ singleJournalData: data }))
    store.dispatch( setFilterActive(window.location.pathname))
    navigate("/userdashboard/accounts/accounting/journal/createjournal")
  }
  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&
      JournalFilterAPICall({
        branchId: FormDataInfo.branchList,
        type: FormDataInfo.type,
        search: FormDataInfo.search,
        index: currentPage - 1,
      }, setIsLoading);
    }
   
  }, [FormDataInfo, currentPage
  ]);

  return (
    <div className='global-page-parent-container updated-global-page-parent-container'>
      <div className="justify-space-between list-create-head-bar">
        <h3>Journal</h3>
        <div className="create-head-buttons-container">
          <TableListActionButtons downloadClick={downloadList} />
          <CategorySearchandFilter
            statusOptions={['ACTIVE', 'INACTIVE']}
            onData={handleDataFromChild}
            isDate={false}
            isType={true}
            typeList={journalTypes}
            onBranchSelect={handleBranchSelect}
            onFilterChange={handleFilterChange}
          />
          <button onClick={createJournalFn} className='create-button-blue btn btn-primary'>Create</button>
        </div>
      </div>

      <div className="justify-space-between pagination-container">
        {FormDataInfo?.branchList?.length > 0 &&
          <h3>Branch name:
            {selectedBranch?.storeCode || filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
            -{selectedBranch?.branchName || filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>}
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          setFormDataInfo={setFormDataInfo}
        />
      </div>
  
      <div className='global-table-container updated-table-container'>
        <table className='global-table'>
          <thead>
            <tr>
              <th>No</th>
              <th>Journal Name</th>
              <th>Type</th>
              <th>Short Code</th>
              <th>Default Account</th>
            </tr>
          </thead>
          <tbody >
            {
              isLoading?
              [...Array(10)].map((_,i)=>(
                <tr key={i}>
                  <td colSpan={7}><Skeleton variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}/></td>
                </tr>
              )):
              (journalList!==undefined && journalList?.list.length!==0)?
              journalList?.list?.map((r, i) => (
                <tr onClick={() => viewSingleRow(r)} style={{ cursor: "pointer" }} title="View Details">
                  <td>{i + 1}</td>
                  <td>{r?.journalName}</td>
                  <td>{r?.type}</td>
                  <td>{r?.shortCode}</td>
                  <td>{r?.defaultAccountName}</td>
                  <td></td>
                </tr>
              ))
              :
              <tr>
                <td colSpan={5}>No Data</td>
              </tr>
            }

          </tbody>
        </table>
      </div>
        
    </div>
  )
}
