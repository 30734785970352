import React, { useState, useRef, useEffect } from "react";
import UploadImageSvg from "../icons/UploadImageSvg";
import CloseIcon from "@mui/icons-material/HighlightOff";
import "./uploadImageStyle.css";

const ImageUpload = ({ onImageChange, imageUrl, disable }) => {
  const [src, setSrc] = useState(null);
  const [inputEnabled, setInputEnabled] = useState(true);
  const fileInput = useRef(null);

  useEffect(() => {
    if (imageUrl && imageUrl instanceof File) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setSrc(reader.result);
      });
      reader.readAsDataURL(imageUrl);
    } else if (imageUrl && typeof imageUrl === "string") {
      setSrc(imageUrl);
    } else {
      setSrc(null);
    }
  }, [imageUrl]);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setSrc(reader.result);
        onImageChange(e.target.files[0]);
      });
      reader.readAsDataURL(e.target.files[0]);
      setInputEnabled(false); // Disable input while image is being processed
    }
  };

  const handleRemoveImage = () => {
    setSrc(null);
    onImageChange(null);
    setInputEnabled(true); // Enable input after removing the image
  };

  const handleUploadImageClick = () => {
    fileInput.current.click();
  };
console.log(inputEnabled);
  return (
    <div className="image-upload-component-container">
      <input
        type="file"
        onChange={onSelectFile}
        ref={fileInput}
        style={{ display: "none" }}
        disabled={disable} // Disable input if inputEnabled is false
      />
      <div className="image-preview-container-new">
        {src ? (
          <>
            <img
              alt="Uploaded"
              src={src}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "fill",
              }}
            />
            {!disable && (
              <CloseIcon
                sx={{ color: "red" }}
                className="remove-image-icon"
                onClick={handleRemoveImage}
              />
            )}
          </>
        ) : (
          <div className="upload-image-svg" onClick={handleUploadImageClick}>
            <UploadImageSvg />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
