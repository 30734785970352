import React from "react";
import PositiveAndNegativeBarChart from "./chartTypes/PositiveAndNegativeBarChart";
import DuoBarChart from "./chartTypes/DuoBarChart";


const DashboardGraph = () => {
 
  return (
    <div style={{display:'flex',gap:'1em'}}>
      <DuoBarChart />
      <PositiveAndNegativeBarChart />
    </div>
  );
};

export default DashboardGraph;
