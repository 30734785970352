import { createSlice } from "@reduxjs/toolkit";

export const viewRolesAndPermissionSlice=createSlice({
    name:'viewRolesAndPermissionSlice',
    initialState:{
        value:undefined,
        employeePermission:undefined
    },
    reducers:{
        update_roles_and_permission:(state,action)=>{
            state.value=action.payload.rolesAndPermission
        },
        get_employee_permission:(state,action)=>{
            state.employeePermission=action.payload.employeePermissionData
        }
    }
})

export const {update_roles_and_permission,get_employee_permission}=viewRolesAndPermissionSlice.actions
export default viewRolesAndPermissionSlice.reducer