import { createSlice } from "@reduxjs/toolkit";

export const specialItemsSlice=createSlice({
    name:"specialItemsSlice",
    initialState:{
        specialItemsList:[],
        productList:[],
        specialItemsListForBilling:[],
        specialItemsSingleList:null,
        tableLoading:false,
        editClicked:false,
        isCombo:false,
        infoArray:[],
        formValues:null,
        isEdit:false,
        imageUpload:null
    },
    reducers:{
        storeSpecialItemList:(state,action)=>{
            state.specialItemsList=action.payload
        },
        storProductListForSpecialItems:(state,action)=>{
            state.productList=action.payload
        },
        storeSpecialItemListForBilling:(state,action)=>{
            state.specialItemsListForBilling=action.payload
        },
        storeSpecialItemSingleList:(state,action)=>{
            state.specialItemsSingleList=action.payload
        },
        setTableLoading:(state,action)=>{
            state.tableLoading=action.payload
        },
        setIsEditClicked:(state,action)=>{
            state.editClicked=action.payload
        },
        setIsCombo:(state,action)=>{
            state.isCombo=action.payload
        },
        setInfoCard:(state,action)=>{
            state.infoArray=action.payload
        },setSpecialItemsFormInfo:(state,action)=>{
            state.formValues=action.payload
        },setIsEdit:(state,action)=>{
            state.isEdit=action.payload
        },setImageUpload:(state,action)=>{
            state.imageUpload=action.payload
        }
    }
})

export const{storeSpecialItemList,
    storeSpecialItemSingleList,
    setTableLoading,
    setIsEditClicked,
    setIsCombo,
    setInfoCard,setSpecialItemsFormInfo,
    setIsEdit,setImageUpload,storeSpecialItemListForBilling,storProductListForSpecialItems
}=specialItemsSlice.actions

export default specialItemsSlice.reducer