import axios from "axios"
import { HEADERS } from "../../../../../API/UrlAndPaths"
import store from "../../../../../Redux/store"
import { setReservationName, storeReserevationList, storeSingleReserevation } from "../Slice/reservationSlice"
import { setTableLoading } from "../Slice/viewOrdersBillingSlice"
import { setSubmitLoading } from "../../../../../Redux/Dashboard/mainDashBoardSlice"
import { setDialogBox, setErrorSnack, setOrderIdInPOS, setSnackMsg, setSuccessSnack } from "../Slice/billingScreenSlice"
import { setBtnSelected } from "../Components/topBarSliceBilling"

// list reservation
export const listReservationList=async(body)=>{

    store.dispatch(setTableLoading(true))
    await axios.post("pos/viewReservationList",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(storeReserevationList(res.data))
                store.dispatch(setTableLoading(false))
            }
        })
        .catch((err)=>{
            store.dispatch(storeReserevationList([]))
            store.dispatch(setTableLoading(false))

        })
}
// list reservation
export const generateReservationName=async(body)=>{
    await axios.post("pos/generateReservationName",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setReservationName(res.data))
            }
        })
        .catch((err)=>{
            store.dispatch(setReservationName(null))
        })
}


// add reservation
export const addReservationAPI=async(body,clearFn)=>{
    store.dispatch(setSubmitLoading(true))
    await axios.post("pos/addReservation",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                clearFn()
                store.dispatch(setSubmitLoading(false))
                store.dispatch(setDialogBox(null))
                store.dispatch(setSnackMsg("Reservation added succesfully"))
                store.dispatch(setSuccessSnack(true))
        }
        })
        .catch((err)=>{
            store.dispatch(setSubmitLoading(false))
            let message=err.response.status===500?"Internal Server Error !!":err.response.data
                store.dispatch(setSnackMsg(message))            
                store.dispatch(setErrorSnack(true))

        })
}
// edit reservation
export const editReservationAPI=async(body,clearFn)=>{
    store.dispatch(setSubmitLoading(true))
    await axios.post("pos/editReservation",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                clearFn()
                store.dispatch(setSubmitLoading(false))
                store.dispatch(setDialogBox(null))
                store.dispatch(setSnackMsg(`Reservation ${body?.status==="CANCEL"? 'Cancelled':'edited succesfully' }`))
                store.dispatch(setSuccessSnack(true))
        }
        })
        .catch((err)=>{
            store.dispatch(setSubmitLoading(false))
            let message=err.response.status===500?"Internal Server Error !!":err.response.data
                store.dispatch(setSnackMsg(message))            
                store.dispatch(setErrorSnack(true))
        })
}
export const postReservationAPI=async(body,clearFn)=>{
    store.dispatch(setSubmitLoading(true))
    await axios.post("pos/postReservationToPosOrder",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setOrderIdInPOS(res.data.orderId))
                clearFn()
                store.dispatch(setSubmitLoading(false))
                store.dispatch(setSnackMsg(`Reservation Posted`))
                store.dispatch(setSuccessSnack(true))
                store.dispatch(setBtnSelected(null))
            }
        })
        .catch((err)=>{
            console.log(err);
            store.dispatch(setSubmitLoading(false))
            let message=err.response.status===500?"Internal Server Error !!":err.response.data
                store.dispatch(setSnackMsg(message))            
                store.dispatch(setErrorSnack(true))
        })
}

