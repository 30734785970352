import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { useSelector } from 'react-redux';
import { StockMoveAPIcall } from './ReportAPI';
import { getCurrentTime } from '../../../../Js/Date';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import Template from "./StockMovePrint"
import { setStockMoveSigleView } from './ReportSlice';
import store from '../../../../Redux/store';
import { setFilterActive, set_Back_button_action } from '../../../Single Components/listSlice/filterCategorySlice';
import ExcelJS from "exceljs"
import TableListActionButtons from '../../../Single Components/ActionButtons/TableListActionButtons';

export const StockMove = () => {
  let navigate = useNavigate();
  const [symbol, setSymbol] = useState(null);
  const [filteredData, setFilteredData] = useState({});
  const [FormDataInfo, setFormDataInfo] = useState([])
  let paymentsPrint = useRef();
  const [selectedBranch, setSelectedBranch] = useState(null);
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  const decimalPosition = localStorage.getItem("decimalPosition");
  const decimalPositionStock = localStorage.getItem("decimalPositionStock");

  const stockMove = useSelector((state) => state?.ReportSlice?.StockMove)
  const {currencyDetails,tableLoading}=useSelector((state)=>state.mainDashBoardSlice)
  const filtereDataOptions = useSelector((state) => state.listCategory);

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const formatFilterInfo = (filters) => {
    const parts = [];
  
    if (filters?.fromDate && filters?.toDate) {
      parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
    }

    if (filters?.branchList) {
      parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    }
    if (filters?.location) {
      parts.push(`Branch: ${filters?.location?.name}`);
    }
   
    
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Stock move report");
    sheet.properties.defaultRowHeight = 20;
  
    // Add header section
    const titleRow = sheet.addRow(["Stock move report"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells('A1:H1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];
  
    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 15, bold: true };
        sheet.mergeCells(`A${index + 2}:H${index + 2}`);
        row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      });
    }
  
    // Add an empty row between header and table
    sheet.addRow([]);
  
    // Header row styling
    const headerRow = sheet.addRow(["Item", "UOM", "Opening", "In","Out","Closing","Cost","Values"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };
  
    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    });
  
    sheet.columns = [
      { header: "Item", key: "productName", width: 30 },
      { header: "UOM", key: "uom", width: 10 },
      { header: "Opening", key: "openingBalance", width: 15 },
      { header: "In", key: "totalInDate", width: 13 },
      { header: "Out", key: "totalOutDate", width: 13 },
      { header: "Closing", key: "closingBalance", width: 13},
      { header: "Cost", key: "cost", width: 13 },
      { header: "Stock Move Report", key: "value", width: 15 },
    ];
  
    stockMove?.forEach((item) => {
      const row = sheet.addRow({
        productName: item?.productName,
        uom: item?.uom,
        openingBalance: ` ${item?.openingBalance}`,
        totalInDate: ` ${item?.totalInDate?.toFixed(2)}`,
        totalOutDate: ` ${item?.totalOutDate?.toFixed(2)}`,
        closingBalance: ` ${item?.closingBalance?.toFixed(2)}`,
        cost: ` ${item?.cost?.toFixed(2)}`,
        value: ` ${item?.value?.toFixed(2)}`,
      });
  
      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFC3D4F7" }, // Use full hex code with "FF" prefix for better compatibility
      };
      
      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >2) { // Right align all columns except the first
            cell.alignment = { horizontal: 'right' };
          }else{
            cell.alignment = { horizontal: 'center' };
          }
      });
    });
  
    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      "",
      "",
      `${symbol} ${stockMove?.reduce((sum,item)=>sum+item?.totalInDate,0)?.toFixed(decimalPosition)}`,
      `${symbol} ${stockMove?.reduce((sum,item)=>sum+item?.totalOutDate,0)?.toFixed(decimalPosition)}`,
      `${symbol} ${stockMove?.reduce((sum,item)=>sum+item?.closingBalance,0)?.toFixed(decimalPosition)}`,
      `${symbol} ${stockMove?.reduce((sum,item)=>sum+item?.cost,0)?.toFixed(decimalPosition)}`,
      `${symbol} ${stockMove?.reduce((sum,item)=>sum+item?.value,0)?.toFixed(decimalPosition)}`,
    
      
      
      
      
     
    ]);
  
    totalRow.eachCell((cell,colNumber) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber >1) { // Right align all columns except the first
        cell.alignment = { horizontal: 'right' };
      }
    });
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Stock Move Report.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const handleClickRow = (row) => {
    localStorage.setItem("vendor", row?.productName)
    localStorage.setItem("stock_id", row?._id);
    store.dispatch( setStockMoveSigleView({stockMoveSingleList:[]}))
    store.dispatch( setFilterActive(window.location.pathname))
    navigate("/userdashboard/inventory/reports/stockMove/singleView")
  }


  const handlePrint=useReactToPrint({
    content: () => paymentsPrint.current,
  })
  useEffect(() => {
    setSymbol(currencyDetails?.symbol);
  }, [currencyDetails]);

  useEffect(() => {
    if (FormDataInfo?.length !== 0) {
      localStorage.setItem("branch", (FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0]))
      localStorage.setItem("location", FormDataInfo?.location?.length === 0 ? '' : FormDataInfo?.location[0])
      localStorage.setItem("fromDate", FormDataInfo?.fromDate)
      localStorage.setItem("ToDate", FormDataInfo?.toDate)

      let body = {
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        endDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        location: FormDataInfo?.location?.length === 0 ? '' : FormDataInfo?.location[0],
        branchId: FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0],

        search: FormDataInfo?.search,
      }

    let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData && StockMoveAPIcall(body)
    }
  }, [FormDataInfo])


  return (
    <div className="global-page-parent-container updated-global-page-parent-container">
      <div className="justify-space-between list-create-head-bar">
        <h3>Stock Move Report</h3>
        <div className="create-head-buttons-container">
          <TableListActionButtons downloadClick={downloadList} PrintClick={handlePrint}/>
          <CategorySearchandFilter
              statusOptions={['PURCHASE ORDER', 'DONE', 'CANCEL']}
              onData={handleDataFromChild}
              isLocation={true}
              onBranchSelect={handleBranchSelect}
              onFilterChange={handleFilterChange}
            />
        </div>
      </div>
      <div className="global-report-table-container updated-table-container" style={{height:"calc(100vh - 145px)"}}>
        <table style={{paddingTop:"4px"}}>
          <thead>
            <tr >
              <th>Item</th>
              <th>UOM</th>
              <th>Opening</th>
              <th>In</th>
              <th>Out</th>
              <th>Closing</th>
              <th>Cost</th>
              <th>Values</th>
            </tr>
          </thead>
          <tbody>
            {
            tableLoading?
            ([...Array(10)].map((r, i) => (
              <tr key={i}>
                <td colSpan={10}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={"100%"}
                    height={40}
                  />
                </td>
              </tr>
            ))
          ):stockMove!==undefined?
          stockMove?.map((item, index) => (
              <tr
              style={{cursor:"pointer"}}
              key={index} onClick={() => handleClickRow(item)}>
                <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.productName}</td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.uom}</td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.openingBalance}</td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalInDate?.toFixed(decimalPositionStock)}</td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalOutDate?.toFixed(decimalPositionStock)}</td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.closingBalance?.toFixed(decimalPosition)}</td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.cost?.toFixed(decimalPosition)}</td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.value?.toFixed(decimalPosition)}</td>
              </tr>
            ))
          :
          <tr>
            <td colSpan={8}>No Records !!</td>
          </tr>
          }
          </tbody>
          {
          !tableLoading&& stockMove!==undefined&& 
          <tfoot>
            <tr>
              <th colSpan={3}>Total</th>
              <th >{symbol}&nbsp;{(stockMove?.reduce((sum,item)=>sum+item?.totalInDate,0)?.toFixed(decimalPosition))}</th>
              <th >{symbol}&nbsp;{(stockMove?.reduce((sum,item)=>sum+item?.totalOutDate,0)?.toFixed(decimalPosition))}</th>
              <th >{symbol}&nbsp;{(stockMove?.reduce((sum,item)=>sum+item?.closingBalance,0)?.toFixed(decimalPosition))}</th>
              <th >{symbol}&nbsp;{(stockMove?.reduce((sum,item)=>sum+item?.cost,0)?.toFixed(decimalPosition))}</th>
              <th >{symbol}&nbsp;{(stockMove?.reduce((sum,item)=>sum+item?.value,0)?.toFixed(decimalPosition))}</th>
            </tr>
          </tfoot>}
        </table>
      </div>


      <div style={{ display: "none" }}>
                <div ref={paymentsPrint}>
                    <Template stockMove={stockMove} symbol={symbol} />
                </div>
            </div>
    </div>
  )
}