import React from "react";
import "./payrollComponentStyle.css";

const PayrollCard = () => {
  return (
    <div className="payroll-card-wrapper">
      <div className="payroll-container">
        <div>
          <div>
            <p className="sub-name">CATEGORY</p>
            <h3 className="name">BASIC</h3>
          </div>
          <div>
            <p className="sub-name">NAME</p>
            <h3 className="name">BASIC FOR SALESMAN</h3>
          </div>
          <div>
            <div className="status-bar">
              <span>Active</span> <h4 className="name">TRUE</h4>
            </div>
          </div>
        </div>
        <div>
          <div>
            <p className="sub-name">CALCULATION TYPE</p>
            <h3 className="name">% OF CTC</h3>
          </div>
          <div>
            <p className="sub-name">PERCENTAGE OF CTC</p>
            <h3 className="name">50 %</h3>
          </div>
        </div>
        <div>
          <div>
            <p className="sub-name">EXPENSE ACCOUNT</p>
            <h3 className="name">Salaries a/c</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayrollCard; 
