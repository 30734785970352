import axios from "axios";
import store from "../../../../Redux/store";
import { HEADERS } from "../../../../API/UrlAndPaths";
import { get_inventory_config_settings_res_list } from "./inventoryConfigurationSettingsSlice";

// add gl config

export const setInventoryConfigSettingsAPICall=async(body,setState,state,setLoadingForm)=>{
    setLoadingForm!==undefined && setLoadingForm(true)

    await axios.post("inventory/productVarientSettings",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                setLoadingForm!==undefined && setLoadingForm(false)

                setState({...state,message:"Inventory Settings Updated",success:true})
                let value=JSON.parse(localStorage.getItem("refreshSideMenu"))
                localStorage.setItem("refreshSideMenu",!value)
                viewInventoryConfigSettingsAPICall();

            }
        })
        .catch((err)=>{
            if (err.response.status === 500) {
                setState({ ...state, message: "Internal Server error !", error: true })
              } else {
                setState({ ...state, message: err?.response?.data, error: true })
              }
        })
}


// view config settings
export const viewInventoryConfigSettingsAPICall=async()=>{
    await axios.get("inventory/ViewProductVarientSettings",HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(get_inventory_config_settings_res_list({invConfigSettingResData:res.data}))
            }
        })
        .catch((err)=>{
            store.dispatch(get_inventory_config_settings_res_list({invConfigSettingResData:undefined}))
        })
}