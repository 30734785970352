import { Dialog, IconButton, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "../../../../css/Staff/HRM/department.css";
import {
  getAllDepartmentAPICall,
  createDepartmentAPICall,
  editDepartmentAPICall,
} from "../../../../API/Staff/HRM/departmentAndDesignationAPI";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
export const Department = () => {
  //*Redux State
  //Department List
  const allDepartmentList = useSelector(
    (state) => state.allDepartmentSlice.value
  );
  // loadingState
  const [isLoading,setIsLoading]=useState(false)
  const [isModify, setIsModify] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!!");
  const [addDept, setAddDept] = useState(false);
  const [openEditDpt, setOpenEditDpt] = useState(false);
  //New Department State
  const [newDepartmentName, setNewDepartmentName] = useState("");
  //Edit dpt states
  const editDptInitialState = { id: null, dptName: "" };
  const [editDpt, setEditDpt] = useState(editDptInitialState);

  // validation state
  const [departmentNameAlert,setDepartmentNameAlert]=useState(false)
  //Close SuccessSnack
  const closeSuccessSnack = () => {
    setOpenSuccessSnack(false);
  };
  //Close ErrorSnack
  const closeErrorSnack = () => {
    setOpenErrorSnack(false);
  };

  //*Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };
  //Clear States
  const clearStates = () => {
    setNewDepartmentName("");
    setAddDept(false);
  };

  //*OnChange Functions
  const getNewDepartmentName = (e) => {
    setNewDepartmentName(e.target.value);
    setDepartmentNameAlert(false)
    if(e.target.value===""){
      setDepartmentNameAlert(true)
    }
  };

  //*OnClick Fns
  const clickAddNewDpt = () => {
    if (newDepartmentName === "") {
      setDepartmentNameAlert(true)
      
    } else {
      createDepartmentAPICall(
        { departmentName: newDepartmentName },
        updateListener,
        setOpenErrorSnack,
        setOpenSuccessSnack,
        setSnackMsg,
        clearStates
      );
    }
  };
  //Click Edit icon button
  const openEditDepartment = (id, dptName) => () => {
    setEditDpt({ ...editDpt, id, dptName });
    setOpenEditDpt(true);
  };
  //Submit Edit Button
  const submitEditDepartment = () => {
    editDepartmentAPICall(
      { id: editDpt.id, departmentName: editDpt.dptName },
      updateListener,
      setOpenErrorSnack,
      setOpenSuccessSnack,
      setSnackMsg
    );
  };

  //Initial API Calls
  useEffect(() => {
    getAllDepartmentAPICall(
      setIsLoading
    );
  }, [isModify]);

  return (
    <>
      <div className="global-page-parent-container">
        <p className="breadcrumb">Staff &gt; HRM &gt; Department </p>
        <div className="department-container">
          <div className="department-top-container">
            <h3>Department</h3>
            <button onClick={() => setAddDept(true)}>Add Department</button>
          </div>
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Department Name</th>
                  <th>Edit</th>
                  {/* <th>Delete</th> */}
                </tr>
              </thead>
              {
                isLoading?
                <tbody>
                    <tr>
                      <td colSpan={9}>
                        {/* <Loader /> */}
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}>
                        {/* <Loader /> */}
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}>
                        {/* <Loader /> */}
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}>
                        {/* <Loader /> */}
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}>
                        {/* <Loader /> */}
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  </tbody>:
                <tbody>
                {allDepartmentList !== undefined ? (
                  allDepartmentList?.map((r, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td >{r?.departmentName?.toUpperCase()}</td>
                      <td>
                        <IconButton
                          onClick={openEditDepartment(
                            r?._id,
                            r?.departmentName
                            )}
                            >
                          <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton>
                      </td>
                      {/* <td>
                        <IconButton disabled className="disabled-icon-button">
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td> */}
                    </tr>
                  ))
                  ) : (
                    <tr>
                    <td colSpan={3}>No Departments</td>
                  </tr>
                )}
              </tbody>
              }
            </table>
          </div>
        </div>

        {/* add department dialog */}
        <Dialog open={addDept} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setAddDept(false )}>
          <div className="add-department-dialog-container">
            <h3>Add Department</h3>
            <hr className="global-hr" />
            <div className="global-single-input department-input">
              <p>Department Name</p>
              <input
                type="text"
                placeholder="Enter new department name..."
                value={newDepartmentName}
                onChange={getNewDepartmentName}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    clickAddNewDpt();
                  }
                }}
              />
              {
                departmentNameAlert?
                <p className="doc-validation-alert">Enter a Name !!</p>
                :""
              }
            </div>
            <div className="department-button-container">
              <button
                onClick={() => {
                  setAddDept(false);
                  setNewDepartmentName("");
                }}
                className="btn btn-secondary-outlined"
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={clickAddNewDpt}>
                Submit
              </button>
            </div>
          </div>
        </Dialog>
        {/* Edit department dialog */}
        <Dialog open={openEditDpt} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setEditDpt(false )}>
          <div className="add-department-dialog-container">
            <h3>Edit Department</h3>
            <hr className="global-hr" />
            <div className="global-single-input department-input">
              <p>Department Name</p>
              <input
                type="text"
                placeholder="Enter department name..."
                value={editDpt.dptName}
                onChange={(e) => {
                  setEditDpt({ ...editDpt, dptName: e.target.value });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    submitEditDepartment()
                  }
                }}
              />
            </div>
            <div className="department-button-container">
              <button
                onClick={() => {
                  setOpenEditDpt(false);
                }}
                className="btn btn-secondary-outlined"
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={submitEditDepartment}>Submit</button>
            </div>
          </div>
        </Dialog>
      </div>

      <SuccessSnackbar
        open={openSuccessSnack}
        message={snackMsg}
        handleClose={closeSuccessSnack}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        message={snackMsg}
        handleClose={closeErrorSnack}
      />
    </>
  );
};
