import axios from "axios";
import { HEADERS, HEADER_FORM_DATA } from "../UrlAndPaths"
import { chart_of_accounts_table_filter, update_chart_of_accounts } from "../../Redux/AccountUpdated/chartOfAccountsSlice";
import store from "../../Redux/store";

export const chartOfAccountListApiCall=(body)=>{
    axios.post("account/listchartscategory",body,HEADERS)
    .then((res)=>{
        store.dispatch(update_chart_of_accounts({chartOfAccountData:res.data}))
    })
    .catch((err)=>{
      
        console.log(err.response);
    })
}
export const ChartOfAccountFilterAPICall =(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)

  axios.post("account/searchchartofaccounts",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(chart_of_accounts_table_filter({chartOfAccountsTableFilter:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(chart_of_accounts_table_filter({chartOfAccountsTableFilter:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)
  })

}

//   import a file
export const importChartOfAccountAPICall=async(body,setSnackBarStates,snackBarStates,closeImportPopup,setLoadingForm)=>{
     setLoadingForm!==undefined && setLoadingForm(true)

    await axios.post("account/importChartOfAccounts",body,HEADER_FORM_DATA)
    
    .then((res)=>{
      if(res.status===200){
          setLoadingForm!==undefined && setLoadingForm(false)
            store.dispatch(update_chart_of_accounts({chartOfAccountData:res.data}))
            setSnackBarStates({...snackBarStates,message:"File imported successfully",success:true})
            closeImportPopup()
        }
    })
    .catch((error)=>{
        setLoadingForm!==undefined && setLoadingForm(false)

        if (error.response.status === 500) {
          setSnackBarStates({...snackBarStates,message:"Internal Server Error!!",error:true})
        } else {
          setSnackBarStates({...snackBarStates,message:error?.response?.data,error:true})
        }
    })
}


// api to export the list
export const exportChartOfAccountListAPICall = async (body,setSnackBarStates,snackBarStates,setLoadingForm) => {
    setLoadingForm!==undefined && setLoadingForm(true)

    try {
      
      const response = await axios.post("account/exportChartOfAccounts", body, {
        ...HEADERS,
        responseType: 'blob', // Specify response type as blob
      });
      
      // Check if the response is successful
      if (response.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)
        // Convert response to blob
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
  
        // Create a temporary URL for the blob
        const url = URL.createObjectURL(blob);
  
        // Create a link element and trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'chartofaccounts.xlsx'; // Set the desired filename
        document.body.appendChild(a);
        a.click();
  
        // Cleanup: remove the link and revoke the URL
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setSnackBarStates({...snackBarStates,message:"File exported successfully",success:true})
      }
    } catch (error) {
            setLoadingForm!==undefined && setLoadingForm(false)
            if (error.response.status === 500) {
              setSnackBarStates({...snackBarStates,message:"Internal Server Error !!",error:true})
            } else {
              setSnackBarStates({...snackBarStates,message:error?.response?.data,error:true})
            }
        }
  };


  export const cancelRedrafteJournalEntriesAPICall=async(body,postFn,setSnackBarStates,snackBarStates,setLoadingForm)=>{
    setLoadingForm!==undefined && setLoadingForm(true)

    await axios.post("account/draftCancelJournalEntry",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                postFn()
                setLoadingForm!==undefined && setLoadingForm(false)

                setSnackBarStates({...snackBarStates,message:"File imported successfully",success:true})
            }
        })
        .catch((err)=>{
          setLoadingForm!==undefined && setLoadingForm(false)

          if (err.response.status === 500) {
            setSnackBarStates({...snackBarStates,message:"Internal Server error!!",error:true})
          } else {
            setSnackBarStates({...snackBarStates,message:err?.response?.data,error:true})
          }

        })
  }
  export const deleteChartOfAccountAPICall=async(body,setstate,refreshApi,setLoadingForm)=>{
    setLoadingForm!==undefined && setLoadingForm(true)

    await axios.post("account/deleteChartOfAccount",body,HEADERS)
      .then((res)=>{
        if(res.status===200){
          setLoadingForm!==undefined && setLoadingForm(false)

          refreshApi();
          setstate((prevState)=>({...prevState,success:true,message:"Chart of Account Deleted"}))
        }
      })
      .catch((err)=>{
        setLoadingForm!==undefined && setLoadingForm(true)

        

        if (err.response.status === 500) {
          setstate((prevState)=>({...prevState,error:true,message:"Internal Server Error !!"}))
        } else {
          setstate((prevState)=>({...prevState,error:true,message:err?.response?.data}))
        }
      })
  }