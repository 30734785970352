import React from 'react'
import HomeIcon from '@mui/icons-material/Home';

import "./arrowPath.css"
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPosCat } from '../Slice/billingScreenSlice';
import { clearPathData, removePathRow } from './ArrowPathSlice';
import { getAllSubCatForBilling } from '../newBillingAPI';
function ArrowPath() {
    const dispatch=useDispatch()  
    const {pathData}=useSelector((state)=>state.arrowPathSlice)
  return (
      <div className="category-list-header">
          <div className="arrow-box justify-center">
              <div className="justify-center">
                  <HomeIcon
                  onClick={()=>{
                            dispatch(setSelectedPosCat(null))
                            dispatch(clearPathData())
                            getAllSubCatForBilling({})
                        }}
                  style={{ cursor: "pointer" }} color="#795547" fontSize={"32"} />
              </div>
              <div className="cone-shape"></div>
          </div>
          {
            pathData?.map((r,i)=>
            <div
            key={i}
            className="arrow-box arrow-para justify-center">
                <div className="justify-center">
                    <p
                    onClick={()=>{
                        dispatch(setSelectedPosCat(r?._id))
                        dispatch(removePathRow(i))
                    }}
                    >{r?.name}</p>
                </div>
                <div className="cone-shape"></div>
            </div>
            )
            }
      </div>
  )
}

export default ArrowPath