import { createSlice } from "@reduxjs/toolkit";

export const deliveryScreenSlice = createSlice({
  name: "deliveryScreenSlice",
  initialState: {
    deliveryOrderList: [],
    deliveryOrderStatus: { index: 0, status: { key: "PICKUP" } },
    selectedDeliveryOrder: null,
    deliveryStatusFilter: null,
  },

  reducers: {
    setDeliveryOrderList: (state, action) => {
      state.deliveryOrderList = action.payload;
    },
    setDeliveryOrderStatus: (state, action) => {
      state.deliveryOrderStatus = action.payload;
    },
    setSelectedDeliveryOrder: (state, action) => {
      state.selectedDeliveryOrder = action.payload;
    },
    setDeliveryStatusFilter: (state, action) => {
      state.deliveryStatusFilter = action.payload;
    },
  },
});

export const {
  setSelectedDeliveryOrder,
  setDeliveryOrderStatus,
  setDeliveryOrderList,
  setDeliveryStatusFilter
} = deliveryScreenSlice.actions;

export default deliveryScreenSlice.reducer;
