import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import colorListSlice from '../../../../../Redux/Product Master/colorListSlice'
import { useNavigate } from 'react-router-dom'
import { editBranchSettingsUserAPICall, viewBranchSettingsAPICall, viewBranchSettingsUserAPICall } from '../../../Settings/BranchSettings/branchSettingsAPI'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { vieWareHouseLocationAPICall } from '../../../Inventory/Configuration/wareHouseLocationAPI'
import { filterObjFromList } from '../../../../../Js/generalFunctions'
import { journalListApiCall } from '../../../../../API/AccountUpdated/journalAPI'
import { getAllTaxSettingsListAPICall } from '../../../../../API/Settings/TaxSettings/taxSettingsAPI'
import SuccessSnackbar from '../../../../Single Components/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../../../Single Components/SnackBars/ErrorSnackbar'
import { getPriceListApi } from '../../../SalesNew/SalesOrders/priceList/priceListAPI'
import { branchSettingsSingleView } from './BranchSettingsAPI'
import { viewAllAllowedBranchesAPICall, viewAllBranchesAPICall } from '../../../../../API/Settings/Company Settings/companySettingsAPI'

export const BranchSettingsForm = () => {
   let navigate=useNavigate()
   const userRole=useSelector((state)=>state.userRoleSlice.value)
   const branchSettingsView = useSelector(
    (state) => state.branchSettingsSliceNew.rowData
  );

  const singleRowId=localStorage.getItem("singleViewById")

  const journalList = useSelector((state) => state.journalSlice.value)
  // all allowed branches list
  const allowedBranchList = useSelector(
    (state) => state.allAllowedBranchesSlice.value
  );
   // login response
   const loginResponse = useSelector((state) => state.loginResponseSlice.value);
   const allBranchesList = useSelector((state) => state.allBranchesSlice.value);


  const wareHouseLocationList=useSelector((state)=>state.wareHouseLocationSlice.value)
  const typeCashJournalList=journalList?.filter(item=>item.type === "cash")
  const typeBankJournalList=journalList?.filter(item=>item.type ==="bank")
  const allTaxList = useSelector((state) => state.taxSettingsSlice.value);
  const allTaxListTypeSales = allTaxList?.filter((obj)=>obj?.taxType==="Sales")

  const locTypeInternal=wareHouseLocationList?.filter(item=>item?.locationType==="INTERNAL")
  const priceListData = useSelector((state) => state.priceListSlice?.priceListData);
  const types=[
    {value:"card"},
    {value:"cash"},
    {value:"upi"},
    {value:"cheque"},
  ]
  const [sequenseTable,setSequenceTable]=useState([])
  const [formValues,setFormValues]=useState({
      branch:null,
      location:null,
      priceList:null,
      defaultTax:[],
      type:null,
      journal:null
  })
  const [paymentTypeTable,setPaymentTypeTable]=useState([])
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
  });
  const [validationAlert,setValidationAlert]=useState({})

  const [taxFilteredList,setTaxFileteredList]=useState(allTaxList)
    //closeSnackbar
    const closeSnackbar = () => {
      setSnackBarStates({
        ...snackBarStates,
        success: false,
        error: false,
        message: "",
      });
    };

  const backButtonFn=()=>{
    navigate("/userdashboard/pointOfSales/configuration/BranchSettings")
  }

 




  const plusButtonFn=()=>{
    if(formValues?.type===null){
      setValidationAlert({...validationAlert,type:"Select Type"})
    }
    else if(formValues?.journal===null){
      setValidationAlert({...validationAlert,journal:"Select Journal"})
    }
    else{

      const journalId = formValues?.journal?._id;
      // Check if the attributeId already exists in the attributeTable
      const isJournalIdExist = paymentTypeTable.some(item => item.journalId === journalId);
      if(isJournalIdExist){
        setSnackBarStates({ ...snackBarStates, message: "journal " + formValues?.journal?.journalName + " already added !!", error: true })
      }
      else{

        setPaymentTypeTable([...paymentTypeTable,
          {
            type:formValues?.type?.value,
            journalName:formValues?.journal?.journalName,
            journalId:formValues?.journal?._id,
            
          }
        ])
        setFormValues({...formValues,type:null,journal:null})
      }
    }
   
  }
  const tableSingleEdit=(data,index)=>()=>{
  paymentTypeTable?.splice(index,1)
   setFormValues({
    ...formValues,
    type:filterObjFromList("value",types,"type",data),
    journal:filterObjFromList("_id",journalList,"journalId",data)
   })
  }

  

 
  const sequenceChange=(index,key)=>(e)=>{
    if (key==="prefix") {
      sequenseTable[index].prefix=e.target.value
    }
    if(key==="sequenceSize"){
      sequenseTable[index].sequenceSize=Number(e.target.value)||""
    }
    if(key==="nextNumber"){
      sequenseTable[index].nextNumber=Number(e.target.value)||""
    }
 
   
  }
  const bodyToApi={
    _id:branchSettingsView?._id,
    branchId:formValues?.branch?._id,
    locationId:formValues?.location?._id,
    priceList:formValues?.priceList?._id,
    defaultTax:formValues?.defaultTax,
    paymentTypes:paymentTypeTable,
    sequences:sequenseTable
  }
  const branchSettingsEditFunction=()=>{
    editBranchSettingsUserAPICall(
      bodyToApi,
      setSnackBarStates,
      snackBarStates
      )
  }

  const defaultTaxChange = (e, newValue) => {
    const addedItems = newValue.filter(item => !formValues.defaultTax.some(obj => obj?._id === item?._id));
    const removedItems = formValues.defaultTax.filter(item => !newValue.some(obj => obj?._id === item?._id));

    if (addedItems.length > 0 || removedItems.length > 0) {
        const updatedDefaultTax = newValue.filter(item => !removedItems.includes(item));
        setFormValues({ ...formValues, defaultTax: updatedDefaultTax });
    }
};

  useEffect(() => {
    if (branchSettingsView !== undefined) {
      setSequenceTable(branchSettingsView?.sequences?.map((r, i) => {
        return {
          ...r
        }
      }))
      setFormValues({
        ...formValues,
        branch: filterObjFromList("_id", allBranchesList, "branchId", branchSettingsView),
        location: filterObjFromList("_id", wareHouseLocationList, "locationId", branchSettingsView),
        priceList: filterObjFromList("_id", priceListData, "priceList", branchSettingsView),
        defaultTax: branchSettingsView?.defaultTax
      })
      setPaymentTypeTable(branchSettingsView?.paymentTypes?.map((r, i) => {
        return {
          ...r
        }
      }))
    }
  }, [branchSettingsView, allBranchesList, wareHouseLocationList])

  useEffect(() => {
    // viewBranchSettingsUserAPICall({ branchId:loginResponse?.data?.branchId})
    vieWareHouseLocationAPICall()
    journalListApiCall()
    getAllTaxSettingsListAPICall();
    getPriceListApi()
    branchSettingsSingleView({ _id: singleRowId })
    viewAllAllowedBranchesAPICall({ employeeId: loginResponse?.data?._id });
    viewAllBranchesAPICall()
  }, [])


  useEffect(()=>{
    if(formValues?.defaultTax.length===1){
      let inclusive=formValues?.defaultTax[0].includedPrice
      setTaxFileteredList(allTaxListTypeSales?.filter((obj)=>obj.includedPrice === inclusive))
    }else if(formValues?.defaultTax.length===0){
      setTaxFileteredList(allTaxListTypeSales)
    }
  },[formValues?.defaultTax])
  return (
    <div className="global-page-parent-container">
      <div  className="new-global-white-bg-container">
        <div className="new-global-white-bg-icon-container">
          <div className="new-global-white-bg-icon-container-left">
          <Tooltip title="Back" onClick={backButtonFn}>
              <IconButton>
                <i class="bi bi-chevron-left"></i>
              </IconButton>
            </Tooltip>
              <Tooltip title="Save">
                <IconButton
                  onClick={branchSettingsEditFunction}
                >
                <i class="bi bi-floppy"></i>
                {/* <i class="bi bi-check2-square edit-icon"></i> */}
                </IconButton>
              </Tooltip>
       
           
        
         
        
           

          
          </div>
        </div>
        <hr className="global-hr" />
        <div className="new-global-input-container" >
          <div className="new-global-single-input" >
                {/* <TextField 
              id="outlined-basic" 
              label="Branch*" 
              variant="outlined" 
              type="text" focused 
              value={formValues?.branch}
              
              /> */}
                <Autocomplete
              disablePortal
              id="combo-box-demo"
               options={allBranchesList||[]}
              getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Branch*" focused />
              )}
              value={formValues?.branch}
              onChange={(e,newValue)=>setFormValues({...formValues,branch:newValue})}
              disabled
             />

            <p className="doc-validation-alert"></p>
          </div>
          <div className="new-global-single-input" >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={locTypeInternal||[]}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Location*" focused />
              )}
              value={formValues?.location}
              onChange={(e,newValue)=>setFormValues({...formValues,location:newValue})}
             />
            <p className="doc-validation-alert"></p>
          </div>
         
          <div className="new-global-single-input auto-complete-new" >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={priceListData||[]}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Price List*" focused />
              )}
              value={formValues?.priceList}
              onChange={(e,newValue)=>setFormValues({...formValues,priceList:newValue})}
             />
            <p className="doc-validation-alert"></p>
          </div>
          <div className="new-global-single-input auto-complete-new">
           <Autocomplete
              multiple
              disablePortal
              id="combo-box-demo"
              options={taxFilteredList||[]}
              getOptionLabel={(option) => option?.taxName}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Default Tax*" focused />
              )}
              value={formValues?.defaultTax}
              onChange={defaultTaxChange}
             />
            <p className="doc-validation-alert"></p>
           </div>
           
        </div>
      </div>
        <div className='new-global-white-bg-container'>
          <h4 style={{margin:"0"}}>Sequence</h4>
            {
              sequenseTable?.map((r,i)=>(
                <>
                  <h5 key={i+1} style={{margin:"1% 2%"}}>{r?.sequenceName}</h5>
                 <div key={i} style={{margin:"0% 1%"}} className="new-global-input-container">
                   <div className='new-global-single-input'>
                        <TextField 
                         id="outlined-basic" 
                         label="Prefix*" 
                         variant="outlined" 
                         type="text" focused 
                        //  disabled={r?.editable===false}
                         value={r?.prefix}
                         onChange={sequenceChange(i,"prefix")}

                         />
                  </div>
                  <div className='new-global-single-input'>
                        <TextField 
                         id="outlined-basic" 
                         label="Sequence Size*" 
                         variant="outlined" 
                         type="text" focused 
                        //  disabled={r?.editable===false}
                         value={r?.sequenceSize}
                         onChange={sequenceChange(i,"sequenceSize")}
                         />
                  </div>
                  <div className='new-global-single-input'>
                        <TextField 
                         id="outlined-basic" 
                         label="Next No*" 
                         variant="outlined" 
                         type="text" focused 
                        //  disabled={r?.editable===false}
                         value={r?.nextNumber}
                         onChange={sequenceChange(i,"nextNumber")}
                         />
                  </div>
                  <div className='new-global-single-input'>
                        <TextField 
                         id="outlined-basic" 
                         label="Sequence*" 
                         variant="outlined" 
                        //  disabled={r?.editable===false}
                         type="text" focused 
                         value={r?.SN}
                         />
                  </div>
            </div> 
            </>
              ))
            }
             
           
        </div>
        <div className='new-global-white-bg-container'>
        <div className="new-global-table-container">
           <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"50%",margin:"2%"}}>
            <div className="new-global-single-input auto-complete-new" style={{width:"43%"}}>
         
               <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={types||[]}
              getOptionLabel={(option) => option?.value}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Type*" focused />
              )}
              value={formValues?.type}
              onChange={(e,newValue)=>{
                                      setFormValues({...formValues,type:newValue,journal:null})
                                      setValidationAlert({})
                                    }}

             />
            <p className="doc-validation-alert">{validationAlert?.type}</p>
            </div>
            <div className="new-global-single-input auto-complete-new" style={{width:"43%"}}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={
                formValues?.type?.value==="cash"?
                typeCashJournalList
                :formValues?.type===null?
                []
                :typeBankJournalList
                ||[]}
              getOptionLabel={(option) => option?.journalName}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Journal*" focused />
              )}
              value={formValues?.journal}
              onChange={(e,newValue)=>{
                                        setFormValues({...formValues,journal:newValue}) 
                                        setValidationAlert({})
                                      }}

             /> 
             <p className="doc-validation-alert">{validationAlert?.journal}</p>
            </div> 
            <button 
             onClick={plusButtonFn}
             style={{
                backgroundColor:"#08b908",
                border:"none",
                width:"30px",
                height:"30px",
                borderRadius:"4px",
                color:"#fff",
                fontSize:'1.3rem',
                cursor:"pointer"
                }}>
            +
            </button> 
          </div>  
         
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Journal</th>
              </tr>
            </thead>
            <tbody>
               {
                paymentTypeTable?.length !==0 ? paymentTypeTable?.map((r,i)=>(
                  <tr key={i} onClick={tableSingleEdit(r,i)}>
                     <td>{r?.type}</td>
                     <td>{r?.journalName}</td>
                  </tr>
                ))
                :
                <tr>
                  <td colSpan={2}>No Data</td>
                </tr>
               }
                
           
            </tbody>
          </table>
        </div>
        </div>
      
        <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
    </div>
  )
}
