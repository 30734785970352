import React from 'react'
import { useNavigate } from 'react-router-dom';
import { InternalTransferFilterAPICall, deleteInternalTransferAPICall, viewInternalTransferAPICall } from './InternalTransferAPI';
import { get_internal_transfer_single_view } from './InternalTransferSlice';
import store from '../../../../../Redux/store';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import ErrorSnackbar from '../../../../Single Components/SnackBars/ErrorSnackbar';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../../Single Components/CustomPagination';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import ReactToPrint from 'react-to-print';
import ExcelJS from "exceljs"
import TableListActionButtons from '../../../../Single Components/ActionButtons/TableListActionButtons';

export const InternalTransfer = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  let navigate = useNavigate();
  const internalTransferList = useSelector((state) => state.internalTransferSlice.value)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = internalTransferList?.pages;
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false
  });
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [filteredData, setFilteredData] = useState({});
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };

  const createInternalTransfer = () => {
    navigate("/userdashboard/inventory/operation/InternalTransfer/create")
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  useEffect(() => {
    // viewInternalTransferAPICall(setIsLoading)
    localStorage.removeItem("singleViewById")
    store.dispatch(get_internal_transfer_single_view({ singleInternalTransferData: undefined }))
  }, [])

  const deleteJournalEntryClick = (id) => () => {
    deleteInternalTransferAPICall({ id })
  }
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);

  };
  const deletePostedJournal = () => {
    setSnackBarStates({ ...snackBarStates, message: "posted inventory Adjustment entrie can't be deleted!! ", error: true })
  }
  const formatFilterInfo = (filters) => {
    const parts = [];
  
    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    }
   
    if (filters?.status) {
      parts.push(`Status: ${filters?.status}`);
    }
    if (filters?.fromBranch) {
      parts.push(`from Branch: ${filters?.fromBranch?.name}`);
    }
    if (filters?.destinationBranch) {
      parts.push(`destination Branch: ${filters?.destinationBranch?.name}`);
    }
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Internal Transfer");
    sheet.properties.defaultRowHeight = 20;
  
    // Add header section
    const titleRow = sheet.addRow(["Internal Transfer"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells('A1:F1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];
  
    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:F${index + 2}`);
        row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      });
    }
  
    // Add an empty row between header and table
    sheet.addRow([]);
  
    // Header row styling
    const headerRow = sheet.addRow(["Name", "From","To","Contact","Schedule Date","Status"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };
  
    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    })
  
    sheet.columns = [
      { header: "Name", key: "NAME", width: 8 },
      { header: "From", key: "sourcename", width: 15 },
      { header: "To", key: "destinationname", width: 25 },
      { header: "Contact", key: "contactPerson", width: 15 },
      { header: "Schedule Date", key: "date", width: 25 },
      { header: "Internal Transfer", key: "status", width: 15 },
  
    ];
  
    internalTransferList?.list?.forEach((item,index) => {
      const row = sheet.addRow({
        NAME:item?.NAME,
        sourcename: item.sourcename,
        destinationname: item?.destinationname,
        contactPerson: item?.contactPerson,
        date: item?.date,
        status: item?.status,
      
      });
  
      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };
      
      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
        //   top: { style: "thin", color: { argb: "FF000000" } },
        //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
        //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) { // Right align all columns except the first
            cell.alignment = { horizontal: 'center' };
          }
         
      });
    });
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Internal Transfer.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const singleViewFn = (data) => {
    store.dispatch(setFilterActive(window.location.pathname))
    navigate("/userdashboard/inventory/operation/InternalTransfer/create")
    localStorage.setItem("singleViewById", data?._id)
    // store.dispatch(get_internal_transfer_single_view({ singleInternalTransferData: data }))
  }
  useEffect(() => {
    if (FormDataInfo.length !== 0) {
      let loadData = window.location.pathname === filtereDataOptions?.filterActive
      !loadData &&
        InternalTransferFilterAPICall({
          // fromDate: `${FormDataInfo.fromDate} ${getCurrentTime()}`,
          // toDate: `${FormDataInfo.toDate} ${getCurrentTime()}`,
          branchList: FormDataInfo.branchList,
          source: FormDataInfo.fromBranch,
          destination: FormDataInfo.destinationBranch,
          status: FormDataInfo.status,
          search: FormDataInfo.search,
          index: currentPage - 1,
        }, setIsLoading);
    }
  }, [FormDataInfo, currentPage]);

  return (
    <div className='global-page-parent-container updated-global-page-parent-container'>
      <div className="justify-space-between list-create-head-bar">
        <h3>Internal Transfer</h3>
        <div className="create-head-buttons-container">
          <TableListActionButtons downloadClick={downloadList}/>
          <CategorySearchandFilter
              statusOptions={['Completed', 'Drafted', 'Cancel']}
              onData={handleDataFromChild}
              isStatus={true}
              isFromBranch={true}
              isDestinationBranch={true}
              isBranch={false}
              isDate={false}
              onBranchSelect={handleBranchSelect}
              onFilterChange={handleFilterChange}
            />
            <button onClick={createInternalTransfer} className='btn btn-primary create-button-blue'>Create</button>
        </div>

      </div>
        <div className="justify-space-between pagination-container">
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
         </div>
        <div className='new-global-table-container updated-table-container'>
          <table>
            <thead>
              <tr>

                <th>Name</th>
                <th>From</th>
                <th>To</th>
                <th>Contact</th>
                <th>Schedule Date</th>
                <th>Status</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody >
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) :
                (internalTransferList !== undefined && internalTransferList?.list?.length !== 0) ? internalTransferList?.list?.slice(0)?.map((r, i) => (
                  <tr>

                    <td onClick={() => singleViewFn(r)}>{r?.NAME}</td>
                    <td onClick={() => singleViewFn(r)}>{r?.sourcename}</td>
                    <td onClick={() => singleViewFn(r)}>{r?.destinationname}</td>
                    <td onClick={() => singleViewFn(r)}>{r?.contactPerson}</td>
                    <td onClick={() => singleViewFn(r)}>{r?.date}</td>
                    <td className='table-data-box' onClick={() => singleViewFn(r)}>
                      {r?.status === "Completed" ? (
                        <span className='post'> POST</span>
                      ) : r?.status === "Drafted" ? (
                        <span className='draft'> DRAFT</span>
                      ) : r?.status === "Cancel" ? (
                        <span className='cancel'> CANCEL</span>
                      ) : (
                        "---"
                      )}
                    </td>

                    {/* <td><span className='status-pending' style={{borderRadius:"4px",backgroundColor:"rgb(91 241 161)",margin:"2% 0"}}>{r?.status}</span></td> */}
                    <td>
                      <IconButton
                        onClick={r?.status !== "Cancel" ? deletePostedJournal : deleteJournalEntryClick(r?._id)}
                      >
                        <i className={`bi bi-trash3 ${r?.status !== "Cancel"
                          ? "edit-icon-disabled"
                          : "delete-icon"
                          }`}></i>
                      </IconButton>
                    </td>
                  </tr>
                ))
                  :
                  <tr>
                    <td colSpan={7}>No Data</td>
                  </tr>
              }

            </tbody>
          </table>
        </div>

      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
    </div>
  )
}
