import { createSlice } from "@reduxjs/toolkit";

export const posShiftSummaryReportSlice=createSlice({
    name:"posShiftSummaryReportSlice",
    initialState:{
        reportsList:[]
    },
    reducers:{
        setShiftSummaryReportList:(state,action)=>{
            state.reportsList=action.payload
        }
    }
})

export const{setShiftSummaryReportList}=posShiftSummaryReportSlice.actions

export default posShiftSummaryReportSlice.reducer