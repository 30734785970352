import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { PurchaseReportNewAPI } from './purchaseReportNewAPI';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';
import Template from "./PurchaseReportPrint"
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../../Redux/store';
import { get_purchase_report_single_list } from './purchaseReportNewSlice';
import ExcelJS from "exceljs"
import TableListActionButtons from '../../../../Single Components/ActionButtons/TableListActionButtons';

const PurchaseReportNew = () => {
    let navigate = useNavigate();
    const [symbol, setSymbol] = useState(null);
    const [FormDataInfo, setFormDataInfo] = useState([])
    let paymentsPrint = useRef();

    const {currencyDetails,tableLoading} = useSelector((state) => state.mainDashBoardSlice)
    const filtereDataOptions = useSelector((state) => state.listCategory);
    const [filteredData, setFilteredData] = useState({});

    const decimalPosition = localStorage.getItem("decimalPosition");
    const handleFilterChange = (updatedItems) => {
        setFilteredData(updatedItems);
      };

    const purchaseList = useSelector((state => state?.purchaseRport?.purhaseReportNwList))
    const formatFilterInfo = (filters) => {
        const parts = [];
      
        if (filters?.fromDate && filters?.toDate) {
          parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
        }
        console.log(filters?.fromDate);
    
        if (filters?.branchList) {
          parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
        }
       
        
        return parts; // Return the array directly
      };
    const downloadList = () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("purchase report");
        sheet.properties.defaultRowHeight = 20;
      
        // Add header section
        const titleRow = sheet.addRow(["purchase report"]);
        titleRow.font = { size: 18, bold: true };
        sheet.mergeCells('A1:G1');
        titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      
        // Add additional header information
        const filterParts = formatFilterInfo(filteredData) || [];
      
        // Ensure filterParts is an array and iterate over it
        if (Array.isArray(filterParts)) {
          filterParts.forEach((part, index) => {
            const row = sheet.addRow([part]);
            row.font = { size: 15, bold: true };
            sheet.mergeCells(`A${index + 2}:G${index + 2}`);
            row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
          });
        }
      
        // Add an empty row between header and table
        sheet.addRow([]);
      
        // Header row styling
        const headerRow = sheet.addRow(["Item", "UOM", "Purchase Qty", "Purchase Return Qty","Total Quatity","Average Unit Cost","Average Total Value"]);
        const headerFill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
        };
        const headerFont = {
          size: 15,
          bold: true,
          color: { argb: "FFFFFFFF" }, // White font color
        };
      
        headerRow.eachCell((cell) => {
          cell.fill = headerFill;
          cell.font = headerFont;
          cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
        });
      
        sheet.columns = [
          { header: "Item", key: "itemName", width: 30 },
          { header: "UOM", key: "uom", width: 10 },
          { header: "Purchase Qty", key: "purchaseQty", width: 20 },
          { header: "Purchase Return Qty", key: "purchaseRet", width: 30 },
          { header: "Total Quantity", key: "totalQuantity", width: 20 },
          { header: "Average Unit Cost", key: "cost", width: 30 },
          { header: "Purchase Report", key: "totalValue", width: 30 },
        ];
      
        purchaseList?.forEach((item) => {
          const row = sheet.addRow({
            itemName: item?.itemName,
            uom: item?.uom,
            purchaseQty: ` ${item?.purchaseQty?.toFixed(2)}`,
            purchaseRet: ` ${item?.purchaseRet?.toFixed(2)}`,
            totalQuantity: ` ${item?.totalQuantity?.toFixed(2)}`,
            cost: ` ${item?.cost?.toFixed(2)}`,
            totalValue: ` ${item?.totalValue?.toFixed(2)}`,
          });
      
          const rowFill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFC3D4F7" }, // Use full hex code with "FF" prefix for better compatibility
          };
          
          row.eachCell((cell, colNumber) => {
            cell.fill = rowFill;
            cell.border = {
              top: { style: "thin", color: { argb: "FFFFFFFF" } },
              left: { style: "thin", color: { argb: "FFFFFFFF" } },
              bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
              right: { style: "thin", color: { argb: "FFFFFFFF" } },
            };
            if (colNumber >2) { // Right align all columns except the first
                cell.alignment = { horizontal: 'right' };
              }
          });
        });
      
        // Add row for totals
        const totalRow = sheet.addRow([
          "Total",
          "",
          `${symbol} ${purchaseList?.reduce((acc, row) => acc + row.purchaseQty, 0)?.toFixed(decimalPosition)}`,
          `${symbol} ${purchaseList?.reduce((acc, row) => acc + row.purchaseRet, 0)?.toFixed(decimalPosition)}`,
          `${symbol} ${purchaseList?.reduce((acc, row) => acc + row.totalQuantity, 0)?.toFixed(decimalPosition)}`,
          `${symbol} ${purchaseList?.reduce((acc, row) => acc + row.cost, 0)?.toFixed(decimalPosition)}`,
          `${symbol} ${purchaseList?.reduce((acc, row) => acc + row.totalValue, 0)?.toFixed(decimalPosition)}`,
         
        ]);
      
        totalRow.eachCell((cell,colNumber) => {
          cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
          cell.fill = headerFill;
          cell.border = {
            top: { style: "thin", color: { argb: "FFFFFFFF" } },
            left: { style: "thin", color: { argb: "FFFFFFFF" } },
            bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
            right: { style: "thin", color: { argb: "FFFFFFFF" } },
          };
          if (colNumber >1) { // Right align all columns except the first
            cell.alignment = { horizontal: 'right' };
          }
        });
      
        workbook.xlsx.writeBuffer().then(function (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "Purchase Report.xlsx";
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
      };

    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
    };
    const handleRow = (row) => {
        store.dispatch(get_purchase_report_single_list({purchaseReportNwSingleData:undefined}))

        store.dispatch( setFilterActive(window.location.pathname))
        localStorage.setItem("singleViewId", row._id)
        localStorage.setItem("name", row.itemName)
        localStorage.setItem("fromDate", FormDataInfo?.fromDate);
        localStorage.setItem("toDate", FormDataInfo?.toDate);
        localStorage.setItem("branch", FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0])
        navigate("/userdashboard/purchasemanager/PurchaseReport/PurchaseReportNew/SingleView")
    }

    useEffect(() => {
        setSymbol(currencyDetails?.symbol);
    }, [currencyDetails]);

    useEffect(() => {
        if (FormDataInfo?.length !== 0) {
            let body = {
                fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
                endDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
                branchId: FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0],
                search: FormDataInfo?.search,
            }

            let loadData=window.location.pathname===filtereDataOptions?.filterActive
            !loadData && PurchaseReportNewAPI(body);
        }
    }, [FormDataInfo]);


    return (
        <div className="global-page-parent-container updated-global-page-parent-container">

           <div className="justify-space-between list-create-head-bar">
                    <h3>Purchase Report</h3>
                    <div className="create-head-buttons-container">
                        <TableListActionButtons downloadClick={downloadList}/>
                        <CategorySearchandFilter
                                statusOptions={['PURCHASE ORDER', 'DONE', 'CANCEL']}
                                onData={handleDataFromChild}
                                onBranchSelect={()=>{}}
                                onFilterChange={handleFilterChange}
                            />
                    </div>
                </div>

                <div className="global-report-table-container updated-table-container" style={{height:"calc(100vh - 145px)"}}>
                    <table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th >UOM</th>

                                <th >Purchase Qty</th>
                                <th >Purchase Return Qty</th>
                                <th >Total Quatity</th>
                                <th >Average Unit Cost</th>
                                <th >Average Total Value</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                            tableLoading?
                            ([...Array(10)].map((_,i)=>(
                                <tr key={i}>
                                    <td colSpan={10}>
                                        <Skeleton
                                            variant="rectangular"
                                            animation="wave"
                                            width={"100%"}
                                            height={30}
                                        />
                                    </td>
                                </tr>
                            )))
                            :purchaseList!==undefined? purchaseList.map((item, index) => (
                                <tr style={{cursor:"pointer"}} key={index} onClick={() => handleRow(item)}>
                                    <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.itemName}</td>
                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.uom}</td>

                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.purchaseQty?.toFixed(decimalPosition)}</td>
                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.purchaseRet?.toFixed(decimalPosition)}</td>
                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalQuantity?.toFixed(decimalPosition)}</td>
                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.cost?.toFixed(decimalPosition)}</td>
                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.totalValue?.toFixed(decimalPosition)}</td>
                                </tr>
                            ))
                            :
                            <tr>
                                <td colSpan={7}>No data</td>
                            </tr>
                            }
                        </tbody>
                        {
                        purchaseList &&    
                        <tfoot>
                            <tr>
                                <th colSpan={2}>Total</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.purchaseQty, 0)?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.purchaseRet, 0)?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.totalQuantity, 0)?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.cost, 0)?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.totalValue, 0)?.toFixed(decimalPosition)}</th>
                            </tr>
                        </tfoot>}
                    </table>
                </div>

            <div style={{ display: "none" }}>
                <div ref={(el) => (paymentsPrint = el)}>

                    <Template purchaseList={purchaseList} symbol={symbol} />

                </div>
            </div>
        </div>

    )
}

export default PurchaseReportNew