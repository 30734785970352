import React from "react";
import LanguageIcon from "@mui/icons-material/Language";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
const TempFooter = ({ currentPage, totalPage, companyDetails, template }) => {
  return (
    <div
      className="invoice-footer-temp-1"
      style={{ backgroundColor: template == 1 ? "#008080" : template == 2? "#8cc63f": "#000000" }}
      // style={{ backgroundColor: "transparent" }}
    >
      <p>{`PAGE ${currentPage || ""}/ ${totalPage || ""}`}</p>
      <div className="footer-contact-temp-1">
        <p>
          <PhoneIphoneIcon />
          {companyDetails?.phoneNumber || "+91 5454545455"}
        </p>
        <p>
          <EmailIcon />
          {companyDetails?.email || "sample@gami.com"}
        </p>
        <p>
          <LanguageIcon />
          {companyDetails?.website || "www.sample.com"}
        </p>
      </div>
    </div>
  );
};

export default TempFooter;
