import React, { useState } from 'react'
import { useSelector } from 'react-redux';

const DiscountSummaryPrint = () => {
    const decimalPosition = localStorage.getItem("decimalPosition");
    const ShiftDiscountSummary=useSelector((state)=>state.posReportshiftSlice.discountSummary)

    const {showSubHeaders} = useSelector((state) => state.posReportshiftSlice);

  return (
    <div
    className="new-global-table-container shift-report-print-container" >
    <div className="bill-main-head">
      <h5>Bill Type</h5>
      <h5>Amount</h5>
    </div>
    <div className="bill-sub-head">
      <h5>Dine In</h5>
      <h5>{(ShiftDiscountSummary?.dineIn?.discount || 0)?.toFixed(decimalPosition)}</h5>
    </div>
    {showSubHeaders&&(

   
    <table>
      <thead>
        <tr>
          <th>Order No</th>
          <th>Date</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
            {ShiftDiscountSummary?.dineIn?.data!==undefined? ShiftDiscountSummary?.dineIn?.data?.map((r, i) => (
                <tr key={i} >
                   
                    <td>{r.name}</td>
                    <td>{r.orderDate}</td>
                    <td>{r.discount?.toFixed(decimalPosition)}</td>
                </tr>
            )):(
                <tr>
                    <td colSpan={8}>
                        NO DATA
                    </td>
                </tr>
            )}
        </tbody>
     
    </table>
     )}
    <div className="bill-sub-head">
      <h5>Take Away</h5>
      <h5>{(ShiftDiscountSummary?.takeAway?.discount || 0)?.toFixed(decimalPosition)}</h5>
    </div>
    {showSubHeaders&&(

   
    <table>
      <thead>
        <tr>
          <th>Order No</th>
          <th>Date</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
            {ShiftDiscountSummary?.takeAway?.data!==undefined? ShiftDiscountSummary?.takeAway?.data?.map((r, i) => (
                <tr key={i} >
                   
                    <td>{r.name}</td>
                    <td>{r.orderDate}</td>
                    <td>{r.discount?.toFixed(decimalPosition)}</td>
                </tr>
            )):(
                <tr>
                    <td colSpan={8}>
                        NO DATA
                    </td>
                </tr>
            )}
        </tbody>
    </table>
     )}
    <div className="bill-sub-head">
      <h5>Delivery</h5>
      <h5>{(ShiftDiscountSummary?.delivery?.discount || 0)?.toFixed(decimalPosition)}</h5>
    </div>
     {showSubHeaders&&(
  
    <table>
      <thead>
        <tr>
          <th>Order No</th>
          <th>Date</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
            {ShiftDiscountSummary?.delivery?.data!==undefined? ShiftDiscountSummary?.delivery?.data?.map((r, i) => (
                <tr key={i} >
                   
                    <td>{r.name}</td>
                    <td>{r.orderDate}</td>
                    <td>{r.discount?.toFixed(decimalPosition)}</td>
                </tr>
            )):(
                <tr>
                    <td colSpan={8}>
                        NO DATA
                    </td>
                </tr>
            )}
        </tbody>
    </table>
     )}
    
  </div>
  )
}

export default DiscountSummaryPrint