import React from 'react'
import access from "../../Assets/GIF/mzA7j46alI.gif"

export const AccessDenied = () => {
  return (
    <div style={{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        margin:"1%",
        height:"90vh",
        backgroundColor:"#fff"


    }}>
        <img src={access} alt="" />
        <p style={{color:"#F20000",fontSize:"1rem",fontWeight:"600"}}>Access Denied</p>
    </div>
  )
}
