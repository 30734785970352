import React, { useEffect } from 'react'
import "./aggregator.css"
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, Skeleton } from '@mui/material'
import { listAggregatorsAPI } from './aggregatorAPI'
import AddAggregator from './AddAggregator'
import { setAggregatorDialog, setAggregatorSingleRow } from './aggregatorSlice'
import { viewAllAllowedBranchesAPICall, viewAllBranchesAPICall } from '../../../../../API/Settings/Company Settings/companySettingsAPI'
function Aggregator() {

    const dispatch=useDispatch()
    const decimalPosition=localStorage.getItem("decimalPosition")
    const userInfo=useSelector((state)=>state.loginResponseSlice.value)
    const userRole = useSelector((state) => state.userRoleSlice.value);


    const {aggregatorList,dialogBox}=useSelector((state)=>state.aggregatorSlice)
    
    const {tableLoading}=useSelector((state)=>state.viewOrdersBillingSlice)
    const createBtnClicked=()=>{
        dispatch(setAggregatorSingleRow(null))
        dispatch(setAggregatorDialog("add"))
    }
    
    const singleRowClick=(row)=>()=>{
        dispatch(setAggregatorSingleRow(row))
        dispatch(setAggregatorDialog("edit"))
    }

    const handleClose=()=>{
        dispatch(setAggregatorDialog(null))
        dispatch(setAggregatorSingleRow(null))
    }
    useEffect(()=>{
        viewAllBranchesAPICall()
        viewAllAllowedBranchesAPICall({employeeId: userInfo?.data?._id })
    },[])
    useEffect(()=>{
        dialogBox===null && listAggregatorsAPI({branchId:userRole==="user"?userInfo?.branchPk:undefined})
    },[dialogBox])
  return (
    <div className='global-page-parent-container aggregator-main-ctn'>
        <div
        style={{marginBottom:"0px"}}
        className="global-white-bg-container special-items-head justify-space-between">
            <h3>Aggregator</h3>
            <button
            onClick={createBtnClicked}
            className="create-button-blue">Create</button>
        </div>

        <div className="global-product-table special-items-table" style={{maxHeight:"83vh"}}>
      <table style={{ borderBottom: "2px solid #dee3f8" }}>
        <thead>
          <tr>
            <th>Aggregator Name</th>
            <th>Branch</th>
            <th>Commission Type</th>
            <th>Commission</th>
          </tr>
        </thead>
        <tbody>
          {
             tableLoading?
             (
               [...Array(10)].map((r, i) => (
                 <tr key={i}>
                   <td colSpan={5}>
                     <Skeleton
                       variant="rectangular"
                       animation="wave"
                       width={"100%"}
                       height={40}
                     />
                   </td>
                 </tr>
               ))
             ):
            aggregatorList.length !== 0 ?
            aggregatorList.slice(0).reverse().map((r, index) => (
                <tr 
                onClick={singleRowClick(r)}
                title={`Edit ${r?.name}`}
                key={index}>
                  <td>{r?.name}</td>
                  <td>{r?.branchName || "---"}</td>
                  <td>{r?.commType}</td>
                  <td>{r?.amount?.toFixed(decimalPosition)}</td>
                </tr>
              ))
              :
              <tr>
                <td colSpan={4}>
                    <div className="center-div" style={{ height: "15vh", backgroundColor: "#fff" }}>
                        No Data
                    </div>
                </td>
              </tr>
          }
        </tbody>
      </table>
    </div>

    <Dialog open={dialogBox!==null} onClose={handleClose}  maxWidth="lg">
        <AddAggregator/>
    </Dialog>
    </div>
  )
}

export default Aggregator