import { IconButton } from "@mui/material";
import React from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const AddButton = ({ keyName,onClick, color, size, fontSize, padding }) => {
  return (
    <IconButton
      onClick={() => onClick(keyName)}
      size={size || "small"}
      
      style={{
        fontSize: fontSize || "5px",
        padding: padding || "2px",
        color:color || "default"
      }}
    >
      <AddCircleIcon />
    </IconButton>
  );
};

export default AddButton;
