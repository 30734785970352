import React from 'react'
import "./advncShiftReportPrint.css"
import { useSelector } from 'react-redux'
import { convertToAMPM } from '../../../../../Js/Date'
const AdvncShiftReportHeader = () => {

  const companyProfile=useSelector((state)=>state.companyProfileSlice?.userValue)
  const {shiftReportDetails} = useSelector((state) => state.posReportshiftSlice);
  return (
    <div className='flex-column  shift-report-print-header'>
        <div className='flex-column justify-center'>
            <img src={companyProfile?.logo} alt="" />
            <p className="head">{companyProfile?.companyName}</p>
            <p className="address">
                {companyProfile?.companyAddress}
                <br />
                {companyProfile?.country}
                <br />
                {companyProfile?.companyEmail}
                <br />
                {companyProfile?.contactNumber}
                </p>
        </div>

        <div className="flex-colum shift-details">
            <p>SHIFT &nbsp;&nbsp;: {`${shiftReportDetails?.shiftId}-${shiftReportDetails?.employeeName}`}</p>
            <p>START : {`${shiftReportDetails?.startDate} - ${convertToAMPM(shiftReportDetails?.startTime)}`}</p>
            <p>END &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {shiftReportDetails?.endDate? `${shiftReportDetails?.endDate} - ${convertToAMPM(shiftReportDetails?.endTime)}`:"Active Shift"}</p>
        </div>
    </div>
  )
}

export default AdvncShiftReportHeader