import React from 'react'
import AddButtonWithLabel from '../../../Single Components/Buttons/AddButtonWithLabel'
import { useDispatch, useSelector } from 'react-redux';
import { set_product_dialog_open } from './inventoryProductsSlice';
import { Dialog } from '@mui/material';
import { CreateProductList } from './ProductListUpdated/CreateProductList';

const ProductCreatePopup = (props) => {
    const {style}=props;
    const dispatch=useDispatch()

    const { addProductDialog }=useSelector((state)=>state.inventoryProductSlice)
  return (
    <div style={style}>
        <AddButtonWithLabel
         onClick={()=>dispatch(set_product_dialog_open(true))}
        text={"Product"}/>

        <Dialog 
          open={addProductDialog} 
            onClose={()=>dispatch(set_product_dialog_open(false))}
            maxWidth='xl'
            fullWidth
          >
            <CreateProductList/>
        </Dialog>
    </div>
  )
}

export default ProductCreatePopup