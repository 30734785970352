import React from "react";
import "./preparingOrderStyle.css";

const PreparingOrderCard = ({data}) => {
  return (
    <div className="preparing-card-container">
      <div className="order-status-type-container">
        <h3 className="token-display-status-header">PREPARING</h3>
      </div>
      <div className="token-status-card-body">
        <ul>
        {data?.map((res, i) => (
              <li key={i}>
                <div className="token-number-box">{res}</div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default PreparingOrderCard;
