import React, { useEffect, useState } from "react";
import "./posReceiptStyle.css";
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
} from "@mui/material";

import resturnt1 from "../../../../Assets/Images/Restaurent Reciept – 1.png";
import resturnt2 from "../../../../Assets/Images/Restaurent Reciept – 2.png";
import resturntArabic from "../../../../Assets/Images/Restaurent RecieptArabic.png";
import CancelIcon from "@mui/icons-material/Cancel";
import { getTemplateInfoAPI, setPosReceiptTemplateAPI } from "./posReceiptApi";
import { useSelector } from "react-redux";

const PosReceipt = () => {
  const { posTemplateSelected } = useSelector((state) => state.posReceiptSlice);

  const posReceiptModel = [
    {
      id: 1,
      label: "TEMPLATE 1",
      tempImgSrc: resturnt1,
    },
    {
      id: 2,
      label: "TEMPLATE 2",
      tempImgSrc: resturnt2,
    },
    {
      id: 3,
      label: "TEMPLATE 3",
      tempImgSrc: resturntArabic,
    },
  ];

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleCheckboxChange = (id, index, templateName) => {
    setSelectedTemplate({ id, index, templateName });
    const body = {
      posReceiptTemplate: id,
    };
    if (id) {
      setPosReceiptTemplateAPI({ body });
    }
  };

  const handleImageClick = (tempImgSrc) => {
    setSelectedTemplate(tempImgSrc);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (posTemplateSelected) {
    setSelectedTemplate({ id: posTemplateSelected.posReceiptTemplate });
    }else{
    getTemplateInfoAPI();
    }
  }, [posTemplateSelected]);

  return (
    <div className="pos-receipt-model-selection-container">
      {posReceiptModel.map((res, i) => (
        <div key={res.id} className="pos-receipt-model-card">
          <div className="pos-receipt-model-header">
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedTemplate?.id === res.id}
                  onChange={() => handleCheckboxChange(res.id, i, res.label)}
                />
              }
              label={res.label}
            />
          </div>
          <div className="pos-receipt-model-body">
            <div className="pos-receipt-model-image">
              <img
                src={res.tempImgSrc}
                alt="template"
                onClick={() => handleImageClick(res.tempImgSrc)}
              />
            </div>
          </div>
        </div>
      ))}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle textAlign={"center"} fontSize={13}>
          Template Preview
          <IconButton
            onClick={handleCloseDialog}
            style={{ position: "absolute", top: 0, right: 0 }}
            aria-label="Example"
          >
            <CancelIcon color="error" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img src={selectedTemplate} alt="Preview" style={{ width: "100%" }} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PosReceipt;
