import axios from "axios"
import { HEADERS } from "../../../../API/UrlAndPaths"
import store from "../../../../Redux/store"
import { get_aggregator_report,
     get_cash_card_report,
      get_sales_details_list,
       get_shift_report_list,
        get_shift_report_payment,
         get_shift_report_payment_summary,
          get_shift_report_sale,
           get_shift_report_wallet_pay,
    get_bill_type_summary_report,
     get_delivery_partner_summary_report,
     get_delivery_boys_summary_report,
     get_discount_summary_report,
     get_category_summary_report,
     get_shift_details_report
 } from "./ReportSlice"
import { setSubmitLoading } from "../../../../Redux/Dashboard/mainDashBoardSlice"
import { errorSnackMsg } from "../../../Custom Hooks/SnackkBarUtilities"

//list of shift//
export const ListPosShifReportAPI = async (body) => {
    await axios.post("pos/shiftReportProducts", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch(get_shift_report_list(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(get_shift_report_list(undefined))


        })
}
//list of shiftSales//
export const ListPosShifReportSalesAPI = async (body,setShiftDetails) => {
    await axios.post("pos/shiftReportSale", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch(get_shift_report_sale(res.data))
                setShiftDetails(res.data)
                

            }
        })
        .catch((err) => {
            store.dispatch(get_shift_report_sale(undefined))


        })
}
//list of shiftWallet and payment//
export const ListPosShifReportWalletAPI = async (body) => {
    try {
        const response = await axios.post("pos/shiftReportPaymentWallet", body, HEADERS);
        if (body.isWallet === true) {
            store.dispatch(get_shift_report_wallet_pay(response.data));
        } else {
            store.dispatch(get_shift_report_payment(response.data));
        }
    } catch (error) {
        if (body.isWallet === true) {
            store.dispatch(get_shift_report_wallet_pay(undefined));
        } else {
            store.dispatch(get_shift_report_payment(undefined));
        }
    }
}

//list of payment summary//
export const ListPosPaymentSummaryAPI = async (body) => {
    await axios.post("pos/shiftReportPaymentSummary", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch( get_shift_report_payment_summary(res.data))

            }
        })
        .catch((err) => {
            store.dispatch( get_shift_report_payment_summary(undefined))


        })
}
//list of sales details//
export const ListPosSalesDetailsAPI = async (body) => {
    store.dispatch(setSubmitLoading(true))

    await axios.post("foodManagement/viewsalesdetailsreport", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(setSubmitLoading(false))

                store.dispatch(get_sales_details_list(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))

            store.dispatch(get_sales_details_list(undefined))


        })
}
//list of cash & card Summary//
export const ListCashAndCardSummaryAPI = async (body) => {
    store.dispatch(setSubmitLoading(true))

    await axios.post("foodManagement/cashcardreport", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(setSubmitLoading(false))

                store.dispatch( get_cash_card_report(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))

            store.dispatch( get_cash_card_report(undefined))


        })
}

export const ListAggregatorAPI = async (body) => {
    store.dispatch(setSubmitLoading(true))
    await axios.post("pos/aggregatorReport", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(setSubmitLoading(false))
                store.dispatch( get_aggregator_report(res.data))
            }
        })
        .catch((err) => {
            errorSnackMsg(err.response.data ||"Network error")
            store.dispatch( get_aggregator_report(undefined))
            store.dispatch(setSubmitLoading(false))
        })
}
//get advanced shift report bill type summary //
export const ListPosShifReportBillTypeSummary = async (body) => {
    await axios.post("pos/shiftReportBillType", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_bill_type_summary_report(res.data))
            }
        })
        .catch((err) => {
            store.dispatch(get_bill_type_summary_report(undefined))
        })
}
//get advanced shift report delivery partner summary //
export const ListPosShifReportDelePartnerSummary = async (body) => {
    await axios.post("pos/shiftReportDeliveryPartner", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_delivery_partner_summary_report(res.data))
            }
        })
        .catch((err) => {
            store.dispatch(get_delivery_partner_summary_report(undefined))
        })
}
export const ListPosShifReportDeleBoysSummary = async (body) => {
    await axios.post("pos/shiftReporDeliveryBoy", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_delivery_boys_summary_report(res.data))
            }
        })
        .catch((err) => {
            store.dispatch(get_delivery_boys_summary_report(undefined))
        })
}
export const ListPosShifReportDiscountSummary = async (body) => {
    await axios.post("pos/shiftReportDiscount", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_discount_summary_report(res.data))
            }
        })
        .catch((err) => {
            store.dispatch(get_discount_summary_report(undefined))
        })
}
export const ListPosShifReportCategorySalesSummary = async (body) => {
    await axios.post("pos/shiftReportCategory", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_category_summary_report(res.data))
            }
        })
        .catch((err) => {
            store.dispatch(get_category_summary_report(undefined))
        })
}
export const ListPosShifReportDetails = async (body) => {
    await axios.post("pos/shiftDetails", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_shift_details_report(res.data))
            }
        })
        .catch((err) => {
            store.dispatch(get_shift_details_report(undefined))
        })
}