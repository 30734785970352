import React, { useState } from "react";
import "../../../css/Settings/settings.css";
import { CompanySettings } from "../Settings/CompanySettings/CompanySettings";
import { Shift } from "../Settings/Shift/Shift";
import { useSelector } from "react-redux";
import { ShiftReopen } from "../Settings/Shift/ShiftReopen";
import { ShiftEnd } from "../Settings/Shift/ShiftEnd";
import { SmsSettings } from "../Settings/SMS Settings/SmsSettings";
import { Discount } from "../Settings/Discount/Discount";
import { Utility } from "../Settings/Utility/Utility";
import { CardType } from "../Settings/CardType/CardType";
import { UtilityCorrections } from "./Utility/UtilityCorrections";
import { UtilityStockCorrections } from "./Utility/UtilityStockCorrections";
import { UtilityShiftTransfer } from "./Utility/UtilityShiftTransfer";
import { UtilityPayment } from "./Utility/UtilityPayment";
import { Log } from "./Log/Log";
import { BackupAndRestore } from "./BackupAndRestore/BackupAndRestore";
import { ReportForward } from "./ReportForward/ReportForward";
import { UtilityForgotPassword } from "./Utility/UtilityForgotPassword";


import { TaxSettings } from "./TaxSettings/TaxSettings";
import { CurrencyUpdated } from "./Currency/CurrencyUpdated";
import { Sequence } from "./Sequence/Sequence";
import {BranchSettings} from "./BranchSettings/BranchSettings";
import { RolesAndPermissionNew } from "./RolesAndPermission Settings/RolesAndPermissionNew";
import { ChangePasswordSettings } from "./ChangePasswordSettings";
import {TemplateModel} from "./TemplateModel";
import PosReceipt from "./posReceipt/PosReceipt";
import "../../../css/Settings/RolesAndPermission/rolesAndPermission.css"
export const Settings = () => {
  const selectShiftData = useSelector((state) => state.selectShiftSlice.value);
  const selectUtilityData = useSelector(
    (state) => state.utilityMenuSlice.value
  );

  const [activeBtn, setActiveBtn] = useState("Company Settings");

  const switchOptions=[
    "Change Password",
    "Company Settings",
    // "Branch Settings",
    "Roles And permission Settings",
    "SMS Settings",
    "Discount",
    "Currency",
    "Sequence",
    "Tax",
    "Card & UPI Type",
    "Template Model",
    "Shift",
    "Report Forward",
    "Log",
    "Backup & Restore",
    "Pos Receipt"  // added on 4-3-24 template  model
  ]
  const clickSettingsBtn = (option) => {
    setActiveBtn(option);
    {
      if(option === "Shift"){
      document.getElementById("shiftBottom").classList.add("show");
      document.getElementById("shiftBottom").classList.remove("hide");

    }
    else{
      document.getElementById("shiftBottom").classList.add("hide");
      document.getElementById("shiftBottom").classList.remove("show");
    }
  }

    {
      if(option ==="Utility"){
      document.getElementById("utilityBottom").classList.remove("hideUtility");
      document.getElementById("utilityBottom").classList.add("showUtility");
      
    }
    else{
    document.getElementById("utilityBottom").classList.remove("showUtility");
    document.getElementById("utilityBottom").classList.add("hideUtility");

    }
  }
  };
  return (
    <div className="global-page-parent-container work-order-main-container">
      <div className="settings-parent-container">
        <div className="settings-left-menu-container">
          
          {
            switchOptions?.map((option)=>(
              <div
                key={option}
                className={`non-focus ${activeBtn===option?'focus-settings':''}`}
                onClick={() => clickSettingsBtn(option)}
              >
               {option}
              </div>
            ))
          }
          
          
        </div>

        <div className="settings-right-contents-container">
          {activeBtn === "Company Settings" ? (
            <CompanySettings />
          ) : activeBtn === "Branch Settings" ? (
            <BranchSettings />
          ) : activeBtn === "Roles And permission Settings" ? (
               <RolesAndPermissionNew/>
          ) : activeBtn === "SMS Settings" ? (
            <SmsSettings />
          )  : activeBtn === "Discount" ? (
            <Discount />
          ) : activeBtn === "Utility" ? (
            <Utility />
          ) : activeBtn === "Currency" ? (
            <CurrencyUpdated />
          )
            :activeBtn==="Sequence" ? (
              <Sequence/>
            )
           : activeBtn === "Card & UPI Type" ? (
            <CardType />
          ) : activeBtn === "Shift" ? (
            <Shift />
          ) : activeBtn === "Log" ? (
            <Log />
          ) : activeBtn === "Report Forward" ? (
            <ReportForward />
          ) : activeBtn === "Backup & Restore" ? (
            <BackupAndRestore />
          )  : activeBtn === "Tax" ? (
            <TaxSettings />
          ) : activeBtn === "Change Password" ?(
            <ChangePasswordSettings/>
          ) :activeBtn === "Template Model" ?(
            <TemplateModel/>
          ) :activeBtn === "Pos Receipt" ?(
            <PosReceipt/>   // added on 4-3-24 template  model
          ):undefined}
        </div>
      </div>

      {/* bottom containers */}
      <div className="hide" id="shiftBottom">
        {
          // selectShiftData === "addShiftButton" ? (
          //   <AddShift />
          // ) :
          selectShiftData === "shiftReopenButton" ? (
            <ShiftReopen />
          ) : selectShiftData === "shiftEndButton" ? (
            <ShiftEnd />
          ) : undefined
        }
      </div>
      <div className="hideUtility" id="utilityBottom">
        {selectUtilityData === "utilitySettingsCorrections" ? (
          <UtilityCorrections />
        ) : selectUtilityData === "utilitySettingsStockCorrections" ? (
          <UtilityStockCorrections />
        ) : selectUtilityData === "utilitySettingsPayments" ? (
          <UtilityPayment />
        ) : selectUtilityData === "utilitySettingsShiftTransfer" ? (
          <UtilityShiftTransfer />
        )  : selectUtilityData === "utilitySettingsForgotPassword" ? (
          <UtilityForgotPassword />
        ) : undefined}
      </div>
    </div>
  );
};
