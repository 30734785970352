import axios from "axios";
import { HEADERS, HEADER_FORM_DATA, HEADER_QUERY } from "../../../../../API/UrlAndPaths";
import store from "../../../../../Redux/store";
import { category_table_list, get_all_category, singleViewCategory } from "./categorySlice";
import { pos_category_table_list } from "../POSCategory/posCategorySlice";
import { setCategoryLoading } from "../../../Sales/Billing/Slice/billingScreenSlice";

// list api
export const categoryListAPICall = (body) => {
  store.dispatch(setCategoryLoading(true))
  axios
    .post("category/view", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_all_category({ categoryData: res.data }));
        store.dispatch(setCategoryLoading(false))
      }
    })
    .catch((err) => {
      store.dispatch(setCategoryLoading(false))
      store.dispatch(get_all_category({ categoryData: undefined }));
    });
};
export const CategoryFilterAPICall =(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)

  axios.post("category/searchMainCategories",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(category_table_list({tableCategoryList:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)

    }
  })
  .catch((err)=>{
    store.dispatch(category_table_list({tableCategoryList:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)
  })

}
// add api 
export const addCategoryAPICall = (body, setState, state,clear,setLoadingForm) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  axios
    .post("category", body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        clear()
        setState({ ...state, message: "Add Success fully !!", success: true });
        setLoadingForm!==undefined && setLoadingForm(false)
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};

// edit api
export const editCategoryAPICall = (
  body,
  setState,
  state,
  setDisabledInput,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)

  axios
    .put("category/edit", body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setDisabledInput(true);
        setState({
          ...state,
          message: "Update successfully !!",
          success: true,
        });
        setLoadingForm!==undefined && setLoadingForm(false)
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)

      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
    });
};

//delete api

export const deleteApiCall = (id, updateListner) => {
  axios
    .delete("category/delete", {
      headers: HEADER_QUERY,
      data: {
        id: id,
      },
    })
    .then((res) => {
      updateListner();
    })
    .catch((err) => {
      window.alert("SOMETHING WRONG >>>");
    });
};
//category single view api
export const categorySingleViewAPI = (body) => {
  axios
    .post("category/viewMainCategoryById", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          singleViewCategory({ singleViewCategoryList: res.data })
        );
      }
    })
    .catch((err) => {
      console.log("ERROR");
    });
};
