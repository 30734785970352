import React from 'react'
import { useSelector } from 'react-redux';

const PurchaseReportPrint = (props) => {
    const {purchaseList,symbol} =props
    const decimalPosition = localStorage.getItem("decimalPosition");

    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
    );
  return (
    <div className="print-main-div"><div className='header-div' style={{ display: "flex", justifyContent: "space-between" }}>
    <div>
        <img src={companyProfileData?.companyLogo} alt="" style={{ height: "62px" }} />
    </div>
    <div style={{ textAlignLast: "center" }}>
        <h1 style={{ fontSize: "medium", margin: "0" }}>Purchase Report</h1>
    </div>
    <div>
        <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1>
        <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyName}</p>
        <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyAddress}</p>
        <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyContact}</p>
    </div>
</div>
<div className="global-report-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th >UOM</th>
                               
                                <th >Purchase Qty</th>
                                <th >Purchase Return Qty</th>
                                <th >Total Quatity</th>
                                <th >Average Unit Cost</th>
                                <th >Average Total Value</th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            {purchaseList?.map((item,index)=>(
                            <tr key={index} >
                                <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.itemName}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.uom}</td>
                               
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.purchaseQty?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.purchaseRet?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalQuantity?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.cost?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.totalValue?.toFixed(decimalPosition)}</td>
                            </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={6}>Total</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.totalValue, 0)?.toFixed(decimalPosition)}</th>
                              
                            </tr>
                        </tfoot>
                    </table>
                </div>

</div>
  )
}

export default PurchaseReportPrint