import { createSlice } from "@reduxjs/toolkit";

export const calculatorSlice=createSlice({
    name:"calculatorSlice",
    initialState:{
        inputValueCalculator:""
    },
    reducers:{
        setInputCalculator:(state,action)=>{
            state.inputValueCalculator=action.payload
        }
    }
})

export const{setInputCalculator}=calculatorSlice.actions

export default calculatorSlice.reducer