import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { attributeListAPICall, deleteAttributeApiCall } from './AttributeAPI';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import store from '../../../../../Redux/store';
import { singleViewAttribute } from './AttributeSlice';

export const Attribute = () => {
  const viewAttributeList = useSelector((state)=>state?.attributeSlice?.value);
    let navigate = useNavigate();
    const attributeInitialState={
        attributeName:"",
        value:""
    }
    const [attributeForm,setAttributeForm]=useState(attributeInitialState);
    const [IsModify,setIsModify] = useState(false);
    const createAttribute=()=>{
        navigate("/userdashboard/inventory/configuration/attribute/create")
    }



    
    const updateListner =()=>{
      setIsModify(!IsModify)
    }
    
    
    //DeleteSingleCategory
    const DeleteSingleCategory=(id)=>{
      console.log(id);
      deleteAttributeApiCall(id,updateListner)
    }
    
    
    //singleViewOfAttribute
    const singleViewOfAttribute =(r)=>{
      navigate("/userdashboard/inventory/configuration/attribute/create")
      store.dispatch(singleViewAttribute({singleViewOfAttribute:r}));
    }
    
    useEffect(()=>{
      attributeListAPICall()
      store.dispatch(singleViewAttribute({ singleViewOfAttribute: undefined}));
    },[IsModify])
    
    return (
      <div className='global-page-parent-container'style={{ margin: "0" }}>
    {/* table */}
    <div className='global-white-bg-container'style={{ backgroundColor: "unset", padding: "5px", margin: "0" }}>
      <div className='create-button-blue-container'style={{ margin:"0",justifyContent: "end", backgroundColor: "white", padding: "5px"}}>
        <button  onClick={createAttribute}  className='create-button-blue'>Create</button>
      </div>
       <div className='global-table-container' style={{marginTop:"4px"}}>
         <table className='global-table'>
            <thead>
              <tr>
                  <th>Attribute Name</th>
                  <th>value</th>
                  <th>Delete</th>
              </tr>
            </thead>
            <tbody >

            {viewAttributeList?.length > 0 ? (
                viewAttributeList?.map((r, i) => (
                  <tr>
                    <td onClick={()=>singleViewOfAttribute(r)}>{r?.attributeName}</td>
                    <td className='table-data-box' style={{textAlign:"center"}} onClick={()=>singleViewOfAttribute(r)} >
                      <div className='table-data-round-parent'>

                        { 
                        r?.attCatogory?.map((r,i)=>(
                          <div className='round-border' key={i}>{r?.value}</div>
                          ))
                        }
                        </div>
                    </td>
                    <td>
                      <IconButton onClick={()=>DeleteSingleCategory(r?._id)}>
                        <i class="bi bi-trash3 delete-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>No Data</td>
                </tr>
              )}
           
              
            </tbody>
         </table>
       </div>
    </div>
   
  </div>
  )
}
