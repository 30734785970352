// icon:arrows_horizontal | Linea Iconset https://linea.io/ | Benjamin Sigidi
import * as React from "react";

function HorizontalLine(props) {
  return (
    <svg
      viewBox="0 0 64 64"
      fill="currentColor"
      height="1.5em"
      width="1.5em"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinejoin="bevel"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M8 25l-7 7 7 7M56 39l7-7-7-7"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M63 32H1"
      />
    </svg>
  );
}

export default HorizontalLine;
