import React, { useRef } from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { convertArrayToExcel, filterObjFromList } from '../../../../../Js/generalFunctions';
import { useEffect } from 'react';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { AccountRecievableSingleAPIcall } from './partnerReportApi';
import { IconButton, Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';
import Template from "./AccountSingleViewPrint"
import ExcelJS from "exceljs"
import PrintComponent from '../../../../print/report/PrintComponent';

export const AccountRecievableSingleView = () => {
  let paymentsPrintNew = useRef();

  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );

  let navigate = useNavigate();
  const [symbol, setSymbol] = useState(null);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const account = localStorage.getItem("account")
  const singleViewId = localStorage.getItem("singleViewById")
  // const StartDate = localStorage.getItem("fromDate");
  // const EndDate = localStorage.getItem("toDate");

  const selectedFromDate = localStorage.getItem("selectedFromDate");
  const selectedToDate = localStorage.getItem("selectedToDate");

  const singleView = useSelector((state) => state?.PartnerReport?.AccountRecievableSingleView)
  console.log(singleView);
  const decimalPosition = localStorage.getItem("decimalPosition");

  const currencyListUpdated = useSelector(
    (state) => state?.viewUpdatedCurrency?.value
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("ACCOUNT Receivable");
    sheet.properties.defaultRowHeight = 20;
  
    // Add header section
    const titleRow = sheet.addRow(["ACCOUNT Receivable"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells('A1:G1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Add additional header information
    // const filterParts = formatFilterInfo(filteredData) || [];
  
    // Ensure filterParts is an array and iterate over it
    // if (Array.isArray(filterParts)) {
    //   filterParts.forEach((part, index) => {
    //     const row = sheet.addRow([part]);
    //     row.font = { size: 13, bold: true };
    //     sheet.mergeCells(`A${index + 2}:H${index + 2}`);
    //     row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    //   });
    // }
  
    // Add an empty row between header and table
    sheet.addRow([]);
  
    // Header row styling
    const headerRow = sheet.addRow(["Date","Name","Journal","Reference","Debit","Credit","Balance"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };
  
    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    });
  
    sheet.columns = [
      { header: "Date", key: "date", width: 20 },
    {header:"Name",key:"name",width:20},
    {header:"Journal",key:"journalNo",width:20},
    {header:"Reference",key:"reference",width:20},
    {header:"Debit",key:"debit",width:20},
    {header:"Credit",key:"credit",width:20},
    {header:"Account Receivable",key:"balance",width:20}
    ];
  
    singleView?.entries?.forEach((item) => {
      const row = sheet.addRow({
        date: item?.date,
        name: ` ${item?.name}`,
        journalNo: ` ${item?.journalNo}`,
        reference: ` ${item?.reference}`,
        debit: ` ${item?.debit?.toFixed(2)}`,
        credit: ` ${item?.credit?.toFixed(2)}`,
        balance: ` ${item?.balance?.toFixed(2)}`,
      });
  
      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };
  
      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFC2CBF9" } },
          left: { style: "thin", color: { argb: "FFC2CBF9" } },
          bottom: { style: "thin", color: { argb: "FFC2CBF9" } },
          right: { style: "thin", color: { argb: "FFC2CBF9" } },
        };
        if (colNumber > 1) { // Right align all columns except the first
          cell.alignment = { horizontal: 'right' };
        }
      });
    });
  
    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      "",
      "",
      "",
      ` ${symbol} ${singleView?.totalDebit?.toFixed(2)}`,
      ` ${symbol} ${singleView?.totalCredit?.toFixed(2)}`,
      ` ${symbol} ${singleView?.totalBalance?.toFixed(2)}`,
    ]);
    
    // Merge cells from A to E
    sheet.mergeCells(`A${totalRow.number}:D${totalRow.number}`);
  
    totalRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber > 1) { // Right align all columns except the first
        cell.alignment = { horizontal: 'right' };
      }
    });
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Account Receivable Single View.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const initialvalue = {
    totalBalance: 0,
    totalCredit: 0,
    totalDebit: 0,
    label: "Account Receivable Report",

    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
  }
  const [data, setData] = useState([])
  const [formValues, setFormValues] = useState(initialvalue)

  useEffect(() => {
    setFormValues({
      ...formValues,
      totalBalance: singleView?.totalBalance || 0,
      totalCredit: singleView?.totalCredit || 0,
      totalDebit: singleView?.totalDebit || 0,

      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",

    });
    setData(singleView?.entries)
  }, [singleView, companyProfileData])
  const handleback = () => {
    localStorage.removeItem("fromDate")
    localStorage.removeItem("toDate")
    navigate("/userdashboard/accounts/report/partnerReport/accountReceivable")
  }

  useEffect(() => {
    if (configurationList !== undefined) {
      setToDate(configurationList?.yearEndDate)
      setFromDate(configurationList?.yearStartDate)
    }

  }, [configurationList])

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  //   },[StartDate,EndDate,singleViewId])
  //   useEffect(()=>{
  //     if (toDate !== undefined && fromDate !== undefined) {
  //       const startDate = selectedFromDate === '' ? '' : `${fromDate} 00:00:00`;
  //       const endDate = toDate === '' ? '' : `${toDate} 23:59:59`;

  //       AccountPayableAPIcall({
  //           startDate: startDate,
  //           endDate: endDate
  //       });
  //   }
  // }, [fromDate, toDate]);


  // useEffect(() => {
  //   AccountRecievableSingleAPIcall({
  //     startDate:  `${selectedFromDate} 00:00:00`,
  //     endDate:  `${selectedToDate} 23:59:59`,
  //     id: singleViewId,
  //   });
  // }, [selectedFromDate, selectedToDate, singleViewId]);


  useEffect(() => {
    if (toDate !== undefined && fromDate !== undefined) {
      const startDate = fromDate === '' ? '' : `${fromDate} 00:00:00`;
      const endDate = toDate === '' ? '' : `${toDate} 23:59:59`;
      AccountRecievableSingleAPIcall({
        startDate: selectedFromDate === '' ? startDate : `${selectedFromDate} 00:00:00`,
        endDate: selectedToDate === '' ? endDate : `${selectedToDate} 23:59:59`,
        id: singleViewId,
      });
    }
  }, [fromDate, toDate, selectedFromDate, selectedToDate, singleViewId]);



  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  return (
    <div className="global-page-parent-container" style={{ margin: "0" }}>
      <div className="global-white-bg-container" style={{backgroundColor:"unset",padding:"4px"}}>
        <div style={{backgroundColor:"white", paddingTop: "8px", display: "flex",justifyContent:"space-between" }}>
          <div style={{display:"flex",alignItems:"center"}}>
          <div className="sharp-arrow" onClick={() => handleback()} > <p style={{margin:"0",fontSize:"medium"}}>Account Recievable Report &gt;</p></div>
          <div className="sharp-arrow" style={{ width: "auto" }}> <p style={{margin:"0",fontSize:"medium"}}>{account}</p></div>
         </div> 
         <div style={{ paddingInline: "15px"}}>
            <Tooltip title="Share">
              <IconButton>
                <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
              </IconButton>
            </Tooltip>

            <ReactToPrint
              trigger={() => (
                <IconButton className="printer-div" style={{ padding: "1px" }}>
                  <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
                </IconButton>
              )}
              content={() => paymentsPrintNew}
            />

            <Tooltip title="Download">
              <IconButton onClick={downloadList}>
                <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
              </IconButton>
            </Tooltip>

          </div>
        </div>


        <div className='global-report-table-container' style={{ maxHeight: "84vh",height:"84vh",backgroundColor:"white",marginTop:"3px" }}>
          <table style={{padding:"4px"}}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Journal</th>
                <th>Referance</th>
                <th>Debit</th>
                <th>Credit</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {singleView?.entries?.map((item, index) => (
                <tr key={index} style={{ height: "38px" }}>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.date}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.name}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.journalNo}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.reference}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.debit?.toFixed(decimalPosition)}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.credit?.toFixed(decimalPosition)}</td>
                  <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.balance?.toFixed(decimalPosition)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan="4">Total</th>
                <th>{symbol}&nbsp;{singleView?.totalDebit?.toFixed(decimalPosition)}</th>
                <th>{symbol}&nbsp;{singleView?.totalCredit?.toFixed(decimalPosition)}</th>
                <th>{symbol}&nbsp;{singleView?.totalBalance?.toFixed(decimalPosition)}</th>
              </tr>
            </tfoot>
          </table>

        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
        <PrintComponent header={"Account Receivable Report"} subHead={account} pageHeight={40}>
        <table style={{padding:"4px"}}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Journal</th>
                <th>Referance</th>
                <th>Debit</th>
                <th>Credit</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {singleView?.entries?.map((item, index) => (
                <>
                <tr key={index} style={{ height: "38px" }}>
                  <td >{item?.date}</td>
                  <td >{item?.name}</td>
                  <td >{item?.journalNo}</td>
                  <td >{item?.reference}</td>
                  <td >{item?.debit?.toFixed(decimalPosition)}</td>
                  <td >{item?.credit?.toFixed(decimalPosition)}</td>
                  <td >{item?.balance?.toFixed(decimalPosition)}</td>
                </tr>
                {singleView?.entries?.length -1 === index &&
                   <tr className='new-layout-print-footer'>
                   <td colSpan="4">Total</td>
                   <td>{symbol}&nbsp;{singleView?.totalDebit?.toFixed(decimalPosition)}</td>
                   <td>{symbol}&nbsp;{singleView?.totalCredit?.toFixed(decimalPosition)}</td>
                   <td>{symbol}&nbsp;{singleView?.totalBalance?.toFixed(decimalPosition)}</td>
                 </tr>
                }
                </>
              ))}
            </tbody>
            <tfoot>
             
            </tfoot>
          </table>
        </PrintComponent>
          {/* <Template data={data} formValues={formValues} /> */}
        </div>
      </div>
    </div>

  )
}