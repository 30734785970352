import { IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../css/Login/login.css";
// import jwtDecode from "jwt-decode";
import { useState } from "react";
import {
  finalLoginAPICall,
  loginAPICall,
  requestOtpAPICall,
  searchUserByUSerNameAPICall,
} from "../../API/Login/LoginAPI";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ErrorSnackbar from "../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../Single Components/SnackBars/SuccessSnackbar";
// import Verification from "./Verification";
// import CountryData from "./countryListWithCode.json";
import { getCurrentLocationAPICall } from "../../API/Location/locationAPI";
import Store from "../../Redux/store";
// import { update_admin_credential } from "../../Redux/Login/adminLoginPasswordSlice";
// import AddOutletDialog from "../CustomComponents/AddOutletDialog";
// import { logOutUser } from "./Lougout";
// import Swal from "sweetalert2";
import EyetLogo from "../../Assets/Images/Logo.png";
import EyetDashboard from "../../Assets/Images/dashboard.png";
import LoginRightPanel from "./LoginRightPanel";
import jwtDecode from "jwt-decode";
import {LoginMobileVerification} from "../Login/LoginMobileVerification.jsx"
import { LoginQuestionAndAnswer } from "./LoginQuestionAndAnswer";
import Swal from "sweetalert2";
import restaurantGif from "../../Assets/BillingImages/Animation - 1708324068340.gif"
const Login = () => {
  //NOTES
  //NB:Admin login by otp is temporarily stopped due to multiple db conflict (20/07/2022)

  //Redux States
  //User Role
  const userRole = useSelector((state) => state.searchUserSlice.value);
  //Generated OTP
  //   const generatedOtp = useSelector((state) => state.generatedOTP.value);
  //Login Status
  //   const isLoggedIn = useSelector((state) => state.loginUserMob.value);
  //UserToken
  //   const userToken = useSelector((state) => state.userToken.value);

  //From local Storage
  const countryMeta = localStorage.getItem("COUNTRY_META");
  const isTempAuth = localStorage.getItem("isTempAuth");
  const isAuth = localStorage.getItem("isAuth");
  // const companyName=localStorage.getItem('cLogo')
  // const companyLogo=localStorage.getItem('cName')
  //Form states from front end
  const [companyCode, setCompanyCode] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [adminMobNo, setAdminMobNo] = useState("");
  //State to disable company code and user Name
  const [isDisabled, setIsDisabled] = useState(false);
  //state to determine NEXT button enabled or disabled
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  //Check is Admin Otp generated or not
  const [isOtpGenerated, setIsOtpGenerated] = useState(false);
  //to determine first NextClick or second NextClick
  const [isFirstNext, setIsFirstNext] = useState(true);
  //isAuth set from api call
  const [isSuccess, setIsSuccess] = useState(false);
  //tempToken From API response
  const [token, setToken] = useState(null);
  // //Add Outlet Dialog Open
  // const [openAddOutlet,setOpenAddOutlet]=useState(false)
  //Success Snackbar
  const [successSnack, setSuccessSnack] = useState(false);
  //Success Message
  const [successMsg, setSuccessMsg] = useState("Success...");
  //Error Snackbar
  const [errorSnack, setErrorSnack] = useState(false);
  //Error Message
  const [errorMsg, setErrorMsg] = useState("Error!!");
  //Password Visible or not
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // forgot password component
  const [showForgotPassword,setShowForgotPassWord]=useState(true)
  //Close Success Snackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnack(false);
  };
  //Close Error Snackbar
  const closeErrorSnackbar = () => {
    setErrorSnack(false);
  };

  const navigate = useNavigate();
  // fetch data from front end
  //company Code
  const getCompanyCode = (e) => {
    setCompanyCode(e.target.value);
  };
  //userName
  const getUserName = (e) => {
    setUserName(e.target.value);
  };
  //password
  const getPassword = (e) => {
    setPassword(e.target.value);
  };
  //Admin Mobile No.
  const getAdminMobNo = (e) => {
    setAdminMobNo(e.target.value);
  };

  // //close Add outlet Dialog
  // const closeAddOutletDialog=()=>{
  //   logOutUser(false)
  //   setOpenAddOutlet(false)
  // }

  //Call Current location API onload
  useEffect(() => {
    getCurrentLocationAPICall();
    localStorage.setItem("country", "india");
  }, []);

  //Body to pass Search User Role
  const searchUserRoleBody = {
    userName: userName,
    companyCode: companyCode,
    prefix: "RST",
  };

  //Call User Role API when ever companyCode and UserName Changes and call next button enabled or disabled
  useEffect(() => {
    if (userName !== "" && companyCode !== "") {
      searchUserByUSerNameAPICall(searchUserRoleBody);
    }
    checkNextBtnEnabled();
  }, [companyCode, userName]);

  //Check Next button Enabled or disabled
  const checkNextBtnEnabled = () => {
    if (companyCode !== "" && userName !== "") {
      setIsNextEnabled(true);
    } else {
      setIsNextEnabled(false);
    }
  };

  //Render Mobile No or Password as per userRole
  const renderMobOrPassword = () => {
    if (userRole?.role === "admin") {
      document
        .getElementById("adminMobileContainer")
        .classList.remove("btn-hide");
      document
        .getElementById("changeCompanyCodeContainer")
        .classList.remove("btn-hide");
      document
        .getElementById("changeUserNameContainer")
        .classList.remove("btn-hide");
      document.getElementById("empPasswordContainer").classList.add("btn-hide");
      setIsDisabled(true);
      setIsFirstNext(false);
    } else if (userRole?.role === "user") {
      document
        .getElementById("empPasswordContainer")
        .classList.remove("btn-hide");
      document.getElementById("adminMobileContainer").classList.add("btn-hide");
      document
        .getElementById("empForgotPasswordContainer")
        .classList.remove("btn-hide");

      setIsDisabled(true);
      setIsFirstNext(false);
    }
  };

  //Login Admin and User by password
  const displayPasswordInputField = () => {
    if (userRole !== undefined) {
      document
        .getElementById("empPasswordContainer")
        .classList.remove("btn-hide");
      document.getElementById("loginPassword").focus();
      document
        .getElementById("changeCompanyCodeContainer")
        .classList.remove("btn-hide");
      document
        .getElementById("changeUserNameContainer")
        .classList.remove("btn-hide");
      document.getElementById("userNameNextBtn").classList.add("btn-hide");
      document.getElementById("loginSubmitBtn").classList.remove("btn-hide");
      setIsDisabled(true);
      setIsFirstNext(false);
    } else {
      setErrorSnack(true);
      setErrorMsg("No User found!!");
    }
  };

  //Change Company code and user name
  const changeCompanyCodeAndUserName = () => {
    document.getElementById("adminMobileContainer").classList.add("btn-hide");
    document.getElementById("empPasswordContainer").classList.add("btn-hide");
    document
      .getElementById("changeCompanyCodeContainer")
      .classList.add("btn-hide");
    document
      .getElementById("changeUserNameContainer")
      .classList.add("btn-hide");
    document.getElementById("userNameNextBtn").classList.remove("btn-hide");
    document.getElementById("loginSubmitBtn").classList.add("btn-hide");
    setIsDisabled(false);
    setIsFirstNext(true);
  };

  //Click Next Button Fn
  const clickNextBtn = () => {
    // renderMobOrPassword();
    displayPasswordInputField();
  };

  //Call Send OTP API call on second Next btn Click
  const secondNextBtnClick = () => {
    if (adminMobNo !== "") {
      //   requestOtpAPICall(adminMobNo, setIsOtpGenerated);
    }
  };

  //Initial Login Data
  
  const initialLoginData = {
    userName: userName,
    password: password,
    companyCode: companyCode,
    prefix: "RST",
  };

  //Login Button Click
  const clickLoginBtn = () => {
    // Store.dispatch(
    //   update_admin_credential({
    //     adminCredential: { userName, password, companyCode },
    //   })
    // );
    // loginAPICall(
    //   initialLoginData,
    //   2,
    //   setIsSuccess,
    //   setToken,
    //   setErrorSnack,
    //   setErrorMsg
    // );
    finalLoginAPICall(
      initialLoginData,
      setIsSuccess,
      setErrorSnack,
      setErrorMsg
    );
  };


  //Decode token and determine first login or not
  const firstLoginOrNotByToken = () => {
    let decodedToken = null;
    // console.log(token);
    if (token !== null) {
      decodedToken = jwtDecode(token);
      localStorage.setItem('branchId',decodedToken?.branchId)
      
      if (decodedToken?.firstlogin) {
        //open add outlet dialog
        navigate("/addOutlet");
      } else {
        setTimeout(() => {
          successAlert();
        }, 1500);
        navigate("/userdashboard/dashboardhome");
      }
    }
  };
  const clickForgotPassWord=()=>{
    setShowForgotPassWord(!showForgotPassword)
    setUserName("")
    setCompanyCode("")
    setIsDisabled(false);
  }
  //Success Message When Logged in Successfully
  const successAlert = () => {
    Swal.fire({
      title: "Logged in",
      text: "User authorized successfully",
      icon: "success",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  //Redirect to selectLocation page if userName and password login success
  useEffect(() => {
    if (isSuccess) {
      // localStorage.setItem('accountExecute',true)
      firstLoginOrNotByToken();
    }
  }, [isSuccess, token]);

  //Redirect to userDashboard if user already logged in
  useEffect(() => {
    if (isAuth) {
      console.log(token);
      successAlert()
      setTimeout(()=>{   
        navigate("/userdashboard/dashboardhome");
      },1500)
    }
  }, [isAuth]);

  //Password visible or not
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  //Make Type password to text and vice versa
  const changePasswordInputType = () => {
    if (isPasswordVisible) {
      showForgotPassword?
      document.getElementById("loginPassword").type = "text":
      console.log(isPasswordVisible);
    } else {
      showForgotPassword?
      document.getElementById("loginPassword").type = "password":
      console.log(isPasswordVisible);
    }
  };
  useEffect(() => {
    changePasswordInputType();
  }, [isPasswordVisible]);

  return (
    <>
      <div className="parent-login-container">
        <div className="child-container left">
          <div className="eyet-dashboard-bg-image-container">
            <img src={EyetDashboard} alt="" />
          </div>
          {isOtpGenerated ? //   otp={generatedOtp} // <Verification
          //   mobile={adminMobNo}
          //   userName={userName}
          // />
          undefined : (
            <>
            {showForgotPassword?
              <div className="login-content-wrapper" id="loginFormLeftContent">
                <div className="logo-container">
                  <img className="logo" src={EyetLogo} alt="EYET logo" />
                </div>

                <div className="login-text-container">
                <img
                
                src={restaurantGif} alt="" />
                  <Typography
                    fontSize={"2rem"}
                    fontWeight={700}
                    marginLeft={"24px"}
                    className="login-text"
                  >
                    Login
                  </Typography>
                  <Typography
                    fontWeight={600}
                    letterSpacing={1.4}
                    marginLeft={"24px"}
                    className="login-description"
                  >
                    Restaurant Innovative ERP Solutions.
                  </Typography>
                </div>
                <div className="login-form-container">
                  <div className="login-form">
                    {/* Company Code Container  */}
                    <div className="user-name-container">
                      <p>Company Code</p>

                      <div className="username-wrapper">
                        <input
                          className="right-input-login-user"
                          type={"text"}
                          name="companyCode"
                          id="companyCodeInput"
                          placeholder="Enter Company Code..."
                          value={companyCode}
                          onChange={getCompanyCode}
                          disabled={isDisabled}
                        />

                        <div
                          className="change-userName-container btn-hide"
                          id="changeCompanyCodeContainer"
                        >
                          <button onClick={changeCompanyCodeAndUserName}>
                            Change
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* user name container [admin and employee]  */}
                    <div className="user-name-container">
                      <p>User name</p>

                      <div className="username-wrapper">
                        <input
                          className="right-input-login-user"
                          type={"text"}
                          name="userName"
                          id="userNameInput"
                          placeholder="Enter user name..."
                          value={userName}
                          onChange={getUserName}
                          disabled={isDisabled}
                          onKeyDown={(e) => {
                            e.key === "Enter" && clickNextBtn();
                          }}
                        />

                        <div
                          className="change-userName-container btn-hide"
                          id="changeUserNameContainer"
                        >
                          <button onClick={changeCompanyCodeAndUserName}>
                            Change
                          </button>
                        </div>
                      </div>
                      
                    </div>

                    {/* mobile number container for admin    */}
                    <div
                      className="user-name-container mobile-no-container btn-hide"
                      id="adminMobileContainer"
                    >
                      <p>Mobile</p>
                      <p className="user-role">Role: {userRole?.role}</p>
                      <div className="username-wrapper">
                        <select className="left-input-login-user">
                          <option>+91</option>
                        </select>
                        <input
                          className="right-input-login-user admin-mobile-input"
                          type={"text"}
                          name="adminMobileInput"
                          id="adminMobileInput"
                          placeholder="0123456789"
                          value={adminMobNo}
                          onChange={getAdminMobNo}
                          maxLength="15"
                        />

                        <div
                          className="change-userName-container btn-hide"
                          id="changeUserNameContainer"
                        >
                          <button>Change</button>
                        </div>
                      </div>
                    </div>

                    {/* password container for employee    */}
                    <div
                      className="password-container btn-hide"
                      id="empPasswordContainer"
                    >
                      <h3>Password</h3>
                      <p className="user-role">Role: {userRole?.role}</p>
                      <input
                        type={"password"}
                        name="password"
                        id="loginPassword"
                        placeholder="Enter password..."
                        value={password}
                        onChange={getPassword}
                        onKeyDown={(e) => {
                            e.key === "Enter" && clickLoginBtn();
                          }}
                      />
                      <div className="password-visible-eye-container">
                        <IconButton onClick={togglePasswordVisibility}>
                          {isPasswordVisible ? (
                            <i class="bi bi-eye-slash"></i>
                          ) : (
                            <i class="bi bi-eye"></i>
                          )}
                        </IconButton>
                      </div>
                    </div>
                    <div
                      className="forgot-password-container btn-hide"
                      // id="empForgotPasswordContainer"
                    >
                      <Link to={"/forgotpassword"}>Forgot Password?</Link>
                    </div>

                    <button
                      disabled={!isNextEnabled}
                      type="submit"
                      className={
                        !isNextEnabled ? "login-btn-disabled" : "login-btn"
                      }
                      onClick={isFirstNext ? clickNextBtn : secondNextBtnClick}
                      id="userNameNextBtn"
                    >
                      Next
                    </button>

                    {/* button to display if user is employee */}
                    <button
                      type="submit"
                      className={"login-btn btn-hide"}
                      id="loginSubmitBtn"
                      onClick={clickLoginBtn}
                      disabled={password !== "" ? false : true}
                    >
                      Login
                    </button>

                    {/* button to display if user is admin */}
                    <button
                      type="submit"
                      className="login-btn btn-hide"
                      // onClick={handleGenerateOtpButton}
                      id="requestOtpBtn"
                    >
                      Request OTP
                    </button>

                  <div className="login-content-wrapper-forgot-password-register">
                    <a href="https://www.eyeterp.ae/" className="login-register-new-link" target="_blank">Register here !</a>
                    <p onClick={clickForgotPassWord}> Forgot password &gt;&gt;&gt;</p> 
                  </div>
                    </div>
                </div>
              </div>
              :
              // forgot password by mobile verification
              <LoginMobileVerification backToLogin={clickForgotPassWord}/>
              // forgot password by Qn & answer verification
              // <LoginQuestionAndAnswer backToLogin={clickForgotPassWord}/>
            }
              <ErrorSnackbar
                // open={errorSnack}
                // handleClose={errorSnackClose}
                message={"No User Found!!!"}
              />
            </>
          )}

        </div>

        <LoginRightPanel
          companyName={userRole?.companyName}
          companyLogo={userRole?.logo}
        />
      </div>

      {/* Success Snackbar  */}
      <SuccessSnackbar
        open={successSnack}
        handleClose={closeSuccessSnackbar}
        message={successMsg}
      />
      {/* Error Snackbar */}
      <ErrorSnackbar
        open={errorSnack}
        handleClose={closeErrorSnackbar}
        message={errorMsg}
      />
    </>
  );
};

export default Login;
