import axios from "axios";
import { HEADERS } from "../../../../API/UrlAndPaths";
import store from "../../../../Redux/store";
import { setStockMove, setStockMoveSigleView } from "./ReportSlice";
import { setSubmitLoading, setTableLoadingGlobal } from "../../../../Redux/Dashboard/mainDashBoardSlice";

export const StockMoveAPIcall=async(body)=>{
    store.dispatch(setTableLoadingGlobal(true))
    await axios
    .post("inventory/stockMoveReport",body, HEADERS)
    .then((res)=>{
        store.dispatch(setTableLoadingGlobal(false))
        if(res.status===200){
            store.dispatch(setStockMove({stockMoveList:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(setStockMove({stockMoveList:undefined}))
        store.dispatch(setTableLoadingGlobal(false))
    })
}

export const StockMoveSingleAPIcall=async(body)=>{
    store.dispatch(setSubmitLoading(true))
    await axios
    .post("inventory/stockMoveReportSingleView",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(setSubmitLoading(false))
            store.dispatch(setStockMoveSigleView({stockMoveSingleList:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch( setStockMoveSigleView({stockMoveSingleList:[]}))
        store.dispatch(setSubmitLoading(false))
    })
}