import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { wareHouseListAPICall } from "./wareHouseAPI";
import {
  addWareHouseLocationAPICall,
  editWareHouseLocationAPICall,
  singleViewWareHouseLocationAPICall,
} from "./wareHouseLocationAPI";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { filterObjFromList } from "../../../../Js/generalFunctions";
import { LoadingForm } from "../../../Single Components/LoadingForm";
export const CreateInvLocation = () => {
  const singleViewById = localStorage.getItem("singleViewById");
  let navigate = useNavigate();

  const locationType = [{ type: "INTERNAL" }];

  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // ware house list
  const wareHouseList = useSelector((state) => state.wareHouseSlice.value);
  // ware house location single view
  const locationSingleView = useSelector(
    (state) => state.wareHouseLocationSlice.singleValue
  );
  const wareHouseLocationInitial = {
    name: "",
    branch: null,
    address: "",
    wareHouse: null,
    locationType: "INTERNAL",
    shortCode: "",
  };
  const [wareHouseLocationForm, setWareHouseLocationForm] = useState(
    wareHouseLocationInitial
  );
  const [filteredWareHouse, setFilteredWareHouse] = useState([]);
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("Error!!");
  const [validationAlert, setValidationAlert] = useState({});

  const [loadingForm, setLoadingForm] = useState(false);

  const backToMainPage = () => {
    if (wareHouseLocationForm?.isEdit === false) {
      const userConfirmed = window.confirm(
        "Changes you made may not be saved."
      );

      if (userConfirmed) {
        navigate("/userdashboard/inventory/configuration/location");
      }
    } else {
      navigate("/userdashboard/inventory/configuration/location");
    }
  };
  const editEnableFn = () => {
    setWareHouseLocationForm({
      ...wareHouseLocationForm,
      isEdit: false,
    });
  };

  const editBody = {
    name: wareHouseLocationForm?.name,
    address: wareHouseLocationForm?.address,
    branchId: wareHouseLocationForm?.branch?._id,
    warehouseId: wareHouseLocationForm?.wareHouse?._id,
    // locationType: wareHouseLocationForm?.locationType?.type,
    locationType: wareHouseLocationForm?.locationType,
    shortCode: wareHouseLocationForm?.shortCode,
    _id: locationSingleView?._id,
  };
  const editSaveFn = () => {

    let saveFn=()=>{
      setWareHouseLocationForm({
        ...wareHouseLocationForm,
        isEdit: true,
      });
    }
    editWareHouseLocationAPICall(
      editBody,
      setSuccessSnackOpen,
      setErrorSnackOpen,
      setSnackMsg,
      setLoadingForm,saveFn
    );
  };

  // snackbar function
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };

  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };

  const getWareHouseForm = (key) => (e, newValue) => {
    if (key === "name") {
      setWareHouseLocationForm({
        ...wareHouseLocationForm,
        name: e.target.value,
      });
    }
    if (key === "branch") {
      setWareHouseLocationForm({ ...wareHouseLocationForm, branch: newValue });
    }
    if (key === "address") {
      setWareHouseLocationForm({
        ...wareHouseLocationForm,
        address: e.target.value,
      });
    }
    if (key === "wareHouse") {
      setWareHouseLocationForm({
        ...wareHouseLocationForm,
        wareHouse: newValue,
      });
    }
    if (key === "locationType") {
      // Set the value directly to "INTERNAL"
      setWareHouseLocationForm({
        ...wareHouseLocationForm,
        locationType: "INTERNAL",
      });
    }
    // if (key === "locationType") {
    //   setWareHouseLocationForm({
    //     ...wareHouseLocationForm,
    //     locationType: newValue,
    //   });
    // }
    if (key === "shortCode") {
      setWareHouseLocationForm({
        ...wareHouseLocationForm,
        shortCode: e.target.value.toUpperCase(),
      });
    }
  };

  const bodyToApi = {
    name: wareHouseLocationForm?.name,
    address: wareHouseLocationForm?.address,
    branchId: wareHouseLocationForm?.branch?._id,
    warehouseId: wareHouseLocationForm?.wareHouse?._id,
    // locationType: wareHouseLocationForm?.locationType?.type,
    locationType: wareHouseLocationForm?.locationType,
    shortCode: wareHouseLocationForm?.shortCode,
  };
  const saveWareHouseLoc = () => {
    if (wareHouseLocationForm?.name === "") {
      setValidationAlert({ ...validationAlert, name: "fill this field" });
    } else if (wareHouseLocationForm?.locationType === null) {
      setValidationAlert({
        ...validationAlert,
        locationType: "fill this field",
      });
    } else if (
      wareHouseLocationForm?.branch === null &&
      wareHouseLocationForm?.locationType?.type === "INTERNAL"
    ) {
      setValidationAlert({ ...validationAlert, branch: "fill this field" });
    } else if (wareHouseLocationForm?.address === "") {
      setValidationAlert({ ...validationAlert, address: "fill this field" });
    } else if (wareHouseLocationForm?.shortCode === "") {
      setValidationAlert({ ...validationAlert, shortCode: "fill this field" });
    } else {
      addWareHouseLocationAPICall(
        bodyToApi,
        clearState,
        setSuccessSnackOpen,
        setErrorSnackOpen,
        setSnackMsg,
        setLoadingForm
      );
    }
  };

  const clearState = () => {
    setWareHouseLocationForm(wareHouseLocationInitial);
  };

  const filterObjectFromList = (key, list, item) => {
    let filteredArray = [];
    filteredArray = list?.filter((obj) => obj[key] === item);
    return filteredArray?.length !== 0 && filteredArray !== undefined
      ? filteredArray[0]
      : null;
  };

  //  filter ware house list based on branch
  useEffect(() => {
    let filterData = wareHouseList?.filter(
      (obj) => obj?.branchId === wareHouseLocationForm?.branch?.storeCode
    );
    setFilteredWareHouse(filterData);
  }, [wareHouseLocationForm?.branch]);

  useEffect(() => {
    setWareHouseLocationForm({
      ...wareHouseLocationForm,
      address: wareHouseLocationForm?.branch?.address,
    });
  }, [wareHouseLocationForm?.branch]);

  useEffect(() => {
    if (locationSingleView !== undefined) {
      setWareHouseLocationForm({
        ...wareHouseLocationForm,
        name: locationSingleView?.name,
        branch: filterObjectFromList(
          "_id",
          allBranchesList,
          locationSingleView?.branchId
        ),
        address: locationSingleView?.address,
        // locationType: filterObjectFromList(
        //   "type",
        //   locationType,
        //   locationSingleView?.locationType
        // ),
        shortCode: locationSingleView?.shortCode,
        editBtn: true,
        isEdit: true,
        // wareHouse:filterObjFromList("_id",filteredWareHouse,"warehouseId",locationSingleView),
      });
    }
  }, [locationSingleView, allBranchesList]);

  useEffect(() => {
    wareHouseListAPICall();
    singleViewWareHouseLocationAPICall({ id: singleViewById });
  }, []);
  useEffect(() => {
    if (locationSingleView !== undefined) {
      setWareHouseLocationForm({
        ...wareHouseLocationForm,
        wareHouse: filterObjFromList(
          "_id",
          filteredWareHouse,
          "warehouseId",
          locationSingleView
        ),
      });
    }
  }, [filteredWareHouse]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !wareHouseLocationForm?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [wareHouseLocationForm?.isEdit]);

  return (
    <div className="global-page-parent-container">
      <div className="global-white-bg-container">
        <Tooltip title="Back" onClick={backToMainPage}>
          <IconButton>
            <i class="bi bi-chevron-left"></i>
          </IconButton>
        </Tooltip>
        {wareHouseLocationForm?.editBtn === true && (
          <Tooltip title="Edit">
            <IconButton
              onClick={
                wareHouseLocationForm?.isEdit === true
                  ? editEnableFn
                  : editSaveFn
              }
            >
              {wareHouseLocationForm?.isEdit === true ? (
                <i class="bi bi-pencil-square edit-icon1"></i>
              ) : (
                <i
                  class="bi bi-check2-square edit-icon"
                  style={{ fontSize: "1.5rem" }}
                ></i>
              )}
            </IconButton>
          </Tooltip>
        )}

        {wareHouseLocationForm?.editBtn !== true && (
          <Tooltip title="Save">
            <IconButton onClick={saveWareHouseLoc}>
              <i class="bi bi-floppy"></i>
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className="global-white-bg-container create-journal-container">
        <div className="global-single-input create-joutnal-input">
          <p>Name</p>
          <input
            type="text"
            value={wareHouseLocationForm?.name}
            onChange={getWareHouseForm("name")}
            disabled={wareHouseLocationForm?.isEdit}
          />
          <p className="doc-validation-alert">{validationAlert?.name}</p>
        </div>
        {/* <div className='global-single-input create-joutnal-input auto-complete'>
        <p>Location Type</p>
        <Autocomplete
                sx={{ width: "100%" }}
                options={locationType||["No Data"]}
                getOptionLabel={(option) =>option?.type}
                renderInput={(params) => (
                  <TextField {...params}
                   placeholder="Select Locatiion Type" />
                )}
                value={wareHouseLocationForm?.locationType}
                onChange={getWareHouseForm("locationType")}
                disabled={wareHouseLocationForm?.isEdit}
               
             />
               <p className="doc-validation-alert">{validationAlert?.locationType}</p>
      </div> */}
       <div className="global-single-input create-joutnal-input">
          <p>Location Type</p>
          <input
            type="text"
            value={wareHouseLocationForm?.locationType}
            onChange={getWareHouseForm("locationType")}
            // disabled={wareHouseLocationForm?.isEdit}
            disabled
          />
          <p className="doc-validation-alert">{validationAlert?.locationType}</p>
        </div>
       

        {/* {
        wareHouseLocationForm?.locationType?.type ==="INTERNAL"&& */}
        <>
          <div className="global-single-input create-joutnal-input auto-complete">
            <p>Branch</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={allBranchesList || [""]}
              getOptionLabel={(option) =>
                `${option?.storeCode}-${option?.branchName}`
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Branch" />
              )}
              value={wareHouseLocationForm?.branch}
              onChange={getWareHouseForm("branch")}
              disabled={wareHouseLocationForm?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.branch}</p>
          </div>

          <div className="global-single-input create-joutnal-input">
            <p>Address</p>
            <input
              type="text"
              value={wareHouseLocationForm?.address}
              onChange={getWareHouseForm("address")}
              disabled={wareHouseLocationForm?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.address}</p>
          </div>

          <div className="global-single-input create-joutnal-input auto-complete">
            <p>Ware House</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={filteredWareHouse || [""]}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Branch" />
              )}
              value={wareHouseLocationForm?.wareHouse}
              onChange={getWareHouseForm("wareHouse")}
              disabled={wareHouseLocationForm?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.wareHouse}</p>
          </div>
        </>

        <div className="global-single-input create-joutnal-input">
          <p>Short Code</p>
          <input
            type="text"
            maxlength="4"
            value={wareHouseLocationForm?.shortCode}
            onChange={getWareHouseForm("shortCode")}
            disabled={wareHouseLocationForm?.isEdit}
          />
          <span className="suffix-text" style={{ textAlign: "end" }}>
            (Limit 4)
          </span>
          <p className="doc-validation-alert">{validationAlert?.shortCode}</p>
        </div>
      </div>

      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />

      <LoadingForm loading={loadingForm} />
    </div>
  );
};
