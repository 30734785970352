import axios from "axios";
import { HEADER_FORM_DATA, HEADERS } from "../../../../../API/UrlAndPaths";
import store from "../../../../../Redux/store";
import advertisementSlice, {
  setAlertMessage,
  setDisplayAdvertisementData,
  setIsEditAdv,
  setLoading,
} from "./advertisementSlice";
import { alertMessages } from "../../../FloorManagement/utils/floorManagementEnum";

// upload advertisement
export const uploadAdvertisement = async (data) => {
  try {
    const result = await axios.post(
      "pos/addAdvertisement",
      data.body,
      HEADER_FORM_DATA
    );
    if (result.status === 200) {
      store.dispatch(
        setAlertMessage({
          status: alertMessages.SUCCESS,
          message: "Advertisement uploaded successfully 👍",
        })
      );
    }
  } catch (error) {
    store.dispatch(
      setAlertMessage({
        status: alertMessages.ERROR,
        message: error.response.data,
      })
    );
  }
};

// view advertisement
export const viewUploadFiles = async (data) => {
  try {
    const result = await axios.post(
      "pos/viewAdvertisement",
      data.body,
      HEADERS
    );

    if (result.status === 200) {
      store.dispatch(setDisplayAdvertisementData(result.data));
    }
  } catch (error) {
    store.dispatch(setDisplayAdvertisementData(null));
    store.dispatch(
      setAlertMessage({
        status: alertMessages?.ERROR,
        message: error?.response?.data,
      })
    );
  }
};

// update advertisement
export const updateAdvertisement = async (data) => {
  try {
    store.dispatch(setDisplayAdvertisementData(null));
    const result = await axios.post(
      "pos/editAdvertisement",
      data.body,
      HEADER_FORM_DATA
    );

    if (result.status === 200) {
      store.dispatch(setLoading(false));
      store.dispatch(setIsEditAdv(false));
      store.dispatch(
        setAlertMessage({
          status: alertMessages?.SUCCESS,
          message: "Advertisement updated successfully 👍",
        })
      );
      viewUploadFiles({
        body: { branchId: data?.branchId },
      });
    }
  } catch (error) {
    store.dispatch(setDisplayAdvertisementData(null));
    store.dispatch(setLoading(false));
    store.dispatch(
      setAlertMessage({
        status: alertMessages?.ERROR,
        message: error?.response?.data,
      })
    );
    console.log("error", error);
  }
};
