import React, { useState, useRef, useEffect, Fragment } from "react";
import "./advertisementCardStyle.css";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Autocomplete,
  Box,
  CircularProgress,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import UploadImageSvg from "../icons/UploadImageSvg";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import ImageIcon from "@mui/icons-material/Image";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  updateAdvertisement,
  uploadAdvertisement,
  viewAddApi,
  viewUploadFiles,
} from "./advertisementApi";
import Carousel from "../carousel/Carousel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  setAdvertisementExpand,
  setHideHeader,
} from "../../kotTokenDisplaySlice";
import {
  setDisplayAdvertisementData,
  setIsEditAdv,
  setLoading,
} from "./advertisementSlice";
import AlertMessage from "../../../FloorManagement/components/alertMessage/AlertMessage";
import videoIcon from "../icons/videoIcon.png";
import MediaDialog from "../dialog/PreviewDialog";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { switch_to_billing_screen } from "../../../Sales/Billing/newBillingSlice";
import { useNavigate } from "react-router-dom";
const AdvertisementCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDetails, branchDetails } = useSelector(
    (state) => state.mainDashBoardSlice
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const { advertisementExpand, hideHeader } = useSelector(
    (state) => state.kotTokenDisplaySlice
  );
  const { displayAdvertisement, alertMessage, loading, isEditAdv } =
    useSelector((state) => state.advertisementSlice);
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);

  const initialState = {
    branch: null,
    selectScreen: 1,
    files: [],
    duration: 1,
    deletedImageIds: [],
  };

  const [formData, setFormData] = useState(initialState);
  const [dialogData, setDialogData] = useState({
    status: false,
    url: null,
    format: null,
  });

  useEffect(() => {
    const { branch } = formData;
    if (branchDetails || branch) {
      dispatch(setDisplayAdvertisementData(null));
      viewUploadFiles({
        body: { branchId: branchDetails?._id || branch?._id },
      });
    }
  }, [branchDetails, formData?.branch]);

  const handleChange = (key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const updatedFiles = [...formData.files];

    newFiles.forEach((newFile) => {
      const duplicateFile = updatedFiles.find(
        (file) => file.name === newFile.name && file.size === newFile.size
      );

      if (!duplicateFile) {
        updatedFiles.push(newFile);
      }
    });

    setFormData((prev) => ({
      ...prev,
      files: updatedFiles,
    }));
  };

  const handleDurationChange = (change) => {
    setFormData((prev) => ({
      ...prev,
      duration: Math.max(1, Math.min(60, prev.duration + change)),
    }));
  };

  const handleDeleteImage = (index, id) => {
    const fileInput = document.getElementById("file-upload");
    fileInput.value = "";
    setFormData((prev) => ({
      ...prev,
      files: prev.files.filter((_, i) => i !== index),
      deletedImageIds: [...prev.deletedImageIds, id],
    }));
  };

  const handleBranchChange = (e, value) => {
    setFormData((prev) => ({
      ...prev,
      branch: value,
    }));
  };

  const handleSubmit = () => {
    dispatch(setLoading(true));
    // console.log(formData);
    const formDataToSend = new FormData();

    formDataToSend.append(
      "branchId",
      formData?.branch?._id || branchDetails?._id
    );
    formDataToSend.append("slideInterval", formData?.duration);
    formDataToSend.append(
      "imageIdsToDelete",
      JSON.stringify(formData?.deletedImageIds)
    );

    // Filter files that do not have an _id property
    const filesToAppend = formData.files.filter((file) => !file._id);

    // Append the filtered files to the FormData
    filesToAppend.forEach((file) => formDataToSend.append("file", file));

    // for (const entry of formDataToSend.entries()) {
    //   console.log(entry);
    // }

    const sendBody = {
      body: formDataToSend,
      branchId: branchDetails?._id,
    };
    if (displayAdvertisement) {
      formDataToSend.append("advId", displayAdvertisement?._id);
      updateAdvertisement(sendBody);
    } else {
      uploadAdvertisement(sendBody);
    }
  };

  useEffect(() => {
    if (displayAdvertisement) {
      const advertisementMedia = displayAdvertisement?.imageUrl || [];
      // console.log("ad media--", advertisementMedia);
      const uploadedFiles = [];

      // Create a set to store unique file names
      const uniqueFileNames = new Set(formData.files.map((file) => file.name));

      // Iterate through advertisement media
      advertisementMedia.forEach(async (media) => {
        // Mark the callback function as async
        // Extract file name from URL
        const fileName = media.url.substring(media.url.lastIndexOf("/") + 1);

        // Check if the media is a video
        const isVideo = /\.(mp4|webm|mov|avi|wmv)$/i.test(fileName);

        if (isVideo) {
          // If it's a video, add video icon image
          try {
            const videoIconBlob = await fetch(videoIcon).then((response) =>
              response.blob()
            );
            const videoIconFile = new File(
              [videoIconBlob],
              `video-icon-${fileName}.png`,
              { type: "image/png" }
            );
            videoIconFile._id = media?._id;
            videoIconFile.url = media?.url;
            videoIconFile.format = "video";

            // Add video icon file to uploaded files
            uploadedFiles.push(videoIconFile);
          } catch (error) {
            console.error("Error creating video icon blob:", error);
          }
        } else {
          // If it's not a video, fetch and process image
          try {
            const response = await fetch(media.url);
            const blob = await response.blob();
            // Create a File object from the Blob
            const file = new File([blob], fileName, { type: blob.type });
            file._id = media?._id;
            file.url = media?.url;
            file.format = "image";

            // Update formData state to include the new file
            uploadedFiles.push(file);
          } catch (error) {
            console.error("Error fetching media:", error);
          }
        }
      });

      // Update other formData fields if necessary
      setFormData((prev) => ({
        ...prev,
        branch: allBranchesList.find(
          (res) => res?._id === displayAdvertisement?.branchId
        ),
        selectScreen: displayAdvertisement?.screenType,
        duration: displayAdvertisement?.slideInterval,
        files: uploadedFiles,
      }));
    }
  }, [displayAdvertisement]);

  useEffect(() => {
    dispatch(setHideHeader(true));
  }, []);

  const handlePreviewItem = (url, format) => {
    setDialogData({ status: true, url, format });
  };
  const handleClose = () => {
    setDialogData({ status: false, url: null, format: null });
  };

  const handleClearForm = () => {
    const fileInput = document.getElementById("file-upload");
    fileInput.value = "";
    setFormData(initialState);
  };

  return (
    <div className="token-display-advertisement-container">
      <div className="token-advertisement-wrapper">
        {/* expand */}
        <div className="token-expand-btn">
          <div>
            <button
              onClick={() =>
                dispatch(setAdvertisementExpand(!advertisementExpand))
              }
            >
              {advertisementExpand ? (
                <ArrowForwardIosIcon color="error" />
              ) : (
                <ArrowBackIosIcon color="error" />
              )}
            </button>
          </div>
        </div>
        {/* update  */}
        {!isEditAdv && !advertisementExpand && (
          <div className="token-expand-btn update-adv">
            <div>
              <button onClick={() => dispatch(setIsEditAdv(!isEditAdv))}>
                <EditIcon />
              </button>
            </div>
          </div>
        )}
        {/* close */}
        {
          <div className="token-expand-btn close-adv">
            <div>
              <button
                onClick={() =>{
                  dispatch(switch_to_billing_screen({ billingBoolean: false }))
                  navigate("/userdashboard/dashboardhome")
                  dispatch(setHideHeader(false));

                }
                }
              >
                <HighlightOffIcon color="error" />
              </button>
            </div>
          </div>
        }

        {userRole === "admin" ? (
          <div className="advertisement-card">
            <div className="adv-branch-selector-cntr">
              <Autocomplete
                id="tags-outlined"
                options={allBranchesList || []}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Branch"
                    value={formData?.branch}
                  />
                )}
                onChange={handleBranchChange}
                value={formData?.branch}
                filterOptions={(options, state) => {
                  const filteredOptions = options.filter(
                    (option) =>
                      option?.branchName
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase()) ||
                      option?.storeCode
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                  );

                  return filteredOptions.filter(
                    (option) => formData.branch?._id !== option?._id
                  );
                }}
              />
            </div>
            <div>
              {displayAdvertisement && (
                <Carousel
                  items={displayAdvertisement?.imageUrl}
                  duration={displayAdvertisement?.slideInterval * 1000}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="advertisement-card">
            <div>
              {displayAdvertisement && !isEditAdv && branchDetails &&  (
                <Carousel
                  items={displayAdvertisement?.imageUrl}
                  duration={displayAdvertisement?.slideInterval * 1000}
                />
              )}
            </div>
          </div>
        )}

        {/* upload advertisement */}
        {isEditAdv &&  (
          <div className="add-advertisement-card">
            <div className="upload-add-header">
              <span>ADS UPLOAD</span>
              <div className="token-display-advertisement-action-button">
                <button
                  type="clear-advertisement"
                  onClick={() => handleClearForm()}
                >
                  CLEAR
                </button>
                {loading ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <button
                    type="update-advertisement"
                    onClick={() => handleSubmit()}
                  >
                    UPDATE
                  </button>
                )}
              </div>
            </div>
            <hr />
            <div className="adv-file-upload-cntr">
              <div className="ticket-advertisement-image-upload-container">
                <div className="image-name-container">
                  <span>
                    {formData.image
                      ? formData.image.name
                      : "Upload image / video"}
                  </span>
                </div>
                <div className="upload-add-image-button-container">
                  <label htmlFor="file-upload" className="custom-file-upload">
                    <UploadImageSvg />
                    &nbsp; Upload
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/*,video/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    multiple
                  />
                </div>
              </div>
              <div className="ticket-advertisement-image-upload-container adv-duration-cntr">
                <div>
                  <QueryBuilderIcon color="info" />
                </div>
                <input
                  type="number"
                  name="duration"
                  id="duration"
                  value={formData.duration}
                  readOnly
                />
                <div className="advr-in-de-btn-cntr">
                  <IconButton
                    aria-label="increase duration"
                    onClick={() => handleDurationChange(1)}
                  >
                    <ArrowDropUpIcon />
                  </IconButton>
                  <IconButton
                    aria-label="decrease duration"
                    onClick={() => handleDurationChange(-1)}
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                </div>
              </div>
            </div>
            <div className="adv-upload-img-preview-cntr">
              {formData?.files?.map((file, index) => (
                <div
                  key={index}
                  className="ticket-advertisement-image-upload-container"
                >
                  <div
                    className="adv-prev-img"
                    onClick={() => handlePreviewItem(file.url, file.format)}
                  >
                    {file.type.startsWith("image/") ? (
                      <img src={URL.createObjectURL(file)} alt={file.name} />
                    ) : file.type.startsWith("video/") ? (
                      // Render video thumbnail if it's a video file
                      <video controls>
                        <source
                          src={URL.createObjectURL(file)}
                          type={file.type}
                        />
                      </video>
                    ) : (
                      <span>Unsupported file type</span>
                    )}
                  </div>
                  <div className="">{file.name}</div>
                  <div className="adv-dlt-cntr">
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImage(index, file._id)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <MediaDialog dialogData={dialogData} handleClose={handleClose} />
    </div>
  );
};

export default AdvertisementCard;

// -----------------------------------------------

export const SquareComponent = ({ squares, selectedScreen }) => {
  const getSquareStyle = (index) => {
    switch (squares) {
      case 1:
        return { width: "100%", height: "100%" };
      case 2:
        return { width: "100%", height: "48%" };
      case 3:
        return { width: "100%", height: `calc(92% / 3)` };
      case 4:
        return { width: "48%", height: "48%" };
      default:
        return { width: "auto", height: "auto" };
    }
  };

  return (
    <div
      className="outer-square"
      style={{ "--border-color": selectedScreen === squares && "#000000" }}
    >
      {[...Array(squares)].map((_, i) => (
        <Fragment key={i}>
          <div
            className={`inner-square ${
              i === 1 ? "top-left" : i === 2 ? "top-right" : ""
            }`}
            style={getSquareStyle(i)}
          >
            {" "}
            <ImageOutlinedIcon
              sx={{ color: selectedScreen === squares ? "#000000" : "#c5c5c5" }}
            />
          </div>
          <div
            className={`inner-square ${
              i === 1 ? "bottom-left" : i === 2 ? "bottom-right" : ""
            }`}
            style={getSquareStyle(i)}
          >
            {" "}
            <ImageOutlinedIcon
              sx={{ color: selectedScreen === squares ? "#000000" : "#c5c5c5" }}
            />
          </div>
          {squares === 3 && (
            <div className={`inner-square center `} style={getSquareStyle(i)}>
              {" "}
              <ImageOutlinedIcon
                sx={{
                  color: selectedScreen === squares ? "#000000" : "#c5c5c5",
                }}
              />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};
