import React, { useEffect, useState } from 'react'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { addCustomerFromReservation, editCustomerFromReservation } from '../newBillingAPI';
import { useDispatch, useSelector } from 'react-redux';
import countryList from "../../../../../Assets/JSON/countryStates.json"
import CustomeKeyBoard from '../../../../Single Components/CustomeKeyBoard';
import { setVisibleKeyboard } from '../../../../Single Components/customKeyBoardSlice';
function AddCustomerFromReservation(props) {

    const enableKeybooard = localStorage.getItem("enableKeybooard");
    const{setCustomerPopup}=props
    const countryWithState=countryList.countries
    const dispatch=useDispatch()
    const userInfo = useSelector((state) => state.loginResponseSlice.value);
    const {customerSingleInfo,customerSearchTerm} = useSelector((state) => state.topBarSliceBilling);
    const {keyBoardVisible} = useSelector((state) => state.customKeyBoardSlice);

    const formValueInitial={
        name:"",
        mobile:isNaN(Number(customerSearchTerm))?"":customerSearchTerm,
        country:null,
        state:null,
        city:"",
        street:"",
        buildingNo:"",
        buildingName :"",
        roomNo:""
    }
    const [formValues,setFormValues]=useState(formValueInitial)
    const [validationAlert,setValidationAlert]=useState({})
    const [addMoreInfo,setAddMoreInfo]=useState(false)
    const formOnChange=(key,value)=>{
        setFormValues({...formValues,[key]:value})
        setValidationAlert({})
    }



    const handleFocus = (inputName) => {
      dispatch(setVisibleKeyboard(inputName))
    };

    const saveCustomer=()=>{
        let customerFormData=new FormData()

        customerFormData.append("name", formValues?.name)
        customerFormData.append("mobileNo", formValues?.mobile)
        formValues.country!==null && customerFormData.append("country",formValues.country?.country)
        formValues.state!==null && customerFormData.append("state",formValues.state)
        formValues.city!=="null" && customerFormData.append("city",formValues.city)
        formValues.street!=="" && customerFormData.append("streetName",formValues.street)
        formValues.buildingNo!=="" && customerFormData.append("buildingNo",formValues.buildingNo)
        formValues.buildingName!=="" && customerFormData.append("buildingName",formValues.buildingName)
        formValues.roomNo!=="" && customerFormData.append("roomNo",formValues.roomNo)
        
        // default values
        customerFormData.append("branchId", userInfo?.branchPk)
        customerFormData.append("status", true)
        customerFormData.append("isCustomer", true)
        customerFormData.append("isVendor", false)
        
        customerSingleInfo!==null && customerFormData.append("_id", customerSingleInfo?._id)

        if(formValues?.name===""){
            setValidationAlert({...validationAlert,name:"fill this field !!"})
          }
          else if(formValues?.mobile===""){
            setValidationAlert({...validationAlert,mobile:"fill this field !!"})
          }
          else{
            let postFn=()=>{
                setCustomerPopup("customerAdded")
                setFormValues(formValueInitial)
            }
           customerSingleInfo?editCustomerFromReservation(customerFormData,postFn): addCustomerFromReservation(customerFormData,postFn)
          }
    }

    useEffect(()=>{
        if(customerSingleInfo){
          setFormValues({...formValues,
            name: customerSingleInfo?.name || "",
            mobile: customerSingleInfo?.mobileNo || "",
            city: customerSingleInfo?.city || "",
            street: customerSingleInfo?.streetName || "",
            buildingNo: customerSingleInfo?.buildingNo || "",
            buildingName: customerSingleInfo?.buildingName || "",
            roomNo: customerSingleInfo?.roomNo || ""
          })
          setAddMoreInfo(true)
        }else{
          setFormValues(formValueInitial)
        }
    },[customerSingleInfo])
    return (
      <div className='flex-column' style={{alignItems:"center"}}>
            <div className='wallet-change-dialog'>
                  <div className="head justify-center">
                    <div className="icon justify-center">
                        <PeopleAltIcon/>
                    </div>
                       {customerSingleInfo?"Edit":"Create New"} Customer

                    <div className="wallet-change-btn" style={{marginLeft:"auto"}}>
                    {
                    !keyBoardVisible&&
                     <button
                        onClick={saveCustomer}
                        className="btn-primary btn">{customerSingleInfo ? "Edit" : "Save"}</button>}

                      {/* {!addMoreInfo &&
                              <p 
                              onClick={()=>setAddMoreInfo(true)}
                              className="doc-validation-alert">Add more fields</p>
                            } */}
                    </div>
                  </div>

                  <div className="customer-popup-input-ctn">

                  <div className="input-ctn">
                    <button disabled className="btn">Name</button>
                    <input type="text" 
                      value={formValues?.name}
                      onChange={(e)=>formOnChange("name",e.target.value)}
                      style={{textAlign:"start",paddingLeft:"10px"}}
                      onFocus={() => handleFocus('name')}
                      // onBlur={handleBlur}
                    />
                    <p className="doc-validation-alert">{validationAlert?.name}</p>
                  </div>
                  <div className="input-ctn">
                    <button disabled className="btn">Mobile</button>
                    <input type="text" 
                    value={formValues?.mobile}
                    onChange={(e)=>formOnChange("mobile",e.target.value)}
                    style={{textAlign:"start",paddingLeft:"10px"}}
                    onFocus={() => handleFocus('mobile')}
                    />
                    <p className="doc-validation-alert">{validationAlert?.mobile}</p>
                  </div>
                  {
                    // addMoreInfo &&
                    <>  
                    <div className="input-ctn">
                      <button disabled className="btn">City</button>
                      <input type="text" 
                      value={formValues?.city}
                      onChange={(e)=>formOnChange("city",e.target.value)}
                      style={{textAlign:"start",paddingLeft:"10px"}}
                      onFocus={() => handleFocus('city')}
                      />                   
                    </div>
                    <div className="input-ctn">
                      <button disabled className="btn">Street</button>
                      <input type="text" 
                      value={formValues?.street}
                      onChange={(e)=>formOnChange("street",e.target.value)}
                      style={{textAlign:"start",paddingLeft:"10px"}}
                      onFocus={() => handleFocus('street')}
                      />                   
                    </div>
                    <div className="input-ctn">
                      <button disabled className="btn">Building Name</button>
                      <input type="text" 
                      value={formValues?.buildingName}
                      onChange={(e)=>formOnChange("buildingName",e.target.value)}
                      style={{textAlign:"start",paddingLeft:"10px"}}
                      onFocus={() => handleFocus('buildingName')}
                      />                   
                    </div>
                    <div className="input-ctn">
                      <button disabled className="btn">Building No</button>
                      <input type="text" 
                      value={formValues?.buildingNo}
                      onChange={(e)=>formOnChange("buildingNo",e.target.value)}
                      style={{textAlign:"start",paddingLeft:"10px"}}
                      onFocus={() => handleFocus('buildingNo')}
                      />                   
                    </div>
                    <div className="input-ctn">
                      <button disabled className="btn">Room No</button>
                      <input type="text" 
                      value={formValues?.roomNo}
                      onChange={(e)=>formOnChange("roomNo",e.target.value)}
                      style={{textAlign:"start",paddingLeft:"10px"}}
                      onFocus={() => handleFocus('roomNo')}
                      />       
                    </div>

                    </>
                  }
                  </div>
                 



            </div>
            {
              // enableKeybooard==="true"&&
              keyBoardVisible&&
              <CustomeKeyBoard
              input={formValues[keyBoardVisible]}
              setInput={(value) => formOnChange(keyBoardVisible,value)}
              enterClick={saveCustomer}
              />
            }
            </div>
  )
}

export default AddCustomerFromReservation