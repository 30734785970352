import { createSlice } from "@reduxjs/toolkit"


export const reservationSlice=createSlice({
    name:"reservationSlice",
    initialState:{
        reservationList:[],
        selectedReservation:null,
        reservationName:null
    },
    reducers:{
        storeReserevationList:(state,action)=>{
            state.reservationList=action.payload
        },
        storeSingleReserevation:(state,action)=>{
            state.selectedReservation=action.payload
        },
        setReservationName:(state,action)=>{
            state.reservationName=action.payload
        }
    }
})

export const{storeReserevationList,storeSingleReserevation,setReservationName}=reservationSlice.actions
export default reservationSlice.reducer