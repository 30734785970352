import axios from "axios"
import { HEADERS } from "../../../API/UrlAndPaths"
import store from "../../../Redux/store"
import { setErrorSnack, setSnackMsg, setSuccessSnack } from "../Sales/Billing/Slice/billingScreenSlice"
import { get_configuration_dataList, get_rowMaterial_data, list_cost_analysis, list_manufacture_order_data, list_order_cost_analysis, list_recipe_data, list_single_view, list_single_view_order } from "./FoodManagementSlice"
import { setSubmitLoading } from "../../../Redux/Dashboard/mainDashBoardSlice"

//add config//
export const configurationFoodAPI = async (body, draftFn) => {
    store.dispatch(setSubmitLoading(true))
    await axios.post("foodManagement/workInProgressUpdate", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(setSubmitLoading(false))
                store.dispatch(setSnackMsg("Configuration Added"))
                store.dispatch(setSuccessSnack(true))
                draftFn()
            }

        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))
            if (err.response.status === 500) {
                store.dispatch(setSnackMsg("Internal Server Error !!"))
            } else {
                store.dispatch(setSnackMsg(err.response.data))
            }
            store.dispatch(setErrorSnack(true))

        })
}
//get congig//
export const getConfigAPI = async (body) => {
    await axios.post("foodManagement/getWorkInProgressConfiguration", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_configuration_dataList(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(get_configuration_dataList(undefined))


        })
}

//to get row material//
export const RowMaterialAPI = async (body) => {
    await axios.post("readymade/productListforBom", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_rowMaterial_data(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(get_rowMaterial_data(undefined))


        })
}

//add bom//
export const RecipeFoodAddAPI = async (body) => {
    store.dispatch(setSubmitLoading(true))
    await axios.post("foodManagement/addRecipe", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(setSubmitLoading(false))
                store.dispatch(setSnackMsg("Configuration Added"))
                store.dispatch(setSuccessSnack(true))
                SingleViewRecipeAPI({ recipeId: res.data._id })
                localStorage.setItem("recipe_id", res.data._id)
            }
        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))
            if (err.response.status === 500) {
                store.dispatch(setSnackMsg("Internal Server Error !!"))
            } else {
                store.dispatch(setSnackMsg(err.response.data))
            }
            store.dispatch(setErrorSnack(true))

        })
}

//list of recipe//
export const ListRecipeAPI = async () => {
    await axios.post("foodManagement/viewRecipes", {}, HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch(list_recipe_data(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(list_recipe_data(undefined))


        })
}

//single view recipe//

export const SingleViewRecipeAPI = async (body) => {
    await axios.post("foodManagement/singleViewRecipe", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(list_single_view(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(list_single_view(undefined))


        })
}

// delete recipe//
export const DeleteRecipeAPI = async (body) => {
    store.dispatch(setSubmitLoading(true))
    await axios.post("foodManagement/deleteRecipe", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(setSubmitLoading(false))
                store.dispatch(setSnackMsg("deleted"))
                store.dispatch(setSuccessSnack(true))
                ListRecipeAPI();

            }
        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))
            if (err.response.status === 500) {
                store.dispatch(setSnackMsg("Internal Server Error !!"))
            } else {
                store.dispatch(setSnackMsg(err.response.data))
            }
            store.dispatch(setErrorSnack(true))

        })
}
//edit//
export const editedRecipeFoodAPI = async (body, draftFn) => {
    store.dispatch(setSubmitLoading(true))
    await axios.post("foodManagement/editRecipe", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(setSubmitLoading(false))
                store.dispatch(setSnackMsg("Edited"))
                store.dispatch(setSuccessSnack(true))
                draftFn()
            }
        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))
            if (err.response.status === 500) {
                store.dispatch(setSnackMsg("Internal Server Error !!"))
            } else {
                store.dispatch(setSnackMsg(err.response.data))
            }
            store.dispatch(setErrorSnack(true))

        })
}

//cost analysis//

export const costAnalysisAPI = async (body) => {
    await axios.post("foodManagement/viewRecipeCostAnalysis", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(list_cost_analysis(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(list_cost_analysis(undefined))


        })
}

//add manufacturing order//

export const ManufacturingOerderAddAPI = async (body) => {
    store.dispatch(setSubmitLoading(true))
    await axios.post("foodManagement/addManufacturingOrder", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(setSubmitLoading(false))
                store.dispatch(setSnackMsg("Manufacturing Order Added"))
                store.dispatch(setSuccessSnack(true))
                SingleViewOrderAPI({ manufacturingOrderId: res.data._id })
                localStorage.setItem("order_id", res.data._id)
            }
        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))
            if (err.response.status === 500) {
                store.dispatch(setSnackMsg("Internal Server Error !!"))
            } else {
                store.dispatch(setSnackMsg(err.response.data))
            }
            store.dispatch(setErrorSnack(true))

        })
}

//list manufacture order//

export const ListManufactureAPI = async () => {
    await axios.post("foodManagement/viewManufacturingOrders", {}, HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch(list_manufacture_order_data(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(list_manufacture_order_data(undefined))


        })
}
//single view order//

export const SingleViewOrderAPI = async (body) => {
    await axios.post("foodManagement/singleViewManufacturingOrders", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(list_single_view_order(res.data))
            }
        })
        .catch((err) => {
            store.dispatch(list_single_view_order(undefined))


        })
}

//order edit

export const editedManufactureOrderAPI = async (body, draftFn) => {
    store.dispatch(setSubmitLoading(true))
    await axios.post("foodManagement/editManufacturingOrder", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(setSubmitLoading(false))
                store.dispatch(setSnackMsg("Edited"))
                store.dispatch(setSuccessSnack(true))
                draftFn()
            }
        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))
            if (err.response.status === 500) {
                store.dispatch(setSnackMsg("Internal Server Error !!"))
            } else {
                store.dispatch(setSnackMsg(err.response.data))
            }
            store.dispatch(setErrorSnack(true))

        })
}

//post manufacturing order//
export const postManufacturingOrderAPI = async (body) => {
    store.dispatch(setSubmitLoading(true))
    await axios.post("foodManagement/postManufacturingOrders", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(setSubmitLoading(false))
                store.dispatch(setSnackMsg("Posted"))
                store.dispatch(setSuccessSnack(true))
  const singleViewId = localStorage.getItem("order_id")

                SingleViewOrderAPI({ manufacturingOrderId:singleViewId })
            }
            store.dispatch(setSubmitLoading(false))

        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))
            if (err.response.status === 500) {
            store.dispatch(setSnackMsg(err.response.data))
            }
            else{
                store.dispatch(setSnackMsg(err.response.data))
            }
            store.dispatch(setErrorSnack(true))
        })
}
//cancel manufacturing order//
export const cancelManufacturingOrderAPI = async (body) => {
    store.dispatch(setSubmitLoading(true))
    await axios.post("foodManagement/draftCancelManufacturingOrder", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                if(body.type===1){
                    store.dispatch(setSnackMsg("Cancelled"))
                }
                if(body.type===2){
                    store.dispatch(setSnackMsg("Drafted"))
                }
                store.dispatch(setSubmitLoading(false))
               
                store.dispatch(setSuccessSnack(true))
                SingleViewOrderAPI({ manufacturingOrderId: res.data._id })
           
            }

        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))
            if (err.response.status === 500) {
            store.dispatch(setSnackMsg(err.response.data))
            }
            else{
                store.dispatch(setSnackMsg(err.response.data))
            }
        })
}


//manufacturing cost analysis//

export const ManufacturingcostAPI = async (body) => {
    await axios.post("foodManagement/viewMOCostAnalysis", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(list_order_cost_analysis(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(list_order_cost_analysis(undefined))


        })
}