import React from 'react'
import { useSelector } from 'react-redux';

const SalesTable = () => {
    const shiftSalesReport = useSelector(
        (state) => state.posReportshiftSlice.shiftReportSale);
// console.log(shiftSalesReport);
const decimalPosition = localStorage.getItem("decimalPosition");

const formatDate = (dateTimeString) => {
    return dateTimeString.split(' ')[0]; // Split the string by space and return the first part (the date)
};
        return (
    <div className="new-global-table-container" style={{ paddingTop: "0 42px" }}>
    <table>
        <thead>
            <tr>
                <th>Date</th>
                <th>Order Reff</th>
                <th>Customer</th>
                <th>Mobile</th>
                <th>Delivery Charge</th>
                <th>Order Amount</th>
                <th>Paid Amount</th>
                <th>Balance</th>
            </tr>
        </thead>
        <tbody>
            {shiftSalesReport?.sale!==undefined? shiftSalesReport?.sale?.map((r, i) => (
                <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                    <td style={{ border: "none", height: "29px" }}>{formatDate(r.orderDate)}</td>
                    <td style={{ border: "none", height: "29px" }}>{r.name}</td>
                    <td style={{ border: "none", height: "29px" }}>{r.customerName}</td>
                    <td style={{ border: "none", height: "29px" }}>{r.mobileNo}</td>
                    <td style={{ border: "none", height: "29px" }}>{r.deliveryCharge}</td>
                    <td style={{ border: "none", height: "29px" }}>{r.amtTotal}</td>
                    <td style={{ border: "none", height: "29px" }}>{r.paidAmt}</td>
                    <td style={{ border: "none", height: "29px" }}>{r.amountDue}</td>
                </tr>
            )):(
                <tr>
                    <td colSpan={8}>
                        NO DATA
                    </td>
                </tr>
            )}
        </tbody>
        <tfoot>
        <tr style={{backgroundColor:"rgb(150 148 148)"}}>
           <td colSpan={5} style={{textAlignLast:"end",paddingInline:"10px"}}>TOTAL</td> 
           <td style={{textAlignLast:"center"}}>{shiftSalesReport?.sale?.reduce((acc, current) => acc + Number(current?.amtTotal || 0), 0)?.toFixed(decimalPosition)}</td>
           <td style={{textAlignLast:"center"}}>{shiftSalesReport?.sale?.reduce((acc, current) => acc + Number(current?.paidAmt || 0), 0)?.toFixed(decimalPosition)}</td>
           <td style={{textAlignLast:"center"}}>{shiftSalesReport?.sale?.reduce((acc, current) => acc + Number(current?.amountDue || 0), 0)?.toFixed(decimalPosition)}</td>
        </tr>
    </tfoot>
    </table>
</div>
  )
}

export default SalesTable