import {createSlice} from '@reduxjs/toolkit'

export const viewShiftSlice=createSlice({
    name:"viewShiftSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_shift:(state,action)=>{
            state.value=action.payload
        }
    }
})
export const {get_shift} = viewShiftSlice.actions;
export default viewShiftSlice.reducer;