export const bankReTable = {
  rt: "RECONCILIATED TRANSACTION",
  cb: "COMPANY BOOK",
  bs: "BANK STATEMENT",
};
export const actionType = {
  edit: "edit",
  save: "save",
  import: "import",
  cancel: "cancel",
  post: "post",
  map: "map",
  back: "back",
  restToDraft:'reset to draft'
};
export const brStatus = {
  pending: "PENDING",
  reconciled: "RECONCILED",
  draft: "DRAFT",
  cancel: "CANCEL",
  done: "DONE",
  new: "NEW",
};

export const snackbarNotification = {
  success: "success",
  error: "error",
  warning:'warning'
}


// Helper function to convert field name to a more readable format
export const getFieldName = (fieldName) => {
  if (!fieldName) return '';
  const words = fieldName.replace(/([A-Z])/g, ' $1').trim().split(' ');
  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

//date to millisecond
export const dateToMilliseconds = (dateString) => {
  if (!dateString || typeof dateString !== 'string') {
    return
    throw new Error('Invalid date string');
  }

  // Split the date string into components
  const [day, month, year] = dateString.split("-").map(Number);

  // Create a new Date object with the provided date components
  const date = new Date(year, month - 1, day);

  // Get the milliseconds since the Unix epoch
  return date.getTime();
};
