import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DeletePosCategoryApiCall,
  PosCategoryFilterAPICall,
} from "./posCategoryAPI";
import { useSelector } from "react-redux";
import { IconButton, Skeleton } from "@mui/material";
import store from "../../../../../Redux/store";
import { posCategorySingleView } from "./posCategorySlice";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";

export const POSCategory = () => {
  const posCategoryList = useSelector(
    (state) => state?.posCategorySlice?.tableList
  );
  const filtereDataOptions = useSelector((state) => state.listCategory);

  const [IsModify, setIsModify] = useState(false);
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = posCategoryList?.pages;
  const [isLoading, setIsLoading] = useState(false);

  const [selectedBranch, setSelectedBranch] = useState(null);
  let navigate = useNavigate();
  const createWareHouse = () => {
    navigate("/userdashboard/inventory/configuration/poscategory/create");
  };



  //singleViewOfPOS
  const singleViewOfPOS = (r) => {
    store.dispatch( setFilterActive(window.location.pathname))
    navigate("/userdashboard/inventory/configuration/poscategory/create");
    // store.dispatch(posCategorySingleView({singleViewOfPos:r}))
    localStorage.setItem("singleViewById", r._id);
  };
  //updateListner
  const updateListner = () => {
    setIsModify(!IsModify);
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  //DeleteSinglePosCat
  const DeleteSinglePosCat = (id) => {
    DeletePosCategoryApiCall(id, updateListner);
  };
  useEffect(() => {
    // posCategoryListAPICall();
    store.dispatch(posCategorySingleView({ singleViewOfPos: undefined }));
    localStorage.removeItem("singleViewById");
  }, [IsModify]);
  useEffect(() => {
    if(FormDataInfo.length!==0){ 
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData &&
   PosCategoryFilterAPICall({
    category: FormDataInfo.category,
    search: FormDataInfo.search,
    index: currentPage-1,
  },setIsLoading);
}
}, [FormDataInfo, currentPage]);

  return (
    <div className="global-page-parent-container updated-global-page-parent-container">
      <div className="justify-space-between list-create-head-bar">
        <h3>Pos Category </h3>
        <div className="create-head-buttons-container">
          <CategorySearchandFilter
              statusOptions={['Completed', 'Drafted','Available']}
              onData={handleDataFromChild}
              isBranch={false}
              isDate={false}
              isCategory={true}
              onBranchSelect={handleBranchSelect}
            />
          <button onClick={createWareHouse} className="create-button-blue btn btn-primary">
            Create
          </button>
        </div>
      </div>

      <div className="justify-space-between pagination-container">
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          setFormDataInfo={setFormDataInfo}
        />
      </div>
      <div className="global-table-container updated-table-container">
        <table className="global-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Main Category</th>
              <th>Parent</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {
              isLoading ? (
                [...Array(20)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) :
                posCategoryList?.list?.length > 0 ? (
                  posCategoryList?.list.map((r, i) => (
                    <tr>
                      <td onClick={() => singleViewOfPOS(r)}>{r?.name}</td>
                      <td onClick={() => singleViewOfPOS(r)}>{r?.catId}</td>
                      <td onClick={() => singleViewOfPOS(r)}>{r?.parentName}</td>
                      <td>
                        <IconButton onClick={() => DeleteSinglePosCat(r?._id)}>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>No Data</td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
