import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";

const ConfirmationDialog = ({ open, onClose, onConfirm }) => {
  const { kotOrderStatus } = useSelector(
    (state) => state.kitchenOrderTicketSlice
  );
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{/*Confirmation*/}</DialogTitle>
      <DialogContent>
        Are you sure you want to select all orders for&nbsp;
        {kotOrderStatus.index === 1
          ? "COOKING"
          : kotOrderStatus.index === 2
          ? "COMPLETION"
          : kotOrderStatus.index === 3
          ? "DELIVERY"
          : ""}
        ?
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="error" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
