import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "../../../../css/Settings/CompanySettings/profileInfo.css";
import { Select, Dialog, MenuItem, Autocomplete, TextField } from "@mui/material";
import { AddOutlet } from "../../../Single Components/AddOutlet";
import {
  editCompanyProfileInfoAPICall,
  viewCompanyProfileInfoAPICall,
} from "../../../../API/Settings/Company Settings/companySettingsAPI";
import CountryListWithCode from "../../../../Assets/JSON/countryListWithCode.json";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import Currency from "../../../../Assets/JSON/currencies.json";
import { filterObjFromList, validateFileSize } from "../../../../Js/generalFunctions";
import { viewAllCurrencyUpdated } from "../../../../API/Settings/Currency/CurrencyAPI";
export const ProfileInfo = () => {
  let currentCountry = localStorage.getItem("country");
  //Company Profile Data
  const companyProfileData = useSelector(
    (state) => state.companyProfileSlice.value
  );
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  //updateListener states
  const [isModify, setIsModify] = useState(false);
  //Snackbar states
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");

  //Add Outlet Location popup State
  const [addOutletPopup, setAddOutletPopup] = useState(false);
  // Profile Form State
  const profileInfoFormInitialState = {
    companyName: "",
    companyNameNative: "",
    companyEmail: "",
    contactAddress: "",
    contactPerson: "",
    website: "",
    logoInfo: "Select logo",
    logo: null,
    country: "0",
    currency: "0",
    language: "0",
    termsAndConditions: "",
    termsAndConditionsNative: "",
  };
  const [profileInfoForm, setProfileInfoForm] = useState(
    profileInfoFormInitialState
  );
  const cancelAddOutletPopUP = () => {
    setAddOutletPopup(false);
  };

  //* Assign Data to respective States
  useEffect(() => {
    if (companyProfileData !== undefined) {
      setProfileInfoForm({
        ...profileInfoForm,
        companyName: companyProfileData?.profile?.companyName,
        companyNameNative: companyProfileData?.profile?.nativeCompanyName,
        companyEmail: companyProfileData?.profile?.companyEmail,
        contactAddress: companyProfileData?.profile?.companyAddress,
        contactPerson: companyProfileData?.profile?.contactPerson,
        website: companyProfileData?.profile?.website,
        logo: companyProfileData?.profile?.logo,
        country: companyProfileData?.profile?.country,
        currency: filterObjFromList("_id",currencyListUpdated,"currency",companyProfileData?.profile),
        // currency: companyProfileData?.profile?.currency,
        language: companyProfileData?.profile?.language,
        termsAndConditions: companyProfileData?.terms,
        termsAndConditionsNative: companyProfileData?.nativeTerms,
        logoInfo: companyProfileData?.profile?.logo?.split("/")[3],
      });
    }
  }, [companyProfileData,currencyListUpdated]);

  //*onChange
  const getProfileInfoData = (key) => (e,newValue) => {
    const { value, files } = e.target;
    switch (key) {
      case "companyNameNative":
        setProfileInfoForm({ ...profileInfoForm, companyNameNative: value });
        break;
        case "contactAddress":
          setProfileInfoForm({ ...profileInfoForm, contactAddress: value });
          break;
      case "contactPerson":
        setProfileInfoForm({ ...profileInfoForm, contactPerson: value });
        break;
      case "website":
        setProfileInfoForm({ ...profileInfoForm, website: value });
        break;
      case "country":
        setProfileInfoForm({ ...profileInfoForm, country: value });
        break;
      case "currency":
        setProfileInfoForm({ ...profileInfoForm, currency: newValue });
        break;
      case "language":
        setProfileInfoForm({ ...profileInfoForm, language: value });
        break;
      case "termsAndConditions":
        setProfileInfoForm({ ...profileInfoForm, termsAndConditions: value });
        break;
      case "termsAndConditionsNative":
        setProfileInfoForm({
          ...profileInfoForm,
          termsAndConditionsNative: value,
        });
        break;

      default:
        break;
    }
  };

  //updateListener
  const updateListener = () => {
    setIsModify(!isModify);
  };
  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnack(false);
  };
  //Close errorSnackbar
  const closeErrorSnackbar = () => {
    setOpenErrorSnack(false);
  };

  const formData = new FormData();
  formData.append("companyName", profileInfoForm?.companyName);
  formData.append("nativeCompanyName", profileInfoForm?.companyNameNative);
  formData.append("companyEmail", profileInfoForm?.companyEmail);
  formData.append("companyAddress", profileInfoForm?.contactAddress);
  formData.append("contactPerson", profileInfoForm?.contactPerson);
  formData.append("website", profileInfoForm?.website);
  formData.append("file", profileInfoForm?.logo);
  formData.append("country", profileInfoForm?.country);
  formData.append("currency", profileInfoForm?.currency?._id);
  formData.append("language", profileInfoForm?.language);
  formData.append("terms", profileInfoForm?.termsAndConditions);
  formData.append("nativeTerms", profileInfoForm?.termsAndConditionsNative);

  const clickProfileUpdateButton = () => {
    editCompanyProfileInfoAPICall(
      formData,
      setOpenSuccessSnack,
      setOpenErrorSnack,
      setSnackMsg,
      updateListener
    );
  };

  //* API Calls
  useEffect(() => {
    viewCompanyProfileInfoAPICall();
    viewAllCurrencyUpdated({ status: 0 });
  }, [isModify]);

  return (
    <>
      <div className="profile-info-container">
        <div className="profile-input-container">
          <p>Company Name</p>
          <input value={profileInfoForm.companyName} disabled />
        </div>
        {currentCountry !== "india" && (  
          <div className="profile-input-container arabic-name arabic">
            <p style={{ width: "17%", textAlign: "right" }}>اسم الشركة</p>
            <input
              dir="rtl"
              value={profileInfoForm.companyNameNative}
              onChange={getProfileInfoData("companyNameNative")}
            />
          </div>
         )}
        <div className="profile-input-container">
          <p>Company Email</p>
          <input value={profileInfoForm.companyEmail} disabled />
        </div>
        <div className="profile-input-container">
          <p>Contact Address</p>
          <textarea rows="3" 
          value={profileInfoForm.contactAddress} 
          onChange={getProfileInfoData("contactAddress")}
          />
        </div>
        <div className="profile-info-add-outlet-btn">
          <button
            onClick={() => setAddOutletPopup(true)}
            className="btn btn-primary"
          >
            Add Outlet
          </button>
        </div>
        <div className="profile-input-container">
          <p>Contact Person </p>
          <input
            placeholder="Enter contact number..."
            value={profileInfoForm.contactPerson}
            onChange={getProfileInfoData("contactPerson")}
          />
        </div>
        <div className="profile-input-container">
          <p>Website</p>
          <input
            placeholder="Enter website address..."
            value={profileInfoForm.website}
            onChange={getProfileInfoData("website")}
          />
        </div>
        <div className="profile-input-container profile-info-logo-upload-container">
          <p>Logo</p>
          <input
            value={profileInfoForm?.logo?.name}
            placeholder={profileInfoForm?.logoInfo}
            style={{ caretColor: "transparent" }}
          />
          <div className="profile-info-logo-upload">
            <label className="profile-info-logo-file-upload">
              <input
                type="file"
                onChange={(e) => {
                  let file = e.target.files[0];
                  let result = validateFileSize(file, 1, 450);
                  if (result?.isValid) {
                    setProfileInfoForm({
                      ...profileInfoForm,
                      logo: file,
                    });
                  } else {
                    alert(
                      "Select image with size less than 450 kb,current size " +
                        result?.actualFileSize +
                        " kb"
                    );
                  }
                  // setProfileInfoForm({
                  //   ...profileInfoForm,
                  //   logo: e.target.files[0],
                  // });
                }}
              />
              Browse
            </label>
          </div>
        </div>
        <div className="profile-input-container">
          <p>Country</p>
          <Select
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "1px solid rgb(206, 199, 199)",
              },
            }}
            value={profileInfoForm.country}
            onChange={getProfileInfoData("country")}
          >
            <MenuItem value="0">-select a country-</MenuItem>
            {CountryListWithCode.map((item, i) => (
              <MenuItem value={item?.name.toLowerCase()}>{item?.name}</MenuItem>
            ))}
          </Select>
        </div>
        <div className="profile-input-container auto-complete-country">
          <p>Currency</p>
          <Autocomplete
            options={currencyListUpdated || []}
            getOptionLabel={(option)=>option?.name}
            renderInput={(params)=>(<TextField {...params}/>)}
            value={profileInfoForm.currency}
            onChange={getProfileInfoData("currency")}
          />
          {/* <Select
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "1px solid rgb(206, 199, 199)",
              },
            }}
            value={profileInfoForm.currency}
            onChange={getProfileInfoData("currency")}
          >
            {Currency?.map((item, i) => (
              <MenuItem key={i} value={item.cc}>
                {item?.name}
              </MenuItem>
            ))}
          </Select> */}
        </div>
        <div className="profile-input-container">
          <p>Language</p>
          <Select
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "1px solid rgb(206, 199, 199)",
              },
            }}
            value={profileInfoForm.language}
            onChange={getProfileInfoData("language")}
          >
            <MenuItem value="0">-no languages specified-</MenuItem>
            <MenuItem value="english">English</MenuItem>
            <MenuItem value="arabic">Arabic</MenuItem>
          </Select>
        </div>
        <div className="profile-input-container">
          <p>Terms And Conditions</p>
          <textarea
            value={profileInfoForm.termsAndConditions}
            onChange={getProfileInfoData("termsAndConditions")}
          />
        </div>
        {currentCountry !== "india" && (
          <div className="profile-input-container arabic-name arabic">
            <p style={{ width: "24%", textAlign: "right" }}>الأحكام والشروط</p>
            <textarea
              dir="rtl"
              value={profileInfoForm.termsAndConditionsNative}
              onChange={getProfileInfoData("termsAndConditionsNative")}
            />
          </div>
        )}
      </div>
      <div>
        <button
          className="btn btn-primary profile-info-save-btn"
          onClick={clickProfileUpdateButton}
        >
          Save
        </button>
      </div>

      {/* add outlet popup */}
      <Dialog open={addOutletPopup} maxWidth="lg" onKeyDown={(e)=>e.key === "Escape" && setAddOutletPopup(false)} onClose={()=>setAddOutletPopup(false)}>
        <AddOutlet cancelDialog={cancelAddOutletPopUP} />
      </Dialog>

      {/* Snackbars  */}
      <SuccessSnackbar
        open={openSuccessSnack}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnack}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  );
};
