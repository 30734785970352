import { createSlice } from "@reduxjs/toolkit";

export const billingFloorPlanSlice=createSlice({
  name:"billingFloorPlanSlice",
  initialState: {
    selectedFloorDetails:undefined,
    selectedChairNames:[],
    selectedTableNames:[],
  },
  reducers:{
    getFloorDetails:(state,action)=>{
      state.selectedFloorDetails=action.payload
    }
    ,setTables:(state,action)=>{
        state.selectedTableNames=action.payload
    },
    setChairs:(state,action)=>{
        state.selectedChairNames=action.payload
    }
  }  
})

export const {setTables,setChairs,getFloorDetails}=billingFloorPlanSlice.actions;

export default billingFloorPlanSlice.reducer