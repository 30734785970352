import axios from "axios";
import store from "../../../../../Redux/store";
import { HEADERS, HEADER_FORM_DATA } from "../../../../../API/UrlAndPaths";
import {
  generate_purchase_w_po_name,
  get_purchase_w_po_list,
  get_purchase_w_po_row_data_list,
  get_purchasewopo_history,
} from "./purchaseWPOlist";
import { setSubmitLoading } from "../../../../../Redux/Dashboard/mainDashBoardSlice";

// view purchase wso

export const viewPurchaseWPOAPICall = async (body, setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true);
  await axios
    .post("account/viewPurchaseWpoAc", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_purchase_w_po_list({ purchaseWPOData: res.data }));
        setIsLoading !== undefined && setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(get_purchase_w_po_list({ purchaseWPOData: undefined }));
      setIsLoading !== undefined && setIsLoading(false);
    });
};
export const PurchaseWOPOFilterAPICall =(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)
  axios.post("account/searchPurchaseWpoAc",body,{
    headers: {
      'Content-Type': 'application/json',
    },
    
  })
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_purchase_w_po_list({purchaseWPOData:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_purchase_w_po_list({purchaseWPOData:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)
  })

}
// generate purchase wso draft name 

export const generatePurchaseWPONameAPICall = (body) => {
  axios
    .post("account/generatepurchasewponumber", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          generate_purchase_w_po_name({ purchaseWPOName: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        generate_purchase_w_po_name({ purchaseWPOName: undefined })
      );
    });
};

// draft purchase wso api call

export const draftPurchaseWPOAPICall = async (
  body,
  postFn,
  setState,
  state,
  setEditDataId,
  setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios
    .post("account/addPurchaseWpoAcDraft", body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)
        setState({ ...state, message: "purchase W/PO Drafted", success: true });
        postFn();
        setEditDataId(res.data?._id);
        purchaseWoSoSingleViewApi({ _id: res.data._id });
        localStorage.setItem("singleViewById", res.data._id);
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if(err.response.status === 500){
        setState({ ...state, message: "Internal Server error !", error: true });
      }
      else{
        setState({ ...state, message:err?.response?.data, error: true });

      }
     
    });
};
// edit purchase wso api call

export const editPurchaseWPOAPICall = async (body, postFn, setState, state,setLoadingForm) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios
    .put("account/editPurchaseWpoAc", body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)
        setState({ ...state, message: "purchase W/PO Edited", success: true });
        postFn();
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if (err.response.status === 500) {
        setState({ ...state, message:"Internal Server error !", error: true });
      } else {
        setState({ ...state, message:err?.response?.data, error: true });
      }
     
    });
};
// post purchase wso api call

export const postPurchaseWPOAPICall = async (body, postFn, setState, state,setLoadingForm) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios
    .post("account/postpurchasewpo", body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)
        setState({ ...state, message: "purchase W/PO Posted", success: true });
        postFn();
        purchaseWoSoSingleViewApi({ _id: res.data._id });
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if (err.response.status === 500) {
        setState({ ...state, message:"Internal Server error !", error: true });
      } else {
        setState({ ...state, message: err?.response?.data, error: true });
      }
   
    });
};

// purchase woso single view api
export const purchaseWoSoSingleViewApi = async (body, setState) => {
  store.dispatch(setSubmitLoading(true))
  await axios
  .post("account/viewPurchaseWpoAcById", body, HEADERS)
  .then((res) => {
    if (res.status === 200) {
      store.dispatch(
        get_purchase_w_po_row_data_list({ purchaseWPOSingleData: res.data })
      );
      store.dispatch(setSubmitLoading(false))
    }
  })
  .catch((err) => {
    store.dispatch(setSubmitLoading(false))
    setState((prev) => ({
        ...prev,
        message: "Something went wrong !!",
        error: true,
      }));
    });
};
//post registered payment
export const purchaseWpoRegisterPaymentPostAPICall = (
  body,
  registerPymentCancelBtn,
  setSnackBarStates,
  setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  axios
    .post("account/registerPaymentFromPWpo", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)
        purchaseWoSoSingleViewApi({ _id: body.pwpoId });
        setSnackBarStates((prev) => ({
          ...prev,
          message: "payment registered",
          success: true,
        }));
        registerPymentCancelBtn();
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if(err.response.status === 500){
        setSnackBarStates((prev) => ({
          ...prev,
          message: "Internal Server error !",
          error: true,
        }));
      }
      else{
        setSnackBarStates((prev) => ({
          ...prev,
          message: err?.response?.data,
          error: true,
        }));
      }
     
    });
};

// Purchase wopo history
export const purchaseWOPOHistoryAPICall=(body)=>{
  axios.post("purchase/purchasewpoHistory",body,HEADERS)
  .then((res)=>{
     if(res.status===200){
       store.dispatch(get_purchasewopo_history({historyData:res.data}))
     }
  })
  .catch((err)=>{
    store.dispatch(get_purchasewopo_history({historyData:undefined}))
  })
}

