import { createSlice } from "@reduxjs/toolkit";

export const viewCurrencyUpdatedSlice=createSlice({
    name:'viewUpdatedCurrency',
    initialState:{
        value:undefined,
        filteredCurrencyList:undefined
    },
    reducers:{
        all_currency_updated:(state,action)=>{
            state.value=action.payload.currencyUpdatedData
        },
        set_currency_filtered_list:(state,action)=>{
            state.filteredCurrencyList=action.payload
        }
    }
})
export const {all_currency_updated,set_currency_filtered_list} = viewCurrencyUpdatedSlice.actions;
export default viewCurrencyUpdatedSlice.reducer