import {
  Autocomplete,
  Dialog,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import postRibbon from "../../../../../Assets/statusRibbon/post.png";
import returnRibbon from "../../../../../Assets/statusRibbon/return.png";
import draftRibbon from "../../../../../Assets/statusRibbon/draft.png";
import uploadFile from "../../../../../Assets/Images/Icon feather-upload.png";
import { CloseOutlined } from "@mui/icons-material";
import logoImage from "../../../../../Assets/Images/Icon ionic-logo-buffer.png";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentTime, today } from "../../../../../Js/Date";
import { useNavigate } from "react-router-dom";
import {
  findObjFromList,
  roundNumericFields,
} from "../../../../../Js/generalFunctions";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import {
  viewUOMSubListAPICall
} from "../../../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall";
import { vieWareHouseLocationAPICall } from "../../../Inventory/Configuration/wareHouseLocationAPI";
import {
  deliveryNoteSingleViewAPICall,
  editDeliveryNotesAPICall,
  generateCustomerInvoiceDraftFromDeliveryNote,
  postDeliveryNotesAPICall,
  postReturnDeliveryNoteAPICall,
} from "./deliveryNoteAPI";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import { set_Back_button_action, setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import store from "../../../../../Redux/store";
import { get_wareHouse_location_list } from "../../../Inventory/Configuration/wareHouseLocationSlice";
import { customerFilterOptions } from "../../../../Single Components/GneralUtilities";

export const CreateDeliiveryNote = () => {
  const singleRowId = localStorage.getItem("singleViewById");



  // stored data from redux
  const editedData = useSelector((state) => state.deliveryNoteSlice.rowData);
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  const allNewProductList = useSelector(
    (state) => state.vendorBillSlice.NewProductList
  );
  const uomSubList = useSelector(
    (state) => state.unitOfMeasurmentSlice.subUomList
  );

  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const wareHouseLocationList = useSelector(
    (state) => state.wareHouseLocationSlice.value
  );

  // defining to navigate to another page
  let navigateTo = useNavigate();
  const dispatch = useDispatch();

  // useStates
  const [creditNoteName, setCreditNoteName] = useState("###");
  const [editDataId, setEditDataId] = useState("");
  const [returnProduct, setReturnProduct] = useState(false);
  const [loadingForm,setLoadingForm]=useState(false)

  // table initial values
  const tableDataInitial = {
    type: "lines",
    product: null,
    uom: null,
    demandQty: "",
    doneQty: "",
  };
  const [tableData, setTableData] = useState([tableDataInitial]);

  // useStates
  const [image, setImage] = useState([]);
  const [imageDisplay, setImageDisplay] = useState([]);
  const [logView, setLogView] = useState(false);
  const [isLoading, setIsLoading] = useState("");
  const [editClicked, setIsEditClicked] = useState(false);
  const containerRef = useRef(null);
  const [validationAlert, setValidationAlert] = useState({});
  const [rowIndex, setRowIndex] = useState({});
  const [log, setLog] = useState([]);
  const [returnValidationAlert, setReturnValidationAlert] = useState(-1);

  // snackbar states
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false,
  });
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };

  // initial form input values
  const formInitialState = {
    customer: null,
    salesOrder: "",
    branch: null,
    location: null,
    date: today,
    reference: "",

    customerNotes: "",
    termsAndConditions: "",
    salesOrderId: "",
    sourceDocument: "",

    isEdit: false,
    status: "",
    id: "",
  };

  const [formValues, setFormValues] = useState(formInitialState);
  const [returnProductList, setReturnProductList] = useState();

  const clearForm = () => {
    setFormValues(formInitialState);
    setTableData([tableDataInitial]);
    setEditDataId("");
  };

  // delete a row from the table
  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  // upload file functions
  const changeImage = (e) => {
    setImage([...image, e.target.files[0]]);
    setImageDisplay([...imageDisplay, URL.createObjectURL(e.target.files[0])]);
  };
  const removeImageFn = (i) => {
    image.splice(i, 1);
    imageDisplay.splice(i, 1);
    setImage([...image]);
    setImageDisplay([...imageDisplay]);
  };

  // view logs enabled
  const clickLogView = () => {
    setLogView(!logView);
  };
  // form info onchange fn
  const getFormInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    setValidationAlert({});
    setFormValues({ ...formValues, [key]: value !== 0 ? value : newValue });
  };
  // table data onchnage for each rows

  const handleQtyChange = (qty, index) => (e) => {
    const { value } = e.target;

    setRowIndex(index);
    setValidationAlert({});
    const receivedQty = Number(value);

    if (receivedQty > qty) {
      setValidationAlert({ ...validationAlert, maxQty: true });
    }
    tableData[index].doneQty = receivedQty > qty ? qty : Number(value);
    setTableData([...tableData]);
  };
  const handleReturnQuantityChange = (qty, index) => (e) => {
    const { value } = e.target;
    setRowIndex(index);
    setReturnValidationAlert({});
    const rtqty = Number(value);
  
    if (rtqty > qty) {
      setReturnValidationAlert({ ...validationAlert, maxQty: true });
    }
  
    setReturnProductList((prevReturnProductList) => {
      const updatedList = [...prevReturnProductList];
      updatedList[index] = { ...updatedList[index], returnQuantity: rtqty > qty ? qty : rtqty };
      return updatedList;
    });
  };

  // functon used to set the decimalpositioning
  const handleMouseEnter = () => {
    const updatedArray = roundNumericFields(tableData, [
      "demandQty",
      "doneQty",
    ]);
    setTableData(updatedArray);
  };
  

  // setting product info array to send in request
  const tableDataToBackend = tableData?.map((item) =>
    item?.type === "lines"
      ? {
          line_type: item?.type,
          _id: item?.product?._id,
          mainCatgeoryId: item?.product?.mainCatgeoryId,
          product: item?.product?.productName,
          label: item?.label,
          uomName: item?.uom?.uomName,
          purchaseUomName: item?.product?.purchaseUomName,
          purchaseuom: item?.product?.purchaseuom,
          cost: item?.product?.cost,
          uom: item?.uom?._id,
          qty: Number(item?.qty),
          unitPrice: item?.unitPrice,
          discount: item?.discount,
          includedPrice: item?.inclusive === "" ? null : item?.inclusive,
          taxes: item?.tax,
          taxIdList:item?.taxIdList,
          uuid: item?.uuid,
          returnQuantity: item?.returnQuantity,
          description: "",
          deliveredQuantity: Number(item?.doneQty),
          orderQuantity: Number(item?.demandQty),
          prodType:item?.prodType
        }
      : {
          line_type: item?.type,
          label: item?.header,
        }
  );

  // creating formdata
  const formData = new FormData();

  // appending key and values
  /*required*/ formData.append("sourceDocument", formValues?.sourceDocument);
  /*required*/ formData.append(
    "date",
    `${formValues?.date} ${getCurrentTime()}`
  );
  /*required*/ formData.append("fromLocation", formValues?.location?._id);
  /*required*/ formData.append("customerId", formValues?.customer?._id);
  /*required*/ formData.append("salesInfo", JSON.stringify(tableDataToBackend));
  formValues?.reference !== "" &&
    formData.append("reference", formValues?.reference);
  /*required*/ formData.append("branchId", formValues?.branch?._id);
  /*required*/ formData.append("salesOrderId", formValues?.salesOrderId);
  formValues?.customerNotes !== "" &&
    formData.append("customerNote", formValues?.customerNotes);
  formValues?.termsAndConditions !== "" &&
    formData.append("termsAndCondition", formValues?.termsAndConditions);

  image.length !== 0 &&
    image.forEach((file, index) => {
      formData.append("imageUrl", file);
    });
  /*required*/ formData.append("postDate", `${today} ${getCurrentTime()}`);

  // function to send api requests
  const submitBtnClicked = (key) => () => {
    dispatch( setFilterActive(false))
    if (formValues?.customer === null || formValues?.customer === undefined) {
      setValidationAlert({
        ...validationAlert,
        customer: "fill this field !!", 
      });
    } else if (
      formValues?.salesOrderId === "" ||
      formValues?.salesOrderId === undefined
    ) {
      setValidationAlert({
        ...validationAlert,
        salesOrder: "fill this field !!",
      });
    } else if (
      formValues?.branch === null ||
      formValues?.branch === undefined
    ) {
      setValidationAlert({ ...validationAlert, branch: "fill this field !!" });
    } else if (
      formValues?.location === null ||
      formValues?.location === undefined
    ) {
      setValidationAlert({
        ...validationAlert,
        location: "fill this field !!",
      });
    } else {
      if (key === "edit") {
        let draftFn = () => {
          setFormValues({ ...formValues, isEdit: true });
          setIsEditClicked(false);
        };
        formData.append("_id", editDataId);
        editDeliveryNotesAPICall(
          formData,
          draftFn,
          setSnackBarStates,
          snackBarStates,setLoadingForm
        );
      }
      if (key === "post") {
        let draftFn = () => {
          setFormValues({ ...formValues, isEdit: true, status: "POSTED" });
        };
        let payload = {
          deliveryNoteId: editDataId,
          branchId: formValues?.branch?.storeCode,
          postDate: `${today} ${getCurrentTime()}`,
        };
        postDeliveryNotesAPICall(
          payload,
          draftFn,
          setSnackBarStates,
          snackBarStates,setLoadingForm
        );
      }
    }
  };

  const handleButtonClicked = (key) => {
    if (key === "submitReturnProduct") {
      const sortProductArray = returnProductList.filter(
        (res) => res.returnQuantity !== 0 && res.deliveredQuantity >= res.returnQuantity
      );
      let returnBody = {
        postDate: today,
        mainDeliveryNoteId: formValues.id,
        salesInfo: sortProductArray,
      };
      if (sortProductArray.length > 0) {
        postReturnDeliveryNoteAPICall(
          returnBody,
          setSnackBarStates,
          setReturnProduct,
          formValues.id,setLoadingForm
        );
      }
    } else if (key === "return") {
      setReturnProduct(true);
    }
  };
  const createCustomerInvoice = () => {
    let draftFn = () => {
      navigateTo("/userdashboard/accounts/customers/customerInvoices/create");
    };
    let bodyToBill = {
      deliveryNoteId: editedData?._id,
      postDate: today,
    };
    generateCustomerInvoiceDraftFromDeliveryNote(
      bodyToBill,
      draftFn,
      setSnackBarStates,
      snackBarStates
    );
  };

  const handleDeleteReturnProductList = (id) => {
    const filteredData = returnProductList.filter((_, i) => i !== id);
    setReturnProductList(filteredData);
  };

  // useEffects

  // initial api calls for listing dats
  useEffect(() => {
    viewUOMSubListAPICall();
    if (singleRowId) {
      deliveryNoteSingleViewAPICall({ _id: singleRowId });
    }
  }, []);

  // edit
  useEffect(() => {

    if (editedData !== undefined && allBranchesList!==undefined && uomSubList!==undefined) {
      setEditDataId(editedData?._id);
      setCreditNoteName(editedData?.name);
      setFormValues({
        ...formValues,
        isEdit: true,
        status: editedData?.status,

        customer: findObjFromList(
          "_id",
          customerList,
          "customerId",
          editedData
        ),
        branch: findObjFromList(
          "_id",
          allBranchesList,
          "branchId",
          editedData
        ),
        location: findObjFromList(
          "_id",
          wareHouseLocationList,
          "fromLocation",
          editedData
        ),
        salesOrder: editedData?.salesOrderName || "",

        salesOrderId: editedData?.salesOrderId || "",


        sourceDocument: editedData?.sourceDocument || "",
        customerNotes: editedData?.customerNote || "",
        termsAndConditions: editedData?.termsAndCondition || "",
        id: editedData?._id || "",
        isBillGenerated:editedData?.isBillGenerated,
        isDeliveryReturn:editedData?.isDeliveryReturn
      });

      const updatedArray = editedData?.salesInfo?.map((r, i) =>{
        if(r?.line_type === "Line" || r?.line_type === "lines"){

           return{
              type: r?.line_type,
              product: findObjFromList("_id", allNewProductList, "_id", r),
              uom: findObjFromList("_id", uomSubList, "uom", r),
              demandQty: r?.orderQuantity || "",
              doneQty: r?.deliveredQuantity ,
              label: r?.label || "",
              qty: r?.qty || "",
              unitPrice: r?.unitPrice,
              discount: r?.discount,
              tax: r?.taxes,
              taxIdList:r?.taxIdList,
              amount:
                r?.qty * r?.unitPrice -
                  (r?.qty * r?.unitPrice * r?.discount) / 100 || "",
              inclusive: r?.includedPrice,
              uuid: r?.uuid || "",
              returnQuantity: r?.returnQuantity,
              prodType:r?.prodType
            }
            }
          else {
            return{

              type: r?.line_type,
              header: r?.label,
            }
            }
          }
      );

      setReturnProductList(editedData?.salesInfo);
      setTableData(updatedArray);
      setLog(editedData?.log);
    } else {
      clearForm();
    }
  }, [
    editedData,
    allBranchesList,
    uomSubList,
    wareHouseLocationList,
  ]);
  // setting default branch in user Case

  useEffect(() => {
    if (formValues?.branch !== null) {
      vieWareHouseLocationAPICall({ branchId: formValues?.branch?._id });
    }else{
      store.dispatch(get_wareHouse_location_list({wareHouseLocationData:undefined}))
    }
  }, [formValues?.branch]);


  
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !formValues?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formValues?.isEdit]);


  return (
    <div className="global-page-parent-container">
      <div
        className="new-global-white-bg-container"
        style={{padding: "0 0", backgroundColor: "unset" }}
      >
        <div className="new-global-white-bg-icon-container" style={{backgroundColor:"white",paddingInline:"10px"}}>
          {isLoading ? (
            <Skeleton
              sx={{ marginTop: "1%" }}
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={40}
            />
          ) : (
            <div
              className="new-global-white-bg-icon-container-left"
              style={{width:"60%",display:"flex",alignItems:"center"}}
            >
              {/* <Tooltip
                title="Back"
                onClick={() => {
                  if (formValues?.isEdit === false) {
                    const userConfirmed = window.confirm(
                      "Changes you made may not be saved."
                    );

                    if (userConfirmed) {
                      navigateTo("/userdashboard/sales/orders/deliveryNote");
                      editedData!==undefined&& dispatch(set_Back_button_action({ backButtonClickAction: true }));
                    }
                  } else {
                    navigateTo("/userdashboard/sales/orders/deliveryNote");
                    editedData!==undefined&& dispatch(set_Back_button_action({ backButtonClickAction: true }));
                  }
                }}
              >
                <IconButton>
                  <i class="bi bi-chevron-left"></i>
                </IconButton>
              </Tooltip> */}
              {formValues?.status === "DRAFT" && (
                <Tooltip title="Edit">
                  {editClicked ? (
                    <IconButton onClick={submitBtnClicked("edit")}>
                      <i class="bi bi-floppy save-icon"></i>
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setFormValues({ ...formValues, isEdit: false });
                        setIsEditClicked(true);
                      }}
                      onMouseEnter={handleMouseEnter}
                    >
                      <i class={"bi bi-pencil-square edit-icon1"}></i>
                    </IconButton>
                  )}
                </Tooltip>
              )}

              {formValues?.status === "CANCEL" && (
                <Tooltip title="Reset to Draft">
                  <IconButton>
                    <i class="bi bi-floppy save-icon"></i>
                  </IconButton>
                </Tooltip>
              )}

              {formValues?.status === "DRAFT" && !editClicked && (
                <>
                  <button
                    className="btn btn-post"
                    onClick={submitBtnClicked("post")}
                  >
                    Post
                  </button>
                </>
              )}
              {formValues?.status === "POSTED" && formValues?.isBillGenerated === false && (
                <button
                  onClick={createCustomerInvoice}
                  className="btn btn-post"
                >
                  Create Invoice
                </button>
              )}
              {formValues?.status === "POSTED" && formValues?.isBillGenerated === false && (
                <button
                  className="btn btn-post"
                  onClick={() => handleButtonClicked("return")}
                >
                  Return
                </button>
              )}
              
          <h3 style={{display:"flex"}}>
            <span
              
              style={{ fontSize: "medium",color: "#676666", cursor: "pointer" }}
              onClick={() => {
                if (formValues?.isEdit === false) {
                  const userConfirmed = window.confirm(
                    "Changes you made may not be saved."
                  );

                  if (userConfirmed) {
                    navigateTo("/userdashboard/sales/orders/deliveryNote");
                    editedData!==undefined&& dispatch(set_Back_button_action({ backButtonClickAction: true }));
                  }
                } else {
                  navigateTo("/userdashboard/sales/orders/deliveryNote");
                  editedData!==undefined&& dispatch(set_Back_button_action({ backButtonClickAction: true }));
                }
              }}
            >Delivery Note &gt;</span>
           <span style={{fontSize:"medium",paddingLeft:"8px"}}>{!isLoading && creditNoteName}</span>
          </h3>
        
            </div>
          )}
         
        </div>
        {/* <hr className="global-hr" /> */}
        <div style={{backgroundColor:"white",padding:"10px",marginTop:"6px",position:"relative"}}>

        <div className="status-container" style={{right:"0"}}>
            {!isLoading && formValues?.status === "DRAFT" && (
              <img src={draftRibbon} alt="" />
            )}
            {!isLoading && formValues?.status === "POSTED" && (
              <img src={postRibbon} alt="" />
            )}
            {!isLoading && formValues?.status === "RETURNED" && (
              <img src={returnRibbon} alt="" />
            )}
          </div>
        <h4 className="global-name">{!isLoading && creditNoteName}</h4>
        {isLoading ? (
          <Skeleton
            sx={{ margin: "1% 0" }}
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={150}
          />
        ) : (
          <div className="new-global-input-container">
            <div className="new-global-single-input auto-complete-new">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={customerList || []}
                filterOptions={customerFilterOptions}

                getOptionLabel={(option) => option?.name}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Customer*" focused />
                )}
                onChange={getFormInfo("customer")}
                value={formValues?.customer}
                disabled
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <span>{option.name}</span>
                  </li>
                )}

              />
              <p className="doc-validation-alert">
                {validationAlert?.customer}
              </p>
            </div>

            <div className="new-global-single-input">
              <TextField
                value={formValues?.salesOrder}
                id="outlined-basic"
                label="Sales Order"
                variant="outlined"
                type="text"
                focused
                disabled
              />
              <p className="doc-validation-alert">
                {validationAlert?.salesOrder}
              </p>
            </div>

            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allBranchesList || []}
                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Branch*" focused />
                )}
                onChange={getFormInfo("branch")}
                value={formValues?.branch}
                disabled
              />
              <p className="doc-validation-alert">{validationAlert?.branch}</p>
            </div>
            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={wareHouseLocationList || []}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Location" focused />
                )}
                onChange={getFormInfo("location")}
                value={formValues.location}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.location}
              </p>
            </div>

            <div className="new-global-single-input">
              <TextField
                value={formValues?.date}
                onChange={getFormInfo("date")}
                id="outlined-basic"
                label="Date*"
                variant="outlined"
                type="date"
                focused
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.orderDate}
              </p>
            </div>

            <div className="new-global-single-input">
              <TextField
                value={formValues?.sourceDocument}
                onChange={getFormInfo("reference")}
                id="outlined-basic"
                label="Reference"
                variant="outlined"
                type="text"
                focused
                disabled={formValues?.isEdit}
              />
            </div>
          </div>
        )}
      </div>

      <div className="new-global-white-bg-container">
        {isLoading ? (
          <Skeleton
            sx={{ marginTop: "1%" }}
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={110}
          />
        ) : (
          <>
            <div className="global-product-table">
              <table disabled={formValues?.isEdit}>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>UOM</th>
                    <th>Demand Qty</th>
                    {formValues.status === "RETURNED"? <th>Return Quantity </th>  :<th>Done Qty</th>}
                  </tr>
                </thead>

                <tbody>
                  {tableData?.filter(obj=>obj?.prodType==="Goods")?.map((item, index) => (
                    <tr
                      key={index}
                      ref={
                        index === tableData?.length - 1 ? containerRef : null
                      }
                    >
                      {item.type === "header" ? (
                        <>
                          <td colSpan="9">
                            <input
                              type="text"
                              value={item.header}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                              disabled
                            />
                          </td>
                          <td style={{ border: "none", width: "4%" }}>
                            <IconButton
                              onClick={() => handleDelete(index)}
                              style={{ height: "30px" }}
                              disabled
                            >
                              <i
                                className="bi bi-trash3"
                                style={{ color: "#db0000", fontSize: "1.3rem" }}
                              ></i>
                            </IconButton>
                          </td>
                        </>
                      ) : (
                        <>
                          <td style={{ minWidth: "100px" }}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                options={allNewProductList || []}
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option) => option?.productName}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseEnter}
                                  />
                                )}
                                value={item?.product}
                                disabled
                              />
                            </div>
                          </td>
                          <td style={{ minWidth: "95px" }}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                options={uomSubList || []}
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option) => option?.uomName}
                                disabled
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseEnter}
                                  />
                                )}
                                value={item?.uom}
                              />
                            </div>
                          </td>
                          <td style={{ maxWidth: "90px" }}>
                            <input
                              type="text"
                              style={{ textAlign: "right" }}
                              value={item?.demandQty}
                              disabled
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                            />
                          </td>
                          <td
                            style={{ maxWidth: "90px", position: "relative" }}
                          >
                            <input
                              type="text"
                              style={{ textAlign: "right" }}
                              value={formValues?.status === "RETURNED" ?item?.returnQuantity :item?.doneQty}
                              onChange={handleQtyChange(item?.demandQty, index)}
                              disabled={formValues?.isEdit}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                            />
                            {rowIndex === index && validationAlert?.maxQty && (
                              <p
                                style={{
                                  top: "0%",
                                  right: "2%",
                                  fontWeight: "600",
                                }}
                                className="doc-validation-alert"
                              >
                                max : {item?.demandQty}
                              </p>
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>

      <div
        className="new-global-white-bg-container"
        style={{
          display: "flex",
          flexWrap: "wrap",
          padding: "8px 2%",
        }}
      >
        {isLoading ? (
          <Skeleton
            sx={{ marginTop: "1%" }}
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={110}
          />
        ) : (
          <>
            <div style={{ display: "flex", width: "97%" }}>
              <div className="new-global-single-input" style={{ width: "35%" }}>
                <p>Customer Notes</p>
                <textarea
                  value={formValues?.customerNotes}
                  onChange={getFormInfo("customerNotes")}
                  disabled={formValues?.isEdit}
                  name=""
                  id=""
                  cols="50"
                  rows="5"
                ></textarea>
              </div>

              <div className="new-global-single-input" style={{ width: "35%" }}>
                <p>Terms & Conditions</p>
                <textarea
                  value={formValues?.termsAndConditions}
                  onChange={getFormInfo("termsAndConditions")}
                  disabled={formValues?.isEdit}
                  name=""
                  id=""
                  cols="50"
                  rows="5"
                ></textarea>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "60%" }}
            >
              <div className="file-picker-new">
                <input
                  disabled={formValues?.isEdit}
                  type="file"
                  id="fileUpload"
                  onChange={changeImage}
                />
                <label htmlFor="fileUpload">
                  <img src={uploadFile} alt="" />
                  Upload File
                </label>
              </div>
              <div style={{ display: "flex", margin: "0 0 0 22px" }}>
                {imageDisplay?.map((r, i) => (
                  <div className="uploaded-image-list">
                    <img src={r} alt="" />
                    <div className="image-remove-icon-new">
                      <IconButton
                        disabled={formValues?.isEdit}
                        onClick={() => removeImageFn(i)}
                      >
                        <CloseOutlined
                          sx={{ color: "#fd0301", fontSize: "0.9rem" }}
                        />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>

      {editedData !== undefined && (
        <div
          className="new-global-white-bg-container new-log-container"
          style={{ padding: "4px" }}
        >
          <div
            style={{ padding: "8px", cursor: "pointer" }}
            onClick={clickLogView}
          ></div>
          <div
            className={logView ? "pointer-up" : "pointer"}
            onClick={clickLogView}
            style={{ cursor: "pointer" }}
          ></div>
          {logView && (
            <div className="single-log-container">
              {log?.map((r, i) => (
                <div className="single-log-item">
                  <div className="single-log-item-line-image">
                    <hr className="single-log-item-line" />
                    <div className="single-log-item-image">
                      <img src={logoImage} alt="" />
                    </div>
                    <hr className="single-log-item-line" />
                  </div>
                  <p className="heading">{r?.status}</p>
                  <p className="name">{r?.empId}</p>
                  <p className="date-time">{r?.date}</p>
                  <p className="date-time">{r?.time}</p>
                </div>
              ))}
            </div>
          )}

          {/* <div className='single-log-container-scroll-line'></div> */}
          <Dialog
            open={returnProduct}
            maxWidth="lg"
            sx={{ zIndex: 1210, marginTop: "2%" }}
            onKeyDown={(e) => e.key === "Escape" && setReturnProduct(false)}
          >
            <div className="add-expense-dialog-container">
              <div className="new-global-single-input">
                <TextField
                  id="outlined-basic"
                  label="Location*"
                  variant="outlined"
                  type="text"
                  focused
                  value={formValues?.location?.name}
                  // disabled
                />
              </div>
              <div
                className="global-white-bg-container add-table-selection"
                style={{ width: "100%" }}
              >
                <div className="global-page-parent-container">
                  <div className="purchase-order-main-container second-row-container">
                    <div className="top-bar">
                      <h3>Return Products</h3>
                    </div>
                    <div className="new-global-table-container">
                      <table>
                        <thead>
                          <tr>
                            {/* <th>Code</th> */}
                            <th>Product</th>
                            <th>UOM</th>
                            <th>Demand Qty</th>
                            <th>Done Qty</th>
                            <th>Return Quantity</th>
                            <th style={{ backgroundColor: "#fff" }}></th>
                          </tr>
                        </thead>

                        <tbody>
                          {returnProductList?.length !== 0 ? (
                            returnProductList?.map((r, i) => (
                              <tr
                                key={i}
                                style={{
                                  cursor:
                                    formValues?.status === "Complete" &&
                                    "not-allowed",
                                }}
                              >
                                {/* <td>{r?.code}</td> */}
                                <td>{r?.product}</td>
                                <td>{r?.uomName}</td>
                                <td style={{ width: "20%" }}>
                                  {r?.orderQuantity}
                                </td>
                                <td>{r?.deliveredQuantity}</td>
                                <td
                                  className="table-data-box"
                                  style={{
                                    width: "20%",
                                    textAlign: "center",
                                    position: "relative",
                                  }}
                                >
                                  {formValues?.status === "POSTED" ? (
                                    <input
                                    onFocus={(e)=>e.target.select()}
                                      type="number"
                                      id={i}
                                      value={r?.returnQuantity>r?.returnQtyLimit? r?.returnQtyLimit:r?.returnQuantity}
                                      onChange={handleReturnQuantityChange(
                                        r?.returnQtyLimit,
                                        i
                                      )}
                                    />
                                  ) : (
                                    r?.returnQuantity
                                  )}
                                  {rowIndex === i &&
                                    returnValidationAlert?.maxQty && (
                                      <p
                                        style={{
                                          top: "50%",
                                          right: "25%",
                                          fontWeight: "600",
                                        }}
                                        className="doc-validation-alert"
                                      >
                                        max : {r?.returnQtyLimit}
                                      </p>
                                    )}
                                </td>
                                <td style={{ border: "none", width: "4%" }}>
                                  <IconButton
                                    onClick={() =>
                                      handleDeleteReturnProductList(i)
                                    }
                                    style={{ height: "30px" }}
                                    // disabled={branchLocationForm?.isEdit}
                                  >
                                    <i
                                      className="bi bi-trash3"
                                      style={{
                                        color: "#db0000",
                                        fontSize: "1.3rem",
                                      }}
                                    ></i>
                                  </IconButton>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10}>No Data</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="expense-button-container">
              <button
                onClick={() => setReturnProduct(false)}
                className="btn btn-secondary-outlined"
              >
                Cancel
              </button>
              <button
                onClick={() => handleButtonClicked("submitReturnProduct")}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </Dialog>
        </div>
      )}
      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <LoadingForm loading={loadingForm}/>
</div>
    </div>
  );
};
