import React from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const DuoBarChart = () => {
  const { accountDashBoardData } = useSelector(
    (res) => res.accountDashboardSlice
  );

  // Function to format the ticks on the y-axis
  const formatYAxisTick = (tick) => {
    const SI_SYMBOL = ["", "K", "M", "B", "T"];
    const tier = (Math.log10(Math.abs(tick)) / 3) | 0;

    if (tier === 0) return tick;

    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);

    const scaledValue = tick / scale;

    // Formatting with one decimal place
    const formattedValue = scaledValue.toFixed(1);

    return formattedValue + suffix;
  };

  // Custom tooltip content to display revenue and COGS with two decimal places
  const CustomTooltip = ({ active, payload, label }) => {
    const tooltipStyle = {
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      border: "1px solid #ccc",
      padding: "10px",
    };
  
    if (active && payload) {
      return (
        <div className="custom-tooltip" style={tooltipStyle}>
          <p style={{ margin: 0 }}>{`Month: ${label}`}</p>
          <p style={{ margin: 0 ,color:'#00866b' }}>{`Revenue: ${payload[0]?.value?.toFixed(2)}`}</p>
          <p style={{ margin: 0 ,color:"#e41503"}}>{`COGS: ${payload[1]?.value?.toFixed(2)}`}</p>
        </div>
      ); 
    }
    return null;
  };

  return (
    <div
      style={{
        background: "white",
        padding: "2em 0",
        borderRadius: "10px",
        flex: 1,
      }}
    >
      <div style={{ padding: "0.5em", textAlign: "center" }}>
        <span>Operating Gross Profit</span>
      </div>

      <ResponsiveContainer height={400}>
        <BarChart
          width={1000}
          height={500}
          data={accountDashBoardData?.report[0]?.graph}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis tickFormatter={formatYAxisTick} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="revenue" fill="#00866b" name="Revenue" />
          <Bar dataKey="COGS" fill="#e41503" name="COGS" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DuoBarChart;
