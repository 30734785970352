import React from "react";
import "./kotSideMenuStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { setSortProduct } from "../../kitchenOrderTicketSlice";
import { listKotOrderAPI } from "../../kotAPI's";
const KotSideMenu = () => {
  const dispatch = useDispatch();
  const { sideMenu, sideMenuList, kotOrderStatus, sortProduct } = useSelector(
    (state) => state.kitchenOrderTicketSlice
  );
  const { branchPk } = useSelector((state) => state.loginResponseSlice.value);

  const handleClearFilter = () => {
    dispatch(setSortProduct(null));
    let body = {
      branchId: branchPk,
      status: kotOrderStatus?.status?.key,
    };
    listKotOrderAPI({ body });
  };

  return (
    <div
      style={{ maxWidth: sideMenu ? "0" : "20em",overflow:"hidden",minWidth: sideMenu ? "0" : "20em" }}
      className="kot-side-menu-container"
    >
      <div style={{opacity:sideMenu ? 0:1}} className="kot-side-menu-wrapper">
        <div className="kot-side-menu-header">
          <h2>Filtered Product </h2>
        </div>
        <div className="kot-side-menu-list-container">
          <ul>
            {sideMenuList?.map((res, i) => (
              <li
                className={
                  sortProduct?.productId === res?._id
                    ? "kot-filter-selected"
                    : ""
                }
                key={i}
                onClick={() =>
                  dispatch(
                    setSortProduct({
                      status: kotOrderStatus?.status?.key,
                      productId: res?._id,
                    })
                  )
                }
              >
                <span>{res?.name}</span> <span>{res?.totalQty}</span>
              </li>
            ))}
          </ul>
        </div>
        {sortProduct && <div className="kot-side-menu-footer">
          <button onClick={() => handleClearFilter()}>Clear Filter</button>
        </div>}
      </div>
    </div>
  );
};

export default KotSideMenu;
