import React, { useEffect, useState } from "react";
import "./style.css";
import KotHeader from "./components/header/KotHeader";
import { useDispatch, useSelector } from "react-redux";
import { switch_to_billing_screen } from "../Sales/Billing/newBillingSlice";
import KotSideMenu from "./components/sideMenu/KotSideMenu";
import KotBody from "./components/kotBody/KotBody";
import KotPopup from "./components/popUp/KotPopup";
import io from "socket.io-client";
import axios from "axios";
import {
  kotSideMenuListApi,
  listKotOrderAPI,
  viewKOTSortProductListAPI,
} from "./kotAPI's";
import { listAllBranches } from "../FloorManagement/apis/commonApi";
import {
  setDarkMode,
  setIsLoading,
  setKotOrderList,
  setSelectedKOTOrder,
  setAlertMessage,
  setNetworkStatus,
  setSortProduct,
  setKotOrderStatus,
} from "./kitchenOrderTicketSlice";
import { LoadingForm } from "../../Single Components/LoadingForm";
import AlertMessage from "../FloorManagement/components/alertMessage/AlertMessage";
import { NETWORK } from "./utils/kotEnum";
import { setHideHeader } from "../KotTokenDisplay/kotTokenDisplaySlice";
import { useLocation } from "react-router-dom";
// import { socket } from "../../../API/UrlAndPaths";

const KitchenOrderTicket = () => {
  // =============================
  const user = JSON.parse(localStorage.getItem("login_meta"));

  const userId = user?.data?._id;
  const location = useLocation();

  // =============================
  const dispatch = useDispatch();
  const {
    selectedKOTOrder,
    kotOrderList,
    isLoading,
    alertMessage,
    kotOrderStatus,
    darkMode,
    sortProduct,
    networkStatus,
  } = useSelector((state) => state.kitchenOrderTicketSlice);

  const { branchPk, data, ...rest } = useSelector(
    (state) => state.loginResponseSlice.value
  );

  const [sendBody, setSendBody] = useState({
    status: null,
    branchId: branchPk,
  });

  //initial actions
  useEffect(() => {
    dispatch(switch_to_billing_screen({ billingBoolean: true }));
    dispatch(setHideHeader(true));
    dispatch(setSelectedKOTOrder(null));
    const body = {
      branchId: branchPk,
    };

    if (branchPk) {
      listKotOrderAPI({ body });
    }
  }, [branchPk]);

  useEffect(() => {
    dispatch(setSortProduct(null));

    if (branchPk && kotOrderStatus?.status?.key) {
      kotSideMenuListApi({
        body: {
          branchId: branchPk,
          status: kotOrderStatus?.status?.key,
        },
      });
    }
  }, [kotOrderStatus, branchPk]);

  useEffect(() => {
    if (branchPk && kotOrderStatus?.status?.key) {
      kotSideMenuListApi({
        body: {
          branchId: branchPk,
          status: kotOrderStatus?.status?.key,
        },
      });
    }
  }, [kotOrderStatus, branchPk, kotOrderList]);

  useEffect(() => {
    if (sortProduct) {
      viewKOTSortProductListAPI({
        body: { ...sortProduct, branchId: branchPk },
      });
    }
  }, [sortProduct]);

  useEffect(() => {
    setSendBody((prevSendBody) => ({
      ...prevSendBody,
      status: kotOrderStatus?.status?.key,
    }));
  }, [kotOrderStatus?.status?.key]);

  // socket connection
  useEffect(() => {
    //connect socket io to backend
    // const socket = io(axios.defaults.baseURL)
    const socket = io(axios.defaults.baseURL, {
      path: "/socket.io/",
      reconnection: true,
      transports: ["websocket"],
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      // reconnectionAttempts: Infinity
    });

    //get backend response if connect the socket

    socket.on("connect", () => {
      if (data && branchPk) {
        socket.emit("setUsername", data?._id, branchPk);
        dispatch(setNetworkStatus(NETWORK.ONLINE));
      }
    });

    // call kot list when  receive NEW KOT ORDER status updated
    socket.on("newKotOrder", () => {
      listKotOrderAPI({ body: sendBody });
    });

    // call kot list when  receive TO COOK status updated
    socket.on("toKotCookStatus", () => {
      listKotOrderAPI({ body: sendBody });
    });

    // call kot list when  receive COMPLETED status updated
    socket.on("toKotCompleteStatus", () => {
      listKotOrderAPI({ body: sendBody });
    });

    // call kot list when  receive RESET status updated
    socket.on("toKotResetStatus", () => {
      listKotOrderAPI({ body: sendBody });
    });

    // call kot list when  receive DELIVERED status updated
    socket.on("toKotDeliveryStatus", () => {
      listKotOrderAPI({ body: sendBody });
    });

    //connection error
    socket.io.on("error", (error) => {
      // Handle connection error
      dispatch(setNetworkStatus(NETWORK.OFFLINE));
      dispatch(
        setAlertMessage({ status: "error", message: "Server is down!" })
      );
    });

    // Clean up function
    return () => {
      if (socket) {
        socket.off("connect");
        socket.disconnect();
      }
    };
  }, [sendBody]);

  // Check for online status and show alert message
  useEffect(() => {
    dispatch(
      setKotOrderStatus({
        index: 0,
        status: { key: "ALL", name: "All", color: "#cc009c" },
      })
    );
    const handleOnlineStatus = () => {
      if (navigator.onLine) {
        dispatch(setNetworkStatus(NETWORK.ONLINE));

        dispatch(
          setAlertMessage({ status: "success", message: "You are online!" })
        );
      } else {
        dispatch(setNetworkStatus(NETWORK.OFFLINE));
        dispatch(
          setAlertMessage({ status: "error", message: "You are offline!" })
        );
      }
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  useEffect(() => {
    // To remove header when exiting the page
    return () => {
      dispatch(switch_to_billing_screen({ billingBoolean: false }));
      dispatch(setHideHeader(false));
    };
  }, [location]);
  useEffect(() => {
    if (networkStatus === NETWORK.ONLINE) {
      listKotOrderAPI({ body: sendBody });
    }
  }, [networkStatus]);

  //setAlertMessage
  const setAlertMessageHandler = () => {
    if (alertMessage !== null) {
      dispatch(setAlertMessage(null));
    }
  };

  return (
    <div id="kot-container" data-theme={darkMode ? "dark" : "light"}>
      <div className="kot-container">
        <KotHeader branchId={branchPk} />
        <div className="kot-body">
          <KotSideMenu />
          <KotBody />
          <KotPopup />
        </div>
      </div>
      <LoadingForm loading={isLoading} />
      <AlertMessage
        status={alertMessage?.status}
        alertMessage={alertMessage?.message}
        setApiAlertMessage={setAlertMessageHandler}
      />
    </div>
  );
};

export default KitchenOrderTicket;
