import React, { useEffect, useState } from "react";
import "./inputOrOutputReport.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { today } from "../../../../../Js/Date";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { listInputOrOutputAPI } from "../vatReportApis";
import { useSelector } from "react-redux";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";

const InputOrOutputReport = () => {
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const { listInputOrOutput } = useSelector((res) => res.vatReportSlice);

  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  const taxTypeList = [
    { label: "input", type: "subheading" },
    { label: "output", type: "subheading" },
    { label: "all", type: "subheading" },
  ];
  useEffect(() => {
    if (FormDataInfo) {
      const taxType =
        FormDataInfo?.type?.length > 0 ? FormDataInfo?.type[0] : "all";
      listInputOrOutputAPI({
        fromDate:FormDataInfo.fromDate===""?"": `${FormDataInfo?.fromDate} 00:00:00`,
        toDate:FormDataInfo.toDate===""?"": `${FormDataInfo?.toDate} 23:59:59`,
        type: taxType === "all" ? "" : taxType,
      });
    }
  }, [FormDataInfo]);
  return (
    <div className="in-or-out-container updated-global-page-parent-container">
      <div className="justify-space-between list-create-head-bar">
          <h3>Input/Output Report</h3>
          <div className="create-head-buttons-container">
            <CategorySearchandFilter
              onData={handleDataFromChild}
              isType={true}
              isBranch={false}
              typeList={taxTypeList}
              isSearch={false}
              isLeft={true}
            />
          </div>
      </div>

      <div className="in-or-out-report-wrapper"  style={{padding:"6px",backgroundColor:"unset"}}>
        <div className="table-wrapper-container" style={{backgroundColor:"white",marginTop:"4px",height:"85vh"}}>
          <TableContainer sx={{ maxHeight: "75vh" }}> 
            <Table
              stickyHeader
              aria-label="sticky table"
              className="transaction-table"
              sx={{ padding: 0 }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Transaction Number</TableCell>
                  <TableCell>Transaction Type</TableCell>
                  <TableCell>Debit</TableCell>
                  <TableCell>Credit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listInputOrOutput?.list?.length > 0 ? (
                  listInputOrOutput?.list?.map((item, index) => (
                    <TableRow
                      key={index}
                      className={index % 2 === 0 ? "even-row " : ""}
                    >
                      <TableCell>{item?.accountingDate}</TableCell>
                      <TableCell>{item?.reference}</TableCell>
                      <TableCell>{item?.createdFrom}</TableCell>
                      <TableCell>{item?.debit}</TableCell>
                      <TableCell>{item?.credit}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell className="no-data-td" colSpan={5}>
                      NO DATA
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter style={{ position: "sticky", bottom: 0 }}>
                <TableRow className="total-amount-cr-dr">
                  <TableCell colSpan={3}>Total</TableCell>
                  <TableCell>
                    {listInputOrOutput?.currencySymbol}{" "}
                    {listInputOrOutput?.totalDebit}
                  </TableCell>
                  <TableCell>
                    {listInputOrOutput?.currencySymbol}{" "}
                    {listInputOrOutput?.totalCredit}
                  </TableCell>
                </TableRow>
                <TableRow className="input-and-output-total">
                  <TableCell colSpan={4}>Total Input Tax</TableCell>
                  <TableCell>
                    {listInputOrOutput?.currencySymbol}{" "}
                    {listInputOrOutput?.totalInputTax}
                  </TableCell>
                </TableRow>
                <TableRow className="input-and-output-total">
                  <TableCell colSpan={4}>Total Output Tax</TableCell>
                  <TableCell>
                    {listInputOrOutput?.currencySymbol}{" "}
                    {listInputOrOutput?.totalOutputTax}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default InputOrOutputReport;
