import React from 'react'
import "./buttonWithHover.css"
const ButtonWithIcon = (props) => {

  const{icon,onClick,text,style}=props


  return (
      <div class="button-icon-hover" onClick={onClick} style={style}>
          <div class="button-wrapper">
              <div class="text">{text}</div>
              <span class="icon">
                {icon}
              </span>
          </div>
      </div>
  )
}

export default ButtonWithIcon