import { createSlice } from "@reduxjs/toolkit";

export const aggregatorSlice=createSlice({
    name:"aggregatorSlice",
    initialState:{
        aggregatorList:[],
        dialogBox:null,
        singleList:null
    },
    reducers:{
        storeAggregatorList:(state,action)=>{
            state.aggregatorList=action.payload
        },
        setAggregatorDialog:(state,action)=>{
            state.dialogBox=action.payload
        },
        setAggregatorSingleRow:(state,action)=>{
            state.singleList=action.payload
        }
    }
})

export const{
    storeAggregatorList,
    setAggregatorDialog,
    setAggregatorSingleRow
}=aggregatorSlice.actions

export default aggregatorSlice.reducer