import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/system";
import "./componentStyle.css";
import FileUpload from "./FileUpload";
import { useDispatch, useSelector } from "react-redux";
import uploadImageSvg from "../../../../../Assets/SVG/uploadFileIcon.svg";
import * as XLSX from "xlsx";
import { setBankStatement, setImportFile } from "../BankReconciliationSlice";

import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: "80%",
    maxHeight: 800,
  },
});

const StyledTableContainer = styled(TableContainer)({
  maxHeight: 400,
});

const StyledTableCell = styled(TableCell)({
  position: "sticky",
  top: 0,
  backgroundColor: "white",
  zIndex: 1,
});

const ImportFileDialog = ({
  handleUploadFile,
  state,
  setState,
  open,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const { importFile, bankStatement } = useSelector(
    (state) => state.bankReconciliation
  );

  const [data, setData] = useState({ headers: [], body: [] });

  // Utility function to convert Excel date serial number to JS Date object
  const excelDateToJSDate = (serial) => {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);
    return new Date(
      date_info.getFullYear(),
      date_info.getMonth(),
      date_info.getDate()
    );
  };

  // Utility function to format date to DD/MM/YYYY
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    if (importFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        // Get the headers
        const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headers = rows[0];
        // Get the body excluding the header row and empty rows
        const body = rows
          .slice(1)
          .filter((row) =>
            row.some((cell) => cell !== undefined && cell !== "")
          );
        const formattedBody = body.map((row) => {
          return row.map((cell) => {
            if (typeof cell === "number" && cell > 40000 && cell < 50000) {
              // Assuming dates are in this range, adjust as necessary
              return formatDate(excelDateToJSDate(cell));
            }
            return cell;
          });
        });
        setData({ headers, body: formattedBody });
      };

      reader.readAsArrayBuffer(importFile);
    }
  }, [importFile]);

  const options = [
    "date",
    "reference number",
    "name",
    "debit",
    "credit",
    "charge",
  ];

  const [selectedOptions, setSelectedOptions] = useState({});
  useEffect(() => {
    if (data.headers.length > 0) {
      const initialOptions = data.headers.reduce((acc, header) => {
        const lowercaseHeader = header.toLowerCase().replace(/\s+/g, "");
        acc[lowercaseHeader] = "";
        return acc;
      }, {});

      setSelectedOptions(initialOptions);
    }
  }, [data.headers]);

  const handleChange = (event, value, key) => {
    const lowercaseKey = key.toLowerCase().replace(/\s+/g, "");
    let camelCaseValue = value || "";

    if (value) {
      camelCaseValue = value
        .replace(/\s(.)/g, ($1) => $1.toUpperCase())
        .replace(/\s+/g, "");
    }

    setSelectedOptions((prevOptions) => {
      const updatedOptions = { ...prevOptions };

      if (value === "") {
        // Remove the selected option
        delete updatedOptions[lowercaseKey];
      } else {
        // Set the selected option
        updatedOptions[lowercaseKey] = camelCaseValue;
      }

      return updatedOptions;
    });
  };

  const handleSubmit = () => {
    if (bankStatement.length === 0) {
      const tableData = [];

      // Populate the tableData array with the corresponding body values
      data.body.forEach((row, rowIndex) => {
        const rowData = {};

        Object.keys(selectedOptions).forEach((header, headerIndex) => {
          const key = selectedOptions[header];
          if (key) {
            rowData[key] = row[headerIndex];
          }
        });

        tableData.push(rowData);
      });
      // console.log(tableData)
      dispatch(setBankStatement(tableData));
    } else {
      handleUploadFile();
    }
  };

  return (
    <StyledDialog maxWidth="lg" open={open} onClose={handleClose}>
      <div className="imp-comp-header-cntr">
        <div>
          <span className="imp-comp-header">IMPORT FILE</span>
        </div>
        {/* submit button */}
        <div className="imp-action-cntr">
          {importFile &&
            Object.values(selectedOptions).filter((value) => value !== "")
              .length >= 6 && (
              <button
                onClick={() => handleSubmit()}
                className="imp-comp-btn-submit"
              >
                <span>
                  {bankStatement?.length > 0 ? "UPLOAD FILE" : "SUBMIT"}
                </span>
              </button>
            )}
          {!importFile && <FileUpload />}
        </div>
      </div>
      <DialogContent>
        <div>
          {
            <div
              style={{
                marginBottom: "16px",
                height: "2em",
                fontWeight: "bold",
              }}
            >
              {importFile && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>Import </span>
                  <span style={{ color: "red", textDecoration: "underline" }}>
                    {importFile?.name}
                  </span>
                  <Tooltip title={`Remove ${importFile?.name} `}>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        dispatch(setImportFile(null));
                        dispatch(setBankStatement([]));
                        setSelectedOptions({});
                      }}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>

                  {/* <span>Note : <q style={{color:'red'}}> company book debit = bank statement credit  and company book credit = bank statement debit </q> </span> */}
                  {/* <span>
                      <strong>Note: </strong>
                      <span style={{ color: "#d32f2f" }}>
                        In the company's book:
                        <ul
                          style={{
                            margin: 0,
                            padding: 0,
                            listStyleType: "none",
                          }}
                        >
                          <li>Debit equals Bank Statement Credit</li>
                          <li>Credit equals Bank Statement Debit</li>
                        </ul>
                      </span>
                    </span> */}

                  <Tooltip
                    title="Company book debit = Bank statement credit _ Company book credit = Bank statement debit"
                    arrow
                  >
                    <InfoIcon
                      sx={{
                        color: "#b8b8b8",
                        marginLeft: 1,
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          }
          {importFile && (
            <div
              style={{
                display: "flex",
                // justifyContent: "space-between",
                gap: "1em",
                marginBottom: "16px",
                overflow: "auto",
                padding: "1em 0",
              }}
            >
              {data?.headers.map((res, index) => (
                <div key={index} style={{ minWidth: "10em" }}>
                  <Autocomplete
                    value={
                      selectedOptions[res.toLowerCase().replace(/\s+/g, "")] ||
                      ""
                    }
                    options={options.filter(
                      (option) =>
                        !Object.values(selectedOptions).some(
                          (value) =>
                            value
                              .replace(/\s(.)/g, ($1) => $1.toUpperCase())
                              .replace(/\s+/g, "") ===
                            option
                              .replace(/\s(.)/g, ($1) => $1.toUpperCase())
                              .replace(/\s+/g, "")
                        )
                    )}
                    getOptionLabel={(option) => option}
                    onChange={(event, value) => handleChange(event, value, res)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={res}
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                      />
                    )}
                    style={{ width: "100%", minWidth: "10em" }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        {bankStatement?.length > 0 && (
          <StyledTableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Ref No</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Debit</StyledTableCell>
                  <StyledTableCell>Credit</StyledTableCell>
                  <StyledTableCell>Charge</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bankStatement.map((res, i) => (
                  <TableRow key={i}>
                    <TableCell sx={{ minWidth: "10em" }}>{res?.date}</TableCell>
                    <TableCell>{res?.referenceNumber}</TableCell>
                    <TableCell>{res?.name}</TableCell>
                    <TableCell>{res?.debit}</TableCell>
                    <TableCell>{res?.credit}</TableCell>
                    <TableCell>{res?.charge}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
        {!importFile && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "1em",
              minHeight: "50vh",
              flexDirection: "column",
              alignItems: "center",
              fontWeight: "600",
              color: "#b5b5b5",
            }}
          >
            <img height={300} width={300} src={uploadImageSvg} alt="" />
            <span>File Upload </span>
          </div>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default ImportFileDialog;
