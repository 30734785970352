import React, { useEffect, useState } from 'react'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { setDialogBox } from '../Slice/billingScreenSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Button, Dialog, Skeleton, TextField } from '@mui/material';
import {  getCurrentTimeWOsec, today } from '../../../../../Js/Date';
import { getFloorDetailsForBilling } from '../API/billingFloorSection';
import { getFloorDetails } from '../Slice/billingFloorPlanSlice';
import { addReservationAPI, editReservationAPI, listReservationList } from '../API/billingReservation';
import { findObjFromList } from '../../../../../Js/generalFunctions';
import { setReservationName } from '../Slice/reservationSlice';
import AddCustomerFromReservation from '../Utilities/AddCustomerFromReservation';

function AddReservationPopup() {

    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.loginResponseSlice.value);
    const customerList = useSelector((state) => state.allCustomerListSlice.value);
    const { floors } = useSelector((state) => state.addSeatSlice);
    const { selectedFloorDetails } = useSelector((store) => store.billingFloorPlanSlice);
    const {selectedReservation,reservationName}=useSelector((state)=>state.reservationSlice)
    const {dialogOpen}=useSelector((state)=>state.billingScreenSlice)
    const {customer} = useSelector((state) => state.topBarSliceBilling)


    const formValuesInitial = {
        customer: null,
        mobile: "",
        date: today,
        time: getCurrentTimeWOsec(),
        totalPerson: "",
        note: "",
        floor: floors.length !== 0 ? floors[0] : null,
        name: null,
    }

    const [formValues, setFormValues] = useState(formValuesInitial)
    const [validationAlert, setValidationAlert] = useState({})
    const [floorLoading, setFloorLoading] = useState(false)

    const [selectedTable,setSelectedTables]=useState([])

    const formOnchnage = (key) => (e, newValue) => {
        const { value } = e.target;

        if(key==="customer"){
            setFormValues({ ...formValues, customer:newValue,mobile:newValue?.mobileNo||"" })
        }else{
            setFormValues({ ...formValues, [key]: newValue === undefined ? value : newValue })
        }
        if(key==="floor"){
            setSelectedTables([])
        }
        setValidationAlert({})
    }

    const [customerPopup,setCustomerPopup]=useState(false)

    const selectTableFn=(table)=>()=>{
        const tableSelected = selectedTable.some((obj) => obj === table._id);
        if (tableSelected) {
            const updatedSelectedObjects = selectedTable.filter((obj) => obj !== table._id);
            setSelectedTables(updatedSelectedObjects);
        } else {
            setSelectedTables([...selectedTable, table._id]);
        }
    }


    const submitReservation=()=>{
        let payload={
            cusId:formValues?.customer?._id,
            mobileNo:formValues.mobile,
            date:`${formValues?.date} ${formValues?.time}`,
            time:formValues?.time,
            totalPerson:formValues?.totalPerson,
            note:formValues?.note,
            floorId:formValues?.floor?._id,
            tableId:selectedTable,
            branchId:userInfo?.branchPk
        }
        if(dialogOpen==="editReservation"){
            payload._id=selectedReservation?._id
        }
        let clearFn=()=>{
            listReservationList({branchId:userInfo?.branchPk})
            setSelectedTables([])
            setFormValues(formValuesInitial)
        }
        if(formValues.customer===null){
            setValidationAlert({...validationAlert,customer:"customer is required"})
        }
        else if(formValues.mobile===""){
            setValidationAlert({...validationAlert,mobile:"Mobile no is required"})
        }
        else if(formValues.date===""){
            setValidationAlert({...validationAlert,date:"please choose a date"})
        }
        else if(formValues.time===""){
            setValidationAlert({...validationAlert,time:"please choose a time"})
        }else{
            dialogOpen==="addReservation"?addReservationAPI(payload,clearFn):editReservationAPI(payload,clearFn)
        }
    }
    useEffect(() => {
        formValues.floor !== null ? getFloorDetailsForBilling({ id: formValues.floor?._id }, setFloorLoading) : dispatch(getFloorDetails(undefined))
    }, [formValues.floor])

    useEffect(()=>{
        if(dialogOpen==="editReservation"){
            setFormValues({...formValues,
                customer:findObjFromList("_id",customerList,"cusId",selectedReservation),
                mobile:selectedReservation?.mobileNo,
                date:selectedReservation?.date,
                time:selectedReservation?.time,
                totalPerson:selectedReservation?.totalPerson,
                note:selectedReservation?.note,
                floor:findObjFromList("_id",floors,"floorId",selectedReservation),
            })
            setSelectedTables(selectedReservation?.tableId || [])
            dispatch(setReservationName(selectedReservation?.name))
        }
    },[])

    useEffect(()=>{
        customerPopup==="customerAdded" && setFormValues({...formValues,customer:customer,mobile:customer?.mobileNo})
    },[customerPopup])
    return (
        <div className='add-reservation-ctn'>
            <div className="header-top justify-center">
                <h3>{dialogOpen==="editReservation"?"Edit":"Add"} Reservation</h3>
                <button className="btn btn-primary" onClick={submitReservation}>{dialogOpen==="editReservation"?"EDIT":"SUBMIT"}</button>
                <CancelOutlinedIcon
                    onClick={() => dispatch(setDialogBox(null))}
                    fontSize='large'
                    sx={{ marginLeft: "auto", color: "red", cursor: "pointer" }} />
            </div>

            <hr className="global-hr" />
            <h3 className='res-name'>{reservationName?.sequence || reservationName}</h3>

            <div className="new-global-input-container add-reservaion-input-ctn">
                <div
                    style={{ width: "30%", marginLeft: "0" }}
                    className="new-global-single-input">
                    <Autocomplete
                        options={customerList || []}
                        getOptionLabel={(option) => option?.name}
                        value={formValues.customer}
                        onChange={formOnchnage("customer")}
                        renderInput={(params) => (
                            <TextField label="Customer" {...params} focused
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <>
                                            {params.InputProps.endAdornment}
                                            <Button 
                                            className="add-customer-button"
                                            onClick={()=>setCustomerPopup("open")}
                                            >+</Button>
                                        </>
                                    ),
                                }}
                            />
                        )}
                        renderOption={(props, option) => (
                            <li {...props} key={option?._id}>
                                <span>{option?.name}</span>
                            </li>
                        )}
                    />
                    <p className="doc-validation-alert">{validationAlert?.customer}</p>
                </div>
                <div
                    style={{ width: "30%" }}
                    className="new-global-single-input">
                    <TextField
                        id="outlined-basic"
                        label="Mobile"
                        variant="outlined"
                        type="number"
                        focused
                        value={formValues.mobile}
                        onChange={formOnchnage("mobile")}
                    />
                    <p className="doc-validation-alert">{validationAlert?.mobile}</p>
                </div>
                <div
                    style={{ width: "17%" }}
                    className="new-global-single-input">
                    <TextField
                        id="outlined-basic"
                        label="Date"
                        variant="outlined"
                        type="date"
                        focused
                        value={formValues.date}
                        onChange={formOnchnage("date")}
                    />
                    <p className="doc-validation-alert">{validationAlert?.date}</p>
                </div>
                <div
                    style={{ width: "17%", marginRight: "0" }}
                    className="new-global-single-input">
                    <TextField
                        id="outlined-basic"
                        label="Time"
                        variant="outlined"
                        type="time"
                        focused
                        value={formValues.time}
                        onChange={formOnchnage("time")}
                    />
                    <p className="doc-validation-alert">{validationAlert?.time}</p>

                </div>
                <div
                    style={{ width: "18%", marginLeft: "0" }}
                    className="new-global-single-input">
                    <TextField
                        id="outlined-basic"
                        label="Total Person"
                        variant="outlined"
                        type="number"
                        focused
                        value={formValues.totalPerson}
                        onChange={formOnchnage("totalPerson")}
                    />
                </div>
                <div
                    style={{ width: "50%" }}
                    className="new-global-single-input">
                    <TextField
                        id="outlined-basic"
                        label="Note"
                        variant="outlined"
                        type="text"
                        focused
                        value={formValues.note}
                        onChange={formOnchnage("note")}
                    />
                </div>
                <div
                    style={{ flex: "1", marginRight: "0" }}
                    className="new-global-single-input">
                    <Autocomplete
                        options={floors || []}
                        getOptionLabel={(option) => option?.nameOfFloor}
                        value={formValues.floor}
                        onChange={formOnchnage("floor")}
                        renderInput={(params) => (
                            <TextField label="Floor" {...params} focused />
                        )}
                        renderOption={(props, option) => (
                            <li {...props} key={option?._id}>
                                <span>{option?.nameOfFloor}</span>
                            </li>
                        )}
                    />
                </div>
            </div>

            <p className="add-tables">Select Table</p>

            <div
                className="new-global-input-container add-reservaion-input-ctn"
                style={{ justifyContent: "start", gap: "12px" }}
            >
                {
                    floorLoading ?
                        [...Array(20)].map((_, i) =>
                            <div key={i} className="table-box justify-center">
                                <Skeleton width={"100%"} height={"100%"}/>
                            </div>
                        )
                        :
                        (selectedFloorDetails!==undefined && selectedFloorDetails?.floorCanvas.length !== 0) ?
                            selectedFloorDetails?.floorCanvas?.filter(obj=>obj.type==="TABLE")?.map((item, index) => (
                                <div 
                                onClick={selectTableFn(item)}
                                className={`table-box justify-center ${selectedTable.some((obj) => obj === item._id) && "table-box-selected"}`} key={index}>
                                    {item.name}
                                </div>
                            ))
                            :
                            <div className="center-div" style={{height:"20vh"}}>
                                No Floor Plan Added
                            </div>
                }
            </div>

            <Dialog open={customerPopup==="open"} onClose={() => setCustomerPopup(false)}>
                <AddCustomerFromReservation setCustomerPopup={setCustomerPopup} />
            </Dialog>
        </div>
    )
}

export default AddReservationPopup