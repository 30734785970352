import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  fontWeight: 'bold',
  padding: theme.spacing(2),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
  justifyContent: 'flex-end',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 'normal',
  borderRadius: 0,
}));

const CancelConfirmationDialog = ({ open, onConfirm, onCancel }) => {
  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <StyledDialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="cancel-confirmation-dialog-title"
      aria-describedby="cancel-confirmation-dialog-description"
    >
      <StyledDialogTitle id="cancel-confirmation-dialog-title">
        Confirm Cancel
      </StyledDialogTitle>
      <StyledDialogContent>
        <DialogContentText id="cancel-confirmation-dialog-description">
          Are you sure you want to cancel this bank reconciliation?
        </DialogContentText>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton onClick={handleCancel} color="inherit" autoFocus>
          Cancel
        </StyledButton>
        <StyledButton onClick={handleConfirm} color="primary" variant="outlined">
          Confirm
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default CancelConfirmationDialog;