import axios from "axios"
import { HEADERS } from "../../../../API/UrlAndPaths"
import store from "../../../../Redux/store"
import { get_wareHouse_location_list, get_wareHouse_location_single_view } from "./wareHouseLocationSlice"
import { setSubmitLoading } from "../../../../Redux/Dashboard/mainDashBoardSlice"

export const vieWareHouseLocationAPICall=(body)=>{
    store.dispatch(get_wareHouse_location_list({wareHouseLocationData:null}))
    axios.post("inventory/listwarehouselocs",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_wareHouse_location_list({wareHouseLocationData:res.data}))
        }
    })
    .catch((err)=>{
      store.dispatch(get_wareHouse_location_list({wareHouseLocationData:undefined}))
    })
}

export const addWareHouseLocationAPICall=(
    body,
    clearState,
    setSuccessSnackOpen,
    setErrorSnackOpen,
    setSnackMsg,setLoadingForm
    )=>{
      setLoadingForm!==undefined && setLoadingForm(true)

    axios.post("inventory/addwarehouselocs",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
          setLoadingForm!==undefined && setLoadingForm(false)

            setSnackMsg('Location Added')
            setSuccessSnackOpen(true)
            clearState()
        }
    })
    .catch((err)=>{
      setLoadingForm!==undefined && setLoadingForm(false)

        switch (err.response.status) {
            case 404:
                setSnackMsg(err?.response?.data)
              break;
            case 500:
                setSnackMsg('Server error!!')
              break;
            case 401:
                setSnackMsg('Unauthorized!!')
              break;
            case 403:
                setSnackMsg('Forbidden!!')
              break;
            case 409:
                setSnackMsg("Duplicated")
            default:
                setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
              break;
          }
        setErrorSnackOpen(true)
    })
}

export const editWareHouseLocationAPICall=(
    body,
    setSuccessSnackOpen,
    setErrorSnackOpen,
    setSnackMsg,setLoadingForm,saveFn
    )=>{
      setLoadingForm!==undefined && setLoadingForm(true)

    axios.put("inventory/editwarehouselocs",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
          setLoadingForm!==undefined && setLoadingForm(false)

            setSnackMsg('Edited')
            setSuccessSnackOpen(true)
            saveFn()
        }
    })
    .catch((err)=>{
      setLoadingForm!==undefined && setLoadingForm(false)

        switch (err.response.status) {
            case 404:
                setSnackMsg(err?.response?.data)
              break;
            case 500:
                setSnackMsg('Server error!!')
              break;
            case 401:
                setSnackMsg('Unauthorized!!')
              break;
            case 403:
                setSnackMsg('Forbidden!!')
              break;
            case 409:
                setSnackMsg("Duplicated")
            default:
                setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
              break;
          }
        setErrorSnackOpen(true)
    })
}
export const singleViewWareHouseLocationAPICall=(body)=>{
  store.dispatch(setSubmitLoading(true))
  axios.post("inventory/singleViewLocation",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(setSubmitLoading(false))
      store.dispatch(get_wareHouse_location_single_view({singleWareHouseLocationData:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(setSubmitLoading(false))
    store.dispatch(get_wareHouse_location_single_view({singleWareHouseLocationData:undefined}))
  })
}