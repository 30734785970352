import React, { useEffect, useState } from 'react'
import "./purchaseHistory.css"
import { Autocomplete, Icon, IconButton, TextField } from '@mui/material'
import { vendorListApi } from '../API/vendorListAPICall'
import { useSelector } from 'react-redux'
import { LoadingForm } from '../../../../Single Components/LoadingForm'
import CustomPagination from '../../../../Single Components/CustomPagination'

export const PurchaseHistory = (props) => {
    const {closeDialog,item,historyApiCall,type,response,orderno,formValues,name}=props
    const vendorList = useSelector(
      (state) => state.vendorsListSlice.apiResList
    );
    const customerList = useSelector((state) => state.allCustomerListSlice.value);
    const [vendor,setVendor]=useState(formValues)
    const [loadingForm, setLoadingForm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = response?.pages;
    const vendorChange=(e,newValue)=>{
       setVendor(newValue)

       historyApiCall({
          _id: item?.product?._id,
          index: 0,
          supplierId: newValue?._id
       }, setLoadingForm)
    }
    const handlePageChange = (page) => {
       setCurrentPage(page);
    };
   
   

    useEffect(()=>{
      historyApiCall({
         _id:item?.product?._id,
         index:0,
         supplierId:formValues?._id
      },setLoadingForm)
    },[])


   

    
  return (
    <div className='purchase-history-container'>
        <div className='purchase-history-container-first'>
           <div style={{width:"50%"}}>
              <div className='purchase-history-heading'>
              <p>{name}</p>
              <p><i class="bi bi-chevron-right"></i></p>
              <p>{orderno}</p>
              </div>
              <p className='purchase-history-heading-sub'>{`${type} history Of ${item?.product?.productName}`}</p>
           </div>
           <IconButton onClick={closeDialog}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>
           </IconButton>
           

        </div>
        <hr className='global-hr'/>
        <div style={{margin:"3% 0",display:"flex"}}>
            <div className="new-global-single-input" style={{width:"35%"}}>
            <Autocomplete
              id="combo-box-demo"
              options={
               type==="sales"?customerList:
               type==="purchase"?vendorList:[]|| []}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  onChange={(e) => vendorListApi({ search: e.target.value })}
                  {...params}
                  label="Vendor*"
                  focused
                />
              )}
              value={vendor}
              onChange={vendorChange}
             
            />
             
            </div>
            <div className="new-global-single-input">
            <TextField
              
                id="outlined-basic"
                label="Cost"
                variant="outlined"
                type="text"
                focused
                value={response?.cost}
                inputProps={{ readOnly: true }}
              />
            </div>
            <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
        <hr className='global-hr'/>
        <div className="global-product-table" style={{margin:"3% 0"}}>
           <table>
              <thead>
                 <tr>
                    <th>Order Reference</th>
                    <th>Order Date</th>
                    <th>Partner</th>
                    <th>Total Qty</th>
                    <th>Unit Price</th>
                 </tr>
              </thead>
              <tbody>
               {
                response?.length!==0 && response?.list?.length !== 0 ? response?.list?.map((r,i)=>(
                     <tr>
                     <td>{r?.orderReference}</td>
                     <td>{r?.orderDate}</td>
                     <td>{r?.partner}</td>
                     <td>{r?.totalQty}</td>
                     <td>{r?.unitPrice}</td>
                 </tr>
                  ))
                  :
                  <tr>
                     <td colSpan={5}>No Data</td>
                  </tr>
               }
               
              </tbody>
           </table>
        </div>
        <LoadingForm loading={loadingForm} />

    </div>
  )
}
