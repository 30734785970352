import React from 'react';

const TimerColumn = ({ startTime }) => {

  const calculateTimeDifference = (startDateTime) => {
    if (!startDateTime) {
        const hours = "H";
        const minutes = "M";
        const seconds = "S";
        return { hours, minutes, seconds };
    } else {
        const startTime = new Date(startDateTime);
        const endTime = new Date();

        const timeDiffInMilliseconds = endTime - startTime;
        const timeDiffInSeconds = Math.floor(timeDiffInMilliseconds / 1000);

        const hours = Math.floor(timeDiffInSeconds / 3600);
        const minutes = Math.floor((timeDiffInSeconds % 3600) / 60);
        const seconds = Math.floor(timeDiffInSeconds % 60);
        return { hours, minutes, seconds };
    }
};

  const { hours, minutes, seconds } = calculateTimeDifference(startTime);

  let hourStyle;

  const totalTimeInSeconds = hours * 3600 + minutes * 60 + seconds;

  if (startTime===false || totalTimeInSeconds < 0) { // Check if total time difference is negative
    hourStyle = {border:"1px solid rgb(84, 188, 24)"};
  } else if (totalTimeInSeconds < 1800) { // Less than 30 minutes (1800 seconds)
    hourStyle = { backgroundColor: "rgb(84 188 24)", color: "#fff" }; // Green
  } else if (totalTimeInSeconds < 3600) { // Between 30 and 60 minutes
    hourStyle = { backgroundColor: "#f39b04", color: "#fff" }; // Yellow
  } else { 
    hourStyle = { backgroundColor: "red", color: "#fff", border: "none" };
  }

  return (
    <div className='justify-center' style={{ gap: "7px" }}>
      <div className='timer-ctn justify-center' style={hourStyle}>{hours}</div>
      <div className='timer-ctn justify-center' style={hourStyle}>{minutes}</div>
      <div className='timer-ctn justify-center' style={hourStyle}>{seconds}</div>
    </div>
  );
};

export default TimerColumn;
