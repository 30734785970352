import React from "react";

const OfferTagIconSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 271.095 91.789"
    >
      <g
        id="Group_16320"
        data-name="Group 16320"
        transform="translate(14359.873 18917.999)"
      >
        <g
          id="Group_16318"
          data-name="Group 16318"
          transform="translate(-14359.873 -18918)"
        >
          <path
            id="Path_2469"
            data-name="Path 2469"
            d="M259.367,91.788H36.586a10.751,10.751,0,0,1-8.359-4.33L3.4,56.244c-4.533-5.71-4.533-14.941,0-20.7L28.186,4.331A10.814,10.814,0,0,1,36.545,0H259.325c6.488,0,11.769,6.566,11.769,14.7V77.132C271.177,85.221,265.9,91.788,259.367,91.788ZM17.539,40.161c-2.745,0-4.99,2.57-4.99,5.71s2.246,5.71,4.99,5.71,4.99-2.57,4.99-5.71S20.284,40.161,17.539,40.161Z"
            transform="translate(0 0)"
            fill="#d31400"
          />
          <path
            id="Path_2470"
            data-name="Path 2470"
            d="M29.412.583A4.274,4.274,0,0,0,26.127,2.3L1.341,33.511a6.769,6.769,0,0,0,0,8.089L26.127,72.814a4.212,4.212,0,0,0,3.285,1.713l221.8-.583c2.537,0,4.616-2.57,4.616-5.758V5.758c0-3.14-2.079-5.758-4.616-5.758Z"
            transform="translate(7.174 8.363)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.5"
            stroke-dasharray="3"
          />
          <path
            id="Path_2471"
            data-name="Path 2471"
            d="M0,5.71A5.792,5.792,0,0,1,5.838,0a5.792,5.792,0,0,1,5.838,5.71,5.792,5.792,0,0,1-5.838,5.71A5.792,5.792,0,0,1,0,5.71Zm9.827,0a3.99,3.99,0,0,0-7.978,0,3.99,3.99,0,0,0,7.978,0Z"
            transform="translate(11.702 40.161)"
            fill="#990006"
          />
        </g>
      </g>
    </svg>
  );
};

export default OfferTagIconSvg;
