import React, { useEffect, useRef, useState } from "react";
import "./advertisementScreenStyle.css";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  TextField,
  Autocomplete,
} from "@mui/material";
import UploadImageSvg from "../../../KotTokenDisplay/components/icons/UploadImageSvg";
import Lottie from "react-lottie";
import animationData from "../../icons/animatedHuman.json";
import { useDispatch, useSelector } from "react-redux";
import { SquareComponent } from "../../../KotTokenDisplay/components/advertisementCard/AdvertisementCard";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import IconButton from "@mui/material/IconButton";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteIcon from "@mui/icons-material/Delete";
import Carousel from "../../../KotTokenDisplay/components/carousel/Carousel";
import { setHideHeader } from "../../../KotTokenDisplay/kotTokenDisplaySlice";
import { setDisplayAdvertisementData } from "../../../KotTokenDisplay/components/advertisementCard/advertisementSlice";
import { viewUploadFiles } from "../../../KotTokenDisplay/components/advertisementCard/advertisementApi";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { switch_to_billing_screen } from "../../../Sales/Billing/newBillingSlice";
import { useNavigate } from "react-router-dom";

const AdvertisementScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetails, branchDetails } = useSelector(
    (state) => state.mainDashBoardSlice
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const { displayAdvertisement, alertMessage, loading, isEditAdv } =
    useSelector((state) => state.advertisementSlice);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  const initialState = {
    branch: [],
    selectScreen: 1,
    files: [],
    duration: 1,
  };

  const [formData, setFormData] = useState(initialState);
  const [splitAdd, setSplitAdd] = useState(false);

  useEffect(() => {
    // console.log(allBranchesList);
    // viewUploadFiles({branchId:branchDetails})
    // Fetch necessary data or perform initial actions
    dispatch(setHideHeader(true));
  }, []);
  useEffect(() => {
    const { branch } = formData;
    if (branchDetails || branch) {
      dispatch(setDisplayAdvertisementData(null));
      viewUploadFiles({
        body: { branchId: branchDetails?._id || branch?._id },
      });
    }
  }, [branchDetails, formData?.branch]);

  const handleChange = (key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const updatedFiles = [...formData.files];

    newFiles.forEach((newFile) => {
      // Check if the file with the same name and size already exists
      const duplicateFile = updatedFiles.find(
        (file) => file.name === newFile.name && file.size === newFile.size
      );

      // If it's not a duplicate, add it to the files array
      if (!duplicateFile) {
        updatedFiles.push(newFile);
      }
    });

    setFormData((prev) => ({
      ...prev,
      files: updatedFiles,
    }));
  };

  const handleDurationChange = (change) => {
    setFormData((prev) => ({
      ...prev,
      duration: Math.max(1, Math.min(60, prev.duration + change)),
    }));
  };

  const handleDeleteImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      files: prev.files.filter((_, i) => i !== index),
    }));

    // Clear the file input field
    const fileInput = document.getElementById("file-upload");
    fileInput.value = "";
  };

  const handleBranchChange = (e, value) => {
    setFormData((prev) => ({
      ...prev,
      branch: value,
    }));
  };

  //handle submission

  const handleSubmit = () => {
    const formDataToSend = new FormData();

    formDataToSend.append("selectScreen", formData.selectScreen);

    const branches = [];
    formData.branch.map((branch) => {
      branches.push(branch._id);
    });

    formDataToSend.append("branch", branches);
    formDataToSend.append("duration", formData?.duration);

    formData.files.forEach((file) => formDataToSend.append("files", file));

    for (const entry of formDataToSend.entries()) {
      console.log(entry);
    }

    const sendBody = {
      body: formDataToSend,
    };
    // uploadAdvertisement(sendBody)
  };

  const isRow = true;

  return (
    <div className="advertisement-container-cd">
      {/* heading- */}
      <div>
        <button
          onClick={() => {
            dispatch(switch_to_billing_screen({ billingBoolean: false }));
            navigate("/userdashboard/dashboardhome");
            dispatch(setHideHeader(false));
          }}
        >
          <HighlightOffIcon color="error" />
        </button>
      </div>
      <div className={isRow ? "restaurant-info" : "restaurant-info column"}>
        <div>
          <span className="cd-header">{companyProfileData?.companyName}</span>
          <span className="cd-ph">
            phone: {companyProfileData?.contactNumber}
          </span>
        </div>
        <div className={`cd-image-wrapper ${!isRow && "column"} `}>
          <div className="cd-image-container">
            <img src={companyProfileData?.logo} alt="company logo" />
          </div>
        </div>
      </div>
      {/* animated interface h-350 w-400 */}
      <div>
        <div className="cd-animated-img">
          <span>Easy to purchase and pay</span>
        </div>
        <Lottie
          style={{ height: "20vh", width: "20vh" }}
          options={defaultOptions}
        />
      </div>
      {/* upload advertisement */}
      <div className="add-advertisement-card-cd">
        {/* ------------------------------------------------------------- */}
        {/* <div className="add-advertisement-card">
 
          <div className="adv-branch-selector-cntr">
            <Autocomplete
              multiple
              id="tags-outlined"
              options={allBranchesList || []}
              getOptionLabel={(option) =>
                `${option?.storeCode}-${option?.branchName}`
              }
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} label="Branch" />}
              onChange={handleBranchChange}
              value={formData?.branch}
              filterOptions={(options, state) =>
                options.filter(
                  (option) =>
                    !formData.branch.some((branch) => branch._id === option._id)
                )
              }
            />
          </div>
   
          <div className="adv-screen-selector-cntr">
            <div className="adv-screen-selection-header">
              <span>Select Screen</span>
            </div>
            <div className="adv-selection-temp">
              {[1, 2, 3, 4].map((i) => (
                <div key={i}>
                  <Radio
                    sx={{ height: "3em", width: "3em" }}
                    checked={formData.selectScreen === i}
                    onChange={() => handleChange("selectScreen", i)}
                    value={i}
                    name="radio-buttons"
                    inputProps={{ "aria-label": "B" }}
                  />
                  <SquareComponent
                    squares={i}
                    selectedScreen={formData?.selectScreen}
                  />
                </div>
              ))}
            </div>
          </div>
    
          <div className="adv-file-upload-cntr">
            <div className="ticket-advertisement-image-upload-container">
              <div className="image-name-container">
                <span>
                  {formData.image
                    ? formData.image.name
                    : "Upload image / video"}
                </span>
              </div>
              <div className="upload-add-image-button-container">
                <label htmlFor="file-upload" className="custom-file-upload">
                  <UploadImageSvg />
                  &nbsp; Upload
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*,video/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  multiple
                />
              </div>
            </div>
      
            <div className="ticket-advertisement-image-upload-container adv-duration-cntr">
              <div>
                <QueryBuilderIcon color="info" />
              </div>
              <input
                type="number"
                name="duration"
                id="duration"
                value={formData.duration}
                readOnly
              />
              <div className="advr-in-de-btn-cntr">
                <IconButton
                  aria-label="increase duration"
                  onClick={() => handleDurationChange(1)}
                >
                  <ArrowDropUpIcon />
                </IconButton>
                <IconButton
                  aria-label="decrease duration"
                  onClick={() => handleDurationChange(-1)}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="adv-upload-img-preview-cntr">
            {formData?.files?.map((file, index) => (
              <div
                key={index}
                className="ticket-advertisement-image-upload-container"
              >
                <div className="adv-prev-img">
                  {file.type.startsWith("image/") ? (
                    <img src={URL.createObjectURL(file)} alt={file.name} />
                  ) : file.type.startsWith("video/") ? (
                    <video controls>
                      <source
                        src={URL.createObjectURL(file)}
                        type={file.type}
                      />
                    </video>
                  ) : (
                    <span>Unsupported file type</span>
                  )}
                </div>
                <div className="">{file.name}</div>
                <div className="adv-dlt-cntr">
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteImage(index)}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        </div> */}

        {displayAdvertisement && (
          <Carousel
            items={displayAdvertisement?.imageUrl}
            duration={displayAdvertisement?.slideInterval * 1000}
          />
        )}
        {/* ------------------------------------------------------------- */}
      </div>
      {/* show advertisement */}
      {/* <div className="cd-advertisement-img-wrapper">
        <div>
          <div className="cd-advertisement-image">
            <img src="https://picsum.photos/500" alt="offer-card-1" />
          </div>
        </div>
        <div>
          <div className="cd-advertisement-image">
            <img src="https://picsum.photos/600" alt="offer-card-2" />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AdvertisementScreen;

// -------------------------------------------------
