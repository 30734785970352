import { createSlice } from "@reduxjs/toolkit";


export const arrowPathSlice=createSlice({
    name:"arrowPathSlice",
    initialState:{
        pathData:[]
    },
    reducers:{
        setArrowPathData:(state,action)=>{
            state.pathData.push(action.payload)
        },
        clearPathData: (state) => {
            state.pathData = []; 
        },
        removePathRow: (state, action) => {
            const rowIndex = action.payload;

            state.pathData = state.pathData.filter((_, index) => index <= rowIndex);
          }
    }
})

export const {setArrowPathData,clearPathData,removePathRow}=arrowPathSlice.actions

export default arrowPathSlice.reducer
