import React from "react";
import { useSelector } from "react-redux";
import SkeletonComponent from "../../../kitchenOrderTicket/components/skeleton/Skeleton";
import DeliveryCard from "./DeliveryCard";
import { NETWORK } from "../../../kitchenOrderTicket/utils/kotEnum";

const DeliveryScreenBody = () => {
  const { networkStatus } = useSelector(
    (state) => state.kitchenOrderTicketSlice
  );
  const { deliveryOrderList } = useSelector(
    (state) => state.deliveryScreenSlice
  );
  return (
    <div className="kot-element-body" style={{height:"fit-content",maxHeight:"89vh"}}>
      {networkStatus === NETWORK.OFFLINE && <SkeletonComponent />}
      {networkStatus === NETWORK.ONLINE && deliveryOrderList?.length > 0
        ? 
        deliveryOrderList?.map((res, i) => (
            <DeliveryCard
              key={i}
              elementData={res}
              orderNo={res?.ordName}
              tableNo={res?.tables}
              tokenNo={res?.transNo}
              orderInfo={res?.orderInfo}
              status={res?.status}
              day={res?.orderDate}
            />
          ))
         : ""} 
    </div>
  );
};

export default DeliveryScreenBody;
