import axios from "axios"
import { HEADERS } from "../../../../../API/UrlAndPaths"
import store from "../../../../../Redux/store"
import { get_purchase_report_list, get_purchase_report_single_list } from "./purchaseReportNewSlice"
import { setSubmitLoading, setTableLoadingGlobal } from "../../../../../Redux/Dashboard/mainDashBoardSlice"

export const PurchaseReportNewAPI =async (body)=>{
    store.dispatch(setTableLoadingGlobal(true))
    await axios.post("account/purchaseReport",body,HEADERS)
    .then((res)=>{
        if(res.status == 200){
            store.dispatch(get_purchase_report_list({purchaseReportNwData:res.data}))
            store.dispatch(setTableLoadingGlobal(false))
            ;
        }
    })
    .catch((err) => {
        store.dispatch(setTableLoadingGlobal(false))
        store.dispatch(get_purchase_report_list({purchaseReportNwData:undefined}))
        
    })
}

export const PurchaseReportNewSingleAPI =async (body)=>{
    store.dispatch(setSubmitLoading(true))
    await axios.post("account/viewPurchaseReportItemSingleDetails",body,HEADERS)
    .then((res)=>{
        if(res.status == 200){
            store.dispatch(setSubmitLoading(false))
            store.dispatch(get_purchase_report_single_list({purchaseReportNwSingleData:res.data}))
            ;
        }
    })
    .catch((err) => {
        store.dispatch(setSubmitLoading(false))
        store.dispatch(get_purchase_report_single_list({purchaseReportNwSingleData:undefined}))
        
    })
}