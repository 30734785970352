import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ReturnConfirmationDialog = ({ dialogOpen, dialogCloseFun, confirmBtnFunc }) => {
  return (
    <Dialog
      open={dialogOpen}
      onClose={dialogCloseFun}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to unreconcile payment?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogCloseFun} color="primary">
          Cancel
        </Button>
        <Button onClick={confirmBtnFunc} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReturnConfirmationDialog;