import React from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const TopSellingProductGraph = () => {
  const { mainDashboardData } = useSelector(state => state.homeDashboardSlice);
  // console.log(mainDashboardData,'..mainDashboardData');
  return (
    <div className="home-dash-temp-cntr">
      <div className="home-dash-graph-head">
        <span>Top selling product</span>
      </div>
      <ResponsiveContainer minWidth={400} width="100%" height={250}>
        <BarChart
          width={500}
          height={300}
          data={mainDashboardData?.product || []}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 60, // Increased bottom margin
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            interval={0}
            angle={50}
            textAnchor="start"
            height={80} // Increased height to accommodate labels
            tick={{ fontSize: 12 }} // Optional: Adjust font size if needed
          />
          <YAxis dataKey="qty" />
          <Tooltip />
          <Legend />
          <Bar dataKey="qty" fill="#00866b" name='Quantity sold' legendType="none" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TopSellingProductGraph;
