import React from 'react';
import { Autocomplete, TextField, InputAdornment } from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';

const AutoCompleteWithIcon = ({ options = [], handleChange ,placeHolder="Select",style,value}) => {
  return (
    <div style={style}>
        <Autocomplete
        options={options}
        value={value}
        onChange={(e,newValue)=>handleChange(newValue)}
        sx={{
            '& .MuiOutlinedInput-root': {
            borderRadius: '50px',
            },
            '& .MuiAutocomplete-inputRoot': {
            padding: '0 5px!important'
            },
            '& .MuiInputBase-input': {
            padding: '6px 20px!important',
            paddingLeft: '0!important',
            minWidth:"66px!important",
            fontSize:"0.8rem"
            },
        }}
        
        renderInput={(params) => (
            <TextField
            {...params}
            variant="outlined"
            InputProps={{
                ...params.InputProps,
                startAdornment: (
                <InputAdornment position="start">
                    <LayersIcon />
                </InputAdornment>
                ),
            }}
            placeholder={placeHolder}
            />
        )}
        />
    </div>
  );
};

export default AutoCompleteWithIcon;
