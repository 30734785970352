import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { switch_to_billing_screen } from "../Sales/Billing/newBillingSlice";
import CartList from "./components/cartList/CartList";
import AdvertisementScreen from "./components/advertisement/AdvertisementScreen";
import './customerDisplayStyle.css'

const CustomerDisplay = () => {
  const dispatch = useDispatch();

  // initial use effect
  useEffect(() => {
    dispatch(switch_to_billing_screen({ billingBoolean: true }));
  }, []);

  return (
    <div className="customer-display-container">
      <AdvertisementScreen />
      <CartList />
    </div>
  );
};

export default CustomerDisplay;
