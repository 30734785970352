// icon:polygon | Unicons https://iconscout.com/unicons | Iconscout
import * as React from "react";

function IconPolygon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1.5em"
      width="1.5em"
      {...props}
    >
      <path d="M21.87 11.5l-4.5-7.79a1 1 0 00-.87-.5h-9a1 1 0 00-.87.5l-4.5 7.79a1 1 0 000 1l4.5 7.79a1 1 0 00.87.5h9a1 1 0 00.87-.5l4.5-7.79a1 1 0 000-1zm-6 7.29H8.08L4.15 12l3.93-6.79h7.84L19.85 12z" />
    </svg>
  );
}

export default IconPolygon;
