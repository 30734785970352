import axios from "axios";
import { HEADERS } from "../../../../API/UrlAndPaths";
import store from "../../../../Redux/store";
import {DailySalesReportListSingleList,DailySalesReportList,
     salesReportBySalesPersonList, salesReportBySalesPersonSingleList,
     setSalesReport, setSalesReportSingle } from "./SalesReportSlice";

export const SalesReportAPIcall=async(body,setIsLoading)=>{
    setIsLoading !== undefined && setIsLoading(true)
    await axios
    .post("account/salesReport",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            setIsLoading !== undefined && setIsLoading(false)
            store.dispatch(setSalesReport({salesReportData:res.data}))
        }
    })
    .catch((err)=>{
        setIsLoading !== undefined && setIsLoading(false)
        store.dispatch(setSalesReport({salesReportData:undefined}))
        console.error(err.response);
    })
}

export const SalesReportSingleAPIcall=async(body)=>{
    await axios
    .post("account/salesReportSingle",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(setSalesReportSingle({salesReportSingleData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(setSalesReportSingle({salesReportSingleData:undefined}))
        console.error(err.response);
    })
}
export const SalesReportBySalesPersonAPIcall=async(body)=>{
    await axios
    .post("sales/salesReportByEmployee",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(salesReportBySalesPersonList({salesReportBySalesPersonData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(salesReportBySalesPersonList({salesReportBySalesPersonData:undefined}))
        console.error(err.response);
    })
}

export const SalesReportBySalesSingleViewAPIcall=async(body)=>{
    await axios
    .post("sales/salesReportBySalesPersonById",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(salesReportBySalesPersonSingleList({salesReportBySalesSingleListData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(salesReportBySalesPersonSingleList({salesReportBySalesSingleListData:undefined}))
        console.error(err.response);
    })
}
export const DailySalesReportAPIcall=async(body)=>{
    await axios
    .post("sales/dailySalesReport",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(DailySalesReportList({dailySalesReportData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(DailySalesReportList({dailySalesReportData:undefined}))
        console.error(err.response);
    })
}

export const DailySalesReportSingleViewAPIcall=async(body)=>{
    await axios
    .post("sales/dailySalesReportSingleView",body, HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(DailySalesReportListSingleList({dailySalesReportSingleListData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(DailySalesReportListSingleList({dailySalesReportSingleListData:undefined}))
        console.error(err.response);
    })
}