import { IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactToPrint from 'react-to-print'
import ActiveRibbon from "../../../../../Assets/statusRibbon/post.png"
import { useSelector } from 'react-redux'
import { SingleViewPosOrderAPI } from '../GeneralAPI'
import { useNavigate } from 'react-router-dom'
import Return from "../../../../../Assets/statusRibbon/return.png"
import Draft from "../../../../../Assets/statusRibbon/draft.png"

const PosOrdersSingleView = () => {
    const navigate=useNavigate()
    const [selectedButton, setSelectedButton] = useState("Products");
    
    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
    };

    const handleback=()=>{
        navigate("/userdashboard/pointOfSales/general/orders")
    }
    const singleId=localStorage.getItem("singleId")

    const singleView=useSelector((state)=>state.generalSlice.orderListSingleView)
   console.log(singleView);
   
   useEffect(()=>{
   singleId!==null && SingleViewPosOrderAPI({_id:singleId})
   },[])
    return (
        <div className='global-page-parent-container'>
            <div
                className="justify-space-between global-white-bg-container">
                  <div style={{display:"flex",alignItems:"center"}}>
                    <Tooltip title="Back">
              <IconButton  onClick={handleback}>
                <i className="bi bi-chevron-left" style={{ fontSize: '30px', color: 'black', cursor: 'pointer' }}></i>
              </IconButton>
            </Tooltip>
                <h5 style={{ margin: "0",cursor:"pointer",fontSize:"larger" }}>Order</h5>
                </div>
                <div className="justify-center" style={{ gap: "10px", width: "70%", justifyContent: "flex-end" }}>
                    <ReactToPrint
                        trigger={() => (
                            <IconButton className="printer-div" style={{ color: "#fff", backgroundColor: "#002995", borderRadius: "0" }}>
                                <i class="bi bi-printer"></i>
                            </IconButton>
                        )}
                    //   content={() => paymentsPrint}
                    />
                    <Tooltip title="Download">
                        <IconButton style={{ backgroundColor: "#ffd2d2", color: "#002995", borderRadius: "0" }}>
                            <i class="bi bi-arrow-down-circle"></i>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div className="global-white-bg-container">
                <div className="ribbon-ctn">
                {singleView?.status === "RETURN" && <img src={Return} alt="" />}
                                {singleView?.status === "DONE" && <img src={ActiveRibbon} alt="" />}
                                {singleView?.status === "DRAFT" && <img src={Draft} alt="" />}
                </div>
                <div className="justify-space-between single-viewinfo-parent-ctn">
                    <div className="single-view-left-ctn">
                        <h3 style={{ margin: "0" }}>{singleView?.orderNo}</h3>
                        <div className="justify-space-between">
                            <div className="single-view-info-ctn-left flex-column">
                                <p>Date</p>
                                <p>Branch</p>
                                <p>Shift No </p>
                                <p>Employee</p>
                                {singleView?.orderType==="DINEIN" &&(
                                <>
                                <p>Table</p>
                                <p>Chair</p>
                                </>
                                )}
                            </div>
                            <div className="single-view-info-ctn-right flex-column">
                                <p>{singleView?.orderDate}</p>
                                <p>{singleView?.branchName}</p>
                                <p>{singleView?.shiftId}</p>
                                <p>{singleView?.employeeName}</p>
                                {singleView?.orderType==="DINEIN" &&(
                                    <>
                                <p className="combo-list">{singleView?.tables.map((item)=>item.tableName).join(', ')}</p>
                                <p className="combo-list">{singleView?.chair.map((item)=>item.chairName).join(', ')}</p>
                                </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="justify-space-between" style={{ paddingTop: "30px", paddingRight: "90px" }}>
                            <div className="single-view-info-ctn-left flex-column">
                                <p>Customer</p>
                                <p>Mobile</p>
                                <p>Order Type</p>
                                {singleView?.orderType==="DELIVERY" &&(
                                    <>
                                <p>Aggregator </p>
                                </>
                                )}
                            </div>
                            <div className="single-view-info-ctn-right flex-column">
                                <p>{singleView?.customerName}</p>
                                <p>{singleView?.mobile}</p>

                                <p>{singleView?.orderType}</p>
                                {singleView?.orderType==="DELIVERY" &&(
                                    <>
                                <p>{singleView?.aggregatorName}</p>
</>
                                )}
                            </div>
                        </div>
                    </div>
                </div>



                <div className="button-div" style={{ padding: "0px", paddingInline: "30px", paddingTop: "19px" }}>

                    <button
                        className={selectedButton === 'Products' ? 'selected-button' : ''}
                        onClick={() => handleButtonClick('Products')}
                    >
                        Products
                    </button>

                    <button
                        className={selectedButton === 'Payments' ? 'selected-button' : ''}
                        onClick={() => handleButtonClick('Payments')}
                    >
                        Payments
                    </button>


                    <button
                        className={selectedButton === 'KOT order' ? 'selected-button' : ''}
                        onClick={() => handleButtonClick('KOT order')}
                    >
                        KOT order
                    </button>

                </div>
                {selectedButton === "Products" && (
                    <>
                        <div className="global-product-table special-items-table" style={{ paddingInline: "29px" }}>
                            <table >
                                <thead>
                                    <tr>
                                        <th>Product ID</th>
                                        <th>Product Name</th>
                                        <th>Qty</th>
                                        <th>UOM</th>
                                        <th>Rate</th>
                                        <th>Amount</th>
                                        <th>VAT%</th>
                                        <th>VAT</th>
                                        <th>Net Amount</th>
                                    </tr>
                                </thead>
                                <tbody style={{ borderBottom: '2px solid #dee3f8' }}>
                                  {singleView?.orderInfo?.list?.lenght !==0 ? (singleView?.orderInfo?.list.map((r,i)=>(
                                    <tr key={i}>
                                        <td>{r.itemcode}</td>
                                        <td>{r.itemName}</td>
                                        <td>{r.qty}</td>
                                        <td>{r.uomName}</td>
                                        <td>{r.rate}</td>
                                        <td>{r.amount}</td>
                                        <td>{r.vat}</td>
                                        <td>{r.vatAmount}</td>
                                        <td>{r.netAmnt}</td>
                                    </tr>
                                   ))):(
                                    <tr>
                                        <td colSpan={9}>NO DATA</td>
                                    </tr>
                                   )}
                                </tbody>
                                <tfoot style={{ textAlignLast: "end", backgroundColor: " #efeded" }}>
                                    <td style={{ fontWeight: "bold" }}>TOTAL</td>
                                    <td colSpan={5} style={{ paddingRight: "30px" }}>{singleView?.orderInfo?.amountTotal}</td>
                                    <td colSpan={1} style={{ paddingRight: "58px" }}>{singleView?.orderInfo?.vatAmountTot}</td>
                                    <td colSpan={2} style={{ paddingRight: "58px" }}>{singleView?.orderInfo?.netAmntTotal}</td>
                                </tfoot>
                            </table>

                        </div>
                        <div className="last-total-div" style={{ backgroundColor: "white" }}>
                            <div className="sub-total">
                                <h1>Total Before VAT</h1>
                                {singleView?.taxlist?.map((r,i)=>(
              <p key={i}>{r.taxName}</p>
              ))}
                                <p>Discount</p>
                                <p>Delivery Charge</p>
                                <hr className='line-2' />
                                <h1>Total INC VAT</h1>
                                <p>Amount Paid</p>
                                <p style={{ color: "red" }}>Amount Due</p>
                            </div>
                            <div className="sub-total-amount">
                                <h1>{singleView?.amountBeforeVat}</h1>
                                {singleView?.taxlist?.map((r,i)=>(
              <p key={i}>{r.taxAmount}</p>
              ))}
                                <p>{singleView?.discount}</p>
                                <p>{singleView?.deliveryCharge!==null ? singleView?.deliveryCharge : "0"}</p>
                                <hr className='line-3' />
                                <h1>{singleView?.totalIncVat}</h1>
                                <p>{singleView?.paidAmount}</p>
                                <p>{singleView?.amountDue}</p>
                            </div>
                        </div>
                    </>
                )}
                 {selectedButton === "Payments" && (
                    <div className="global-product-table special-items-table" style={{ paddingInline: "29px" }}>
                            <table >
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Payment Methord</th>
                                        <th>Type</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody style={{ borderBottom: '2px solid #dee3f8' }}>
                                    {singleView?.paymentList?.list?.lenght!==0 ?(singleView?.paymentList?.list?.map((r,i)=>(
                                    <tr key={i}>
                                        <td>{r?.paymentDate}</td>
                                        <td>{r.paymentType}</td>
                                        <td>{r.vendorName}</td>
                                        <td>{r.amount}</td> 
                                    </tr>
                                    ))):(
                                        <tr>
                                            <td colSpan={4}>NO DATA</td>
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot style={{ textAlignLast: "end", backgroundColor: " #efeded" }}>
                                    <td style={{ fontWeight: "bold" }}>TOTAL</td>
                                    <td colSpan={3} style={{ paddingRight: "30px" }}>{singleView?.paymentList?.totalPaid}</td>
                                    
                                </tfoot>
                            </table>

                        </div>

                 )}

{selectedButton === "KOT order" && (
                    <div className="global-product-table special-items-table" style={{ paddingInline: "29px" }}>
                            <table >
                                <thead>
                                    <tr>
                                        <th>Sequence</th>
                                       
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody style={{ borderBottom: '2px solid #dee3f8' }}>
                                    {singleView?.kotList?.lenght !==0 ?(singleView?.kotList.map((r,i)=>(
                                    <tr key={i}>
                                        <td>{r.sequenceName}</td>
                                        <td>{r.status}</td>
                                    </tr>
                                    ))):(
                                        <tr>
                                            <td colSpan={2}>NO DATA</td>
                                        </tr>
                                    )}
                                </tbody>
                               
                            </table>

                        </div>

                 )}
            </div>
        </div>
    )
}

export default PosOrdersSingleView