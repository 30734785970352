import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import axios from "axios";
import { switch_to_billing_screen } from "../../Sales/Billing/newBillingSlice";
import { setHideHeader } from "../../KotTokenDisplay/kotTokenDisplaySlice";
import { setAlertMessage, setNetworkStatus, setSelectedKOTOrder } from "../../kitchenOrderTicket/kitchenOrderTicketSlice";

import AlertMessage from "../../FloorManagement/components/alertMessage/AlertMessage";
import { LoadingForm } from "../../../Single Components/LoadingForm";
import { NETWORK } from "../../kitchenOrderTicket/utils/kotEnum";
import DeliveryScreenHeader from "./Components/DeliveryScreenHeader";
import { useLocation } from "react-router-dom";
import DeliveryScreenBody from "./Components/DeliveryScreenBody";
import { listAggregatorsAPI } from "../Configuration/Aggregator/aggregatorAPI";
import { listDeliveryOrderAPI } from "./deliveryScreenAPI";
import { setDeliveryOrderStatus } from "./deliveryScreenSlice";

const DeliveryScreen = () => {

  // =============================
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    isLoading,
    alertMessage,
    darkMode,
    networkStatus,
  } = useSelector((state) => state.kitchenOrderTicketSlice);
  const {
    deliveryOrderStatus,deliveryStatusFilter
  } = useSelector((state) => state.deliveryScreenSlice);

  const { branchPk, data, ...rest } = useSelector(
    (state) => state.loginResponseSlice.value
  );

  const [sendBody, setSendBody] = useState({
    status: "PICKUP",
    branchId: branchPk,
    isSelf:true,
    pickUpStatus:null
  });

  //initial actions
  useEffect(() => {
    dispatch(switch_to_billing_screen({ billingBoolean: true }));
    dispatch(setHideHeader(true));
    dispatch(setSelectedKOTOrder(null));
    listAggregatorsAPI({branchId:branchPk})
  }, [branchPk]);

  useEffect(() => {
    setSendBody((prevSendBody) => ({
      ...prevSendBody,
      status: "PICKUP",
      isSelf:deliveryOrderStatus?.status?.key==="self",
      pickUpStatus:deliveryStatusFilter==="Pickup"?"RDYPICKUP":deliveryOrderStatus==="Delivering"?"PICKUP":null
    }));
  }, [deliveryOrderStatus?.status?.key,deliveryStatusFilter]);

  // socket connection
  useEffect(() => {
    //connect socket io to backend
    // const socket = io(axios.defaults.baseURL)
    const socket = io(axios.defaults.baseURL, {
      path: "/socket.io/",
      reconnection: true,
      transports: ["websocket"],
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      // reconnectionAttempts: Infinity
    });

    //get backend response if connect the socket

    socket.on("connect", () => {
      if (data && branchPk) {
        socket.emit("setUsername", data?._id, branchPk);
        dispatch(setNetworkStatus(NETWORK.ONLINE));
      }
    });

    // call kot list when  receive NEW KOT ORDER status updated
    socket.on("toKotDeliveryStatus", () => {
      listDeliveryOrderAPI({ body: sendBody });
    });


    //connection error
    socket.io.on("error", (error) => {
      // Handle connection error
      dispatch(setNetworkStatus(NETWORK.OFFLINE));
      dispatch(
        setAlertMessage({ status: "error", message: "Server is down!" })
      );
    });
    // Clean up function
    return () => {
      if (socket) {
        socket.off("connect");
        socket.disconnect();
      }
    };

  }, [sendBody]);

  // Check for online status and show alert message
  useEffect(() => {
    dispatch(
      setDeliveryOrderStatus({
        index: 0,
        status: { key: "self", name: "SELF DELIVERY", color: "#465378" },
      })
    );
    const handleOnlineStatus = () => {
      if (navigator.onLine) {
        dispatch(setNetworkStatus(NETWORK.ONLINE));

        dispatch(
          setAlertMessage({ status: "success", message: "You are online!" })
        );
      } else {
        dispatch(setNetworkStatus(NETWORK.OFFLINE));
        dispatch(
          setAlertMessage({ status: "error", message: "You are offline!" })
        );
      }
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  useEffect(() => {
    if (networkStatus === NETWORK.ONLINE) {
      listDeliveryOrderAPI({ body: sendBody });
    }
  }, [networkStatus]);

  useEffect(() => {
    // To remove header when exiting the page
    return () => {
      dispatch(switch_to_billing_screen({ billingBoolean: false }));
      dispatch(setHideHeader(false));
    };
  }, [location]);


  //setAlertMessage
  const setAlertMessageHandler = () => {
    if (alertMessage !== null) {
      dispatch(setAlertMessage(null));
    }
  };

  return (
    <div id="kot-container" data-theme={darkMode ? "dark" : "light"}>
      <div className="kot-container">
        <DeliveryScreenHeader branchId={branchPk} />
        <div className="kot-body" style={{height:"calc(100vh - 78px)"}}>
          <DeliveryScreenBody />
        </div>
      </div>
      <LoadingForm loading={isLoading} />
      <AlertMessage
        status={alertMessage?.status}
        alertMessage={alertMessage?.message}
        setApiAlertMessage={setAlertMessageHandler}
      />
    </div>
  );
};

export default DeliveryScreen;
