import React from "react";
import "./FooterStyles.css";
import { useSelector } from "react-redux";

const PrintFooter = ({ currentPage, totalPages }) => {
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  return (
    <footer
      style={{
        padding: "10px",
        textAlign: "center",
        position: "absolute",
        bottom: "0",
        left: "0",
        width: "100%",
      }}
      className="new-ftr-container"
    >
      <div className="new-ftr-content">
        <div className="new-ftr-page-number">
          PAGE {currentPage}/{totalPages}
        </div>
        <div className="new-ftr-contact-info">
          <span className="new-ftr-phone">📞 {companyProfileData?.contactNumber}</span>
          <span className="new-ftr-email">✉️ {companyProfileData?.companyEmail}</span>
          <span className="new-ftr-website">🌐 {companyProfileData?.website}</span>
        </div>
      </div>
    </footer>
  );
};

export default PrintFooter;
