import React, { useEffect } from 'react'
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import { convertDateFormat } from '../../../../Js/Date';

const PosSummaryReportPrint = (props) => {
    const { formValues, grandTotals } = props
    const { tableLoading } = useSelector((state) => state.mainDashBoardSlice)

    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
    );
    const { reportsList } = useSelector((state) => state.posShiftSummaryReportSlice)
    const decimalPosition = localStorage.getItem("decimalPosition");



    return (
        <div className="print-main-div" style={{ backgroundColor: "white" }}>
            <div className='header-div' style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <img src={companyProfileData?.companyLogo} alt="" style={{ height: "62px" }} />
                </div>
                <div style={{ textAlignLast: "center" }}>
                    <h1 style={{ fontSize: "medium", margin: "0" }}>Sales Details</h1>

                    {/* <p style={{margin:"0" ,fontSize: "small"}}>--</p> */}


                </div>
                <div>
                    <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyName}</p>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyAddress}</p>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyContact}</p>
                </div>
            </div>
            <div className='global-white-bg-container shift-report-table-container' style={{ overflow: "visible" }}>

                {

                    tableLoading ?
                        <div className="new-global-table-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Order Date</th>
                                        <th>Order Ref</th>
                                        <th>Customer</th>
                                        <th>Mobile</th>
                                        <th>Order Amount</th>
                                        <th>Paid Amount</th>
                                        <th>Bank</th>
                                        <th>Cash</th>
                                        <th>Credit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        [...Array(10)].map((_, i) => (
                                            <tr key={i}>
                                                <td colspan={15}>
                                                    <Skeleton variant="rectangular"
                                                        animation="wave"
                                                        width={"100%"}
                                                        height={40} />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        :
                        reportsList?.length !== 0 ?
                            reportsList?.map((list, i) => (
                                <>
                                    <div className="single-view-left-ctn" style={{ width: "60%", minWidth: "fit-content", maxWidth: "550px", padding: "16px", margin: "1%", marginBottom: "0", backgroundColor: "rgb(248 248 248)" }}>
                                        <h3 style={{ margin: "0", cursor: "pointer", fontSize: "larger" }}>{formValues.branch?.branchName || "-NIL-"}</h3>
                                        <div className="justify-space-between" style={{ display: "flex" }}>
                                            <div style={{ display: "flex", gap: "10px" }}>
                                                <div className="single-view-info-ctn-left flex-column">
                                                    <p style={{ margin: "1px" }}>Shift</p>
                                                    <p style={{ margin: "1px" }}>Employee</p>
                                                </div>
                                                <div className="single-view-info-ctn-right flex-column">
                                                    <p style={{ margin: "1px" }}>SHIFT {list?.shift?.shiftId}</p>
                                                    <p style={{ margin: "1px" }}>{list?.shift?.empName}</p>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", gap: "10px" }}>
                                                <div className="single-view-info-ctn-left flex-column">
                                                    <p style={{ margin: "1px" }}>Start Date</p>
                                                    <p style={{ margin: "1px" }}>End Date</p>
                                                </div>
                                                <div className="single-view-info-ctn-right flex-column">
                                                    <p style={{ margin: "1px" }}>{list?.shift?.startDate}</p>
                                                    <p style={{ margin: "1px" }}>{list?.shift?.endDate}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="new-global-table-container" style={{ paddingTop: "0 42px", overflow: "visible", maxHeight: "fit-content" }}>
                                        <table>
                                            <thead >
                                                <tr>
                                                    <th>Order Date</th>
                                                    <th>Order Reff</th>
                                                    <th>Customer</th>
                                                    <th>Mobile</th>
                                                    <th>Order Amount</th>
                                                    <th>Paid Amount</th>
                                                    <th>Credit</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list?.orders?.length !== 0 ?
                                                    list?.orders?.map((item, i) => (
                                                        <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                                                            <td style={{ border: "none", height: "29px" }}>{convertDateFormat(item?.orderDate)}</td>
                                                            <td style={{ border: "none", height: "29px" }}>{item?.name}</td>
                                                            <td style={{ border: "none", height: "29px" }}>{item?.customerName}</td>
                                                            <td style={{ border: "none", height: "29px" }}>{item?.custMobile}</td>
                                                            <td style={{ border: "none", height: "29px" }}>{item?.amtTotal?.toFixed(decimalPosition)}</td>
                                                            <td style={{ border: "none", height: "29px" }}>{item?.paidAmt?.toFixed(decimalPosition)}</td>
                                                            <td style={{ border: "none", height: "29px" }}>{item?.amountDue?.toFixed(decimalPosition)}</td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <tr>
                                                        <td colspan={7}>No Records found</td>
                                                    </tr>
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr style={{ backgroundColor: "#dfd9d9" }}>
                                                    <td colSpan={4} style={{ textAlignLast: "end" }}>TOTAL</td>
                                                    <td style={{ textAlignLast: "center" }}>{list?.totalAmtTotal?.toFixed(decimalPosition)}</td>
                                                    <td style={{ textAlignLast: "center" }}>{list?.totalPaidAmt?.toFixed(decimalPosition)}</td>
                                                    <td style={{ textAlignLast: "center" }}>{list?.totalDueAmount?.toFixed(decimalPosition)}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        {/* {
            Object.keys(grandTotals).length!==0 &&    
            <div className="new-global-table-container report-totalbar" style={{position:"unset"}}>
                <table>
                    <thead style={{display:"none"}}>
                        <tr>
                            <th>Order Date</th>
                            <th>Order Reff</th>
                            <th>Customer</th>
                            <th>Mobile</th>
                            <th>Order Amount</th>
                            <th>Paid Amount</th>
                            <th>Credit</th>

                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>GRAND TOTAL</td>
                            <td>{Number(grandTotals?.totalAmtTotal)?.toFixed(decimalPosition)}</td>
                            <td>{Number(grandTotals?.totalPaidAmt)?.toFixed(decimalPosition)}</td>
                            <td>{Number(grandTotals?.totalDueAmount)?.toFixed(decimalPosition)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>} */}
                                    </div>
                                </>
                            ))
                            :
                            <div className="center-div">
                                No Records
                            </div>
                }
                {
                    Object.keys(grandTotals).length !== 0 &&
                    <div className="new-global-table-container report-totalbar" style={{ position: "unset", maxHeight: "fit-content" }}>
                        <table>
                            <thead style={{ display: "none" }}>
                                <tr>
                                    <th>Order Date</th>
                                    <th>Order Reff</th>
                                    <th>Customer</th>
                                    <th>Mobile</th>
                                    <th>Order Amount</th>
                                    <th>Paid Amount</th>
                                    <th>Credit</th>

                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>GRAND TOTAL</td>
                                    <td>{Number(grandTotals?.totalAmtTotal)?.toFixed(decimalPosition)}</td>
                                    <td>{Number(grandTotals?.totalPaidAmt)?.toFixed(decimalPosition)}</td>
                                    <td>{Number(grandTotals?.totalDueAmount)?.toFixed(decimalPosition)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>}
            </div>

            <div>

            </div>

        </div>
    )
}

export default PosSummaryReportPrint