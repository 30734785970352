import axios from "axios";
import { generate_sales_order_name, get_sales_order_history, get_sales_order_res_list, get_sales_order_row_data_list } from "./salesOrderSlice";
import store from "../../../../../Redux/store";
import { HEADERS, HEADER_FORM_DATA } from "../../../../../API/UrlAndPaths";
import { get_delivery_noterow_data_list } from "../DeliveryNote/deliveryNoteSlice";
import { deliveryNoteSingleViewAPICall } from "../DeliveryNote/deliveryNoteAPI";
// sales order quatation listing
export const viewSalesOrdersListAPICall=async(body,setIsLoading)=>{
  setIsLoading !== undefined && setIsLoading(true)  
  await axios
    .post(`sales/viewsalesOrder`,body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_sales_order_res_list({salesOrderData:res.data}))
        setIsLoading !== undefined && setIsLoading(false)
      }
    })
    .catch((err) => {
        setIsLoading !== undefined && setIsLoading(false)
        store.dispatch(get_sales_order_res_list({salesOrderData:undefined}))
    });
}
export const salesOrderFilterAPICall =(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)
  axios.post("sales/salesOrderAndQuotationSearch",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_sales_order_res_list({salesOrderData:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_sales_order_res_list({salesOrderData:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)
  })

}


// generate sales order name
// export const generateSalesQuatationAPICall=async(body)=>{
//     await axios
//     .post(`account/generateVendorBillNo`,body, HEADERS)
//     .then((res) => {
//       if (res.status === 200) {
//         store.dispatch(generate_sales_order_quot_name({salesQuotname:res.data}))
//       }
//     })
//     .catch((err) => {
//         store.dispatch(generate_sales_order_quot_name({salesQuotname:undefined}))
//     });
// }
// // draft sales quatation api call

export const draftSalesOrderAPICall=async(body,postFn,setState,state,setEditDataId,setLoadingForm)=>{
  setLoadingForm!==undefined && setLoadingForm(true)

    await axios.post("sales/addSalesOrder",body,HEADER_FORM_DATA)
     .then((res)=>{
        if(res.status===200){
          postFn()
          setEditDataId(res.data?._id)
          setState({...state,message:"sales order drafted",success:true})
         localStorage.setItem("singleViewById",res.data._id)
         salesOrderSingleViewAPICall({_id:res.data._id})
         setLoadingForm!==undefined && setLoadingForm(false)

        }
     })
     .catch((err)=>{
      setLoadingForm!==undefined && setLoadingForm(false)
      if (err.response.status === 500) {
        setState({ ...state, message: "Internal Server error !", error: true })
      } else {
        setState({ ...state, message: err?.response?.data, error: true })
      }
     })
}

// // edit sales order api call

export const editSalesOrderAPICall=async(body,postFn,setState,state,setLoadingForm)=>{
  setLoadingForm!==undefined && setLoadingForm(true)

  await axios.post("sales/editSalesOrder",body,HEADER_FORM_DATA)
   .then((res)=>{
      if(res.status===200){
        setLoadingForm!==undefined && setLoadingForm(false)
        setState({...state,message:"sales order edited",success:true})
          postFn()
      }
   })
   .catch((err)=>{
    setLoadingForm!==undefined && setLoadingForm(false)
    if (err.response.status === 500) {
      setState({ ...state, message: "Internal Server error !", error: true })
    } else {
      setState({ ...state, message: err?.response?.data, error: true })
    }
   })
}

// // confirm sales order api call

export const confirmSalesOrderAPICall=async(body,postFn,setState,state,setLoadingForm)=>{
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios.post("sales/draftDeliveryNote",body,HEADERS)
   .then((res)=>{
      if(res.status===200){
      
        setLoadingForm!==undefined && setLoadingForm(false)
        // store.dispatch(get_delivery_noterow_data_list({deliveryNoteSingleData:res.data}))
        setState({...state,message:"sales order confirmed",success:true})
        postFn()
        deliveryNoteSingleViewAPICall({id:res.data._id})
        localStorage.setItem("singleViewById",res.data._id)
      }
   })
   .catch((err)=>{
    setLoadingForm!==undefined && setLoadingForm(false)
    if (err.response.status === 500) {
      setState({ ...state, message: "Internal Server error !", error: true })
    } else {
      setState({ ...state, message: err?.response?.data, error: true })
    }
   })
}

// generate sales order name api call
export const generateSalesOrderNameAPICall=(body,setCreditNoteName)=>{
   axios.post("sales/genarateSalesOrderName",body,HEADERS)
   .then((res)=>{
    if(res.status===200){
      store.dispatch(generate_sales_order_name({salesOrderQuotname:res.data}))
      setCreditNoteName(res.data?.NAME)
    }
   })
   .catch((err)=>{
    setCreditNoteName("###")
   })
}

// sales order single view
export const salesOrderSingleViewAPICall=(body)=>{
  axios.post("sales/viewSalesOrderById",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
     store.dispatch(get_sales_order_row_data_list({salesOrderSingleData:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_sales_order_row_data_list({salesOrderSingleData:undefined}))

  })
}
/// sales order history

export const salesOrderHistoryAPICall=(body,setLoadingForm)=>{
  setLoadingForm!==undefined && setLoadingForm(true)
  axios.post("purchase/salesHistory",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_sales_order_history({salesOrderHistory:res.data}))
      setLoadingForm!==undefined && setLoadingForm(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_sales_order_history({salesOrderHistory:undefined}))
    setLoadingForm!==undefined && setLoadingForm(false)
  })
}

