import { configureStore } from "@reduxjs/toolkit";

import searchUserSliceReducer from "../Redux/Login/searchUserSlice";
import loginResponseSliceReducer from "../Redux/Login/loginResponseSlice";
import allReadymadeCategoriesReducer from "../Redux/Product Master/allReadymadeCategoriesSlice";
import allReadymadeSubCategorySliceReducer from "../Redux/Product Master/allReadymadeSubCategorySlice";
import readymadeOccasionSliceReducer from "../Redux/Product Master/readymadeOccasionSlice";
import productIdSliceReducer from "../Redux/Product Master/productIDSlice";
import colorListSliceReducer from "../Redux/Product Master/colorListSlice";
import readymadeBrandSliceReducer from "../Redux/Product Master/allReadymadeBrandsSlice";
import allFittingsSliceReducer from "../Redux/Product Master/allFittingsSlice";
import productCareSliceReducer from "../Redux/Product Master/productCareSlice";
import neckStyleSlicereducer from "../Redux/Product Master/neckStyleSlice";
import unitsSliceReducer from "../Redux/Product Master/unitsSlice";
import readymadeStyleSliceReducer from "../Redux/Product Master/readymadeStyleSlice";
import patternSliceReducer from "../Redux/Product Master/patternSlice";
import materialSliceReducer from "../Redux/Product Master/materialSlice";
import allMaterialCategorySliceReducer from "../Redux/Product Master/allMaterialCategorySlice";
import allAccessoryCategorySliceReducer from "../Redux/Product Master/allAccessoryCategorySlice";
import allAccessorySubCategorySliceReducer from "../Redux/Product Master/allAccessorySubCategorySlice";
import sizeChartSliceReducer from "../Redux/Product Master/sizeChartSlice";
import companyProfileSliceReducer from "../Redux/Settings/Company Settings/companyProfileSlice";
import allLocationsSliceReducer from "../Redux/Settings/Company Settings/allLocationsSlice";
import allBranchesSliceReducer from "../Redux/Settings/Company Settings/allBranchesSlice";
import allDepartmentSliceReducer from "../Redux/Staff/HRM/Department/allDepartmentSlice";
import allDesignationListSliceReducer from "../Redux/Staff/HRM/Designation/allDesignationList";
import allInactiveEmployeeSliceReducer from "../Redux/Staff/HRM/Employee/allIncativeEmployeeSlice";
import employeeCompanyInfoFormSliceReducer from "../Redux/Staff/HRM/Employee/employeeCompanyInfoFormSlice";
import empQrSliceReducer from "../Redux/Staff/HRM/Employee/empQrSlice";
import allActiveEmployeeSliceReducer from "../Redux/Staff/HRM/Employee/allActiveEmployeesSlice";
import singleEmployeeSliceReducer from "../Redux/Staff/HRM/Employee/singleEmployeeSlice";
import allProductsListSliceReducer from "../Redux/Product List/allProductsListSlice";
import productSingleViewSliceReducer from "../Redux/Product List/productSingleViewSlice";
import userRoleSliceReducer from "../Redux/Login/userRoleSlice";
import selectShiftReducer from "../Redux/Settings/Shift/SelectShiftSlice";
import shiftReopenDialogReducer from "../Redux/Settings/Shift/shiftReopenDialogSlice";

import shiftDenominationDialogReducer from "../Redux/Settings/Shift/shiftDenominationDialogSlice";

import viewRolesAndPermissionSliceReducer from "./Settings/Roles and permission/viewRolesAndPermissionSlice";
import stockReportReducer from "./Report/stockReportSlice";
import allCustomerListSliceReducer from "./Customer/allCustomerListSlice";
import salesReportReducer from "./Report/salesReportSlice";
import viewShiftReducer from "./Settings/Shift/viewShiftSlice";
import stockSummaryReportReducer from "./Report/stockSummaryReportSlice";
import stockAdjTransNoReducer from "./Report/StockAdjustmentReport/stockAdjustmenTransNoSlice";
import stockAdjSingleViewReducer from "./Report/StockAdjustmentReport/stockAdjSingleViewSlice";
import purchaseOrderIdSliceReducer from "./Prchase Manager/Purchase Order/purchaseOrderIdSlice";
import purchaseReportReducer from "./Report/PurchaseReport/purchaseReportSlice";
import purchaseReportSingleViewReducer from "./Report/PurchaseReport/purchaseReportSingleViewSlice";
import taxSliceReducer from "./Tax/taxSlice";
import expenseTypeReducer from "./Expense/AddExpenseType/addExpenseTypeSlice";
import addExpenseTypeDocNoReducer from "./Expense/AddExpenseType/addExpenseTypeDocNoSlice";
import staffExpenseReducer from "./Expense/StaffExpense/staffExpenseSlice";
import outletExpenseSlice from "./Expense/outletExpense/outletExpenseSlice";
import outletExpenseDocNoReducer from "./Expense/outletExpense/outletExpenseDocNoSlice";
import pettyCashBalanceReducer from "./Expense/outletExpense/viewPettyCashBalanceSlice";
import pettyCashReceiptReducer from "./Expense/PettyCashReceipt/pettyCashReceiptSlice";
import pettyCashDocNoReducer from "./Expense/PettyCashReceipt/pettyCashReciptDocNoSlice";
import cardManualReducer from "./Settings/CardAndUpiType/cardManualSlice";
import chequeManualReducer from "./Settings/CardAndUpiType/chequeManualSlice"
import upiManualReducer from "./Settings/CardAndUpiType/upiManualSlice";
import allEmployeeDocSliceReducer from "./Staff/HRM/Documents/allEmployeeDocSlice";
import allUpiProviderReducer from "./Settings/Company Settings/allUpiProviderSlice";
import shiftStatusSliceReducer from "./Settings/Shift/shiftStatusSlice";
import utilityMenuSliceReducer from "./Settings/Utility/utilityMenuSlice";

import viewPurchaseOrderReducer from "./Prchase Manager/Purchase Order/viewPurchaseOrderSlice";
import purchaseOrderSingleViewReducer from "./Prchase Manager/Purchase Order/singleViewPurchaseOrderSlice";
import allEmployeeLoanSliceReducer from "./Staff/HRM/Employee/allEmployeeLoanSlice";
import singleEmployeeLoanSliceReducer from "./Staff/HRM/Employee/singleEmployeeLoanSlice";
import paymentReportReducer from "./Report/paymentReportSlice";
import expenseReportReducer from "./Report/expenseReportSlice";
import employeeDocViewSliceReducer from "./Staff/HRM/Documents/employeeDocViewSlice";

import smsHeaderReducer from "./Settings/SmsSettings/viewSmsHeaderSlice";

import allLeaveTypeSliceReducer from "./Staff/LEAVE/allLeaveTypeSlice";


import viewCurrencyReducer from "./Settings/Currency/viewCurrencySlice";
import getDiscountReducer from "./Settings/Discount/getDiscountSlice";
import allCustomerListWithFilterReducer from './Customer/allCustomerListWithFilterSlice'
import docTypeSliceReducer from "./Staff/HRM/Documents/docTypeViewSlice";
import logReducer from './Settings/Log/LogSlice'
import shiftTransferDialogReducer from './Settings/Utility/shiftTransferReopenDialogSlice'
import shiftTransferOrderListReducer from './Settings/Utility/ShiftTransfer/shiftTransferOrderListSlice'
import allOrdersInvoiceNoReducer from './Settings/Utility/ShiftTransfer/shiftTransferOrderListInvoiceNoSlice'

import utilityPaymentListReducer from './Settings/Utility/PaymentTypeAndAmount/paymentListSlice'
import paymentInvoiceNoListReducer from './Settings/Utility/PaymentTypeAndAmount/paymetsInvoiceNoListSlice'

import allLeaveApplicationSliceReducer from "./Staff/LEAVE/allLeaveApplicationSlice";

import expenseGraphSliceReducer from './Staff/Staff Dashboard/expenseGraphSlice'
import empCountGraphSliceReducer from './Staff/Staff Dashboard/empCountGraphSlice'
import noticeboardSliceReducer from './Staff/Staff Dashboard/noticeboardSlice'
import holidayTypeViewSliceReducer from "./Staff/LEAVE/holidayTypeViewSlice";
import jobCompletionWorkorderListSliceReducer from "./Settings/Utility/JobCompletion/jobCompletionWorkorderListSlice";
import utilityJobCompletionListSliceReducer from "./Settings/Utility/JobCompletion/utilityJobCompletionListSlice";

import cuttingSlipModelReducer from './Settings/CuttingSlipModel/cuttingSlipModelSlice'
import stockOrderListSliceReducer from "./Settings/Utility/stockCorrection/stockOrderListSlice";
import staffAllPayrollSliceReducer from "./Staff/Payroll/staffAllPayrollSlice";
import staffSinglePayslipSliceReducer from "./Staff/Payroll/staffSinglePayslipSlice";
import generatePayrollVoucherSliceReducer from "./Staff/Payroll/generatePayrollVoucherSlice";
import posReceiptSlice from "../component/Modules/Settings/posReceipt/posReceiptSlice";
import foodManagement from "../component/Modules/FoodManagementCopy/FoodManagementSlice"
import generalSlice from "../component/Modules/PointOfSale/General/GeneralSlice"
import journalSlice from "./AccountUpdated/journalSlice";
import chartOfAccountsSlice from "./AccountUpdated/chartOfAccountsSlice"

import viewUpdatedCurrency from "./Settings/Currency/viewCurrencyUpdatedSlice"

import journalEntrySliceReducer from "../component/Modules/Accounts/Accounting/JournalEntry/journalEntrySlice";
import unitOfMeasurmentSliceReducer from "../component/Modules/Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentSlice";

import glConfigurationSliceReducer from "../component/Modules/Accounts/Configuration/glConfigurationSlice";

import ConfigurationSlice from "./Prchase Manager/Configuration/ConfigurationSlice"

import taxSettingsSlice from "./Settings/TaxSettings/taxSettingsSlice"
import PurchaseTaxListSlice from './Prchase Manager/Configuration/PurchaseTaxListSlice'

import vendorBillSliceReducer from "../component/Modules/Accounts/Vendor/Slice/vendorBillSlice";

import vendorPaymentSliceReducer from "../component/Modules/Accounts/Vendor/Slice/vendorPaymentSlice";
import vendorsListSliceReducer from "../component/Modules/Accounts/Vendor/Slice/vendorsListSlice";

import sequenceSlice from "../component/Modules/Settings/Sequence/sequenceSlice"
import wareHouseSlice from "../component/Modules/Inventory/Configuration/wareHouseSlice"
import stockMoveSliceReducer from "../component/Modules/Inventory/Operations/Stock Moves/stockMovesSlice";
import wareHouseLocationSlice from "../component/Modules/Inventory/Configuration/wareHouseLocationSlice"
import internalTransferSlice from "../component/Modules/Inventory/Operations/InternalTransfer/InternalTransferSlice"
import branchTransferSlice from "../component/Modules/Inventory/Operations/BranchTransfer/BranchTransferSlice"
import goodsReceivedNoteUpdatedSliceReducer from "../component/Modules/Purchase Manager/Pages/GRNUpdated/grnListingSlice";
import RfqSlice from "./Prchase Manager/RFQ/RfqSlice";
import posCategorySlice from "../component/Modules/Inventory/Configuration/POSCategory/posCategorySlice"
import categorySlice from "../component/Modules/Inventory/Configuration/Category/categorySlice"
import attributeSlice from "../component/Modules/Inventory/Configuration/Attribute/AttributeSlice"
import salesQuatationSliceReducer  from "../component/Modules/SalesNew/SalesOrders/SalesQuotation/salesQuatationSlice";
import salesOrderSliceReducer from "../component/Modules/SalesNew/SalesOrders/SalesOrder/salesOrderSlice";
import inventoryConfigSettingSliceReducer from "../component/Modules/Inventory/Configuration/inventoryConfigurationSettingsSlice";
import inventoryProductSliceReducer  from "../component/Modules/Inventory/Products/inventoryProductsSlice";
import branchSettingsSliceReducer  from "../component/Modules/Settings/BranchSettings/branchSettingSlice";
import allAllowedBranchesSlice from "../Redux/Settings/Company Settings/allAllowedBranchesSlice"
import customerInvoiceSliceReducer from "../component/Modules/Accounts/Customers/CustomerInvoice/customerInvoiceSlice";
import customerCreditNoteSliceReducer from "../component/Modules/Accounts/Customers/CreditNotes/customerCreditNotesSlice";

import salesWSOSliceReducer from "../component/Modules/Accounts/Customers/SalesWSO/SalesWSOSlice";
import purchaseWPOSliceReducer from "../component/Modules/Accounts/Vendor/purchaseWPO/purchaseWPOlist";

import BranchReceiptSlice from "../component/Modules/Inventory/Operations/BranchReceipt/BranchReceiptSlice"
import InventoryAdjustmentSlice from "../component/Modules/Inventory/Operations/InventoryAdjustment/InventoryAdjustmentSlice"

import deliveryNoteSliceReducer from "../component/Modules/SalesNew/SalesOrders/DeliveryNote/deliveryNoteSlice";

import customerPaymentSlice from "../component/Modules/Accounts/Customers/Payments/customerPaymentSlice"
import debitNoteSlice from "../component/Modules/Accounts/Vendor/Slice/debitNoteSlice";

import landedCostSlice from "../component/Modules/Inventory/Operations/LandedCost/LandedCostSlice";
import Openingbalance from "../component/Modules/Accounts/Accounting/OpeningBalanceSlice";


import posSettingSlice from "../component/Modules/PointOfSale/Configuration/Settings/PosSettingsSlice"
import newBillingSliceReducer from "../component/Modules/Sales/Billing/newBillingSlice";
import cashInOrOutSlice from "../Redux/Dashboard/cashInOrOutSlice"
import trailbalance from "../component/Modules/Accounts/ReportsNew/FinancialReports/TrailBalanceSlice"
import branchSettingsSliceNew from "../component/Modules/PointOfSale/Configuration/BranchSettings/BranchSettingsSlice"
import partnerReportSlice from "../component/Modules/Accounts/ReportsNew/PartnerReport/partnerReportSlice";
import GenerateQrBarcodeSlice from "../component/Modules/GenerateQrOrBarcode/GenerateQrBarcodeSlice"
import GeneralReportSlice from "../component/Modules/Accounts/ReportsNew/General Report/GeneralReportSlice";
import SalesReportSlice from "../component/Modules/SalesNew/SalesReport/SalesReportSlice";
import ReportSlice from "../component/Modules/Inventory/Reports/ReportSlice";
import posReportSlice  from "../component/Modules/Report/POSSalesReport/posSalesReportAPI";
import purchaseReportNewSlice from "../component/Modules/Purchase Manager/Pages/PurchaseReport.jsx/purchaseReportNewSlice";
import BankReconciliationSlice from "../component/Modules/Accounts/Reconciliation/BankReconciliationSlice";
import EstablishmentIdSlice from "../component/Modules/Payroll/Establishment/EstablishmentIdSlice";
import OvertimeSlice from "../component/Modules/Payroll/Attendance/Overtime/OvertimeSlice";
import vatReportSlice from "../component/Modules/Accounts/VatReport/vatReportSlice";
import SettingsSlice from "../component/Modules/Settings/SettingsSlice";
import filterCategorySlice from "../component/Single Components/listSlice/filterCategorySlice";
import addSeatSlice from "../component/Modules/FloorManagement/addSeat/addSeatSlice";
import kitchenOrderTicketSlice from "../component/Modules/kitchenOrderTicket/kitchenOrderTicketSlice";
import topBarSliceBilling from "../component/Modules/Sales/Billing/Components/topBarSliceBilling";
import billingFloorPlanSlice from "../component/Modules/Sales/Billing/Slice/billingFloorPlanSlice";
import billingScreenSlice from "../component/Modules/Sales/Billing/Slice/billingScreenSlice";
import viewOrdersBillingSlice from "../component/Modules/Sales/Billing/Slice/viewOrdersBillingSlice";
import kotTokenDisplaySlice from "../component/Modules/KotTokenDisplay/kotTokenDisplaySlice";
import arrowPathSlice from "../component/Modules/Sales/Billing/Utilities/ArrowPathSlice";
import specialItemsSlice from "../component/Modules/Sales/SpecialItems/specialItemsSlice";
import mainDashBoardSlice from "./Dashboard/mainDashBoardSlice";
import calculatorSlice from "../component/Modules/Sales/Billing/Slice/calculatorSlice";
import aggregatorSlice from "../component/Modules/PointOfSale/Configuration/Aggregator/aggregatorSlice";
import reservationSlice from "../component/Modules/Sales/Billing/Slice/reservationSlice";
import filterButtonSlice from "../component/Modules/Sales/Billing/Utilities/filterButtonSlice";

import posShiftSummaryReportSlice from "../component/Modules/PointOfSale/Report/ReportsSlice/posShiftSummaryReportSlice";

 import posReportshiftSlice from "../component/Modules/PointOfSale/Report/ReportSlice"
import accountDashboardSlice from "../component/Modules/Accounts/Dashboard/accountDashboardSlice";
import advertisementSlice from "../component/Modules/KotTokenDisplay/components/advertisementCard/advertisementSlice";
import priceListSlice from "../component/Modules/SalesNew/SalesOrders/priceList/priceListSlice";
import homeDashboardSlice from "../component/Modules/HomeDashboard/homeDashboardNew/homeDashboardSlice";
import posReaderPreviewSlice from "../component/Modules/PointOfSale/posReaderPreview/posReaderPreviewSlice";
import customKeyBoardSlice from "../component/Single Components/customKeyBoardSlice";
import deviceSettingsSlice from "../component/Modules/Settings/Device Settings/deviceSettingsSlice";
import deliveryScreenSlice from "../component/Modules/PointOfSale/DeliveryScreen/deliveryScreenSlice";
import posAggregatorReportSlice from "../component/Modules/PointOfSale/Report/PosAggregatorReport/posAggregatorReportSlice";

export default configureStore({

  reducer: {
    //List of reducers
    searchUserSlice: searchUserSliceReducer,
    loginResponseSlice: loginResponseSliceReducer,
    allReadymadeCategories: allReadymadeCategoriesReducer,
    allReadymadeSubCategorySlice: allReadymadeSubCategorySliceReducer,
    readymadeOccasionSlice: readymadeOccasionSliceReducer,
    productIdSlice: productIdSliceReducer,
    colorListSlice: colorListSliceReducer,
    readymadeBrandSlice: readymadeBrandSliceReducer,
    allFittingsSlice: allFittingsSliceReducer,
    productCareSlice: productCareSliceReducer,
    neckStyleSlice: neckStyleSlicereducer,
    unitsSlice: unitsSliceReducer,
    readymadeStyleSlice: readymadeStyleSliceReducer,
    patternSlice: patternSliceReducer,
    materialSlice: materialSliceReducer,
    allMaterialCategorySlice: allMaterialCategorySliceReducer,
    allAccessoryCategorySlice: allAccessoryCategorySliceReducer,
    allAccessorySubCategorySlice: allAccessorySubCategorySliceReducer,
    sizeChartSlice: sizeChartSliceReducer,
    companyProfileSlice: companyProfileSliceReducer,
    allLocationsSlice: allLocationsSliceReducer,
    allBranchesSlice: allBranchesSliceReducer,
    allDepartmentSlice: allDepartmentSliceReducer,
    allDesignationListSlice: allDesignationListSliceReducer,
    allInactiveEmployeeSlice: allInactiveEmployeeSliceReducer,
    employeeCompanyInfoFormSlice: employeeCompanyInfoFormSliceReducer,
    empQrSlice: empQrSliceReducer,
    allActiveEmployeeSlice: allActiveEmployeeSliceReducer,
    singleEmployeeSlice: singleEmployeeSliceReducer,
    allProductsListSlice: allProductsListSliceReducer,
    productSingleViewSlice: productSingleViewSliceReducer,
    userRoleSlice: userRoleSliceReducer,
    selectShiftSlice: selectShiftReducer,
    shiftReopenDialog: shiftReopenDialogReducer,
    shiftDenominationDialog: shiftDenominationDialogReducer,
    viewRolesAndPermissionSlice: viewRolesAndPermissionSliceReducer,
    stockReportSlice: stockReportReducer,
    allCustomerListSlice: allCustomerListSliceReducer,
    salesReportSlice: salesReportReducer,
    viewShiftSlice: viewShiftReducer,
    stockSummarySlice: stockSummaryReportReducer,
    stockAdjTransNo: stockAdjTransNoReducer,
    stockAdjSingleView: stockAdjSingleViewReducer,
    purchaseOrderIdSlice: purchaseOrderIdSliceReducer,
    purchaseReportSlice: purchaseReportReducer,
    purchaseReportSingleView: purchaseReportSingleViewReducer,
    taxSlice: taxSliceReducer,
    addExpenseType: expenseTypeReducer,
    addExpenseTypeDocNo: addExpenseTypeDocNoReducer,
    staffExpenseSlice: staffExpenseReducer,
    outletExpenseSlice: outletExpenseSlice,
    outletExpenseDocNoSlice: outletExpenseDocNoReducer,
    pettyCashBalanceSlice: pettyCashBalanceReducer,
    pettyCashReceiptSlice: pettyCashReceiptReducer,
    pettyCashReceiptDocNoSlice: pettyCashDocNoReducer,
    cardManualSlice: cardManualReducer,
    upiManualSlice: upiManualReducer,
    allEmployeeDocSlice: allEmployeeDocSliceReducer,
    upiProviderSlice: allUpiProviderReducer,
    shiftStatusSlice: shiftStatusSliceReducer,
    utilityMenuSlice: utilityMenuSliceReducer,

    viewPurchaseOrderSlice: viewPurchaseOrderReducer,
    singleViewPurchaseOrder: purchaseOrderSingleViewReducer,
    allEmployeeLoanSlice: allEmployeeLoanSliceReducer,

    singleEmployeeLoanSlice: singleEmployeeLoanSliceReducer,

    viewPurchaseOrderSlice: viewPurchaseOrderReducer,
    singleViewPurchaseOrder: purchaseOrderSingleViewReducer,
    paymentReportSlice: paymentReportReducer,
    viewPurchaseOrderSlice: viewPurchaseOrderReducer,
    singleViewPurchaseOrder: purchaseOrderSingleViewReducer,

    expenseReportSlice: expenseReportReducer,
    employeeDocViewSlice: employeeDocViewSliceReducer,
    smsHeaderSlice: smsHeaderReducer,

    allLeaveTypeSlice: allLeaveTypeSliceReducer,
    viewCurrencySlice: viewCurrencyReducer,
    discountSlice: getDiscountReducer,
    allCustomerWithFilter:allCustomerListWithFilterReducer,
    docTypeSlice:docTypeSliceReducer,
    logSlice:logReducer,
    shiftTransferDialog:shiftTransferDialogReducer,
    shiftTransferOrderList:shiftTransferOrderListReducer,
    shiftTransferOrderListInvoiceNo:allOrdersInvoiceNoReducer,

    utilityPaymentList:utilityPaymentListReducer,
    paymentInvoiceNoList:paymentInvoiceNoListReducer,


    allLeaveApplicationSlice:allLeaveApplicationSliceReducer,
    expenseGraphSlice:expenseGraphSliceReducer,
    empCountGraphSlice:empCountGraphSliceReducer,
    noticeboardSlice:noticeboardSliceReducer,
    holidayTypeViewSlice:holidayTypeViewSliceReducer,
    jobCompletionWorkorderListSlice:jobCompletionWorkorderListSliceReducer,
    utilityJobCompletionListSlice:utilityJobCompletionListSliceReducer,
    cuttingSlipSlice:cuttingSlipModelReducer,
    stockOrderListSlice:stockOrderListSliceReducer,
    chequeManualSlice:chequeManualReducer,
    staffAllPayrollSlice:staffAllPayrollSliceReducer,
    staffSinglePayslipSlice:staffSinglePayslipSliceReducer,
    generatePayrollVoucherSlice:generatePayrollVoucherSliceReducer,
    journalEntrySlice:journalEntrySliceReducer,
    unitOfMeasurmentSlice:unitOfMeasurmentSliceReducer,
    glConfigurationSlice:glConfigurationSliceReducer,
    vendorBillSlice:vendorBillSliceReducer,
    vendorPaymentSlice:vendorPaymentSliceReducer,
    vendorsListSlice:vendorsListSliceReducer,
    stockMoveSlice:stockMoveSliceReducer,
    goodsReceivedNoteUpdatedSlice:goodsReceivedNoteUpdatedSliceReducer,
    salesQuatationSlice:salesQuatationSliceReducer,
    salesOrderSlice:salesOrderSliceReducer,
    inventoryConfigSettingSlice:inventoryConfigSettingSliceReducer,
    inventoryProductSlice:inventoryProductSliceReducer,
    branchSettingsSlice:branchSettingsSliceReducer,
    customerInvoiceSlice:customerInvoiceSliceReducer,
    customerCreditNoteSlice:customerCreditNoteSliceReducer,
    salesWSOSlice:salesWSOSliceReducer,
    purchaseWPOSlice:purchaseWPOSliceReducer,
    deliveryNoteSlice:deliveryNoteSliceReducer,
    newBillingSlice:newBillingSliceReducer,
    PartnerReport:partnerReportSlice,
    generalReport:GeneralReportSlice,
    salesReport:SalesReportSlice,
    posReports:posReportSlice,
    purchaseRport:purchaseReportNewSlice,
    bankReconciliation:BankReconciliationSlice,
    establishmentId:EstablishmentIdSlice,
    overtime:OvertimeSlice,
    settingReport:SettingsSlice,
    listCategory:filterCategorySlice,
    journalSlice,
    chartOfAccountsSlice,
    viewUpdatedCurrency,
    ConfigurationSlice,
    taxSettingsSlice,
    priceListSlice,
    PurchaseTaxListSlice,
    sequenceSlice,
    wareHouseSlice,
    wareHouseLocationSlice,
    internalTransferSlice,
    branchTransferSlice,
    RfqSlice:RfqSlice,
    posCategorySlice,
    categorySlice,
    attributeSlice,
    allAllowedBranchesSlice,
    BranchReceiptSlice,
    InventoryAdjustmentSlice,
    customerPaymentSlice,
    debitNoteSlice,
    landedCostSlice,
    posSettingSlice,
    cashInOrOutSlice,
    trailbalance,
    ReportSlice,
    Openingbalance,
    generalSlice,
   
    
    branchSettingsSliceNew,
    GenerateQrBarcodeSlice,
    vatReportSlice,
    addSeatSlice,
    kitchenOrderTicketSlice,
    topBarSliceBilling,
    billingFloorPlanSlice,
    billingScreenSlice,
    viewOrdersBillingSlice,
    kotTokenDisplaySlice,
    arrowPathSlice,
    specialItemsSlice,
    mainDashBoardSlice,
    calculatorSlice,
    posReceiptSlice,
    foodManagement,
    aggregatorSlice,
    reservationSlice,
    filterButtonSlice,
    posShiftSummaryReportSlice,    
    posReportshiftSlice,
    accountDashboardSlice,
    advertisementSlice,
    homeDashboardSlice,
    posReaderPreviewSlice,
    customKeyBoardSlice,
    deviceSettingsSlice,
    deliveryScreenSlice,
    posAggregatorReportSlice


  },
});
