// import React, { useState, useEffect, useRef } from "react";
// import { useSelector } from "react-redux";
// import "./carouselStyle.css";

// const Carousel = ({ items = [], duration = 5000 }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const videoRefs = useRef(items.map(() => React.createRef()));

//   useEffect(() => {
//     if (items.length === 0) return;

//     const currentItem = items[currentIndex];
//     const fileExtension = currentItem?.url?.split(".").pop().toLowerCase();

//     if (fileExtension === "mp4" || fileExtension === "webm") {
//       const videoElement = videoRefs.current[currentIndex]?.current;
//       if (videoElement) {
//         videoElement.load();
//         videoElement.play();

//         const handleEnded = () => {
//           // Pause the video when it ends
//           videoElement.pause();
//           // Move to the next item
//           setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
//         };

//         videoElement.addEventListener("ended", handleEnded);

//         return () => {
//           videoElement.removeEventListener("ended", handleEnded);
//         };
//       }
//     } else {
//       const timeout = setTimeout(() => {
//         setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
//       }, duration);

//       return () => clearTimeout(timeout);
//     }
//   }, [currentIndex, items]);

//   // const handlePrevious = () => {
//   //   const prevIndex = (currentIndex - 1 + items.length) % items.length;
//   //   setCurrentIndex(prevIndex);
//   // };

//   // const handleNext = () => {
//   //   setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
//   // };

//   return (
//     <div className="carousel">
//       <div className="carousel-inner">
//         {items.map((item, index) => (
//           <div
//             key={index}
//             className={`carousel-item ${
//               index === currentIndex ? "active-adv" : ""
//             }`}
//             style={{
//               display: index === currentIndex ? "block" : "none",
//             }}
//           >
//             {item.url.endsWith(".mp4") || item.url.endsWith(".webm") ? (
//               <video
//                 ref={videoRefs.current[index]}
//                 autoPlay
//                 playsInline
//                 controls={false}
//               >
//                 <source src={item.url} type="video/mp4" />
//                 Your browser does not support the video tag.
//               </video>
//             ) : (
//               <img src={item.url} alt="" />
//             )}
//           </div>
//         ))}
//       </div>
//       {/* <button className="carousel-control prev" onClick={handlePrevious}>
//         Prev
//       </button>
//       <button className="carousel-control next" onClick={handleNext}>
//         Next
//       </button> */}
//     </div>
//   );
// };

// export default Carousel;

// ------------------------------------------------new------------------------
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import "./carouselStyle.css";

// const Carousel = ({ items = [], duration = 5000 }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [playedVideos, setPlayedVideos] = useState([]);

//   const videoRefs = useRef(items?.map(() => React.createRef()));

//   useEffect(() => {
//     if (items?.length === 0) return;

//     const currentItem = items[currentIndex];
//     const fileExtension = currentItem?.url?.split(".").pop().toLowerCase();

//     if (fileExtension === "mp4" || fileExtension === "webm") {
//       const videoElement = videoRefs.current[currentIndex]?.current;
//       if (videoElement) {
//         videoElement.load();
//         videoElement.play();

//         const handleEnded = () => {
//           // Pause the video when it ends
//           videoElement.pause();
//           // Add the index to the played videos array
//           if (items?.length === 1) {
//             setPlayedVideos((prevPlayedVideos) => [
//               ...prevPlayedVideos,
//               currentIndex,
//             ]);
//             // Find the next unplayed video
//             let nextIndex = currentIndex + 1;
//             while (playedVideos.includes(nextIndex)) {
//               nextIndex = (nextIndex + 1) % items.length;
//             }

//             setCurrentIndex(nextIndex);
//           } else {
//             setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
//           }
//         };

//         videoElement.addEventListener("ended", handleEnded);

//         return () => {
//           videoElement.removeEventListener("ended", handleEnded);
//         };
//       }
//     } else {
//       const timeout = setTimeout(() => {
//         setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
//       }, duration);

//       return () => clearTimeout(timeout);
//     }
//   }, [currentIndex, items, playedVideos]);

//   return (
//     <>
//       <div className="carousel">
//         <div className="carousel-inner">
//           {items.map((item, index) => (
//             <div
//               key={index}
//               className={`carousel-item ${
//                 index === currentIndex ? "active-adv" : ""
//               }`}
//               style={{
//                 display: index === currentIndex ? "block" : "none",
//               }}
//             >
//               {item.url.endsWith(".mp4") || item.url.endsWith(".webm") ? (
//                 <video
//                   ref={videoRefs.current[index]}
//                   autoPlay
//                   playsInline
//                   controls={false}
//                 >
//                   <source src={item.url} type="video/mp4" />
//                   Your browser does not support the video tag.
//                 </video>
//               ) : (
//                 <img src={item.url} alt="" />
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

//-----------------------------------------two------

// const Carousel = ({ items = [], duration = 5000 }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [playedVideos, setPlayedVideos] = useState([]);

//   const videoRefs = useRef(items?.map(() => React.createRef()));
//   const [userInteracted, setUserInteracted] = useState(false);

//   useEffect(() => {
//     const handleInteraction = () => {
//       setUserInteracted(true);
//     };

//     document.addEventListener('click', handleInteraction);

//     return () => {
//       document.removeEventListener('click', handleInteraction);
//     };
//   }, []);

//   useEffect(() => {
//     if (items?.length === 0) return;

//     const currentItem = items[currentIndex];
//     const fileExtension = currentItem?.url?.split(".").pop().toLowerCase();

//     if (fileExtension === "mp4" || fileExtension === "webm") {
//       const videoElement = videoRefs.current[currentIndex]?.current;
//       if (videoElement && userInteracted) {
//         videoElement.load();
//         videoElement.play();

//         const handleEnded = () => {
//           // Pause the video when it ends
//           videoElement.pause();
//           // Add the index to the played videos array
//           if (items?.length === 1) {
//             setPlayedVideos((prevPlayedVideos) => [
//               ...prevPlayedVideos,
//               currentIndex,
//             ]);
//             // Find the next unplayed video
//             let nextIndex = currentIndex + 1;
//             while (playedVideos.includes(nextIndex)) {
//               nextIndex = (nextIndex + 1) % items.length;
//             }

//             setCurrentIndex(nextIndex);
//           } else {
//             setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
//           }
//         };

//         videoElement.addEventListener("ended", handleEnded);

//         return () => {
//           videoElement.removeEventListener("ended", handleEnded);
//         };
//       }
//     } else {
//       const timeout = setTimeout(() => {
//         setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
//       }, duration);

//       return () => clearTimeout(timeout);
//     }
//   }, [currentIndex, items, playedVideos, userInteracted]);

//   return (
//     <>
//       <div className="carousel">
//         <div className="carousel-inner">
//           {items.map((item, index) => (
//             <div
//               key={index}
//               className={`carousel-item ${
//                 index === currentIndex ? "active-adv" : ""
//               }`}
//               style={{
//                 display: index === currentIndex ? "block" : "none",
//               }}
//             >
//               {item.url.endsWith(".mp4") || item.url.endsWith(".webm") ? (
//                 <video
//                   ref={videoRefs.current[index]}
//                   autoPlay
//                   playsInline
//                   controls={false}
//                 >
//                   <source src={item.url} type="video/mp4" />
//                   Your browser does not support the video tag.
//                 </video>
//               ) : (
//                 <img src={item.url} alt="" />
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

//------------------------------------three---
const Carousel = ({ items = [], duration = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [playedVideos, setPlayedVideos] = useState([]);

  const videoRefs = useRef(items?.map(() => React.createRef()));

  useEffect(() => {
    if (items?.length === 0) return;

    const currentItem = items[currentIndex];
    const fileExtension = currentItem?.url?.split(".").pop().toLowerCase();

    if (fileExtension === "mp4" || fileExtension === "webm") {
      const videoElement = videoRefs.current[currentIndex]?.current;
      if (videoElement) {
        videoElement.load();
        videoElement.play().catch(error => {
          // Autoplay failed, possibly due to browser restrictions
          console.error("Autoplay failed:", error);
        });

        const handleEnded = () => {
          // Pause the video when it ends
          videoElement.pause();
          // Add the index to the played videos array
          if (items?.length === 1) {
            setPlayedVideos((prevPlayedVideos) => [
              ...prevPlayedVideos,
              currentIndex,
            ]);
            // Find the next unplayed video
            let nextIndex = currentIndex + 1;
            while (playedVideos.includes(nextIndex)) {
              nextIndex = (nextIndex + 1) % items.length;
            }

            setCurrentIndex(nextIndex);
          } else {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
          }
        };

        videoElement.addEventListener("ended", handleEnded);

        return () => {
          videoElement.removeEventListener("ended", handleEnded);
        };
      }
    } else {
      const timeout = setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
      }, duration);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, items, playedVideos]);

  return (
    <>
      <div className="carousel">
        <div className="carousel-inner">
          {items.map((item, index) => (
            <div
              key={index}
              className={`carousel-item ${
                index === currentIndex ? "active-adv" : ""
              }`}
              style={{
                display: index === currentIndex ? "block" : "none",
              }}
            >
              {item.url.endsWith(".mp4") || item.url.endsWith(".webm") ? (
                <video
                  ref={videoRefs.current[index]}
                  autoPlay
                  muted={true}  // Muted autoplay
                  playsInline
                  controls={false}
                >
                  <source src={item.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={item.url} alt="" />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Carousel;
