import axios from "axios";
import { HEADERS } from "../../../../API/UrlAndPaths";
import { alertMessages, shapeTypes } from "../utils/floorManagementEnum";
import store from "../../../../Redux/store";
import {
  setFloors,
  setSelectedElementDetails,
  setSelectedFloorDetails,
} from "./addSeatSlice";
import { errorSnackMsg } from "../../../Custom Hooks/SnackkBarUtilities";

//add floor api call
export const addFloorAPI = async (
  body,
  setLoading,
  setApiAlertMessage,
  setDialogForm,
  onClose,
  dynamicKey
) => {
  try {
    const response = await axios.post("pos/addFloor", body, HEADERS);
    if (response.status === 200) {
      setApiAlertMessage({
        status: alertMessages.SUCCESS,
        message: "successfully added floor ",
      });
      setLoading(false);
      setDialogForm({});
      onClose(dynamicKey);
      listAllFloorAPI({ branchId: body.branchId });
    }
  } catch (error) {
    setLoading(false);
    setApiAlertMessage({
      status: alertMessages.ERROR,
      message: error?.response?.data || "an error occurred",
    });
    // throw error;
  }
};

//edit floor
export const editFloorAPI = async (
  body,
  setLoading,
  setApiAlertMessage,
  setDialogForm,
  onClose,
  dynamicKey
) => {
  try {
    const response = await axios.post(`pos/editFloor`, body, HEADERS);
    if (response.status === 200) {
      getSingleFloorAPI({ id: body.floorId });
      onClose(dynamicKey);
      store.dispatch(setSelectedElementDetails(null));
      listAllFloorAPI({ branchId: body.branchId });


    }
  } catch (error) {
    errorSnackMsg(error.response.data)
  }
};

//get all floors data
export const listAllFloorAPI = async (body) => {
  try {
    const response = await axios.post("pos/viewFloor", body, HEADERS);
    if (response.status === 200) {
      store.dispatch(setFloors(response.data));
    }
  } catch (error) {
    // throw error;
  }
};

//get single floor details
export const getSingleFloorAPI = async (body, setFloorLoading) => {
  setFloorLoading !== undefined && setFloorLoading(true);
  try {
    const response = await axios.post("pos/viewFloorDesignById", body, HEADERS);
    if (response.status === 200) {
      store.dispatch(setSelectedFloorDetails(response.data));
      setFloorLoading !== undefined && setFloorLoading(false);
    }
  } catch (error) {
    setFloorLoading !== undefined && setFloorLoading(false);
    // throw error;
  }
};

//update
export const updateCanvasElementAPI = async (
  body,
  setLoading,
  setApiAlertMessage,
  setDialogForm,
  onClose,
  dynamicKey
) => {
  let ENDPOINT;
  if (body.type === shapeTypes.TABLE) {
    ENDPOINT = "editTable";
  } else if (body.type === shapeTypes.CHAIR) {
    ENDPOINT = "editChair";
  } else {
    ENDPOINT = "editShape";
  }

  try {
    const response = await axios.post(`pos/${ENDPOINT}`, body, HEADERS);
    if (response.status === 200) {
      getSingleFloorAPI({ id: body.floorId });
      onClose(dynamicKey);
      store.dispatch(setSelectedElementDetails(null));
    }
  } catch (error) {
    errorSnackMsg(error.response.data)
  }
};

// add shapes
export const addShapesAPI = async (body, type, setLoading, setAlertMessage) => {
  let ENDPOINT;
  if (type === shapeTypes.TABLE) {
    ENDPOINT = "addTable";
  } else if (type === shapeTypes.CHAIR) {
    ENDPOINT = "addChair";
  } else {
    ENDPOINT = "addShape";
  }

  try {
    const response = await axios.post(`pos/${ENDPOINT}`, body, HEADERS);
    if (response.status === 200) {
      setAlertMessage({
        status: alertMessages.SUCCESS,
        message: `${type} Successfully added!  `,
      });
      getSingleFloorAPI({ id: body.floorId });
      setLoading(false);
    }
  } catch (error) {
    setLoading(false);
    setAlertMessage({
      status: alertMessages.ERROR,
      message: error?.response?.data || "an error occurred",
    });
    // throw error;
  }
};
// delete shape
export const deleteShapesAPI = async (body, setLoading, setAlertMessage) => {
  let ENDPOINT;
  if (body.type === shapeTypes.TABLE) {
    ENDPOINT = "deleteTable";
  } else if (body.type === shapeTypes.CHAIR) {
    ENDPOINT = "deleteChair";
  } else {
    ENDPOINT = "deleteShape";
  }

  try {
    const response = await axios.delete(
      `pos/${ENDPOINT}`,
      { data: { _id: body._id, branchId: body.branchId } },
      HEADERS
    );
    if (response.status === 200) {
      getSingleFloorAPI({ id: body.floorId });
      setLoading(false);
      setAlertMessage({
        status: alertMessages.SUCCESS,
        message: `${body.type} Successfully Deleted!  `,
      });
      store.dispatch(setSelectedElementDetails(null));
    }
  } catch (error) {
    setLoading(false);
    setAlertMessage({
      status: alertMessages.ERROR,
      message: error?.response?.data || "an error occurred",
    });
    // throw error;
  }
};
