import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { addSeatFormKeyName } from "../../utils/floorManagementEnum";

const MultipleSelectionInput = ({focused, label, options, value, placeHolder,onChange }) => {
 
  return (
    <div>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={options}
        getOptionLabel={(option) => option?.storeCode}
        // defaultValue={[top100Films[13]]}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField focused={focused} {...params} label={label} placeholder={placeHolder} />
        )}
        value={value}
        onChange={(e,v)=>onChange(addSeatFormKeyName.BRANCHES,v)}
        
      />
    </div>
  );
};

export default MultipleSelectionInput;
