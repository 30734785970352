import { Autocomplete,IconButton, Menu, Skeleton, TextField } from "@mui/material";
import React, { useState } from "react";
import "../../../../css/Settings/Utility/utilityPayment.css"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { changePaymentMethodAPICall, paymentInvoiceNoListAPICall, updateUtilityPaymentAPICall, utilityPaymentTypeListAPICall } from "../../../../API/Settings/Utility/PaymentTypeAndAmount/paymentTypeAndAmountAPI";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { extractSingleObjectGeneral } from "../../../../Js/generalFunctions";
import {viewCardManualAPICall} from '../../../../API/Settings/CardAndUpiType/cardManualAPI'
import {viewUpiManualAPICall} from '../../../../API/Settings/CardAndUpiType/UpiManualAPI'
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { getCurrentTime, today } from "../../../../Js/Date";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
export const UtilityPayment=()=>{
  const storeCode=localStorage.getItem('branchId')
  // payment list data
  const paymentList=useSelector((state)=>state.utilityPaymentList.value)
  // all branch list
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  // payments invoice list
  const paymentsInvoiceList = useSelector((state)=>state.paymentInvoiceNoList.value)
   // login response
   const loginResponse = useSelector((state) => state.loginResponseSlice.value);
   // role based rendering
   const userRole = useSelector((state) => state.userRoleSlice.value);
   // upi
   const upiManual=useSelector((state)=>state.upiManualSlice.value)
   // card name
   const cardManual=useSelector((state)=>state.cardManualSlice.value)



  const orderTypes=[
    {
      orderType:'WorkOrder',
      value:0
    },
    {
      orderType:'Alteration',
      value:1
    },
    {
      orderType:'Sales Order',
      value:2
    }
  ]
  const paymetTypeWOCash=[
    {
      paymetType:"card"
    },
    {
      paymetType:"upi"
    }
  ]
  const paymetTypeWOCard=[
    {
      paymetType:"cash"
    },
    {
      paymetType:"upi"
    }
  ]
  const paymetTypeWOUpi=[
    {
      paymetType:"cash"
    },
    {
      paymetType:"card"
    }
  ]
  const currentDate={today}
  const [isRendered, setIsRendered] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!!");
  const closeErrorSnack = () => {
    setErrorSnackOpen(false);
  };
  const closeSuccessSnack = () => {
    setSuccessSnackOpen(false);
  };
   
   /// payment type anchor
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // amount change anchor
  const [anchorE2, setAnchorE2] = useState(null);
  const open2 = Boolean(anchorE2);

  const filterFormInitialState={
    branch:null,
    orderType:null,
    orderId:null,
    fromDate:'',
    toDate:''
  }
  const [filterForm,setFilterForm]=useState(filterFormInitialState)
  const [paymentTypeArray,setPaymentTypeArray]=useState([])
  const [paymentTypeArrayList,setPaymentTypeArrayList]=useState([])
  const [paymentTypeArrayPaymentMethod,setPaymentTypeArrayPaymentMethod]=useState([])
  const [isModify,setIsModify]=useState(false)
  const [paymentTypeSingleRowData,setPaymentTypeSingleRowData]=useState([])
  const [paymentTypeArrayNew,setPaymentTypeArrayNew]=useState([])

  // loading states
  const [isLoading,setIsLoading]=useState(false)
  const [searchKeyword,setSearchKeyword]=useState("")
  const [filteredOrderList,setFiltereOrderList]=useState([])

  const [paymnetTypeItemArray,setPaymnetTypeItemArray]=useState([]) 


  // payment type anchor functions
  const OpenAnchorPaymetType=(event)=>{
    setAnchorEl(event.currentTarget)
  }

  const closeAnchorPaymentType=()=>{
    setAnchorEl(null)
  }

  // amount change anchor functions
  const OpenAnchorAmount=(event)=>{
    setAnchorE2(event.currentTarget)
    setIsEdited(!isEdited)
  }
  const CloseAnchorAmount=()=>{
    setAnchorE2(null)
  }
  const updateListner=()=>{
    setIsModify(!isModify)
    CloseAnchorAmount()
  }
  // onChange function
  const getFromDate=(e)=>{
    setFilterForm({...filterForm,fromDate:e.target.value})
  }
  const getToDate=(e)=>{
    setFilterForm({...filterForm,toDate:e.target.value})
  }

  const submitButtonFunction=()=>{
    utilityPaymentTypeListAPICall({
      branchId:filterForm?.branch,
      type:filterForm?.orderType,
      purchasePk:filterForm?.orderId,
      fromDate:filterForm?.fromDate !== "" ?`${filterForm?.fromDate} 00:00:00`:null,
      endDate:filterForm?.toDate !==""?`${filterForm?.toDate} 23:59:59`:null
    },
    setIsLoading
    )
  }

  // filter payment Type
  const filterPaymentType=(currentObjId)=>()=>{
   let paymentTypeArray= extractSingleObjectGeneral(paymentList,'purchasePk',currentObjId)
   const [resultObj]=paymentTypeArray
   const {paymentMethod}=resultObj
   if(paymentTypeArray !== undefined) setPaymentTypeSingleRowData(paymentTypeArray[0]) 
   setPaymentTypeArray(paymentMethod?.map(obj=>{return{...obj,isSelected:obj?.type,cardName:obj?.vendor,shiftPk:obj?.shiftPk,uuid:obj?.uuid,cardCommission:obj?.cardCommission}}))
   
  }
  
 

  const changePaymentType=(i)=>(e,newValue)=>{
    if(paymentTypeArray !== undefined){
      paymentTypeArray[i].isSelected=newValue?.paymetType
      paymentTypeArray[i].cardName=null
      setPaymentTypeArray([...paymentTypeArray])
    }
    
  }

  const changeCardName=(i)=>(e,newValue)=>{
    paymentTypeArray[i].cardName=paymentTypeArray[i]?.isSelected==="card"?newValue.cardName:paymentTypeArray[i]?.isSelected==="upi"?newValue.upiName:undefined
    setPaymentTypeArray([...paymentTypeArray])
  }
  const bodyToApi={
    _id:paymentTypeSingleRowData?.purchasePk,
    paymentMethod:paymentTypeArrayNew
    
  }
  const changePaymentTypeSubmitBtn=()=>{
     var alertArray=[]
     paymentTypeArray.forEach((item)=>{
       if(item.isSelected==='card'){
         if(item.cardName===null){
          alertArray.push("true")
         }
       }
       else if(item.isSelected==='upi'){
         if(item.cardName===null){
          alertArray.push("true")
         }
       }
       else if(item.isSelected==='cash'){
        alertArray.push("false")
       }
     })
     
     if(alertArray.includes('true')){
      alert('Select vendor')
     }
     else{
      changePaymentMethodAPICall(bodyToApi,updateListner,closeAnchorPaymentType)
      // console.log(bodyToApi);
     }
  }

  const getSearchKeyword=(e)=>{
    setSearchKeyword(e.target.value)
  }
  
  const searchFilter=(chars,listToFilter)=>{
    let searchedArray=[]
    let filteredArray=[]

    if(chars!==""){
      searchedArray=listToFilter.filter((obj)=>
        obj?.orderId?.toLowerCase()?.includes(chars?.toLowerCase())||
        obj?.customerName?.toLowerCase()?.includes(chars?.toLowerCase())||
        obj?.location?.toLowerCase()?.includes(chars?.toLowerCase())
      )
      filteredArray=searchedArray
    }
    else{
      filteredArray=listToFilter
    }
    return filteredArray
  }

  // amount change popup functions
  //filter items from list
  const filterPayentTypeItem=(ObjId)=>()=>{
    let paymentTypeArray=extractSingleObjectGeneral(paymentList,'purchasePk',ObjId)
    const [result]= paymentTypeArray;
    setPaymnetTypeItemArray(result)
    setIsRendered(!isRendered)
  }

  const getDiscount=(e)=>{
    setPaymnetTypeItemArray({...paymnetTypeItemArray,discount:isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value)})
    setIsEdited(!isEdited)
  }
  const getDeliveryDiscount=(e)=>{
    setPaymnetTypeItemArray({...paymnetTypeItemArray,deliveryDiscount:isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value)})
    setIsEdited(!isEdited)
  }
  const getPaidAmount=(e)=>{
    setPaymnetTypeItemArray({...paymnetTypeItemArray,paidAmount:isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value)})
  }
  const getShipmentRate=(e)=>{
    setPaymnetTypeItemArray({...paymnetTypeItemArray,shipmentCharge:isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value)})
    setIsEdited(!isEdited)
    
  }
  const orderListTypeInfoChange=(index,key)=>(e)=>{
    const{value}=e.target;
    if(key==="itemRate"){
      let initialRate=Number(paymentTypeArrayList[index].itemRate)
      let updatedArray=[...paymentTypeArrayList];
      updatedArray[index]={
        ...updatedArray[index],[key]:Number(value)
      }


      let newAmt=(Number(paymnetTypeItemArray.amount)-((initialRate+Number(updatedArray[index].stitchRate))*Number(updatedArray[index].qty)))+(Number(updatedArray[index].qty)*(Number(value)+Number(updatedArray[index].stitchRate)))
      
      setPaymnetTypeItemArray({...paymnetTypeItemArray,amount:newAmt})
      setPaymentTypeArrayList([...updatedArray])
    }
    if(key==="paidAmount"){
      let updatedArray=[...paymentTypeArrayPaymentMethod];
      updatedArray[index]={
        ...updatedArray[index],[key]:Number(value)
      }
      setPaymentTypeArrayPaymentMethod([...updatedArray])
    }
    if(key==="stitchRate"){
      let initialRate=paymentTypeArrayList[index].stitchRate
      let updatedArray=[...paymentTypeArrayList];
      updatedArray[index]={
        ...updatedArray[index],[key]:Number(value)
      }

      let newAmt=(Number(paymnetTypeItemArray.amount)-((Number(initialRate)+Number(updatedArray[index].itemRate))*Number(updatedArray[index].qty)))+(Number(updatedArray[index].qty)*(Number(value)+Number(updatedArray[index].itemRate)))
      setPaymnetTypeItemArray({...paymnetTypeItemArray,amount:newAmt})
      setPaymentTypeArrayList([...updatedArray])
    }
  }

// body to api
  const paymentEditSalesOrderPayloadBody=
  {

      type:paymnetTypeItemArray.orderType,
      orderId:paymnetTypeItemArray.purchasePk,
      editDate:`${currentDate.today} ${getCurrentTime()}`,
      productList:paymentTypeArrayList?
                    paymentTypeArrayList.map(({itemRate,_uuid:uuid})=>  ({itemRate,uuid}))
                :[]
                ,
      paymentMethod:paymentTypeArrayPaymentMethod?
                     paymentTypeArrayPaymentMethod.map(({type,account,date,paidAmount,vendor,shiftId,shiftPk,uuid,cardCommission})=>({type,account,date,paidAmount,vendor,shiftId,shiftPk,uuid,cardCommission}))
                     :[],
      total:paymnetTypeItemArray.totalAmt,
      shipmentCharge:paymnetTypeItemArray.shipmentCharge,
      discount:paymnetTypeItemArray.discount,
      paidAmount:paymnetTypeItemArray.paidAmount,
      balance:paymnetTypeItemArray.balance,
  }
  const paymentEditAlterationPayloadBody=
  {

      type:paymnetTypeItemArray.orderType,
      orderId:paymnetTypeItemArray.purchasePk,
      editDate:`${currentDate.today} ${getCurrentTime()}`,
      productList:paymentTypeArrayList.length===0?null:
                    paymentTypeArrayList.map(({itemRate,qty,productItemId:itemId})=>  ({itemRate,qty,itemId}))
                ,
      paymentMethod:paymentTypeArrayPaymentMethod?
                 paymentTypeArrayPaymentMethod.map(({type,account,date,paidAmount,vendor,shiftId,shiftPk,uuid,cardCommission})=>({type,account,date,paidAmount,vendor,shiftId,shiftPk,uuid,cardCommission}))

                     :[],

      stitchRate:paymentTypeArrayList.length===0?null:paymentTypeArrayList[0].stitchRate,
      total:paymnetTypeItemArray.totalAmt,
      shipmentCharge:paymnetTypeItemArray.shipmentCharge,
      discount:paymnetTypeItemArray.discount,
      paidAmount:paymnetTypeItemArray.paidAmount,
      balance:paymnetTypeItemArray.balance,
  }
  const paymentEditWorkOrderPayloadBody=
  {

      type:paymnetTypeItemArray.orderType,
      orderId:paymnetTypeItemArray.purchasePk,
      editDate:`${currentDate.today} ${getCurrentTime()}`,
      productList:paymentTypeArrayList.length===0?null:
                    paymentTypeArrayList.map(({itemRate,stitchRate,qty,itemId:id,_uuid:uuid})=>  ({itemRate,stitchRate,qty,id,uuid}))
                ,
      paymentMethod:paymentTypeArrayPaymentMethod?
                   paymentTypeArrayPaymentMethod.map(({type,account,date,paidAmount,vendor,shiftId,shiftPk,uuid,cardCommission})=>({type,account,date,paidAmount,vendor,shiftId,shiftPk,uuid,cardCommission}))

                     :[],
      total:paymnetTypeItemArray.totalAmt,
      shipmentCharge:paymnetTypeItemArray.shipmentCharge,
      discount:paymnetTypeItemArray.discount,
      deliveryDiscount:paymnetTypeItemArray.deliveryDiscount,
      paidAmount:paymnetTypeItemArray.paidAmount,
      balance:paymnetTypeItemArray.balance,
  }

  const updatePaymentBodyOrderType=paymnetTypeItemArray?.orderType===0?
                                      paymentEditWorkOrderPayloadBody:
                                   paymnetTypeItemArray?.orderType===1?
                                      paymentEditAlterationPayloadBody:
                                   paymnetTypeItemArray?.orderType===2?
                                      paymentEditSalesOrderPayloadBody
                                      :null

  const updatePaymentClick=()=>{
    updateUtilityPaymentAPICall(updatePaymentBodyOrderType,updateListner,setSuccessSnackOpen,
                                        setErrorSnackOpen,setSnackMsg
                                )

  }
  // payment list api call
  useEffect(()=>{
    if(filterForm?.branch===null){
      utilityPaymentTypeListAPICall({branchId:storeCode},setIsLoading,setErrorSnackOpen,setSnackMsg)      
    }
    else{

      utilityPaymentTypeListAPICall({
        branchId:filterForm?.branch,
        type:filterForm?.orderType,
        purchasePk:filterForm?.orderId,
        fromeDate:filterForm?.fromDate,
        toDate:filterForm?.toDate
      },
      setIsLoading,setErrorSnackOpen,setSnackMsg
      )
    }
  },[isModify])

  useEffect(()=>{
    viewCardManualAPICall()
    viewUpiManualAPICall()
    viewAllBranchesAPICall();
    paymentInvoiceNoListAPICall({type:filterForm?.orderType,branchId:filterForm?.branch})
  },[filterForm?.orderType])

  useEffect(()=>{
    let paymentNewArray=paymentTypeArray?.map((obj)=>{
      return {
        type:obj?.isSelected,
        account:obj?.account,
        date:obj?.date,
        paidAmount:obj?.paidAmount,
        vendor:obj?.cardName,
        shiftId:obj?.shiftId,
        uuid:obj?.uuid,
        shiftPk:obj?.shiftPk,
        cardCommission:obj?.cardCommission

      }
    })
    setPaymentTypeArrayNew(paymentNewArray)
  },[paymentTypeArray])

  useEffect(()=>{
    let filteredArray=[]
    if(paymentList!==undefined){
      setFiltereOrderList(paymentList)
      filteredArray=searchFilter(searchKeyword,paymentList)
      setFiltereOrderList(filteredArray)
    }
    else{
      setFiltereOrderList([])
    }
  },[searchKeyword,paymentList])

  useEffect(()=>{
    if(paymnetTypeItemArray.length!==0){
      setPaymentTypeArrayList(paymnetTypeItemArray.list)
      setPaymentTypeArrayPaymentMethod(paymnetTypeItemArray.paymentMethod)
    }
  },[isRendered])

  useEffect(()=>{
    if(paymnetTypeItemArray.length!==0){
      setPaymnetTypeItemArray(prevData=>({...prevData,paymentMethod:paymentTypeArrayPaymentMethod,list:paymentTypeArrayList}))
    }
    let paymentMethodTotal=[]
    paymentMethodTotal=paymentTypeArrayPaymentMethod.map((obj)=> 
                      obj?.paidAmount
                      )
    let sum,sumAmount=0
    sum=paymentMethodTotal.reduce((a,b)=>a+b,0)

    paymentTypeArrayList.forEach((product)=>{
      const calcAmount=Number(product.qty)*(Number(product.itemRate)+Number(product.stitchRate))
      sumAmount+=calcAmount    
    })
    let total=
    paymnetTypeItemArray?.orderType===0?
    sumAmount-paymnetTypeItemArray.discount-paymnetTypeItemArray.deliveryDiscount+paymnetTypeItemArray.shipmentCharge
    :sumAmount-paymnetTypeItemArray.discount+paymnetTypeItemArray.shipmentCharge
    setPaymnetTypeItemArray({...paymnetTypeItemArray,paidAmount:sum,balance:total-sum,grandTotal:total,totalAmt:sumAmount})
  },[paymentTypeArrayPaymentMethod,paymentTypeArrayList,isEdited])

    return(
        <>
             {/* top container */}
             <div id="utility-payment-cash-to-card">
             <div className="utility-corrections-container" >
                <div style={{display:"flex",flexDirection:"row",width:"100%",margin:"1% 0 2% 0"}}>
                <div className="global-single-input auto-complete">
                  <p>Branch</p>
                  <Autocomplete
                    options={allBranchesList||[""]}
                    getOptionLabel={(option) =>
                      `${option?.storeCode}-${option?.branchName}`
                    }
                    renderInput={(params) => (
                        <TextField {...params} placeholder="Select Branch" />
                      )}
                    onChange={(e, newValue) => setFilterForm({...filterForm,branch:newValue?.storeCode})}
                  />
                </div>
                <div className="global-single-input auto-complete">
                  <p>Order Type</p>
                  <Autocomplete
                    options={orderTypes||[""]}
                    getOptionLabel={(option) =>
                      option?.orderType
                    }
                    renderInput={(params) => (
                        <TextField {...params} placeholder="Select OrderType" />
                      )}
                      onChange={(e, newValue) => setFilterForm({...filterForm,orderType:newValue?.value})}
                  />
                </div>
                <div className="global-single-input auto-complete">
                  <p>Order ID</p>
                  <Autocomplete
                    options={paymentsInvoiceList||[""]}
                    getOptionLabel={(option) =>
                      option?.invoiceNo
                    }
                    renderInput={(params) => (
                        <TextField {...params} placeholder="Select OrderId" />
                      )}
                      onChange={(e, newValue) => setFilterForm({...filterForm,orderId:newValue?.purchasePk})}
                    
                  />
                </div>
                <div className="global-single-input">
                  <p>From</p>
                  <input type="date" onChange={getFromDate} />
                </div>
                <div className="global-single-input">
                  <p>To</p>
                  <input type="date" onChange={getToDate}/>
                </div>
                </div>
                
                <button onClick={submitButtonFunction} className="btn btn-primary">Submit</button>
             </div>
             {/* bottom container */}
                <div className="utility-corrections-container">
                <div className="utility-corrections-container-top">
                    <p>Order Listed</p>
                    <div className="view-order-search-container view-order-admin-search-container" style={{width:"25%"}}>
                      <input
                          placeholder="Customer name,Order ID,Location"
                          value={searchKeyword}
                          onChange={getSearchKeyword}
                          />
                      <IconButton>
                          <i class="bi bi-search search-icon-order"></i>
                      </IconButton>
                  </div>
                </div>
                <div className="global-table-container" style={{width:'98%'}}>
                    <table className="global-table">
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Date</th>
                                <th>Customer Name</th>
                                <th>Location</th>
                                <th>Amount</th>
                                <th>Type</th>
                                <th>&nbsp;</th>
                              

                            </tr>
                        </thead>
                        {
                        isLoading?
                          <tbody>
                        <tr>
                            <td colSpan={10}>
                              <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={30}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={10}>
                              <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={30}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={10}>
                              <Skeleton
                                variant="rectangular"
                                animation="wave"
                                width={"100%"}
                                height={30}
                              />
                            </td>
                          </tr>
                        </tbody>:
                        <tbody>
                          {
                            filteredOrderList?.map((r,i)=>(
                              <tr>
                                <td>{r?.orderId}</td>
                                <td>{r?.date}</td>
                                <td>{r?.customerName}</td>
                                <td>{r?.location}</td>
                                <td>{r?.amount}</td>
                                <td style={{width:"10%"}}>
                                    <div className="utility-payment-type">
                                     <button
                                        onClick={OpenAnchorPaymetType}
                                        onMouseDown={filterPaymentType(r?.purchasePk)}
                                     >
                                     <span style={{margin:"0 0 0 8%"}}><KeyboardArrowDownIcon/></span>
                                     </button>
                                    </div>
                                    
                                   
                                </td>
                                <td style={{width:"10%"}}>
                                    <div className="utility-payment-type">
                                     <button
                                      onClick={OpenAnchorAmount}
                                      onMouseDown={filterPayentTypeItem(r?.purchasePk)}
                                     >
                                     <span style={{margin:"0 0 0 8%"}}><KeyboardArrowDownIcon/></span>
                                     </button>
                                    </div>
                                    
                                   
                                </td>
                              
                            </tr>
                           
                            ))
                          }
                            
                          
                        </tbody>}
                    </table>
                </div>
                </div>
                </div>

                <Menu
                             id="basic-menu"
                             anchorEl={anchorEl}
                             open={open}
                             onClose={closeAnchorPaymentType}
                             MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  overflow: "visible",
                                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                  mt: 1.5,
                                  "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                              >
                              
                              <p className="utility-payment-type-heading">Select Payment</p>
                              {
                                paymentTypeArray?.map((r,i)=>(
                               <div className="utility-payment-type-anchor-container">
                                <div className="global-single-input input-payment-type-utility">
                                  <input type="text" value={r?.type} />
                               </div>
                               <div className="global-single-input auto-complete input-payment-type-utility">
                                   <Autocomplete
                                      options={
                                        r?.type==="cash" ?
                                          paymetTypeWOCash
                                        :r?.type==="card"?
                                          paymetTypeWOCard
                                        :r?.type==="upi"||r?.type==="Upi"?
                                          paymetTypeWOUpi
                                        :undefined
                                        ||[""]}
                                      getOptionLabel={(option)=>option?.paymetType}
                                      renderInput={(params) => (
                                     <TextField {...params} placeholder="Select Paymnet Type" />
                                      )}
                                     onChange={changePaymentType(i)}
                                   />
                               </div>
                              
                               <div className="global-single-input auto-complete input-payment-type-utility">
                                   <Autocomplete
                                    
                                      options={r?.isSelected === "upi"? upiManual : r?.isSelected === "card" ? cardManual:[""]||[""]}
                                      getOptionLabel={(option)=>r?.isSelected === "upi"?option?.upiName:r?.isSelected === "card"?option?.cardName:undefined}
                                      renderInput={(params) => (
                                     <TextField {...params} placeholder="Select" />
                                      )}
                                      disabled={r?.isSelected === "cash"}
                                      onChange={changeCardName(i)}
                                      
                                   />
                               </div>
                                 
                               </div>
                                ))
                              }
                              <div className="utility-payment-type-btn">
                              <button
                             
                              onClick={changePaymentTypeSubmitBtn} className="btn btn-primary">Submit</button>
                              </div>
                              
                </Menu>

                <Menu
                             id="basic-menu"
                             anchorEl={anchorE2}
                             open={open2}
                             onClose={CloseAnchorAmount}
                             MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  overflow: "visible",
                                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                  mt: 1.5,
                                  "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                              >
                              
                              <p className="utility-payment-type-heading">Payment</p>
                              <hr className="global-hr" />
                              <div className="utility-amount-change-container">
                                <div className="utility-amount-change-left-container">
                                  <div className="global-table-container">
                                  <table className="global-table">
                                    <thead>
                                      <tr>
                                        <th>Product</th>
                                        <th>Qty</th>
                                        {
                                          paymnetTypeItemArray?.length!==0 &&
                                          paymnetTypeItemArray?.orderType!==2 &&
                                          <th>Stitch rate</th>
                                        }
                                        <th>Rate</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                       paymentTypeArrayList.map((r,i)=>(
                                       <tr>
                                        <td style={{backgroundColor:"#fff"}}>{r?.productName}</td>
                                        <td style={{backgroundColor:"#fff"}}>
                                           {r?.qty}
                                        </td>
                                        {
                                           paymnetTypeItemArray?.length!==0 &&
                                           paymnetTypeItemArray?.orderType!==2 &&
                                          <td style={{backgroundColor:"#fff",width:"25%"}}>
                                          <input type="number" className="utility-amount-change-left-container-input" 
                                           value={r?.stitchRate}
                                            onChange={orderListTypeInfoChange(i,"stitchRate")} 
                                           />
                                        </td>
                                        }
                                        <td style={{backgroundColor:"#fff",width:paymnetTypeItemArray?.orderType!==2?"25%":"40%"}}>
                                          <input type="number" className="utility-amount-change-left-container-input" 
                                           value={r?.itemRate}
                                           onChange={orderListTypeInfoChange(i,"itemRate")}
                                          />
                                        </td>
                                      </tr>
                                        ))
                                      }
                                      
                                    </tbody>
                                  </table>
                                  </div>
                                  
                                </div>
                                <div className="utility-amount-change-middle-container">
                                  <div className="global-table-container">
                                    <table className="global-table">
                                      <thead>
                                        <tr>
                                          <th>Pay Mode</th>
                                          <th>Amount</th>
                                          <th>Type</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                            paymentTypeArrayPaymentMethod.map((r,i)=>(
                                          <tr>
                                            <td style={{backgroundColor:"#fff"}}>{r?.type}</td>
                                            <td style={{backgroundColor:"#fff",width:"40%"}}>
                                              <input type="number" 
                                              className="utility-amount-change-left-container-input" 
                                              value={r?.paidAmount}
                                              onChange={orderListTypeInfoChange(i,"paidAmount")}
                                              /></td>
                                            <td style={{backgroundColor:"#fff"}}>{r?.vendor===null?"":r?.vendor}</td>
                                          </tr>
                                          ))
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="utility-amount-change-right-container">
                                  <div className="utility-amount-change-single-container">
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                  </div>
                                  <div className="utility-amount-change-single-container">
                                    <p>Amount</p>
                                    <input type="text" value={paymnetTypeItemArray?.totalAmt} disabled />
                                  </div>
                                  <div className="utility-amount-change-single-container">
                                    <p>Discount</p>
                                    <input type="text" value={paymnetTypeItemArray?.discount} onChange={getDiscount}/>
                                  </div>
                                  {
                                    paymnetTypeItemArray.length!==0 && paymnetTypeItemArray?.orderType===0
                                    &&
                                  <div className="utility-amount-change-single-container">
                                    <p>Delivery Discount</p>
                                    <input type="text" value={paymnetTypeItemArray?.deliveryDiscount} 
                                    onChange={getDeliveryDiscount}/>
                                  </div>
                                  }
                                  <div className="utility-amount-change-single-container">
                                    <p>Shipping Charge</p>
                                    <input type="text" 
                                      value={paymnetTypeItemArray?.shipmentCharge}
                                     onChange={getShipmentRate}/>
                                  </div>
                                  <div className="utility-amount-change-single-container">
                                    <p>Paid Amount</p>
                                    <input disabled type="text" value={paymnetTypeItemArray?.paidAmount} onChange={getPaidAmount}/>
                                  </div>
                                  <div className="utility-amount-change-single-container">
                                    <p>Balance</p>
                                    <input type="text" value={paymnetTypeItemArray?.balance} disabled/>
                                  </div>
                                  <div className="utility-amount-change-single-container utility-amount-change-single-container-grand-total">
                                    <p>Grand Total</p>
                                    <input type="text" 
                                    value={paymnetTypeItemArray?.grandTotal}
                                     disabled/>
                                  </div>
                                </div>
                               

                              </div>
                             
                              <div className="utility-payment-type-btn">
                              <button onClick={CloseAnchorAmount} className="btn btn-secondary-outlined">Cancel</button>
                              <button onClick={updatePaymentClick} className="btn btn-primary">Update</button>
                              </div>
                              
                </Menu>

                <ErrorSnackbar
                open={errorSnackOpen}
                handleClose={closeErrorSnack}
                message={snackMsg}
              />
              <SuccessSnackbar
               open={successSnackOpen}
               handleClose={closeSuccessSnack}
               message={snackMsg}
              />
       
        </>
    )
}