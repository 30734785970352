import React, { useMemo, useCallback } from "react";
import "./printComponentStyle.css";
import PrintHeader from "../header/PrintHeader";
import PrintFooter from "../footer/PrintFooter";

const A4_PAGE_HEIGHT = 1123;
const A4_PAGE_WIDTH = 794.4;
const HEADER_HEIGHT = 40;
const FOOTER_HEIGHT = 40;

const PrintComponent = ({ children, pageHeight, header,subHead }) => {
  const rowsPerPage = useMemo(() => {
    return Math.floor((A4_PAGE_HEIGHT - HEADER_HEIGHT - FOOTER_HEIGHT - 40) / (pageHeight || 34));
  }, [pageHeight]);

  const renderPages = useCallback(() => {
    const pages = [];
    const table = React.Children.toArray(children).find(
      (child) => child.type === "table"
    );
    if (!table) return null;

    const thead = table.props.children.find((child) => child.type === "thead");
    const tbody = table.props.children.find((child) => child.type === "tbody");
    const tfoot = table.props.children.find((child) => child.type === "tfoot");
    const rows = React.Children.toArray(tbody.props.children);
    const totalPages = Math.ceil(rows.length / rowsPerPage);

    for (let i = 0; i < totalPages; i++) {
      const startRow = i * rowsPerPage;
      const endRow = startRow + rowsPerPage;
      const pageRows = rows.slice(startRow, endRow);

      pages.push(
        <div
          key={`page-${i + 1}`}
          style={{
            height: 1210,
            width: A4_PAGE_WIDTH,
            position: "relative",
            pageBreakAfter: i < totalPages - 1 ? "always" : "auto",
            boxSizing: "border-box",
          }}
        >
          <PrintHeader header={header} subHead={subHead} />

          <div
            style={{
              marginTop: `${HEADER_HEIGHT}px`,
              marginBottom: `${FOOTER_HEIGHT}px`,
              height: A4_PAGE_HEIGHT - HEADER_HEIGHT - FOOTER_HEIGHT,
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            <table className="new-print-table">
              <thead>{thead.props.children}</thead>
              <tbody>{pageRows}</tbody>
              {tfoot && i === totalPages - 1 && (
                <tfoot
                  style={{
                    position: "absolute",
                    bottom: -25,
                    left: 0,
                    right: 0,
                    width: "100%",
                    color: "black",
                  }}
                >
                  {tfoot.props.children}
                </tfoot>
              )}
            </table>
          </div>

          <PrintFooter currentPage={i + 1} totalPages={totalPages} />
        </div>
      );
    }

    return pages;
  }, [children, rowsPerPage]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {renderPages()}
    </div>
  );
};

export default PrintComponent;
