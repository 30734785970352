// aggregator list api
import axios from "axios"
import { HEADERS } from "../../../../../API/UrlAndPaths"
import store from "../../../../../Redux/store"
import { setTableLoading } from "../../../Sales/Billing/Slice/viewOrdersBillingSlice"
import { setAggregatorDialog, storeAggregatorList } from "./aggregatorSlice"
import { setSubmitLoading } from "../../../../../Redux/Dashboard/mainDashBoardSlice"
import { setErrorSnack, setSnackMsg, setSuccessSnack } from "../../../Sales/Billing/Slice/billingScreenSlice"

export const listAggregatorsAPI=async(body)=>{
    store.dispatch(setTableLoading(true))
    await axios.post("pos/viewAggregatorList",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(storeAggregatorList(res.data))
                store.dispatch(setTableLoading(false))
            }
        })
        .catch((err)=>{
            store.dispatch(setTableLoading(false))
        })
}

export const addAggregatorsAPI=async(body)=>{
    store.dispatch(setSubmitLoading(true))
    await axios.post("pos/addAggregator",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setSubmitLoading(false))
                store.dispatch(setAggregatorDialog(null))
                store.dispatch(setSnackMsg("New Aggregator added"))
                store.dispatch(setSuccessSnack(true))
        }
        })
        .catch((err)=>{
            store.dispatch(setSubmitLoading(false))
            let message=err.response.status===500?"Internal Server Error !!":err.response.data
                store.dispatch(setSnackMsg(message))            
                store.dispatch(setErrorSnack(true))

        })
}
export const editAggregatorsAPI=async(body)=>{
    store.dispatch(setSubmitLoading(true))
    await axios.post("pos/editAggregator",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setSubmitLoading(false))
                store.dispatch(setAggregatorDialog(null))
                store.dispatch(setSnackMsg("Aggregator Edited"))
                store.dispatch(setSuccessSnack(true))
        }
        })
        .catch((err)=>{
            store.dispatch(setSubmitLoading(false))
            let message=err.response.status===500?"Internal Server Error !!":err.response.data
                store.dispatch(setSnackMsg(message))            
                store.dispatch(setErrorSnack(true))

        })
}