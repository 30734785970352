import React from 'react'
import { useEffect } from 'react';
import { useNavigate } from "react-router";
import { wareHouseListAPICall } from './wareHouseAPI';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import store from '../../../../Redux/store';
import { get_wareHouse_single_view } from './wareHouseSlice';
import { viewInventoryConfigSettingsAPICall } from './inventoryConfigurationSettingsAPI';
export const WareHouse = () => {
    let navigate = useNavigate();
    const wareHouseList=useSelector((state)=>state.wareHouseSlice.value)
    const configurationList = useSelector((state) => state.inventoryConfigSettingSlice.apiResList)

    const createWareHouse=()=>{
        navigate("/userdashboard/inventory/configuration/createwarehouse")
    }
  

    useEffect(()=>{
        viewInventoryConfigSettingsAPICall()
        wareHouseListAPICall()
        store.dispatch(get_wareHouse_single_view({singleWareHouseData:undefined}))
        localStorage.removeItem("singleViewById")
    },[])

    const viewSingleRow=(data)=>{
      // store.dispatch(get_wareHouse_single_view({singleWareHouseData:data}))
      localStorage.setItem("singleViewById",data?._id)
      navigate("/userdashboard/inventory/configuration/createwarehouse")
    }
  return (
    <div className='global-page-parent-container'style={{ margin: "0" }}>
    {/* table */}
    <div className='global-white-bg-container'style={{ backgroundColor: "unset", padding: "0", margin: "0" }}>
      <div className='create-button-blue-container' style={{backgroundColor:"white",justifyContent:"space-between",margin:"0", padding: "15px",paddingInline:"14px"}}>
        <h3 style={{fontSize:"medium"}}>WareHouse</h3>
        {
          configurationList?.multiWarehouse &&
          <button  onClick={createWareHouse}  className='create-button-blue'>Create</button>
        }
      
      </div>
       <div className='global-table-container' style={{marginTop:"4px",height:"85vh",maxHeight:"85vh"}}>
         <table className='global-table'>
            <thead>
              <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Branch</th>
                  <th>Short Code</th>
              </tr>
            </thead>
            <tbody >
                {
                   wareHouseList?.length !== 0 ? wareHouseList?.slice(0)?.reverse()?.map((r,i)=>(
                        <tr onClick={()=>viewSingleRow(r)}>
                        <td>{i+1}</td>
                        <td>{r?.name}</td>
                        <td>{r?.branchId}</td>
                        <td>{r?.shortCode}</td>
                     </tr>
                    ))
                    :
                    <tr>
                      <td colSpan={4}>No Data</td>
                    </tr>
                }
           
              
            </tbody>
         </table>
       </div>
    </div>
   
  </div>
  )
}
