import React from 'react'
import { useSelector } from 'react-redux';

const StockMovePrint = (props) => {
    const {stockMove,symbol} =props

    const decimalPosition = localStorage.getItem("decimalPosition");
    const decimalPositionStock = localStorage.getItem("decimalPositionStock");

    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
    );
  return (
    <div className="print-main-div"><div className='header-div' style={{ display: "flex", justifyContent: "space-between" }}>
    <div>
        <img src={companyProfileData?.companyLogo} alt="" style={{ height: "62px" }} />
    </div>
    <div style={{ textAlignLast: "center" }}>
        <h1 style={{ fontSize: "medium", margin: "0" }}>Stock Move Report</h1>
    </div>
    <div>
        <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1>
        <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyName}</p>
        <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyAddress}</p>
        <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyContact}</p>
    </div>
</div>
<div className="global-report-table-container" style={{overflow:"visible"}}>
          <table>
            <thead>
              <tr >
                <th>Item</th>
                <th>UOM</th>
                <th>Opening</th>
                <th>In</th>
                <th>Out</th>
                <th>Closing</th>
                <th>Cost</th>
                <th>Values</th>
              </tr>
            </thead>
            <tbody>
              {stockMove?.map((item, index) => (
                <tr key={index}>
                  <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.productName}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.uom}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.openingBalance}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalInDate?.toFixed(decimalPositionStock)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalOutDate?.toFixed(decimalPositionStock)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.closingBalance?.toFixed(decimalPosition)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.cost?.toFixed(decimalPosition)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.value?.toFixed(decimalPosition)}</td>
                </tr>
              ))}
            </tbody>
            {/* <tfoot>
              <tr>
                <th colSpan={7}>Total</th>
                <th >{symbol}&nbsp;{(stockMove?.reduce((sum,item)=>sum+item?.value,0)?.toFixed(decimalPosition))}</th>
              </tr>
            </tfoot> */}
          </table>

          <div style={{display:"flex",placeContent:"end",backgroundColor:"#1d1da7"}}>
            <p style={{color:"white",margin:"0",paddingInline:"70px"}}>Total</p>
            <p style={{color:"white",margin:"0",paddingRight:"10px"}}>{symbol}&nbsp;{(stockMove?.reduce((sum,item)=>sum+item?.value,0)?.toFixed(decimalPosition))} </p>
          </div>
        </div>
</div>
  )
}

export default StockMovePrint