import React from "react";
import { useSelector } from "react-redux";

const AccountAgingPrint = (props) => {
  const { data, formValues } = props;
  const decimalPosition = localStorage.getItem("decimalPosition");
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const today = new Date();
  const formattedToday = `${today.getDate().toString().padStart(2, "0")}-${(
    today.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${today.getFullYear()}`;

  return (
    <div className="print-main-div">
      <div className="header-div">
        <div>
          <h3 style={{ fontSize: "1em", margin: "0",textAlign:"center" }}>{formValues?.label}</h3>
          <span style={{ textAlign: "center", display: "block" }}>
            {formattedToday}
          </span>
          {/* <p style={{margin:"0"}}>aaaaaaa</p> */}
        </div>
        <div style={{ maxWidth: "100px", height: "62px" }}>
          <img
            src={loginResponse?.logo}
            alt=""
            style={{ maxWidth: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
        <div>
          {/* <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1> */}
          <p style={{ margin: "0", fontSize: "smaller" }}>
            {loginResponse?.data?.profile?.companyName}
          </p>
          <p style={{ margin: "0", fontSize: "smaller" }}>
            {loginResponse?.data?.profile?.companyAddress}
          </p>
          <p style={{ margin: "0", fontSize: "smaller" }}>
            {loginResponse?.data?.profile?.contactNumber}
          </p>
        </div>
      </div>
      <div className="global-report-table-container">
        <table>
          <thead>
            <tr>
              <th>Vendor</th>
              <th>Current</th>
              <th>1 to 30</th>
              <th>31 to 60</th>
              <th>61 to 90</th>
              <th>91 to 120</th>
              <th>121 to 150</th>
              <th>150 Above</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td
                  style={{
                    backgroundColor: "white",
                    border: "0.8px solid #c3d4f7",
                    textAlignLast: "start",
                    paddingLeft: "10px",
                    width: "23%",
                  }}
                >
                  {item?.customerName}
                </td>
                <td
                  style={{
                    backgroundColor: "white",
                    border: "0.8px solid #c3d4f7",
                    textAlignLast: "end",
                    paddingRight: "10px",
                  }}
                >
                  {item?.categories?.current?.toFixed(decimalPosition)}
                </td>
                <td
                  style={{
                    backgroundColor: "white",
                    border: "0.8px solid #c3d4f7",
                    textAlignLast: "end",
                    paddingRight: "10px",
                  }}
                >
                  {item?.categories?.upto30days?.toFixed(decimalPosition)}
                </td>
                <td
                  style={{
                    backgroundColor: "white",
                    border: "0.8px solid #c3d4f7",
                    textAlignLast: "end",
                    paddingRight: "10px",
                  }}
                >
                  {item?.categories?.upto60days?.toFixed(decimalPosition)}
                </td>
                <td
                  style={{
                    backgroundColor: "white",
                    border: "0.8px solid #c3d4f7",
                    textAlignLast: "end",
                    paddingRight: "10px",
                  }}
                >
                  {item?.categories?.upto90days?.toFixed(decimalPosition)}
                </td>
                <td
                  style={{
                    backgroundColor: "white",
                    border: "0.8px solid #c3d4f7",
                    textAlignLast: "end",
                    paddingRight: "10px",
                  }}
                >
                  {item?.categories?.upto120days?.toFixed(decimalPosition)}
                </td>
                <td
                  style={{
                    backgroundColor: "white",
                    border: "0.8px solid #c3d4f7",
                    textAlignLast: "end",
                    paddingRight: "10px",
                  }}
                >
                  {item?.categories?.upto150days?.toFixed(decimalPosition)}
                </td>
                <td
                  style={{
                    backgroundColor: "white",
                    border: "0.8px solid #c3d4f7",
                    textAlignLast: "end",
                    paddingRight: "10px",
                  }}
                >
                  {item?.categories?.morethan150days?.toFixed(decimalPosition)}
                </td>
                <td
                  style={{
                    backgroundColor: "white",
                    border: "0.8px solid #c3d4f7",
                    textAlignLast: "end",
                    paddingRight: "10px",
                  }}
                >
                  {item?.total?.toFixed(decimalPosition)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="div-table-total" style={{display:"flex",color:"white",backgroundColor:"#1d1da7"}}>
        <p style={{ textAlign: "end", width: "22%",margin:"0" }}>Total</p>
        <p style={{ textAlign: "end", width: "10%",margin:"0" }}>
          {formValues?.sumOfcurrent?.toFixed(decimalPosition)}
        </p>
        <p style={{ textAlign: "end", width: "7%",margin:"0" }}>{formValues?.sumOf31?.toFixed(decimalPosition)}</p>
        <p style={{ textAlign: "end", width: "9%",margin:"0" }}>{formValues?.sumOf61?.toFixed(decimalPosition)}</p>
        <p style={{ textAlign: "end", width: "9%",margin:"0" }}>{formValues?.sumOf91?.toFixed(decimalPosition)}</p>
        <p style={{ textAlign: "end", width: "10%",margin:"0" }}>{formValues?.sumOf121?.toFixed(decimalPosition)}</p>
        <p style={{ textAlign: "end", width: "10%",margin:"0" }}>{formValues?.sumOf151?.toFixed(decimalPosition)}</p>
        <p style={{ textAlign: "end", width: "12%",margin:"0" }}>
          {formValues?.sumOfAbove151?.toFixed(decimalPosition)}
        </p>
        <p style={{ textAlign: "end", width: "10%",margin:"0" }}>
          {formValues?.sumOfTotal?.toFixed(decimalPosition)}
        </p>
      </div>
    </div>
  );
};

export default AccountAgingPrint;
