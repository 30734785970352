import React, { useEffect, useState } from "react";

import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter";
import { useSelector } from "react-redux";
import { filterObjFromList } from "../../../../Js/generalFunctions";
import { companyProfileInfoForUser } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { viewAllCurrencyUpdated } from "../../../../API/Settings/Currency/CurrencyAPI";
import { SalesReportAPIcall } from "./SalesReportApi";
import { useNavigate } from "react-router-dom";
import { setFilterActive } from "../../../Single Components/listSlice/filterCategorySlice";
import store from "../../../../Redux/store";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs";
import TableListActionButtons from "../../../Single Components/ActionButtons/TableListActionButtons";

export const SalesNewReport = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [symbol, setSymbol] = useState(null);
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [filteredData, setFilteredData] = useState({});
  const decimalPosition = localStorage.getItem("decimalPosition");
  const decimalPositionStock = localStorage.getItem("decimalPositionStock");

  const SalesReport = useSelector(
    (state) => state?.salesReport?.salesReportlist
  );
  console.log(SalesReport);
  const currencyListUpdated = useSelector(
    (state) => state?.viewUpdatedCurrency?.value
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  const handleFilterChange = (updatedItems) => {
   setFilteredData(updatedItems);
 };
  const handleClickRow = (row) => {
    localStorage.setItem("singleViewById", row?._id);
    localStorage.setItem("fromDate", FormDataInfo?.fromDate);
    localStorage.setItem("toDate", FormDataInfo?.toDate);
    localStorage.setItem("account", row?.itemName);
    store.dispatch(setFilterActive(window.location.pathname));
    navigate("/userdashboard/sales/Report/salesReportSingleView");
  };
  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.fromDate && filters?.toDate) {
      parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
    }
    console.log(filters?.fromDate);

    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }

    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Sales report");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["sales report"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells("A1:J1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 15, bold: true };
        sheet.mergeCells(`A${index + 2}:J${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "Item",
      "UOM",
      "Opening Qty",
      "Sold Qty",
      "Return Qty",
      "Closing item",
      "Gross Item Sales",
      "Total Item Cost",
      "Item Profit",
      "Gross Profit Per Unit",
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };

    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Item", key: "itemName", width: 20 },
      { header: "UOM", key: "uom", width: 10 },
      { header: "Opening Qty", key: "openingQty", width: 20 },
      { header: "Sold Qty", key: "soldQty", width: 20 },
      { header: "Return Qty", key: "salesRet", width: 20 },
      { header: "Closing item", key: "closingSale", width: 20 },
      { header: "Gross Item Sales", key: "grossItemSale", width: 20 },
      { header: "Total Item Cost", key: "grossItemCost", width: 20 },
      { header: "Item Profit", key: "grossprofit", width: 20 },
      { header: "Sales Report", key: "grossprofitperunit", width: 20 },
    ];

    SalesReport?.forEach((item) => {
      const row = sheet.addRow({
         itemName: item.itemName,
         uom: item.uom,
         openingQty: ` ${item.openingQty?.toFixed(2)}`,
         soldQty: `${item?.soldQty?.toFixed(2)}`,
         salesRet: ` ${item?.salesRet?.toFixed(2)}`,
         closingSale: ` ${item?.closingSale?.toFixed(2)}`,
         grossItemSale: ` ${item?.grossItemSale?.toFixed(2)}`,
         grossItemCost: ` ${item?.grossItemCost?.toFixed(2)}`,
         grossprofit: ` ${item?.grossprofit?.toFixed(2)}`,
         grossprofitperunit: ` ${item?.grossprofitperunit?.toFixed(2)}`,
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFC3D4F7" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if(colNumber=>1){
         cell.alignment={horizontal:"center"}
        }
       
      });
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Sales Report.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    // console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);
  useEffect(() => {
    if (FormDataInfo?.length !== 0) {
      let body = {
        fromDate:
          FormDataInfo.fromDate === ""
            ? ""
            : `${FormDataInfo.fromDate} 00:00:00`,
        endDate:
          FormDataInfo.toDate === "" ? "" : `${FormDataInfo.toDate} 23:59:59`,
        branchId:
          FormDataInfo?.branchList?.length === 0
            ? ""
            : FormDataInfo?.branchList[0],
        search: FormDataInfo?.search,
      };

      let loadData =
        window.location.pathname === filtereDataOptions?.filterActive;
      !loadData && SalesReportAPIcall(body, setIsLoading);
    }
  }, [FormDataInfo]);

  return (
    <div className="global-page-parent-container updated-global-page-parent-container">
        <div className="justify-space-between list-create-head-bar">
          <h3>Sales Report</h3>
          <div className="create-head-buttons-container">
            <TableListActionButtons downloadClick={downloadList}/>
            <CategorySearchandFilter
              statusOptions={["PURCHASE ORDER", "DONE", "CANCEL"]}
              onData={handleDataFromChild}
              onBranchSelect={() => {}}
                onFilterChange={handleFilterChange}
            />
          </div>
        </div>

        <div className="global-report-table-container updated-table-container" style={{height:"calc(100vh - 145px)"}}>
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>UOM</th>
                <th>Opening Qty</th>
                <th>Sold Qty</th>
                <th>Return Qty</th>
                <th>Closing item</th>
                <th>Gross Item Sales</th>
                <th>Total Item Cost</th>
                <th>Item Profit</th>
                <th>Gross Profit Per Unit</th>
              </tr>
            </thead>
            <tbody>
              {
              isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={15}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) :
              SalesReport?.length !== 0 && SalesReport !== undefined ? (
                SalesReport?.map((item, index) => (
                  <tr key={index} onClick={() => handleClickRow(item)}>
                    <td>{item.itemName}</td>
                    <td>{item.uom}</td>
                    <td>{item.openingQty?.toFixed(decimalPositionStock)}</td>
                    <td>{item.soldQty?.toFixed(decimalPositionStock)}</td>
                    <td>{item.salesRet?.toFixed(decimalPositionStock)}</td>
                    <td>{item.closingSale?.toFixed(decimalPosition)}</td>
                    <td>{item.grossItemSale?.toFixed(decimalPosition)}</td>
                    <td>{item.grossItemCost?.toFixed(decimalPosition)}</td>
                    <td>{item.grossprofit?.toFixed(decimalPosition)}</td>
                    <td>{item.grossprofitperunit?.toFixed(decimalPosition)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td style={{ textAlign: "center" }} colSpan={10}>
                    NO DATA
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
    </div>
  );
};
