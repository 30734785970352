import { createSlice } from "@reduxjs/toolkit";

export const kotTokenDisplaySlice = createSlice({
  name: "kotTokenDisplaySlice",
  initialState: {
    kotTokenData: null,
    isLoading: false,
    advertisementExpand: false,
    hideHeader: false,
  },

  reducers: {
    setKotTokenData: (state, action) => {
      state.kotTokenData = action.payload;
    },
    setAdvertisementExpand: (state, action) => {
      state.advertisementExpand = action.payload;
    },
    setHideHeader: (state, action) => {
      state.hideHeader = action.payload;
    },
  },
});

export const { setKotTokenData, setAdvertisementExpand, setHideHeader } =
  kotTokenDisplaySlice.actions;

export default kotTokenDisplaySlice.reducer;
