import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import { addBranchSettingsAPICall, editBranchSettingsAPICall, viewBranchSettingsAPICall } from "./branchSettingsAPI";
import { viewAllBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { vieWareHouseLocationAPICall } from "../../Inventory/Configuration/wareHouseLocationAPI";
import { journalListApiCall } from "../../../../API/AccountUpdated/journalAPI";
import { filterObjFromList } from "../../../../Js/generalFunctions";
export const BranchSettings = () => {
  const tableData = useSelector(
    (state) => state.branchSettingsSlice.apiResList
  );
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const wareHouseLocationList=useSelector((state)=>state.wareHouseLocationSlice.value)
  const journalList = useSelector((state) => state.journalSlice.value)
 
  
  const filterJournalList=journalList?.filter(item => item.type === "bank" || item.type==="cash")
  const [createBtn, setcreateBtn] = useState(false);


  const formValuesInitialState = {
    branch: null,
    location: null,

    type:null,
    journal:null,
  };
  const [formValues, setFormValues] = useState(
    formValuesInitialState
  );
  const [isModify, setIsModify] = useState(false)

  const [editedData,setEditedData]=useState([])

  const [isEdit,setIsEdit]=useState(false)
  const [paymentTypeArray,setPaymentTypeArray]=useState([])
  const [validationAlert,setvalidationAlert]=useState({})

  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
  });
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      message: "",
    });
  };  

  const updateListner = () => {
    setIsModify(!isModify);
    setFormValues(formValuesInitialState)
    setPaymentTypeArray([])
    setcreateBtn(false);
  };

  

  const createBtnFn = () => {
    clearState();
    setcreateBtn(true);
    setEditedData([])
    setPaymentTypeArray([])
    setIsEdit(false)
  };

  const singleRowClickFn = (data) => {
    setIsEdit(true)
    setFormValues({...formValues,
      branch:filterObjFromList("storeCode",allBranchesList,"branchId",data),
    })
    setPaymentTypeArray(data?.paymentTypes)
    setEditedData(data)
    setcreateBtn(true);
  };


  const getFormInfo = (key) => (e,newValue) => {
    const {value}=e.target;
    if(key==="branch"){
      setFormValues({...formValues,[key]:newValue,location:null})
    }
    else{
      setFormValues({...formValues,[key]:newValue})
    }
    setvalidationAlert({})
  };

  const payload={
    branchId:formValues?.branch?.storeCode,
    locationId:formValues?.location?._id,
    paymentTypes:paymentTypeArray
  }

  const submitCurrencyFn =(key)=> () => {
    
    if(formValues?.branch===null){
      setvalidationAlert({...validationAlert,branch:"seelect a branch !"})
    }
    else if(formValues?.location===null){
      setvalidationAlert({...validationAlert,location:"seelect a location !"})
    }
    else if(paymentTypeArray?.length ===0){
      setvalidationAlert({...validationAlert,type:"select this field !"})
    }
    else{
      if(key==="add"){
        addBranchSettingsAPICall(payload,updateListner,setSnackBarStates,snackBarStates)
      }
      else{
        let editPayload={...payload}
        editPayload._id=editedData?._id
        editBranchSettingsAPICall(editPayload,updateListner,setSnackBarStates,snackBarStates)
      }
    }
  };

  const clearState = () => {
    setFormValues(formValuesInitialState);
  };





  const addRow =()=> {

   if(formValues?.type===null){
    setvalidationAlert({...validationAlert,type:"select this field !!"})
   } 
  else if(formValues?.journal===null){
     setvalidationAlert({...validationAlert,journal:"select this field !!"})
   }

   else{
     setPaymentTypeArray((prevTable)=>[
       ...prevTable,
       {
         type:formValues?.type,
         journalId:formValues?.journal?._id,
         journalName:formValues?.journal?.journalName
        }
      ])
      setFormValues({...formValues,type:null,journal:null})
    }
  };


  const handleClickRow = (data, index) => {
    setFormValues({
      ...formValues,
      journal: filterObjFromList(
        "_id",
        filterJournalList,
        "journalId",
        data
      ),
      type: data?.type
    });

    const updatedTable = [
      ...paymentTypeArray.slice(0, index),
      ...paymentTypeArray.slice(index + 1),
    ];
    setPaymentTypeArray(updatedTable);
  };

  const backButtonFunction = () => {
    setcreateBtn(false);
  };

  useEffect(()=>{
    viewAllBranchesAPICall()
    journalListApiCall()
  },[])

  useEffect(()=>{
    vieWareHouseLocationAPICall({branchId:formValues?.branch?._id})
  },[formValues?.branch])

  useEffect(()=>{
    viewBranchSettingsAPICall()
  },[isModify])

  useEffect(()=>{
    setFormValues({...formValues,location:filterObjFromList("_id",wareHouseLocationList,"locationId",editedData)})
  },[wareHouseLocationList])


  return (
    <>
      {createBtn === true ? (
        <>
          <div
            className="global-white-bg-container"
            style={{ margin: "1% 1%", borderRadius: "4px" }}
          >
            <div>

            <Tooltip title="Back">
              <IconButton onClick={backButtonFunction}>
                <i class="bi bi-chevron-left"></i>
              </IconButton>
            </Tooltip>
              <Tooltip title="Save">
             <IconButton
               onClick={submitCurrencyFn(isEdit?"edit":"add")}
               >
               <i class={isEdit?"bi bi-pencil-square edit-icon1":"bi bi-floppy"}></i>
             </IconButton>
           </Tooltip>
          
                 </div>
           <hr className="global-hr"/>
          <div className="global-input-field-container" style={{alignItems:"center"}}>
          <div className='new-global-single-input' style={{width:"30%"}}>
            <Autocomplete
               disablePortal
               id="combo-box-demo"
               options={allBranchesList ||[]}
               value={formValues?.branch}
               onChange={getFormInfo("branch")}
               getOptionLabel={(option)=>`${option?.storeCode}-${option?.branchName}`}
               renderInput={(params) => <TextField {...params} label="Branch*" focused />}
              />
              <p className="doc-validation-alert">{validationAlert?.branch}</p>
          </div>
          <div className='new-global-single-input' style={{width:"30%"}}>
            <Autocomplete
               disablePortal
               id="combo-box-demo"
               options={wareHouseLocationList || []}
               getOptionLabel={(option)=>option?.name}
               value={formValues?.location}
               onChange={getFormInfo("location")}
               renderInput={(params) => <TextField {...params} label="Location" focused />}
            />
              <p className="doc-validation-alert">{validationAlert?.location}</p>
          </div>
          </div>

          
            <>
            <div className="global-input-field-container">
                <div className='new-global-single-input' style={{width:"30%"}}>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={["cash","card","cheque"]}
                    getOptionLabel={(option)=>option}
                    renderInput={(params) => <TextField {...params} label="Type" focused />}
                    value={formValues?.type}
                    onChange={getFormInfo("type")}
                    />
              <p className="doc-validation-alert">{validationAlert?.type}</p>
                </div>
                <div className='new-global-single-input' style={{width:"30%"}}>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={filterJournalList ||[]}
                    getOptionLabel={(option)=>option?.journalName}
                    renderInput={(params) => <TextField {...params} label="Journal" focused />}
                    value={formValues?.journal}
                    onChange={getFormInfo("journal")}
                    />
              <p className="doc-validation-alert">{validationAlert?.journal}</p>
                </div>
                <button style={{margin:"8px"}} onClick={addRow} className="plus-btn">+</button>
            </div>

            <div className="new-global-table-container">
              <table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Journal</th>
                  </tr>
                </thead>
                <tbody>
                  {
                  paymentTypeArray.length!==0?
                  paymentTypeArray?.map((r, i) => (
                    <tr
                      onClick={()=>handleClickRow(r,i)}
                      style={{ cursor: "pointer" }}
                      title="View Details"
                    >
                      <td>{r?.type}</td>
                      <td>{r?.journalName}</td>
                    </tr>
                  ))
                :
                <tr>
                    <td 
                    className="table-no-data"
                    colspan={2}>No Data</td>
                </tr>
                }
                </tbody>
              </table>
            </div>
            </>
          
          </div>
        </>
      ) : (
        <div className="utility-settings-container">
          <div
            className="utility-settings-menu-container"
            style={{ padding: "0px 4px" }}
          >
            <div className="create-button-blue-container" style={{justifyContent:"flex-end",margin:"1%"}}>
              <button onClick={createBtnFn} className="create-button-blue" >
                Create
              </button>
            </div>
            <div className="new-global-table-container">
              <table>
                <thead>
                  <tr>
                    <th>Branch</th>
                    <th>Location</th>
                    <th>Payment Types</th>
                  </tr>
                </thead>
                <tbody>
                  {
                  tableData!==undefined?
                  tableData?.map((r, i) => (
                    <tr
                      key={i}
                      onClick={()=>singleRowClickFn(r)}
                      style={{ cursor: "pointer" }}
                      title="View Details"
                    >
                      <td>{r?.branchId}</td>
                      <td>{r?.locationName}</td>
                      <td className='table-data-box'>
                        { 
                        r?.paymentTypes?.map((r,i)=>(
                            <div key={i}>{r?.type}</div>
                        ))
                        }
                    </td>
                    </tr>
                  ))
                :
                <tr>
                    <td 
                    className="table-no-data"
                    colspan={3}>No Data</td>
                </tr>
                }
                </tbody>
              </table>
            </div>

          </div>
        </div>
      )}


      {/* snackbar */}
      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
    </>
  );
};
