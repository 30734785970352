import React, { useEffect, useState } from 'react'
import "./FoodManagement.css"
import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material'
import { chartOfAccountListApiCall } from '../../../API/AccountUpdated/chartOfAccountAPI'
import { useSelector } from 'react-redux'
import { configurationFoodAPI, getConfigAPI } from './FoodManagementNewAPI'
import { findObjFromList } from '../../../Js/generalFunctions'

export const ConfigurationFood = () => {
  const [editClicked, setIsEditClicked] = useState(false);

  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );

  const config = useSelector(
    (state) => state?.foodManagement.valueConfig);
    console.log(config);

  // const configuration = useSelector((state) => state.FoodManagement?.value)

  const filteredAccounts = chartOfAccountList?.filter(account => account.type === "Cost of revenue");

  // console.log(filteredAccounts);

  const formInitialState = {
    progressAccount: null,
    stageEnabled: false,
    isEdit: false,
    byStageProduct:false
  }
  const [formValues, setFormValues] = useState(formInitialState);
   const handlechange =(key)=>(e,newValue)=>{
    const updated ={...formValues}
    if(key==="stageEnabled"||key==="byStageProduct"){
      updated[key]=e.target.checked;
      setFormValues(updated)
    }
    // else if(key==="byStageProduct"){
    //   updated[key]=e.target.checked;
    //   setFormValues(updated)
    // }
    else{
      updated[key]=newValue!==undefined ? newValue : e.target.value;
      setFormValues(updated)
    }
   
   }
   console.log(formValues?.stageEnabled);
  
  const handleSave = () => {
    let body = {
      workInProgressAccount: formValues?.progressAccount?._id,
      isStageEnabled: formValues?.stageEnabled,
      isByProductEnabled: formValues?.byStageProduct,
    }
    configurationFoodAPI(body)
  }

  const editOnClick =()=>{
    if(formValues.isEdit===true){
      setFormValues({ ...formValues, isEdit:false })
      setIsEditClicked(true);
    }
    else{
      let body = {
        workInProgressAccount: formValues?.progressAccount?._id,
        isStageEnabled:formValues?.stageEnabled,
        isByProductEnabled:formValues?.byStageProduct,
      }
      // console.log(body);
      const draftFn = () => {
        setFormValues({ ...formValues,isEdit: true });
      };
      configurationFoodAPI(body,draftFn)
    }
  }

  useEffect(()=>{
     if(config!==undefined){
      setFormValues({...formValues,
      isEdit:true,
      progressAccount:findObjFromList("_id",filteredAccounts,"workInProgressAccount",config),
      stageEnabled:config?.isStageEnabled,
      byStageProduct:config?.isByProductEnabled,
       })
    }
  },[config,chartOfAccountList])

  useEffect(() => {
    chartOfAccountListApiCall()
    getConfigAPI();
  },[])

  console.log(formValues);
 
  return (
    <div className="global-page-parent-container">
      <div className="global-white-bg-container Header">
        <p>Configuration</p>
      </div>
      <div className="global-white-bg-container ">
        <div className='food_config_secound_header'>
          <div>
            {config!==undefined &&(
              <Tooltip title="Edit">
                <IconButton onClick={editOnClick}>
                <i
                 class={formValues?.isEdit ? 
                  "bi bi-pencil-square edit-icon1"
                  :
                  "bi bi-check2-square edit-icon"
                }
                ></i>
                </IconButton>
              </Tooltip>
           )}
           {config===undefined &&(
              <Tooltip title="Save">
                <IconButton onClick={handleSave}>
                  <i class="bi bi-floppy" style={{ color: "black" }}></i>
                </IconButton>
              </Tooltip>
           )}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ paddingInline: "10px", fontSize: "small", fontWeight: "600" }}>Enable Stage</p>
            <input
              className="toggle-checkbox"
              type="checkbox"
              // value={formValues?.stageEnabled}
              checked={formValues?.stageEnabled}
              onChange={handlechange("stageEnabled")}
              disabled={formValues?.isEdit}

            />
            <p style={{ paddingInline: "10px", fontSize: "small", fontWeight: "600" }}>Enable By Product</p>
            <input
              className="toggle-checkbox"
              type="checkbox"
              // value={formValues?.byStageProduct}
              checked={formValues?.byStageProduct}
              onChange={handlechange("byStageProduct")}
              disabled={formValues?.isEdit}
            />
          </div>
        </div>
        <hr className="global-hr" style={{ width: "94%" }} />

        <div className='food_config_div'>
          <p style={{ paddingRight: "96px" }}>Work in Progress Account</p>
          <div className="new-global-single-input auto-complete-new" style={{ width: "34%" }}>
            <Autocomplete
              disablePortal
              options={filteredAccounts || []}
              getOptionLabel={(option) => option?.accountName}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="" focused />
              )}
              value={formValues?.progressAccount}
              onChange={handlechange("progressAccount")}
              disabled={formValues?.isEdit}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

