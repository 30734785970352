import { createSlice } from "@reduxjs/toolkit";

export const FoodManagement=createSlice({
    name:'FoodManagement',
    initialState:{
        value:undefined,
        rowMaterial:undefined,
        listRecipe:undefined,
        listSinglRecipe:undefined,
        costAnalysis:undefined,
        listOrder:undefined,
        singlelistOrder:undefined,
        OrdercostAnalysis:undefined,
    },
    reducers:{
        get_configuration_data:(state,action)=>{
            state.value=action.payload.configuration
        },
        get_configuration_dataList:(state,action)=>{
            state.valueConfig=action.payload
        },
        get_rowMaterial_data:(state,action)=>{
            state.rowMaterial=action.payload
        },
        list_recipe_data:(state,action)=>{
            state.listRecipe=action.payload
        },
        list_single_view:(state,action)=>{
            state.listSinglRecipe=action.payload
        },
        list_cost_analysis:(state,action)=>{
            state.costAnalysis=action.payload
        },
        list_manufacture_order_data:(state,action)=>{
            state.listOrder=action.payload
        },
        list_single_view_order:(state,action)=>{
            state.singlelistOrder=action.payload
        },
        list_order_cost_analysis:(state,action)=>{
            state.OrdercostAnalysis=action.payload
        },
    }
})
export const {get_configuration_dataList,
    get_configuration_data,
    get_rowMaterial_data,
    list_recipe_data,
    list_single_view,
    list_cost_analysis,
    list_manufacture_order_data,
    list_order_cost_analysis,
    list_single_view_order} =FoodManagement.actions
export default FoodManagement.reducer