import React, { useRef } from "react";
import "../../../../../css/Report/TrailBalanace.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  TrailBalananceListAPI,
  TrailBalananceSingleListAPI,
} from "./TrailBalanceAPI";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentTime, previousDate, today } from "../../../../../Js/Date";
import { IconButton, Tooltip } from "@mui/material";
import ReactToPrint from "react-to-print";
import { LoadingForm } from "../../../../Single Components/LoadingForm";

export const LedgerBalance = () => {
  const ExcelJS = require("exceljs");
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  let componentRef = useRef();
  let navigate = useNavigate();
  const [selectedHeading, setSelectedHeading] = useState("Ledger Balance");
  const [startDate, setStartDate] = useState(previousDate);
  const [endDate, setEndDate] = useState(today);
  const [isLoading, setIsLoading] = useState(false);
  const singleViewID = localStorage.getItem("singleViewById");
  const StartDate = localStorage.getItem("fromDate");
  const EndDate = localStorage.getItem("toDate");
  const account = localStorage.getItem("accName");
  const trailBalanceSingleList = useSelector(
    (state) => state.trailbalance.generalLedgerSingleData
  );
  const [filteredData, setFilteredData] = useState({});

  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };

  //initialise values

  const decimalPosition = localStorage.getItem("decimalPosition");

  const getFromDate = (e) => {
    setStartDate(e.target.value);
  };
  const getToDate = (e) => {
    setEndDate(e.target.value);
  };

  const changeColor = (heading) => {
    setSelectedHeading(heading);
  };
  const handleBackpage = () => {
    navigate("/userdashboard/accounts/report/financialReport/generalLedger");
  };

  // const formatFilterInfo = (filters) => {
  //   const parts = [];
  
  //   if (filters?.fromDate && filters?.toDate) {
  //     parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
  //   }
  //   if (filters?.partner) {
  //     parts.push(`Partner: ${filters?.partner?.name}`);
  //   }
  //   if (filters?.accountType) {
  //     parts.push(`Account Type: ${filters?.accountType?.label}`);
  //   }
  //   if (filters?.branchList) {
  //     parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
  //   }
  //   if (filters?.status) {
  //     parts.push(`Status: ${filters?.status}`);
  //   }
  //   if (filters?.createdBy) {
  //     parts.push(`Employee: ${filters?.createdBy?.staff_name}`);
  //   }
    
  //   return parts; // Return the array directly
  // };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("General Ledger");
    sheet.properties.defaultRowHeight = 20;
  
    // Add header section
    const titleRow = sheet.addRow(["GENERAL LEDGER"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells('A1:H1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Add additional header information
    // const filterParts = formatFilterInfo(filteredData) || [];
  
    // Ensure filterParts is an array and iterate over it
    // if (Array.isArray(filterParts)) {
    //   filterParts.forEach((part, index) => {
    //     const row = sheet.addRow([part]);
    //     row.font = { size: 13, bold: true };
    //     sheet.mergeCells(`A${index + 2}:H${index + 2}`);
    //     row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    //   });
    // }
  
    // Add an empty row between header and table
    sheet.addRow([]);
  
    // Header row styling
    const headerRow = sheet.addRow(["Date","Branch","Particular","Journal","Reference","DEbit","Credit","Balance"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };
  
    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    });
  
    sheet.columns = [
      { header: "Date", key: "date", width: 20 },
    {header:"Branch",key:"branch",width:20},
    {header:"Particular",key:"name",width:20},
    {header:"Journal",key:"journalNo",width:20},
    {header:"Reference",key:"reference",width:20},
    {header:"Debit",key:"debit",width:20},
    {header:"Credit",key:"credit",width:20},
    {header:"General Ledger",key:"balance",width:20}
    ];
  
    trailBalanceSingleList?.journal?.forEach((item) => {
      const row = sheet.addRow({
        date: item?.date,
        branch: ` ${item.branch}`,
        name: ` ${item?.name}`,
        journalNo: ` ${item?.journalNo}`,
        reference: ` ${item?.reference}`,
        debit: ` ${item?.debit}`,
        credit: ` ${item?.credit}`,
        balance: ` ${item?.balance}`,
      });
  
      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };
  
      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFC2CBF9" } },
          left: { style: "thin", color: { argb: "FFC2CBF9" } },
          bottom: { style: "thin", color: { argb: "FFC2CBF9" } },
          right: { style: "thin", color: { argb: "FFC2CBF9" } },
        };
        if (colNumber > 1) { // Right align all columns except the first
          cell.alignment = { horizontal: 'right' };
        }
      });
    });
  
    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      "",
      "",
      "",
      "",
      trailBalanceSingleList?.debitTotal.toFixed(2),
      trailBalanceSingleList?.creditTotal.toFixed(2),
      trailBalanceSingleList?.balanceTotal.toFixed(2),
    ]);
    
    // Merge cells from A to E
    sheet.mergeCells(`A${totalRow.number}:E${totalRow.number}`);
  
    totalRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber > 1) { // Right align all columns except the first
        cell.alignment = { horizontal: 'right' };
      }
    });
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "General Ledger Single View.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  


  useEffect(() => {
    //TrailBalananceListAPI()
    TrailBalananceSingleListAPI({
      startDate: `${StartDate}  00:00:00`,
      endDate: `${EndDate}  23:59:59`,
      id: singleViewID,
    },setIsLoading);
  }, [startDate, EndDate, singleViewID]);

  return (
    <div className="global-page-parent-container" style={{ margin: "0",padding:"3px" }}>
      <div className="global-white-bg-container" style={{height:"92vh",backgroundColor:"unset",padding:"0"}}>
        <div
          className="header-div-path-search"
          style={{ display: "flex", justifyContent: "space-between",backgroundColor:"white" }}
        >
          <div style={{display:"flex"}}>
            <div
              className="sharp-arrow"
              style={{alignSelf:"center",height:"auto"  }}
              onClick={() => handleBackpage()}
            >
              {" "}
              <p style={{fontSize:"medium"}}> General Ledger &gt;</p>
            </div>
            <div
              className="sharp-arrow"
              style={{ width: "auto",alignSelf:"center",height:"auto" }}
            >
              {" "}
              <p style={{fontSize:"medium"}}>{account}</p>
            </div>
          </div>
          <div style={{display:"flex",alignItems:"center"}}>
            <div className="first-div-container action-btns-container">
              <div>
                <Tooltip title="Share">
                  <IconButton>
                    <i
                      class="bi bi-share"
                      style={{
                        backgroundColor: "#9797e9",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                <ReactToPrint
                  trigger={() => (
                    <Tooltip title="Print">
                      <IconButton
                        className="printer-div"
                        style={{ padding: "1px" }}
                      >
                        <i
                          style={{
                            color: "white",
                            backgroundColor: " #d787e7",
                            fontSize: "medium",
                            padding: "8px",
                            borderRadius: "2px",
                          }}
                          class="bi bi-printer"
                        ></i>
                      </IconButton>
                    </Tooltip>
                  )}
                  content={() => componentRef}
                />
              </div>
              <div>
                <Tooltip title="Download">
                  <IconButton onClick={downloadList}>
                    <i
                      class="bi bi-arrow-down-circle"
                      style={{
                        backgroundColor: "rgb(255 118 133 / 69%)",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          <div
            className="view-order-search-container"
            style={{ width: "100%", position: "relative", margin: "7px" }}
          >
            <p className="search-head">Search</p>
            <input value={""} />
            <SearchIcon
              className="billing-search-icon"
              style={{ color: "#736ff3" }}
            />
          </div>
          </div>
        </div>
        <div className="secound-main-container-div" style={{ paddingTop: "0" }}>
          {/* <div className="table-header" style={{ height: "34px" }}>
            <p style={{ fontSize: "14px", marginLeft: "-16px" }}>
              {" "}
              {account} Ledger Balance
            </p>
          </div> */}
        </div>
        <div className="table-container" style={{ maxHeight: "85vh",height:"85vh",marginTop:"4px",backgroundColor:"white" }}>
          <table className="table" style={{marginTop:"7px"}}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Branch</th>
                <th
                  style={{
                    textAlign: "start",
                    paddingLeft: "12px",
                  }}
                >
                  Particular
                </th>
                <th>Journal</th>
                <th>Referance</th>
                <th>Debit</th>
                <th>Credit</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {trailBalanceSingleList?.journal?.map((item, index) => (
                <tr key={index}>
                  <td>{item.date}</td>
                  <td>{item.branch}</td>
                  <td style={{ textAlign: "start", paddingLeft: "12px" }}>
                    {item.name}
                  </td>
                  <td>{item.journalNo}</td>
                  <td>{item.reference}</td>
                  <td>{item.debit?.toFixed(decimalPosition)}</td>
                  <td>{item.credit?.toFixed(decimalPosition)}</td>
                  <td>{item.balance?.toFixed(decimalPosition)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr style={{ textAlign: "end" }}>
                <th colSpan="5" style={{ width: "55%", paddingLeft: "12px" }}>
                  Total
                </th>

                <th>
                  {trailBalanceSingleList?.debitTotal?.toFixed(decimalPosition)}
                </th>
                <th>
                  {trailBalanceSingleList?.creditTotal?.toFixed(
                    decimalPosition
                  )}
                </th>
                <th>
                  {trailBalanceSingleList?.balanceTotal?.toFixed(
                    decimalPosition
                  )}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
        {/* print container */}
        <div style={{ display: "none" }}>
          <div
            ref={(el) => (componentRef = el)}
            className="print-container-trail-balance"
            style={{ "@media print": { "@page": { size: "landscape" } } }}
          >
            <div className="trailbalnce-print-header">
              <div className="company-logo-container">
                <img
                  height={100}
                  width={100}
                  src="http://placehold.it/100x100"
                  alt=""
                />
              </div>
              <div className="trail-balance-print-title">
                <span> General Ledger Report</span>
                <span style={{ fontSize: "13px" }}>
                  {account}- Ledger Balance
                </span>
                <span style={{ fontSize: "13px" }}> As Off { endDate}</span>
              </div>
              <div className="company-details-container">
                <span>{userInfo?.data?.profile?.companyName} </span>
                <span>{userInfo?.data?.profile?.companyAddress} </span>
                <span>{userInfo?.data?.profile?.companyEmail}</span>
              </div>
            </div>
            <div>
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Branch</th>
                      <th
                        style={{
                          textAlign: "start",
                          paddingLeft: "12px",
                        }}
                      >
                        Particular
                      </th>
                      <th>Journal</th>
                      <th>Referance</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trailBalanceSingleList?.journal?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.date}</td>
                        <td>{item.branch}</td>
                        <td style={{ textAlign: "start", paddingLeft: "12px" }}>
                          {item.name}
                        </td>
                        <td>{item.journalNo}</td>
                        <td>{item.reference}</td>
                        <td>{item.debit?.toFixed(decimalPosition)}</td>
                        <td>{item.credit?.toFixed(decimalPosition)}</td>
                        <td>{item.balance?.toFixed(decimalPosition)}</td>
                      </tr>
                    ))}
                    <tr>
                      <th
                        colSpan="5"
                        style={{ width: "55%", paddingLeft: "12px" }}
                      >
                        Total
                      </th>

                      <th>
                        {trailBalanceSingleList?.debitTotal?.toFixed(
                          decimalPosition
                        )}
                      </th>
                      <th>
                        {trailBalanceSingleList?.creditTotal?.toFixed(
                          decimalPosition
                        )}
                      </th>
                      <th>
                        {trailBalanceSingleList?.balanceTotal?.toFixed(
                          decimalPosition
                        )}
                      </th>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingForm loading={isLoading} />
    </div>
  );
};
