import React from 'react';
import { useSelector } from 'react-redux';

const PurchaseReportSinglePrint = (props) => {
    const {singleView,symbol} =props
    const decimalPosition = localStorage.getItem("decimalPosition");

    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
    );

    return (
        <div className="print-main-div">
            <div className='header-div' style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <img src={companyProfileData?.companyLogo} alt="" style={{ height: "62px" }} />
                </div>
                <div style={{ textAlignLast: "center" }}>
                    <h1 style={{ fontSize: "medium", margin: "0" }}>Purchase Report</h1>
                </div>
                <div>
                    <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyName}</p>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyAddress}</p>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyContact}</p>
                </div>
            </div>

            <div className='global-report-table-container' style={{ maxHeight: "80vh" }}>
                    <table>
                    <thead>
                            <tr>
                                <th>Date</th>
                                <th>Vendor</th>
                                <th>Bill No.</th>
                                <th>Qty</th>
                                <th>Unit Price</th>
                                <th>Total</th>
                            </tr>
                            
                        </thead>
                        <tbody>
                       
                            {singleView?.table?.map((item,index)=>(
                            <tr  key={index} style={{ height: "38px" }}>
                                <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.date}</td>
                                <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.vendorName}</td>
                                <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.invoiceNo}</td>
                                <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.qty}</td>
                                <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{symbol}&nbsp;{item.unitPrice}</td>
                                <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{symbol}&nbsp;{item.totalAmount}</td>
                            </tr>
))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan="5">Total</th>
                                <th>{symbol}&nbsp;{singleView?.amountTotal}</th>
                                
                            </tr>
                        </tfoot>
                    </table>

                </div>
        </div>
    );
}

export default PurchaseReportSinglePrint;
