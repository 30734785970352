import { Autocomplete, IconButton, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DeleteIcon from '@mui/icons-material/Delete';
import { setInfoCard } from '../specialItemsSlice';
import { setErrorSnack, setSnackMsg } from '../../Billing/Slice/billingScreenSlice';

function SpecialItemsTable() {

  const dispatch = useDispatch()
  const containerRef = useRef(null);

  const decimalPosition = localStorage.getItem("decimalPosition")

  const { isCombo, isEdit, specialItemsSingleList,productList } = useSelector((state) => state.specialItemsSlice)
  
  const tableDataInitial = {
    product: null,
    qty: "",
    unitPrice: "",
    offerPrice: "",
  }
  const [tableData, setTableData] = useState([tableDataInitial])
  const [addRowScroll, setAddRowScroll] = useState(false);

  const handleInputChange = (id, field) => (e, newValue) => {
    const { value } = e.target;
    const updatedData = tableData.map((row, index) => {
      if (index === id) {
        if (field === "product") {
          let existingProducts=tableData.map((item)=>item?.product?._id)
          let productExist=existingProducts?.includes(newValue?._id)

          if(productExist){
            dispatch(setSnackMsg("Product already added !!"))
            dispatch(setErrorSnack(true))
          }
            return {
              ...row,
              [field]:productExist?null: newValue,
              unitPrice:productExist?"": newValue?.salesPrice || ""
          }
        } 
        else if(field==="offerPrice"){
          if(tableData[id].unitPrice < Number(e.target.value)){
            dispatch(setSnackMsg(`maximum price allowed is ${tableData[id].unitPrice}`))
            dispatch(setErrorSnack(true))
            return { ...row, [field]: tableData[id].unitPrice };
            
            
          }else{
            console.log(Number(e.target.value),tableData[id].unitPrice);
            return { ...row, [field]: e.target.value };
          }
        }
        else {
          return { ...row, [field]: e.target.value };
        }
      }
      return row;
    });

    setTableData(updatedData);
  };

  const addRow = () => {
    setTableData([...tableData, tableDataInitial,]);
    setAddRowScroll(!addRowScroll)
  };
  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [addRowScroll]);

  useEffect(() => {
    dispatch(setInfoCard(tableData))
  }, [tableData])

  useEffect(() => {
    if (specialItemsSingleList !== null) {
      const updatedArray = specialItemsSingleList?.productList?.map((item) => ({
        product: item || null,
        qty: item.qty || "",
        unitPrice: item.unitprice || "",
        offerPrice: item.offerPrice || "",
        _id: item._id || null
      }))

      setTableData(updatedArray)
    } else {
      setTableData([tableDataInitial])
    }
  }, [specialItemsSingleList])
  return (
    <div>
    <div className="global-product-table special-items-table">
      <table style={{ borderBottom: "2px solid #dee3f8" }}>
        <thead>
          <tr>

            <th>Product</th>
            {
              isCombo ?
                <th>Quantity</th> :
                <>
                  <th>Unit Price</th>
                  <th>Offer Price</th>
                </>
            }
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {
            tableData.length !== 0 ?
              tableData.map((r, index) => (
                <tr 
                key={index}
                ref={
                  index === tableData?.length - 1
                    ? containerRef
                    : null
                }
                >
                  <td>
                    <div className="product-table-auto-complete" style={{ minWidth: "190px" }}>
                      <Autocomplete
                        options={productList || []}
                        style={{ maxHeight: "100px" }}
                        getOptionLabel={(option) => option?.productName}
                        renderInput={(params) => (
                          <TextField {...params} placeholder='Select Products' />)}
                        value={r?.product}
                        onChange={handleInputChange(index, "product")}
                        renderOption={(props, option) => (
                          <li {...props} key={option._id}>
                            <span>{option.productName}</span>
                          </li>
                        )}
                        disabled={isEdit}
                      />
                    </div></td>
                  {
                    isCombo ?
                      <td>{
                        <input type="text"
                          value={r?.qty}
                          onChange={handleInputChange(index, "qty")}
                          onFocus={(e) => e.target.focus()}
                        />
                      }
                      </td> :
                      <>
                        <td>
                          <input type="text"
                            value={Number(r?.unitPrice)?.toFixed(decimalPosition)}
                            readOnly
                          />
                        </td>
                        <td>
                          <input type="text"
                            value={r?.offerPrice}
                            onChange={handleInputChange(index, "offerPrice")}
                            onFocus={(e) => e.target.focus()}
                            disabled={isEdit}
                          />
                        </td>
                      </>
                  }
                  <td>
                    <IconButton
                      onClick={() => handleDelete(index)}
                      disabled={isEdit}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </td>
                </tr>
              ))

              :
              <tr>
                <td colSpan={isCombo ? 3 : 4}>No Lines Added!!</td>
              </tr>
          }
        </tbody>
      </table>
    </div>
      {
        !isEdit &&
        <div className="add-row-btn-container">
          <div
            className="global-single-input auto-complete"
            style={{
              width: "76%",
              fontSize: "0.9rem",
              paddingLeft: "7.5px",
              fontWeight: "600",
              color: "rgb(134, 152, 229)", cursor: "pointer"
            }}
            onClick={addRow}
          >
            Add Line
          </div>
        </div>}
    </div>
  )
}

export default SpecialItemsTable