import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RecipeFoodAddAPI, RowMaterialAPI, SingleViewRecipeAPI, editedRecipeFoodAPI, getConfigAPI } from './FoodManagementNewAPI';
import { viewUOMSubListAPICall } from '../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall';
import { chartOfAccountListApiCall } from '../../../API/AccountUpdated/chartOfAccountAPI';
import { viewAllBranchesAPICall } from '../../../API/Settings/Company Settings/companySettingsAPI';
import { filterObjFromList, findObjFromList } from '../../../Js/generalFunctions';
import { setErrorSnack, setSnackMsg } from '../Sales/Billing/Slice/billingScreenSlice';

const RecipeFood = () => {
  let navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [selectedButton, setSelectedButton] = useState("Components");
  const [editClicked, setIsEditClicked] = useState(false);
  
  const singleViewId=localStorage.getItem("recipe_id")
  const allNewProductList = useSelector(
    (state) => state.vendorBillSlice.NewProductList
  );
  const allRowMaterial = useSelector(
    (state) => state.foodManagement.rowMaterial
  );
  const uomSubList = useSelector(
    (state) => state.unitOfMeasurmentSlice.subUomList
  );

  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );

  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );
  // console.log(allActiveEmpList);
  const allBranchesList = useSelector(
    (state) => state.allBranchesSlice.value);

  const singleView = useSelector(
    (state) => state?.foodManagement.listSinglRecipe);

  const config = useSelector(
    (state) => state?.foodManagement.valueConfig);

  // console.log(config);

  // initialstate

  const formInitialState = {
    bom: null,
    product: null,
    qty: "",
    uom: "",
    status: "",
    branch:null,
    isEdit: false,
  }
  const [formValues, setFormValues] = useState(formInitialState);

  const componentInitial = {
    product: null,
    qty: "",
    uom: null,
  }
  const [tableDataComponents, setTableDataComponents] = useState([]);
  // console.log(tableDataComponents);

  const stageInitial = {
    name: "",
    account: null,
    employee: null,
    // qty: "",
    // unitprice: "",
    total: "",
  }
  const [tableDataStages, setTableDataStages] = useState([]);
  const productInitial = {
    product: null,
    qty: "",
    uom: null,
  }
  const [tableDataByProducts, setTableDataByProducts] = useState([]);

  const tableDataInitial = {
    product: null,
    qty: '',
    unitPrice: '',
    offerPrice: '',
  };
// console.log(tableDataStages);
// console.log(tableDataByProducts);
  //form onchange//
  const handleInputChange = (key) => (e, newValue) => {
    const updated = { ...formValues };
    updated[key] = newValue !== undefined ? newValue : e.target.value;
    setFormValues(updated)

  }

  //table onchange//
  const tableInputChange = (id, key, type) => (e, newValue) => {
    const { value } = e.target

    if (type === 1) {
      const updatedData = tableDataComponents.map((row, index) => {
        if (index === id) {
          if(key==="product"){
            return { ...row, product: newValue,uom:newValue?.uomName||"" };
          }else{
            return { ...row, [key]: newValue === undefined ? value : newValue };
          }

        }
        return row;
      });
      setTableDataComponents(updatedData)
    }
    else if (type === 2) {
      const updatedData = tableDataStages.map((row, index) => {
        if (index === id) {

          return { ...row, [key]: newValue === undefined ? value : newValue };
   
        }
        return row;
      });
      setTableDataStages(updatedData)
    }
    else {
      const updatedData = tableDataByProducts.map((row, index) => {
        if (index === id) {
          if(key==="product"){
            return { ...row, [key]: newValue === undefined ? value : newValue ,uom:newValue?.uomName};
  
            }
          return { ...row, [key]: newValue === undefined ? value : newValue };

        }
        return row;
      });
      setTableDataByProducts(updatedData)
    }


  }
  // console.log(formValues?.product);

  //delete Row//
  const deleteRow = (tableType, index) => {
    let updatedTableData;
    if (tableType === 'Components') {
      updatedTableData = [...tableDataComponents];
      updatedTableData.splice(index, 1);
      setTableDataComponents(updatedTableData);
    } else if (tableType === 'Stages') {
      updatedTableData = [...tableDataStages];
      updatedTableData.splice(index, 1);
      setTableDataStages(updatedTableData);
    } else if (tableType === 'By Products') {
      updatedTableData = [...tableDataByProducts];
      updatedTableData.splice(index, 1);
      setTableDataByProducts(updatedTableData);
    }
  };
  //add Row//
  const addRow = (tableType) => {
    let updatedTableData;
    let errorMsg;
    let errorSnack=false;

    if (tableType === 'Components') {

      let lastObj=tableDataComponents.length!==0?tableDataComponents[tableDataComponents.length-1]:"newRow"
      if(lastObj==="newRow"){
        updatedTableData = [...tableDataComponents, tableDataInitial];
      }else{
        if(lastObj?.product===null || lastObj?.qty==="" || lastObj?.uom===null){
          errorMsg="fill all feilds"
          errorSnack=true
          updatedTableData =tableDataComponents;
        }else{
          updatedTableData = [...tableDataComponents, componentInitial];
        }
      }
      setTableDataComponents(updatedTableData);
  
    } else if (tableType === 'Stages') {
      let lastObj=tableDataStages.length!==0?tableDataStages[tableDataStages.length-1]:"newRow"
      if(lastObj==="newRow"){
        updatedTableData = [...tableDataStages, tableDataInitial];
      }else{
        if(lastObj?.name==="" || lastObj?.account===null || lastObj?.employee===null || lastObj?.qty==="" || lastObj?.unitprice==="" || lastObj?.total===""){
          errorMsg="fill all feilds"
          errorSnack=true
          updatedTableData =tableDataStages;
        }else{
          updatedTableData = [...tableDataStages, stageInitial];
        }
      }
      setTableDataStages(updatedTableData);
     
     
    } else if (tableType === 'By Products') {
      let lastObj=tableDataByProducts.length!==0?tableDataByProducts[tableDataByProducts.length-1]:"newRow"
      if(lastObj==="newRow"){
        updatedTableData = [...tableDataByProducts, tableDataInitial];
      }else{
        if(lastObj?.product===null || lastObj?.qty==="" || lastObj?.uom===null){
          errorMsg="fill all feilds"
          errorSnack=true
          updatedTableData =tableDataByProducts;
        }else{
          updatedTableData = [...tableDataByProducts, tableDataInitial];
        }
      }
      setTableDataByProducts(updatedTableData);

      
     
    }
    
    dispatch(setSnackMsg(errorMsg))
    dispatch(setErrorSnack(errorSnack))
  };
 //single view
  useEffect(()=>{
    if(singleView!==undefined){
      setFormValues({...formValues,
        isEdit:true,
        bom:singleView?.bomName,
        product:findObjFromList("_id",allNewProductList,"productId",singleView),
        branch:findObjFromList("_id",allBranchesList,"branchId",singleView),
        qty:singleView?.quantity,
       });

    const tablecomponent = singleView?.components?.map((r,i)=>(
     { product:findObjFromList("_id",allRowMaterial,"productId",r),
     qty:r.quantity,
     uom: r.uomName,
    }
    ))
    setTableDataComponents(tablecomponent)
   
    const tableStagesData = singleView?.stages?.map((r,i)=>(
      { name:r?.name,
        account: findObjFromList("_id",chartOfAccountList,"account",r),
    employee: findObjFromList("_id",allActiveEmpList,"employee",r),
    qty: r.quantity,
    unitprice:r.unitprice,
    total: r.total,
     }
     ))
     setTableDataStages(tableStagesData)
 
     const tableProduct = singleView?.byProducts?.map((r,i)=>(
      { product:findObjFromList("_id",allNewProductList,"productId",r),
      qty:r.quantity,
      uom: r.uomName,
     }
     ))
     setTableDataByProducts(tableProduct)

    }
  },[singleView,allNewProductList,allRowMaterial])
  // console.log(tableDataByProducts);
  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };
  const handleCost = () => {
    navigateTo("/userdashboard/foodManagement/recipe/costAnalysis")
  }
  const backList = () => {
    localStorage.removeItem("recipe_id")
    navigateTo("/userdashboard/foodManagement/recipe")
  
  }

  const editOnClick=()=>{
    if(formValues.isEdit===true){
      setFormValues({ ...formValues, isEdit:false })
      setIsEditClicked(true);
    }
    else {
      let componentArray=tableDataComponents?.map((obj)=>(
        {
          productId:obj?.product?._id,
          quantity:obj?.qty,
          uom: obj?.product?.uomId
        }
      ))
      let stageArray=tableDataStages?.map((obj)=>(
        {
          name:obj?.name,
          account:obj?.account?._id,
          employee:obj?.employee?._id,
          // quantity:obj?.qty,
          // unitprice:obj?.unitprice,
          total:obj?.total,
        }
      ))
      let productArray=tableDataByProducts?.map((obj)=>(
        {
          productId:obj?.product?._id,
          quantity:obj?.qty,
          uom: obj?.product?.uom,
        }
      ))
    
      let body={
        recipeId:singleView?._id,
        bomName:formValues?.bom,
        productId:formValues?.product?._id,
        quantity:formValues?.qty,
        uom:formValues?.product?.uom,
        branchId:formValues?.branch?._id,
        components:componentArray,
        stages:stageArray,
        byProducts:productArray,
      }

      const draftFn = () => {
        setFormValues({ ...formValues,isEdit: true });
      };
      editedRecipeFoodAPI(body,draftFn)
      
    }}
  // console.log(tableDataComponents?. product?._id);

  //save//
const handleSave =()=>{

  let componentArray=tableDataComponents?.map((obj)=>(
    {
      productId:obj?.product?._id,
      quantity:obj?.qty,
      uom: obj?.product?.uomId
    }
  ))
  let stageArray=tableDataStages?.map((obj)=>(
    {
      name:obj?.name,
      account:obj?.account?._id,
      employee:obj?.employee?._id,
      total:obj?.total,
    }
  ))
  let productArray=tableDataByProducts?.map((obj)=>(
    {
      productId:obj?.product?._id,
      quantity:obj?.qty,
      uom: obj?.product?.uom
    }
  ))

  console.log(componentArray,"looo");
  let body={
    bomName:formValues?.bom,
    productId:formValues?.product?._id,
    quantity:formValues?.qty,
    uom:formValues?.product?.uom,
    branchId:formValues?.branch?._id,
    components:componentArray,
    stages:stageArray,
    byProducts:productArray,

  }
  RecipeFoodAddAPI(body)
  
}

  useEffect(() => {
    RowMaterialAPI({ type: 2 })
    viewUOMSubListAPICall()
    chartOfAccountListApiCall();
    viewAllBranchesAPICall();
    getConfigAPI();
    if(singleViewId!==null){
    SingleViewRecipeAPI({recipeId:singleViewId})}
  }, [])


  return (
    <div className="global-page-parent-container">
      <div className="global-white-bg-container Header" style={{display:"flex",alignItems:"center"}}>
      <Tooltip title="Back">
              <IconButton onClick={()=>backList()}>
                <i className="bi bi-chevron-left" style={{ fontSize: '30px', color: 'black', cursor: 'pointer' }}></i>
              </IconButton>
            </Tooltip>
        <p>Recipe</p>
      </div>
      <div className="global-white-bg-container ">
        <div className="food_config_secound_header">
          <div>
           
            {singleView!==undefined &&(
            <Tooltip title="Edit">
              <IconButton onClick={editOnClick}>
                <i
                 class={formValues?.isEdit ? 
                  "bi bi-pencil-square edit-icon1"
                  :
                  "bi bi-check2-square edit-icon"
                }
                ></i>
              </IconButton>
            </Tooltip>
            )}
            {singleView===undefined &&(
            <Tooltip title="Save">
              <IconButton onClick={handleSave}>
                <i className="bi bi-floppy" style={{ color: 'black' }}></i>
              </IconButton>
            </Tooltip>
            )}
          </div>
        </div>
        <hr className="global-hr" />
        <div className="div_data_container">
          <div style={{ width: '50%' }}>
            <p style={{ fontWeight: 700, fontSize: 'larger', margin: '2px' }}>{singleView?.sequenceName}</p>
            <div style={{ display: 'flex' }}>
              <p style={{margin:"15px 3px 0px 15px"}}>BOM</p>
            <div className="new-global-single-input" style={{ width: '79%' }}>
              <TextField
                id="outlined-basic"
                placeholder="Enter BOM Name"
                onChange={handleInputChange("bom")}
                value={formValues?.bom}
                variant="outlined"
                type="text"
                focused
                disabled={formValues?.isEdit}
              />
            </div>
         </div>
            
            <div style={{ display: 'flex' }}>
              <p style={{ paddingLeft: '12px' }}>Product</p>
              <div className="new-global-single-input auto-complete-new" style={{ width: '79%' }}>
                <Autocomplete
                  disablePortal
                  options={allNewProductList?.filter(item=>item.isRawMaterial===false) || []} // Populate options with proper data
                  getOptionLabel={(option) => option?.productName}
                  sx={{ width: 300 }}
                  onChange={handleInputChange("product")}
                  value={formValues?.product}
                  renderInput={(params) => <TextField {...params} label="" focused />}
                  disabled={formValues?.isEdit}
                />
                
              </div>
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <p style={{ color: '#e5611f', margin: '2px', textAlign: 'end', cursor: "pointer" }} onClick={() => handleCost()}>Cost Analysis</p>
            <div style={{ display: 'flex', justifyContent: 'end',marginLeft:"auto" }}>
            <p style={{ paddingLeft: '12px' }}>Branch</p>
              <div className="new-global-single-input auto-complete-new" style={{ width: '79%' }}>
                <Autocomplete
                  disablePortal
                  options={allBranchesList || []} // Populate options with proper data
                  getOptionLabel={(option) => option?.branchName}
                  sx={{ width: 300 }}
                  onChange={handleInputChange("branch")}
                  value={formValues?.branch}
                  renderInput={(params) => <TextField {...params} label="" focused />}
                  disabled={formValues?.isEdit}
               />
              </div>
              </div>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <p>Qty</p>
              <div className="new-global-single-input" style={{ width: '79%' ,borderRadius:"0"}}>
                <div className='justify-center'>
                <TextField
                  id="outlined-basic"
                  placeholder="qty"
                  variant="outlined"
                  type="text"
                  onChange={handleInputChange("qty")}
                  value={formValues?.qty}
                  focused
                  disabled={formValues?.isEdit}
                />
                <div style={{height:"41px",padding:"10px",backgroundColor:"#c3c0c0",paddingInline:"15px"}}>
                  {formValues?.product?.uomName || "UOM"}
                </div>
                </div>
              </div>
              {/* <div className="new-global-single-input">
                <TextField
                  id="outlined-basic"
                  placeholder="UOM"
                  variant="outlined"
                  type="text"
                  value={formValues?.product?.uomName}
                  focused
                  readOnly
                  disabled={formValues?.isEdit}
                />
              </div> */}
            </div>
          </div>
        </div>
        <div className="button-div">
         
          <button
            className={selectedButton === 'Components' ? 'selected-button' : ''}
            onClick={() => handleButtonClick('Components')}
          >
            Components
          </button>
          {config?.isStageEnabled===true && (
          <button
            className={selectedButton === 'Stages' ? 'selected-button' : ''}
            onClick={() => handleButtonClick('Stages')}
          >
            Stages
          </button>
          )}
           {config?.isByProductEnabled===true &&(
          <button
            className={selectedButton === 'By Products' ? 'selected-button' : ''}
            onClick={() => handleButtonClick('By Products')}
          >
            By Products
          </button>
          )}
        </div>
        {selectedButton === "Components" && (
          <div className="global-product-table special-items-table">
            <table style={{ borderBottom: '2px solid #dee3f8' }}>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Quatity</th>
                  <th>Unit</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableDataComponents?.map((r, index) => (
                  <tr key={index}>
                    <td style={{ paddingTop: "0" }}>
                      <div className="product-table-auto-complete">
                        <Autocomplete
                          style={{ maxHeight: "100px" }}
                          options={allRowMaterial || []}
                          getOptionLabel={(option) => option?.name}
                          // disabled={formValues?.isEdit}
                          renderInput={(params) => (
                            <TextField
                              {...params}

                            />
                            
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              <span>{option.name}</span>
                            </li>
                          )}
                          value={r?.product}
                          onChange={tableInputChange(index, "product", 1)}
                          disabled={formValues?.isEdit}
                      />
                      </div>
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <input
                        type="number"

                        onChange={tableInputChange(index, "qty", 1)}
                        value={r.qty}
                        disabled={formValues?.isEdit}
                      
                      />
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <div className="product-table-auto-complete">
                      <input
                        type="text"

                        // onChange={tableInputChange(index, "qty", 1)}
                        value={r.uom}
                        disabled={formValues?.isEdit}

                      />
                        {/* <Autocomplete
                          options={uomSubList?.filter(obj => obj._id === r?.product?.uomId) || []}
                          
                          // options={allRowMaterial || []}style={{ maxHeight: "100px" }}
                          getOptionLabel={(option) => option?.uomName}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              <span>{option.uomName}</span>
                            </li>
                          )}
                          value={r?.uom}
                          onChange={tableInputChange(index,"uom", 1)}
                          disabled={formValues?.isEdit}
                       /> */}
                      </div>
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <IconButton onClick={() => deleteRow('Components', index)} disabled={formValues?.isEdit}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="add-row-btn-container">
              <div
                className="global-single-input auto-complete"
                style={{
                  width: '76%',
                  fontSize: '0.9rem',
                  paddingLeft: '7.5px',
                  fontWeight: '600',
                  color: 'rgb(134, 152, 229)',
                  cursor: 'pointer',
                }}
                onClick={() => addRow('Components')}
              >
                Add Line
              </div>
            </div>
          </div>
        )}

        {selectedButton === "Stages" && (
          <div className="global-product-table special-items-table">
            <table style={{ borderBottom: '2px solid #dee3f8' }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Account</th>
                  <th>Employee</th>
                  {/* <th>Qty</th>
                  <th>Unit Price</th> */}
                  <th>Amount</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableDataStages.map((r, index) => (
                  <tr key={index}>
                    <td >
                      <input
                        type="text"
                        onChange={tableInputChange(index, "name", 2)}

                        value={r.name}
                        disabled={formValues?.isEdit}
                      />
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <div className="product-table-auto-complete" style={{ minWidth: "136px" }}>
                        <Autocomplete
                          options={chartOfAccountList || []}
                          style={{ maxHeight: "100px" }}
                          getOptionLabel={(option) => option?.accountName}
                          disabled={formValues?.isEdit}
                          renderInput={(params) => (
                            <TextField
                              {...params}

                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              <span>{option.accountName}</span>
                            </li>
                          )}
                          value={r?.account}
                          onChange={tableInputChange(index, "account", 2)}
                        />
                      </div>
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <div className="product-table-auto-complete" style={{ minWidth: "136px" }}>
                        <Autocomplete
                          options={allActiveEmpList || []}
                          style={{ maxHeight: "100px" }}
                          getOptionLabel={(option) => option?.staff_name}
                          disabled={formValues?.isEdit}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              <span>{option.staff_name}</span>
                            </li>
                          )}
                          value={r?.employee}
                          onChange={tableInputChange(index, "employee", 2)}
                        />
                      </div>
                    </td>
                    {/* <td style={{ paddingTop: "0" }}>
                      <input
                        type="number"
                        onChange={tableInputChange(index, "qty", 2)}
                        value={r.qty}
                        disabled={formValues?.isEdit}
                      />
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <input
                        type="number"
                        onChange={tableInputChange(index, "unitprice", 2)}
                        value={r.unitprice}
                        disabled={formValues?.isEdit}
                      />
                    </td> */}
                    <td style={{ paddingTop: "0" }}>
                      <input
                        type="number"
                        onChange={tableInputChange(index,"total", 2)}
                        value={r.total}
                        disabled={formValues?.isEdit}
                      />
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <IconButton onClick={() => deleteRow('Stages', index)} disabled={formValues?.isEdit}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="add-row-btn-container">
              <div
                className="global-single-input auto-complete"
                style={{
                  width: '76%',
                  fontSize: '0.9rem',
                  paddingLeft: '7.5px',
                  fontWeight: '600',
                  color: 'rgb(134, 152, 229)',
                  cursor: 'pointer',
                }}
                onClick={() => addRow('Stages')}
              >
                Add Line
              </div>
            </div>
          </div>
        )}

        {selectedButton === "By Products" && (
          <div className="global-product-table special-items-table">
            <table style={{ borderBottom: '2px solid #dee3f8' }}>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Quatity</th>
                  <th>Unit</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableDataByProducts.map((r, index) => (
                  <tr key={index}>
                    <td style={{ paddingTop: "0" }}>
                      <div className="product-table-auto-complete">
                        <Autocomplete
                          options={allNewProductList || []}
                          style={{ maxHeight: "100px" }}
                          getOptionLabel={(option) => option?.productName}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              <span>{option.productName}</span>
                            </li>
                          )}
                          disabled={formValues?.isEdit}
                           value={r?.product}
                          onChange={tableInputChange(index, "product", 3)}
                        />
                      </div>
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <input
                        type="number"

                        onChange={tableInputChange(index, "qty", 3)}
                        value={r.qty}

                        disabled={formValues?.isEdit}
                      />
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <div className="product-table-auto-complete">
                      <input
                        type="text"

                        // onChange={tableInputChange(index, "qty", 1)}
                        value={r.uom}
                        disabled={formValues?.isEdit}

                      />
                        {/* <Autocomplete
                          options={uomSubList?.filter(obj => obj.parentCatId === formValues?.product?.parentUomId) || []}
                          style={{ maxHeight: "100px" }}
                          getOptionLabel={(option) => option?.uomName}
                          disabled={formValues?.isEdit}
                          renderInput={(params) => (
                            <TextField
                              {...params}

                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              <span>{option.uomName}</span>
                            </li>
                          )}
                          value={r?.uom}
                          onChange={tableInputChange(index,"uom", 3)}
                        /> */}
                      </div>
                    </td>
                    <td style={{ paddingTop: "0" }}>
                      <IconButton onClick={() => deleteRow('By Products', index)} disabled={formValues?.isEdit}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="add-row-btn-container">
              <div
                className="global-single-input auto-complete"
                style={{
                  width: '76%',
                  fontSize: '0.9rem',
                  paddingLeft: '7.5px',
                  fontWeight: '600',
                  color: 'rgb(134, 152, 229)',
                  cursor: 'pointer',
                }}
                onClick={() => addRow('By Products')}
              >
                Add Line
              </div>
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default RecipeFood;
