import axios from 'axios'
import { HEADERS } from '../UrlAndPaths';

export const verifyPasswordAPICall= async (
    body,
    setEditPasswordDialog,
    setErrorSnackbar,
    setSnackMsg,
    setEditDialog
    )=>{
    await axios.post("admin/verifypassword",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            // console.log('login success');
            setEditPasswordDialog(false)
            setEditDialog(true)
        }
    })
    .catch((err)=>{
        console.log(err.response);
        setErrorSnackbar(true)
        if (err.response.status===401) {
            setSnackMsg('Invalid Password')
        }
        if (err === undefined) {
            setSnackMsg("Network Error!");
          }
          if (err.response.status === 500) {
            setSnackMsg("Server error!");
          }
    })
}
export const verifyPasswordForShiftAPICall= async (
    body,
    setErrorSnackbar,
    setSnackMsg,
    shiftEndFn
    )=>{
    await axios.post("admin/verifypassword",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            // console.log('login success');
            shiftEndFn()
        }
    })
    .catch((err)=>{
        console.log(err.response);
        setErrorSnackbar(true)
        if (err.response.status===401) {
            setSnackMsg('Invalid Password')
        }
        if (err === undefined) {
            setSnackMsg("Network Error!");
          }
          if (err.response.status === 500) {
            setSnackMsg("Server error!");
          }
    })
}