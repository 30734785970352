import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAlertMessage, setIsLoading } from "../../../kitchenOrderTicket/kitchenOrderTicketSlice";
import { listKotOrderAPI } from "../../../kitchenOrderTicket/kotAPI's";
import KotButton from "../../../kitchenOrderTicket/components/buttons/KotButton";
import StyledBadgeDot from "../../../FloorManagement/components/badge/StyledBadge";
import DarkModeSwitch from "../../../kitchenOrderTicket/components/switch/DarkMode";
import { NETWORK } from "../../../kitchenOrderTicket/utils/kotEnum";
import { switch_to_billing_screen } from "../../../Sales/Billing/newBillingSlice";
import { setHideHeader } from "../../../KotTokenDisplay/kotTokenDisplaySlice";
import { setDeliveryOrderStatus,setDeliveryStatusFilter } from "../deliveryScreenSlice";
import AutoCompleteWithIcon from "../Utilities/AutocompleteWithIcon";
import { listDeliveryOrderAPI } from "../deliveryScreenAPI";

const DeliveryScreenHeader = ({ branchId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deliveryOrderStatus,deliveryStatusFilter } =
    useSelector((state) => state.deliveryScreenSlice);
  const { networkStatus } =
    useSelector((state) => state.kitchenOrderTicketSlice);
  const buttons = [
    { name: "SELF DELIVERY", color: "#465378", key: "self" },
    { name: "AGGREGATOR", color: "#8406cc", key: "aggregator" }
  ];

  const statusFilterChange=(option)=>{
    dispatch(setDeliveryStatusFilter(option))
    let body = {
      branchId,
      status: "PICKUP",
      isSelf:deliveryOrderStatus?.status.key==="self",
      pickUpStatus:option==="Pickup"?"RDYPICKUP":option==="Delivering"?"PICKUP":null
    };
    // api call for list  of orders based on status
    listDeliveryOrderAPI({ body });
  }
  // button handle change
  const handleHeaderBtnChange = (index, data) => {
    dispatch(setAlertMessage(null));
    dispatch(setIsLoading(true));
    let body = {
      branchId,
      status: "PICKUP",
      isSelf:data?.key==="self",
      pickUpStatus:deliveryStatusFilter==="Pickup"?"RDYPICKUP":deliveryStatusFilter==="Delivering"?"PICKUP":null
    };
    dispatch(setDeliveryOrderStatus({ status: data, index: index }));
    // api call for list  of orders based on status
    listDeliveryOrderAPI({ body });
  };
  return (
    <div className="kot-header-container">
      <div className="kot-header">
        <div className="kot-header-left-side">
             <h3 style={{color:"#7869e0",margin:"8px 0"}}>
             READY FOR DELIVERY
             </h3>

        </div>
        <div className="kot-action-button">
          {buttons?.map((btn, index) => (
            <div
              className={`kot-action-item`}
              key={index}
              style={{
                borderBottom:
                  deliveryOrderStatus?.index === index
                    ? `5px solid ${btn.color}`
                    : "",
              }}
            >
              <KotButton
                active={deliveryOrderStatus?.index === index}
                index={index}
                onClick={() => handleHeaderBtnChange(index, btn)}
                name={btn?.name}
                color={btn?.color}
              />
            </div>
          ))}
        </div>
        <div className="kot-action-container">
          <AutoCompleteWithIcon 
            placeHolder="All" 
            options={["Pickup","Delivering"]} 
            style={{marginRight:"10px"}}
            value={deliveryStatusFilter}
            handleChange={statusFilterChange} 
            />
          <StyledBadgeDot
            animate={networkStatus === NETWORK.ONLINE ? false : true}
            isActive={true}
            ActiveColor={
              networkStatus === NETWORK.ONLINE ? "#4bbda6" : "#fa1b49"
            }
          />
          <span className="network-status">
            {networkStatus === NETWORK.ONLINE ? "Online" : "Offline"}
          </span>

          <DarkModeSwitch />
          <div>
            <button
              className="close-icon-btn"
              onClick={() => {
                dispatch(switch_to_billing_screen({ billingBoolean: false }));
                navigate("/userdashboard/dashboardhome");
                dispatch(setHideHeader(false));
              }}
            >
              <HighlightOffIcon color="error" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryScreenHeader;
