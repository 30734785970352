import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import store from "../../../../../Redux/store";
// import { get_branch_transfer_single_view } from './BranchTransferSlice';
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import { VendorPaymentFilterAPICall, viewVendorPaymentListAPICall } from "../API/vendorPaymenAPICall";
import { get_vendor_payment_row_data_list } from "../Slice/vendorPaymentSlice";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { getCurrentTime } from "../../../../../Js/Date";
import axios from "axios";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs"
import TableListActionButtons from "../../../../Single Components/ActionButtons/TableListActionButtons";

export const VendorPayments = () => {
  const tableListData = useSelector(
    (state) => state.vendorPaymentSlice.apiResList
  );
  let navigate = useNavigate();

  const filtereDataOptions = useSelector((state) => state.listCategory);
  const [filteredDatas, setFilteredData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = tableListData?.pages;
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [symbol, setSymbol] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const createVendorPayment = () => {
    navigate("/userdashboard/accounts/vendor/createvendorPayments");
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems)
  }

  const currencyListUpdated = useSelector(
    (state) => state?.viewUpdatedCurrency?.value
  );

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);

  };
  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }
    if (filters?.location) {
      parts.push(`location: ${filters?.location?.name}`);
    }
    if (filters?.toLocation) {
      parts.push(`To Location: ${filters?.toLocation?.name}`);
    }
    if (filters?.status) {
      parts.push(`status: ${filters?.status}`);
    }
    if (filters?.createdBy) {
      parts.push(`createdBy: ${filters?.createdBy}`);
    }
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Vendor Payments");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Vendor Payments"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells("A1:H1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredDatas) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:H${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "Date",
      "Name",
      "Payment Type",
      "Payment Mode",
      "Supplier",
      "Total Amount",
      "Created By",
      "Status",

    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Date", key: "paymentDate", width: 15 },
      { header: "Name", key: "name", width: 20 },
      { header: "Payment Type", key: "PAYMENTTYPE", width: 25 },
      { header: "Payment Mode", key: "paymentMode", width: 25 },
      { header: "Supplier", key: "vendor", width: 25 },
      { header: "Total Amount", key: "totalAmount", width: 15 },
      { header: "Created By", key: "CREATEDBY", width: 15 },
      { header: "Payments", key: "status", width: 20 },
    ];

    tableListData?.list?.forEach((item, index) => {
      const row = sheet.addRow({
        paymentDate: item?.paymentDate,
        name: item.name,
        PAYMENTTYPE: item.PAYMENTTYPE,
        paymentMode: item?.paymentMode,
        vendor: item?.vendor,
        totalAmount: item?.totalAmount,
        CREATEDBY: item?.CREATEDBY,
        status: item?.status,

      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: "thin", color: { argb: "FF000000" } },
          //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
          //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Vendor Payments.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {

    if (FormDataInfo.length !== 0) {
      let loadData = window.location.pathname === filtereDataOptions?.filterActive
      !loadData &&
        VendorPaymentFilterAPICall({
          fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
          toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
          branchList: FormDataInfo.branchList,
          type: [1],
          status: FormDataInfo.status,
          search: FormDataInfo.search,
          index: currentPage - 1,
        }, setIsLoading);
    }

  }, [FormDataInfo, currentPage]);

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);


  useEffect(() => {
    localStorage.removeItem("singleViewById");
    store.dispatch(
      get_vendor_payment_row_data_list({
        vendorPaymentSingleRowData: undefined,
      })
    );
  }, []);

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };

  const handleClickRow = (row) => {
    localStorage.setItem("singleViewById", row._id);
    store.dispatch(setFilterActive(window.location.pathname))
    navigate("/userdashboard/accounts/vendor/createvendorPayments");
  };

  return (
    <div className="global-page-parent-container updated-global-page-parent-container">
      <div className="justify-space-between list-create-head-bar">
        <h3>Payments</h3>
        <div className="create-head-buttons-container">
          <TableListActionButtons downloadClick={downloadList} />
          <CategorySearchandFilter
            statusOptions={['COMPLETED', 'DRAFT', "RETURNED"]}
            onData={handleDataFromChild}
            isStatus={true}
            onBranchSelect={handleBranchSelect}
            onFilterChange={handleFilterChange}
          />
          <button onClick={createVendorPayment} className="create-button-blue btn btn-primary">
            Create
          </button>
        </div>
      </div>
      <div className="justify-space-between pagination-container">
        {FormDataInfo?.branchList?.length > 0 &&
          <h3>Branch name:
            {selectedBranch?.storeCode || filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
            -{selectedBranch?.branchName || filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>}
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          setFormDataInfo={setFormDataInfo}
        />
      </div>

      <div className="new-global-table-container updated-table-container">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Payment Type</th>
              <th>Payment Mode</th>
              <th>Supplier</th>
              <th>Total Amount</th>
              <th>Created By</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              [...Array(10)].map((r, i) => (
                <tr key={i}>
                  <td colSpan={8}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
              ))
            ) : tableListData?.list !== undefined &&
              tableListData?.list?.length !== 0 ? (
              tableListData?.list
                ?.slice(0)

                .map((r, i) => (
                  <tr
                    key={i}
                    onClick={() => handleClickRow(r)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{r?.paymentDate}</td>
                    <td>{r?.name}</td>
                    <td>{r?.PAYMENTTYPE}</td>
                    <td>{r?.paymentMode}</td>
                    <td>{r?.vendor}</td>
                    <td>{symbol}&nbsp;{r?.totalAmount}</td>
                    <td>{r?.CREATEDBY}</td>
                    <td className="table-data-box">
                      {r?.status === "COMPLETED" ? (
                        <span className="post"> Posted</span>
                      ) : r?.status === "DRAFT" ? (
                        <span className="draft"> Drafted</span>
                      ) : r?.status === "RETURNED" ? (
                        <span className='return'>Return</span>
                      ) : r?.status === "Reconciled" ? (
                        <span className='reconciled'>Reconciled</span>
                      ) : (
                        "---"
                      )}
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={7}>No Data</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
