import React from 'react'

const NextCustomerSvg = ({height,width}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width||"657.449" }height={height||"593.744"} viewBox="0 0 657.449 593.744">
  <g id="OBJECTS" transform="translate(-100.51 -12.861)">
    <g id="Group_15504" data-name="Group 15504" transform="translate(100.51 12.861)">
      <path id="Path_2811" data-name="Path 2811" d="M718.295,475.037c0,8.727-138.286,15.817-308.886,15.817s-308.9-7.078-308.9-15.817,138.3-15.817,308.9-15.817S718.295,466.3,718.295,475.037Z" transform="translate(-100.51 102.89)" fill="#c7daea" opacity="0.5"/>
      <g id="Group_15503" data-name="Group 15503" transform="translate(18.386)">
        <g id="Group_15420" data-name="Group 15420">
          <path id="Path_2812" data-name="Path 2812" d="M140.4,457.312l.265,3.615.882,11.409s13.978,1.209,14.671,1.209,3.929-1.776,4.773-2.418a39.192,39.192,0,0,0,2.9-2.859l-.579-6.586-.718-5.642Z" transform="translate(-108.552 102.065)" fill="#ffa383"/>
          <path id="Path_2813" data-name="Path 2813" d="M192.66,455.623s-1.108,7.128-1.788,11.221c-.34,2.557,7.669,7.846,19.608,7.166s24.506-1.6,24.355-7.443l-8.337-3.123s-8.652-1.2-9.357-9.206C216.461,446.2,192.66,455.623,192.66,455.623Z" transform="translate(-95.466 100.757)" fill="#ffa383"/>
          <path id="Path_2814" data-name="Path 2814" d="M168.418,80.523c-10.616-4.8-12.971-42.8-2.808-55.964,13.852-17.945,38.762-13.349,43.61,1.02,0,0,12.53,11.132,4.861,40.928-1.448,5.642-2.179,11.12-9.344,16.774Z" transform="translate(-103.702 -12.861)" fill="#0a1d35"/>
          <path id="Path_2815" data-name="Path 2815" d="M284.092,207.37s7.669,5.453,11.258,7.846,32.918,8.186,32.4,19.784c-.516,3.237-8.362,9.168-14.495,6.826-10.062,7.329-23.726-8.022-26.8-12.115s-14.47-7.732-14.47-7.732Z" transform="translate(-74.428 37.58)" fill="#ffa383"/>
          <path id="Path_2816" data-name="Path 2816" d="M162.56,94.854c0-4,2.758-9.206,2.569-12.152-.2-3.287,8.412-6.611,20.943-6.611s19.242,2.619,20.288,4.76c.856,1.75,4.634,12.568,5.717,15.678.932,2.682-27.73,5.289-27.73,5.289Z" transform="translate(-102.805 3.536)" fill="#aa0017"/>
          <path id="Path_2817" data-name="Path 2817" d="M190.8,462.148c.076,1.284-1.7,6.649-1.562,11.661.063,2.343.252,3.967,2.053,4.068,8.374.441,33.8,1.675,39.026,1.675,6.36-.012,12.4-.44,13.638-2.921,1.826-3.715-.592-10.616-13.135-14.482,8.475,5.541-18.21,7.669-30.677,5.881C191.949,466.858,192.339,459.34,190.8,462.148Z" transform="translate(-95.888 103.487)" fill="#101e35"/>
          <path id="Path_2818" data-name="Path 2818" d="M165.126,462.239c.554,5.3,7.657,4.747,7.367,10.83-.063,1.272,0,3.879-.743,4.9-2.229,3.06-7.531,3.451-11.913,3.463-6.3,0-12.77-.441-17.631-2.329-8.135-3.174-2.934-10.868-2.645-18.135.025-.567.365-1.839,1.662-1.725.768,5.176,2.544,10.428,14.583,10.428,10.213,0,5.856-8.123,8.2-8.8C164.736,460.639,165.088,461.748,165.126,462.239Z" transform="translate(-109.263 102.894)" fill="#101e35"/>
          <path id="Path_2819" data-name="Path 2819" d="M190.882,263.831c-.541,6.5-6.334,79.891-17.429,137.165-11.284,58.231-8.727,117.8-8.727,117.8s-3.476,1.31-11.435,1.864a33.909,33.909,0,0,1-12.732-1.385s-6.385-91.49-1.524-124.95c7.556-52.01-3.274-132.178,4.848-157.05,0,0,13.273-15.288,28.624-15.515,15.653-.227,35.034-.416,49.5-.277,8.84.088,14.331,15.339,17.517,15.729.642,17.1,5.73,88.568,1.31,153.209-3.942,57.6-8.614,108.755-8.412,125.416,0,0-3.073,3.526-11.9,3.274-6.637-.189-11.573-.768-13.941-3.954,2.279-27.491-7.543-84.9-1.07-122.078,1.763-28.3-10.024-129.244-10.024-129.244Z" transform="translate(-109.379 41.226)" fill="#101e35"/>
          <path id="Path_2820" data-name="Path 2820" d="M174.029,91.818l13.588-9.193,9.76-2.3,9.986,11.246,32.188,11.472s3,29.43-2.154,85.772c-3.022,33.057,11.863,64.263,9.646,66.114-5.176,4.345-8.123,6.813-8.123,6.813l1.045,12.064c-31.773,2.229-63.873,2.632-95.645.05l1.2-10.452s-2.67-1.914-8.06-5.377c-6.523-3.048,4.962-46.179,7.191-83.065a419.937,419.937,0,0,0-1.9-69.489Z" transform="translate(-109.828 4.633)" fill="#aa0017"/>
          <path id="Path_2821" data-name="Path 2821" d="M170.652,71.05c.038,4.609-.957,17.278-1.272,19.985,0,0,28.813,1.9,29.2,2.934-.39-1.033-1.826-14.923-1.511-21.723Z" transform="translate(-101.037 2.229)" fill="#ffa383"/>
          <path id="Path_2822" data-name="Path 2822" d="M170.39,71.639a27.446,27.446,0,0,1,.038,4.609s7.871,9.306,13.475,9.294,12.782-8.6,12.782-8.6-.126-5.755.126-8.16Z" transform="translate(-100.775 1.64)" fill="#ed906b"/>
          <path id="Path_2823" data-name="Path 2823" d="M209.024,51.613c-.642,4.8-4.357,10.969-7.934,9.117-2.871-1.5-5.3-3.992-4.659-8.8s3.148-7.166,6.964-7.934C207.513,43.163,209.654,46.815,209.024,51.613Z" transform="translate(-94.048 -4.818)" fill="#ed906b"/>
          <path id="Path_2824" data-name="Path 2824" d="M157.193,51.643c.642,4.8,4.37,10.956,7.946,9.1,2.871-1.5,5.289-3.992,4.647-8.8s-3.161-7.153-6.977-7.921C158.692,43.193,156.551,46.833,157.193,51.643Z" transform="translate(-104.226 -4.81)" fill="#ed906b"/>
          <path id="Path_2825" data-name="Path 2825" d="M158.07,45.58c.768-.957,4.9-1.473,6.083,2.141l-.441-3.413a5.49,5.49,0,0,0-2.9-.365A3.772,3.772,0,0,0,158.07,45.58Z" transform="translate(-103.97 -4.816)" fill="#ffa383"/>
          <path id="Path_2826" data-name="Path 2826" d="M210.993,47.552a24.217,24.217,0,1,1-48.433.038c-.013-17.958,10.818-26.6,24.2-26.609S210.981,29.972,210.993,47.552Z" transform="translate(-102.805 -10.755)" fill="#ffa383"/>
          <path id="Path_2827" data-name="Path 2827" d="M210.993,42.08c.013,12.958.013,23.8-4.924,30.841-2.972,4.244-10.692,11.535-16.283,13.387a16.293,16.293,0,0,1-6.75-.264c-5.554-1.965-12.681-9.067-15.515-13.11-4.949-7.04-4.949-17.882-4.962-30.841" transform="translate(-102.805 -5.283)" fill="#ffa383"/>
          <path id="Path_2828" data-name="Path 2828" d="M193.859,52.062c0,2.2-1.322,3.992-2.959,3.992s-2.959-1.788-2.959-3.992,1.322-3.992,2.959-3.992S193.859,49.858,193.859,52.062Z" transform="translate(-96.224 -3.73)" fill="#0e263f"/>
          <path id="Path_2829" data-name="Path 2829" d="M176.489,52.069c0,2.2-1.322,3.992-2.959,3.992s-2.959-1.788-2.959-3.979,1.322-3.992,2.959-3.992S176.489,49.866,176.489,52.069Z" transform="translate(-100.728 -3.725)" fill="#0e263f"/>
          <g id="Group_15417" data-name="Group 15417" transform="translate(90.437 35.138)">
            <path id="Path_2830" data-name="Path 2830" d="M187.234,40.923a19.309,19.309,0,0,1,10.049,1.335.368.368,0,0,1-.239.693,35.853,35.853,0,0,0-9.747-1.285.374.374,0,0,1-.063-.743Z" transform="translate(-186.924 -40.763)" fill="#0a1d35"/>
          </g>
          <g id="Group_15418" data-name="Group 15418" transform="translate(65.529 35.151)">
            <path id="Path_2831" data-name="Path 2831" d="M177.339,41.663a36.338,36.338,0,0,0-9.734,1.3.368.368,0,0,1-.239-.693,19.291,19.291,0,0,1,10.049-1.335.368.368,0,0,1-.076.73Z" transform="translate(-167.145 -40.773)" fill="#0a1d35"/>
          </g>
          <path id="Path_2832" data-name="Path 2832" d="M178.478,59.368c1.549-.73,7.14-.793,8.463-.013s-2.8,1.851-4.231,1.851S177.243,60.048,178.478,59.368Z" transform="translate(-98.737 -0.949)" fill="#ed906b"/>
          <path id="Path_2833" data-name="Path 2833" d="M174.013,65.539c5.415,1.36,13.853,1.159,18.89.592.617-.076.856.038.05.378-1.411.592-5.6,4.773-9.369,4.773s-8.538-4.66-9.772-5.428C173.283,65.539,173.522,65.425,174.013,65.539Z" transform="translate(-99.965 0.789)" fill="#ed906b"/>
          <path id="Path_2834" data-name="Path 2834" d="M193.388,66.133c.277.113-2.393,1.6-3.312,2.342-1.637.428-11.472.4-13.4-.39-4.168-2.758-2.985-2.594-2.985-2.594S185.807,66.674,193.388,66.133Z" transform="translate(-99.959 0.787)" fill="#fff"/>
          <path id="Path_2835" data-name="Path 2835" d="M136.18,220.869c1.108,1.209,101.829,4.685,110.694-1.889l-8.123,6.813s-29.027,6.611-43.61,7.191-49.781-5.516-49.781-5.516l-8.425-5.617Z" transform="translate(-109.646 40.59)" fill="#c1c1c1"/>
          <path id="Path_2836" data-name="Path 2836" d="M170.315,80.82c-.819.554,4.559,4.345,15.389,4.345,8.3,0,12.48-2.695,12.014-4.017l4.269,13.3H168.3Z" transform="translate(-101.317 4.763)" fill="#aa0017"/>
          <g id="Group_15419" data-name="Group 15419" transform="translate(59.755 89.272)">
            <path id="Path_2837" data-name="Path 2837" d="M163.227,92.136a12.361,12.361,0,0,1-.667-1.146A4.508,4.508,0,0,0,163.227,92.136Z" transform="translate(-162.56 -81.872)" fill="#ed906b"/>
            <path id="Path_2838" data-name="Path 2838" d="M209.33,96.23,204.947,84l3.224,12.832a32.721,32.721,0,0,1-5.062,1.9L199.9,86.029l1.977,13.034a58.868,58.868,0,0,1-8.274,1.511l-1.587-12.77v12.933c-1.713.151-3.388.227-4.962.227-2.078,0-4.055-.113-5.944-.3V87.83l-1.574,12.681a46.156,46.156,0,0,1-7.921-1.687l1.939-12.795-3.136,12.4a24.181,24.181,0,0,1-4.735-2.2l2.04-12.48-3.148,11.7a8.015,8.015,0,0,1-1.473-1.436,27.328,27.328,0,0,0,23.549,12.392c17.5,0,25.325-12.14,25.325-12.14A11.835,11.835,0,0,1,209.33,96.23Z" transform="translate(-162.42 -83.75)" fill="#c1c1c1"/>
          </g>
          <path id="Path_2839" data-name="Path 2839" d="M221.4,98.36c12.984,4.634,25.539,91.061,25.539,91.061s49.794,41.444,57.979,46.217-6.145,25.186-15.691,20.716-61.694-45.575-66.731-54.478-16.988-60.208-16.988-60.208Z" transform="translate(-91.667 9.311)" fill="#aa0017"/>
          <path id="Path_2840" data-name="Path 2840" d="M163.1,59.576c.479,1.637,3.413,1.41,3.463-.252s-2.556-11.258.932-16.9c7.392-11.938,29.355.68,36.52-11.422,0,0-1.713,9.319,2.909,14.142,1.562,2.141,1.272,12.581,1.272,15.011s3.211,1.4,3.35-.139S213.6,44.225,213.6,44.225l-7.039-17.139-15.477-9.6L172.406,21.2l-8.639,11.762L160.48,47.3Z" transform="translate(-103.345 -11.66)" fill="#0a1d35"/>
          <path id="Path_2841" data-name="Path 2841" d="M136.76,246.2l16.988-109.9-4.219-11.258-8.06,19.948Z" transform="translate(-109.496 16.23)" fill="#c1c1c1"/>
          <path id="Path_2842" data-name="Path 2842" d="M134.413,248.023c7.732,6.838,5.516,15.88,6.4,21.95.6,4.13,2.947,8.765-.567,7.417-6.284-2.418-5.3-12.82-5.3-12.82a4.762,4.762,0,0,0-2.38,3.639c-.567,4.584,4.9,14.054,5.528,18.613.68,4.849-9.357.73-13.311-4.672-7.329-9.231-7.329-25.438-7.317-26.937.038-3.236-.013-12.4-.013-12.4l18.033-11.7S133.191,246.877,134.413,248.023Z" transform="translate(-114.503 43.736)" fill="#ffa383"/>
          <path id="Path_2843" data-name="Path 2843" d="M148.029,100.29c-5.654,1.826-15.3,13.727-20.9,53.672-3.186,22.731-11.573,80.017-12.014,111.185-.013,1.3,2.216,3.4,2.2,4.584-.076,7.279-.05,12.379.113,14.117.466,5.125,20.527-1.4,20.527-6.385,0-1.511.353-5.856.932-11.976.126-1.3,1.675-2,1.814-3.451,2.947-29.984,11.686-98.831,11.686-98.831l2.758-46.469-7.115-16.447" transform="translate(-115.11 9.812)" fill="#aa0017"/>
          <path id="Path_2844" data-name="Path 2844" d="M217.151,187.109,212,123.01l15.427,79.866S219.493,196.051,217.151,187.109Z" transform="translate(-89.984 15.703)" fill="#c1c1c1"/>
        </g>
        <g id="Group_15502" data-name="Group 15502" transform="translate(227.844 263.465)">
          <g id="Group_15427" data-name="Group 15427" transform="translate(326.646 132.34)">
            <g id="Group_15421" data-name="Group 15421" transform="translate(12.773 1.174)">
              <rect id="Rectangle_2043" data-name="Rectangle 2043" width="20.225" height="6.888" transform="matrix(0.11, -0.994, 0.994, 0.11, 0, 20.101)" fill="#c7daea"/>
            </g>
            <g id="Group_15422" data-name="Group 15422" transform="translate(0 10.523)">
              <path id="Path_2845" data-name="Path 2845" d="M574.562,406.383c-2.7,6.548-18.235,4.445-18.89-2.091-1.776-17.756,6.359-39.858,6.813-43.963s-2.091-13.689,1.965-20.25c4.181-6.775,12.731-5.843,15.615,1.725,2.9,7.606-1.41,15.59-1.876,19.419S581.2,390.238,574.562,406.383Z" transform="translate(-555.419 -335.517)" fill="#c7daea"/>
            </g>
            <g id="Group_15423" data-name="Group 15423" transform="translate(14.064 4.219)">
              <path id="Path_2846" data-name="Path 2846" d="M574.385,332.5c-.088.831-1.914,1.31-4.068,1.07s-3.816-1.1-3.728-1.927,1.9-1.31,4.055-1.07S574.486,331.681,574.385,332.5Z" transform="translate(-566.587 -330.511)" fill="#ffa300"/>
            </g>
            <g id="Group_15424" data-name="Group 15424" transform="translate(14.085 1.137)">
              <rect id="Rectangle_2044" data-name="Rectangle 2044" width="4.257" height="7.846" transform="translate(0 4.231) rotate(-83.771)" fill="#ffa300"/>
            </g>
            <g id="Group_15425" data-name="Group 15425" transform="translate(14.529)">
              <path id="Path_2847" data-name="Path 2847" d="M574.756,329.149c-.088.831-1.914,1.31-4.068,1.07s-3.828-1.108-3.728-1.927,1.9-1.31,4.055-1.07S574.844,328.318,574.756,329.149Z" transform="translate(-566.956 -327.161)" fill="#ffbc80"/>
            </g>
            <g id="Group_15426" data-name="Group 15426" transform="translate(3.091 23.263)">
              <path id="Path_2848" data-name="Path 2848" d="M577.552,358.587c.239-2,1.524-5.125,2.292-8.74-.139.693-2.3,1.058-5.088,1.1.164-2.708-1.4-5.05-3.614-5.289-2.279-.252-4.37,1.813-4.71,4.609-2.77-.529-4.886-1.347-4.936-2.443.012,3.98.6,7.695.365,9.873-.227,1.952-2.166,7.946-3.954,15.616-.982,4.055,20.124,6.246,20.161,2.808h0C578.031,367.529,577.313,360.476,577.552,358.587Z" transform="translate(-557.874 -345.633)" fill="#ffbc80"/>
            </g>
          </g>
          <g id="Group_15434" data-name="Group 15434" transform="translate(317.344 144.454)">
            <g id="Group_15428" data-name="Group 15428" transform="translate(7.416 1.496)">
              <rect id="Rectangle_2045" data-name="Rectangle 2045" width="20.225" height="6.888" transform="translate(0 20.225) rotate(-89.893)" fill="#c7daea"/>
            </g>
            <g id="Group_15429" data-name="Group 15429" transform="translate(0 10.566)">
              <path id="Path_2849" data-name="Path 2849" d="M568.292,415.39c-1.964,6.8-17.656,6.4-19.016-.038-3.69-17.467,2-40.311,2.015-44.441s-3.564-13.387-.24-20.351c3.426-7.191,12.014-7.191,15.7.025,3.7,7.254.29,15.653.239,19.507S573.14,398.628,568.292,415.39Z" transform="translate(-548.032 -345.17)" fill="#c7daea"/>
            </g>
            <g id="Group_15430" data-name="Group 15430" transform="translate(6.974 4.244)">
              <path id="Path_2850" data-name="Path 2850" d="M561.415,341.661c0,.831-1.75,1.5-3.929,1.5s-3.917-.68-3.917-1.511,1.751-1.511,3.917-1.5S561.415,340.83,561.415,341.661Z" transform="translate(-553.57 -340.15)" fill="#f29a86"/>
            </g>
            <g id="Group_15431" data-name="Group 15431" transform="translate(6.974 1.499)">
              <rect id="Rectangle_2046" data-name="Rectangle 2046" width="7.846" height="4.257" fill="#f29a86"/>
            </g>
            <g id="Group_15432" data-name="Group 15432" transform="translate(6.974)">
              <path id="Path_2851" data-name="Path 2851" d="M561.415,338.291c0,.844-1.75,1.5-3.929,1.5s-3.917-.68-3.917-1.511,1.751-1.511,3.917-1.5S561.415,337.46,561.415,338.291Z" transform="translate(-553.57 -336.78)" fill="#ffaf99"/>
            </g>
            <g id="Group_15433" data-name="Group 15433" transform="translate(1.008 23.323)">
              <path id="Path_2852" data-name="Path 2852" d="M566.787,367.465c.025-2.015.957-5.264,1.335-8.941-.063.705-2.178,1.3-4.936,1.637-.139-2.708-1.939-4.861-4.168-4.861-2.292,0-4.143,2.267-4.194,5.088-2.808-.227-5-.806-5.175-1.9.453,3.954,1.435,7.594,1.435,9.772-.012,1.964-1.284,8.135-2.242,15.955-.528,4.13,20.678,4.03,20.338.6h0C568.248,376.293,566.762,369.367,566.787,367.465Z" transform="translate(-548.832 -355.3)" fill="#9abad8"/>
            </g>
          </g>
          <g id="Group_15437" data-name="Group 15437" transform="translate(235.248 87.696)">
            <g id="Group_15435" data-name="Group 15435" transform="translate(1.71 5.289)">
              <path id="Path_2853" data-name="Path 2853" d="M507.094,318.124a17.843,17.843,0,0,1-22.895-2.053,17.837,17.837,0,1,0,30.74-17.844,17.493,17.493,0,0,0-1.965-2.317A17.833,17.833,0,0,1,507.094,318.124Z" transform="translate(-484.2 -295.91)" fill="none"/>
            </g>
            <g id="Group_15436" data-name="Group 15436">
              <path id="Path_2854" data-name="Path 2854" d="M507.446,319.213A17.821,17.821,0,0,0,513.327,297a17.817,17.817,0,0,0-28.775,20.149A17.812,17.812,0,0,0,507.446,319.213Z" transform="translate(-482.842 -291.71)" fill="#ffa300"/>
            </g>
          </g>
          <g id="Group_15440" data-name="Group 15440" transform="translate(258.97 90.505)">
            <g id="Group_15438" data-name="Group 15438" transform="translate(1.714 5.276)">
              <path id="Path_2855" data-name="Path 2855" d="M525.934,320.357A17.844,17.844,0,0,1,503.04,318.3a17.84,17.84,0,1,0,30.74-17.857,18.411,18.411,0,0,0-1.952-2.317A17.865,17.865,0,0,1,525.934,320.357Z" transform="translate(-503.04 -298.13)" fill="none"/>
            </g>
            <g id="Group_15439" data-name="Group 15439">
              <path id="Path_2856" data-name="Path 2856" d="M526.287,321.443a17.821,17.821,0,0,0,5.881-22.214,17.828,17.828,0,0,0-28.788,20.162A17.86,17.86,0,0,0,526.287,321.443Z" transform="translate(-501.679 -293.94)" fill="#ffa300"/>
            </g>
          </g>
          <g id="Group_15448" data-name="Group 15448" transform="translate(217.666 72.832)">
            <g id="Group_15441" data-name="Group 15441" transform="translate(0 9.94)">
              <path id="Path_2857" data-name="Path 2857" d="M504.569,305.644A17.845,17.845,0,1,1,486.724,287.8,17.846,17.846,0,0,1,504.569,305.644Z" transform="translate(-468.88 -287.8)" fill="#ffa300"/>
            </g>
            <g id="Group_15444" data-name="Group 15444" transform="translate(0 9.94)">
              <g id="Group_15442" data-name="Group 15442" transform="translate(0 14.822)">
                <path id="Path_2858" data-name="Path 2858" d="M486.724,314.392a17.827,17.827,0,0,1-17.568-14.822,18.335,18.335,0,0,0-.277,3.022,17.845,17.845,0,1,0,35.689,0,17.077,17.077,0,0,0-.277-3.022A17.839,17.839,0,0,1,486.724,314.392Z" transform="translate(-468.88 -299.57)" fill="none"/>
              </g>
              <g id="Group_15443" data-name="Group 15443" transform="translate(0.277)">
                <path id="Path_2859" data-name="Path 2859" d="M486.667,317.444a17.837,17.837,0,0,0,17.568-14.822,17.822,17.822,0,0,0-35.135,0A17.849,17.849,0,0,0,486.667,317.444Z" transform="translate(-469.1 -287.8)" fill="#ffbc80"/>
              </g>
            </g>
            <g id="Group_15445" data-name="Group 15445" transform="translate(16.825 6.175)">
              <path id="Path_2860" data-name="Path 2860" d="M483.26,291.522a1.024,1.024,0,0,1-1.02-1.02V285.83a1.02,1.02,0,1,1,2.04,0V290.5A1.016,1.016,0,0,1,483.26,291.522Z" transform="translate(-482.24 -284.81)" fill="#38ad80"/>
            </g>
            <g id="Group_15446" data-name="Group 15446" transform="translate(17.139)">
              <path id="Path_2861" data-name="Path 2861" d="M509.679,282.492s-5.3,5.642-12.82,6.9-14.369-2.317-14.369-2.317,5.3-5.629,12.82-6.9S509.679,282.492,509.679,282.492Z" transform="translate(-482.49 -279.906)" fill="#4ecc9b"/>
            </g>
            <g id="Group_15447" data-name="Group 15447" transform="translate(17.139 2.586)">
              <path id="Path_2862" data-name="Path 2862" d="M482.49,286.556s16.321-4.609,27.189-4.6" transform="translate(-482.49 -281.96)" fill="#38ad80"/>
            </g>
          </g>
          <g id="Group_15456" data-name="Group 15456" transform="translate(285.576 66.149)">
            <g id="Group_15449" data-name="Group 15449" transform="translate(0 17.978)">
              <path id="Path_2863" data-name="Path 2863" d="M555.263,296.487a17.844,17.844,0,1,1-24.846-4.382A17.846,17.846,0,0,1,555.263,296.487Z" transform="translate(-522.805 -288.876)" fill="#ffa300"/>
            </g>
            <g id="Group_15452" data-name="Group 15452" transform="translate(0.007 17.977)">
              <g id="Group_15450" data-name="Group 15450" transform="translate(1.71 5.283)">
                <path id="Path_2864" data-name="Path 2864" d="M547.065,315.3a17.83,17.83,0,0,1-22.895-2.065,17.837,17.837,0,1,0,30.74-17.845,17.492,17.492,0,0,0-1.965-2.317A17.849,17.849,0,0,1,547.065,315.3Z" transform="translate(-524.17 -293.07)" fill="none"/>
              </g>
              <g id="Group_15451" data-name="Group 15451">
                <path id="Path_2865" data-name="Path 2865" d="M547.416,316.385a17.834,17.834,0,0,0,5.881-22.227,17.823,17.823,0,0,0-28.775,20.162A17.83,17.83,0,0,0,547.416,316.385Z" transform="translate(-522.812 -288.875)" fill="#ffbc80"/>
              </g>
            </g>
            <g id="Group_15453" data-name="Group 15453" transform="translate(4.994 17.934)">
              <path id="Path_2866" data-name="Path 2866" d="M530.485,294.721a1.018,1.018,0,0,1-.844-.441l-2.682-3.828a1.022,1.022,0,1,1,1.675-1.171l2.682,3.828a1.013,1.013,0,0,1-.252,1.423A.993.993,0,0,1,530.485,294.721Z" transform="translate(-526.771 -288.841)" fill="#38ad80"/>
            </g>
            <g id="Group_15454" data-name="Group 15454" transform="translate(5.445)">
              <path id="Path_2867" data-name="Path 2867" d="M546.762,274.6s-1.108,7.657-6.536,13.009-13.1,6.359-13.1,6.359,1.12-7.656,6.535-13.009S546.762,274.6,546.762,274.6Z" transform="translate(-527.13 -274.6)" fill="#4ecc9b"/>
            </g>
            <g id="Group_15455" data-name="Group 15455" transform="translate(5.445)">
              <path id="Path_2868" data-name="Path 2868" d="M527.13,293.968s10.717-13.147,19.633-19.368" transform="translate(-527.13 -274.6)" fill="#38ad80"/>
            </g>
          </g>
          <g id="Group_15457" data-name="Group 15457" transform="translate(217.452 102.909)">
            <rect id="Rectangle_2047" data-name="Rectangle 2047" width="105.997" height="121.764" fill="#050b9f"/>
          </g>
          <g id="Group_15458" data-name="Group 15458" transform="translate(224.806 114.86)" opacity="0.5">
            <path id="Path_2869" data-name="Path 2869" d="M474.55,313.28c1.058,20.754,15.83,109.8,42.628,109.8H479.65Z" transform="translate(-474.55 -313.28)" fill="#c1c1c1"/>
          </g>
          <g id="Group_15459" data-name="Group 15459" transform="translate(116.379 24.621)">
            <path id="Path_2870" data-name="Path 2870" d="M471.742,263.405c-4.849-2.67-4.975-16.5-7.984-19.482s-36.1-3.161-40.109,0-2.38,15.439-6.435,18.676c-2.821,2.254-13.172,3.7-19.23,4.395a4.966,4.966,0,0,0-4.622,4.647c-.063,2.342-4.911,175.335-4.911,175.335H501.839s-7.065-171.142-7.329-174.3-2.091-5.365-4.773-5.654C484.158,266.428,475.015,265.206,471.742,263.405Zm-16.711-7.493H430.587a3.545,3.545,0,1,1,0-7.09H455.03a3.545,3.545,0,1,1,0,7.09Z" transform="translate(-388.45 -241.623)" fill="#840000"/>
          </g>
          <g id="Group_15460" data-name="Group 15460" transform="translate(116.391 153.936)" opacity="0.7">
            <path id="Path_2871" data-name="Path 2871" d="M389.9,368.992c-.592,23.02,31.571,41,65.686,35.664,29.9-4.685,44.252-35.815,43.119-60.347l3.287,70.724H388.46Z" transform="translate(-388.46 -344.31)" fill="#c1c1c1"/>
          </g>
          <g id="Group_15467" data-name="Group 15467" transform="translate(334.541 144.449)">
            <g id="Group_15461" data-name="Group 15461" transform="translate(20.111 0.911)">
              <rect id="Rectangle_2048" data-name="Rectangle 2048" width="20.225" height="6.888" transform="translate(0 19.622) rotate(-75.983)" fill="#c7daea"/>
            </g>
            <g id="Group_15462" data-name="Group 15462" transform="translate(0 10.286)">
              <path id="Path_2872" data-name="Path 2872" d="M580.14,415.612c-3.539,6.12-18.663,1.977-18.449-4.6.6-17.832,11.611-38.649,12.606-42.666s-.252-13.84,4.647-19.809c5.05-6.158,13.387-4.093,15.238,3.791,1.864,7.921-3.463,15.263-4.445,18.99C588.766,375.074,588.879,400.512,580.14,415.612Z" transform="translate(-561.688 -344.944)" fill="#c7daea"/>
            </g>
            <g id="Group_15463" data-name="Group 15463" transform="translate(23.534 4.131)">
              <path id="Path_2873" data-name="Path 2873" d="M588,342.743c-.2.806-2.065,1.032-4.168.516s-3.64-1.6-3.438-2.405,2.065-1.045,4.168-.516S588.2,341.937,588,342.743Z" transform="translate(-580.376 -340.056)" fill="#f29a86"/>
            </g>
            <g id="Group_15464" data-name="Group 15464" transform="translate(23.528 0.802)">
              <rect id="Rectangle_2049" data-name="Rectangle 2049" width="4.269" height="7.846" transform="matrix(0.24, -0.971, 0.971, 0.24, 0, 4.144)" fill="#f29a86"/>
            </g>
            <g id="Group_15465" data-name="Group 15465" transform="translate(24.554)">
              <path id="Path_2874" data-name="Path 2874" d="M588.81,339.463c-.2.806-2.065,1.033-4.168.516s-3.639-1.6-3.438-2.405,2.065-1.045,4.168-.516S589.011,338.657,588.81,339.463Z" transform="translate(-581.186 -336.776)" fill="#ffaf99"/>
            </g>
            <g id="Group_15466" data-name="Group 15466" transform="translate(6.534 22.911)">
              <path id="Path_2875" data-name="Path 2875" d="M588.4,368.734c.5-1.939,2.191-4.874,3.438-8.362-.227.68-2.43.743-5.188.416.516-2.67-.718-5.188-2.884-5.717-2.229-.554-4.572,1.209-5.289,3.942-2.67-.894-4.659-1.99-4.572-3.086-.516,3.942-.428,7.707-.957,9.835-.478,1.9-3.2,7.594-5.994,14.948-1.511,3.879,19.116,8.866,19.608,5.466h0C587.7,377.651,587.924,370.573,588.4,368.734Z" transform="translate(-566.877 -354.969)" fill="#ffaf99"/>
            </g>
          </g>
          <g id="Group_15472" data-name="Group 15472" transform="translate(142.944 188.799)">
            <g id="Group_15468" data-name="Group 15468" transform="translate(0 10.115)">
              <path id="Path_2876" data-name="Path 2876" d="M439.347,396.465c-1.574,6.511-7.505,12.316-13.953,13.386-2.494.416-4.332-2.04-4.332-2.04a4.5,4.5,0,0,1-4.508.025c-5.554-3.652-8.148-11.875-6.536-18.5,1.247-5.126,4.722-8.337,9.231-9.193,3.942-.743,7.984,2.242,7.984,2.242s5.717-.655,8.5,2A12.071,12.071,0,0,1,439.347,396.465Z" transform="translate(-409.545 -380.026)" fill="#028429"/>
            </g>
            <g id="Group_15469" data-name="Group 15469" transform="translate(16.074 7.424)">
              <path id="Path_2877" data-name="Path 2877" d="M423.33,386.391a1.547,1.547,0,0,1-.239-.025,1.036,1.036,0,0,1-.756-1.234l1.574-6.46a1.023,1.023,0,1,1,1.99.479l-1.562,6.46A1.047,1.047,0,0,1,423.33,386.391Z" transform="translate(-422.309 -377.889)" fill="#38ad80"/>
            </g>
            <g id="Group_15470" data-name="Group 15470" transform="translate(17.725)">
              <path id="Path_2878" data-name="Path 2878" d="M445.305,372.808a18.942,18.942,0,0,1-21.685,7.719,18.942,18.942,0,0,1,21.685-7.719Z" transform="translate(-423.62 -371.993)" fill="#005433"/>
            </g>
            <g id="Group_15471" data-name="Group 15471" transform="translate(17.738 0.802)">
              <path id="Path_2879" data-name="Path 2879" d="M423.63,380.349s12.744-6.158,21.673-7.719" transform="translate(-423.63 -372.63)" fill="#38ad80"/>
            </g>
          </g>
          <g id="Group_15477" data-name="Group 15477" transform="translate(168.991 201.704)">
            <g id="Group_15473" data-name="Group 15473" transform="translate(0 6.844)">
              <path id="Path_2880" data-name="Path 2880" d="M450.43,398.813a12.5,12.5,0,0,1-9.458,9.067,3.455,3.455,0,0,1-2.934-1.385,3.057,3.057,0,0,1-3.06.012c-3.766-2.468-5.516-8.035-4.433-12.53a7.829,7.829,0,0,1,6.259-6.221c2.67-.5,5.4,1.524,5.4,1.524s3.879-.441,5.768,1.36A8.119,8.119,0,0,1,450.43,398.813Z" transform="translate(-430.228 -387.676)" fill="#005433"/>
            </g>
            <g id="Group_15474" data-name="Group 15474" transform="translate(10.578 4.706)">
              <path id="Path_2881" data-name="Path 2881" d="M439.65,392.39a1.535,1.535,0,0,1-.239-.025,1.019,1.019,0,0,1-.755-1.234l1.07-4.37a1.031,1.031,0,0,1,1.234-.755,1.019,1.019,0,0,1,.755,1.234l-1.07,4.37A1.033,1.033,0,0,1,439.65,392.39Z" transform="translate(-438.629 -385.979)" fill="#005433"/>
            </g>
            <g id="Group_15475" data-name="Group 15475" transform="translate(12.028)">
              <path id="Path_2882" data-name="Path 2882" d="M454.463,382.793a12.826,12.826,0,0,1-14.684,5.226,12.827,12.827,0,0,1,14.684-5.226Z" transform="translate(-439.78 -382.241)" fill="#005433"/>
            </g>
            <g id="Group_15476" data-name="Group 15476" transform="translate(12.028 0.552)">
              <path id="Path_2883" data-name="Path 2883" d="M439.78,387.906s8.626-4.169,14.684-5.226" transform="translate(-439.78 -382.68)" fill="#005433"/>
            </g>
          </g>
          <g id="Group_15478" data-name="Group 15478" transform="translate(124.388 51.239)" opacity="0.3">
            <path id="Path_2884" data-name="Path 2884" d="M394.81,354.376l2.418-85s20.086-.478,25.212-6.611h36.344S405.552,288.929,394.81,354.376Z" transform="translate(-394.81 -262.76)" fill="#fff"/>
          </g>
          <g id="Group_15479" data-name="Group 15479" transform="translate(266.099 108.79)" opacity="0.3">
            <path id="Path_2885" data-name="Path 2885" d="M507.34,308.46h51.67v77.763s-1.738-48.912-13.5-63.8C535.234,309.417,507.34,308.46,507.34,308.46Z" transform="translate(-507.34 -308.46)" fill="#fff"/>
          </g>
          <g id="Group_15501" data-name="Group 15501">
            <g id="Group_15480" data-name="Group 15480" transform="translate(70.5 54.767)">
              <path id="Path_2886" data-name="Path 2886" d="M629.449,443.841H372.572a3.083,3.083,0,0,1-3.06-2.758L352.033,268.947a3.077,3.077,0,0,1,3.324-3.375l332.523,28.914a3.077,3.077,0,0,1,2.582,4.219l-58.168,143.21A3.074,3.074,0,0,1,629.449,443.841ZM375.343,437.7H627.383l55.826-137.442L358.518,272.02Z" transform="translate(-352.018 -265.561)" fill="#c1c1c1"/>
            </g>
            <g id="Group_15481" data-name="Group 15481" transform="translate(114.351 59.513)">
              <path id="Path_2887" data-name="Path 2887" d="M388.88,441.844a2.042,2.042,0,0,1-2.04-2.053V271.383a2.046,2.046,0,1,1,4.093,0V439.792A2.07,2.07,0,0,1,388.88,441.844Z" transform="translate(-386.84 -269.33)" fill="#c1c1c1"/>
            </g>
            <g id="Group_15482" data-name="Group 15482" transform="translate(157.571 63.291)">
              <path id="Path_2888" data-name="Path 2888" d="M423.2,441.066a2.042,2.042,0,0,1-2.04-2.053V274.37a2.046,2.046,0,0,1,4.093,0V439.026A2.059,2.059,0,0,1,423.2,441.066Z" transform="translate(-421.16 -272.33)" fill="#c1c1c1"/>
            </g>
            <g id="Group_15483" data-name="Group 15483" transform="translate(200.778 67.043)">
              <path id="Path_2889" data-name="Path 2889" d="M457.523,440.293a2.053,2.053,0,0,1-2.053-2.053V277.35a2.053,2.053,0,0,1,4.105,0V438.241A2.053,2.053,0,0,1,457.523,440.293Z" transform="translate(-455.47 -275.31)" fill="#c1c1c1"/>
            </g>
            <g id="Group_15484" data-name="Group 15484" transform="translate(244.011 70.796)">
              <path id="Path_2890" data-name="Path 2890" d="M491.84,439.521a2.042,2.042,0,0,1-2.04-2.053V280.343a2.04,2.04,0,1,1,4.08,0V437.468A2.034,2.034,0,0,1,491.84,439.521Z" transform="translate(-489.8 -278.29)" fill="#c1c1c1"/>
            </g>
            <g id="Group_15485" data-name="Group 15485" transform="translate(287.243 74.436)">
              <path id="Path_2891" data-name="Path 2891" d="M526.17,438.771a2.042,2.042,0,0,1-2.04-2.053V283.22a2.047,2.047,0,0,1,4.093,0V436.731A2.067,2.067,0,0,1,526.17,438.771Z" transform="translate(-524.13 -281.18)" fill="#c1c1c1"/>
            </g>
            <g id="Group_15486" data-name="Group 15486" transform="translate(330.45 78.314)">
              <path id="Path_2892" data-name="Path 2892" d="M560.492,437.972a2.053,2.053,0,0,1-2.052-2.053V286.313a2.046,2.046,0,1,1,4.093,0v149.62A2.031,2.031,0,0,1,560.492,437.972Z" transform="translate(-558.44 -284.26)" fill="#c1c1c1"/>
            </g>
            <g id="Group_15487" data-name="Group 15487" transform="translate(373.67 81.928)">
              <path id="Path_2893" data-name="Path 2893" d="M594.812,368.784a2.053,2.053,0,0,1-2.053-2.053V289.17a2.046,2.046,0,0,1,4.093,0v77.562A2.034,2.034,0,0,1,594.812,368.784Z" transform="translate(-592.76 -287.13)" fill="#c1c1c1"/>
            </g>
            <g id="Group_15488" data-name="Group 15488" transform="translate(74.985 83.742)">
              <path id="Path_2894" data-name="Path 2894" d="M683.91,292.675H357.632a2.053,2.053,0,0,1,0-4.105H683.91a2.053,2.053,0,0,1,0,4.105Z" transform="translate(-355.58 -288.57)" fill="#c1c1c1"/>
            </g>
            <g id="Group_15489" data-name="Group 15489" transform="translate(78.209 121.622)">
              <path id="Path_2895" data-name="Path 2895" d="M671.031,322.743H360.18a2.046,2.046,0,0,1,0-4.093H671.031a2.046,2.046,0,1,1,0,4.093Z" transform="translate(-358.14 -318.65)" fill="#c1c1c1"/>
            </g>
            <g id="Group_15490" data-name="Group 15490" transform="translate(82.062 159.477)">
              <path id="Path_2896" data-name="Path 2896" d="M654.861,352.815H363.24a2.053,2.053,0,0,1,0-4.105H654.874a2.053,2.053,0,0,1-.013,4.105Z" transform="translate(-361.2 -348.71)" fill="#c1c1c1"/>
            </g>
            <g id="Group_15491" data-name="Group 15491" transform="translate(85.903 197.345)">
              <path id="Path_2897" data-name="Path 2897" d="M637.082,382.873H366.29a2.046,2.046,0,0,1,0-4.093H637.082a2.046,2.046,0,1,1,0,4.093Z" transform="translate(-364.25 -378.78)" fill="#c1c1c1"/>
            </g>
            <g id="Group_15492" data-name="Group 15492" transform="translate(2.049 2.036)">
              <path id="Path_2898" data-name="Path 2898" d="M643.544,456.74H388.456a5.117,5.117,0,0,1-5.088-4.584L362.476,254.443l-61.341-20.792a5.113,5.113,0,0,1,3.287-9.684l64.44,21.849a5.142,5.142,0,0,1,3.45,4.307l20.754,196.391H640.106l56.871-140.024a5.116,5.116,0,1,1,9.483,3.841L648.292,453.554A5.14,5.14,0,0,1,643.544,456.74Z" transform="translate(-297.663 -223.689)" fill="#c1c1c1"/>
            </g>
            <g id="Group_15493" data-name="Group 15493">
              <path id="Path_2899" data-name="Path 2899" d="M335.583,247.382a7.363,7.363,0,0,1-2.3-.378L300.9,236.023a7.16,7.16,0,0,1,4.6-13.563l32.377,10.969a7.167,7.167,0,0,1-2.292,13.953Z" transform="translate(-296.036 -222.072)" fill="#bf5858"/>
            </g>
            <g id="Group_15494" data-name="Group 15494" transform="translate(75.129 224.861)">
              <path id="Path_2900" data-name="Path 2900" d="M635.208,455.423H360.814a5.116,5.116,0,0,1-4.924-6.511l12.58-44.555a5.117,5.117,0,1,1,9.848,2.783L367.577,445.2H635.208a5.113,5.113,0,0,1,0,10.226Z" transform="translate(-355.695 -400.63)" fill="#c1c1c1"/>
            </g>
            <g id="Group_15495" data-name="Group 15495" transform="translate(102.765 287.21)">
              <path id="Path_2901" data-name="Path 2901" d="M411.289,466.964a16.824,16.824,0,1,1-16.824-16.825A16.819,16.819,0,0,1,411.289,466.964Z" transform="translate(-377.64 -450.14)" fill="#0a1d35"/>
            </g>
            <g id="Group_15496" data-name="Group 15496" transform="translate(109.15 293.595)">
              <path id="Path_2902" data-name="Path 2902" d="M403.589,465.65a10.44,10.44,0,1,1-10.44-10.44A10.434,10.434,0,0,1,403.589,465.65Z" transform="translate(-382.71 -455.21)" fill="#ededed"/>
            </g>
            <g id="Group_15497" data-name="Group 15497" transform="translate(114.48 269.432)">
              <path id="Path_2903" data-name="Path 2903" d="M392.052,475.738a5.1,5.1,0,0,1-4.584-7.354l14.419-29.493a5.117,5.117,0,0,1,9.193,4.5L396.661,472.88A5.142,5.142,0,0,1,392.052,475.738Z" transform="translate(-386.942 -436.022)" fill="#c1c1c1"/>
            </g>
            <g id="Group_15498" data-name="Group 15498" transform="translate(302.065 287.21)">
              <path id="Path_2904" data-name="Path 2904" d="M569.549,466.964a16.824,16.824,0,1,1-16.825-16.825A16.82,16.82,0,0,1,569.549,466.964Z" transform="translate(-535.9 -450.14)" fill="#0a1d35"/>
            </g>
            <g id="Group_15499" data-name="Group 15499" transform="translate(308.463 293.595)">
              <path id="Path_2905" data-name="Path 2905" d="M561.847,465.65a10.433,10.433,0,1,1-10.439-10.44A10.434,10.434,0,0,1,561.847,465.65Z" transform="translate(-540.98 -455.21)" fill="#ededed"/>
            </g>
            <g id="Group_15500" data-name="Group 15500" transform="translate(313.776 269.419)">
              <path id="Path_2906" data-name="Path 2906" d="M550.313,475.741a5.076,5.076,0,0,1-2.242-.516,5.128,5.128,0,0,1-2.355-6.851l14.419-29.494a5.117,5.117,0,0,1,9.193,4.5L554.91,472.87A5.113,5.113,0,0,1,550.313,475.741Z" transform="translate(-545.199 -436.012)" fill="#c1c1c1"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
  )
}

export default NextCustomerSvg