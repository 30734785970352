import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../deliveryScreen.css"
import { Autocomplete, TextField } from "@mui/material";
import { findObjFromList } from "../../../../../Js/generalFunctions";
import { aggregatorUpdateAPI, orderDeliveredAPI, pickupDeliveryOrder } from "../deliveryScreenAPI";
const DeliveryCard = ({
  elementData,
  day,
  orderNo,
  tokenNo,
  orderInfo,
  key
}) => {
  const componentRef = useRef();

  const dispatch = useDispatch();
  
  const { kotOrderStatus } = useSelector(
      (state) => state.kitchenOrderTicketSlice
  );

  const {aggregatorList}=useSelector((state)=>state.aggregatorSlice)

  const [toggle,setToggle]=useState(false)    
  const [aggregator,setAggregator]=useState(null)    
  const [deliveryCode,setDeliveryCode]=useState("")    
  const [validationAlert,setValidationAlert]=useState({})    

  // single product selection
  const handleSingleProductClick = (data) => {
    if (kotOrderStatus.index === 2) {
        
    }
  };

  const aggregatorOnChange=(value)=>{
    setAggregator(value)
    setValidationAlert({})

    if(value){
      aggregatorUpdateAPI({
          id:elementData?._id,
          aggregatorId:value?._id
      })
    }
  }
  const dlCodeOnChange=(e)=>{
    setDeliveryCode(e.target.value)
    setValidationAlert({})
  }

  const deliveryCardClick=()=>{
    if(aggregator===null){
      setValidationAlert({...validationAlert,aggregator:"Aggregator not selected !!"})
    }else if(deliveryCode===""){
      setValidationAlert({...validationAlert,deliveryCode:"Fill this field !!"})
    }else if(elementData?.isSelf && !(deliveryCode?.toUpperCase()===aggregator?.code?.toUpperCase())){
      setValidationAlert({...validationAlert,deliveryCode:"DeliveryCode is incorrect !!"})
    }else if(!elementData?.isSelf && !(deliveryCode?.toUpperCase()===elementData?.aggregatorRef?.toUpperCase())){
      setValidationAlert({...validationAlert,deliveryCode:"Refference is incorrect !!"})
    }else{
      let payload={id:elementData?._id}
      elementData?.status==="RDYPICKUP"?pickupDeliveryOrder(payload):orderDeliveredAPI(payload)
      setDeliveryCode("")
    }
  }
  useEffect(()=>{
    if (aggregatorList.length > 0 && elementData) {
      let agregatorFinded=findObjFromList("_id",aggregatorList,"aggregatorId",elementData) 
      setAggregator(agregatorFinded)
      setDeliveryCode("")
    }
  },[aggregatorList,elementData?.aggregatorId])

  return (
    <div className="kot-card-wrapper" style={{height:"fit-content"}} key={key}>
      <div className="kot-card-container">
        <div className="kot-card-header">
          <p className="card-order-no">{orderNo}</p>
          {
           
           <input 
           value={toggle}
           onChange={(e)=>setToggle(e.target.checked)}
           style={{marginLeft:"auto"}} type="checkbox" className="toggle-checkbox" />

          }
          <p className="card-date">{day}</p>
          <p className="card-token-no">Token No : {tokenNo}</p>

        </div>
        {
        toggle &&    
        <div className="card-order-list-container" style={{height:"fit-content",maxHeight:"12em"}}>
          <ul>
            {orderInfo?.map((res, i) => (
              <li key={i} onClick={() => handleSingleProductClick(res)}>
                <div className="card-order-list">
                  <div className="card-order-item-name">
                    <span style={{ color: res.itemType === 3 ? "red" : "" }}>
                      {res?.productName}
                    </span>
                  </div>
                  <div className="card-order-quantity">
                    <span style={{ color: res.itemType === 3 ? "red" : "" }}>
                      {res?.qty}
                    </span>
                  </div>
                </div>
                {res?.customerNote && (
                  <div className="card-notes">
                    <p>Note : {res?.customerNote}</p>
                  </div>
                )}
                {/* combo offer  */}
                {res?.productList?.length > 0 && (
                  <ul className="kot-compo-offer-container">
                    {res?.productList?.map((res, i) => (
                      <li key={i}>
                        {res?.qty} X {res?.productName}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>}
        <div className="flex-column">
        <div className="new-global-single-input" style={{width:"calc(100% - 16px)"}}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={aggregatorList?.filter((obj)=>obj?.isSelf===elementData?.isSelf) || []}
                getOptionLabel={(option) => option?.name}
                value={aggregator}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Name" focused />
                )} 
                onChange={(e,newValue)=>aggregatorOnChange(newValue)}
                disabled={!elementData?.isSelf || elementData?.status==="PICKUP"}
        />
        <p className="doc-validation-alert">{validationAlert?.aggregator}</p>
        </div>
        <div className="new-global-single-input" style={{width:"calc(100% - 16px)"}}>
            <TextField
              id="outlined-basic"
              label={elementData?.isSelf?"Delivery Code":"Reference"}
              variant="outlined"
              value={deliveryCode}
              onChange={(e)=>dlCodeOnChange(e)}
              type={"text"}
              focused
              onKeyDown={(e) => e.key === "Enter" && deliveryCardClick()}
            />
        <p className="doc-validation-alert">{validationAlert?.deliveryCode}</p>
          </div>

            <button
             onClick={deliveryCardClick}
              className={`delivery-action-btn ${elementData?.status==="RDYPICKUP"?"delivery-pickup":"delivery-delivered"}`}
              style={{width:"100%"}}
            >
              <span>{elementData?.status==="RDYPICKUP"?"Pick up":"DELIVERING"}</span>
            </button>
        </div>

      </div>
    </div>
  );
};

export default DeliveryCard;
