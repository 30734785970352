import { Autocomplete, IconButton, Skeleton, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { cancelRedrafteJournalEntriesAPICall, chartOfAccountListApiCall } from '../../../../API/AccountUpdated/chartOfAccountAPI'
import { journalListApiCall } from '../../../../API/AccountUpdated/journalAPI'
import { useDispatch, useSelector } from 'react-redux'
import {  getAllInactiveEmployeesAPICall } from '../../../../API/Staff/HRM/employeeAPI'
import { getCurrentTime, today } from '../../../../Js/Date'
import { JournalEntriesSingleViewApi, draftJournalEntryAPICall, editJournalEntryAPICall, generateJournalEntryNameAPICall, postJournalEntryAPICall } from './JournalEntry/journalEntryApi'
import { useNavigate } from 'react-router-dom'
import { findObjFromList, roundNumericFields } from '../../../../Js/generalFunctions'
import cancelRibbon from "../../../../Assets/statusRibbon/cancel.png";

import postRibbon from "../../../../Assets/statusRibbon/post.png";
import draftRibbon from "../../../../Assets/statusRibbon/draft.png";
import logoImage from "../../../../Assets/Images/Icon ionic-logo-buffer.png"
import uploadFile from "../../../../Assets/Images/Icon feather-upload.png"
import { CloseOutlined } from '@mui/icons-material'
import { useRef } from 'react'
import SuccessSnackbar from '../../../Single Components/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar'
import { LoadingForm } from '../../../Single Components/LoadingForm'
import { setFilterActive, set_Back_button_action } from '../../../Single Components/listSlice/filterCategorySlice'
import { viewCustomerVendorAPICall } from './OpeningBalanceAPI'
import { viewAllAllowedBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI'
export const JournalEntriesForm = () => {
  const singleViewId = localStorage.getItem("singleViewById")

  let navigateTo=useNavigate()  
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  // localStorage.setItem("decimalPosition",2)
  const decimalPosition=Number(localStorage.getItem("decimalPosition"))

  const chartOfAccountList=useSelector((state)=>state.chartOfAccountsSlice.value) 
  const journalList=useSelector((state)=>state.journalSlice.value)
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const editedData=useSelector((state)=>state.journalEntrySlice.editClickData);
  const generatedName=useSelector((state)=>state.journalEntrySlice.generateName);
  const allEmployeeList=useSelector((state)=>state.allInactiveEmployeeSlice.value);
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const userRole=useSelector((state)=>state.userRoleSlice.value)
  const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);



  const [validationAlert,setValidationAlert]=useState({})

  const [imageDisplay, setImageDisplay] = useState([]);
  const [editDataId,setEditDataId]=useState("")

  const [loadingForm, setLoadingForm] = useState(false);

  const customerVendorList=useSelector((state)=>state.Openingbalance.customerVendor)


  // predefined input fields value in table
  const tableDataInitial = {
    account: null,
    partner: null,
    employee: null,
    branch: null,
    reference:"",
    debit: "",
    credit: ""
  }
  const [tableData, setTableData] = useState([tableDataInitial]);


  // value to create which type of line
  const addRowData=["Add Line"]
  
  // input fields initial states
  const journalEntryFormInitialState={
    name:"###",
    journal:null,
    accountingDate:today,
    reference:"",
    debitTotal:0,
    creditTotal:0,
    customerNotes:"",
    termsAndConditions:"",

    status:"",
    isEdit:false
  }

  // useStates
  const [log,setLog]=useState([])
  const [formValues,setFormValues]=useState(journalEntryFormInitialState)
  const [addRowInput,setAddRowInput]=useState(addRowData[0])
  const [image,setImage]=useState([])
  const [logView,setLogView]=useState(false)
  const [isLoading,setIsLoading]=useState("")
  const [editClicked,setIsEditClicked]=useState(false)
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false
  });



  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };

  // function to add new line in table
  const [addRowScroll,setAddRowScroll]=useState(false)
  const addRow = () => {
      setTableData([...tableData, tableDataInitial]);
      setAddRowScroll(!addRowScroll)
    
  };
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [addRowScroll]);
  // function to delete a line in table

  const handleDelete=(index)=>{
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  }
  // onchange function of table inputs
  const handleInputChange = (id, field) =>(e,newValue)=> {

    const {value}=e.target
   
    const updatedData = tableData.map((row,index) => {
      if (index === id) {
        if(field==="debit"){
          return { ...row, [field]:e.target.value,credit:0 };
        }
        else if(field==="credit"){
          return { ...row, [field]:e.target.value,debit:0 };
        }
        else{
          return { ...row, [field]:(value === 0 || value === undefined )? newValue: e.target.value };
        }
      }
      return row;
    });
    setTableData(updatedData);
  };

  // onchange of image upload
  const changeImage = (e) => {
      setImage([...image, e.target.files[0]]);
      setImageDisplay([...imageDisplay, URL.createObjectURL(e.target.files[0])]);
  };

  const removeImageFn = (i) => {
    image.splice(i, 1);
    imageDisplay.splice(i, 1);
    setImage([...image]);
    setImageDisplay([...imageDisplay]);
  };

  // function to enable log view
  const clickLogView=()=>{
    setLogView(!logView)
  }

  // onchange function of form
  const getFormInfo=(key)=>(e,newValue)=>{
      const {value}=e.target;
      setValidationAlert({})
      if(key==="journal"){
        generateJournalEntryNameAPICall({journalId:newValue?._id})
      }
      setFormValues({...formValues,[key]:value!==0?value:newValue})
      
  }
  


  const tableDataToBackend = tableData?.filter(item => item?.account !== null)?.map((item =>({
    account: item?.account?._id,
    partner: item?.partner?._id,
    employee: item?.employee?._id,
    branch: item?.branch?._id,
    debit: Number(item?.debit),
    credit: Number(item?.credit)
  })))

 const formData=new FormData()

  /*required*/ formData.append("journal",formValues?.journal?._id)
  /*required*/ formData.append("accountingDate",`${formValues?.accountingDate} ${getCurrentTime()}`)
  formValues?.name!=="###" && formData.append("name",formValues?.name)
  formValues?.reference!=="" && formData.append("reference",formValues?.reference)
  image.length!==0 && image.forEach((file,index)=>{formData.append("file",file)}) 
  formValues?.customerNotes!=="" && formData.append("customerNote",formValues?.customerNotes)
  formValues?.termsAndConditions!=="" && formData.append("termsAndCondition",formValues?.termsAndConditions)
  /*required*/ formData.append("postDate",`${today} ${getCurrentTime()}`)
  /*required*/ formData.append("journalList",JSON.stringify(tableDataToBackend))
// cancel button function
const cancelButtonFunction=()=>{
  let draftFn=()=>{
    setFormValues({...formValues,status:"CANCEL"})
  }
  cancelRedrafteJournalEntriesAPICall({id:editDataId,statusType:1},draftFn,setSnackBarStates,snackBarStates,setLoadingForm)

}
const rebaseJournalEntryFunction=()=>{
  let draftFn=()=>{
    setFormValues({...formValues,status:"DRAFT"})
  }
  cancelRedrafteJournalEntriesAPICall({id:editDataId,statusType:2},draftFn,setSnackBarStates,snackBarStates,setLoadingForm)

}
  // submitting form and api calls
  const submitBtnClicked=(key)=>()=>{
    dispatch(setFilterActive(false))
    if(key==="post"){
      let draftFn=()=>{
        setFormValues({...formValues,isEdit:true,status:"Completed"})
        localStorage.setItem("singleViewById",editedData)
      }
      let postFormData=new FormData()
      postFormData.append("_id",editDataId)
      postFormData.append("postDate",`${today} ${getCurrentTime()}`)
  
      postJournalEntryAPICall(postFormData,draftFn,setSnackBarStates,snackBarStates,setLoadingForm)
    }
    if(formValues?.name==="###"){
        setValidationAlert({...validationAlert,name:"name not generated"})
    }
    else if(formValues?.journal===null){
        setValidationAlert({...validationAlert,journal:"select this field"})
    }
    else if(formValues?.creditTotal!==formValues?.debitTotal){
        setSnackBarStates({ ...snackBarStates, message: "Debit and credit must be equal !!", alert: true })
    }
    else if(tableDataToBackend?.length===0){
        setSnackBarStates({ ...snackBarStates, message: "Lines empty!!", alert: true })

    }
    else{
        if(key==="draft"){
          let draftFn=()=>{
            setFormValues({...formValues,isEdit:true,status:"DRAFT"})
          }
          draftJournalEntryAPICall(formData,draftFn,setSnackBarStates,snackBarStates,setEditDataId,setLoadingForm)
        }
        else if(key==="edit"){
          let draftFn=()=>{
            setFormValues({...formValues,isEdit:true,status:"DRAFT"})
            setIsEditClicked(false)
            localStorage.setItem("singleViewById",editedData?._id)
          }
          formData.append("_id",editDataId)
          editJournalEntryAPICall(formData,draftFn,setSnackBarStates,snackBarStates,setLoadingForm)
        }
    }
  }

  // function to set decimal position
  const handleMouseEnter = () => {
    const updatedArray = roundNumericFields(tableData, ["debit", "credit"]);
    setTableData(updatedArray);
  }

  
// initial api calls for listingdata used in dropdowns
  useEffect(()=>{
    chartOfAccountListApiCall()
    journalListApiCall()
    getAllInactiveEmployeesAPICall();
    if(singleViewId){
      JournalEntriesSingleViewApi({_id:singleViewId})
    }
    viewCustomerVendorAPICall()
  },[])



// generated name assigning in formvalues
  useEffect(()=>{
    if(editedData!==undefined){
      setFormValues({...formValues,name:editedData?.name})
    }
    else{
      setFormValues({...formValues,name:generatedName?.nameprefix!==undefined?generatedName?.nameprefix:"###"})
    }
  },[generatedName])



  // calculating debit and credit total
  useEffect(()=>{
    // setFormValues({...formValues,
    //   debitTotal:Number(editedData?.debitTot),
    //   creditTotal:Number(editedData?.creditTot)
    // })
    if(!(editedData?.status==="Completed")){
      setFormValues({...formValues,
        debitTotal:tableData?.reduce((sum,item)=>sum+Number(item?.debit||0),0),
        creditTotal:tableData?.reduce((sum,item)=>sum+Number(item?.credit||0),0)
      })
    }
  },[tableData])

  // fetching datas in edit case
  useEffect(()=>{
    userRole==="user"&& viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });
    if(editedData!==undefined && customerVendorList!==undefined){
      // isLoading==="" && setIsLoading(true)
      setEditDataId(editedData?._id)
      setFormValues({...formValues,
        isEdit:true,
        status:editedData?.status,

        journal:findObjFromList("_id",journalList,"journal",editedData),
        reference:editedData?.reference ||"",
        accountingDate:editedData?.accountingDate ||"",
        termsAndConditions:editedData?.termsAndCondition ||"",
        customerNotes:editedData?.customerNote ||"",
        name:editedData?.name,
        debitTotal:Number(editedData?.debitTot),
        creditTotal:Number(editedData?.creditTot)
      })

      const updatedArray=editedData?.journalList?.map((r,i)=>(
        {
          account:findObjFromList("_id",chartOfAccountList,"account",r),
          partner:findObjFromList("_id",customerVendorList,"partner",r),
          employee:findObjFromList("_id",allEmployeeList,"employee",r),
          branch:findObjFromList("_id",allBranchesList,"branch",r),
          credit:r?.credit,
          debit:r?.debit,
        }
      ))

      setTableData(updatedArray)

      setLog(editedData?.log || [])
    }else{
      setTableData([tableDataInitial])
      setFormValues(journalEntryFormInitialState)
    }
  },[editedData,
    chartOfAccountList,
    customerVendorList
  ])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !formValues?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [formValues?.isEdit]);
  return (
    <div className='global-page-parent-container'>
       <div className="new-global-white-bg-container" style={{padding:"0 2%"}}>
       <div className="new-global-white-bg-icon-container">
          {
            isLoading?
              <Skeleton
              sx={{marginTop:"1%"}}
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={40}
              />
            :
          <div 
          style={{display:"flex",alignItems:"center"}}
          className="new-global-white-bg-icon-container-left">
            <Tooltip title="Back"
            onClick={()=>{
              if (formValues?.isEdit === false) {
                const userConfirmed = window.confirm("Changes you made may not be saved.");
                
                if (userConfirmed) {
                  localStorage.removeItem("singleViewById")
                  navigateTo("/userdashboard/accounts/accounting/journalEntries")
                  dispatch(set_Back_button_action({ backButtonClickAction: true }));
                }
            }
            else{
              navigateTo("/userdashboard/accounts/accounting/journalEntries")
              dispatch(set_Back_button_action({ backButtonClickAction: true }));
            }
            }
            
            }>
              <IconButton>
                <i class="bi bi-chevron-left"></i>
              </IconButton>
            </Tooltip>
            {
              formValues?.status==="DRAFT" &&
            <Tooltip title="Edit">
              <IconButton 
              onClick={editClicked?submitBtnClicked("edit"):
                                  ()=>{
                                  setFormValues({...formValues,isEdit:false})
                                  setIsEditClicked(true)
                                }}>
                <i class={editClicked?"bi bi-check2-square edit-icon":"bi bi-pencil-square edit-icon1"}></i>
              </IconButton>
            </Tooltip>
            }

            {
              formValues?.status==="" &&
            <Tooltip title="Save">
              <IconButton 
                onClick={submitBtnClicked("draft")}
              >
                <i class="bi bi-floppy save-icon"></i>
              </IconButton>
            </Tooltip>
            }
            {
              formValues?.status==="CANCEL" &&
            <Tooltip title="Reset to draft">
              <IconButton 
                onClick={rebaseJournalEntryFunction}
              >
                <i class="bi bi-floppy save-icon"></i>
              </IconButton>
            </Tooltip>
            }
            {
              formValues?.status==="DRAFT" && !editClicked &&
              <button style={{ cursor: "pointer", width: "80px",height:"30px",fontSize:"0.8rem" }} 
              onClick={cancelButtonFunction} 
              className='gray-status' >Cancel</button>
            }
            {
              formValues?.status==="DRAFT" && !editClicked &&
              <button
              onClick={submitBtnClicked("post")}
              className="btn btn-post">Post</button>
            }
          </div>}
          <div className="status-container">
            {  formValues?.status==="DRAFT" && <img src={draftRibbon} alt="" />}
            { formValues?.status==="Completed" && <img src={postRibbon} alt="" />}
            {formValues?.status === "CANCEL" && <img src={cancelRibbon} alt="" />} 
            
          </div>
        </div>
         <hr className='global-hr'/>
         <h4 className='global-name'>{formValues?.name || "###"}</h4>
        { 
        isLoading?
        <Skeleton
        sx={{margin:"1% 0"}}
          variant="rectangular"
          animation="wave"
          width={"100%"}
          height={110}
        />
      :
        <div className='new-global-input-container'>
          <div className='new-global-single-input auto-complete-new'>
            <Autocomplete
               disablePortal
               id="combo-box-demo"
               options={journalList || []}
               getOptionLabel={(option)=>option?.journalName}
               sx={{ width: 300 }}
               renderInput={(params) => <TextField {...params} label="Journal*" focused />}
               value={formValues?.journal}
               onChange={getFormInfo("journal")}
              disabled ={formValues?.isEdit}
/>
          </div>
          <div className='new-global-single-input'>
            <TextField
              value={formValues?.accountingDate}
              onChange={getFormInfo("accountingDate")}
              id="outlined-basic"
              label="Accounting Date*"
              variant="outlined"
              type="date"
              disabled ={formValues?.isEdit}
              focused />
          </div>
          <div className='new-global-single-input'>
            <TextField
              value={formValues?.reference}
              onChange={getFormInfo("reference")}
              id="outlined-basic"
              label="Reference*"
              variant="outlined"
              type="text"
              disabled ={formValues?.isEdit}
              focused />
          </div>
        
         
         </div>}
       </div>
       <div className="new-global-white-bg-container" 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseEnter}>
       
            {isLoading?
              <Skeleton
              sx={{marginTop:"1%"}}
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={110}
              />
              :<>
             <div className='global-product-table'>
                <table>
                   <thead>
                      <tr>
                        <th>Accounting</th>
                        <th>Partner</th>
                        <th>Employee</th>
                        <th>Branch</th>
                        <th>Reference</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th style={{backgroundColor:"#fff"}}></th>
                      </tr>
                   </thead>
              
                   <tbody>
                   {tableData?.map((item, index) => (
            <tr key={index} ref={index === tableData.length - 1 ? containerRef : null}>
              {item.type === 'header' ? (
                <>
                <td colspan={6}>
                  <input type='text'
                   value={item?.value}
                    onChange={handleInputChange(index,"value")}
                   />
                </td>
                <td  style={{border:"none",width:"4%"}}>
                <IconButton onClick={() => handleDelete(index)} style={{height:"30px"}}>
                  <i className="bi bi-trash3" style={{color:"#db0000",fontSize:"1.3rem"}}></i>
                </IconButton>
                </td>
                </>
              ) : (
                  <>
                             <td style={{ minWidth: "160px" }}>
                               <div className='product-table-auto-complete'>
                                 <Autocomplete
                                   options={chartOfAccountList || []}
                                   style={{ maxHeight: '100px' }}
                                   getOptionLabel={(option) => option?.accountName}
                                   renderInput={(params) => <TextField {...params} />}
                                   value={item.account}
                                   onChange={handleInputChange(index, 'account')}
                                   disabled ={formValues?.isEdit}
                                 />
                               </div>
                             </td>
                             <td style={{ minWidth: "155px" }}>
                               <div className='product-table-auto-complete'>
                                 <Autocomplete
                                   options={ customerVendorList || []}
                                   style={{ maxHeight: '100px' }}
                                   getOptionLabel={(option) => option?.name}
                                   renderInput={(params) => <TextField {...params} />}
                                   value={item.partner}
                                   onChange={handleInputChange(index, 'partner')}
                                   disabled ={formValues?.isEdit}
                                 />
                               </div>
                             </td>
                             <td style={{ minWidth: "155px" }}>
                               <div className='product-table-auto-complete'>
                                 <Autocomplete
                                   options={allEmployeeList || []}
                                   style={{ maxHeight: '100px' }}
                                   getOptionLabel={(option) => option?.staff_name}
                                   renderInput={(params) => <TextField {...params} />}
                                   value={item.employee}
                                   onChange={handleInputChange(index, 'employee')}
                                   disabled ={formValues?.isEdit}
                                 />
                               </div>
                             </td>
                             <td style={{ minWidth: "155px" }}>
                               <div className='product-table-auto-complete'>
                                 <Autocomplete
                                  options={userRole==="admin" ? allBranchesList: allowedBranchList || []}
                                   style={{ maxHeight: '100px' }}
                                   getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}
                                   renderInput={(params) => <TextField {...params} />}
                                   value={item.branch}
                                   onChange={handleInputChange(index, 'branch')}
                                   disabled ={formValues?.isEdit}
                                 />
                               </div>
                             </td>

                          <td style={{maxWidth:"75px"}}>
                            <input type="text" 

                            value={item?.reference}
                            onChange = {handleInputChange(index,'reference')}
                            disabled ={formValues?.isEdit}
                            />

                          </td>


                            <td style={{maxWidth:"75px"}}>
                            <input type="text" 
                              value={item.debit} 
                              onChange={handleInputChange(index, 'debit')} 
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                              disabled ={formValues?.isEdit}
                              onFocus={(e)=>e.target.select()}
                            />
                            </td>
                            
                            <td style={{maxWidth:"75px"}}>
                            <input type="text" 
                              value={item.credit} 
                              onChange={handleInputChange(index, 'credit')}
                              disabled ={formValues?.isEdit}
                              onFocus={(e)=>e.target.select()}
                            />
                            </td>
                           {
                            !(formValues?.status==="Completed") &&            
                           <td  style={{border:"none",width:"4%"}}>
                              <IconButton
                              disabled ={formValues?.isEdit} 
                              onClick={() => handleDelete(index)} 
                              style={{height:"30px"}}>
                                <i className="bi bi-trash3" style={{color:"#db0000",fontSize:"1.3rem"}}></i>
                             </IconButton>
                           </td>}
                            </>
                
               
              )}
            </tr>
          ))}
                <tr>
                  <td style={{ border: "0" }} colSpan={4} ></td>
                  <td style={{ border: "0", textAlign: "end",fontSize:"0.8rem",fontWeight:"600" }}>Total :</td>
                  <td style={{ border: "0", textAlign: "start" }}>{formValues?.debitTotal?.toFixed(decimalPosition)}</td>
                  <td style={{ border: "0", textAlign: "start" }}>{formValues?.creditTotal?.toFixed(decimalPosition)}</td>
                </tr>
                   </tbody>
                </table>
             
             </div>
            {
            !(formValues?.status==="Completed") &&
            <div className='add-row-btn-container'>
                 <div className='global-single-input auto-complete add-line-autocomplete' style={{width:"76%"}}>
              <Autocomplete
                options={addRowData||[]}
                getOptionLabel={(option) => option}
                renderInput={(params) => <TextField {...params} />}
                value={addRowInput}
                // onChange={(e,newValue)=>setAddRowInput(newValue)}
                renderClear={() => null}
              />
              </div>
              <button disabled={formValues?.isEdit} onClick={()=>addRow()} className='add-row-btn'>+</button>
            </div>}
            
            
            </>}
          
          
       </div>
       <div className="new-global-white-bg-container" style={{display:"flex",flexDirection:"row",gap:"5px"}}>
                
          {isLoading?
              <Skeleton
              sx={{marginTop:"1%"}}
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={100}
              />
            :<>
          <div style={{width:"40%"}}>
          <div className='new-global-single-input' style={{width:"100%"}}>
            <p>Customer Notes</p>
            <textarea
            value={formValues?.customerNotes}
            onChange={getFormInfo("customerNotes")}
            name="" id="" cols="50" rows="5"
            disabled ={formValues?.isEdit}
            ></textarea>
          </div>
          <div style={{display:"flex",width:"60%",flexDirection:"column"}}>
            <div className='file-picker-new' style={{margin:"0 8px"}}>
              <input 
              type="file" 
              id="fileUpload" 
              onChange={changeImage}
              disabled ={formValues?.isEdit}/>
              <label htmlFor="fileUpload">
               <img src={uploadFile} alt="" />
               Upload File
             
             </label>
           </div>
           <div style={{display:"flex",margin:"0 8px"}}>
            
                {
                    imageDisplay?.map((r,i)=>(
                  <div className='uploaded-image-list' >
                
                    
                    <img src={r} alt="" />
                    <div className='image-remove-icon-new'>
                    <IconButton disabled={formValues?.isEdit}
                       onClick={()=>removeImageFn(i)} >
                       <CloseOutlined sx={{ color: "#fd0301",fontSize:"0.9rem"}} />
                    </IconButton>
                    </div>
                   
                 
                  </div>
                   ))
                }
                
             
           </div>
          </div>
          </div>
          <div style={{width:"40%"}}>
          <div className='new-global-single-input' style={{width:"100%"}}>
            <p>Terms & Conditions</p>
            <textarea 
            value={formValues?.termsAndConditions}
            onChange={getFormInfo("termsAndConditions")}
            style={{minHeight:"105px"}}
            disabled ={formValues?.isEdit}
            name="" id="" cols="100" rows="5"></textarea>
          </div>
          </div>
          </>}
         
         
       </div>
       {
        editedData!==undefined &&
        <div className="new-global-white-bg-container new-log-container" style={{padding:"4px"}}>
       <div style={{padding:"8px",cursor:"pointer"}} onClick={clickLogView}></div>
       <div className={ logView ? 'pointer-up':"pointer"} onClick={clickLogView} style={{cursor:"pointer"}}></div>
        {
          logView &&(
         <div className='single-log-container' >
          {
            log?.map((r,i)=>(
              <div className='single-log-item'>
              <div className='single-log-item-line-image'>
                 <hr className='single-log-item-line'/>
                 <div className='single-log-item-image'>
                    <img src={logoImage} alt="" />
                 </div>
                 <hr className='single-log-item-line'/>
              </div>
              <p className='heading'>{r?.status}</p>
              <p className='name'>{r?.empName}</p>
              <p className='date-time'>{r?.date}</p>
           </div>
            ))
          }
          
           
         </div>
          )
        }
         
       </div>}

       <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
              style={{ top: "7%", left: "50%", height: "20px", width: "auto",transform:"translateX(-50%)",position:"absolute" }}
              open={snackBarStates.alert}
              handleClose={closeSnackbar}
              message={snackBarStates.message}
          />
        
        <LoadingForm loading={loadingForm} />
    </div>
  )
}
