import React from "react";
import { useSelector } from "react-redux";

function BillTypeSummary({ showSubHeaders }) {
  const ShiftBillTypeSummary = useSelector(
    (state) => state.posReportshiftSlice.billTypeSummary
  );
  const decimalPosition = localStorage.getItem("decimalPosition");
  const getBackgroundColor = (index) => {
    return index % 2 === 0 ? "#ffffff" : "#E6E6E6";
  };
  const deliveryTotal = (ShiftBillTypeSummary?.selfDelivery?.total || 0) + (ShiftBillTypeSummary?.aggregator?.total|| 0)

  return (
    <div
      className="new-global-table-container"
      style={{ paddingTop: "0 42px" }}
    >
      <div className="bill-main-head">
        <h5>Bill Type</h5>
        <h5>Amount</h5>
      </div>
      <div
        className="bill-sub-head"
        style={{
          backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(0),
        }}
      >
        <h5>Dine In</h5>
        <h5>
          {ShiftBillTypeSummary?.dineIn?.total?.toFixed(decimalPosition) || 0.0}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead style={{ backgroundColor: "#C0CFF8" }}>
            <tr>
              <th>Bill No</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.dineIn?.list !== undefined ? (
              ShiftBillTypeSummary?.dineIn?.list?.map((r, i) => (
                <tr key={i}>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.billNo}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.date}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.amount}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div
        className="bill-sub-head"
        style={{
          backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(1),
        }}
      >
        <h5>Take Away</h5>
        <h5>
          {ShiftBillTypeSummary?.takeAway?.total?.toFixed(decimalPosition) ||
            0.0}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead style={{ backgroundColor: "#C0CFF8" }}>
            <tr>
              <th>Bill No</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.takeAway?.list !== undefined ? (
              ShiftBillTypeSummary?.takeAway?.list?.map((r, i) => (
                <tr key={i}>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.billNo}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.date}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.amount}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div
        className="bill-sub-head"
        style={{
          backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(2),
        }}
      >
        <h5>Delivery</h5>
        <h5>
          {deliveryTotal==0?0:deliveryTotal?.toFixed(decimalPosition)}
        </h5>
      </div>
      {showSubHeaders && (
        <div className="bill-sub2-head">
          <h5>Self Delivery</h5>
        </div>
      )}
      {showSubHeaders && (
        <table>
          <thead style={{ backgroundColor: "#C0CFF8" }}>
            <tr>
              <th>Bill No</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.selfDelivery?.list !== undefined ? (
              ShiftBillTypeSummary?.selfDelivery?.list?.map((r, i) => (
                <tr key={i}>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.billNo}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.date}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.amount}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      {showSubHeaders && (
        <div className="bill-sub2-head">
          <h5>Aggregator</h5>
        </div>
      )}
      {showSubHeaders && (
        <table>
          <thead style={{ backgroundColor: "#C0CFF8" }}>
            <tr>
              <th>Aggregator</th>
              <th>Order ID</th>
              <th>Date</th>
              <th>Discount</th>
              <th>Commission</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.aggregator?.list !== undefined ? (
              ShiftBillTypeSummary?.aggregator?.list?.map((r, i) => (
                <tr key={i}>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.aggregatorName}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.billNo}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.date}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.discount}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.commission}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.amount}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div
        className="bill-sub-head"
        style={{
          backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(3),
        }}
      >
        <h5>Staff Expense</h5>
        <h5>
          {ShiftBillTypeSummary?.staffExpense?.total?.toFixed(
            decimalPosition
          ) || 0.0}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead style={{ backgroundColor: "#C0CFF8" }}>
            <tr>
              <th>Expense</th>
              <th>Employee Name</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.staffExpense?.list !== undefined ? (
              ShiftBillTypeSummary?.staffExpense?.list?.map((r, i) => (
                <tr key={i}>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.expense}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.employee}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.date}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.amount}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div
        className="bill-sub-head"
        style={{
          backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(4),
        }}
      >
        <h5>Outlet Expense</h5>
        <h5>
          {ShiftBillTypeSummary?.outletExpense?.total?.toFixed(
            decimalPosition
          ) || 0.0}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead style={{ backgroundColor: "#C0CFF8" }}>
            <tr>
              <th>Shift ID</th>
              <th>Doc No</th>
              <th>Expense</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.outletExpense?.list !== undefined ? (
              ShiftBillTypeSummary?.outletExpense?.list?.map((r, i) => (
                <tr key={i}>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.shift}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.docNo}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.expense}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.date}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.amount}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div
        className="bill-sub-head"
        style={{
          backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(5),
        }}
      >
        <h5>Refund</h5>
        <h5>
          {ShiftBillTypeSummary?.refund?.total?.toFixed(decimalPosition) || 0.0}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead style={{ backgroundColor: "#C0CFF8" }}>
            <tr>
              <th>Order ID</th>
              <th>Product</th>
              <th>Type</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.refund?.list !== undefined ? (
              ShiftBillTypeSummary?.refund?.list?.map((r, i) => (
                <tr key={i}>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.billNo}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.product}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.type}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.date}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.amount}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div
        className="bill-sub-head"
        style={{
          backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(6),
        }}
      >
        <h5>Cash & Card</h5>
        <h5>
          {ShiftBillTypeSummary?.cashAndCard?.total?.toFixed(decimalPosition) ||
            0.0}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead style={{ backgroundColor: "#C0CFF8" }}>
            <tr>
              <th>Payment Method</th>
              <th>Amount</th>
              <th>Commission</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.cashAndCard?.list !== undefined ? (
              ShiftBillTypeSummary?.cashAndCard?.list?.map((r, i) => (
                <tr key={i}>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.type}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.amount}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.commission}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.balance}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default BillTypeSummary;
