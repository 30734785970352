import { createSlice } from "@reduxjs/toolkit";

export const billingScreenSlice=createSlice({
    name:"billingScreenSlice",
    initialState:{
        singleOrderInfo:null,
        orderIdForPos:null,
        successSnack:false,
        errorSnack:false,
        snackMsg:"",
        dialogOpen:null,
        billLoading:false,
        cartToBackend:null,
        updatedOrder:false,
        branchId:null,
        categoryLoading:false,
        posCategoryLoading:false,
        selectedPOSCategory:null,
        pageIndex:0,
        pagination:false,
        kotSingleData:null
    },
    reducers:{
        setOrderIdInPOS:(state,action)=>{
            state.orderIdForPos=action.payload
        },
        setSingleOrderInfo:(state,action)=>{
            state.singleOrderInfo=action.payload
        },
        setSuccessSnack:(state,action)=>{
            state.successSnack=action.payload
        },
        setErrorSnack:(state,action)=>{
            state.errorSnack=action.payload
        },
        setSnackMsg:(state,action)=>{
            state.snackMsg=action.payload
        },
        setBillLoading:(state,action)=>{
            state.billLoading=action.payload
        },
        setDialogBox:(state,action)=>{
            state.dialogOpen=action.payload
        },
        setCartTOBackend:(state,action)=>{
            state.cartToBackend=action.payload
        },
        setUpdateOrder:(state,action)=>{
            state.updatedOrder=action.payload
        },
        setPOSBranchId:(state,action)=>{
            state.branchId=action.payload
        },
        setCategoryLoading:(state,action)=>{
            state.categoryLoading=action.payload
        },
        setPosCategoryLoading:(state,action)=>{
            state.posCategoryLoading=action.payload
        },
        setSelectedPosCat:(state,action)=>{
            state.selectedPOSCategory=action.payload
        },
        setPageIndex:(state,action)=>{
            state.pageIndex=action.payload
        },
        setPagination:(state,action)=>{
            state.pagination=action.payload
        },
        setKOTSingleInfo:(state,action)=>{
            state.kotSingleData=action.payload
        }

    }
})

export const {setOrderIdInPOS,
    setSuccessSnack,
    setErrorSnack,
    setSnackMsg,
    setDialogBox,
    setBillLoading,
    setCartTOBackend,
    setSingleOrderInfo,
    setUpdateOrder,
    setPOSBranchId,
    setCategoryLoading,
    setPosCategoryLoading,
    setSelectedPosCat,
    setPageIndex,
    setPagination,
    setKOTSingleInfo
}=billingScreenSlice.actions

export default billingScreenSlice.reducer