import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { today } from "../../../../../Js/Date";
import draftRibbon from "../../../../../Assets/statusRibbon/draft.png";
import logoImage from "../../../../../Assets/Images/Icon ionic-logo-buffer.png";
import "./PriceListStyle.css";
import { useRef } from "react";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { useSelector } from "react-redux";
import { ViewCustomerGroupListApiCall } from "../../../Accounts/Vendor/API/vendorListAPICall";
import { savePriceListApi, singleViewPriceListApi } from "./priceListAPI";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";

const CreatePriceList = () => {
  const containerRef = useRef(null);
  let singleViewId = localStorage.getItem("singleViewById");

  // price scheme
  let priceScheme = {
    BUYNGETN: "buyngetn",
    DISCOUNT: "discount",
    UNITPRICING: "unitpricing",
    VOLUMEPRICING: "volumepricing",
  };

  // redux state
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const {getApiSaveDataResult ,singleViewData } = useSelector(
    (state) => state.priceListSlice
  );
  const allCustomerGroupList = useSelector((state) => state.vendorsListSlice?.CustomerGroupList)

  // initial api calls
  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    ViewCustomerGroupListApiCall();

    
    if(singleViewId){
      singleViewPriceListApi({id:singleViewId})
    }
  }, []);
 

  //navigate
  let navigate = useNavigate();

  const backButtonFn = () => {
    if (priceListForm?.isEdit === false) {
      const userConfirmed = window.confirm("Changes you made may not be saved.");
      
      if (userConfirmed) {
        localStorage.removeItem('singleViewById');
    navigate("/userdashboard/sales/orders/pricelist");
      }
  }
  else{
    localStorage.removeItem('singleViewById');
    navigate("/userdashboard/sales/orders/pricelist");
  }
    
  };

  // console.log("percentage", percentage);
  // console.log("mainCategory", mainCategory);
  const landedCostInitialState = {
    applyType: 0,
    priceScheme: 0,
    name: null,
    currency: null,
    transactionType: [
      {
        sale: false,
        pointOfSale: false,
      },
    ],
    customerGroup: null,
    applyOnDate: today,
    status: "",
    isEdit: false,
  };
  const [mainCategory, setMainCategory] = useState("");
  const [priceListForm, setPriceListForm] = useState(landedCostInitialState);
  const [editClicked, setEditClicked] = useState(false);
  const [validationAlert, setValidationAlert] = useState({});
  const addRowData = [{ value: "Add Line" }];
  const [addRowInput, setAddRowInput] = useState(addRowData[0]);
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("price list added!!");



  const [logView, setLogView] = useState(false);
  const log = [
    { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
    { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
    { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
    { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
    { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
    { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
    { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
    { name: "munshid", type: "rfq", date: "27-10-2023 15:48" },
  ];

  const [tableData, setTableData] = useState([
    {
      type: "lines",
    },
  ]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [tableData]);



  // handle field change
  const handleFieldChange = (value, key, subgroup) => {
    setValidationAlert ({...validationAlert,[key]: value? "":"fill this field"})
    if (key === "transactionType") {
      // Update the corresponding subgroup property based on the value
      setPriceListForm((pre) => ({
        ...pre,
        transactionType: pre.transactionType.map((group) =>
          subgroup in group ? { ...group, [subgroup]: value } : group
        ),
      }));
    } else {
      setPriceListForm((pre) => ({ ...pre, [key]: value }));
    }
  };

  //handle table field update
  const handleUpdateTable = (index, dataKey, newValue) => {
    let tempArray = [...tableData];
    if (!tempArray[index]) return;
    tempArray[index][dataKey] = newValue;
    setTableData(tempArray);
  };

  const getInitialRow = () => {
    switch (`${priceListForm.applyType}-${priceListForm.priceScheme}`) {
      case "0-0":
        return { buyN: "", getN: "" };
      case "0-1":
        return { percentage: "" };
      case "1-2":
        return { product: "", salesPrice: "", newRate: "", discount: "" };
      case "1-3":
        return {
          product: "",
          salesPrice: "",
          newRate: "",
          discount: "",
          startQuantity: "",
          endQuantity: "",
        };
      case "1-4":
        return { product: "", buyN: "", getN: "" };
      case "2-5":
        return { product: "", buyN: "", getN: "", category: "" };
      case "2-6":
        return {
          category: "",
          startQuantity: "",
          endQuantity: "",
          mainCategory: "",
        };
      default:
        return {};
    }
  };

  const addRow = (addedNewRaw) => {
    if (addRowInput?.value !== "Add Line") {
      return;
    }

    const newRow = getInitialRow();
    const newType = "lines";

    if (!addedNewRaw) {
      const lastRowIndex = tableData.findIndex((row) => row.type === newType);
      if (lastRowIndex !== -1) {
        const newTableData = [...tableData];
        newTableData[lastRowIndex] = { type: newType, ...newRow };
        setTableData(newTableData);
      } else {
        setTableData((prev) => [...prev, { type: newType, ...newRow }]);
      }
    } else {
      setTableData((prev) => [...prev, { type: newType, ...newRow }]);
    }
  };

  useEffect(() => {
    setTableData([{ type: "lines", ...getInitialRow() }]);
  }, [priceListForm.applyType, priceListForm.priceScheme]);

  //handle delete row from table
  const handleDeleteRow = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  // =============================
  const editBtnEnableFn = () => {
    setPriceListForm({ ...priceListForm, isEdit: false });
    setEditClicked(true);
  };
  const editSaveFn = () => {
    // console.log("edit save function called ");
    // if (priceListForm?.branch === null) {
    //   setValidationAlert({ ...validationAlert, branch: "fill this field" });
    // } else if (priceListForm?.date === null) {
    //   setValidationAlert({ ...validationAlert, wareHouse: "fill this field" });
    // } else if (priceListForm?.grn === null) {
    //   setValidationAlert({ ...validationAlert, sLocation: "fill this field" });
    // } else if (priceListForm?.vendor === null) {
    //   setValidationAlert({ ...validationAlert, dLocation: "fill this field" });
    // } else if (priceListForm?.journal === null) {
    //   setValidationAlert({ ...validationAlert, dLocation: "fill this field" });
    // } else {
    //   let sendData = {
    //     branchId: priceListForm.branch.storeCode,
    //     date: priceListForm.date,
    //     vendorId: priceListForm.vendor._id,
    //     grnId: priceListForm.grn._id,
    //     journalId: priceListForm.journal._id,
    //     isInventory: priceListForm.isInventory,
    //     postDate: today,
    //     extraCharge: tableData,
    //     id: priceListForm.id,
    //   };
    //   editLcAPI(sendData);
    // }
    // setEditClicked(false);
    // setpriceListForm({ ...priceListForm, isEdit: true });
  };

  //save
  const saveLandedCostFunc = () => {
    if (priceListForm?.name === null) {
      setValidationAlert({ ...validationAlert, name: "fill this field" });
    } else if (priceListForm?.currency === null) {
      setValidationAlert({ ...validationAlert, currency: "fill this field" });
    } else if (priceListForm?.customerGroup === null) {
      setValidationAlert({ ...validationAlert, customerGroup: "fill this field" });
    } else {
      let handleSubmitForm = {
        ...priceListForm,
        currency:priceListForm?.currency?._id,
        customerGroup:priceListForm?.customerGroup?._id,
        priceSchemeType:
          priceListForm.priceScheme == 0
            ? priceScheme.BUYNGETN
            : priceListForm.priceScheme == 1
            ? priceScheme.DISCOUNT
            : priceListForm.priceScheme == 2
            ? priceScheme.UNITPRICING
            : priceListForm.priceScheme == 3
            ? priceScheme.VOLUMEPRICING
            : priceListForm.priceScheme == 4
            ? priceScheme.BUYNGETN
            : priceListForm.priceScheme == 5
            ? priceScheme.BUYNGETN
            : priceListForm.priceScheme == 6
            ? priceScheme.VOLUMEPRICING
            : "",
  
        offerInfo: tableData.map((data, index) => {
          let sortedObj;
          if (priceListForm.applyType == 2) {
            sortedObj = { ...data, mainCategory };
          } else {
            sortedObj = data;
          }
          return sortedObj;
        }),
        postDate:today
  
      };
      console.log("handleSubmitForm", handleSubmitForm);
      savePriceListApi(handleSubmitForm,setSnackMsg,setSuccessSnackOpen,setErrorSnackOpen)
    }

   
  };

 
  // snackbar ********
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };

  const closeErrorSnackbar = () => {
    setErrorSnackOpen(false);
  };

  //log
  const clickLogView = () => {
    setLogView(!logView);
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !priceListForm?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [priceListForm?.isEdit]);
  return (
    <div className="global-page-parent-container">
      {/* input field */}
      <div
        className="new-global-white-bg-container"
        style={{ padding: "0 2%" }}
      >
        <div className="new-global-white-bg-icon-container">
          <div className="new-global-white-bg-icon-container-left">
            <Tooltip title="Back" onClick={backButtonFn}>
              <IconButton>
                <i class="bi bi-chevron-left"></i>
              </IconButton>
            </Tooltip>
            {/* edit */}
            {priceListForm.status === "DRAFT" && (
              <Tooltip title="Edit">
                <IconButton
                  onClick={
                    priceListForm?.isEdit === true
                      ? editBtnEnableFn
                      : editSaveFn
                  }
                >
                  {priceListForm?.isEdit === true ? (
                    <i class="bi bi-pencil-square edit-icon1"></i>
                  ) : (
                    <i
                      class="bi bi-check2-square edit-icon"
                      style={{ fontSize: "1.5rem" }}
                    ></i>
                  )}
                </IconButton>
              </Tooltip>
            )}
            {/* save */}

            {priceListForm.status === "" && (
              <Tooltip title="Save" onClick={saveLandedCostFunc}>
                <IconButton>
                  <i class="bi bi-floppy save-icon"></i>
                </IconButton>
              </Tooltip>
            )}
            {/* post */}

            {/* {priceListForm.status === "DRAFT" && !editClicked && (
              <button onClick={postLandedCost} className="btn btn-post">
                Confirm
              </button>
            )} */}
          </div>
          {/* ribbon  */}
          {/* <div className="status-container">
            <img
              src={
                priceListForm.status === ""
                  ? Banner
                  : priceListForm.status === "DRAFT"
                  ? draftRibbon
                  : priceListForm.status === "POST"
                  ? ""
                  : ""
              }
              alt=""
            />
          </div> */}
        </div>
        <hr className="global-hr" />
        {/* main  */}
        <div className="new-global-input-container">
          {/* name */}
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Name*"
              variant="outlined"
              type="text"
              focused
              value={priceListForm?.name}
              onChange={(e) => handleFieldChange(e.target.value, "name")}
              disabled={priceListForm?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.name}</p>

          </div>
          {/* Currency */}
          <div className="new-global-single-input auto-complete-new">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={currencyListUpdated || []}
              sx={{ width: 300 }}
              getOptionLabel={(option) => `${option?.currencyCode}`}
              renderInput={(params) => (
                <TextField {...params} label="Currency*" focused />
              )}
              value={priceListForm?.currency}
              onChange={(e, v) => handleFieldChange(v, "currency")}
              disabled={priceListForm?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.currency}</p>
          </div>
          {/* customer group */}
          <div className="new-global-single-input auto-complete-new">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={allCustomerGroupList || []}
              sx={{ width: 300 }}
              getOptionLabel={(option) => `${option?.customerGroup}`}
              renderInput={(params) => (
                <TextField {...params} label="Customer Group*" focused />
              )}
              value={priceListForm?.customerGroup}
              onChange={(e, v) => handleFieldChange(v, "customerGroup")}
              disabled={priceListForm?.isEdit}
            />
            <p className="doc-validation-alert">
              {validationAlert?.customerGroup}
            </p>
          </div>
          {/* transaction type */}
          <div
            className="new-global-single-input auto-complete-new price-list-transaction"
            style={{ margin: "8px" }}
          >
            <p>Transaction Type</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label
                  htmlFor="sale"
                  style={{
                    fontSize: "0.9rem",
                    marginTop: "4px",
                    cursor: "pointer",
                  }}
                >
                  Sale
                </label>
                <input
                  type="checkbox"
                  id="sale"
                  checked={priceListForm?.transactionType[0].sale === true}
                  onChange={() =>
                    handleFieldChange(
                      !priceListForm?.transactionType[0].sale,
                      "transactionType",
                      "sale"
                    )
                  }
                  // checked={formValues?.posAccounts===true}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label
                  htmlFor="point-of-sale"
                  style={{
                    fontSize: "0.9rem",
                    marginTop: "4px",
                    cursor: "pointer",
                  }}
                >
                  Point Of Sale
                </label>
                <input
                  type="checkbox"
                  id="point-of-sale"
                  checked={
                    priceListForm?.transactionType[0].pointOfSale === true
                  }
                  onChange={() =>
                    handleFieldChange(
                      !priceListForm?.transactionType[0].pointOfSale,
                      "transactionType",
                      "pointOfSale"
                    )
                  }
                  // checked={formValues?.posAccounts===true}
                />
              </div>
            </div>
          </div>
          {/* start date */}
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Start Date*"
              variant="outlined"
              type="date"
              focused
              value={priceListForm?.applyOnDate}
              onChange={(e) => handleFieldChange(e.target.value, "applyOnDate")}
              disabled={priceListForm?.isEdit}
            />
          </div>
          {/* apply on  */}
          <div className="apply-input-radio-container">
            <p className="heading">Apply on</p>
            <ul>
              <li>
                <input
                  checked={priceListForm.applyType == 0}
                  onChange={() => handleFieldChange(0, "applyType")}
                  id="all-products"
                  type="radio"
                />
                <label htmlFor="all-products">All Products</label>
              </li>
              <li>
                <input
                  checked={priceListForm.applyType == 1}
                  onChange={() => handleFieldChange(1, "applyType")}
                  id="individual-product"
                  type="radio"
                />
                <label htmlFor="individual-product">Individual Product</label>
              </li>
              <li>
                <input
                  checked={priceListForm.applyType == 2}
                  onChange={() => handleFieldChange(2, "applyType")}
                  id="category"
                  type="radio"
                />
                <label htmlFor="category">Category</label>
              </li>
            </ul>
            <div>
              <p className="heading">Price Scheme</p>
              <ul>
                {/* all products */}
                {priceListForm.applyType == 0 && (
                  <>
                    <li>
                      <input
                        checked={priceListForm.priceScheme == 0}
                        id="buyN1"
                        onChange={() => handleFieldChange(0, "priceScheme")}
                        type="radio"
                      />
                      <label htmlFor="buyN1">Buy N Get N</label>
                    </li>
                    <li>
                      <input
                        checked={priceListForm.priceScheme == 1}
                        id="discount"
                        onChange={() => handleFieldChange(1, "priceScheme")}
                        type="radio"
                      />
                      <label htmlFor="discount">Discount</label>
                    </li>
                    {priceListForm.priceScheme == 1 && (
                      <li>
                        <div className="new-global-single-input category-input">
                          <TextField
                            id="outlined-basic"
                            label="Percentage*"
                            variant="outlined"
                            type="text"
                            focused
                            value={tableData[0]?.percentage}
                            onChange={(e) =>
                              setTableData([
                                {
                                  ...tableData[0],
                                  type: null,
                                  percentage: e.target.value,
                                },
                              ])
                            }
                            disabled={priceListForm?.isEdit}
                          />
                        </div>
                      </li>
                    )}
                  </>
                )}
                {/* individual products */}
                {priceListForm.applyType == 1 && (
                  <>
                    <li>
                      <input
                        checked={priceListForm.priceScheme == 2}
                        id="unit-price"
                        onChange={() => handleFieldChange(2, "priceScheme")}
                        type="radio"
                      />
                      <label htmlFor="unit-price">Unit Pricing</label>
                    </li>
                    <li>
                      <input
                        checked={priceListForm.priceScheme == 3}
                        id="volume-price1"
                        onChange={() => handleFieldChange(3, "priceScheme")}
                        type="radio"
                      />
                      <label htmlFor="volume-price1">Volume Pricing</label>
                    </li>
                    <li>
                      <input
                        checked={priceListForm.priceScheme == 4}
                        id="buyNGetN"
                        onChange={() => handleFieldChange(4, "priceScheme")}
                        type="radio"
                      />
                      <label htmlFor="buyNGetN">Buy N Get N</label>
                    </li>
                  </>
                )}
                {/*category*/}
                {priceListForm.applyType == 2 && (
                  <>
                    <li>
                      <input
                        checked={priceListForm.priceScheme == 5}
                        id="buyN2"
                        onChange={() => handleFieldChange(5, "priceScheme")}
                        type="radio"
                      />
                      <label htmlFor="buyN2">Buy N Get N</label>
                    </li>
                    <li>
                      <input
                        checked={priceListForm.priceScheme == 6}
                        id="volume-price-2"
                        onChange={() => handleFieldChange(6, "priceScheme")}
                        type="radio"
                      />
                      <label htmlFor="volume-price-2">Volume Pricing</label>
                    </li>

                    <li>
                      <div className="new-global-single-input category-input">
                        <TextField
                          id="outlined-basic"
                          label="Category*"
                          variant="outlined"
                          type="text"
                          focused
                          value={mainCategory}
                          onChange={(e) => setMainCategory(e.target.value)}
                          disabled={priceListForm?.isEdit}
                        />
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* ===============  table ==================== */}
      <div>
        {
          // all products ->  buy N get N
          priceListForm.applyType == 0 && priceListForm.priceScheme == 0 ? (
            <div className="new-global-white-bg-container buy-n-get-n-container">
              <div className="new-global-single-input">
                <TextField
                  id="outlined-basic"
                  label="Buy N*"
                  variant="outlined"
                  type="text"
                  focused
                  value={tableData[0].buyN}
                  onChange={(e) =>
                    setTableData([
                      { ...tableData[0], type: null, buyN: e.target.value },
                    ])
                  }
                  disabled={priceListForm?.isEdit}
                />
              </div>
              <div className="new-global-single-input">
                <TextField
                  id="outlined-basic"
                  label="Get N*"
                  variant="outlined"
                  type="text"
                  focused
                  value={tableData[0]?.getN}
                  onChange={(e) =>
                    setTableData([
                      { ...tableData[0], type: null, getN: e.target.value },
                    ])
                  }
                  disabled={priceListForm?.isEdit}
                />
              </div>
            </div>
          ) : // individual product -> buy N get N and category ->  buy N get N
          (priceListForm.applyType == 1 && priceListForm.priceScheme == 4) ||
            (priceListForm.applyType == 2 && priceListForm.priceScheme == 5) ? (
            <>
              <div className="new-global-white-bg-container">
                <div className="global-product-table ">
                  <table>
                    <thead>
                      <tr>
                        {priceListForm.applyType == 1 &&
                        priceListForm.priceScheme == 4 ? (
                          <th>Product</th>
                        ) : (
                          <th>Category</th>
                        )}
                        <th>Buy N</th>
                        <th>Get N</th>
                        <th style={{ backgroundColor: "#fff" }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {tableData &&
                        tableData.map((item, index) => (
                          <tr
                            key={index}
                            ref={
                              index === tableData.length - 1
                                ? containerRef
                                : null
                            }
                          >
                            <>
                              <td style={{ minWidth: "200px" }}>
                                {priceListForm.applyType == 1 &&
                                priceListForm.priceScheme == 4 ? (
                                  <div className="product-table-auto-complete">
                                    <Autocomplete
                                      options={[]}
                                      style={{ maxHeight: "100px" }}
                                      // getOptionLabel={(option) => option?.name}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                      // value={item.product}
                                      // onChange={handleUpdateTable(
                                      //   item.id,
                                      //   "product"
                                      // )}
                                      // disabled={landedCostForm?.isEdit}
                                    />
                                  </div>
                                ) : (
                                  <div className="product-table-auto-complete">
                                    <Autocomplete
                                      options={[]}
                                      style={{ maxHeight: "100px" }}
                                      // getOptionLabel={(option) => option?.name}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                      // value={item.product}
                                      // onChange={handleUpdateTable(
                                      //   item.id,
                                      //   "product"
                                      // )}
                                      // disabled={landedCostForm?.isEdit}
                                    />
                                  </div>
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={item.buyN}
                                  onChange={(e) =>
                                    handleUpdateTable(
                                      index,
                                      "buyN",
                                      e.target.value
                                    )
                                  }
                                  // disabled={landedCostForm?.isEdit}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={item.getN}
                                  onChange={(e) =>
                                    handleUpdateTable(
                                      index,
                                      "getN",
                                      e.target.value
                                    )
                                  }
                                  // disabled={landedCostForm?.isEdit}
                                />
                              </td>

                              <td style={{ border: "none", width: "4%" }}>
                                <IconButton
                                  onClick={() => handleDeleteRow(0)}
                                  style={{ height: "30px" }}
                                  // disabled={landedCostForm?.isEdit}
                                >
                                  <i
                                    className="bi bi-trash3"
                                    style={{
                                      color: "#db0000",
                                      fontSize: "1.3rem",
                                    }}
                                  ></i>
                                </IconButton>
                              </td>
                            </>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="add-row-btn-container">
                  <div
                    className="global-single-input auto-complete add-line-autocomplete"
                    style={{ width: "76%" }}
                  >
                    <Autocomplete
                      options={addRowData || []}
                      getOptionLabel={(option) => option?.value}
                      renderInput={(params) => <TextField {...params} />}
                      value={addRowInput}
                      onChange={(e, newValue) => setAddRowInput(newValue)}
                      renderClear={() => null}
                      // disabled={landedCostForm?.isEdit}
                    />
                  </div>
                  <button
                    onClick={() => addRow(true)}
                    className="add-row-btn"
                    // disabled={landedCostForm?.isEdit}
                  >
                    +
                  </button>
                </div>
              </div>
            </>
          ) : // individual products ->  unit Pricing
          priceListForm.applyType == 1 && priceListForm.priceScheme == 2 ? (
            <>
              <div className="new-global-white-bg-container">
                <div className="global-product-table ">
                  <table>
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Sales Price</th>
                        <th>New Rate</th>
                        <th>Discount</th>
                        <th style={{ backgroundColor: "#fff" }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {tableData?.map((item, index) => (
                        <tr
                          key={index}
                          ref={
                            index === tableData.length - 1 ? containerRef : null
                          }
                        >
                          <>
                            <td style={{ minWidth: "200px" }}>
                              <div className="product-table-auto-complete">
                                <Autocomplete
                                  options={[]}
                                  style={{ maxHeight: "100px" }}
                                  // getOptionLabel={(option) => option?.name}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  // value={item.product}
                                  // onChange={handleUpdateTable(
                                  //   item.id,
                                  //   "product"
                                  // )}
                                  // disabled={landedCostForm?.isEdit}
                                />
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.salesPrice}
                                onChange={(e) =>
                                  handleUpdateTable(
                                    index,
                                    "salesPrice",
                                    e.target.value
                                  )
                                }
                                // disabled={landedCostForm?.isEdit}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.newRate}
                                onChange={(e) =>
                                  handleUpdateTable(
                                    index,
                                    "newRate",
                                    e.target.value
                                  )
                                }
                                // disabled={landedCostForm?.isEdit}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.discount}
                                onChange={(e) =>
                                  handleUpdateTable(
                                    index,
                                    "discount",
                                    e.target.value
                                  )
                                }
                                // disabled={landedCostForm?.isEdit}
                              />
                            </td>

                            <td style={{ border: "none", width: "4%" }}>
                              <IconButton
                                onClick={() => handleDeleteRow(index)}
                                style={{ height: "30px" }}
                                // disabled={landedCostForm?.isEdit}
                              >
                                <i
                                  className="bi bi-trash3"
                                  style={{
                                    color: "#db0000",
                                    fontSize: "1.3rem",
                                  }}
                                ></i>
                              </IconButton>
                            </td>
                          </>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="add-row-btn-container">
                  <div
                    className="global-single-input auto-complete add-line-autocomplete"
                    style={{ width: "76%" }}
                  >
                    <Autocomplete
                      options={addRowData || []}
                      getOptionLabel={(option) => option?.value}
                      renderInput={(params) => <TextField {...params} />}
                      value={addRowInput}
                      onChange={(e, newValue) => setAddRowInput(newValue)}
                      renderClear={() => null}
                      // disabled={landedCostForm?.isEdit}
                    />
                  </div>
                  <button
                    onClick={() => addRow(true)}
                    className="add-row-btn"
                    // disabled={landedCostForm?.isEdit}
                  >
                    +
                  </button>
                </div>
              </div>
            </>
          ) : // individual products ->  volume pricing
          priceListForm.applyType == 1 && priceListForm.priceScheme == 3 ? (
            <>
              <div className="new-global-white-bg-container">
                <div className="global-product-table ">
                  <table>
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Sales Price</th>
                        <th>Start Qty</th>
                        <th>End Qty</th>
                        <th>New Rate</th>
                        <th>Discount</th>
                        <th style={{ backgroundColor: "#fff" }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {tableData?.map((item, index) => (
                        <tr
                          key={index}
                          ref={
                            index === tableData.length - 1 ? containerRef : null
                          }
                        >
                          {
                            <>
                              <td style={{ minWidth: "200px" }}>
                                <div className="product-table-auto-complete">
                                  <Autocomplete
                                    options={[]}
                                    style={{ maxHeight: "100px" }}
                                    // getOptionLabel={(option) => option?.name}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    // value={item.product}
                                    // onChange={handleUpdateTable(
                                    //   item.id,
                                    //   "product"
                                    // )}
                                    // disabled={landedCostForm?.isEdit}
                                  />
                                </div>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={item.salesPrice}
                                  onChange={(e) =>
                                    handleUpdateTable(
                                      index,
                                      "salesPrice",
                                      e.target.value
                                    )
                                  }
                                  // disabled={landedCostForm?.isEdit}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={item.startQuantity}
                                  onChange={(e) =>
                                    handleUpdateTable(
                                      index,
                                      "startQuantity",
                                      e.target.value
                                    )
                                  }
                                  // disabled={landedCostForm?.isEdit}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={item.endQuantity}
                                  onChange={(e) =>
                                    handleUpdateTable(
                                      index,
                                      "endQuantity",
                                      e.target.value
                                    )
                                  }
                                  // disabled={landedCostForm?.isEdit}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  // value={item.description}
                                  value={item.newRate}
                                  onChange={(e) =>
                                    handleUpdateTable(
                                      index,
                                      "newRate",
                                      e.target.value
                                    )
                                  }
                                  // disabled={landedCostForm?.isEdit}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={item.discount}
                                  onChange={(e) =>
                                    handleUpdateTable(
                                      index,
                                      "discount",
                                      e.target.value
                                    )
                                  }
                                  // disabled={landedCostForm?.isEdit}
                                />
                              </td>

                              <td style={{ border: "none", width: "4%" }}>
                                <IconButton
                                  onClick={() => handleDeleteRow(index)}
                                  style={{ height: "30px" }}
                                  // disabled={landedCostForm?.isEdit}
                                >
                                  <i
                                    className="bi bi-trash3"
                                    style={{
                                      color: "#db0000",
                                      fontSize: "1.3rem",
                                    }}
                                  ></i>
                                </IconButton>
                              </td>
                            </>
                          }
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="add-row-btn-container">
                  <div
                    className="global-single-input auto-complete add-line-autocomplete"
                    style={{ width: "76%" }}
                  >
                    <Autocomplete
                      options={addRowData || []}
                      getOptionLabel={(option) => option?.value}
                      renderInput={(params) => <TextField {...params} />}
                      value={addRowInput}
                      onChange={(e, newValue) => setAddRowInput(newValue)}
                      renderClear={() => null}
                      // disabled={landedCostForm?.isEdit}
                    />
                  </div>
                  <button
                    onClick={() => addRow(true)}
                    className="add-row-btn"
                    // disabled={landedCostForm?.isEdit}
                  >
                    +
                  </button>
                </div>
              </div>
            </>
          ) : // category ->  volume pricing
          priceListForm.applyType == 2 && priceListForm.priceScheme == 6 ? (
            <>
              <div className="new-global-white-bg-container">
                <div className="global-product-table ">
                  <table>
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Start Qty</th>
                        <th>End Qty</th>
                        <th>Discount</th>
                        <th style={{ backgroundColor: "#fff" }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {tableData?.map((item, index) => (
                        <tr
                          key={index}
                          ref={
                            index === tableData.length - 1 ? containerRef : null
                          }
                        >
                          {
                            <>
                              <td style={{ minWidth: "200px" }}>
                                <div className="product-table-auto-complete">
                                  <Autocomplete
                                    options={[]}
                                    style={{ maxHeight: "100px" }}
                                    // getOptionLabel={(option) => option?.name}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    // value={item.product}
                                    // onChange={handleUpdateTable(
                                    //   item.id,
                                    //   "product"
                                    // )}
                                    // disabled={landedCostForm?.isEdit}
                                  />
                                </div>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={item.startQuantity}
                                  onChange={(e) =>
                                    handleUpdateTable(
                                      index,
                                      "startQuantity",
                                      e.target.value
                                    )
                                  }
                                  // disabled={landedCostForm?.isEdit}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={item.endQuantity}
                                  onChange={(e) =>
                                    handleUpdateTable(
                                      index,
                                      "endQuantity",
                                      e.target.value
                                    )
                                  }
                                  // disabled={landedCostForm?.isEdit}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={item.discount}
                                  onChange={(e) =>
                                    handleUpdateTable(
                                      index,
                                      "discount",
                                      e.target.value
                                    )
                                  }
                                  // disabled={landedCostForm?.isEdit}
                                />
                              </td>

                              <td style={{ border: "none", width: "4%" }}>
                                <IconButton
                                  onClick={() => handleDeleteRow(index)}
                                  style={{ height: "30px" }}
                                  // disabled={landedCostForm?.isEdit}
                                >
                                  <i
                                    className="bi bi-trash3"
                                    style={{
                                      color: "#db0000",
                                      fontSize: "1.3rem",
                                    }}
                                  ></i>
                                </IconButton>
                              </td>
                            </>
                          }
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="add-row-btn-container">
                  <div
                    className="global-single-input auto-complete add-line-autocomplete"
                    style={{ width: "76%" }}
                  >
                    <Autocomplete
                      options={addRowData || []}
                      getOptionLabel={(option) => option?.value}
                      renderInput={(params) => <TextField {...params} />}
                      value={addRowInput}
                      onChange={(e, newValue) => setAddRowInput(newValue)}
                      renderClear={() => null}
                      // disabled={landedCostForm?.isEdit}
                    />
                  </div>
                  <button
                    onClick={() => addRow(true)}
                    className="add-row-btn"
                    // disabled={landedCostForm?.isEdit}
                  >
                    +
                  </button>
                </div>
              </div>
            </>
          ) : (
            ""
          )
        }
      </div>
      {/* ===============  log ==================== */}
      <div
        className=" new-log-container new-global-white-bg-container"
        style={{ padding: "4px" }}
      >
        <div
          style={{ padding: "8px", cursor: "pointer" }}
          onClick={clickLogView}
        ></div>
        <div
          className={logView ? "pointer-up" : "pointer"}
          onClick={clickLogView}
          style={{ cursor: "pointer" }}
        ></div>
        {logView && (
          <div className="single-log-container">
            {log?.map((r, i) => (
              <div className="single-log-item">
                <div className="single-log-item-line-image">
                  <hr className="single-log-item-line" />
                  <div className="single-log-item-image">
                    <img src={logoImage} alt="" />
                  </div>
                  <hr className="single-log-item-line" />
                </div>
                <p className="heading">{r?.type}</p>
                <p className="name">{r?.name}</p>
                <p className="date-time">{r?.date}</p>
              </div>
            ))}
          </div>
        )}

        {/* <div className='single-log-container-scroll-line'></div> */}
      </div>
      {/* ====================snackbar============= */}
      
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={errorSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};

export default CreatePriceList;
