import { IconButton } from '@mui/material';
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import Header from './HeaderDemo';
import '../Modules/PrintDemo.css';

const PrintDemo = () => {
  const paymentsPrint = useRef(null);

  return (
    <div>
      PrintDemo
      <ReactToPrint
        trigger={() => (
          <IconButton className="printer-div">
            <i style={{ color: 'black' }} className="bi bi-printer"></i>
          </IconButton>
        )}
        content={() => paymentsPrint.current}
      />
      <div style={{ display: 'none' }}>
        <div ref={paymentsPrint} className="print-content">
          <header>This is the header</header>
          <main>
            {[...Array(50)].map((_, index) => (
              <div key={index} className="page">
                <div className="content">www</div>
              </div>
            ))}
          </main>
          <footer>This is the footer</footer>
        </div>
      </div>
    </div>
  );
};

export default PrintDemo;
