import { Autocomplete, Dialog, IconButton, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { viewAllAllowedBranchesAPICall, viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI'
import { useDispatch, useSelector } from 'react-redux'
import { viewShiftAPICall } from '../../../../API/Settings/Shift/viewShiftAPI'
import { listAggregatorsAPI } from '../Configuration/Aggregator/aggregatorAPI'
import { ListAggregatorAPI } from './ReportAPI'
import Template from "./PosAggregatePrint"
import ExcelJS from "exceljs"
import "./posBillingReports.css"
import { convertDateFormat } from '../../../../Js/Date'
import AggregatorReportPayment from './PosAggregatorReport/AggregatorReportPayment'
import { set_aggregator_payment_popup } from './PosAggregatorReport/posAggregatorReportSlice'
import { viewPosSettingsAPICall } from '../Configuration/Settings/posSettingsAPI'
import { paymentTypesForBilling } from '../../Sales/Billing/newBillingAPI'
const PosAggregatorReport = () => {
    let paymentsPrint = useRef();
    const dispatch=useDispatch()
    const decimalPosition = localStorage.getItem("decimalPosition");

    const userRole = useSelector((state) => state.userRoleSlice.value)
    const userInfo = useSelector((state) => state.loginResponseSlice.value);
    const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);
    const allShiftList = useSelector((state) => state.viewShiftSlice.value);
    const aggregatorList = useSelector((state) => state.aggregatorSlice.aggregatorList)
    const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
    const aggregatorReportList = useSelector((state) => state.posReportshiftSlice.aggregatorReport);
    const {paymentPopup}=useSelector((state) => state.posAggregatorReportSlice)


    const [branch, setBranch] = useState(null);
    const [shift, setShift] = useState(null);
    const [aggregator, setAggregator] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);

    const branchOnchange = (e, newValue) => {
        setBranch(newValue)
        setShift(null)
        viewShiftAPICall({ branchId: newValue?._id });
    }
    const generateExcelReport = () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('Aggregator Report');
        sheet.properties.defaultRowHeight = 20;
    
        // Add header
        const titleRow = sheet.addRow(['Aggregator Report']);
        titleRow.font = { size: 15, bold: true };
        sheet.mergeCells('A1:K1');
        titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    
        // Filter and additional information header
        const filterParts = [
            `Branch: ${branch?.branchName || 'All'}`,
            `Shift: ${shift?.SHIFID || 'All'}`,
            `Aggregator: ${aggregator?.name || 'All'}`,
            `Date Range: ${fromDate || 'Start Date'} to ${toDate || 'End Date'}`,
        ];
    
        filterParts.forEach((part, index) => {
            const row = sheet.addRow([part]);
            row.font = { size: 11, bold: true };
            sheet.mergeCells(`A${index + 2}:K${index + 2}`);
            row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
        });
    
        sheet.addRow([]);
    
        // Add table headers
        const headerRow = sheet.addRow([
            'Date', 
            'Bill No', 
            'Customer', 
            'Mobile', 
            'Delivery Charge', 
            'Discount', 
            'Order Amount', 
            'Paid Amount', 
            'Balance', 
            'Status'
        ]);
        
        const headerFont = { size: 12, bold: true, color: { argb: 'FF000000' } };
        const headerFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFedf2f9' } };
    
        headerRow.eachCell((cell) => {
            cell.fill = headerFill;
            cell.font = headerFont;
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });
    
        sheet.columns = [
            { header: "Date", key: "date", width: 20 },
            { header: "Bill No", key: "orderRef", width: 20 },
            { header: "Customer", key: "customer", width: 25 },
            { header: "Mobile", key: "mobile", width: 25 },
            { header: "Delivery Charge", key: "deliveryCharge", width: 20 },
            { header: "Discount", key: "discount", width: 20 },
            { header: "Order Amount", key: "orderAmount", width: 20 },
            { header: "Paid Amount", key: "paidAmount", width: 20 },
            { header: "Balance", key: "balance", width: 20 },
            { header: "Aggregator Report", key: "status", width: 15 },
        ];
        const aggregatorRowFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFb7b6b6' } };
        // Add data rows
        aggregatorReportList?.list?.forEach((item) => {
            // Add a row for the aggregator name
            const aggregatorRow = sheet.addRow([item?.aggregator]);

        // Merge cells A to J in the aggregatorRow
        sheet.mergeCells(`A${aggregatorRow.number}:J${aggregatorRow.number}`);
        
        // Apply background color and alignment to the merged cells
        aggregatorRow.font = { bold: true, color: { argb: 'FF000000' } };
        aggregatorRow.getCell('A').fill = aggregatorRowFill;
        aggregatorRow.getCell('A').alignment = { vertical: 'middle', horizontal: 'left' };
    
            item?.list?.forEach((data, index) => {
                const row = sheet.addRow({
                    date: data.date,
                    orderRef: data.orderRef,
                    customer: data.customer,
                    mobile: data.mobile,
                    deliveryCharge: Number(data.deliveryCharge)?.toFixed(decimalPosition),
                    discount: Number(data.discount)?.toFixed(decimalPosition),
                    orderAmount: Number(data.orderAmount)?.toFixed(decimalPosition),
                    paidAmount: Number(data.paidAmount)?.toFixed(decimalPosition),
                    balance: Number(data.balance)?.toFixed(decimalPosition),
                    status: data.status?.toUpperCase()
                });
    
                if (index % 2 === 1) {
                    const alternateRowFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF8F8F8' } };
                    row.eachCell((cell) => {
                        cell.fill = alternateRowFill;
                    });
                }
    
                row.eachCell((cell, colNumber) => {
                    if (colNumber >= 5 && colNumber <= 9) {
                        cell.alignment = { horizontal: "right" };
                    } else {
                        cell.alignment = { horizontal: "center" };
                    }
                });
            });
          
            // Add the aggregator total row
            const totalRow = sheet.addRow([
                '',
                '',
                '',
                '',
                item?.deliveryCharge?.toFixed(decimalPosition),
                '',
                item?.orderAmount?.toFixed(decimalPosition),
                item?.paidAmount?.toFixed(decimalPosition),
                item?.balance?.toFixed(decimalPosition),
                ''
            ]);
            sheet.addRow([]);
            totalRow.eachCell((cell, colNumber) => {
                cell.font = { bold: true, color: { argb: 'FF000000' } };
                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFb7b6b6' } };
                if (colNumber >= 5 && colNumber <= 9) {
                    cell.alignment = { horizontal: 'right' };
                } else {
                    cell.alignment = { horizontal: 'center' };
                }
            });
        });
    
        // Write to buffer and trigger download
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'AggregatorReport.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    };
    const submitButtonClick = () => {
        let body = {
            branchId: branch?._id ||"",
            shiftId: shift?._id,
            aggregatorId: aggregator?._id,
            startDate: fromDate,
            endDate: toDate
        }
        ListAggregatorAPI(body)
        onClosePaymentDialog()
    }

    const payBtnOnClick=(item)=>{
        dispatch(set_aggregator_payment_popup(item))

    }
    const onClosePaymentDialog=()=>{
        dispatch(set_aggregator_payment_popup(null))

    }
    useEffect(() => {
        userRole === "user" && viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });
        listAggregatorsAPI({ branchId: userInfo?.branchPk })
        paymentTypesForBilling({ branchId: userInfo?.branchPk });
        viewPosSettingsAPICall();
    }, [])
    return (
        <div className="global-page-parent-container" style={{ position: "relative" }}>
            <div className="global-white-bg-container Header" style={{ display: "flex", justifyContent: "space-between", padding: "0px 30px 0 25px", alignItems: "center" }}>
                <p>Aggregator Report</p>
                <div>

                    <ReactToPrint
                        trigger={() => (
                            <IconButton className="printer-div" style={{ padding: "1px" }}>
                                <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>

                            </IconButton>
                        )}
                        content={() => paymentsPrint}
                    />

                    <Tooltip title="Download">
                        <IconButton onClick={generateExcelReport}>
                            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            <div className="global-white-bg-container " style={{ display: "flex", alignItems: "center" }}>
                <div className="new-global-single-input auto-complete-new">
                    <Autocomplete
                        options={userRole === "admin" ? allBranchesList : allowedBranchList || []}
                        getOptionLabel={(option) => option?.branchName}
                        renderInput={(params) => (
                            <TextField {...params} label="Branch" focused variant='outlined' />
                        )}
                        value={branch}
                        onChange={branchOnchange}
                    />
                </div>

                <div className="new-global-single-input auto-complete-new">
                    <Autocomplete
                        options={(allShiftList?.filter(obj => obj.branchId === branch?._id) || []).reverse()}

                        getOptionLabel={(option) => option?.SHIFID}
                        renderInput={(params) => (
                            <TextField {...params} label="Shift" focused variant='outlined' />
                        )}
                        value={shift}
                        onChange={(e, newValue) => setShift(newValue)}
                    />
                </div>
                <div className="new-global-single-input auto-complete-new">
                    <Autocomplete
                        options={aggregatorList || []}
                        getOptionLabel={(option) => option?.name}
                        renderInput={(params) => (
                            <TextField {...params} label="Aggregator" focused variant='outlined' />
                        )}
                        value={aggregator}
                        onChange={(e, newValue) => setAggregator(newValue)}
                    />
                </div>


                <div className="new-global-single-input" style={{ width: '15%' }}>
                    <TextField
                        id="outlined-basic"
                        placeholder="From"
                        onChange={(e, newValue) => setFromDate(e.target.value)}
                        value={fromDate}
                        variant="outlined"
                        type="date"
                        focused
                        label="From Date"
                    />
                </div>



                <div className="new-global-single-input" style={{ width: '15%' }}>
                    <TextField
                        id="outlined-basic"
                        placeholder="From"
                        onChange={(e, newValue) => setToDate(e.target.value)}
                        value={toDate}
                        variant="outlined"
                        type="date"
                        focused
                        label="To Date"
                    />
                </div>

                <button
                    onClick={submitButtonClick}
                    className='btn btn-primary' style={{ marginLeft: "25px" }}>Submit</button>

            </div>
            {aggregatorReportList !== undefined && (
                <div className="new-global-table-container" style={{ paddingTop: "0 42px" }}>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Bill No</th>
                                <th>Customer</th>
                                <th>Mobile</th>
                                <th>Delivery Charge</th>
                                <th>Discount </th>
                                <th>Order Amount</th>
                                <th>Paid Amount</th>
                                <th>Balance</th>
                                <th>Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {aggregatorReportList?.list?.map((item, index) => (
                                <>
                                <tr>
                                <td colSpan={15} className='aggregator-head-td'>{item?.aggregator}</td>
                                </tr>
                                {
                                    item?.list?.map((data, index) => (
                                    <tr
                                    className='aggregaor-order-list-tr'
                                    key={index} style={{ backgroundColor: index % 2 === 0 ? "white" : "#e6e6e6" }}>
                                    <td>{convertDateFormat(data.date)}</td>
                                    <td>{data.orderRef}</td>
                                    <td>{data.customer}</td>
                                    <td>{data.mobile}</td>
                                    <td>{Number(data.deliveryCharge)?.toFixed(decimalPosition)}</td>
                                    <td>{Number(data.discount)?.toFixed(decimalPosition)}</td>
                                    <td>{Number(data.orderAmount)?.toFixed(decimalPosition)}</td>
                                    <td>{Number(data.paidAmount)?.toFixed(decimalPosition)}</td>
                                    <td>{Number(data.balance)?.toFixed(decimalPosition)}</td>
                                    <td>
                                        <div className="justify-center">
                                            <span style={{backgroundColor:data?.status==="Paid"?"#06b800":"#d91e00"}}>{data?.status?.toUpperCase()}</span>
                                        </div>
                                    </td>
                                </tr>
                                    ))
                                }
                                    <tr className='aggregator-footer-tr'>
                                        <td colSpan={4}> </td>
                                        <td>{item?.deliveryCharge?.toFixed(decimalPosition)}</td>
                                        <td colSpan={1}> </td>
                                        <td>{item?.orderAmount?.toFixed(decimalPosition)}</td>
                                        <td>{item?.paidAmount?.toFixed(decimalPosition)}</td>
                                        <td>{item?.balance?.toFixed(decimalPosition)}</td>
                                        {
                                        item?.balance>0?
                                        <td className="pay-btn"
                                        onClick={()=>payBtnOnClick(item)}
                                        >Pay</td>
                                        :
                                        <td>&nbsp;</td>
                                        }
                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            <div style={{ display: "none" }}>
                <div ref={(el) => (paymentsPrint = el)}>
                    <Template aggregatorReportList={aggregatorReportList} branch={branch} />
                </div>
            </div>

            <Dialog open={paymentPopup}  maxWidth="md" fullWidth onClose={onClosePaymentDialog}>
                <AggregatorReportPayment selectedBranch={branch} submitFn={submitButtonClick}/>
            </Dialog>
        </div>
    )
}

export default PosAggregatorReport