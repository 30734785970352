import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CategoryFilterAPICall, categoryListAPICall, deleteApiCall } from "./CategoryAPI";
import { useSelector } from "react-redux";
import { IconButton, Skeleton } from "@mui/material";
import store from "../../../../../Redux/store";
import { singleViewCategory } from "./categorySlice";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";

export const Category = () => {
  const CategoryList = useSelector((state) => state.categorySlice?.tableList);
  let navigate = useNavigate();
  const createCategory = () => {
    navigate("/userdashboard/inventory/configuration/category/create");
  };
  const [IsModify, setIsModify] = useState(false);
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = CategoryList?.pages;

  const [selectedBranch, setSelectedBranch] = useState(null);

  const updateListner = () => {
    setIsModify(!IsModify)
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  //DeleteSingleCategory
  const DeleteSingleCategory = (id) => {
    deleteApiCall(id, updateListner)
  }
  //singleViewCateGry
  const singleViewCateGry = (r) => {
    localStorage.setItem("singleViewById", r._id)
    navigate("/userdashboard/inventory/configuration/category/create");
    // store.dispatch(singleViewCategory({singleViewCategoryList:r}));
  }
  useEffect(() => {
    if (FormDataInfo.length !== 0) {
      CategoryFilterAPICall({
        search: FormDataInfo.search,
        index: currentPage-1,
      }, setIsLoading);
    }
    store.dispatch(singleViewCategory({ singleViewCategoryList: undefined }));
    localStorage.removeItem("singleViewById")
  }, [FormDataInfo, currentPage,IsModify]);

  return (
    <div className="global-page-parent-container updated-global-page-parent-container">
      <div className="justify-space-between list-create-head-bar">
        <h3>Category</h3>
        <div className="create-head-buttons-container">
          <CategorySearchandFilter
            onData={handleDataFromChild}
            isBranch={false}
            isFilter={false}
            onBranchSelect={handleBranchSelect}
          />
          <button onClick={createCategory} className="create-button-blue btn btn-primary">
            Create
          </button>
        </div>
      </div>

      <div className="justify-space-between pagination-container">
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          setFormDataInfo={setFormDataInfo}
        />
      </div>
      <div className="global-table-container updated-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>Category Name</th>
                <th>Delete</th>
              </tr>
            </thead>
              <tbody>
              {
              isLoading ? (
                [...Array(20)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) :
              CategoryList?.list?.length > 0 ? (
                CategoryList?.list?.slice(0).map((r, i) => (
                  <tr>
                    <td onClick={() => singleViewCateGry(r)}>{r?.categoryName}</td>
                    <td>
                      <IconButton onClick={() => DeleteSingleCategory(r?._id)}>
                        <i class="bi bi-trash3 delete-icon"></i>
                      </IconButton>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

    </div>
  );
};
