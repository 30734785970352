import React, { useEffect } from "react";
import "./kotTokenDisplayStyle.css";
import AdvertisementCard from "./components/advertisementCard/AdvertisementCard";
import CompleteOrderCard from "./components/completeOrderCard/CompleteOrderCard";
import PreparingOrderCard from "./components/preparingOrderCard/PreparingOrderCard";
import { switch_to_billing_screen } from "../Sales/Billing/newBillingSlice";
import { useDispatch, useSelector } from "react-redux";
import { getTokenDisplayAPI } from "./tokenDisplayApi";
import { tokenStatus } from "./util/kotTokenUtil";
import io from "socket.io-client";
import axios from "axios";
import { setAdvertisementExpand } from "./kotTokenDisplaySlice";
import AlertMessage from "../FloorManagement/components/alertMessage/AlertMessage";
import { setAlertMessage } from "./components/advertisementCard/advertisementSlice";

const KotTokenDisplay = () => {
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(switch_to_billing_screen({ billingBoolean: true }));
  }, []);

  const { kotTokenData, advertisementExpand } = useSelector(
    (state) => state.kotTokenDisplaySlice
  );
  const { alertMessage } = useSelector((state) => state.advertisementSlice);

  useEffect(() => {
    const socket = io(axios.defaults.baseURL, {
      path: "/socket.io/",
      reconnection: true,
      transports: ["websocket"],
      reconnectionAttempts: 5,
    });

    socket.on("connect", () => {
      if (loginResponse?.data?._id && loginResponse?.branchPk) {
        socket.emit(
          "setUsername",
          loginResponse?.data?._id,
          loginResponse?.branchPk
        );
      }
    });

    if (!kotTokenData) {
      getTokenDisplayAPI({
        body: { branchId: loginResponse?.branchPk },
      });
    }

    // Event listeners for socket messages
    const updateKotList = () => {
      getTokenDisplayAPI({
        body: { branchId: loginResponse?.branchPk },
      });
    };

    socket.on("toKotCookStatus", () => {
      updateKotList();
    });

    socket.on("toKotCompleteStatus", () => {
      updateKotList();
    });

    socket.on("toKotResetStatus", () => {
      updateKotList();
    });
    socket.on("toKotDeliveryStatus", () => {
      updateKotList();
    });

    // Cleanup function
    return () => {
      // Disconnect socket and remove event listeners
      socket.disconnect();
      socket.off("connect");
      socket.off("toKotCookStatus");
      socket.off("toKotCompleteStatus");
      socket.off("toKotResetStatus");
    };
  }, [loginResponse, kotTokenData]);

  const setAlertMessageHandler = () => {
    if (alertMessage !== null) {
      dispatch(setAlertMessage(null));
    }
  };

  return (
    <div className="kot-token-display-container">
      <div className="token-display-body">
        {!advertisementExpand && (
          <PreparingOrderCard data={kotTokenData?.preparing} />
        )}
        {!advertisementExpand && (
          <CompleteOrderCard data={kotTokenData?.completed} />
        )}
        <AdvertisementCard />
        <AlertMessage
          status={alertMessage?.status}
          alertMessage={alertMessage?.message}
          setApiAlertMessage={setAlertMessageHandler}
        />
      </div>
    </div>
  );
};

export default KotTokenDisplay;
