//list all branches

import axios from "axios";
import { HEADERS } from "../../../../API/UrlAndPaths";
import { setBranchList } from "../addSeat/addSeatSlice";
import store from "../../../../Redux/store";


// list all branches 
export const listAllBranches = async (body) => {
  try {
    const response = await axios.post("admin/viewbranches", body, HEADERS);
    if (response.status === 200) {
      store.dispatch(setBranchList(response.data));
    }
  } catch (error) {
    store.dispatch(setBranchList([]));
    console.log(`Error: ${error}`);
  }
};

