import { IconButton, Tooltip } from "@mui/material";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import {
  companyProfileInfoForUser,
  viewCompanyProfileInfoAPICall,
} from "../../../../API/Settings/Company Settings/companySettingsAPI";
import PO from "../../../../Assets/statusRibbon/purchaseorder.png";
import Cancel from "../../../../Assets/statusRibbon/cancel.png";
import Done from "../../../../Assets/statusRibbon/done.png";
import { convertDateFormat, today } from "../../../../Js/Date";
import ReactToPrint from "react-to-print";
import { viewPurchaseOrderSingleView } from "../../../../API/Purchase Manager/NewPurchaseOrder/PurchaseOrder";
import { set_Back_button_action } from "../../../Single Components/listSlice/filterCategorySlice";
import Template1 from "../../SalesNew/SalesOrders/SalesQuotation/Template1";
import Template2 from "../../SalesNew/SalesOrders/SalesQuotation/Template2";
import { getTemplateAPI } from "../../SalesNew/SalesOrders/SalesQuotation/salesQuatationAPI";
import { printSection } from "../../Accounts/PrintComponents/printTemplates/common/enum";
import { InvoiceContent } from "../../Accounts/PrintComponents/printTemplates/common/InvoiceContent";

export const PurchaseOrderSingleView = () => {
  let navigateTo = useNavigate();
  let paymentsPrint = useRef();
  let paymentsPrintNew = useRef();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const decimalPosition = localStorage.getItem("decimalPosition");
  //data of selected List
  const singleViewID = localStorage.getItem("singleViewById");

  const singleList = useSelector(
    (state) => state?.RfqSlice?.purchaseOrderSingleView
  );
  // company details and info
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const template = useSelector(
    (state) => state?.salesQuatationSlice?.setTemplate
  );

  const formValuesInitialState = {
    status: "",
    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
    companyTRN: "",

    customerName: "",
    customerAddress: "",
    customerContact: "",

    dateN1: "Quotation Date",
    dateN2: "Expiry Date",

    lpo: "",
    ref: "",
    name: "",
    firstDate: "",
    secondDate: "",

    termsAndCondition: "",

    address: "Vendor ",
    sequence: "PO No",
    subtotal: 0,
    inclusiveTax: 0,
    exclusiveTax: 0,
    total: 0,
    totalAmount: "",
    payTerm: "",
    label: "PURCHASE ORDER",
  };
  const [formValues, setFormValues] = useState(formValuesInitialState);
  const [data, setData] = useState([]);
  const [tax, setTax] = useState([]);
  const [domain, setDomain] = useState("");

  // api to fetch companyprofile data
  useEffect(() => {
    getTemplateAPI();
    viewPurchaseOrderSingleView({ _id: singleViewID });
  }, []);

  useEffect(() => {
    setFormValues({
      ...formValues,
      status: singleList?.type,
      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
      companyTRN: companyProfileData?.companyTRN || "",

      ref: singleList?.reference || "",
      lpo: singleList?.LpoNo || "",

      customerName: singleList?.vendorAddress?.vendorName || "",
      customerAddress: singleList?.vendorAddress?.streetName || "",
      customerContact: singleList?.vendorAddress?.mobileNo || "",
      trn: singleList?.vendorAddress?.TRN || "",
      name: singleList?.sequence || "",
      firstDate: singleList?.quotationDate || "",
      secondDate: singleList?.expiryDate || "",
      payTerm: singleList?.PAYMENTTERM || "",

      termsAndCondition: singleList?.termsAndConditions || "",

      subtotal: singleList?.subTotal || 0,
      inclusiveTax: singleList?.totalTaxInclusive || 0,
      exclusiveTax: singleList?.totalTaxExclusive || 0,
      total: singleList?.total || 0,
      totalAmount: singleList?.totalAmount || "",
      qrcodetype: singleList?.qrcodetype || "",
      supplierId: singleList?.supplierId || "",
      db: singleList?.db || "",
      _id: singleList?._id || "",
    });
    setData(singleList?.productInfo);
    setTax(singleList?.taxDetails);
  }, [singleList, companyProfileData]);

  // Extracting domain from location object
  useEffect(() => {
    if (formValues) {
      // const domainAddress = `${window.location.protocol}//${window.location.host}/posReaderPreview?path=admin/viewqrcodedata&db=${element?.db}&vendororcusid=${element?.supplierId}&type=${element?.qrcodetype}&orderId=${element?._id}`;
      let element = formValues;
      const domainAddress = `${window.location.protocol}//${
        window.location.host
      }/posReaderPreview?path=admin/viewqrcodedata&db=${encodeURIComponent(
        element?.db
      )}&vendororcusid=${encodeURIComponent(
        element?.supplierId
      )}&type=${encodeURIComponent(
        element?.qrcodetype
      )}&orderId=${encodeURIComponent(element?._id)}`;
      setDomain(domainAddress);
    }
  }, [formValues]);

  return (
    <div
      className="main-container"
      style={{ backgroundColor: "unset", padding: "0" }}
    >
      <div
        className="first-div-container"
        style={{ backgroundColor: "white", justifyContent: "space-between" }}
      >
        {/* <div className="post-ribbon">
          {formValues?.status === "PURCHASE ORDER" && <img src={PO} alt="" />}
          {formValues?.status === "DONE" && <img src={Done} alt="" />}
          {formValues?.status === "CANCEL" && <img src={Cancel} alt="" />}
        </div> */}
        <div style={{ display: "flex", gap: "8px" }}>
          <Tooltip title="Edit">
            <IconButton>
              <i
                class="bi bi-pencil-square edit-icon1"
                onClick={() =>
                  navigateTo(
                    "/userdashboard/purchaseManager/CreateNewPurchaseOrder"
                  )
                }
              ></i>
            </IconButton>
          </Tooltip>
          <h3>
            <span
              style={{ fontSize: "medium", color: "black", cursor: "pointer" }}
              onClick={() => {
                navigateTo("/userdashboard/purchaseManager/NewPurchaseOrder");
                dispatch(
                  set_Back_button_action({ backButtonClickAction: true })
                );
              }}
            >
              Purchase Order &gt;
            </span>
            <span style={{ fontSize: "medium", paddingLeft: "8px" }}>
              {formValues?.name}
            </span>
          </h3>
        </div>
        {/* <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div">
              <i style={{ color: "black" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrint}
        /> */}
        <div>
          <Tooltip title="Share">
            <IconButton>
              <i
                class="bi bi-share"
                style={{
                  backgroundColor: "#9797e9",
                  color: "white",
                  fontSize: "medium",
                  padding: "8px",
                  borderRadius: "2px",
                }}
              ></i>
            </IconButton>
          </Tooltip>

          {/* <ReactToPrint
            trigger={() => (
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i
                  style={{
                    color: "white",
                    backgroundColor: " #d787e7",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                  class="bi bi-printer"
                ></i>
              </IconButton>
            )}
            content={() => paymentsPrintNew}
          /> */}
           <ReactToPrint
            // The print button
            trigger={() => (
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i
                  style={{
                    color: "white",
                    backgroundColor: " #d787e7",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                  class="bi bi-printer"
                ></i>
              </IconButton>
            )}
            // The content to be printed (InvoiceContent component)
            content={() => componentRef.current}
            // Set the page style for printing
            pageStyle="@page { size: A4; margin: 0; }"
          />

          <Tooltip title="Download">
            <IconButton>
              <i
                class="bi bi-arrow-down-circle"
                style={{
                  backgroundColor: "rgb(255 118 133 / 69%)",
                  color: "white",
                  fontSize: "medium",
                  padding: "8px",
                  borderRadius: "2px",
                }}
              ></i>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* <hr className="line" /> */}
      <div
        style={{
          backgroundColor: "white",
          height: "84vh",
          marginTop: "4px",
          position: "relative",
        }}
      >
        <div className="second-main-container">
          <div className="post-ribbon" style={{ right: "0px", top: "0px" }}>
            {formValues?.status === "PURCHASE ORDER" && <img src={PO} alt="" />}
            {formValues?.status === "DONE" && <img src={Done} alt="" />}
            {formValues?.status === "CANCEL" && <img src={Cancel} alt="" />}
          </div>
          <div className="left-side-div">
            <img src={formValues?.companyLogo} alt="" />
            <p>{formValues?.companyName}</p>
            <p>{formValues?.companyAddress}</p>
            <p>{formValues?.companyContact}</p>

            <h1 className="vendor-head">Vendor Address</h1>
            <p>{formValues?.customerName}</p>
            <p>{formValues?.customerContact}</p>
            {/* <p>--</p>
          <p>--</p> */}
          </div>
          <div className="right-side-div">
            <h1>Purchase Order</h1>
            <p>/{formValues?.name}</p>

            <div className="date-div">
              <p>
                <span class="date-container-single">Date:</span>
                {formValues?.firstDate}
              </p>
              {/* <p>Order Date:{formValues?.secondDate} </p> */}
            </div>
          </div>
        </div>

        <div className="Table-container">
          <div className="Table-data" style={{ maxHeight: "50vh" }}>
            <table>
              <thead>
                <tr style={{ backgroundColor: "#eaeef9" }}>
                  <th>SI NO</th>
                  <th>PRODUCT</th>
                  <th>DESCRIPTION</th>
                  <th>QTY</th>
                  <th>UOM</th>
                  <th>RATE</th>
                  <th>AMOUNT</th>
                  <th>DISC%</th>
                  <th>VAT </th>
                  <th>VAT AMOUNT</th>
                  <th>NET AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>{item.product}</td>
                    <td>{item?.label}</td>
                    <td>{item.qty}</td>
                    <td>{item.uomName}</td>
                    <td>{item.unitPrice?.toFixed(decimalPosition)}</td>
                    <td>
                      {(item.qty * item.unitPrice)?.toFixed(decimalPosition)}
                    </td>
                    <td>{item.discount}%</td>
                    <td>
                      {item.taxIdList?.map((r, i) => (
                        <p
                          key={i}
                          className="tax"
                          style={{ margin: "1px", border: "none" }}
                        >
                          {r?.taxName}
                        </p>
                      ))}
                    </td>
                    <td>{item.taxAmt?.toFixed(decimalPosition)}</td>
                    <td>
                      {item.includedPrice === false
                        ? (item.lineTotal + item.taxAmt)?.toFixed(
                            decimalPosition
                          )
                        : item.lineTotal?.toFixed(decimalPosition)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              backgroundColor: "#e9e9e9",
              display: "flex",
              textAlign: "end",
              paddingInline: "5%",
              width: "85%",
            }}
          >
            <p style={{ margin: "0" }}>Total</p>
            {data && data.length > 0 && (
              <p
                style={{
                  width: "53%",
                  margin: "0",
                  fontSize: "smaller",
                  fontWeight: "600",
                }}
              >
                {" "}
                {data
                  ?.filter((obj) => obj?.line_type === "lines")
                  ?.reduce((total, item) => {
                    const qty = typeof item.qty === "number" ? item.qty : 0; // Check if item.qty is a number, otherwise default to 0
                    const unitPrice =
                      typeof item.unitPrice === "number" ? item.unitPrice : 0; // Check if item.unitPrice is a number, otherwise default to 0
                    return total + qty * unitPrice;
                  }, 0)
                  ?.toFixed(decimalPosition)}
              </p>
            )}

            <p
              style={{
                width: "28%",
                margin: "0",
                fontSize: "smaller",
                fontWeight: "600",
              }}
            >
              {" "}
              {data
                ?.filter((obj) => obj?.line_type === "lines")
                ?.reduce((acc, row) => acc + row?.taxAmt, 0.0)
                ?.toFixed(decimalPosition)}
            </p>
            <p
              style={{
                width: "17%",
                padding: "3px",
                margin: "0",
                fontSize: "smaller",
                fontWeight: "600",
              }}
            >
              {data
                ?.filter((obj) => obj?.line_type === "lines")
                ?.reduce((acc, row) => {
                  if (row.includedPrice === false) {
                    return acc + (row.lineTotal + row.taxAmt);
                  } else {
                    return acc + row.lineTotal;
                  }
                }, 0.0)
                ?.toFixed(decimalPosition)}
            </p>
          </div>
          <div
            className="last-total-div"
            style={{ width: "85%", padding: "18px 32px" }}
          >
            <div className="sub-total">
              <h1>Sub Total</h1>
              {formValues?.inclusiveTax > 0 && <p>Total tax Inclusive</p>}
              {formValues?.exclusiveTax > 0 && <p>Total tax Exclusive</p>}
              <hr className="line-2" />
              <h1>Total Inc Vat</h1>
            </div>
            <div className="sub-total-amount">
              <h1>{formValues?.subtotal?.toFixed(decimalPosition) || 0}</h1>
              {formValues?.inclusiveTax?.toFixed(decimalPosition) > 0 && (
                <p>{formValues?.inclusiveTax?.toFixed(decimalPosition)}</p>
              )}
              {formValues?.exclusiveTax > 0 && (
                <p>{formValues?.exclusiveTax?.toFixed(decimalPosition)}</p>
              )}
              <hr className="line-3" />
              <h1>{formValues?.total?.toFixed(decimalPosition)}</h1>
            </div>
          </div>
        </div>
      </div>
      {/* payments print view */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <p></p>
              <p></p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {/* <h3>--</h3>

              <h3>--</h3> */}

              <p>Purchase Order</p>
            </div>

            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>{convertDateFormat(today)}</p>
              <p>{""}</p>
            </div>
          </div>

          <div className="second-main-container">
            <div className="left-side-div">
              <img
                src={formValues?.companyLogo}
                alt={formValues?.companyName}
              />
              <p>{formValues?.companyAddress}</p>
              <p>{formValues?.companyContact}</p>

              <h1 className="vendor-head">vendor Address</h1>
              <p>{formValues?.vendorName}</p>
              {/* <p>--</p>
              <p>--</p> */}
            </div>
            <div className="right-side-div">
              <h1>Purchase Order</h1>
              <p>{formValues?.name}</p>
              <div className="date-div">
                <p>
                  <span class="date-container-single">Date:</span>
                  {formValues?.firstDate}
                </p>
                {/* <p>Order Date:{formValues?.secondDate} </p> */}
              </div>
            </div>
          </div>

          <div className="Table-container" style={{ padding: "0px 35px" }}>
            <div className="Table-datas">
              <table style={{ overflow: "none" }}>
                <thead>
                  <tr style={{ backgroundColor: "#eaeef9" }}>
                    <th>No</th>
                    <th>Product</th>
                    <th>QTY</th>
                    <th>Rate</th>
                    <th>Tax%</th>
                    <th>dis%</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{item.product}</td>
                      <td>{item.qty}</td>
                      <td>{item.unitPrice}</td>
                      <td
                        style={{
                          display: "flex",
                          placeContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        {item.taxes?.map((r, i) => (
                          <p key={i} className="tax" style={{ margin: "1px" }}>
                            {r?.amount}%
                          </p>
                        ))}
                      </td>
                      <td>{item.discount}</td>
                      <td>{item.lineTotal?.toFixed(decimalPosition) || 0} </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="last-total-div">
                <div className="sub-total">
                  <h1>Sub Total</h1>
                  {formValues?.inclusiveTax > 0 && <p>Total tax Inclusive</p>}
                  {formValues?.exclusiveTax > 0 && <p>Total tax Exclusive</p>}
                  <hr className="line-2" />
                  <h1>Total</h1>
                </div>
                <div className="sub-total-amount">
                  <h1>{formValues?.subtotal?.toFixed(decimalPosition) || 0}</h1>
                  {formValues?.inclusiveTax?.toFixed(decimalPosition) > 0 && (
                    <p>{formValues?.inclusiveTax?.toFixed(decimalPosition)}</p>
                  )}
                  {formValues?.exclusiveTax > 0 && (
                    <p>{formValues?.exclusiveTax?.toFixed(decimalPosition)}</p>
                  )}
                  <hr className="line-3" />
                  <h1>{formValues?.total?.toFixed(decimalPosition)}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="terms">
            <h1>Terms & Conditions</h1>
            <hr className="lines" />
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
          {/* <Printing /> */}
          <InvoiceContent
            invoiceDetails={{ ...formValues, printSection: printSection.purchaseOrder }}
            ref={componentRef}
            printData={data}
            taxDetails={tax}
            qrData={domain}
          />
        </div>
      </div>
    </div>
  );
};
