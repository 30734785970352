import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setKotPopup, setKotStatus } from './filterButtonSlice';
import kotIcon from "../../../../../Assets/SVG/KOTfilterIcon.svg"
import CategoryEmptyAnimation from '../Gif/CategoryEmptyAnimation';
import { listKotOrderAPI } from '../../../kitchenOrderTicket/kotAPI\'s';
import { PrintOutlined } from "@mui/icons-material";
import { IconButton, Skeleton } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import KotPrint from '../../../kitchenOrderTicket/components/print/KotPrint';
import { setBillLoading, setKOTSingleInfo } from '../Slice/billingScreenSlice';
import { useReactToPrint } from 'react-to-print';
import  html2pdf  from 'html2pdf.js';
import { pdfBlobOptionsPrint } from '../../../../Single Components/GneralUtilities';
import { kotPrintRequestAPICall } from '../../../Settings/Device Settings/deviceSettingsAPI';
import { getPPI } from '../../../../../Js/generalFunctions';
function KOTorders() {

    const menuRef=useRef()
    const dispatch=useDispatch()
    const kotPrintRef=useRef(null)
    const {kotPopup,kotStatusFilter}=useSelector((state)=>state.filterButtonSlice)
    const { kotOrderList } = useSelector((state) => state.kitchenOrderTicketSlice);
    const { kotSingleData } = useSelector((state) => state.billingScreenSlice);
    const { tableLoading } = useSelector((state) => state.mainDashBoardSlice);
    const userInfo=useSelector((state)=>state.loginResponseSlice.value)
    const userShiftStatus = localStorage.getItem("shiftStatus");
    const userShiftpk = JSON.parse(userShiftStatus)?._id

    const switchTabs=["All","To Cook","Preparing","Completed"]
    const onChangeBillType=(key)=>()=>{
        dispatch(setKotStatus(key))
        let filter=key==="All"?"ONGORD":key==="To Cook"?"NEW":key==="Preparing"?"PREPARING":"COMPLETED"

        listKotOrderAPI({ body: {branchId:userInfo?.branchPk,status:filter,shiftId:userShiftpk} });

    }
    const filterIconClick = () => {
        dispatch(setKotPopup(!kotPopup))

        let key=kotStatusFilter
        let filter=key==="All"?"ONGORD":key==="To Cook"?"NEW":key==="Preparing"?"PREPARING":"COMPLETED"

       !kotPopup && listKotOrderAPI({ body: {branchId:userInfo?.branchPk,status:filter,shiftId:userShiftpk} });
    }
    
    const handlePrintKot=useReactToPrint({
      content: () => kotPrintRef.current,
    })

    const kotPrintClick=async(info)=>{
      dispatch(setBillLoading(true))
      try {
          let printSettingsString=localStorage.getItem('PrinterSettings')
          let printSettings=JSON.parse(printSettingsString)
          
          let payload={
            printerWidth: printSettings?.kotSize==="80"?48:32,
            printerName: printSettings?.kotPrinter,
            transNo: info?.transNo ,
            ordName: info?.ordName,
            orderDate: info?.orderDate,
            ordType:info?.ordType,
            waiterName: info?.waiterName || "-NIL-",
            orderInfo:info?.orderInfo
        }
      if (printSettings?.kotPrinter) {
          kotPrintRequestAPICall(payload,handlePrintKot)
          }
          else{
            handlePrintKot()
          }
      } catch (error) {
          dispatch(setBillLoading(false))
      } finally {
        dispatch(setBillLoading(false));
      }
      
    }
    // const kotPrintClick=async()=>{
    //   dispatch(setBillLoading(true))
    //   try {
    //       const receiptHTML = kotPrintRef.current;
    //       const contentHeight = receiptHTML.offsetHeight;
    //       const ppi = getPPI();
    //       // Convert inches to mm for jsPDF
    //       const pixelsToMM = (pixels) => pixels * (25.4 / ppi);
    //       const contentHeightInMM = pixelsToMM(contentHeight);
          
    //       const opt = {
    //         margin: 0,
    //         filename: 'receipt.pdf',
    //         html2canvas: { scale: 4, useCORS: true },
    //         jsPDF: { unit: 'mm', format: [80, contentHeightInMM*1.2] }
    //       };
    //       // Convert to PDF
    //       const pdfBlob = await html2pdf().from(receiptHTML).set(opt).outputPdf('blob');
          
    //       let printSettingsString=localStorage.getItem('PrinterSettings')
    //       let printSettings=JSON.parse(printSettingsString)
          
    //       const formData = new FormData();
    //       formData.append('pdfFile', pdfBlob);
    //       formData.append('printer_name',printSettings?.kotPrinter);
    //       printRequestAPICall(formData,handlePrintKot)
    //   } catch (error) {
    //       dispatch(setBillLoading(false))
    //   } finally {
    //     dispatch(setBillLoading(false));
    //   }
      
    // }
    const kotSinglePrint=(row)=>()=>{
      dispatch(setKOTSingleInfo(row))
      setTimeout(() => {
        kotPrintClick(row)
      }, 0);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) 
            ) {
                dispatch(setKotPopup(false))
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <div
        ref={menuRef}
        style={{position:"relative"}}>
        <div 
        className="filter-icon-ctn justify-center" 
        style={{backgroundColor:"#ffdfe0",color:"rgb(233 58 56)",padding:"15px 8px"}}
        onClick={filterIconClick}>
            <img
            style={{height:"20px",width:"20px"}}
            src={kotIcon} alt="" />
            <span>
            KOT
            </span>
        </div>
            {
                kotPopup &&
                <div className="kot-list-ctn filter-icon-ctn-drop-down">
                    <div className="justify-space-between">

                    <h4 style={{margin:"0px"}}>KOT</h4>
                    <div
                    className="new-billing-order-type-switch"
                    style={{width:"auto"}}
                  >
                    {
                      switchTabs?.map((option) => (
                        <div
                          key={option}
                          className={`switch-bill-type ${kotStatusFilter === option ? 'active' : ''}`}
                          onClick={onChangeBillType(option)}
                          style={{paddingBottom:kotStatusFilter === option ?0:"4px"}}
                        >
                          <p readOnly style={{ margin: "0", fontSize: "0.9rem" }}>
                            {option}
                          </p>
                        </div>
                      ))
                    }
                  </div>
                  </div>
                  <hr className="global-hr" style={{marginTop:"0px"}}/>

                  <div className="billing-kot-list-body">
                        {

                        tableLoading?
                        [...Array(10)].map((_,i)=>(
                          <div
                              key={i}
                              style={{ backgroundColor: "#fff" ,width:"100%"}}>
                                  <Skeleton width={"100%"} height={90} />

                          </div> 
                      ))
                        :kotOrderList.length!==0?
                        kotOrderList.map((r,i)=>(
                            <div className="kot-order-card" key={i} style={{padding:r?.status!=="DELIVERED" ?"10px 15px":"0 15px" }}>
                            <div className="justify-space-between" style={{marginBottom:r?.status!=="DELIVERED" ?"15px":"0px"}}>
                                <p>
                                KOTNO:&nbsp;<span style={{color:"red"}}>{r?.name || "---"}</span>
                                </p>
                                <div className="justify-center" style={{gap:"15px"}}>
                                    <p>O/No:&nbsp; <span style={{color:"#1438f9"}}>{r?.ordName ||"---"}</span></p>
                                    {
                                      r?.status!=="DELIVERED"?
                                    <div className={`status ${r?.status==="NEW"?"status-red":r?.status==="PREPARING"?"status-blue":"status-green"}`}>
                                        {r?.status==="NEW"?"To Cook":r?.status==="PREPARING"?"Preparing":r?.status==="COMPLETED"?"Completed":"---"}
                                    </div>:
                                    <span><DoneIcon color='success' fontSize='large'/></span>
                                    }
                                    <IconButton onClick={kotSinglePrint(r)}>
                                      <PrintOutlined/>
                                    </IconButton>
                                </div>
                            </div>
                            {
                            r?.status!=="DELIVERED" &&
                            r?.orderInfo.map((product,index)=>(
                                <div 
                                key={index}
                                className="kot-order-item-list justify-space-between"
                                >

                                <p>{product?.productName}</p>
                                <p>{product?.qty}</p>
                            </div>
                            ))
                            }
                        </div>
                        )):
                      <div className="center-div" style={{ height: "50vh", flexDirection: "column" }}>
                        <CategoryEmptyAnimation />
                        No Records !!
                      </div>    
                        }
                  </div>
                  <div className='print-parent'>
          <div ref={kotPrintRef}>
            <KotPrint
              billNo={kotSingleData?.ordName || "-NIL-"}
              waiter={kotSingleData?.waiterName || "-NIL-"}
              orderDate={kotSingleData?.orderDate || "00/00/0000 00:00:00"}
              orderData={kotSingleData?.orderInfo || []}
              orderType={kotSingleData?.ordType || "---"}
              tokenNumber={kotSingleData?.transNo || "---"}
            />
          </div>
        </div>
                </div>
            }


        
        </div>
    )
}

export default KOTorders