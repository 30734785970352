import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import store from '../../../../../Redux/store';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import { get_debit_note_row_data_list } from '../Slice/debitNoteSlice';
import { DebitNotesFilterAPICall, viewDebitNotesAPICall } from '../API/debitNoteAPI';
import { convertDateFormat, getCurrentTime } from '../../../../../Js/Date';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../../Single Components/CustomPagination';
import axios from 'axios';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import ReactToPrint from 'react-to-print';
import ExcelJS from "exceljs"
import TableListActionButtons from '../../../../Single Components/ActionButtons/TableListActionButtons';

export const DebitNotes = () => {

  const filtereDataOptions = useSelector((state) => state.listCategory);
  let navigate = useNavigate();
  const tableListData = useSelector((state) => state.debitNoteSlice.apiResList);

  const createButton = () => {
    navigate("/userdashboard/accounts/vendor/DebitNotes/create")
    store.dispatch(get_debit_note_row_data_list({debitNoteSingleData:undefined}));
  }
  const [isLoading, setIsLoading] = useState(false)
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [filteredDatas, setFilteredData] = useState({});
  const totalPages = tableListData?.pages;


  const handleClickRow = (row) => {
    store.dispatch(get_debit_note_row_data_list({ debitNoteSingleData: row }));
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem('singleViewById', row?._id)

    //navigate("/userdashboard/accounts/customers/creditNotes/create")
    navigate("/userdashboard/accounts/vendor/DebitNote/SingleView")

  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handleFilterChange=(updatedItems)=>{
    setFilteredData(updatedItems)
  }
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);

  };
  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }
    if (filters?.location) {
      parts.push(`location: ${filters?.location?.name}`);
    }
    if (filters?.toLocation) {
      parts.push(`To Location: ${filters?.toLocation?.name}`);
    }
    if (filters?.status) {
      parts.push(`status: ${filters?.status}`);
    }
    if (filters?.createdBy) {
      parts.push(`createdBy: ${filters?.createdBy}`);
    }
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Debit Notes");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Debit Notes"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells("A1:H1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredDatas) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:H${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "Date",
      "Name",
      "Vendor",
      "Bill Date",
      "Bill Reference",
      "Total",
      "Created By",
      "Status",
     
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Date", key: "billDates", width: 15 },
      { header: "Name", key: "name", width: 20 },
      { header: "Vendor", key: "vendorName", width: 25 },
      { header: "Bill Date", key: "billDate", width: 25 },
      { header: "Bill Reference", key: "billRef", width: 25 },
      { header: "Total", key: "total", width: 15 },
      { header: "Created By", key: "createdBy", width: 15 },
      { header: "Debit Notes", key: "status", width: 20 },
    ];

    tableListData?.list?.forEach((item, index) => {
      const row = sheet.addRow({
        billDates: convertDateFormat(item?.billDate),
        name: item.name,
        vendorName: item.vendorName,
        billDate: convertDateFormat(item?.billDate),
        billRef: item?.billRef,
        total: item?.total,
        createdBy: item?.createdBy,
        status: item?.status,
        
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: "thin", color: { argb: "FF000000" } },
          //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
          //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Debit Notes.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  useEffect(() => {
    localStorage.removeItem("singleViewById");
    store.dispatch(get_debit_note_row_data_list({ debitNoteSingleData: undefined }))
    // if (userRole === "user") {
    //   viewDebitNotesAPICall({ branchId: userBranchId }, setIsLoading)
    // }
    // else {
    //   viewDebitNotesAPICall({}, setIsLoading)
    // }
  }, [])
  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
       let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData &&
      DebitNotesFilterAPICall({
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        branchList: FormDataInfo.branchList,
        status: FormDataInfo.status,
        search: FormDataInfo.search,
        index: currentPage - 1,
      }, setIsLoading);
    };
   
  }, [FormDataInfo, currentPage]);



  return (
    <div className='global-page-parent-container updated-global-page-parent-container'>
      <div className="justify-space-between list-create-head-bar">
        <h3>Debit Notes</h3>
        <div className="create-head-buttons-container">
          <TableListActionButtons downloadClick={downloadList} />
          <CategorySearchandFilter
              statusOptions={['DRAFT', 'POSTED']}
              onData={handleDataFromChild}
              isStatus={true}
              onBranchSelect={handleBranchSelect}
              onFilterChange={handleFilterChange}
            />
            <button onClick={createButton} className='create-button-blue btn btn-primary'>Create</button>
        </div>
      </div>

      <div className="justify-space-between pagination-container">
        {FormDataInfo?.branchList?.length > 0 &&
          <h3>Branch name:
            {selectedBranch?.storeCode || filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
            -{selectedBranch?.branchName || filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>}
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          setFormDataInfo={setFormDataInfo}
        />
      </div>
        <div className='new-global-table-container updated-table-container'>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Vendor</th>
                <th>Bill Date</th>
                {/* <th>Due Date</th> */}
                <th>Bill Reference</th>
                <th>Total</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody >
              {

                isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={9}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) :

                  tableListData?.list !== undefined && tableListData?.list?.length !== 0 ? tableListData?.list?.slice(0)?.map((r, i) => (
                    <tr onClick={() => handleClickRow(r)}>
                      <td>{convertDateFormat(r?.billDate)}</td>
                      <td>{r?.name}</td>
                      <td>{r?.vendorName}</td>
                      <td>{convertDateFormat(r?.billDate)}</td>
                      {/* <td>{r?.dueDate}</td> */}
                      <td>{r?.billRef || "---"}</td>
                      <td>{r?.total || "---"}</td>
                      <td>{r?.createdBy || "---"}</td>
                      <td className='table-data-box'>
                        {r?.status === "DRAFT" ? (
                          <span className='draft'> DRAFT</span>
                        ) : r?.status === "POSTED" ? (
                          <span className='post'> POST</span>
                        ) : r?.status === "PAID" ? (
                          <span className='paid'>PAID</span>
                        ) : (
                          <span>{r?.status}</span>
                        )}
                      </td>
                    </tr>
                  ))
                    :
                    <tr>
                      <td colSpan={9}>No Data</td>
                    </tr>
              }

            </tbody>

          </table>
        </div>

    </div>
  )
}
