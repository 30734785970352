import React, { useEffect } from "react";
import "../../../../css/Settings/DeviceSetttings/deviceSettings.css";
import { useState } from "react";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSnackMsg, setSuccessSnack } from "../../Sales/Billing/Slice/billingScreenSlice";
import { getAllPrinterDevicesAPICall } from "./deviceSettingsAPI";
import DownloadButton from "./DownloadButton";

export const DeviceSettings = () => {
  
  const dispatch=useDispatch()

  const {printersList}=useSelector((state)=>state.deviceSettingsSlice)
  const devices=["Printer","Attendance Device","Payment Device","Scanning Device"]
  const printersSize=["58","80"]
  const deviceFormsInitialState={
    receipt:null,
    receiptSize:null,
    receiptAuto:false,
    kot:null,
    kotAuto:false,
    kotSize:null,
    barCode:null,
  }

  const [deviceForms, setDeviceForms] = useState(deviceFormsInitialState);

  const [activeBtn, setActiveBtn] = useState("Printer");

  
  const clickDeviceSettingsPrinter = (key) => {
    setActiveBtn(key);
  };

  const devicesOnChange=(key)=>(e,newValue)=>{
    const {value}=e.target;
    setDeviceForms({...deviceForms,[key]:newValue!==undefined ? newValue:value});
  }

  const deviceSubmitClick=()=>{

    let printSettings={
      receiptPrinter:deviceForms?.receipt,
      kotPrinter:deviceForms?.kot,
      receiptAuto:deviceForms?.receiptAuto,
      kotAuto:deviceForms?.kotAuto,
      receiptSize:deviceForms?.receiptSize,
      kotSize:deviceForms?.kotSize,
    }
    localStorage.setItem("PrinterSettings",JSON.stringify(printSettings))
    dispatch(setSnackMsg("Device Settings updated"))
    dispatch(setSuccessSnack(true))
  }

  useEffect(()=>{
    getAllPrinterDevicesAPICall()
    let printSettingsString=localStorage.getItem('PrinterSettings')
    let printSettings=JSON.parse(printSettingsString)
    setDeviceForms({...deviceForms,
                    receipt:printSettings?.receiptPrinter || null,
                    kot:printSettings?.kotPrinter || null,
                    receiptSize:printSettings?.receiptSize || null,
                    kotSize:printSettings?.kotSize || null,
                    receiptAuto:printSettings?.receiptAuto || false,
                    kotAuto:printSettings?.kotAuto || false,
                  });

  },[])


  return (
    <div className="device-settings-container">
        <p className="breadcrumb">Device Settings</p>

        <div className="device-settings-body">

      <div className="device-settings-container-left device-settings-container-left1">

        <div className="device-settings-container-left-section">
          {
          devices.map((device,index) =>
            <div
              key={index} 
              // onClick={()=>clickDeviceSettingsPrinter(device)}
              className={`device-settings-container-left-list ${activeBtn===device?"focus":'disabled-list'}`}
            >
              <ul style={{width:"100%"}}>
                <li>
                  {device}

                 {device==="Printer" && <DownloadButton style={{marginLeft:"60px"}}/>}
                </li>
              </ul>
            </div>
          )  
        }
        </div>
      </div>
      <div className="device-settings-container-right">
        {activeBtn === "Printer" ? (
          <div>
            <div className="device-settings-container-right-section">
              <div className="device-settings-container-right-printer">
                <div className="device-settings-container-right-printer-top">
                  <p>Receipt</p>
                  <div className="justify-center" style={{gap:"10px"}}> 
                    <p>Auto</p>
                    <input 
                    className="toggle-checkbox"
                    type="checkBox" 
                    checked={deviceForms.receiptAuto}
                    onChange={(e)=>setDeviceForms({...deviceForms,receiptAuto:e.target.checked})}
                    />
                  </div>
                </div>
                <div className="global-single-input auto-complete" style={{ width: "100%" }}>
                  <div className="justify-center" style={{justifyContent:"start",gap:"5px"}}> 

                  <Autocomplete 
                  options={printersList || []}
                  getOptionLabel={(option)=>option}
                  value={deviceForms?.receipt} onChange={devicesOnChange("receipt")}
                  renderInput={(params)=>(
                    <TextField {...params}/>
                  )}
                  sx={{width: "90%"}}
                  />
                  <Autocomplete 
                  options={printersSize}
                  getOptionLabel={(option)=>option}
                  value={deviceForms?.receiptSize} 
                  onChange={devicesOnChange("receiptSize")}
                  renderInput={(params)=>(
                    <TextField {...params} placeholder="size"/>
                  )}
                  sx={{height:"29px",paddingTop:"6px"}}
                  />
                  </div>
                </div>
              </div>
            </div>
            <div className="device-settings-container-right-section">
              <div className="device-settings-container-right-printer">
                <div className="device-settings-container-right-printer-top">
                  <p>KOT</p>
                  <div className="justify-center" style={{gap:"10px"}}> 
                    <p>Auto</p>
                    <input 
                    className="toggle-checkbox"
                    type="checkBox" 
                    checked={deviceForms.kotAuto}
                    onChange={(e)=>setDeviceForms({...deviceForms,kotAuto:e.target.checked})}
                    />
                  </div>
                </div>
                <div className="global-single-input auto-complete" style={{ width: "100%" }}>

                <div className="justify-center" style={{justifyContent:"start",gap:"5px"}}> 

                <Autocomplete 
                  options={printersList || []}
                  getOptionLabel={(option)=>option}
                  value={deviceForms?.kot} onChange={devicesOnChange("kot")}
                  renderInput={(params)=>(
                    <TextField {...params}/>
                  )}
                  sx={{width: "90%"}}
                  />

                <Autocomplete
                  options={printersSize}
                  getOptionLabel={(option) => option}
                  value={deviceForms?.kotSize}
                  onChange={devicesOnChange("kotSize")}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="size" />
                  )}
                  sx={{ height: "29px", paddingTop: "6px" }}
                />
                </div>
                </div>
              </div>
            </div>
            <div className="device-settings-container-right-section">
              <div className="device-settings-container-right-printer">
                <div className="device-settings-container-right-printer-top">
                  <p>BARCODE</p>
                </div>
                <div className="global-single-input auto-complete" style={{ width: "100%" }}>
                <Autocomplete 
                  options={printersList || []}
                  getOptionLabel={(option)=>option}
                  value={deviceForms?.barCode} onChange={devicesOnChange("barcode")}
                  renderInput={(params)=>(
                    <TextField {...params}/>
                  )}
                  />
                </div>
              </div>
            </div>
          </div>
        ): undefined}
        <div className="device-settings-right-container-button">
          <button className="btn btn-primary" onClick={deviceSubmitClick}>Submit</button>
        </div>
      </div>
      </div>
    </div>
  );
};
