import { IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactToPrint from 'react-to-print'
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter'
import CustomPagination from '../../../../Single Components/CustomPagination'
import { ChequeRegisterReportAPIcall } from './GeneralReportAPI'
import { today } from '../../../../../Js/Date'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { filterObjFromList } from '../../../../../Js/generalFunctions'
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI'
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI'
import ExcelJS from "exceljs"
import TableListActionButtons from '../../../../Single Components/ActionButtons/TableListActionButtons'

const ChequeRegisterReport = () => {
  const navigate = useNavigate()


  const chequeList = useSelector((state) => state.generalReport.chequeRegiter)

  const [FormDataInfo, setFormDataInfo] = useState([])
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [filteredData, setFilteredData] = useState({}); 
  const [currentPage, setCurrentPage] = useState(1);
  const [symbol, setSymbol] = useState(null);

  const totalPages = chequeList?.pages;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const currencyListUpdated = useSelector(
    (state) => state?.viewUpdatedCurrency?.value
  );
  const singleView = (r) => {
    localStorage.setItem("singleViewById", r?._id)
    navigate("/userdashboard/accounts/customer/payments/create", { state: { isCheque: true } });
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);
  };
  const formatFilterInfo = (filters) => {
    const parts = [];
  
    if (filters?.fromDate && filters?.toDate) {
      parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
    }
    console.log(filters?.fromDate);

    if (filters?.branchList) {
      parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    }
   
    
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Cheque Register Report");
    sheet.properties.defaultRowHeight = 20;
  
    // Add header section
    const titleRow = sheet.addRow(["Cheque Register Report"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells('A1:I1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];
  
    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 15, bold: true };
        sheet.mergeCells(`A${index + 2}:I${index + 2}`);
        row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      });
    }
  
    // Add an empty row between header and table
    sheet.addRow([]);
  
    // Header row styling
    const headerRow = sheet.addRow(["Customer", "Branch", "Payment Date", "Reff","Payment Method","Cheque Number","Cheque Date","Amount","Status"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };
  
    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    });
  
    sheet.columns = [
      { header: "Customer", key: "customer", width: 50 },
      { header: "Branch", key: "branch", width: 20 },
      { header: "Payment Date", key: "paymentDate", width: 20 },
      { header: "Reff", key: "paymentRef", width: 20 },
      { header: "Payment Method", key: "paymentMethod", width: 20 },
      { header: "Cheque Number", key: "chequeNo", width: 20 },
      { header: "Cheque Date", key: "chequeDate", width: 20 },
      { header: "Amount", key: "totalAmount", width: 20 },
      { header: "Cheque Register Report", key: "status", width: 20 },
    ];
  
    chequeList?.report?.forEach((item) => {
      const row = sheet.addRow({
        customer: item.customer,
        branch: item.branch,
        paymentDate: item.paymentDate,
        paymentRef: item.paymentRef,
        paymentMethod: item.paymentMethod,
        chequeNo: item.chequeNo,
        chequeDate: item.chequeDate,
        totalAmount: `${symbol} ${item.totalAmount?.toFixed(2)}`,
        status: item.status,
      });
  
      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFC3D4F7" }, // Use full hex code with "FF" prefix for better compatibility
      };
      
      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber === 4) { // Assuming the balance column is the 4th column
          const value = parseFloat(item?.balance);
          if (value < 0) {
            cell.font = { color: { argb: "FFFF0000" } }; // Red color for negative values
          }
        }
      });
    });
  
    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      `${symbol} ${chequeList?.total?.totalAmount?.toFixed(2)}`,
      
    ]);
  
    totalRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
    });
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Cheque Register Report.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    // console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);
  useEffect(() => {
    ChequeRegisterReportAPIcall({ currentDate: today })
  }, [])
  return (
    <div className="global-page-parent-container updated-global-page-parent-container">
      <div className="justify-space-between list-create-head-bar">
        <h3>Cheque Register Report</h3>
        <div className="create-head-buttons-container">
         <TableListActionButtons downloadClick={downloadList} />
         <CategorySearchandFilter
            onData={handleDataFromChild}
            onBranchSelect={handleBranchSelect}
            onFilterChange={handleFilterChange}
          />
        </div>
      </div>

        <div className="justify-space-between pagination-container">
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
        <div className="invoice-table-container updated-table-container">

          <table>
            <thead>
              <tr>
                <th style={{ textAlignLast: "center" }}>Customer</th>
                <th style={{ textAlignLast: "center" }}>Branch</th>
                <th style={{ textAlignLast: "center" }}>Payment Date</th>
                <th style={{ textAlignLast: "center" }}> Reff</th>
                <th style={{ textAlignLast: "center" }}>Payment Method</th>
                <th style={{ textAlignLast: "center" }}>Cheque Number</th>
                <th style={{ textAlignLast: "center" }}>Cheque Date</th>
                <th style={{ textAlignLast: "center" }}>Amount</th>
                <th style={{ textAlignLast: "center" }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {chequeList?.report?.map((item, index) => (
                <tr key={index} onClick={() => singleView(item)}>
                  <td>{item.customer}</td>
                  <td>{item.branch}</td>
                  <td>{item.paymentDate}</td>
                  <td>{item.paymentRef}</td>
                  <td>{item.paymentMethod}</td>
                  <td>{item.chequeNo}</td>
                  <td>{item.chequeDate}</td>
                  <td>{item.totalAmount}</td>
                  <td>{item.status}</td>

                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={8}>Total</th>
                <th>{chequeList?.total?.totalAmount}</th>
              </tr>
            </tfoot>
          </table>
        </div>

    </div>
  )
}

export default ChequeRegisterReport