import axios from "axios";
import { update_all_products_list, update_product_list_single_view } from "../../Redux/Product List/allProductsListSlice";
import { update_product_singleView } from "../../Redux/Product List/productSingleViewSlice";
import store from "../../Redux/store";
import { HEADERS } from "../UrlAndPaths";
import { setSubmitLoading } from "../../Redux/Dashboard/mainDashBoardSlice";

//view all products (material,readymade,accessories)
export const viewAllProductsAPICall = async (body, setIsLoading,setErrorSnack,setSnackMsg) => {
  if(setIsLoading !== undefined)  setIsLoading(true)
 
  await axios
    .post("readymade/viewproducts", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_all_products_list({ allProductsList: res.data }));
        if(setIsLoading !== undefined)  setIsLoading(false)
      }
    })
    .catch((err) => {
      store.dispatch(update_all_products_list({ allProductsList: undefined }));
      console.error(err.response);
      // console.log('error');
      switch (err.response.status) {
        case 404:
            setSnackMsg('No data available..')
          break;
        case 500:
            setSnackMsg('Server error!!')
          break;
        case 401:
            setSnackMsg('Unauthorized!!')
          break;
        case 403:
            setSnackMsg('Forbidden!!')
          break;
      
        default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
          break;
      }
      setErrorSnack(true)
      if(setIsLoading !== undefined)  setIsLoading(false)
    });
};
//view all products (material,readymade,accessories)
export const viewAllProductsForProductListAPICall = async (body, setIsLoading,setErrorSnack,setSnackMsg) => {
  if(setIsLoading !== undefined)  setIsLoading(true)
 
  await axios
    .post("readymade/productListForDashBoard", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_all_products_list({ allProductsList: res.data }));
        if(setIsLoading !== undefined)  setIsLoading(false)
      }
    })
    .catch((err) => {
      store.dispatch(update_all_products_list({ allProductsList: undefined }));
      // console.log('error');
      switch (err.response.status) {
        case 404:
            setSnackMsg('No data available..')
          break;
        case 500:
            setSnackMsg('Server error!!')
          break;
        case 401:
            setSnackMsg('Unauthorized!!')
          break;
        case 403:
            setSnackMsg('Forbidden!!')
          break;
      
        default:
          setSnackMsg('Unseen error occurred,Please contact to EYET Support!!!!')
          break;
      }
      setErrorSnack(true)
      if(setIsLoading !== undefined)  setIsLoading(false)
    });
};
export const ProductListFilterAPICall =(body,setIsLoading)=>{

  setIsLoading!==undefined&&setIsLoading(true)
    axios.post("readymade/searchProductListForDashBoard",body,HEADERS)
    .then((res)=>{
      if(res.status===200){
        store.dispatch(update_all_products_list({allProductsList:res.data}))
        setIsLoading!==undefined&&setIsLoading(false)
      }
    })
    .catch((err)=>{
      store.dispatch(update_all_products_list({allProductsList:undefined}))
      setIsLoading!==undefined&&setIsLoading(false)
    })
  
  }

  export const productListSingleViewAPICall=(body)=>{
    store.dispatch(setSubmitLoading(true))
    axios.post("readymade/ProductListForDashBoardSingleView",body,HEADERS)
    .then((res)=>{
      if(res.status===200){
        store.dispatch(setSubmitLoading(false))
        store.dispatch(update_product_list_single_view({productListSingleView:res.data}))
      }
    })
    .catch((err)=>{
      store.dispatch(setSubmitLoading(false))
      store.dispatch(update_product_list_single_view({productListSingleView:undefined}))
      
    })
  }


//Material Product Single View
export const materialProductSingleViewAPICall = async (body) => {
  await axios
    .post("materialproduct/materialproductbyid",body,HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_product_singleView({ productSingleView: res.data })
        );
      }
    })
    .catch((err) => {
      update_product_singleView({ productSingleView: undefined })
      console.error(err.response);
    });
};
//Readymade Product Single View
export const readymadeProductSingleViewAPICall = async (body) => {
  await axios
    .post("readymade/viewreadymadebyid", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_product_singleView({ productSingleView: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        update_product_singleView({ productSingleView: undefined })
      );
      console.error(err.response);
    });
};
//Accessory Product Single View
export const accessoryProductSingleViewAPICall = async (body) => {
  await axios
    .post("accessoryProduct/getAccessoryProductById", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          update_product_singleView({ productSingleView: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        update_product_singleView({ productSingleView: undefined })
      );
      console.error(err.response);
    });
};

