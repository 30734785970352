import React, { useRef } from "react";
import "./style.css";
import PrintIcon from "@mui/icons-material/Print";
import {
  APISTATUS,
  actionKeyName,
  listKotOrderStatus,
  orderStatus,
} from "../../utils/kotEnum";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedKOTOrder } from "../../kitchenOrderTicketSlice";
import { kotOrderActionAPI, updateSingleProductStatus } from "../../kotAPI's";
import OfferTagIconSvg from "../icons/OfferTagIconSvg";
import { useReactToPrint } from "react-to-print";
import KotPrint from "../print/KotPrint";
import { setSubmitLoading } from "../../../../../Redux/Dashboard/mainDashBoardSlice";
import { kotPrintRequestAPICall, printRequestAPICall } from "../../../Settings/Device Settings/deviceSettingsAPI";
import  html2pdf  from "html2pdf.js";
import { pdfBlobOptionsPrint } from "../../../../Single Components/GneralUtilities";
import { getPPI } from "../../../../../Js/generalFunctions";
import { setBillLoading } from "../../../Sales/Billing/Slice/billingScreenSlice";
const KotCard = ({
  elementData,
  day,
  orderNo,
  tableNo,
  tokenNo,
  orderInfo,
  status,
}) => {
  const componentRef = useRef();

  const dispatch = useDispatch();
  const handleHeaderBtnChange = (key) => {
    dispatch(setSelectedKOTOrder({ ...elementData, actionKeyName: key }));
    const body = {
      actionKeyName: key,
      body: { id: elementData._id },
    };
    kotOrderActionAPI(body);
  };

  const { kotOrderStatus, darkMode } = useSelector(
    (state) => state.kitchenOrderTicketSlice
  );

  //print btn action
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const kotPrintClick=async()=>{
    try {
        let printSettingsString=localStorage.getItem('PrinterSettings')
        let printSettings=JSON.parse(printSettingsString)
        
        let payload={
          printerWidth: printSettings?.kotSize==="80"?48:32,
          printerName: printSettings?.kotPrinter,
          transNo: tokenNo ,
          ordName: orderNo,
          orderDate:day,
          ordType:elementData?.ordType,
          waiterName: elementData?.waiterName || "-NIL-",
          orderInfo:orderInfo
      }
    if (printSettings?.kotPrinter) {
        kotPrintRequestAPICall(payload,handlePrint)
        }
        else{
          handlePrint()
        }
    } catch (error) {
        dispatch(setBillLoading(false))
    } finally {
      dispatch(setBillLoading(false));
    }
    
  }
  // single product selection
  const handleSingleProductClick = (data) => {
    if (kotOrderStatus.index === 2) {
      updateSingleProductStatus({
        body: {
          id: elementData._id,
          ordInfoId: data._id,
          statusType: data.status === APISTATUS.PENDING ? true : false,
        },
      });
    }
  };

  return (
    <div className="kot-card-wrapper">
      <div className="kot-card-container">
        <div className="kot-card-header">
          <p className="card-order-no">Order No : {orderNo}</p>
          {
            elementData?.ordType==="DELIVERY"?
            <p className="card-table-no">Delivery: {elementData?.aggregatorName?.toUpperCase()}</p>
            :<p className="card-table-no">Table No : {tableNo.join(",")}</p>

          }
          <p className="card-date">{day}</p>
          <p className="card-token-no">Token No : {tokenNo}</p>
          <p className="card-type">
            TYPE : <span>{elementData?.ordType}</span>
          </p>
        </div>
        <div className="card-print-container">
          <div>
            <span>waiter :</span> <span>{elementData?.waiterName}</span>
          </div>
          <div>
            <button onClick={kotPrintClick} className="card-print-btn">
              <PrintIcon fontSize="small" />
            </button>
          </div>
        </div>
        <div className="card-order-list-container">
          <ul>
            {orderInfo?.map((res, i) => (
              <li key={i} onClick={() => handleSingleProductClick(res)}>
                <div className="card-order-list">
                  {res.status === APISTATUS.COMPLETED &&
                    kotOrderStatus.index === 2 && (
                      <div
                        className="cross-line"
                        style={{
                          backgroundColor: darkMode ? "#ffffff" : "#000000",
                        }}
                      ></div>
                    )}
                  <div className="card-order-item-name">
                    {res.itemType === 3 && <OfferTagIconSvg />}
                    <span style={{ color: res.itemType === 3 ? "red" : "" }}>
                      {res?.productName}
                    </span>
                  </div>
                  <div className="card-order-quantity">
                    <span style={{ color: res.itemType === 3 ? "red" : "" }}>
                      {res?.qty}
                    </span>
                  </div>
                </div>
                {res?.customerNote && (
                  <div className="card-notes">
                    <p>Note : {res?.customerNote}</p>
                  </div>
                )}
                {/* combo offer  */}
                {res?.productList?.length > 0 && (
                  <ul className="kot-compo-offer-container">
                    {res?.productList?.map((res, i) => (
                      <li key={i}>
                        {res?.qty} X {res?.productName}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="card-btn-container">
          {status === listKotOrderStatus.NEW && (
            <button
              onClick={() => handleHeaderBtnChange(actionKeyName.TOCOOK)}
              className={orderStatus.TOCOOK}
            >
              <span>To Cook</span>
            </button>
          )}
          {/* {status === listKotOrderStatus.NEW && ( 
            <button
              onClick={() => handleHeaderBtnChange(actionKeyName.REJECT)}
              className={orderStatus.REJECT}
            >
              <span>Reject</span>
            </button>
          )} */}
          {status === listKotOrderStatus.PREPARING && (
            <button
              onClick={() => handleHeaderBtnChange(actionKeyName.COMPLETED)}
              className={orderStatus.COMPLETE}
            >
              <span>Completed</span>
            </button>
          )}
          {status === listKotOrderStatus.COMPLETED && (
            <button
              onClick={() => handleHeaderBtnChange(actionKeyName.DELIVERY)}
              className={elementData?.ordType==="DELIVERY"?orderStatus.PICKUP:orderStatus.DELIVERY}
            >
              <span>
              {
                elementData?.ordType==="DELIVERY"?"Ready to pickup":"Delivery"
              }
              </span>
            </button>
          )}
          {status === listKotOrderStatus.COMPLETED && (
            <button
              onClick={() => handleHeaderBtnChange(actionKeyName.RESET)}
              className={orderStatus.RESET}
            >
              <span>Reset</span>
            </button>
          )}
        </div>

        <div className='print-parent'>
          <div ref={componentRef}>
            <KotPrint
              billNo={orderNo}
              waiter={elementData?.waiterName}
              orderDate={day}
              orderData={orderInfo}
              orderType={elementData?.ordType}
              tokenNumber={tokenNo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KotCard;
