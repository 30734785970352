import React, { useEffect } from "react";
import "../../../../css/Staff/DashBoard/staffDashboard.css";
import totalSalaryIcon from "../../../../Assets/SVG/staffDashboardIcons/TOTAL SALARY PAID.svg";
import totalAttendance from "../../../../Assets/SVG/staffDashboardIcons/TOTAL ATTENDENCE.svg";
import totalLeave from "../../../../Assets/SVG/staffDashboardIcons/TOTAL LEAVE.svg";
// import { Doughnut, Line } from "react-chartjs-2";
import { useState } from "react";
import { CustomRadioGroup } from "../../../Single Components/CustomRadioGroup";
import { useSelector } from "react-redux";
import { Dialog, IconButton, MenuItem, Select } from "@mui/material";
import {
  createEmpCountDoughnutData,
  monthLabels,
  todayLabels,
  weekLabels,
} from "./staffDashboardChartDatas";
import {
  convertMeridiemToIso,
  convertMonthToWord,
  dateAfterFiveDays,
  getCurrentTime,
  today,
} from "../../../../Js/Date";
import {
  addNewNoticeInBoard,
  deleteSingleNoticeAPICall,
  employeeCountChartAPICall,
  getNoticeboardAPICall,
  totalExpenseGraphAPICall,
} from "../../../../API/Staff/Staff Dashboard/staffDashboardAPI";
import Minutes from "../../../../Assets/JSON/minutes.json";
import Hours from "../../../../Assets/JSON/hours.json";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";

export const StaffDashboard = () => {
  const expenseGraphData = useSelector(
    (state) => state.expenseGraphSlice.value
  );
  const empCountGraph = useSelector((state) => state.empCountGraphSlice.value);
  const noticeboardData = useSelector((state) => state.noticeboardSlice.value);
  console.log("NoticeboardData--->",noticeboardData)
  //Current Time
  const [currentTime, setCurrentTime] = useState("00:00");
  const [expenseType, setExpenseType] = useState("week");
  const [noticeAddBoard, setNoticeAddBoard] = useState(false);

  //Add Notice board Form
  const addNoticeboardInitialState = {
    title: "",
    note: "",
    fromDate: today,
    toDate: dateAfterFiveDays,
    timeHour: 1,
    timeMinute: 0,
    meridiem: "AM",
  };
  const [addNoticeBoardForm, setAddNoticeBoardForm] = useState(
    addNoticeboardInitialState
  );

  //Update Listening States
  const [isModify, setIsModify] = useState(false);
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("default!!");

  //Line Chart Data
  const data = {
    labels:
      expenseType === "day"
        ? todayLabels
        : expenseType === "week"
        ? weekLabels
        : expenseType === "month"
        ? monthLabels
        : undefined,

    datasets: [
      {
        label: "Revenue",
        data: expenseGraphData || null,
        backgroundColor: "#3a00ff",
        borderColor: "#3a00ff",
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Revenue Graph",
      },
    },
  };

  //Custom Radio data
  const customRadio = [
    {
      id: "fstRadio",
      label: "Today",
      name: "customRadio",
      value: "day",
      
    },
    {
      id: "secondRadio",
      label: "Week",
      name: "customRadio",
      value: "week",
      checked: true
    },
    {
      id: "thirdRadio",
      label: "Month",
      name: "customRadio",
      value: "month",
    },
  ];

  //Doughnut chart data
  const doughnutData = {
    labels: ["Female", "Male"],
    datasets: [
      {
        data: createEmpCountDoughnutData(empCountGraph),
        backgroundColor: ["rgb(17 0 255)", "rgb(255 36 0)"],
        radius: 100,
      },
    ],
  };

  //Expense button Day || Week || Month
  const totalExpenseGraphChange = (e) => {
    setExpenseType(e.target.value);
  };
  const ClickNoticeAddBoard = () => {
    setNoticeAddBoard(true);
  };

  //*Functions
  //Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //Clear Add Notice board form
  const clearAddNoticeBoard = () => {
    setAddNoticeBoardForm(addNoticeboardInitialState);
  };

  //Close SuccessSnackbar
  const closeSuccessSnackbar=()=>{
    setOpenSuccessSnack(false)
  }

  const closeErrorSnackbar=()=>{
    setOpenErrorSnack(false)
  }

  //Close Add Notice board
  const closeAddNoticeBoardDialog=()=>{
    setNoticeAddBoard(false);
  }

  //Add Notice Board onChange Functions
  const getAddBoardData = (key) => (e) => {
    const { value } = e.target;

    switch (key) {
      case "title":
        setAddNoticeBoardForm({ ...addNoticeBoardForm, title: value });
        break;
      case "note":
        setAddNoticeBoardForm({ ...addNoticeBoardForm, note: value });
        break;
      case "fromDate":
        setAddNoticeBoardForm({ ...addNoticeBoardForm, fromDate: value });
        break;
      case "toDate":
        setAddNoticeBoardForm({ ...addNoticeBoardForm, toDate: value });
        break;
      case "timeHour":
        setAddNoticeBoardForm({ ...addNoticeBoardForm, timeHour: value });
        break;
      case "timeMinute":
        setAddNoticeBoardForm({ ...addNoticeBoardForm, timeMinute: value });
        break;
      case "meridiem":
        setAddNoticeBoardForm({ ...addNoticeBoardForm, meridiem: value });
        break;

      default:
        break;
    }
  };

  //Add Notice Final Submit
  const finalNoticeBody = {
    title: addNoticeBoardForm.title,
    note: addNoticeBoardForm.note,
    fromeDate: `${addNoticeBoardForm.fromDate} ${getCurrentTime()}`,
    toDate: `${addNoticeBoardForm.toDate} ${getCurrentTime()}`,
    time: convertMeridiemToIso(
      addNoticeBoardForm.timeHour?.toString(),
      addNoticeBoardForm.timeMinute?.toString(),
      addNoticeBoardForm.meridiem
    ),
  };
  const submitNotice = () => {
    if (addNoticeBoardForm.title === "") {
      alert("Enter title!!");
    } else if (addNoticeBoardForm.note === "") {
      alert("Enter Notes!!");
    } else {
      addNewNoticeInBoard(
        finalNoticeBody,
        setOpenSuccessSnack,
        setOpenErrorSnack,
        setSnackMsg,
        updateListener,
        clearAddNoticeBoard,
        closeAddNoticeBoardDialog
      );
    }
  };
  const deleteNoticeBoardClick=(id)=>()=>{
    console.log(id);
    deleteSingleNoticeAPICall({_id:id},updateListener)
  }
  //Get Current Time
  //   setInterval(()=>{
  //     let currentTime=getCurrentTime()
  //     console.log(currentTime);
  //     setCurrentTime(currentTime)
  //   },1000)
  const getCurrentPcTime=()=>{
      let now=new Date();
      return `${now.getHours().toString().padStart(2,"0")}:${now.getMinutes().toString().padStart(2, "0")}`
  }
  const [currentPcTime,setCurrentPcTime]=useState(getCurrentPcTime())
  //UseEffects

  //Call Total Expense graph api
  useEffect(() => {
    totalExpenseGraphAPICall({ timeFilter: expenseType });
  }, [expenseType]);

  //Call Emp Count api
  useEffect(() => {
    employeeCountChartAPICall();
    getNoticeboardAPICall();
  }, [isModify]);
  // get current pc time
  useEffect(()=>{
    let interval=setInterval(()=>{
      setCurrentPcTime(getCurrentPcTime());
    },1000)
    return ()=>clearInterval(interval)
  },[])
  return (
    <>
      <div className="global-page-parent-container staff-dashboard-container">
        <p className="breadcrumb">Staff &gt; Dashboard</p>

        <div
          className="employee-dashboard-date-time-picker"
          style={{ alignSelf: "flex-end" }}
        >
          <div className="employee-dashboard-date-picker">
            <input type="date" value={today} />
          </div>
          <div className="employee-dashboard-time-picker">
            <input type="time" value={currentPcTime} disabled/>
          </div>
        </div>

        
        <div className="employee-dashboard-card-container">
          <div className="employee-dashboard-card">
            <div
              className="employee-dashboard-card-icon-container"
              style={{ backgroundColor: "#f26149" }}
            >
              <img src={totalAttendance} alt="total salary" />
            </div>
            <div className="employee-dashboard-card-text-container">
              <h5>Total Employees</h5>
              <p>{empCountGraph?.Employeetotal || "##"}</p>
            </div>
          </div>
          <div className="employee-dashboard-card">
            <div
              className="employee-dashboard-card-icon-container"
              style={{ backgroundColor: "rgb(79 73 242)" }}
            >
              <img src={totalLeave} alt="total attendance" />
            </div>
            <div className="employee-dashboard-card-text-container">
              <h5>Total Departments</h5>
              <p>{empCountGraph?.departmentTotal || "##"}</p>
            </div>
          </div>
          <div className="employee-dashboard-card">
            <div
              className="employee-dashboard-card-icon-container"
              style={{ backgroundColor: "rgb(0 133 85)" }}
            >
              <img src={totalSalaryIcon} alt="total leave" />
            </div>
            <div className="employee-dashboard-card-text-container">
              <h5>Total Expense</h5>
              <p>{empCountGraph?.expenseTotal || "##"}</p>
            </div>
          </div>
        </div>

        <div className="staff-dashboard-body-container">
          <div className="staff-dashboard-total-expense-container">
            <div className="top-bar">
              <p>Total Expense</p>
              <CustomRadioGroup
                customRadioData={customRadio}
                value={expenseType}
                onChange={totalExpenseGraphChange}
              />
            </div>

            <div className="chart-container">
              {/* <Line options={options} data={data} /> */}
            </div>
          </div>

          <div className="staff-dashboard-notice-board-container">
            <div className="staff-dashboard-notice-board-container-top">
              <h3>Notice Board</h3>
              <button
                className="btn btn-notice-board"
                onClick={ClickNoticeAddBoard}
              >
                Add Board
              </button>
            </div>
            <div className="staff-dashboard-notice-board-container-body">            
              {noticeboardData?.slice()?.reverse()?.map((notice) => (
                <div className="staff-dashboard-notice-board-container-section">
                  <div className="staff-dashboard-notice-board-container-section-left">
                    <p>{convertMonthToWord(notice?.fromeDate)}</p>
                    <p>{notice?.fromeDate?.split("-")[2]}</p>
                  </div>
                  <div className="staff-dashboard-notice-board-container-section-right">
                    <h4>{notice?.title}</h4>
                    <p>{notice?.note}</p>
                  </div>
                   <IconButton 
                    onClick={deleteNoticeBoardClick(notice?._id)} 
                    className="staff-dashboard-notice-board-container-section-delete-btn">
                    <i class="bi bi-trash3 delete-icon"></i>
                  </IconButton>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="staff-dashboard-body-container-two">
          <div className="staff-dashboard-body-container-two-left">
            <h3>Employee</h3>
            <div className="chart-container staff-dashboard-doughnut">
              {/* <div className="chart-container-total-count">
                                <h4>Total</h4>
                                <h1>500</h1>
                            </div> */}
              {/* <Doughnut
                data={doughnutData}
                options={{
                  responsive: true,
                  cutout: "70%",
                  plugins: {
                    legend: {
                      display: true,
                      position: "bottom",
                      labels: {
                        boxWidth: 18,
                        boxHeight: 18,
                      },
                    },
                  },
                }}
              ></Doughnut> */}
            </div>
          </div>


          <div className="staff-dashboard-body-container-two-right">
            <div className="top-bar">
              <h3>Notifications</h3>
              <CustomRadioGroup
                customRadioData={customRadio}
                // value={""}
                // onChange={totalExpenseGraphChange}
                backgroundColor="#f60000"
              />
            </div>
            
            <div className="staff-dashboard-body-container-two-right-notification">
              <div className="staff-dashboard-body-container-two-right-notification-left"></div>
              <div className="staff-dashboard-body-container-two-right-notification-content">
                <h3>Job Application Mail</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <div className="staff-dashboard-body-container-two-right-notification-right">
                <p>10 min ago</p>
              </div>
            </div>
            <div className="staff-dashboard-body-container-two-right-notification">
              <div
                className="staff-dashboard-body-container-two-right-notification-left"
                style={{ backgroundColor: "#efeb7c" }}
              ></div>
              <div className="staff-dashboard-body-container-two-right-notification-content">
                <h3>Job Application Mail</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <div className="staff-dashboard-body-container-two-right-notification-right">
                <p>10 min ago</p>
              </div>
            </div>
            <div className="staff-dashboard-body-container-two-right-notification">
              <div
                className="staff-dashboard-body-container-two-right-notification-left"
                style={{ backgroundColor: "#7cbdef" }}
              ></div>
              <div className="staff-dashboard-body-container-two-right-notification-content">
                <h3>Job Application Mail</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <div className="staff-dashboard-body-container-two-right-notification-right">
                <p>10 min ago</p>
              </div>
            </div>
            <div className="staff-dashboard-body-container-two-right-notification">
              <div
                className="staff-dashboard-body-container-two-right-notification-left"
                style={{ backgroundColor: "#efeb7c" }}
              ></div>
              <div className="staff-dashboard-body-container-two-right-notification-content">
                <h3>Job Application Mail</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <div className="staff-dashboard-body-container-two-right-notification-right">
                <p>10 min ago</p>
              </div>
            </div>
          </div>

          
        </div>
        <div className="staff-dashboard-body-container-bottom">
          <div className="top-bar">
            <h3>Attendance</h3>
            <CustomRadioGroup
              customRadioData={customRadio}
              // value={""}
              backgroundColor="#f60000"
            />
          </div>
          <div className="global-table-container">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Employee ID</th>
                  <th>Name</th>
                  <th>In</th>
                  <th>Out</th>
                  <th>Total Work Hours</th>
                  <th>Break In</th>
                  <th>Break Out</th>
                  <th>Total Break Hours</th>
                  <th>Date</th>
                  <th>Leave</th>
                  <th>View</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>01</td>
                  <td>EMP-W2200</td>
                  <td>Niyhad</td>
                  <td>9:00 AM</td>
                  <td>6:00 PM</td>
                  <td>9 hours</td>
                  <td>12:00 PM</td>
                  <td>3:00 PM</td>
                  <td>4 Hours</td>
                  <td>09-12-2022</td>
                  <td>20</td>
                  <td>
                    <IconButton>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <button className="btn btn-green-outlined">Active</button>
                  </td>
                </tr>
                <tr>
                  <td>01</td>
                  <td>EMP-W2200</td>
                  <td>Niyhad</td>
                  <td>9:00 AM</td>
                  <td>6:00 PM</td>
                  <td>9 hours</td>
                  <td>12:00 PM</td>
                  <td>3:00 PM</td>
                  <td>4 Hours</td>
                  <td>09-12-2022</td>
                  <td>20</td>
                  <td>
                    <IconButton>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <button className="btn btn-green-outlined">Active</button>
                  </td>
                </tr>
                <tr>
                  <td>01</td>
                  <td>EMP-W2200</td>
                  <td>Niyhad</td>
                  <td>9:00 AM</td>
                  <td>6:00 PM</td>
                  <td>9 hours</td>
                  <td>12:00 PM</td>
                  <td>3:00 PM</td>
                  <td>4 Hours</td>
                  <td>09-12-2022</td>
                  <td>20</td>
                  <td>
                    <IconButton>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <button className="btn btn-green-outlined">Active</button>
                  </td>
                </tr>
                <tr>
                  <td>01</td>
                  <td>EMP-W2200</td>
                  <td>Niyhad</td>
                  <td>9:00 AM</td>
                  <td>6:00 PM</td>
                  <td>9 hours</td>
                  <td>12:00 PM</td>
                  <td>3:00 PM</td>
                  <td>4 Hours</td>
                  <td>09-12-2022</td>
                  <td>20</td>
                  <td>
                    <IconButton>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <button className="btn btn-green-outlined">Active</button>
                  </td>
                </tr>
                <tr>
                  <td>01</td>
                  <td>EMP-W2200</td>
                  <td>Niyhad</td>
                  <td>9:00 AM</td>
                  <td>6:00 PM</td>
                  <td>9 hours</td>
                  <td>12:00 PM</td>
                  <td>3:00 PM</td>
                  <td>4 Hours</td>
                  <td>09-12-2022</td>
                  <td>20</td>
                  <td>
                    <IconButton>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <button className="btn btn-green-outlined">Active</button>
                  </td>
                </tr>
                <tr>
                  <td>01</td>
                  <td>EMP-W2200</td>
                  <td>Niyhad</td>
                  <td>9:00 AM</td>
                  <td>6:00 PM</td>
                  <td>9 hours</td>
                  <td>12:00 PM</td>
                  <td>3:00 PM</td>
                  <td>4 Hours</td>
                  <td>09-12-2022</td>
                  <td>20</td>
                  <td>
                    <IconButton>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <button className="btn btn-green-outlined">Active</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Dialog
        open={noticeAddBoard}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setNoticeAddBoard(false)}
      >
        <div className="notice-add-board-container">
          <h3>Add Board</h3>
          <div className="global-single-input notice-add-board-input">
            <p>Title</p>
            <input
              type="text"
              value={addNoticeBoardForm.title}
              onChange={getAddBoardData("title")}
              placeholder="Enter title.."
            />
          </div>
          <div className="global-single-input notice-add-board-input">
            <p>Note</p>
            <textarea
              value={addNoticeBoardForm.note}
              onChange={getAddBoardData("note")}
              placeholder="Enter notes..."
            ></textarea>
          </div>
          <div className="notice-add-board-container-section">
            <div className="global-single-input notice-add-board-input">
              <p>From date</p>
              <input
                type="date"
                value={addNoticeBoardForm.fromDate}
                onChange={getAddBoardData("fromDate")}
              />
            </div>
            <div className="global-single-input notice-add-board-input">
              <p>To Date</p>
              <input
                type="date"
                value={addNoticeBoardForm.toDate}
                onChange={getAddBoardData("toDate")}
              />
            </div>
          </div>
          <div className="notice-add-board-container-section-two">
            <div className="global-single-input notice-add-board-input">
              <p>Time</p>
              <Select
                style={{ backgroundColor: "#eef2f9" }}
                value={addNoticeBoardForm.timeHour}
                onChange={getAddBoardData("timeHour")}
              >
                {Hours.map((hour) => (
                  <MenuItem value={hour.hour}>{hour.hour}</MenuItem>
                ))}
              </Select>
            </div>
            <div className="global-single-input notice-add-board-input">
              <Select
                style={{ backgroundColor: "#eef2f9" }}
                value={addNoticeBoardForm.timeMinute}
                onChange={getAddBoardData("timeMinute")}
              >
                {Minutes.map((minute) => (
                  <MenuItem value={minute.minute}>{minute.minute}</MenuItem>
                ))}
              </Select>
            </div>
            <div className="global-single-input notice-add-board-input">
              <Select
                style={{ backgroundColor: "#eef2f9" }}
                value={addNoticeBoardForm.meridiem}
                onChange={getAddBoardData("meridiem")}
              >
                <MenuItem value="AM">AM</MenuItem>
                <MenuItem value="PM">PM</MenuItem>
              </Select>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              className="btn btn-secondary-outlined"
              style={{ alignSelf: "center", margin: "3%" }}
              onClick={closeAddNoticeBoardDialog}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              style={{ alignSelf: "center", margin: "3%" }}
              onClick={submitNotice}
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>

              <SuccessSnackbar open={openSuccessSnack} handleClose={closeSuccessSnackbar} message={snackMsg}/>
              <ErrorSnackbar open={openErrorSnack} handleClose={closeErrorSnackbar} message={snackMsg}/>


    </>
  );
};
