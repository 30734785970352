import axios from "axios"
import { HEADERS } from "../../../../API/UrlAndPaths"
import store from "../../../../Redux/store"
import { post_opening_balance, view_customer_vendor_list, view_opening_balance } from "./OpeningBalanceSlice"
import { setSubmitLoading } from "../../../../Redux/Dashboard/mainDashBoardSlice"

 export const draftOpeningBalanceAPI = async(body,draftFn,setIsLoading,setEditDataId)=>{
    setIsLoading !== undefined && setIsLoading(true)
await axios.post("account/addOpeningBalanceEntry",body,HEADERS)
.then((res)=>{
    if(res.status===200){
        // store.dispatch(post_opening_balance({addOpening:res.data}))
        setIsLoading !== undefined && setIsLoading(false)
        draftFn()
        setEditDataId(res.data?._id)
   
    }
})
.catch((err)=>{
        // store.dispatch(post_opening_balance({addOpening:undefined}))
        setIsLoading !== undefined && setIsLoading(false)
})
 }


 export const postOpeningBalanceAPI = async(body,draftFn,setIsLoading)=>{
    setIsLoading !== undefined && setIsLoading(true)
await axios.post("account/postopeningbalance",body,HEADERS)
.then((res)=>{
    if(res.status===200){
        setIsLoading !== undefined && setIsLoading(false)
        draftFn()
    }
})
.catch((err)=>{   
        setIsLoading !== undefined && setIsLoading(false)
})
 }

 export const ViewOpeningBalanceAPI = async()=>{
    // setIsLoading !== undefined && setIsLoading(true)
await axios.post("account/viewBalanceEntry",{},HEADERS)
.then((res)=>{
    console.log(res);
    if(res.status===200){
        store.dispatch(view_opening_balance({ViewOpening:res.data}))
        // setIsLoading !== undefined && setIsLoading(false)
    }
})
.catch((err)=>{
        store.dispatch(view_opening_balance({ViewOpening:undefined}))
        // setIsLoading !== undefined && setIsLoading(false)
})
 }


 // view customer vendor api call
 export const viewCustomerVendorAPICall= async()=>{
    store.dispatch(setSubmitLoading(true))
    axios.post("account/viewAllCustomerForDropdown",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
        store.dispatch(setSubmitLoading(false))
        store.dispatch(view_customer_vendor_list({customerVendorData:res.data}))
    }
})
.catch((err)=>{
       store.dispatch(setSubmitLoading(false))
      store.dispatch(view_customer_vendor_list({customerVendorData:undefined}))
   })
}

 export const ResetOpeningBalanceAPI = async()=>{
    // setIsLoading !== undefined && setIsLoading(true)
await axios.post("account/resetOpeningBalanceToDraft",{},HEADERS)
.then((res)=>{
    console.log(res);
    if(res.status===200){
        store.dispatch(view_opening_balance({ViewOpening:res.data}))
        // setIsLoading !== undefined && setIsLoading(false)
    }
})
.catch((err)=>{
        store.dispatch(view_opening_balance({ViewOpening:undefined}))
        // setIsLoading !== undefined && setIsLoading(false)
})

 }