import { IconButton, Tooltip } from "@mui/material";
import "../../../../css/Purchase Manager/StockAdjustment/requestForQuatationNw.css";
import React, { useState } from "react";
import postRibbon from "../../../../Assets/statusRibbon/post.png";
import cancel from "../../../../Assets/statusRibbon/cancel.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import rfqRibbon from "../../../../Assets/statusRibbon/rfq.png";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { convertDateFormat, today } from "../../../../Js/Date";
import { viewPurchaseOrderSingleView } from "../../../../API/Purchase Manager/NewPurchaseOrder/PurchaseOrder";

import Template1 from "../../SalesNew/SalesOrders/SalesQuotation/Template1";
import Template2 from "../../SalesNew/SalesOrders/SalesQuotation/Template2";

import { set_Back_button_action } from "../../../Single Components/listSlice/filterCategorySlice";
import { getTemplateAPI } from "../../SalesNew/SalesOrders/SalesQuotation/salesQuatationAPI";
import PrintTempOne from "../../Accounts/PrintComponents/printTemplates/PrintTempOne";
// import usePaginatedPrint from "../../Accounts/PrintComponents/printTemplates/customPintHook/usePrintPageHook";
import { InvoiceContent } from "../../Accounts/PrintComponents/printTemplates/common/InvoiceContent";
import { printSection } from "../../Accounts/PrintComponents/printTemplates/common/enum";

export const RequestForQuatationSingleView = () => {
  //paginated print -new -experiment

  // const printComponentRef = useRef(null);
  // // const { PrintWrapper, handlePrint } = usePaginatedPrint(printComponentRef);

  //new 2
  const componentRef = useRef();
  //used to navigate
  let navigateTo = useNavigate();
  const singleViewID = localStorage.getItem("singleViewById");

  let paymentsPrintNew = useRef();

  const dispatch = useDispatch();

  const decimalPosition = localStorage.getItem("decimalPosition");
  let paymentsPrint = useRef();

  const template = useSelector(
    (state) => state?.salesQuatationSlice?.setTemplate
  );

  // data of selected list
  const singleList = useSelector(
    (state) => state?.RfqSlice?.purchaseOrderSingleView
  );

  // company details and info
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  // initial values
  const formValuesInitialState = {
    status: "",
    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
    companyTRN: "",

    customerName: "",
    customerEmail: "",
    customerAddress: "",
    customerContact: "",
    trn: "",

    dateN1: "Quotation Date",
    dateN2: "Expiry Date",

    termsAndCondition: "",

    payTerm: "",
    name: "",
    firstDate: "",
    secondDate: "",

    address: "Vendor ",
    lpo: "",
    ref: "",
    sequence: "RFQ No",
    totalAmount: "",
    subtotal: "",
    inclusiveTax: "",
    exclusiveTax: "",
    total: "",
    label: "RFQ",
  };
  const [formValues, setFormValues] = useState(formValuesInitialState);

  // table values
  const [data, setData] = useState([]);
  const [tax, setTax] = useState([]);
  // api to fetch companyprofile data
  useEffect(() => {
    getTemplateAPI();
    viewPurchaseOrderSingleView({ _id: singleViewID });
  }, []);

  // assigning values
  useEffect(() => {
    setFormValues({
      ...formValues,
      status: singleList?.type,
      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
      companyTRN: companyProfileData?.companyTRN || "",

      customerName: singleList?.vendorAddress?.vendorName || "",
      customerAddress: singleList?.vendorAddress?.streetName || "",
      customerContact: singleList?.vendorAddress?.mobileNo || "",
      customerEmail: singleList?.vendorAddress?.email || "",
      trn: singleList?.vendorAddress?.TRN || "",

      termsAndCondition: singleList?.termsAndConditions || "",

      ref: singleList?.reference || "",
      lpo: singleList?.LpoNo || "",
      name: singleList?.sequence || "",
      firstDate: singleList?.quotationDate || "",
      secondDate: singleList?.expiryDate || "",
      payTerm: singleList?.PAYMENTTERM || "",

      subtotal: singleList?.subTotal || "",
      inclusiveTax: singleList?.totalTaxInclusive || "",
      exclusiveTax: singleList?.totalTaxExclusive || "",
      total: singleList?.total || "",
      totalAmount: singleList?.totalAmount || "",
    });

    setData(singleList?.productInfo);
    setTax(singleList?.taxDetails);
  }, [singleList, companyProfileData]);

  const [domain, setDomain] = useState("");
  useEffect(() => {
    if (formValues) {
      let element = formValues;
      const domainAddress = `${window.location.protocol}//${
        window.location.host
      }/posReaderPreview?path=admin/viewqrcodedata&db=${encodeURIComponent(
        element?.db
      )}&vendororcusid=${encodeURIComponent(
        element?.supplierId
      )}&type=${encodeURIComponent(
        element?.qrcodetype
      )}&orderId=${encodeURIComponent(element?._id)}`;
      setDomain(domainAddress);
    }
  }, [formValues]);
  return (
    <div
      className="main-container"
      style={{ backgroundColor: "unset", padding: "0" }}
    >
      <div
        className="first-div-container"
        style={{ backgroundColor: "white", justifyContent: "space-between" }}
      >
        {/* <div className="post-ribbon">
          {formValues?.status === "RFQ" && <img src={rfqRibbon} alt="" />}
          {formValues?.status === "POSTED" && <img src={postRibbon} alt="" />}
          {formValues?.status === "CANCEL" && <img src={cancel} alt="" />}
        </div> */}
        <div style={{ display: "flex", gap: "8px" }}>
          <Tooltip title="Edit">
            <IconButton
              onClick={() =>
                navigateTo(
                  "/userdashboard/purchaseManager/CreateRequestForQuatation"
                )
              }
            >
              <i class="bi bi-pencil-square edit-icon1"></i>
            </IconButton>
          </Tooltip>
          <h3>
            <span
              style={{ fontSize: "medium", color: "black", cursor: "pointer" }}
              onClick={() => {
                navigateTo(
                  "/userdashboard/purchaseManager/RequestForQuatation"
                );
                dispatch(
                  set_Back_button_action({ backButtonClickAction: true })
                );
              }}
            >
              Request For Quotation &gt;
            </span>
            <span style={{ fontSize: "medium", paddingLeft: "8px" }}>
              {formValues?.name}
            </span>
          </h3>
        </div>
        {/* <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div">
              <i style={{ color: "black" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrint}
        /> */}
        <div>
          <Tooltip title="Share">
            <IconButton>
              <i
                class="bi bi-share"
                style={{
                  backgroundColor: "#9797e9",
                  color: "white",
                  fontSize: "medium",
                  padding: "8px",
                  borderRadius: "2px",
                }}
              ></i>
            </IconButton>
          </Tooltip>
          {/* new print  */}
          <ReactToPrint
            // The print button
            trigger={() => (
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i
                  style={{
                    color: "white",
                    backgroundColor: " #d787e7",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                  class="bi bi-printer"
                ></i>
              </IconButton>
            )}
            // The content to be printed (InvoiceContent component)
            content={() => componentRef.current}
            // Set the page style for printing
            pageStyle="@page { size: A4; margin: 0; }"
          />

          <Tooltip title="Download">
            <IconButton>
              <i
                class="bi bi-arrow-down-circle"
                style={{
                  backgroundColor: "rgb(255 118 133 / 69%)",
                  color: "white",
                  fontSize: "medium",
                  padding: "8px",
                  borderRadius: "2px",
                }}
              ></i>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* <hr className="line" /> */}
      <div
        style={{
          backgroundColor: "white",
          height: "80vh",
          marginTop: "4px",
          position: "relative",
        }}
      >
        <div className="second-main-container">
          <div className="post-ribbon" style={{ right: "0px", top: "0px" }}>
            {formValues?.status === "RFQ" && <img src={rfqRibbon} alt="" />}
            {formValues?.status === "POSTED" && <img src={postRibbon} alt="" />}
            {formValues?.status === "CANCEL" && <img src={cancel} alt="" />}
          </div>
          <div className="left-side-div">
            {/* <p style={{marginTop:"35px"}}>Leeyet</p> */}
            <img src={formValues?.companyLogo} alt={formValues?.companyName} />
            <p>{formValues?.companyAddress}</p>
            <p>{formValues?.companyContact}</p>
            {/* <p>kozhikod 673016</p>
          <p>phone:xxxxxxx</p> */}

            <h1 className="vendor-head">vendor Address</h1>
            <p>{formValues?.customerName}</p>
            <p>{formValues?.vendorAddress}</p>
            <p>{formValues?.customerContact}</p>
            {/* <p>Gov.Cyber park, sahya Building, 2nd floor,<br></br> near hilite mall, palazhi.</p>
          <p>kozhikod 673016</p>
          <p>phone:xxxxxxx</p> */}
          </div>
          <div className="right-side-div">
            <h1>Request for Quotation</h1>

            <p>/{formValues?.name}</p>

            <div className="date-div">
              <p>
                {" "}
                <span class="date-container-single">Quotation Date:</span>
                {formValues?.firstDate}
              </p>
              <p>
                {" "}
                <span class="date-container-single">Expiry Date:</span>
                {formValues?.secondDate}
              </p>
            </div>
          </div>
        </div>

        <div className="Table-container">
          <div className="Table-data" style={{ maxHeight: "64vh" }}>
            <table>
              <thead>
                <tr style={{ backgroundColor: "#eaeef9" }}>
                  <th>SI NO</th>
                  <th>PRODUCT</th>
                  <th>DESCRIPTION</th>
                  <th>QTY</th>
                  <th>UOM</th>
                  <th>RATE</th>
                  <th>AMOUNT</th>
                  <th>DISC%</th>
                  <th>VAT </th>
                  <th>VAT AMOUNT</th>
                  <th>NET AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>{item.product}</td>
                    <td>{item?.label}</td>
                    <td>{item.qty}</td>
                    <td>{item.uomName}</td>
                    <td>{item.unitPrice?.toFixed(decimalPosition)}</td>
                    <td>
                      {(item.qty * item.unitPrice)?.toFixed(decimalPosition)}
                    </td>
                    <td>{item.discount}%</td>
                    <td>
                      {item.taxIdList?.map((r, i) => (
                        <p
                          key={i}
                          className="tax"
                          style={{ margin: "1px", border: "none" }}
                        >
                          {r?.taxName}
                        </p>
                      ))}
                    </td>
                    <td>{item.taxAmt?.toFixed(decimalPosition)}</td>
                    <td>
                      {item.includedPrice === false
                        ? (item.lineTotal + item.taxAmt)?.toFixed(
                            decimalPosition
                          )
                        : item.lineTotal?.toFixed(decimalPosition)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div
              style={{
                backgroundColor: "#e9e9e9",
                display: "flex",
                textAlign: "end",
                paddingInline: "5%",
              }}
            >
              <p style={{ margin: "0" }}>Total</p>
              {data && data.length > 0 && (
                <p style={{ width: "58%", margin: "0", fontSize: "smaller" }}>
                  {" "}
                  {data
                    ?.reduce((total, item) => {
                      const qty = typeof item.qty === "number" ? item.qty : 0; // Check if item.qty is a number, otherwise default to 0
                      const unitPrice =
                        typeof item.unitPrice === "number" ? item.unitPrice : 0; // Check if item.unitPrice is a number, otherwise default to 0
                      return total + qty * unitPrice;
                    }, 0)
                    ?.toFixed(decimalPosition)}
                </p>
              )}

              <p style={{ width: "27%", margin: "0", fontSize: "smaller" }}>
                {" "}
                {data
                  ?.reduce((acc, row) => acc + row?.taxAmt, 0.0)
                  ?.toFixed(decimalPosition)}
              </p>
              <p
                style={{
                  width: "14%",
                  padding: "3px",
                  margin: "0",
                  fontSize: "smaller",
                }}
              >
                {data
                  ?.reduce((acc, row) => {
                    if (row.includedPrice === false) {
                      return acc + (row.lineTotal + row.taxAmt);
                    } else {
                      return acc + row.lineTotal;
                    }
                  }, 0.0)
                  ?.toFixed(decimalPosition)}
              </p>
            </div>

            <div className="last-total-div">
              <div className="sub-total">
                <h1>Sub Total</h1>
                {Number(formValues?.inclusiveTax) > 0 && (
                  <p>Total tax Inclusive</p>
                )}
                {Number(formValues?.exclusiveTax) > 0 && (
                  <p>Total tax Exclusive</p>
                )}
                <hr className="line-2" />
                <h1>Total</h1>
              </div>
              <div className="sub-total-amount">
                <h1>
                  {Number(formValues?.subtotal)?.toFixed(decimalPosition) || 0}
                </h1>
                {Number(formValues?.inclusiveTax) > 0 && (
                  <p>
                    {Number(formValues?.inclusiveTax)?.toFixed(
                      decimalPosition
                    ) || 0}
                  </p>
                )}
                {Number(formValues?.exclusiveTax) > 0 && (
                  <p>
                    {Number(formValues?.exclusiveTax)?.toFixed(
                      decimalPosition
                    ) || 0}
                  </p>
                )}
                <hr className="line-3" />
                <h1>{Number(formValues?.total)?.toFixed(decimalPosition)}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* payments print view */}
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrint = el)}>
          <div className="print-view-top-container">
            <div
              className="print-view-top-left-container"
              style={{ width: "25%" }}
            >
              <p></p>
              <p></p>
            </div>
            <div
              className="print-view-top-middle-container"
              style={{ width: "50%" }}
            >
              {/* <h3>--</h3>

              <h3>--</h3> */}

              <p>Request for Quatation</p>
            </div>

            <div
              className="print-view-top-right-container"
              style={{ width: "25%" }}
            >
              <p>{convertDateFormat(today)}</p>
              <p>{""}</p>
            </div>
          </div>

          <div className="second-main-container">
            <div className="left-side-div">
              <img
                src={formValues?.companyLogo}
                alt={formValues?.companyName}
              />
              <p>{formValues?.companyAddress}</p>
              <p>{formValues?.companyContact}</p>

              <h1 className="vendor-head">vendor Address</h1>
              <p>{formValues?.vendorName}</p>
              <p>{formValues?.vendorAddress}</p>
              <p>{formValues?.vendorContact}</p>
            </div>
            <div className="right-side-div">
              <h1>Request for Quatation </h1>
              <p>{formValues?.name}</p>
              <div className="date-div">
                <p>
                  {" "}
                  <span class="date-container-single">Quatation Date:</span>
                  {formValues?.firstDate}
                </p>
                <p>
                  {" "}
                  <span class="date-container-single">Expiry Date:</span>
                  {formValues?.secondDate}
                </p>
              </div>
            </div>
          </div>

          <div className="Table-container" style={{ padding: "0px 35px" }}>
            <div className="Table-datas">
              <table style={{ overflow: "none" }}>
                <thead>
                  <tr style={{ backgroundColor: "#eaeef9" }}>
                    <th>SI NO</th>
                    <th>PRODUCT</th>
                    <th>DESCRIPTION</th>
                    <th>QTY</th>
                    <th>UOM</th>
                    <th>RATE</th>
                    <th>AMOUNT</th>
                    <th>DISC%</th>
                    <th>VAT% </th>
                    <th>VAT</th>
                    <th>NET AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f1f2f5" : "white",
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{item.product}</td>
                      <td>{item?.label}</td>
                      <td>{item.qty}</td>
                      <td>{item.uomName}</td>
                      <td>{item.unitPrice?.toFixed(decimalPosition)}</td>
                      <td>
                        {(item.qty * item.unitPrice)?.toFixed(decimalPosition)}
                      </td>
                      <td>{item.discount}%</td>
                      <td>
                        {item.taxes?.map((r, i) => (
                          <p
                            key={i}
                            className="tax"
                            style={{ margin: "1px", border: "none" }}
                          >
                            {r?.amount}%
                          </p>
                        ))}
                      </td>
                      <td>{item.taxAmt?.toFixed(decimalPosition)}</td>
                      <td>
                        {item.includedPrice === false
                          ? (item.lineTotal + item.taxAmt)?.toFixed(
                              decimalPosition
                            )
                          : item.lineTotal?.toFixed(decimalPosition)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div
                style={{
                  backgroundColor: "#e9e9e9",
                  display: "flex",
                  textAlign: "end",
                  paddingInline: "86px",
                }}
              >
                <p style={{ margin: "0" }}>Total</p>
                {data && data.length > 0 && (
                  <p style={{ width: "58%", margin: "0" }}>
                    {" "}
                    {data
                      ?.reduce((total, item) => {
                        const qty = typeof item.qty === "number" ? item.qty : 0; // Check if item.qty is a number, otherwise default to 0
                        const unitPrice =
                          typeof item.unitPrice === "number"
                            ? item.unitPrice
                            : 0; // Check if item.unitPrice is a number, otherwise default to 0
                        return total + qty * unitPrice;
                      }, 0)
                      ?.toFixed(decimalPosition)}
                  </p>
                )}

                <p style={{ width: "27%", margin: "0" }}>
                  {" "}
                  {data
                    ?.reduce((acc, row) => acc + row?.taxAmt, 0.0)
                    ?.toFixed(decimalPosition)}
                </p>
                <p
                  style={{
                    width: "14%",
                    padding: "3px",
                    margin: "0",
                    fontSize: "smaller",
                  }}
                >
                  {data
                    ?.reduce((acc, row) => {
                      if (row.includedPrice === false) {
                        return acc + (row.lineTotal + row.taxAmt);
                      } else {
                        return acc + row.lineTotal;
                      }
                    }, 0.0)
                    ?.toFixed(decimalPosition)}
                </p>
              </div>
              <div className="last-total-div">
                <div className="sub-total">
                  <h1>Sub Total</h1>
                  <p>Total tax Inclusive</p>
                  <p>Total tax Exclusive</p>
                  <hr className="line-2" />
                  <h1>Total</h1>
                </div>
                <div className="sub-total-amount">
                  <h1>
                    {Number(formValues?.subtotal)?.toFixed(decimalPosition) ||
                      0}
                  </h1>
                  <p>{formValues?.inclusiveTax || 0}</p>
                  <p>{formValues?.exclusiveTax || 0}</p>
                  <hr className="line-3" />
                  <h1>{formValues?.total}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="terms">
            <h1>Terms & Conditions</h1>
            <hr className="lines" />
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
          <InvoiceContent
            invoiceDetails={{ ...formValues, printSection: printSection.rfq }}
            ref={componentRef}
            printData={data}
            taxDetails={tax}
            qrData={domain}
          />
        </div>
      </div>
    </div>
  );
};
