import React from "react";
import './homeDashboardStyle.css'
import SalesGraph from "./components/graph/salesGraph/SalesGraph";
import TopSellingProductGraph from "./components/graph/topSellingProductGraph/TopSellingProductGraph";
import TopCustomersTable from "./components/table/topCustomers/TopCustomersTable";
import FlowChartAccounts from "./components/flowChart/accounts/FlowChartAccounts";

import FlowChart from "./components/flowChart/flowCharts/FlowChart";
import { chartTypes } from "./utils/dashboardEnum";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getMainDashboardDataAPI } from "./homeDashboardAPI";
import TopCreditCustomers from "./components/table/topCreditCustomers/TopCreditCustomers";


const HomeDashboard = () => {
  const { userDetails, branchDetails } = useSelector(
    (state) => state.mainDashBoardSlice
  );

  // console.log(branchDetails)
  const {mainDashboardData}=useSelector(state=>state.homeDashboardSlice)
  useEffect(() => {
    if(!mainDashboardData  ){
      // getMainDashboardDataAPI({body:{branchId:branchDetails?._id || null}})
      getMainDashboardDataAPI()
    }
    
  }, [mainDashboardData])
  // console.log("mainDashboardData",mainDashboardData)
  
  return (
    <div className="home-dashboard-new-container">
      <div className="home-dash-new-wrapper">
        {/* <--------Graph----------> */}
        <>
          <SalesGraph />
          <TopSellingProductGraph />
        </>
        {/* <--------table----------> */}
        <>
          <TopCustomersTable />
          <TopCreditCustomers />
        </>
        {/* <--------flow-chart---------> */}
        <>
          {/* <FlowChartAccounts /> */}
          <FlowChart chartType={chartTypes.purchase} />
          <FlowChart chartType={chartTypes.sales} /> 

        </>
      </div>
    </div>
  );
};

export default HomeDashboard;
