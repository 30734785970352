import React from "react";

const UploadImageSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 516 427"
    >
      <g
        id="Group_17979"
        data-name="Group 17979"
        transform="translate(-8341 2125)"
      >
        <g
          id="trace_7_"
          data-name="trace (7)"
          transform="translate(8341 -2169.076)"
        >
          <path
            id="Path_3124"
            data-name="Path 3124"
            d="M282.837,161.28c-5.977,3.1-9.439,6.826-60.991,65.75-30.06,34.359-54.965,62.395-55.344,62.3s-12.548-13.66-27.039-30.15c-23.136-26.328-27-30.293-31.719-32.524-10.348-4.9-22.032-4.4-31.049,1.319-3,1.906-16.833,16.948-40.625,44.191L0,313.47l.017,9.015c.037,19.163,4.937,31.529,17.453,44.045,5.839,5.839,9.913,8.932,14.931,11.334,13.28,6.358,6.634,6.1,157.64,6.119L327.582,384l1.686,8.915c3.567,18.871,11.649,33.95,25.758,48.059,12.317,12.317,23.691,19.052,40.974,24.264,9.525,2.872,31,3.551,41.972,1.328,34.36-6.962,60.717-31.581,71.266-66.566,2.886-9.572,3.551-30.994,1.307-42.09A91.086,91.086,0,0,0,486,311.932c-11.554-11.836-28.494-22.321-38-23.521-1.375-.174-5.425-1.005-9-1.849a60.931,60.931,0,0,0-11.052-1.547L423.4,285l-50.939-58.127c-28.016-31.969-52.88-59.835-55.254-61.924-5.54-4.877-11.741-6.949-20.8-6.949-6.221,0-8.122.459-13.572,3.28M402.7,349.83c-14.949,15.087-15.956,17.037-11.631,22.535,1.62,2.06,2.943,2.635,6.059,2.635,3.429,0,4.751-.721,9.428-5.145L412,364.71v23.471c0,23.24.023,23.492,2.314,25.645,3.172,2.979,8.643,2.913,11.512-.14,2.1-2.24,2.174-3.068,2.174-25.646V364.71l5.44,5.145c4.677,4.424,6,5.145,9.428,5.145,3.116,0,4.439-.575,6.059-2.635,4.325-5.5,3.318-7.448-11.631-22.535C424.748,337.167,423.289,336,420,336s-4.748,1.167-17.3,13.83"
            fill="#458a30"
            fill-rule="evenodd"
          />
          <path
            id="Path_3125"
            data-name="Path 3125"
            d="M42.5,45.87A58.907,58.907,0,0,0,6.252,75.706a71.472,71.472,0,0,0-5.184,13.5c-.759,3.683-1.012,38.515-.83,114.5L.5,312.91l35.814-41.018c23.647-27.082,37.364-42,40.375-43.91,9.023-5.722,20.7-6.22,31.055-1.323,4.717,2.231,8.593,6.207,31.865,32.691,14.573,16.582,26.81,30.071,27.193,29.974s25.223-28.208,55.2-62.47c51.4-58.752,54.86-62.48,60.837-65.574,5.45-2.821,7.351-3.28,13.572-3.28,9.054,0,15.255,2.072,20.8,6.949,2.374,2.089,27.238,29.955,55.254,61.924L423.4,285l4.551.008a68.834,68.834,0,0,1,12.3,1.862l7.75,1.855V191.257c0-108.274.3-103.143-6.747-116.578C433.542,59.97,420.7,49.9,404,45.48c-4.72-1.25-30.306-1.448-180.5-1.4-162.695.055-175.422.181-181,1.787M402.7,349.83c-14.949,15.087-15.956,17.037-11.631,22.535,1.62,2.06,2.943,2.635,6.059,2.635,3.429,0,4.751-.721,9.428-5.145L412,364.71v23.471c0,23.24.023,23.492,2.314,25.645,3.172,2.979,8.643,2.913,11.512-.14,2.1-2.24,2.174-3.068,2.174-25.646V364.71l5.44,5.145c4.677,4.424,6,5.145,9.428,5.145,3.116,0,4.439-.575,6.059-2.635,4.325-5.5,3.318-7.448-11.631-22.535C424.748,337.167,423.289,336,420,336s-4.748,1.167-17.3,13.83M16.5,367c2.709,2.75,5.15,5,5.425,5s-1.716-2.25-4.425-5-5.15-5-5.425-5,1.716,2.25,4.425,5"
            fill="#d4dcdc"
            fill-rule="evenodd"
          />
          <circle
            id="Ellipse_108"
            data-name="Ellipse 108"
            cx="95"
            cy="95"
            r="95"
            transform="translate(326 281.076)"
            fill="#00a1bb"
          />
          <circle
            id="Ellipse_109"
            data-name="Ellipse 109"
            cx="41"
            cy="41"
            r="41"
            transform="translate(120 87.076)"
            fill="#dba000"
          />
          <path
            id="Icon_ionic-md-arrow-round-up"
            data-name="Icon ionic-md-arrow-round-up"
            d="M74.148,36.551,45.976,7.517a6.4,6.4,0,0,0-4.709-1.892h-.084a6.4,6.4,0,0,0-4.709,1.892L8.3,36.551a6.217,6.217,0,0,0,0,9.082,6.954,6.954,0,0,0,9.5,0L34.5,28.184V73.175a6.734,6.734,0,0,0,13.455,0V28.184l16.693,17.45a6.954,6.954,0,0,0,9.5,0A6.217,6.217,0,0,0,74.148,36.551Z"
            transform="translate(379.775 333.459)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default UploadImageSvg;
