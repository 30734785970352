import axios from "axios";
import { get_tax_settings_list, tax_filtered_list } from "../../../Redux/Settings/TaxSettings/taxSettingsSlice";
import store from "../../../Redux/store";
import { HEADERS } from "../../UrlAndPaths";
import { setSubmitLoading } from "../../../Redux/Dashboard/mainDashBoardSlice";

export const getAllTaxSettingsListAPICall = () => {
  axios
    .post("settings/taxList", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_tax_settings_list({ taxSettingsData: res.data }));
      }
    })
    .catch((err) => {
      console.log(err.response);
    });
};
export const TaxSettingsFilterAPICall =(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)

  axios.post("settings/searchtaxList",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(tax_filtered_list({filteredListTax:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(tax_filtered_list({filteredListTax:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)
  })

}
// addTaxsettings 
export const createTaxSettingsApicall = (
  addCreateTax,
  setState,
  state,
  setCreateTax
) => {
  store.dispatch(setSubmitLoading(true))
  axios
  .post("settings/createTax", addCreateTax, HEADERS)
  
  .then((res) => {
    if (res.status === 200) {
        store.dispatch(setSubmitLoading(false))
        setState({...state,message:"Created Success fully !!",success:true})
        setCreateTax(false);
      }
    })
    .catch((err) => {
      store.dispatch(setSubmitLoading(false))
      if(err.response.status === 500){
        setState({...state,message:"Internal Server error !",error:true})
      }
      else{
        setState({...state,message:err?.response?.data,error:true})
      }
    });
};

//updateTaxSettingsApiCall
export const updateTaxSettingsApiCall = (
  body,
  setState,
  state,
  setConfEditShow,
  setDisabledInput
) => {
  store.dispatch(setSubmitLoading(true))
  axios
    .put("settings/editTax", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({...state,message:"Updated Successfully !!",success:true})
        store.dispatch(setSubmitLoading(false))
        setDisabledInput(true)
        setConfEditShow(false)
      }
    })
    .catch((err) => {
      store.dispatch(setSubmitLoading(false))
      if(err.response.status === 500){
        setState({...state,message:"Internal Server error !",error:true})
      }
      else{
        setState({...state,message:err?.response?.data,error:true})
      }
    });
};

// UpdateTaxStatusAPiCall
export const UpdateTaxStatusAPiCall = (id,status,setTaxStatus,TaxStatus) => {
  console.log(status)
  axios
    .post(
      "settings/updateTaxStatus",
      {
        id: id,
        status: !status,
      },
      HEADERS
    )
    .then((res) => {
      if(res.status === 200){
        console.log(res.data)
        setTaxStatus(!TaxStatus)
      }
    })
    .catch((err) => {
      window.alert("ERROR OF STATUS UPDATE>>>")

    });
};
