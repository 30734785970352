import React, { useEffect, useState } from "react";
import "./style.css";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KotButton from "../buttons/KotButton";
import {
  Badge,
  Button,
  FormControlLabel,
  IconButton,
  Radio,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch, useSelector } from "react-redux";
import {
  setAlertMessage,
  setIsLoading,
  setKotOrderStatus,
  setSideMenu,
} from "../../kitchenOrderTicketSlice";
import { NETWORK, listKotOrderStatus } from "../../utils/kotEnum";
import { listKotOrderAPI, updateAllOrderStatus } from "../../kotAPI's";
import DarkModeSwitch from "../switch/DarkMode";
import StyledBadgeDot from "../../../FloorManagement/components/badge/StyledBadge";

import { useNavigate } from "react-router-dom";
import { setHideHeader } from "../../../KotTokenDisplay/kotTokenDisplaySlice";
import { switch_to_billing_screen } from "../../../Sales/Billing/newBillingSlice";
import ConfirmationDialog from "../popUp/statuschange/ConfirmationDialog";

const KotHeader = ({ branchId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sideMenu, kotOrderStatus, darkMode, networkStatus, kotOrderList } =
    useSelector((state) => state.kitchenOrderTicketSlice);
  const [popup, setPopup] = useState(false);
  const buttons = [
    { name: "All", color: "#cc009c", key: listKotOrderStatus.ALL },
    { name: "NEW", color: "#8406cc", key: listKotOrderStatus.NEW },
    { name: "PREPARING", color: "#1e05cc", key: listKotOrderStatus.PREPARING },
    { name: "COMPLETED", color: "#0569cc", key: listKotOrderStatus.COMPLETED },
  ];

  // button handle change
  const handleHeaderBtnChange = (index, data) => {
    dispatch(setAlertMessage(null));
    dispatch(setIsLoading(true));
    let body = {
      branchId,
      status: data?.key,
    };
    dispatch(setKotOrderStatus({ status: data, index: index }));
    // api call for list  of orders based on status
    listKotOrderAPI({ body });
  };

  //on filter button click
  const filterBtnClicked = () => {
    dispatch(setSideMenu(!sideMenu));
  };

  // select all action
  const handleChangeAllItemStatus = () => {
    const body = {
      branchId,
      idList: kotOrderList.map((item) => item._id),
    };
    updateAllOrderStatus({ body, setPopup, index: kotOrderStatus.index });
  };
  const onPopupClose = () => {
    setPopup(false);
  };

  return (
    <div className="kot-header-container">
      <div className="kot-header">
        <div className="kot-header-left-side">
          <Badge color="secondary" variant="dot" invisible={false}>
            <button onClick={() => filterBtnClicked()} className="kot-filter">
              <FilterAltIcon sx={{ color: darkMode ? "white" : "black" }} />
            </button>
          </Badge>
          {kotOrderStatus.index !== 0 && kotOrderList?.length > 0 && (
            <Button
              onClick={() => setPopup(true)}
              color="error"
              variant="outlined"
            >{`${
              kotOrderStatus.index == 1
                ? "cook"
                : kotOrderStatus.index == 2
                ? "complete"
                : kotOrderStatus.index == 3
                ? "delivery"
                : ""
            } all`}</Button>
          )}
        </div>
        <div className="kot-action-button">
          {buttons?.map((btn, index) => (
            <div
              className={`kot-action-item`}
              key={index}
              style={{
                borderBottom:
                  kotOrderStatus?.index === index
                    ? `5px solid ${btn.color}`
                    : "",
              }}
            >
              <KotButton
                active={kotOrderStatus?.index === index}
                index={index}
                onClick={() => handleHeaderBtnChange(index, btn)}
                name={btn?.name}
                color={btn?.color}
              />
            </div>
          ))}
        </div>
        <div className="kot-action-container">
          <StyledBadgeDot
            animate={networkStatus === NETWORK.ONLINE ? false : true}
            isActive={true}
            ActiveColor={
              networkStatus === NETWORK.ONLINE ? "#4bbda6" : "#fa1b49"
            }
          />
          <span className="network-status">
            {networkStatus === NETWORK.ONLINE ? "Online" : "Offline"}
          </span>

          <DarkModeSwitch />
          <div>
            <button
              className="close-icon-btn"
              onClick={() => {
                dispatch(switch_to_billing_screen({ billingBoolean: false }));
                navigate("/userdashboard/dashboardhome");
                dispatch(setHideHeader(false));
              }}
            >
              <HighlightOffIcon color="error" />
            </button>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        open={popup}
        onClose={onPopupClose}
        onConfirm={handleChangeAllItemStatus}
      />
    </div>
  );
};

export default KotHeader;
