import axios from "axios";
import store from "../../../../../Redux/store";
import {
  generate_customer_credit_note_name,
  get_customer_credit_note_list,
  get_customer_credit_note_row_data_list,
} from "./customerCreditNotesSlice";
import { HEADERS, HEADER_FORM_DATA } from "../../../../../API/UrlAndPaths";
import {
  get_customer_invoice_row_data_list,
  get_single_customer_invoice_list,
} from "../CustomerInvoice/customerInvoiceSlice";
import { setSubmitLoading } from "../../../../../Redux/Dashboard/mainDashBoardSlice";

// view creditNotes

export const viewCreditNotesAPICall = async (body, setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true);
  await axios
    .post("account/viewCreditNotes", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_customer_credit_note_list({ customerCreditNoteData: res.data })
        );
        setIsLoading !== undefined && setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(
        get_customer_credit_note_list({ customerCreditNoteData: undefined })
      );
      setIsLoading !== undefined && setIsLoading(false);
    });
};
export const CreditNoteFilterAPICall =(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)
  axios.post("account/searchCreditNotes",body,{
    headers: {
      'Content-Type': 'application/json',
    },
   
  })
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_customer_credit_note_list({customerCreditNoteData:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)

    }
  })
  .catch((err)=>{
    store.dispatch(get_customer_credit_note_list({customerCreditNoteData:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)
  })

}
// generate credit draft name 

export const generateCreditNoteNameAPICall = (body,setName) => {
  axios
    .post("account/generateCreditNoteDraftNo", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          generate_customer_credit_note_name({
            customerCreditNoteName: res.data,
          })
          );
          setName(res.data)
      }
    })
    .catch((err) => {
      store.dispatch(
        generate_customer_credit_note_name({
          customerCreditNoteName: undefined,
        })
        );
        setName("###")
    });
};

// draft credit note api call

export const draftCreditNoteAPICall = async (
  body,
  postFn,
  setState,
  state,
  setEditDataId,
  setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios
   
    .post("account/draftCreditNote", body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setState({ ...state, message: "Credit Note Drafted", success: true });
        postFn();
        setEditDataId(res.data?._id);
        crediNotesSingleViewAPICall({ _id: res.data?._id });
        localStorage.setItem("singleViewById", res.data?._id);
        setLoadingForm!==undefined && setLoadingForm(false)
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if(err.response.status === 500){
        setState({ ...state, message: "Internal Server error !", error: true });
       }
       else{
        setState({ ...state, message:err?.response?.data, error: true });

       }
    });
};
// edit credit note api call

export const editCreditNoteAPICall = async (body, postFn, setState, state,setLoadingForm) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios
    .put("account/editCreditNote", body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setState({ ...state, message: "Credit Note Edited", success: true });
        postFn();
        setLoadingForm!==undefined && setLoadingForm(false)
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if(err?.response?.data===500){
        setState({ ...state, message: "Internal Server error !", error: true });
       }
       else{
        setState({ ...state, message:err?.response?.data,error: true });

       }
    });
};
// post credit note api call

export const postCreditNoteAPICall = async (body, postFn, setState, state,setLoadingForm) => {
  setLoadingForm!==undefined && setLoadingForm(true)

  await axios
    .post("account/postCreditNote", body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setState({ ...state, message: "Credit Note Posted", success: true });
        postFn();
        crediNotesSingleViewAPICall({_id:res.data._id})
        setLoadingForm!==undefined && setLoadingForm(false)

      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if(err.response.status === 500===500){
        setState({ ...state, message: "Internal Server error !", error: true });
      }
      else{
        setState({ ...state, message:err?.response?.data, error: true });

      }
    });
};

// view credit note single view
export const crediNotesSingleViewAPICall = (body) => {
  store.dispatch(setSubmitLoading(true))
  axios
  .post("account/creditNoteSingleView", body, HEADERS)
  .then((res) => {
    if (res.status === 200) {
      store.dispatch(
        get_customer_credit_note_row_data_list({
          customerCreditNoteSingleData: res.data,
        })
      );
      store.dispatch(setSubmitLoading(false))
    }
  })
  .catch((err) => {
    store.dispatch(
      get_customer_credit_note_row_data_list({
        customerCreditNoteSingleData: undefined,
      })
    );
    store.dispatch(setSubmitLoading(false))
    });
};

// customer invoice single view
// export const customerInvoiceSingleData = (body) => {
//   axios
//     .post("account/viewCustomerInvoiceById", body, HEADERS)
//     .then((res) => {
//       if (res.status === 200) {
//         store.dispatch(
//           get_single_customer_invoice_list({ singleInvoiceData: res.data })
//         );
//       }
//     })
//     .catch((err) => {
//       console.log(err.response);
//     });
// };

export const customerInvoiceSingleData = (body) => {
  axios
    .post("account/viewInvoiceForCreditNote", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_single_customer_invoice_list({ singleInvoiceData: res.data })
        );
      }
    })
    .catch((err) => {
      store.dispatch(
        get_single_customer_invoice_list({ singleInvoiceData: undefined })
      )
    });
};

  //post registered payment 
  export const customerCreditNoteRegisterPaymentPostAPICall = (
    body,registerPymentCancelBtn,setSnackBarStates,setLoadingForm
  ) => {
    setLoadingForm!==undefined && setLoadingForm(true)
    axios
      .post("account/registerPaymentFromCreditNote", body, HEADERS)
      .then((res) => {
        if (res.status === 200) {
          crediNotesSingleViewAPICall({_id:body.creditNoteId})
            setSnackBarStates((prev)=>({...prev,message: "payment registered", success: true }))
            registerPymentCancelBtn()
            setLoadingForm!==undefined && setLoadingForm(false)
        }
      })
      .catch((err) => {
        setLoadingForm!==undefined && setLoadingForm(false)
        if(err.response.status === 500){
          setSnackBarStates((prev)=>({ ...prev, message: "Internal Server error !", error: true }));
         }
         else{
          setSnackBarStates((prev)=>({ ...prev, message:err?.response?.data, error: true }));

         }
      });
  };