import { createSlice } from "@reduxjs/toolkit";

export const bankReconciliation = createSlice({
  name: "bankReconciliation",
  initialState: {
    bankReconciliationForm: null,
    singleViewData:null,
    bankReconciliationList: [], //main list
    bankJournalList: [],
    companyBookList:[],

    importFile:null,
    bankStatement:[],
    showAlert:{message:'',open:false,severity:null}
 
  },
  reducers: {
    setBankReconciliationForm: (state, action) => {
      state.bankReconciliationForm = action.payload;
    },
    setBankReconciliationList: (state, action) => {
      state.bankReconciliationList = action.payload;
    },
    setBankJournalList: (state, action) => {
      state.bankJournalList = action.payload;
    },

    setCompanyBookList: (state, action) => {
      state.companyBookList = action.payload;
    },
    setImportFile: (state, action) => {
      state.importFile = action.payload;
    },
    setBankStatement: (state, action) => {
      state.bankStatement = action.payload;
    },
    setBankReSingleViewData: (state, action) => {
      state.singleViewData = action.payload;
    },
    setSnackbarNotification: (state, action) => {
      state.showAlert = action.payload;
    },
   
  },
});

export const {
  setSnackbarNotification,
  setBankReSingleViewData,
  setBankStatement,
  setImportFile,
  setCompanyBookList,
  setBankJournalList,
  setBankReconciliationForm,
  setBankReconciliationList,
} = bankReconciliation.actions;
export default bankReconciliation.reducer;
