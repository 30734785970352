import React, { useEffect, useState } from "react";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RFQFilterAPICall } from "../../../../API/Purchase Manager/RFQ/ConfirmRfqApiCall";
import { useSelector } from "react-redux";
import store from "../../../../Redux/store";
import { purchaseOrderSingleView, rfqSingleView } from "../../../../Redux/Prchase Manager/RFQ/RfqSlice";
import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../Single Components/CustomPagination";
import { setFilterActive } from "../../../Single Components/listSlice/filterCategorySlice";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs"
import  "../../../../css/Global Css/updatedGlobalCss.css"
import TableListActionButtons from "../../../Single Components/ActionButtons/TableListActionButtons";
export const RequestForQuatation = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const RfqList = useSelector((state) => state?.RfqSlice?.value);
  let navigateTo = useNavigate();
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [filteredData, setFilteredData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const totalPages = RfqList?.pages;

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const formatFilterInfo = (filters) => {
    const parts = [];
  
    if (filters?.toDate&&filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    }
    if (filters?.createdBy) {
      parts.push(`createdBy: ${filters?.createdBy?.staff_name}`);
    }
    if (filters?.type) {
      parts.push(`Status: ${filters?.type}`);
    }
   
    
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Request For Quotation");
    sheet.properties.defaultRowHeight = 20;
  
    // Add header section
    const titleRow = sheet.addRow(["Request For Quotation"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells('A1:G1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];
  
    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:G${index + 2}`);
        row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      });
    }
  
    // Add an empty row between header and table
    sheet.addRow([]);
  
    // Header row styling
    const headerRow = sheet.addRow(["No", "PO No", "Supplier Name", "Date","Total","CreatedBy","Status"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };
  
    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    })
  
    sheet.columns = [
      { header: "No", key: "No", width: 8 },
      { header: "PO No", key: "sequence", width: 15 },
      { header: "Supplier Name", key: "supplierName", width: 25 },
      { header: "Date", key: "quotationDate", width: 15 },
      { header: "Total", key: "total", width: 15 },
      { header: "CreatedBy", key: "CREATEDBY", width: 15 },
      { header: "Request For Quotation", key: "type", width: 15 },
  
    ];
  
    RfqList?.list?.forEach((item,index) => {
      const row = sheet.addRow({
        No: index+1,
        sequence: item.sequence,
        supplierName: item?.supplierName,
        quotationDate: item?.quotationDate,
        total: item?.total,
        CREATEDBY: item?.CREATEDBY,
        type: item?.type,
      
      });
  
      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };
      
      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
        //   top: { style: "thin", color: { argb: "FF000000" } },
        //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
        //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) { // Right align all columns except the first
            cell.alignment = { horizontal: 'center' };
          }
         
      });
    });
  
    // Add row for totals
    // const totalRow = sheet.addRow([
    //   "Total",
    //   "",
    //   `${symbol} ${SingleListData?.sumOfCurrent.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf30?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf60?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf90?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOf120?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOfAbove120?.toFixed(2)}`,
    //   `${symbol} ${SingleListData?.sumOfTotal?.toFixed(2)}`,
    // ]);
  
    // totalRow.eachCell((cell,colNumber) => {
    //   cell.font = { bold: true, color: { argb: "FF000000" } };
    //   cell.fill = headerFill;
    //   cell.border = {
    //     top: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     left: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
    //     right: { style: "thin", color: { argb: "FFFFFFFF" } },
    //   };
    //   if (colNumber > 1) { // Right align all columns except the first
    //     cell.alignment = { horizontal: 'right' };
    //   }
    // });
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Request For Quotation.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    // const source = axios.CancelToken.source();
    if (FormDataInfo.length !== 0) {
      let body = {
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        createdBy: FormDataInfo.createdBy,
        branchList: FormDataInfo.branchList,
        type: FormDataInfo.status,
        search: FormDataInfo.search,
        index: currentPage - 1,
      };
      let loadData = window.location.pathname === filtereDataOptions?.filterActive
      !loadData && RFQFilterAPICall(body)
    }
    // return () => {
    //   source.cancel('Request canceled by cleanup');
    // };

  }, [FormDataInfo, currentPage]);


  useEffect(() => {
    localStorage.removeItem("singleViewById");
    store.dispatch(purchaseOrderSingleView({ purchaseOrderSingleView: undefined }));

  }, []);

  const handleClickRow = (row) => {
    localStorage.setItem("singleViewById", row?._id);
    store.dispatch(setFilterActive(window.location.pathname))
    navigateTo("/userdashboard/purchaseManager/RequestForQuatation/singleView")
  };

  return (
    <>
      <div className="global-page-parent-container updated-global-page-parent-container">
          <div className="justify-space-between list-create-head-bar">
           
                <h3>Request For Quotation</h3>
              <div className="create-head-buttons-container">
                <TableListActionButtons downloadClick={downloadList}/>
                <CategorySearchandFilter
                    statusOptions={['RFQ', 'CANCEL']}
                    onData={handleDataFromChild}
                    isCreatedBy={true}
                    isStatus={true}
                    onBranchSelect={handleBranchSelect}
                    onFilterChange={handleFilterChange}
                  />
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    navigateTo(
                      "/userdashboard/purchaseManager/CreateRequestForQuatation"
                    )
                  }
                >
                  Create
                </button>
              </div>
          </div>
           <div className="justify-space-between pagination-container">
           {FormDataInfo?.branchList?.length > 0 && (
                <h4>Branch name: {selectedBranch?.storeCode}-{selectedBranch?.branchName}</h4>
            )}
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              setFormDataInfo={setFormDataInfo}
              onBranchSelect={handleBranchSelect}
            /></div>
          <div className="new-global-table-container updated-table-container">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>PO No</th>
                  <th>Supplier Name</th>
                  <th>Date</th>
                  <th>Total</th>
                  <th>CreatedBy</th>
                  <th>Status</th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {RfqList !== undefined && RfqList?.list?.length !== 0 ? (
                    RfqList?.list?.slice(0)
                      ?.map((r, i) => (
                        <tr key={i} onClick={() => handleClickRow(r)}>
                          <td>{i + 1}</td>
                          <td>{r?.sequence}</td>
                          <td>{r?.supplierName}</td>

                          <td>{r?.quotationDate}</td>
                          <td>{r?.total}</td>
                          <td>{r?.CREATEDBY}</td>
                          <td className="table-data-box">
                            {r?.type === "RFQ" ? (
                              <span className="rfq" style={{ padding: "5px 25px" }}> RFQ</span>
                            ) : r?.type === "PURCHASE ORDER" ? (
                              <span className="po"> PO</span>
                            ) : r?.type === "CANCEL" ? (
                              <span className="cancel"> CANCEL</span>
                            ) : (
                              "-----"
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={10}>No Data</td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
    </>
  );
};
