import { createSlice } from "@reduxjs/toolkit";

export const posAggregatorReportSlice=createSlice({
    name:'posAggregatorReportSlice',
    initialState:{
        paymentPopup:null,
    },
    reducers:{
        set_aggregator_payment_popup:(state,action)=>{
            state.paymentPopup=action.payload
        },

    }

})
export const {
    set_aggregator_payment_popup
    }=posAggregatorReportSlice.actions
export default posAggregatorReportSlice.reducer