import { Dialog, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FilePicker } from "../../../../Single Components/filePicker";
import OTPInput from "otp-input-react";
import CountryWithStates from "../../../../../Assets/JSON/countryStates.json";
import { extractStatesFromCountry } from "../../../../../Js/generalFunctions";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import { addNewEmployeeAPICall } from "../../../../../API/Staff/HRM/employeeAPI";


export const PersonalInfo = (props) => {
  const { navigateToNextTab } = props;
  const storeCode = localStorage.getItem("branchId");
  //CurrentCountry
  let currentCountry = localStorage.getItem("country");
  const [staffImage, setStaffImage] = useState();
  const [accountLogin, setAccountLogin] = useState(false);
  const [status, setStatus] = useState("mobileVerification");
  const [OTP, setOTP] = useState("");

  //Snackbar States
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");

  // validation states

  const [staffNameAlert, setStaffNameAlert] = useState(false);
  const [genderAlert, setGenderAlert] = useState(false);
  const [fatherNameAlert, setFathernameAlert] = useState(false);
  const [maritalStatusAlert, setMaritalStatusAlert] = useState(false);
  const [contactAlert, setContactAlert] = useState(false);
  const [bloodGroupAlert, setBloodGroupAlert] = useState(false);
  const [emgContactAlert, setEmgContactAlert] = useState(false);
  const [addressAlert, setAddressAlert] = useState(false);
  const [emailAlert, setEmailAlert] = useState(false);
  const [dobAlert, setDobAlert] = useState(false);
  const [stateAlert, setStateAlert] = useState(false);
  const [staffImgAlert, setStaffImgAlert] = useState(false);

  //Personal Info Form
  const personalInfoFormInitialState = {
    staffName: "",
    gender: "0",
    fatherName: "",
    maritalStatus: "0",
    contactNo: "",
    bloodGroup: "0",
    emergencyContactNo: "",
    address: "",
    email: "",
    dob: "",
    country: currentCountry,
    state: "0",
  };
  const [personalInfoForm, setPersonalInfoForm] = useState(
    personalInfoFormInitialState
  );

  //Account Login Form
  const accountLoginFormInitialState = {
    mobNo: "",
    password: "",
    confirmPassword: "",
  };
  const [accountLoginForm, setAccountLoginForm] = useState(
    accountLoginFormInitialState
  );

  //States As per country
  const [states, setStates] = useState([]);

  //Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setOpenSuccessSnackbar(false);
  };
  //Close errorSnackbar
  const closeErrorSnackbar = () => {
    setOpenSuccessSnackbar(false);
  };

  const sendOtpBtnFunction = () => {
    setStatus("otp");
  };
  const verifyBtnFunction = () => {
    setStatus("addPassword");
  };

  //* onChange Fns
  const getPersonalInfoForm = (key) => (e) => {
    const { value } = e.target;

    if (key === "staffName") {
      setPersonalInfoForm({ ...personalInfoForm, staffName: value });
      setStaffNameAlert(false);
      if (value === "") {
        setStaffNameAlert(true);
      }
    }
    if (key === "gender") {
      setPersonalInfoForm({ ...personalInfoForm, gender: value });
      setGenderAlert(false);
    }
    if (key === "fatherName") {
      setPersonalInfoForm({ ...personalInfoForm, fatherName: value });
      setFathernameAlert(false);
      if (value === "") {
        setFathernameAlert(true);
      }
    }
    if (key === "maritalStatus") {
      setPersonalInfoForm({ ...personalInfoForm, maritalStatus: value });
      setMaritalStatusAlert(false);
    }
    if (key === "contactNo") {
      setPersonalInfoForm({ ...personalInfoForm, contactNo: value });
      setContactAlert(false);
      if (value === "") {
        setContactAlert(true);
      }
    }
    if (key === "bloodGroup") {
      setPersonalInfoForm({ ...personalInfoForm, bloodGroup: value });
      setBloodGroupAlert(false);
    }
    if (key === "emergencyContactNo") {
      setPersonalInfoForm({ ...personalInfoForm, emergencyContactNo: value });
      setEmgContactAlert(false);
      if (value === "") {
        setEmgContactAlert(true);
      }
    }
    if (key === "address") {
      setPersonalInfoForm({ ...personalInfoForm, address: value });
      setAddressAlert(false);
      if (value === "") {
        setAddressAlert(true);
      }
    }
    if (key === "email") {
      setPersonalInfoForm({ ...personalInfoForm, email: value });
      setEmailAlert(false);
      if (value === "") {
        setEmailAlert(true);
      }
    }
    if (key === "dob") {
      setPersonalInfoForm({ ...personalInfoForm, dob: value });
      setDobAlert(false);
      if (value === "") {
        setDobAlert(true);
      }
    }
    if (key === "country") {
      setPersonalInfoForm({ ...personalInfoForm, country: value });
    }
    if (key === "state") {
      setPersonalInfoForm({ ...personalInfoForm, state: value });
      setStateAlert(false);
      if (value === "0") {
        setStateAlert(true);
      }
    }
  };

  const getAccountLoginForm = (key) => (e) => {
    const { value } = e.target;

    if (key === "mobNo") {
      setAccountLoginForm({ ...accountLoginForm, mobNo: value });
    }
    if (key === "password") {
      setAccountLoginForm({ ...accountLoginForm, password: value });
    }
    if (key === "confirmPassword") {
      setAccountLoginForm({ ...accountLoginForm, confirmPassword: value });
    }
  };

  //Account login cancel function
  const accountLoginCancel = () => {
    setAccountLogin(false);
    setStatus("mobileVerification");
  };

  //Add New Employee Form Data
  const addNewEmployeeFormData = new FormData();
  addNewEmployeeFormData.append("staff_name", personalInfoForm.staffName);
  addNewEmployeeFormData.append("email", personalInfoForm.email);
  addNewEmployeeFormData.append("contactnumber", personalInfoForm.contactNo);
  personalInfoForm.gender!==null && addNewEmployeeFormData.append("gender", personalInfoForm.gender);
  addNewEmployeeFormData.append("address", personalInfoForm.address);
  addNewEmployeeFormData.append("emp", staffImage);
  addNewEmployeeFormData.append("fathersName", personalInfoForm.fatherName);
  addNewEmployeeFormData.append(
    "maritialStatus",
    personalInfoForm.maritalStatus === "single"
      ? true
      : personalInfoForm.maritalStatus === "married"
      ? false
      : null
  );
  addNewEmployeeFormData.append("bloodGroup", personalInfoForm.bloodGroup);
  addNewEmployeeFormData.append(
    "emergencyContactNumber",
    personalInfoForm.emergencyContactNo
  );
  addNewEmployeeFormData.append("country", personalInfoForm.country);
  addNewEmployeeFormData.append("state", personalInfoForm.state);
  addNewEmployeeFormData.append("dob", personalInfoForm.dob);
  addNewEmployeeFormData.append("username", accountLoginForm.mobNo);
  addNewEmployeeFormData.append("password", accountLoginForm.password);
  addNewEmployeeFormData.append("branchId", storeCode);

  //*onClick fns
  const nextButtonClick = () => {
    const {
      staffName,
      email,
      contactNo,
      gender,
      address,
      fatherName,
      maritalStatus,
      bloodGroup,
      emergencyContactNo,
      country,
      state,
      dob,
    } = personalInfoForm;

    const { mobNo, password, confirmPassword } = accountLoginForm;

    if (staffName === "") {
      setStaffNameAlert(true);
    } 
    else if (gender === "0") {
      setGenderAlert(true);
    }
    else if (contactNo === "") {
      setContactAlert(true);
    } 
    
    else if (dob === "") {
      setDobAlert(true);
    } 
    else {
      addNewEmployeeAPICall(
        addNewEmployeeFormData,
        setOpenSuccessSnackbar,
        setOpenErrorSnackbar,
        setSnackMsg,
        navigateToNextTab
      );
    }
  };

  //Call Extract States Fn
  useEffect(() => {
    let extractedStates = extractStatesFromCountry(personalInfoForm.country);
    setStates(extractedStates);
  }, [personalInfoForm.country]);

  useEffect(() => {
    if (personalInfoForm.contactNo !== "") {
      setAccountLoginForm({
        ...accountLoginForm,
        mobNo: personalInfoForm.contactNo,
      });
    }
  }, [personalInfoForm.contactNo]);

  return (
    <div className="personal-info-container">
      <div className="personal-info-input-container">
        <div className="global-single-input add-employee-input">
          <p>Staff Name</p>
          <input
            type="text"
            placeholder="Enter Staff Name..."
            value={personalInfoForm.staffName}
            onChange={getPersonalInfoForm("staffName")}
          />
          {staffNameAlert ? (
            <p className="doc-validation-alert">Fill this field !!</p>
          ) : (
            ""
          )}
        </div>
        <div className="global-single-input add-employee-input">
          <p>Gender</p>
          <Select
            value={personalInfoForm.gender}
            onChange={getPersonalInfoForm("gender")}
          >
            <MenuItem value="0">-select gender-</MenuItem>
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="trnasgender">Transgender</MenuItem>
          </Select>
          {genderAlert ? (
            <p className="doc-validation-alert">Select a value !!</p>
          ) : (
            ""
          )}
        </div>
        <div className="global-single-input add-employee-input">
          <p>Father Name</p>
          <input
            type="text"
            value={personalInfoForm.fatherName}
            onChange={getPersonalInfoForm("fatherName")}
            placeholder="Enter Father Name..."
          />
          {fatherNameAlert ? (
            <p className="doc-validation-alert">Fill this field !!</p>
          ) : (
            ""
          )}
        </div>
        <div className="global-single-input add-employee-input">
          <p>Marital Status</p>
          <Select
            value={personalInfoForm.maritalStatus}
            onChange={getPersonalInfoForm("maritalStatus")}
          >
            <MenuItem value="0">-select marital status-</MenuItem>
            <MenuItem value="single">Single</MenuItem>
            <MenuItem value="married">Married</MenuItem>
          </Select>
          {maritalStatusAlert ? (
            <p className="doc-validation-alert">Select a value !!</p>
          ) : (
            ""
          )}
        </div>
        <div className="global-single-input add-employee-input">
          <p>Contact Number</p>
          <input
            type="text"
            placeholder="Enter Contact Number..."
            value={personalInfoForm.contactNo}
            onChange={getPersonalInfoForm("contactNo")}
          />
          {contactAlert ? (
            <p className="doc-validation-alert">Fill this field !!</p>
          ) : (
            ""
          )}
        </div>
        <div className="global-single-input add-employee-input">
          <p>Blood Group</p>
          <Select
            value={personalInfoForm.bloodGroup}
            onChange={getPersonalInfoForm("bloodGroup")}
          >
            <MenuItem value="0">-select a blood group-</MenuItem>
            <MenuItem value="A+">A +ve</MenuItem>
            <MenuItem value="A-">A -ve</MenuItem>
            <MenuItem value="B+">B +ve</MenuItem>
            <MenuItem value="B-">B -ve</MenuItem>
            <MenuItem value="AB+">AB +ve</MenuItem>
            <MenuItem value="AB-">AB -ve</MenuItem>
            <MenuItem value="O+">O +ve</MenuItem>
            <MenuItem value="O-">O -ve</MenuItem>
          </Select>
          {bloodGroupAlert ? (
            <p className="doc-validation-alert">Select a value !!</p>
          ) : (
            ""
          )}
        </div>
        <div className="global-single-input add-employee-input">
          <p>Emergency Contact Number</p>
          <input
            type="text"
            placeholder="Enter Contact Number..."
            value={personalInfoForm.emergencyContactNo}
            onChange={getPersonalInfoForm("emergencyContactNo")}
          />
          {emgContactAlert ? (
            <p className="doc-validation-alert">select a value !!</p>
          ) : (
            ""
          )}
        </div>
        <div className="global-single-input add-employee-input">
          <p>Address</p>
          <input
            type="text"
            placeholder="Enter Address..."
            value={personalInfoForm.address}
            onChange={getPersonalInfoForm("address")}
          />
          {addressAlert ? (
            <p className="doc-validation-alert">Fill this field !!</p>
          ) : (
            ""
          )}
        </div>
        <div className="global-single-input add-employee-input">
          <p>Email</p>
          <input
            type="text"
            placeholder="abc@gmail.com"
            value={personalInfoForm.email}
            onChange={getPersonalInfoForm("email")}
          />
          {emailAlert ? (
            <p className="doc-validation-alert">Fill this field !!</p>
          ) : (
            ""
          )}
        </div>
        <div className="global-single-input add-employee-input">
          <p>DOB</p>
          <input
            type="date"
            value={personalInfoForm.dob}
            onChange={getPersonalInfoForm("dob")}
          />
          {dobAlert ? (
            <p className="doc-validation-alert">Fill this field !!</p>
          ) : (
            ""
          )}
        </div>
        <div className="global-single-input add-employee-input">
          <p>Country</p>
          <Select
            value={personalInfoForm.country}
            onChange={getPersonalInfoForm("country")}
          >
            <MenuItem value="0">-select a country-</MenuItem>
            {CountryWithStates?.countries?.map((item, i) => (
              <MenuItem value={item?.country.toLowerCase()} key={i}>
                {item?.country}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="global-single-input add-employee-input">
          <p>State</p>
          <Select
            value={personalInfoForm.state}
            onChange={getPersonalInfoForm("state")}
          >
            <MenuItem value="0">-select a state-</MenuItem>
            {states[0]?.states?.map((state, i) => (
              <MenuItem value={state} key={i}>
                {state}
              </MenuItem>
            ))}
          </Select>
          {stateAlert ? (
            <p className="doc-validation-alert">Fill this field !!</p>
          ) : (
            ""
          )}
        </div>
        <div className="global-single-input add-employee-input">
          <p>Image Upload</p>
          <FilePicker
            id="staffImage"
            uploadImageUrl={staffImage}
            setImage={setStaffImage}
            onChange={(e) => {
              setStaffImage(e.target.files[0]);
              setStaffImgAlert(false);
            }}
          />
          {staffImgAlert ? (
            <p
              className="doc-validation-alert"
              style={{ left: "0", textAlign: "start" }}
            >
              Fill this field !!
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="global-single-input add-employee-input-button">
          <button
            onClick={() => setAccountLogin(true)}
            className="profile-info-account-login"
          >
            Account Login
          </button>
        </div>
      </div>
      <div className="add-employee-button-container">
        <button className="btn btn-primary-outlined">Cancel</button>
        <button className="btn btn-primary" onClick={nextButtonClick}>
          Next
        </button>
      </div>

      {/* account login dialog */}

      <Dialog
        open={accountLogin}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setAccountLogin(false)}
      >
        <div className="profile-info-account-login-container">
          <h3>Account Login</h3>
          <hr className="global-hr" />

          {status === "mobileVerification" ? (
            <div className="profile-info-account-login-content">
              {/* <h3>Mobile Verification</h3> */}
              <h3>Create username</h3>
              {/* <p>Please Enter Your Mobile Number</p> */}
              <p>User name will be your mobile number</p>

              <div className="profile-info-account-login-input-content">
                <p>Mobile</p>
                <div className="profile-info-account-login-input">
                  {/* <Select>
                    <MenuItem>+91</MenuItem>
                  </Select> */}
                  <input
                    readOnly
                    type="text"
                    placeholder="Enter mobile number without country code..."
                    value={accountLoginForm.mobNo}
                    // onChange={getAccountLoginForm("mobNo")}
                  />
                </div>
              </div>
              <div className="profile-info-account-login-button-container">
                <button
                  className="btn btn-primary-outlined"
                  onClick={accountLoginCancel}
                >
                  Cancel
                </button>
                <button
                  // onClick={sendOtpBtnFunction} // actual onclick fn..  changed temporarily to remove msg input field
                  onClick={verifyBtnFunction}
                  className="btn btn-primary"
                >
                  {/* Send OTP */}
                  Next
                </button>
              </div>
            </div>
          ) : status === "otp" ? (
            <div className="profile-info-account-login-content">
              <h3>Enter OTP</h3>
              <p style={{ width: "77%", textAlign: "center" }}>
                We have sent you an access code via SMS for
                <br /> mobile number verification
              </p>

              <div className="otp-input-container">
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                />
              </div>
              <div className="profile-info-account-login-button-container">
                <button
                  className="btn btn-primary-outlined"
                  onClick={accountLoginCancel}
                >
                  Cancel
                </button>
                <button onClick={verifyBtnFunction} className="btn btn-primary">
                  Verify
                </button>
              </div>
              <div>
                <p>Don't Receive the OTP?</p>
                <h4 style={{ textAlign: "center" }}>Resend Code</h4>
              </div>
            </div>
          ) : status === "addPassword" ? (
            <div className="profile-info-account-login-content">
              <h3>Add Password</h3>
              <div className="acoount-login-password-confirm-password-container">
                <div className="global-single-input account-login-password-container">
                  <p>Password</p>
                  <input
                    type="password"
                    placeholder="Enter new password..."
                    value={accountLoginForm.password}
                    onChange={getAccountLoginForm("password")}
                  />
                </div>
                <div className="global-single-input account-login-password-container">
                  <p>Confirm Password</p>
                  <input
                    type="password"
                    placeholder="Re Enter password..."
                    value={accountLoginForm.confirmPassword}
                    onChange={getAccountLoginForm("confirmPassword")}
                  />
                </div>
              </div>

              <div className="profile-info-account-login-button-container">
                <button
                  className="btn btn-primary-outlined"
                  onClick={accountLoginCancel}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={accountLoginCancel}
                >
                  Submit
                </button>
              </div>
            </div>
          ) : undefined}
        </div>
      </Dialog>

      <SuccessSnackbar
        open={openSuccessSnackbar}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
      <ErrorSnackbar
        open={openErrorSnackbar}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </div>
  );
};
