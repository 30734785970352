import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FilePicker } from "../../../../Single Components/filePicker";
import { useEffect } from "react";
import { viewAllAllowedBranchesAPICall, viewAllBranchesAPICall } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { useDispatch, useSelector } from "react-redux";
import countryList from "../../../../../Assets/JSON/countryStates.json"
import { createVendorAPICall, editVendorAPICall } from "../API/vendorListAPICall";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import { set_Back_button_action, setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
export const CreateVendors = () => {

  let navigateTo = useNavigate()
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const editedData = useSelector((state) => state.vendorsListSlice.rowData);
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const userRole=useSelector((state)=>state.userRoleSlice.value)
  const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);
  const dispatch = useDispatch();

  const countryWithState = countryList.countries;
  const [editClicked, setEditClicked] = useState(false)
  const [editClickedId, setEditClickedId] = useState(false)
  const [vendorImg, setVendorImg] = useState(null)
  const [validationAlert, setValidationAlert] = useState({})
  const formInitialState = {
    name: "",
    address: "",
    street: "",
    city: "",
    country: null,
    state: null,
    taxId: "",
    phone: "",

    mobile: "",
    email: "",
    paymentTerms: "",
    supplierCurrency: "",
    contactPerson: "",
    mobilePhoneNo: "",
    companyCode: "",
    branch: null,
    toggleChecked: false,

    visibleOnly: false
  }
  const [formValues, setFormValues] = useState(formInitialState)
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
  })
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates, success: false,
      error: false,
      message: "",
    })
  }
  const backButtonFunction = () => {
    navigateTo("/userdashboard/accounts/vendor/vendors")
    dispatch(set_Back_button_action({ backButtonClickAction: true }));
  }

  const payloadFormData = new FormData()

  payloadFormData.append("name", formValues.name)
  payloadFormData.append("mobileNo", formValues.mobile)
  payloadFormData.append("streetName", formValues.street)
  payloadFormData.append("email", formValues.email)
  payloadFormData.append("taxId", formValues.taxId)
  payloadFormData.append("branchId", formValues.branch?.storeCode)
  payloadFormData.append("zipCode", formValues.address)
  payloadFormData.append("city", formValues.city)
  payloadFormData.append("country", formValues.country?.country)
  payloadFormData.append("state", formValues.state)
  payloadFormData.append("phone", formValues.phone)
  payloadFormData.append("payTerm", formValues.paymentTerms)
  payloadFormData.append("supplierCurrency", formValues.supplierCurrency)
  payloadFormData.append("contactPerson", formValues.contactPerson)
  payloadFormData.append("contactPersonMobile", formValues.mobilePhoneNo)
  payloadFormData.append("companyCode", formValues.companyCode)
  payloadFormData.append("file", vendorImg !== null ? vendorImg : undefined)
  payloadFormData.append("status", formValues?.toggleChecked)


  const postFunction = () => {
    navigateTo("/userdashboard/accounts/vendor/vendors")
  }
  const submitApiClick = (key) => () => {
    dispatch( setFilterActive(false))
    if (formValues.name === "") {
      setValidationAlert({ ...validationAlert, name: "enter a name" })
    }
    else if (formValues.mobile === "") {
      setValidationAlert({ ...validationAlert, mobile: "fill this field" })
    }
    else if (formValues.branch === null) {
      setValidationAlert({ ...validationAlert, branch: "fill this field" })
    }
    else {
      if (key === "create") {
        createVendorAPICall(payloadFormData, postFunction, setSnackBarStates, snackBarStates)
      }
      else if (key === "edit") {
        payloadFormData.append("_id", editClickedId)
        editVendorAPICall(payloadFormData, postFunction, setSnackBarStates, snackBarStates)
      }
    }
  }
  const getFormInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    setValidationAlert({})
    if (key === "country") {
      setFormValues({ ...formValues, country: newValue, state: null })
    }
    else {
      setFormValues({ ...formValues, [key]: value !== 0 ? value : newValue })
    }
  }


  useEffect(() => {
    if (editedData !== undefined) {

      setEditClicked(true)
      setFormValues({
        ...formValues,
        name: editedData?.name,
        visibleOnly: true,
        address: editedData?.address,
        street: editedData?.streetName,
        city: editedData?.city,
        country: filterObjFromList("country", countryWithState, "country", editedData),
        state: editedData?.state,
        taxId: editedData?.gst,
        mobile: editedData?.mobileNo,
        email: editedData?.email,
        paymentTerms: editedData?.payTerm,
        supplierCurrency: editedData?.supplierCurrency,
        contactPerson: editedData?.contactPerson,
        mobilePhoneNo: editedData?.contactPersonMobile,
        companyCode: editedData?.companyCode,
        branch: filterObjFromList("storeCode", allBranchesList, "branchId", editedData),
        toggleChecked: editedData?.status
      })
      setEditClickedId(editedData?._id)

    } else {
      setEditClicked(false)
      setFormValues(formInitialState)
    }
  }, [editedData, allBranchesList])

  

  useEffect(() => {
    viewAllBranchesAPICall()
    userRole==="user"&& viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });

  }, [])
  
  return (
    <div className="global-page-parent-container">
      <p className="breadcrumb">Accounts &gt; Vendors &gt;{editClicked ? "Edit Vendors" : "Create Vendors"}</p>
      <div className="global-white-bg-container justify-space-between" style={{ padding: "3px 1%" }}>
        <div>
          <Tooltip title="Back">
            <IconButton onClick={backButtonFunction}>
              <i class="bi bi-chevron-left"></i>
            </IconButton>
          </Tooltip>
          {
            editClicked ?
              <IconButton
                onClick={
                  formValues?.visibleOnly === true ?
                    () => setFormValues({
                      ...formValues, visibleOnly: false
                    }) : submitApiClick("edit")}
              >
                {formValues?.visibleOnly === true ?
                  <i class="bi bi-pencil-square edit-icon1"></i> :
                  <i class="bi bi-check2-square edit-icon" style={{ fontSize: "1.5rem" }}></i>}
              </IconButton>
              :
              <IconButton
                onClick={submitApiClick("create")}
              >
                <i class="bi bi-floppy save-icon"></i>
              </IconButton>
          }
        </div>
      </div>
      <div className="white-bg-two-section">
        <div className="global-white-bg-container">
          <div className="global-single-input">
            <p>Image Upload</p>
            <FilePicker
              id="addVendorImage"
              uploadImageUrl={vendorImg}
              setImage={setVendorImg}
              onChange={(e) => {
                setVendorImg(e.target.files[0]);
              }}
            />
            <p className="doc-validation-alert">{validationAlert?.image}</p>
          </div>
          <div className="global-single-input">
            <p>Name</p>
            <input type="text" value={formValues.name}
              onChange={getFormInfo("name")}
              disabled={formValues?.visibleOnly}
            />
            <p
              style={{ top: "60%" }}
              className="doc-validation-alert">{validationAlert?.name}</p>

          </div>
          <div className="global-single-input auto-complete">
            <p>Country</p>
            <Autocomplete
              options={countryWithState || []}
              getOptionLabel={(option) => option?.country}
              renderInput={(params) => (
                <TextField {...params} placeholder="select country" />
              )}
              value={formValues.country}
              onChange={getFormInfo("country")}
              disabled={formValues?.visibleOnly}
            />
            <p className="doc-validation-alert">{validationAlert?.country}</p>
          </div>
          <div className="global-single-input auto-complete">
            <p>State</p>
            <Autocomplete
              options={formValues?.country?.states || []}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} placeholder="select state" />
              )}
              value={formValues.state}
              onChange={getFormInfo("state")}
              disabled={formValues?.visibleOnly}
            />
            <p className="doc-validation-alert">{validationAlert?.state}</p>
          </div>

          <div className="global-single-input">
            <p>City</p>
            <input type="text" value={formValues.city}
              onChange={getFormInfo("city")}
              disabled={formValues?.visibleOnly}
            />
            <p className="doc-validation-alert">{validationAlert?.city}</p>
          </div>

          <div className="global-single-input">
            <p>Street</p>
            <input type="text" value={formValues.street}
              onChange={getFormInfo("street")}
              disabled={formValues?.visibleOnly}
            />
            <p className="doc-validation-alert">{validationAlert?.street}</p>
          </div>

          <div className="global-single-input">
            <p>Zip code</p>
            <input type="text" value={formValues.address}
              onChange={getFormInfo("address")}
              disabled={formValues?.visibleOnly}
            />
            <p className="doc-validation-alert">{validationAlert?.address}</p>
          </div>
          <div className="global-single-input">
            <p>Tax ID</p>
            <input type="text" value={formValues.taxId}
              onChange={getFormInfo("taxId")}
              disabled={formValues?.visibleOnly}
            />
            <p className="doc-validation-alert">{validationAlert?.taxId}</p>
          </div>
          {/* <div className="global-single-input">
                        <p>Phone</p>
                        <input type="text" value={formValues.phone}
                          onChange={getFormInfo("phone")}
                          disabled={formValues?.visibleOnly}
                        />
                        <p className="doc-validation-alert">{validationAlert?.phone}</p>
                    </div> */}
        </div>
        <div className="global-white-bg-container">
          <div className="global-single-input">
            <p>Mobile</p>
            <input type="text" value={formValues.mobile}
              onChange={getFormInfo("mobile")}
              disabled={formValues?.visibleOnly}
            />
            <p className="doc-validation-alert">{validationAlert?.mobile}</p>
          </div>
          <div className="global-single-input">
            <p>Email</p>
            <input type="text" value={formValues.email}
              onChange={getFormInfo("email")}
              disabled={formValues?.visibleOnly}
            />
            <p className="doc-validation-alert">{validationAlert?.email}</p>
          </div>
          <div className="global-single-input auto-complete">
            <p>Payment Terms</p>
            <Autocomplete
              options={[]}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} placeholder="select payment terms" />
              )}
              value={formValues.paymentTerms}
              onChange={getFormInfo("paymentTerms")}
              disabled={formValues?.visibleOnly}
            />
            {/* <input type="text" value={formValues.paymentTerms}
                          onChange={getFormInfo("paymentTerms")}
                          disabled={formValues?.visibleOnly}
                        /> */}
            <p className="doc-validation-alert">{validationAlert?.paymentTerms}</p>
          </div>
          <div className="global-single-input">
            <p>Supplier Currency</p>
            <input type="text" value={formValues.supplierCurrency}
              onChange={getFormInfo("supplierCurrency")}
              disabled={formValues?.visibleOnly}
            />
            <p className="doc-validation-alert">{validationAlert?.supplierCurrency}</p>
          </div>
          <div className="global-single-input">
            <p>Contact Person</p>
            <input type="text" value={formValues.contactPerson}
              onChange={getFormInfo("contactPerson")}
              disabled={formValues?.visibleOnly}
            />
            <p className="doc-validation-alert">{validationAlert?.contactPerson}</p>
          </div>
          <div className="global-single-input">
            <p>Contact Person Mobile</p>
            <input type="text" value={formValues.mobilePhoneNo}
              onChange={getFormInfo("mobilePhoneNo")}
              disabled={formValues?.visibleOnly}
            />
            <p className="doc-validation-alert">{validationAlert?.mobilePhoneNo}</p>
          </div>
          <div className="global-single-input">
            <p>Company Code</p>
            <input type="text" value={formValues.companyCode}
              onChange={getFormInfo("companyCode")}
              disabled={formValues?.visibleOnly}
            />
            <p className="doc-validation-alert">{validationAlert?.companyCode}</p>
          </div>
          <div className="global-single-input auto-complete">
            <p>Branch</p>
            <Autocomplete
              options={userRole==="admin" ? allBranchesList: allowedBranchList || []}
              getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}
              renderInput={(params) => (
                <TextField {...params} placeholder="select branch" />
              )}
              value={formValues.branch}
              onChange={getFormInfo("branch")}
              disabled={formValues?.visibleOnly}
            />
            <p className="doc-validation-alert">{validationAlert?.branch}</p>
          </div>
          <div className="global-single-input">
            <p>Active / Inactive</p>
            <input
              className="toggle-checkbox"
              checked={formValues?.toggleChecked}
              onChange={(e) => setFormValues({ ...formValues, toggleChecked: e.target.checked })}
              disabled={formValues?.visibleOnly}
              type="checkbox" />
          </div>
        </div>
      </div>
      <SuccessSnackbar open={snackBarStates.success} handleClose={closeSnackbar} message={snackBarStates.message} />
      <ErrorSnackbar open={snackBarStates.error} handleClose={closeSnackbar} message={snackBarStates.message} />
    </div>
  )
}