import { IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setErrorSnack, setSnackMsg } from '../Billing/Slice/billingScreenSlice';
import { useNavigate } from 'react-router-dom';
import { addListSpecialItemsAPI, editListSpecialItemsAPI, productlistSpecialItemsAPI, specialItemsSingleView } from './specialItemsApi';
import { setIsCombo, setIsEdit, setIsEditClicked } from './specialItemsSlice';
import SpecialItemsInputFields from './components/SpecialItemsInputFields';
import { viewAllAllowedBranchesAPICall, viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import SpecialItemsTable from './components/SpecialItemsTable';
import ComboPriceTag from './components/ComboPriceTag';
import { viewUOMSubListAPICall } from '../../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall';
import { getAllTaxSettingsListAPICall } from '../../../../API/Settings/TaxSettings/taxSettingsAPI';

function CreateSpecialItems() {

  const dispatch=useDispatch()
  const navigate=useNavigate()


  const userInfo=useSelector((state)=>state.loginResponseSlice.value)

  const{specialItemsSingleList,editClicked,isCombo,infoArray,formValues,imageUpload,isEdit}=useSelector((state)=>state.specialItemsSlice)

  const singleViewById=localStorage.getItem("singleViewById")
  



  const saveBtnClick=(type)=>()=>{

    const productList=infoArray
    .filter((item) =>(item.product!==null && (isCombo ? ( item.qty !== "") : (item.offerPrice !== ""))))
    .map((item)=>(
        {
            productId: (item?.product?.productId) || item?.product?._id,
            unitprice:isCombo?0:Number(item?.unitPrice),
            offerPrice:isCombo?0:Number( item?.offerPrice),
            qty:isCombo?Number(item?.qty):0,
            _id:type==="edit" ?item?._id:undefined
        }
    ))

    const formData=new FormData()

    formData.append("isCombo",isCombo)
    formData.append("name",formValues?.name || "")
    formData.append("branchId",JSON.stringify(formValues?.branches?.map((day)=>day._id)) || [])
    formData.append("productList",JSON.stringify(productList))
    formData.append("weekdays",JSON.stringify(formValues?.days?.map((day)=>day.value)) || [])
    formData.append("taxes",JSON.stringify(formValues?.tax?.map((obj)=>obj?._id)) || [])
    typeof(imageUpload)!=="string" ?formData.append("file",imageUpload):formData.append("imageUrl",imageUpload)
    formData.append("comboPrice",isCombo?formValues?.comboPrice :0)
    formData.append("uom",isCombo?formValues?.uom?._id :null)


    if(formValues===null || formValues?.name===""){
        dispatch(setSnackMsg("Enter a name!!"))
        dispatch(setErrorSnack(true))
    }
    else if(productList?.length===0){
        dispatch(setSnackMsg("Items not added!!"))
        dispatch(setErrorSnack(true))
    }
    else if(isCombo && formValues?.comboPrice===""){
        dispatch(setSnackMsg("Combo price is required!!"))
        dispatch(setErrorSnack(true))
    }
    else if(isCombo && formValues?.uom===null){
        dispatch(setSnackMsg("UOM is required!!"))
        dispatch(setErrorSnack(true))
    }
    else{
        if(type==="edit"){
            formData.append("_id",specialItemsSingleList?._id)
        }
        let clearFn=()=>{
            navigate("/userdashboard/pos/specialItems")
        }

        type==="add" ?addListSpecialItemsAPI(formData,clearFn):editListSpecialItemsAPI(formData,clearFn)
    }
  }

  useEffect(()=>{
    viewAllAllowedBranchesAPICall({employeeId: userInfo?.data?._id })
    viewAllBranchesAPICall()
    specialItemsSingleList===null && singleViewById!==null && specialItemsSingleView({id:singleViewById})
    productlistSpecialItemsAPI()
    viewUOMSubListAPICall();
    getAllTaxSettingsListAPICall();
  },[])

    useEffect(() => {
        if (specialItemsSingleList === null) {
            dispatch(setIsEdit(false))
        } else {
            dispatch(setIsCombo(specialItemsSingleList?.isCombo))
            dispatch(setIsEdit(true))
        }
    }, [specialItemsSingleList])


  return (
    <div className='global-page-parent-container special-items-ctn'>
        <div className="global-white-bg-container special-items-head">
        <div className='justify-center' style={{justifyContent:"space-between",width:"100%"}}>
            
            <div className='justify-start'>
            
            <IconButton onClick={()=>navigate("/userdashboard/pos/specialItems")}>
                <i class="bi bi-chevron-left"></i>
            </IconButton>

            {
                specialItemsSingleList!==null?
               <IconButton
              onClick={
                editClicked
                  ? saveBtnClick("edit")
                  : () => {
                      dispatch(setIsEdit(false))
                      dispatch(setIsEditClicked(true))
                    }
              }
              >
                  <i
                      class={
                          editClicked
                              ? "bi bi-check2-square edit-icon"
                              : "bi bi-pencil-square edit-icon1"
                      }
                  ></i>
              </IconButton>:
              <IconButton onClick={saveBtnClick("add")}
              >
                  <i class="bi bi-floppy save-icon"></i>
              </IconButton>
                
              
              }
            </div>

            <div className="justify-center" style={{gap:"5px"}}>
                <p className='p-head'>Combo Offer</p>
                      <input
                          className="toggle-checkbox"
                          type={"checkbox"}
                          checked={isCombo}
                          onChange={(e)=>dispatch(setIsCombo(e.target.checked))}
                          name="purchaseReport"
                          disabled={isEdit}
                      />
            </div>
        </div>
        <hr className="global-hr" />
        <SpecialItemsInputFields/>
        </div>

        <div className="global-white-bg-container">
            <SpecialItemsTable/>
        </div>

        <div className="lists">
        {
            formValues!==null && formValues?.name!=="" &&
            <div className={`list-cards` }>
                <div className="justify-space-between" style={{paddingBottom:"6px"}}>
                    <p className='head'>{formValues?.name}</p>
                    {
                        isCombo&&
                        <ComboPriceTag amount={formValues?.comboPrice}/>}
                </div>

                <div className="list-card-items">
                    {
                        infoArray.length!==0?
                        infoArray.filter((item)=>(item.product!==null && (isCombo ? ( item.qty !== "") : (item.offerPrice !== ""))))
                        .map((r,i)=>(
                            <div className='justify-space-between' style={{border:"none"}}>
                            <p
                            key={r?._id}
                            >{r?.product?.productName}</p>
                            {
                            <p>{isCombo?r?.qty:r?.offerPrice}</p>
                            }
                            </div>
                        )):
                        <div className="center-div" style={{height:"150px",color:"red"}}>
                            Add Items !!
                        </div>
                    }
                </div>
            </div>}
        </div>
    </div>
  )
}

export default CreateSpecialItems