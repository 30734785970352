import React, { useState } from "react";
import "./componentStyle.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDispatch, useSelector } from "react-redux";
import { setImportFile } from "../BankReconciliationSlice";

const FileUpload = () => {
  const dispatch = useDispatch();
  const { importFile } = useSelector((state) => state.bankReconciliation);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      dispatch(setImportFile(file));
    } else {
      // Handle invalid file type
      alert("Please upload a .xlsx file.");
    }
    // Clear the file input value to allow re-uploading the same file
    event.target.value = null;
  };

  const handleUploadClick = () => {
    const fileInput = document.getElementById("file-input");
    fileInput.click();
  };

  return (
    <div className="file-upload">
      <div className="file-upload__input">
        <label htmlFor="file-input">
          <span className="file-upload__label">
            {importFile ? (
              <span className="file-upload__filename" title={importFile.name}>
                {importFile.name.length > 20
                  ? `${importFile.name.slice(0, 17)}...`
                  : importFile.name}
              </span>
            ) : (
              "File Upload"
            )}
          </span>
          <input
            id="file-input"
            type="file"
            accept=".xlsx"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </label>
      </div>
      <div className="file-upload__icon" onClick={handleUploadClick}>
        <CloudUploadIcon />
      </div>
    </div>
  );
};

export default FileUpload;