import { createSlice } from "@reduxjs/toolkit";

export const vendorsListSlice = createSlice({
  name: "vendorsListSlice",
  initialState: {
    apiResList: undefined,
    rowData:undefined,
    paymentTermsList:undefined,
    CustomerGroupList:undefined,
  },
  reducers: {
    get_vendor_list_api_res_list: (state, action) => {
      state.apiResList = action.payload.vendorResData;
    },
    get_vendor_list_row_data_list: (state, action) => {
      state.rowData = action.payload;
    },
    getPaymentTerms_List:(state,action)=>{
      state.paymentTermsList = action.payload.allPaymentList;

    },
    getCustomerGroup_List:(state,action)=>{
      state.CustomerGroupList=action.payload.allCustomerList;
    }
  },
});

export const { get_vendor_list_api_res_list,get_vendor_list_row_data_list,getPaymentTerms_List,getCustomerGroup_List} = vendorsListSlice.actions;

export default vendorsListSlice.reducer;