import axios from "axios";
import { HEADERS } from "../../../../API/UrlAndPaths";
import store from "../../../../Redux/store";
import { setAccountDashboardData } from "./accountDashboardSlice";

export const getAccountDashboardDataApi = async (data) => {
  try {
    const result = await axios.post("account/accountDashBoard", data?.body, HEADERS);
    if (result.status === 200) {
      store.dispatch(setAccountDashboardData(result.data));
    }
  } catch (error) {
    console.error("Error fetching account dashboard data:", error);
    throw error;
  }
};
