import React, { useEffect, useMemo, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import CategoryEmptyAnimation from '../Gif/CategoryEmptyAnimation';
import SearchIcon from "@mui/icons-material/Search";
import { Skeleton } from '@mui/material';
import { setCustomer, setCustomerSearchTerm, setCustomerWallet } from '../Components/topBarSliceBilling';
import { update_all_customer_list } from '../../../../../Redux/Customer/allCustomerListSlice';
import { setVisibleKeyboard } from '../../../../Single Components/customKeyBoardSlice';
import { errorSnackMsg } from '../../../../Custom Hooks/SnackkBarUtilities';
import { getCustomerWallet } from '../API/billingPaymentSection';

const CustomerListPopup = () => {

    const dispatch=useDispatch()
    const listViewRef=useRef(null)
    const buttonRef=useRef(null)
    const {customer,customerLoading,customerSearchTerm}=useSelector((state)=>state.topBarSliceBilling)
    const {singleOrderInfo} = useSelector((state) => state.billingScreenSlice);

    const customerList = useSelector((state) => state.allCustomerListSlice.value);

    const [open,setOpen]=useState(false)
    const customerClick=(item)=>{

        dispatch(setVisibleKeyboard(null))
        if(singleOrderInfo?.type==="refund"){
            errorSnackMsg("Customer can't be changed on refund!!")
        }else{
            dispatch(setCustomer(customer?._id===item?._id?null:item))
            item?getCustomerWallet({cusId:item?._id}):dispatch(setCustomerWallet(null))
        }
        setOpen(false) 
    }

    const closeIconClick=(e)=>{
        e.stopPropagation()
        open?setOpen(false):dispatch(setCustomer(null))
    }

    const searchFieldOnChange=(keyWord)=>{
        dispatch(setCustomerSearchTerm(keyWord))
    }

    const filteredData = useMemo(() => {
        if (!customerSearchTerm) return customerList;

        return customerList.filter(item => 
            item.name.toLowerCase().includes(customerSearchTerm.toLowerCase()) || 
            item.mobileNo.includes(customerSearchTerm)
        );
    }, [customerSearchTerm, customerList]);


    useEffect(()=>{
        if(!open && customer && customerList){
            const updatedList = [...customerList];
            const customerIndex = updatedList.findIndex(c => c._id === customer._id);
        
            if (customerIndex > 0) { 
              const [customerObj] = updatedList.splice(customerIndex, 1);
              updatedList.unshift(customerObj);
              dispatch(update_all_customer_list({ customerList: updatedList }));
            }

            filteredData && filteredData.length!==0 && dispatch(setCustomerSearchTerm("")) 
        }
    },[open,customerList])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (buttonRef.current && buttonRef.current.contains(event.target)) {
                return;
            }
            if (
                listViewRef.current && 
                !listViewRef.current.contains(event.target)
            ) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

  return (
    <div style={{position:"relative"}}>
        <div className='flex-row customer-list-btn' onClick={()=>setOpen(!open)} ref={buttonRef}>
            {customer?.name || "customer"}
            {(customer || open) &&<CloseIcon fontSize='small' onClick={(e)=>closeIconClick(e)}/>} 
        </div>

        {
                open &&
                <div className="customer-list-menu-items" ref={listViewRef}>
                    <div className="billing-search-bar" style={{width:"100%"}}>
                        <SearchIcon
                            sx={{ cursor: "pointer",color: "#9d9d9d !important" }}
                        />
                        <input
                            type="text"
                            placeholder={`Search customer by name,number..`}
                            className="search-bar"
                            value={customerSearchTerm}
                            onChange={(e)=>searchFieldOnChange(e.target.value)}
                            onFocus={(e) => e.target.select()}
                        />
                    </div>
                  
                  <div className="billing-kot-list-body" style={{paddingRight:"15px"}}>
                        {

                        customerLoading?
                        [...Array(8)].map((_,i)=>(
                          <div
                              key={i}
                              style={{ backgroundColor: "#fff" ,width:"100%"}}>
                                  <Skeleton width={"100%"} height={60} />

                          </div> 
                      ))
                        :customerList && filteredData?.length!==0?
                        filteredData.map((r,i)=>(
                            <div 
                            onClick={()=>customerClick(r)}
                            className={`kot-order-card customer-list-info-ctn ${customer?._id===r?._id?"selected-customer-from-list":""}`} key={i}>
                            <div className="flex-column">
                                <p style={{fontWeight:"600"}}>{r?.name || "---"}
                                </p>
                                <div className="justify-space-between" style={{width:"100%",alignItems:"start"}}>
                                    <p>Room No:&nbsp;{r?.roomNo || "---"}</p>
                                    <p>Building No:&nbsp;{r?.buildingNo || "---"}</p>
                                    <p style={{textAlign:"end"}}>Place:&nbsp;{r?.city || "---"}</p>
                                </div>
                            </div>
                        </div>
                        )):
                      <div className="center-div" style={{ height: "50vh", flexDirection: "column" }}>
                        <CategoryEmptyAnimation />
                        Customer data not found !!
                      </div>    
                        }
                  </div>
                </div>
            }

    </div>
  )
}

export default CustomerListPopup